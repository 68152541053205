<div class="main-container">
  <div class="ch-dialog-top">
    <span>
      About
      <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
    </span>
  </div>
  <div class="ch-dialog-body">
    <div class="about-section">
      <div class="logo" synthiaLogo [logoCountry]="appStateService.country | async"></div>
      <span class="logo-title" [innerHtml]="logoText"></span>
      <span class="version">Version: {{ version }}</span>
    </div>
  </div>
  <div class="ch-dialog-bottom">
    <span class="milliporesigma-tag" *ngIf="isMilliporeSigmaCountry">{{
      appConstantsService.milliporeSigmaTag
    }}</span>
    <div class="ch-dialog-controls">
      <button class="ch-outline-btn about-close-btn" mat-button buttonOutline="primary" (click)="dialogRef.close()">
        Close
      </button>
    </div>
  </div>
</div>
