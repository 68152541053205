import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppConstantsService, ParametersStashService } from '../../services';
import { AppState } from '../../../app-state.service';
import { LogoColor } from '../../directives/synthia-logo.directive';
@Component({
  selector: 'ch-unauthorized-view-header',
  templateUrl: './unauthorized-view-header.component.html',
  styleUrls: ['./unauthorized-view-header.component.scss'],
})
export class UnauthorizedViewHeaderComponent implements OnInit {
  public logoColor: string;
  public isSynthiaLiteEnabled: boolean = false;

  @Output() public onLogoClick: EventEmitter<any> = new EventEmitter();

  constructor(
    public appStateService: AppState,
    public appConstantsService: AppConstantsService,
    public stashService: ParametersStashService,
  ) {}

  public ngOnInit(): void {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.logoColor = !this.stashService.isSynthiaLiteEnabled ? LogoColor.CYAN : LogoColor.BLUE;
  }

  public onLogoClickEvent() {
    this.onLogoClick.emit();
  }
}
