import {
  ElementRef,
  Directive,
  AfterViewInit,
  Input,
  SimpleChanges,
  Renderer2,
  OnChanges,
} from '@angular/core';

import { AppConstantsService } from '../services';
import { AppState } from '../../app-state.service';

export enum LogoColor {
  BLUE = 'blue',
  CYAN = 'cyan',
}
@Directive({
  selector: '[synthiaLogo]',
})
export class SynthiaLogoDirective implements AfterViewInit, OnChanges {
  @Input() public logoColor: LogoColor = LogoColor.BLUE;
  @Input() public logoCountry: string = '';

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private appConstantsService: AppConstantsService,
    private appState: AppState,
  ) {}

  public ngAfterViewInit() {
    this.addLogo();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('logoCountry')) {
      this.addLogo();
    }
  }

  public addLogo() {
    const logo = this.getApplicationLogo();
    if (logo) {
      const imageEl = this.renderer.createElement('img');
      imageEl.src = logo;
      imageEl.alt = 'logo';
      this.clearContent();
      this.renderer.appendChild(this.elementRef.nativeElement, imageEl);
    }
  }

  public clearContent() {
    const wrapperChildren: any[] = this.elementRef.nativeElement.children;
    for (const childElement of wrapperChildren) {
      this.renderer.removeChild(this.elementRef.nativeElement, childElement);
    }
  }

  public getApplicationLogo() {
    let logo: string =
      this.logoColor === LogoColor.CYAN
        ? this.appConstantsService.merckLogoCyanUrl
        : this.appConstantsService.merckLogoUrl;
    if (this.appState.isMilliporeSigmaCountry(this.logoCountry)) {
      logo =
        this.logoColor === LogoColor.CYAN
          ? this.appConstantsService.milliporeSigmaLogoCyanUrl
          : this.appConstantsService.milliporeSigmaLogoUrl;
    }
    return logo;
  }
}
