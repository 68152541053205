import { fromEvent, Observable, Subscription } from 'rxjs';
import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AnalysisAlgorithm,
  AppConstantsService,
  ReactionListMode,
  AlgorithmType,
  AlgorithmMap,
  SimilarReactionsEntry,
  ResultsView,
} from '../../services';
import { MoleculeImageComponent } from '../molecule-image';
import { NodeMenuParentClass } from '../new-graph-node-menu/new-graph-node-menu.component';
import { GraphReactionNode } from '../../services/graph-builder';
import {
  escapeRegEx,
  prepareHtmlDoiLink,
  prepareHtmlPatentLink,
  prepareHtmlReferenceLink,
  isValidUrl,
} from '../utils';

@Component({
  selector: 'ch-reaction-details',
  templateUrl: './reaction-details.component.html',
  styleUrls: ['./reaction-details.component.scss'],
})
export class ReactionDetailsComponent implements AfterViewInit, OnDestroy, OnChanges {
  public readonly ReactionListMode = ReactionListMode;
  public protections: Array<{
    groupPreview: string;
    chemicalClass: string;
    protectiveGroups: string[];
  }> = [];
  public loadingProtections: boolean;
  public protectionOfNeeded: string[] = [];
  public pointed: boolean = false;
  public hideReaction: boolean = false;
  public menuOpened: boolean = false;
  public algorithmType: AlgorithmType;
  public reactionNodeType: string = 'reaction';
  public readonly ResultsView = ResultsView;

  @Input() public reaction: GraphReactionNode & SimilarReactionsEntry;
  @Input() public displayMode: 'normal' | 'similarReactions' | 'sideReactions';
  @Input() public reactionIndex: number;
  @Input() public algorithm: string;
  @Input() public reactionFilter: ReactionListMode;
  @Input() public viewMode: ResultsView;

  @Output() public onDetails = new EventEmitter<object>();
  @Output() public onMouseDown = new EventEmitter<object>();
  @Output() public imageLoaded = new EventEmitter<boolean>();
  @Output() public onProtectionInformation = new EventEmitter<object>();
  @Output() public onSimilarReactions = new EventEmitter<object>();
  @Output() public onSideReactions = new EventEmitter<object>();

  @ViewChild('image') private image: ElementRef;
  @ViewChild('moleculeImage')
  public moleculeImageComponent: MoleculeImageComponent;
  private onMouseDownQueue: Observable<any>;
  private reactionProtectionsSubscriptions: Subscription;
  private mouseDownSubscription: Subscription;

  constructor(public appConstantsService: AppConstantsService) {}

  public ngAfterViewInit() {
    if (this.image) {
      this.onMouseDownQueue = fromEvent(this.image.nativeElement, 'mousedown');
      this.mouseDownSubscription = this.onMouseDownQueue.subscribe(() => {
        if (this.viewMode === ResultsView.GRAPH) {
          this.onMouseDown.emit(this.reaction);
          this.pointed = !this.pointed;
        }
      });
    }
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('algorithm')) {
      this.algorithmType = AlgorithmMap[this.algorithm];
    }

    if (changes.hasOwnProperty('reaction')) {
      this.prepareDoiClickableLinks();
      this.preparePatentClickableLinks();
      this.prepareReferenceClickableLinks();
    }
  }

  public ngOnDestroy() {
    if (this.reactionProtectionsSubscriptions && !this.reactionProtectionsSubscriptions.closed) {
      this.reactionProtectionsSubscriptions.unsubscribe();
    }
    if (this.mouseDownSubscription && !this.mouseDownSubscription.closed) {
      this.mouseDownSubscription.unsubscribe();
    }
  }

  public getReactionName() {
    if (!this.isRetrosynthesisAnalysis()) {
      return this.appConstantsService.nocReactionTitle;
    } else {
      if (this.reaction.reaction.name) {
        return this.reaction.reaction.name;
      } else if (!this.reaction.reaction.name && this.reaction.reaction.rxids.length === 1) {
        return 'Retrosynthesis ID:' + this.reaction.reaction.rxids[0];
      }
    }
  }

  public isRetrosynthesisAnalysis() {
    return (
      this.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS ||
      this.algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS ||
      this.algorithm === AnalysisAlgorithm.LIBRARY_MODE ||
      this.algorithm === AnalysisAlgorithm.DIVERSITY_LIBRARY
    );
  }

  public updateMenuOpenedValue(value: boolean) {
    this.menuOpened = value;
  }

  public closeMenuInstance() {
    this.menuOpened = false;
  }

  public getParentClass() {
    return NodeMenuParentClass.reactionReport;
  }

  private prepareDoiClickableLinks() {
    if (!!this.reaction.doi && this.reaction.doi.length) {
      this.reaction.doi = this.reaction.doi.replace(
        new RegExp(escapeRegEx(this.reaction.doi), 'g'),
        prepareHtmlDoiLink(this.reaction.doi),
      );
    }
  }

  private preparePatentClickableLinks() {
    if (!!this.reaction.patent_number && this.reaction.patent_number.length) {
      this.reaction.patent_number = this.reaction.patent_number.replace(
        new RegExp(escapeRegEx(this.reaction.patent_number), 'g'),
        prepareHtmlPatentLink(this.reaction.patent_number),
      );
    }
  }

  private prepareReferenceClickableLinks() {
    if (!!this.reaction.reference && isValidUrl(this.reaction.reference)) {
      this.reaction.reference = prepareHtmlReferenceLink(this.reaction.reference);
    }
  }
}
