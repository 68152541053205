<div class="nested-tree" [class.synthia-lite-nested-tree]="isSynthiaLiteEnabled">
  <div *ngFor="let article of treeData">
    <div (click)="selectArticle(article, $event)" class="topic">
      <button mat-button [color]="isActive(article) ? 'primary' : 'default-grey'">
        <ng-container *ngIf="article.icon">
          <mat-icon class="article-icon">{{ article.icon }}</mat-icon>
        </ng-container>
        {{ article.topic_article }}
        <mat-icon
          class="down-arrow article-icon"
          *ngIf="!expandedArticle[article.id] && article.children.length !== 0"
          >chevron_right</mat-icon
        >
        <mat-icon
          class="up-arrow article-icon"
          *ngIf="expandedArticle[article.id] && article.children.length !== 0"
          >expand_more</mat-icon
        >
      </button>
      <div *ngIf="expandedArticle[article.id]">
        <div *ngFor="let firstNestedChild of article.children">
          <div
            *ngIf="expandedArticle[article.id]"
            (click)="selectArticle(firstNestedChild, $event)"
            [className]="
              isActive(firstNestedChild)
                ? 'ch-clr-secondary first-child'
                : 'first-child black-color'
            "
          >
            <mat-icon class="left-arrow" *ngIf="!expandedArticle[firstNestedChild.id]"
              >arrow_right</mat-icon
            >
            <mat-icon
              class="left-arrow"
              *ngIf="expandedArticle[firstNestedChild.id] && firstNestedChild.children.length"
              >arrow_drop_down</mat-icon
            >{{ firstNestedChild.topic_article }}
            <div *ngIf="expandedArticle[firstNestedChild.id]">
              <div *ngFor="let secondNestedChild of firstNestedChild.children">
                <div
                  *ngIf="expandedArticle[firstNestedChild.id]"
                  (click)="selectArticle(secondNestedChild, $event)"
                  [className]="
                    isActive(secondNestedChild)
                      ? 'second-child ch-clr-primary'
                      : 'second-child black-color'
                  "
                >
                  {{ secondNestedChild.topic_article }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
