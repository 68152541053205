import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Batch } from '../models/batch';

export const BackendEntryPointBatches = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/batches/';

@Injectable({
  providedIn: 'root',
})
export class BatchesService {
  constructor(private http: HttpClient) {}

  public getBatches(): Observable<Batch[]> {
    return this.http.get(BackendEntryPointBatches, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((batch) => new Batch(batch));
        } catch (e) {
          throw new Error('Unexpected format of batches response.');
        }
      }),
    );
  }

  public renameBatch(batchId: number, _name: string) {
    const batchUrl: string = BackendEntryPointBatches + batchId + '/';
    const batchName = _name.toString().replace(/\s+/g, ' ');
    const options = {
      name: batchName.trim(),
    };
    return this.http
      .patch(batchUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public deleteBatch(batchId: number) {
    const batchUrl: string = BackendEntryPointBatches + batchId + '/';
    return this.http.delete(batchUrl, { observe: 'response' }).pipe(map((result) => result));
  }
}
