<div class="ch-information" [class.synthia-lite-information]="isSynthiaLiteEnabled">
  <ng-container *ngIf="!loadingInformation; else showLoadingSpinner">
    <ng-container *ngIf="articles.length; else emptyPage">
      <div class="ch-information-article">
        <section class="article-header">
          <div class="title" *ngIf="activeArticle">
            {{ activeArticle.topic_article }}
          </div>
        </section>

        <div class="nested-tree">
          <ch-nested-tree
            #nestedTreeComponent
            [treeData]="articles"
            (openArticle)="openTreeArticle($event)"
            [activeArticle]="activeArticle"
          >
          </ch-nested-tree>
        </div>
        <div class="article-text" *ngIf="activeArticle">
          <div [innerHTML]="activeArticle.content.innerHTML" style="padding-bottom: 50px;"></div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #emptyPage>
    <div class="ch-information-empty">
      <p>Cannot display page at the moment.</p>
      <button mat-flat-button (click)="loadInformation()">
        REFRESH
      </button>
    </div>
  </ng-template>
  <ng-template #showLoadingSpinner>
    <div class="ch-information-loading">
      <mat-spinner [strokeWidth]="2" [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
</div>
