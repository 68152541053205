import { throwError } from 'rxjs';
import { isString } from '../../shared/components/utils';

export enum UserSubscriptionType {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
  NULL = '',
}
export class UserEntry {
  private static synthiaLiteExpiryInfo = new Set([
    'remaining_analyses',
    'plan_expiration_date',
    'deactivation_date',
  ]);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public email: string = '';
  public username: string = '';
  public first_name: string = '';
  public last_name: string = '';
  public send_finished_computation_email: boolean = true;
  public can_upload_inventory: boolean = false;
  public compounds_per_batch_limit: number = 100;
  public is_batch_enabled: boolean = true;
  public default_parameter_set: number = 0;
  public is_developer: boolean = false;
  public is_diversity_library_enabled: boolean = false;
  public is_sso_user: boolean = false;
  public landing_page_url: string = '';
  public is_compliance_data_accepted: boolean = false;
  public show_welcome_screen: boolean = true;
  public remaining_analyses: number | null = null;
  public plan_expiration_date: string = '';
  public deactivation_date: string = '';
  public phone_number: string = '';
  public two_factor_authentication: boolean = false;
  public is_customer_user_manager: boolean = false;
  public managed_users_limit: number = 0;
  public customer_account_name: string = '';
  public subscription_type: UserSubscriptionType = UserSubscriptionType.NULL;
  public is_premium_plans_enabled: boolean = false;
  public is_authorised_to_run_analyses: boolean = false;
  public show_path_score: boolean = false;
  public display_pathway_view: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data?) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (field === 'subscription_type') {
          try {
            this.subscription_type =
              jsonData[field] === null
                ? UserSubscriptionType.NULL
                : Object.values(UserSubscriptionType).includes(jsonData[field])
                ? jsonData[field]
                : throwError('Unexpected value');
          } catch (error) {
            console.warn(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else if (field === 'remaining_analyses') {
          this.remaining_analyses = jsonData[field];
        } else if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
          console.warn(this.getInvalidFieldTypeError(field, jsonData[field]));
        }
      } else {
        if (!UserEntry.synthiaLiteExpiryInfo.has(field)) {
          console.error(`Unexpected field '${field}' in data provided to UserEntry:ctor.`);
        }
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to UserEntry:ctor.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
