import { isString } from '../../components/utils';

export class PasswordEncryptionConfiguration {
  public PASSWORD_ENCRYPTION_ENABLED: boolean = true;
  public PASSWORD_ENCRYPTION_PUBLIC_KEY: string = '';

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.warn(
            `Unexpected type of '${field}' in data provided to PasswordEncryptionConfiguration.`,
          );
        }
      } else {
        console.warn(
          `Unexpected field '${field}' in data provided to PasswordEncryptionConfiguration.`,
        );
      }
    }
  }
}
