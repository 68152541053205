import { ElementRef, Directive, AfterViewInit } from '@angular/core';

// Focus element with 'ch-autofocus' attribute on ngAfterViewInit.

@Directive({
  selector: '[ch-autofocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(public elementRef: ElementRef) {}

  public ngAfterViewInit() {
    setTimeout(() => this.elementRef.nativeElement.focus());
  }
}
