import { DatePipe } from '@angular/common';
import { isString } from 'src/app/shared/components/utils';
import { Dictionary } from 'underscore';
import { ProductDetailInfo } from './ProductDetailInfo';
import { SkuAvailabilityInfo } from './SkuAvailabilityInfo';
import { SkuDetailInfo } from './SkuDetailInfo';
import { SubstanceProductDetailInfo } from './SubstanceProductDetailInfo';

export const currencySymbolDict: Dictionary<string> = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$',
};

export class SkuInfo {
  public country: string;
  public skuDetailList: SkuDetailInfo[];
  public productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo;

  constructor(
    productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo,
    pricingAndAvailabilityModel: any,
  ) {
    const jsonData = isString(pricingAndAvailabilityModel)
      ? JSON.parse(pricingAndAvailabilityModel)
      : pricingAndAvailabilityModel;
    this.country = jsonData.country;
    this.productDetailInfo = productDetailInfo;
    this.skuDetailList = new Array<SkuDetailInfo>();

    for (const item of jsonData.items) {
      const availableInfo = {
        key: item.availability_msg?.primary_msg?.key,
        quantity: item.availability_msg?.primary_msg?.quantity,
        date: item.availability_msg?.primary_msg?.date,
        plant_loc: item.availability_msg?.primary_msg?.plant_loc,
      };
      this.skuDetailList.push({
        sku: item.material_number,
        pack_size: item.package_size,
        material_description: item.material_description,
        availability: availableInfo,
        availabilityMessage: this.prepareAvailableMsg(availableInfo),
        price: item.price,
        quantity: 0,
        currency: item.currency,
        currencySymbol: this.prepareCurrencySymbol(item.currency),
        brand: item.brand ?? productDetailInfo.brand,
        brand_key: productDetailInfo.brand_key,
        product_key: productDetailInfo.product_key,
      });
    }
  }

  private prepareAvailableMsg(availableInfo: SkuAvailabilityInfo): string {
    if (availableInfo.date) {
      const datePipe = new DatePipe('en-GB');
      const date = new Date(availableInfo.date * 1000);
      const formattedDate = datePipe.transform(date, 'short');
      let msg = availableInfo.key?.toLowerCase()?.split('_').join(' ');
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
      return `${msg} ${formattedDate}`;
    } else if (availableInfo.key) {
      let msg = availableInfo.key.toLowerCase()?.split('_').join(' ');
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
      return `${msg}`;
    } else {
      return '';
    }
  }

  private prepareCurrencySymbol(currencyStr: string): string {
    const code = currencyStr.toUpperCase();
    if (code in currencySymbolDict) {
      return currencySymbolDict[code];
    } else {
      return '';
    }
  }
}
