import { MatDialogRef } from '@angular/material/dialog';
import { Component, HostListener, Input, OnInit } from '@angular/core';

import { ParametersStashService } from '../../services/parameters-stash/parameters-stash.service';

enum TrainingMaterialOptions {
  Hide = 'hide',
  Start = 'start',
  Close = 'close',
}
@Component({
  selector: 'ch-training-material-link-dialog',
  templateUrl: './training-material-link-dialog.component.html',
  styleUrls: ['./training-material-link-dialog.component.scss'],
})
export class TrainingMaterialLinkDialogComponent implements OnInit {
  public hidePopupForUser: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  @Input() public tourName: string = '';

  constructor(
    public dialogRef: MatDialogRef<TrainingMaterialLinkDialogComponent>,
    private stashService: ParametersStashService,
  ) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
  }

  public startTutorial() {
    this.dialogRef.close(TrainingMaterialOptions.Start);
  }

  public closeTutorial() {
    this.dialogRef.close(TrainingMaterialOptions.Close);
  }

  public checkToHideTutorial() {
    if (this.hidePopupForUser) {
      this.dialogRef.close(TrainingMaterialOptions.Hide);
    }
  }

  @HostListener('window:keyup.esc')
  public onKeyUp() {
    this.dialogRef.close(TrainingMaterialOptions.Close);
  }
}
