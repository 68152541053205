import { of, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CopyToClipboardService {
  private textarea: HTMLTextAreaElement;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Copy a string by creating a textarea html element, selecting it and executing copy command.
   * Returns an Observable carrying true or false.
   * Requires a string or it will fail.
   * @param content
   * @returns Observable<boolean>
   */
  public copyContent(content: string): Observable<boolean> {
    let success: boolean;
    if (this.isSupported()) {
      if (!this.textarea) {
        this.textarea = this.createTextArea(this.document);
        this.document.body.appendChild(this.textarea);
      }
      this.inputAndSelectText(this.textarea, content);
      this.copyText();
      this.deselectText(this.textarea);
      this.destroy();
      success = true;
    } else {
      success = false;
    }
    return of(success);
  }

  public destroy(element?: HTMLInputElement | HTMLTextAreaElement) {
    if (element) {
      this.document.body.removeChild(element);
      element = undefined;
    } else {
      this.document.body.removeChild(this.textarea);
      this.textarea = undefined;
    }
  }

  private isSupported(): boolean {
    return !!this.document.queryCommandSupported && !!this.document.queryCommandSupported('copy');
  }

  private createTextArea(doc: Document) {
    const isRtl = document.documentElement.getAttribute('dir') === 'rtl';
    const textarea = doc.createElement('textarea');
    textarea.style.position = 'absolute';
    textarea.style.fontSize = '12pt';
    textarea.style[isRtl ? 'right' : 'left'] = '1000px';
    textarea.style.top = '-1000px';
    textarea.setAttribute('readonly', '');
    return textarea;
  }

  private inputAndSelectText(textarea: HTMLTextAreaElement | HTMLInputElement, content: string) {
    textarea.value = content;
    textarea.select();
    textarea.setSelectionRange(0, content.length);
  }

  private copyText() {
    this.document.execCommand('Copy');
  }

  private deselectText(textarea: HTMLTextAreaElement | HTMLInputElement) {
    this.textarea.blur();
    window.getSelection().removeAllRanges();
  }
}
