import { Injectable } from '@angular/core';
import {
  setPersistence,
  browserSessionPersistence,
  signInWithPopup,
  getRedirectResult,
  signOut,
  SAMLAuthProvider,
} from 'firebase/auth';
import { Auth, authState } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class SingleSignOnService {
  constructor(private auth: Auth) {}

  public firebaseSetPersistence(provider: string) {
    return setPersistence(this.auth, browserSessionPersistence).then(() => {
      return signInWithPopup(this.auth, this.getProvider(provider));
    });
  }

  public getProvider(samlProvider: string, type: string = 'SAML') {
    return new SAMLAuthProvider(samlProvider);
  }

  public firebaseGetRedirectResult() {
    return getRedirectResult(this.auth);
  }

  public firebaseLogout() {
    signOut(this.auth)
      .then(() => {})
      .catch(function (error) {
        console.log(error);
      });
  }

  public firebaseIsAuthorized() {
    return authState(this.auth);
  }
}
