import { isString } from '../../shared/components/utils';
import { SubstanceProductDetailInfo } from './SubstanceProductDetailInfo';
import { prepareImageUrl } from '../services/ecomm-handler.service';

export class Substance {
  public cas_number: string;
  public empirical_formula: string;
  public image_url: string;
  public image_alt: string;
  public molecular_weight: string;
  public molecule_name: string;
  public synonyms: string[];
  public products: SubstanceProductDetailInfo[];

  constructor(data: any, ecommAPIUrl: string) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    this.cas_number = jsonData.cas_number;
    this.empirical_formula = jsonData.empirical_formula;
    this.molecular_weight = jsonData.molecular_weight;
    this.molecule_name = jsonData.substance_name;
    if (jsonData.images && jsonData.images.length > 0) {
      this.image_url = prepareImageUrl(jsonData.images[0].url, ecommAPIUrl);
      this.image_alt = jsonData.images[0].alt_text;
    } else {
      this.image_url = '';
      this.image_alt = '';
    }
    this.synonyms = jsonData.synonyms;
    this.products = new Array<SubstanceProductDetailInfo>();
    for (const productData of jsonData.products) {
      if (productData.is_sial === '0') {
        this.image_url = jsonData.images?.length > 0 ? jsonData.images[0].url : '';
        for (const material of productData.materials) {
          this.products.push(new SubstanceProductDetailInfo(productData, true, material));
        }
      } else {
        this.products.push(new SubstanceProductDetailInfo(productData));
      }
    }
  }
}
