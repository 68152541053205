import { Injectable } from '@angular/core';
import { GraphResources } from './analysis/models/graph-resources';
import {
  GraphReactionNodeEntry,
  GraphReactionEntry,
  GraphMoleculeEntry,
  LibraryResources,
} from './analysis/models/';
import { getLocalCopy } from '../components/utils';
import { AnalysisAlgorithm } from './app-constants/app-constants.service';
import { COLOR_EDGE_DEFAULT } from '../../colors';
import { DiversityResources } from './analysis/models/diversity-resources';

/* tslint:disable:prefer-for-of */
/* tslint:disable:max-classes-per-file */

export enum PathMode {
  NORMAL = 'normal',
  STRUCTURES = 'structures',
  EXPANDED = 'expanded',
  NAME = 'name',
}

export enum GraphNodeType {
  REACTION = 'diamond',
  MOLECULE = 'circle',
  STRUCTURE = 'image',
  EXPANDED_IMAGE = 'expandedImage',
  EXPANDED_REACTION = 'expandedReaction',
}

export enum GraphEdgeType {
  EXPANDED_ARROW = 'expandedArrow',
  EXPANDED_LINE = 'expandedLine',
  CURVED_DOWN_LINE = 'curvedDownLine',
  CURVED_UP_LINE = 'curvedUpLine',
}

export enum ChildReactionNodeType {
  EXPANDED = 'expanded',
  PRETARGET = 'preTarget',
}

export enum MoleculeNodeTagType {
  NODE_KNOWN = 'NODE_KNOWN',
  NODE_UNKNOWN = 'NODE_UNKNOWN',
  NODE_COMMERCIALLY_AVAILABLE = 'NODE_COMMERCIALLY_AVAILABLE',
  NODE_SA_AVAILABLE = 'NODE_SA_AVAILABLE',
  NODE_CUSTOM_INVENTORY = 'NODE_CUSTOM_INVENTORY',
  NODE_PROTECTION_NEEDED = 'NODE_PROTECTION_NEEDED',
  NODE_REGULATED = 'NODE_REGULATED',
  NODE_SECONDARY = 'NODE_SECONDARY',
  NODE_TARGET = 'NODE_TARGET',
}

export enum GraphEdgeLabelValues {
  NONE_SELECTIVE = 'Non-selective',
  DIASTEREOSELECTIVE_ONLY = 'Diastereoselective only',
  STRATEGIES = 'Strategies',
  DYNAMIC_STRATEGIES = 'Dynamic Strategies',
  TUNNELS = 'Power search',
}

export interface GraphReactionNode {
  [attr: string]: any;
  reaction_node: GraphReactionNodeEntry;
  reaction: GraphReactionEntry;
  type: GraphNodeType;
}

export interface GraphMoleculeNode {
  [attr: string]: any;
  molecule: GraphMoleculeEntry;
  type: GraphNodeType;
  parentReactionNodeId: number;
  childReactionNodeId: number | string;
  massPerGramOfTarget: number;
  score: any;
  paths: number[];
  isTarget?: boolean;
}

export interface GraphEdge {
  id: number;
  color: string;
  defaultColor: string;
  source: number;
  target: number;
  size: number;
  type: string;
  reactionNodeId: number;
  markedForPrint: boolean;
  paths: number[];
}

export interface Graph {
  nodes: GraphMoleculeNode[] & GraphReactionNode[];
  edges: GraphEdge[];
  toDisplay: any[];
  score: any;
  syntheticPath: any;
  [attr: string]: any;
}

export class GraphBuilderParameters {
  constructor(
    public graph = createGraphObject(),
    public reactionNodes: GraphReactionNodeEntry[] = [],
    public graphApiResult = new GraphResources(),
    public syntheticPath: any = undefined /* tslint:disable-line:no-unnecessary-initializer */,
    public algorithm: string = '',
  ) {}
}

export class DiversityBuilderParameters {
  constructor(
    public graph = createGraphObject(),
    public reactionNodes: GraphReactionNodeEntry[] = [],
    public graphApiResult = new DiversityResources(),
    public syntheticPath: any = undefined /* tslint:disable-line:no-unnecessary-initializer */,
    public algorithm: string = '',
  ) {}
}

export class LibraryBuilderParameters {
  constructor(
    public graph = createGraphObject(),
    public reactionNodes: GraphReactionNodeEntry[] = [],
    public graphApiResult = new LibraryResources(),
    public syntheticPath: any = undefined /* tslint:disable-line:no-unnecessary-initializer */,
    public algorithm: string = '',
  ) {}
}

export function createGraphObject(): Graph {
  const graph: Graph = {
    nodes: [],
    edges: [],
    toDisplay: [],
    score: undefined,
    syntheticPath: undefined,
  };

  return graph;
}

/* tslint:disable:max-classes-per-file */

@Injectable()
export class GraphBuilder {
  private graph = createGraphObject();
  private graphApiResult: GraphResources;
  private algorithm: string;
  private createdGraphReactionNodes: { [reactionId: string]: GraphReactionNode[] } = {};
  private createdGraphMoleculeNodes: { [moleculeId: string]: GraphMoleculeNode[] } = {};

  public build(parameters: GraphBuilderParameters) {
    this.graph = parameters.graph;
    this.graphApiResult = parameters.graphApiResult;
    this.algorithm = parameters.algorithm;

    // The line below is a critically needed. We need to sort reaction nodes by id, descending,
    // because graph building should be started from reaction nodes wich appears
    // next from target molecule. Otherwise the graph will be wrongly built for some analysis algorithms
    this.graphApiResult.reaction_nodes = this.graphApiResult.reaction_nodes.sort(
      (a, b) => a.id - b.id,
    );

    this.addNodes();
    this.addEdges();

    return this.graph;
  }

  private addNodes() {
    for (const apiReactionNode of this.graphApiResult.reaction_nodes) {
      const reactionNode = this.createReactionNode(apiReactionNode);

      this.createReactionProductNodes(reactionNode);
      this.createReactionSubstrateNodes(reactionNode);
    }

    for (const moleculeGroup in this.createdGraphMoleculeNodes) {
      if (this.createdGraphMoleculeNodes.hasOwnProperty(moleculeGroup)) {
        for (const moleculeNode of this.createdGraphMoleculeNodes[moleculeGroup]) {
          let moleculeExist: boolean = false;

          if (this.algorithm !== AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS) {
            for (const currentNode of this.graph.nodes) {
              if (
                currentNode.type !== GraphNodeType.REACTION &&
                currentNode.id === moleculeNode.id &&
                currentNode.molecule.id === moleculeNode.molecule.id &&
                currentNode.parentReactionNodeId === moleculeNode.parentReactionNodeId
              ) {
                if (currentNode.childReactionNodeId !== moleculeNode.childReactionNodeId) {
                  currentNode.childReactionNodeId = moleculeNode.childReactionNodeId;
                  currentNode.fromLastModifications = true;
                }
                moleculeExist = true;
              }
            }
          } else {
            moleculeNode.fromLastModifications = true;
          }

          if (!moleculeExist) {
            moleculeNode.id =
              this.graph.nodes.length > 0
                ? this.graph.nodes[this.graph.nodes.length - 1].id + 1
                : 1;
            this.graph.nodes.push(moleculeNode);
          }
        }
      }
    }

    for (const parentReaction in this.createdGraphReactionNodes) {
      if (this.createdGraphReactionNodes.hasOwnProperty(parentReaction)) {
        for (const reactionNode of this.createdGraphReactionNodes[parentReaction]) {
          let pushedReactionClone: GraphReactionNode;

          if (this.algorithm !== AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS) {
            pushedReactionClone = this.graph.nodes.find(
              (node: GraphReactionNode) =>
                node.type === GraphNodeType.REACTION &&
                node.reaction_node.id === reactionNode.reaction_node.id,
            );
          }

          if (!pushedReactionClone) {
            reactionNode.id =
              this.graph.nodes.length > 0
                ? this.graph.nodes[this.graph.nodes.length - 1].id + 1
                : 1;
            this.graph.nodes.push(reactionNode);
          }
        }
      }
    }
  }

  private createReactionNode(apiReactionNode: GraphReactionNodeEntry) {
    const apiReaction = this.getApiReaction(apiReactionNode);
    const graphReactionNode = this.createEmptyGraphReactionNode();
    graphReactionNode.reaction_node = apiReactionNode;
    graphReactionNode.reaction = apiReaction;

    if (this.createdGraphReactionNodes[graphReactionNode.reaction_node.parent_reaction_node]) {
      this.createdGraphReactionNodes[graphReactionNode.reaction_node.parent_reaction_node].push(
        graphReactionNode,
      );
    } else {
      this.createdGraphReactionNodes[graphReactionNode.reaction_node.parent_reaction_node] = [];
      this.createdGraphReactionNodes[graphReactionNode.reaction_node.parent_reaction_node].push(
        graphReactionNode,
      );
    }

    return graphReactionNode;
  }

  private createReactionProductNodes(reactionNode: GraphReactionNode) {
    for (const reactionProductId of reactionNode.reaction.products) {
      const productMolecule = this.getApiMolecule(reactionNode, reactionProductId);
      let moleculeNode = this.createEmptyGraphMoleculeNode();
      moleculeNode.molecule = productMolecule;

      if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
        moleculeNode.parentReactionNodeId = reactionNode.reaction_node.id;
        moleculeNode.score = reactionNode.reaction_node.child_molecule_scores[productMolecule.id];
        moleculeNode.massPerGramOfTarget = undefined;
      } else {
        moleculeNode.childReactionNodeId = reactionNode.reaction_node.id;

        if (
          reactionNode.reaction_node.parent_reaction_node === null &&
          reactionNode.reaction_node.parent_molecule === productMolecule.id
        ) {
          moleculeNode.childReactionNodeId = ChildReactionNodeType.PRETARGET;
        } else if (
          reactionNode.reaction_node.parent_reaction_node !== null &&
          reactionNode.reaction_node.parent_molecule === productMolecule.id
        ) {
          moleculeNode.parentReactionNodeId = reactionNode.reaction_node.parent_reaction_node;
        }
      }

      let isMoleculeAlreadyAdded: boolean = false;
      const createdMoleculeNodesWithSameId = this.createdGraphMoleculeNodes[productMolecule.id];

      if (createdMoleculeNodesWithSameId) {
        for (let existingMoleculeNode of createdMoleculeNodesWithSameId) {
          if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
            const reactionNodesToCompare: GraphReactionNodeEntry[] = getLocalCopy(
              this.graphApiResult.reaction_nodes.filter(
                (_reactionNode) =>
                  _reactionNode.parent_reaction_node === reactionNode.reaction_node.id,
              ),
            );

            if (reactionNodesToCompare) {
              for (const reactionNodeToCompare of reactionNodesToCompare) {
                if (existingMoleculeNode.childReactionNodeId === reactionNodeToCompare.id) {
                  existingMoleculeNode.score =
                    reactionNode.reaction_node.child_molecule_scores[productMolecule.id];
                  existingMoleculeNode = this.addPathwaysIdToMoleculeNode(
                    reactionNode,
                    existingMoleculeNode,
                  );
                  isMoleculeAlreadyAdded = true;
                }
              }
            }
          } else {
            if (
              reactionNode.reaction_node.parent_molecule === productMolecule.id &&
              reactionNode.reaction_node.parent_reaction_node === null
            ) {
              existingMoleculeNode = this.addPathwaysIdToMoleculeNode(
                reactionNode,
                existingMoleculeNode,
              );
              isMoleculeAlreadyAdded = true;
            } else if (
              reactionNode.reaction_node.parent_molecule === productMolecule.id &&
              reactionNode.reaction_node.parent_reaction_node !== null &&
              reactionNode.reaction_node.parent_reaction_node ===
                existingMoleculeNode.parentReactionNodeId
            ) {
              existingMoleculeNode = this.addPathwaysIdToMoleculeNode(
                reactionNode,
                existingMoleculeNode,
              );
              isMoleculeAlreadyAdded = true;

              const childReactionsFromGraph = this.createdGraphReactionNodes[
                reactionNode.reaction_node.parent_reaction_node
              ];

              const expandedReactions = childReactionsFromGraph.filter(
                (reaction) =>
                  reaction.reaction_node.parent_molecule === existingMoleculeNode.molecule.id,
              );

              if (
                existingMoleculeNode.childReactionNodeId === undefined ||
                expandedReactions.length > 1
              ) {
                existingMoleculeNode.childReactionNodeId = ChildReactionNodeType.EXPANDED;
              }
            }
          }
        }
      }

      if (!isMoleculeAlreadyAdded) {
        moleculeNode.fromLastModifications = true;
        moleculeNode = this.addPathwaysIdToMoleculeNode(reactionNode, moleculeNode);
        if (this.createdGraphMoleculeNodes[moleculeNode.molecule.id]) {
          this.createdGraphMoleculeNodes[moleculeNode.molecule.id].push(moleculeNode);
        } else {
          this.createdGraphMoleculeNodes[moleculeNode.molecule.id] = [];
          this.createdGraphMoleculeNodes[moleculeNode.molecule.id].push(moleculeNode);
        }
      }
    }
  }

  private createReactionSubstrateNodes(reactionNode: GraphReactionNode) {
    let addedSubstratesCount: number = 0;

    if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
      for (const substrateId of reactionNode.reaction.substrates) {
        let substrate = this.graphApiResult.molecules.find(
          (_substrate) => _substrate.id === substrateId,
        );

        if (substrate) {
          substrate = new GraphMoleculeEntry(substrate);
        } else {
          this.throwGraphBuildingError(`
            Cannot find substrate molecule with ID:
            ${substrateId} on the molecule list for
            reaction node with ID: ${reactionNode.reaction_node.id}
          `);
        }

        let moleculeNode = this.createEmptyGraphMoleculeNode();
        moleculeNode.molecule = substrate;

        if (
          reactionNode.reaction_node.parent_reaction_node === null &&
          moleculeNode.molecule.id === reactionNode.reaction_node.parent_molecule
        ) {
          moleculeNode.childReactionNodeId = ChildReactionNodeType.PRETARGET;
        } else if (
          reactionNode.reaction_node.parent_reaction_node === null &&
          moleculeNode.molecule.id !== reactionNode.reaction_node.parent_molecule
        ) {
          moleculeNode.childReactionNodeId = reactionNode.reaction_node.id;
        } else if (
          reactionNode.reaction_node.parent_reaction_node !== null &&
          moleculeNode.molecule.id === reactionNode.reaction_node.parent_molecule
        ) {
          moleculeNode.childReactionNodeId = reactionNode.reaction_node.id;
        } else {
          moleculeNode.childReactionNodeId = reactionNode.reaction_node.id;
        }

        let isMoleculeAlreadyAdded: boolean = false;
        const currentMolecules = this.createdGraphMoleculeNodes[moleculeNode.molecule.id];

        if (currentMolecules) {
          for (let currentMolecule of currentMolecules) {
            if (
              reactionNode.reaction_node.parent_reaction_node !== null &&
              reactionNode.reaction_node.parent_molecule === substrate.id
            ) {
              let parentReaction: number;

              const parentReactionNode = this.graphApiResult.reaction_nodes.find(
                (_reactionNode) => _reactionNode.id === currentMolecule.parentReactionNodeId,
              );

              if (parentReactionNode) {
                parentReaction = parentReactionNode.parent_reaction_node;
              } else {
                const parentReactionFromGraph = this.graph.nodes.find(
                  (node: GraphReactionNode) =>
                    node.type === GraphNodeType.REACTION &&
                    node.reaction_node.id === currentMolecule.parentReactionNodeId,
                );

                if (parentReactionFromGraph) {
                  parentReaction = parentReactionFromGraph.reaction_node.parent_reaction_node;
                }
              }

              if (parentReaction !== undefined) {
                const reactionsToCompare = this.createdGraphReactionNodes[parentReaction];

                if (reactionsToCompare) {
                  for (const reactionToCompare of reactionsToCompare) {
                    if (
                      currentMolecule.parentReactionNodeId === reactionToCompare.reaction_node.id
                    ) {
                      if (currentMolecule.childReactionNodeId === undefined) {
                        if (
                          reactionToCompare.reaction_node.id ===
                          reactionNode.reaction_node.parent_reaction_node
                        ) {
                          currentMolecule.childReactionNodeId = reactionNode.reaction_node.id;
                          currentMolecule.fromLastModifications = true;
                        }
                      } else {
                        if (
                          reactionToCompare.reaction_node.id ===
                          reactionNode.reaction_node.parent_reaction_node
                        ) {
                          currentMolecule.childReactionNodeId = ChildReactionNodeType.EXPANDED;
                          currentMolecule.fromLastModifications = true;
                        }
                      }

                      currentMolecule.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
                        currentMolecule.molecule.id,
                      );
                      currentMolecule = this.addPathwaysIdToMoleculeNode(
                        reactionNode,
                        currentMolecule,
                      );
                      isMoleculeAlreadyAdded = true;
                    }
                  }
                }
              }
            } else if (
              reactionNode.reaction_node.parent_reaction_node === null &&
              reactionNode.reaction_node.parent_molecule === substrate.id
            ) {
              currentMolecule.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
                currentMolecule.molecule.id,
              );
              currentMolecule = this.addPathwaysIdToMoleculeNode(reactionNode, currentMolecule);
              isMoleculeAlreadyAdded = true;
            }
          }
        }

        if (!isMoleculeAlreadyAdded) {
          moleculeNode.fromLastModifications = true;
          moleculeNode = this.addPathwaysIdToMoleculeNode(reactionNode, moleculeNode);
          if (reactionNode.reaction_node.parent_molecule === substrate.id) {
            moleculeNode.parentReactionNodeId = reactionNode.reaction_node.parent_reaction_node;
          }

          moleculeNode.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
            substrate.id,
          );

          if (this.createdGraphMoleculeNodes[substrate.id]) {
            this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
          } else {
            this.createdGraphMoleculeNodes[substrate.id] = [];
            this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
          }

          addedSubstratesCount++;
        }
      }
    } else {
      const childReactionNodes: GraphReactionNodeEntry[] = getLocalCopy(
        this.graphApiResult.reaction_nodes.filter(
          (_reactionNode) => _reactionNode.parent_reaction_node === reactionNode.reaction_node.id,
        ),
      );

      for (const childReactionNode of childReactionNodes) {
        let substrate = this.graphApiResult.molecules.find(
          (_substrate) => _substrate.id === childReactionNode.parent_molecule,
        );

        if (substrate) {
          substrate = new GraphMoleculeEntry(substrate);
        } else {
          this.throwGraphBuildingError(`
            Cannot find parent molecule with ID:
            ${childReactionNode.parent_molecule} in molecule
            list for reaction node with ID: ${childReactionNode.id}
          `);
        }

        let moleculeNode = this.createEmptyGraphMoleculeNode();
        moleculeNode.molecule = substrate;
        let isMoleculeAlreadyAdded: boolean = false;
        const currentMolecules = this.createdGraphMoleculeNodes[substrate.id];

        if (currentMolecules) {
          for (let currentMolecule of currentMolecules) {
            if (currentMolecule.childReactionNodeId === childReactionNode.id) {
              isMoleculeAlreadyAdded = true;
              currentMolecule = this.addPathwaysIdToMoleculeNode(reactionNode, currentMolecule);
              currentMolecule.parentReactionNodeId = reactionNode.reaction_node.id;
              currentMolecule.score =
                reactionNode.reaction_node.child_molecule_scores[substrate.id];
              if (
                this.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS &&
                reactionNode.reaction_node.child_molecule_masses_per_gram_of_target &&
                reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id]
              ) {
                currentMolecule.massPerGramOfTarget =
                  reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id];
              } else {
                currentMolecule.massPerGramOfTarget = undefined;
              }
              currentMolecule.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
                currentMolecule.molecule.id,
              );
            } else if (
              currentMolecule.parentReactionNodeId === childReactionNode.parent_reaction_node
            ) {
              isMoleculeAlreadyAdded = true;
              currentMolecule.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
                currentMolecule.molecule.id,
              );
              currentMolecule = this.addPathwaysIdToMoleculeNode(reactionNode, currentMolecule);
            }
          }
        }

        if (!isMoleculeAlreadyAdded) {
          moleculeNode.fromLastModifications = true;
          moleculeNode.parentReactionNodeId = reactionNode.reaction_node.id;
          moleculeNode.childReactionNodeId = childReactionNode.id;
          moleculeNode = this.addPathwaysIdToMoleculeNode(reactionNode, moleculeNode);
          if (reactionNode.reaction_node.child_molecule_scores[substrate.id]) {
            moleculeNode.score = reactionNode.reaction_node.child_molecule_scores[substrate.id];
          } else {
            moleculeNode.score = undefined;
          }

          if (
            this.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS &&
            reactionNode.reaction_node.child_molecule_masses_per_gram_of_target &&
            reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id]
          ) {
            moleculeNode.massPerGramOfTarget =
              reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id];
          } else {
            moleculeNode.massPerGramOfTarget = undefined;
          }

          moleculeNode.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
            substrate.id,
          );

          if (this.createdGraphMoleculeNodes[substrate.id]) {
            this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
          } else {
            this.createdGraphMoleculeNodes[substrate.id] = [];
            this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
          }
          addedSubstratesCount++;
        }
      }

      if (
        reactionNode.reaction.substrates.length >= childReactionNodes.length ||
        reactionNode.reaction.substrates.length >= addedSubstratesCount
      ) {
        for (const substrateId of reactionNode.reaction.substrates) {
          const childReactionWithPushedProduct = childReactionNodes.find(
            (node) => node.parent_molecule === substrateId,
          );

          if (childReactionWithPushedProduct === undefined) {
            let moleculeNode = this.createEmptyGraphMoleculeNode();

            let substrate = this.graphApiResult.molecules.find(
              (_substrate) => _substrate.id === substrateId,
            );

            if (substrate) {
              substrate = new GraphMoleculeEntry(substrate);
            } else {
              this.throwGraphBuildingError(`
                Cannot find substrate molecule with ID:
                ${substrateId} in
                molecule list for reaction node with ID:
                ${reactionNode.reaction_node.id}.
              `);
            }

            moleculeNode.molecule = substrate;

            moleculeNode.parentReactionNodeId = reactionNode.reaction_node.id;
            if (reactionNode.reaction_node.child_molecule_scores[substrate.id]) {
              moleculeNode.score = reactionNode.reaction_node.child_molecule_scores[substrate.id];
            } else {
              moleculeNode.score = undefined;
            }

            if (
              this.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS &&
              reactionNode.reaction_node.child_molecule_masses_per_gram_of_target &&
              reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id]
            ) {
              moleculeNode.massPerGramOfTarget =
                reactionNode.reaction_node.child_molecule_masses_per_gram_of_target[substrate.id];
            } else {
              moleculeNode.massPerGramOfTarget = undefined;
            }

            moleculeNode.needsProtection = reactionNode.reaction.isSubstrateNeedsProtection(
              substrate.id,
            );

            moleculeNode.fromLastModifications = true;
            moleculeNode = this.addPathwaysIdToMoleculeNode(reactionNode, moleculeNode);
            if (this.createdGraphMoleculeNodes[substrate.id]) {
              this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
            } else {
              this.createdGraphMoleculeNodes[substrate.id] = [];
              this.createdGraphMoleculeNodes[substrate.id].push(moleculeNode);
            }
          }
        }
      }
    }
  }

  private addPathwaysIdToMoleculeNode(
    reactionNode: GraphReactionNode,
    reactionMolecule: GraphMoleculeNode,
  ) {
    for (const pathId of reactionNode.reaction_node.paths) {
      if (!reactionMolecule.paths.includes(pathId)) {
        reactionMolecule.paths.push(pathId);
      }
    }

    return reactionMolecule;
  }

  private addPathwaysIdToEdge(reactionNode: GraphReactionNode, edge: GraphEdge) {
    for (const pathId of reactionNode.reaction_node.paths) {
      if (!edge.paths.includes(pathId)) {
        edge.paths.push(pathId);
      }
    }

    return edge;
  }

  private addEdges() {
    const addedMolecules: GraphMoleculeNode[] = this.graph.nodes.filter(
      (molecule: GraphMoleculeNode | GraphReactionNode) =>
        molecule.type !== GraphNodeType.REACTION && molecule.fromLastModifications,
    );

    for (const addedMolecule of addedMolecules) {
      if (addedMolecule.parentReactionNodeId) {
        const parentReaction = this.graph.nodes.find(
          (node: GraphReactionNode) =>
            node.type === GraphNodeType.REACTION &&
            node.reaction_node.id === addedMolecule.parentReactionNodeId,
        );

        let edge = this.createGraphEdge();
        edge.reactionNodeId = addedMolecule.parentReactionNodeId;

        if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
          edge.source = parentReaction.id;
          edge.target = addedMolecule.id;
        } else {
          edge.source = addedMolecule.id;
          edge.target = parentReaction.id;
        }

        let existingEdge = this.graph.edges.find(
          (_existingEdge) =>
            _existingEdge.source === edge.source && _existingEdge.target === edge.target,
        );

        if (!existingEdge) {
          edge = this.addPathwaysIdToEdge(parentReaction, edge);
          this.graph.edges.push(edge);
        } else {
          existingEdge = this.addPathwaysIdToEdge(parentReaction, existingEdge);
        }
      }

      if (
        addedMolecule.childReactionNodeId !== undefined &&
        addedMolecule.childReactionNodeId !== ChildReactionNodeType.PRETARGET &&
        addedMolecule.childReactionNodeId !== ChildReactionNodeType.EXPANDED
      ) {
        const childReaction = this.graph.nodes.find(
          (node: GraphReactionNode) =>
            node.type === GraphNodeType.REACTION &&
            node.reaction_node.id === addedMolecule.childReactionNodeId,
        );

        let edge = this.createGraphEdge();
        edge.reactionNodeId = childReaction.reaction_node.id;

        if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
          edge.source = addedMolecule.id;
          edge.target = childReaction.id;
        } else {
          edge.source = childReaction.id;
          edge.target = addedMolecule.id;
        }

        let existingEdge = this.graph.edges.find(
          (_existingEdge) =>
            _existingEdge.source === edge.source && _existingEdge.target === edge.target,
        );

        if (!existingEdge) {
          edge = this.addPathwaysIdToEdge(childReaction, edge);
          this.graph.edges.push(edge);
        } else {
          existingEdge = this.addPathwaysIdToEdge(childReaction, existingEdge);
        }
      }

      if (addedMolecule.childReactionNodeId === ChildReactionNodeType.PRETARGET) {
        const preTargetReactions = this.graph.nodes.filter(
          (node: GraphReactionNode) =>
            node.type === GraphNodeType.REACTION &&
            node.reaction_node.parent_reaction_node === null,
        );

        if (preTargetReactions.length > 0) {
          for (const preTargetReaction of preTargetReactions) {
            let edge = this.createGraphEdge();
            edge.reactionNodeId = preTargetReaction.reaction_node.id;

            if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
              edge.source = addedMolecule.id;
              edge.target = preTargetReaction.id;
            } else {
              edge.source = preTargetReaction.id;
              edge.target = addedMolecule.id;
            }

            let existingEdge = this.graph.edges.find(
              (_existingEdge) =>
                _existingEdge.source === edge.source && _existingEdge.target === edge.target,
            );

            if (!existingEdge) {
              edge = this.addPathwaysIdToEdge(preTargetReaction, edge);
              this.graph.edges.push(edge);
            } else {
              existingEdge = this.addPathwaysIdToEdge(preTargetReaction, existingEdge);
            }
          }
        }
      }

      if (addedMolecule.childReactionNodeId === ChildReactionNodeType.EXPANDED) {
        const childReactions = this.graph.nodes.filter(
          (node: GraphReactionNode) =>
            node.type === GraphNodeType.REACTION &&
            node.reaction_node.parent_reaction_node === addedMolecule.parentReactionNodeId &&
            node.reaction_node.parent_molecule === addedMolecule.molecule.id,
        );

        if (childReactions.length > 0) {
          for (const childReaction of childReactions) {
            let edge = this.createGraphEdge();
            edge.reactionNodeId = childReaction.reaction_node.id;

            if (this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
              edge.source = addedMolecule.id;
              edge.target = childReaction.id;
            } else {
              edge.source = childReaction.id;
              edge.target = addedMolecule.id;
            }

            let existingEdge = this.graph.edges.find(
              (_existingEdge) =>
                _existingEdge.source === edge.source && _existingEdge.target === edge.target,
            );

            if (!existingEdge) {
              edge = this.addPathwaysIdToEdge(childReaction, edge);
              this.graph.edges.push(edge);
            } else {
              existingEdge = this.addPathwaysIdToEdge(childReaction, existingEdge);
            }
          }
        }
      }

      if (
        !addedMolecule.childReactionNodeId &&
        this.algorithm !== AnalysisAlgorithm.REVERSE_REACTION_NETWORK
      ) {
        addedMolecule.isLastSubstrate = true;
      } else if (
        addedMolecule.childReactionNodeId &&
        this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK
      ) {
        addedMolecule.isLastSubstrate = true;
      }

      addedMolecule.fromLastModifications = false;
    }
  }

  private createEmptyGraphReactionNode() {
    const graphReactionNode: GraphReactionNode = {
      id: undefined,
      type: GraphNodeType.REACTION,
      reaction_node: null,
      reaction: null,
    };

    return graphReactionNode;
  }

  private createEmptyGraphMoleculeNode() {
    const moleculeNode: GraphMoleculeNode = {
      id: undefined,
      type: GraphNodeType.MOLECULE,
      molecule: null,
      parentReactionNodeId: null,
      score: null,
      massPerGramOfTarget: null,
      childReactionNodeId: undefined,
      paths: [],
    };

    return moleculeNode;
  }

  private createGraphEdge() {
    const edge: GraphEdge = {
      id: this.graph.edges.length > 0 ? this.graph.edges[this.graph.edges.length - 1].id + 1 : 1,
      color: COLOR_EDGE_DEFAULT,
      defaultColor: COLOR_EDGE_DEFAULT,
      source: undefined,
      target: undefined,
      size: 0.3,
      type: 'arrow',
      reactionNodeId: null,
      markedForPrint: false,
      paths: [],
    };

    return edge;
  }

  private getApiReaction(apiReactionNode: GraphReactionNodeEntry) {
    let apiReaction = this.graphApiResult.reactions.find(
      (reaction) => reaction.id === apiReactionNode.reaction,
    );

    if (apiReaction) {
      apiReaction = new GraphReactionEntry(getLocalCopy(apiReaction));
    } else {
      this.throwGraphBuildingError(`
        Cannot find reaction with ID: ${apiReactionNode.reaction}
        on reaction node list for reaction node with ID:
        ${apiReactionNode.id}.
      `);
    }

    return apiReaction;
  }

  private getApiMolecule(reactionNode: GraphReactionNode, moleculeId: string) {
    let apiMolecule = this.graphApiResult.molecules.find((molecule) => molecule.id === moleculeId);

    if (apiMolecule) {
      apiMolecule = new GraphMoleculeEntry(apiMolecule);
    } else {
      this.throwGraphBuildingError(`
        Cannot find compound molecule with ID:
        ${moleculeId} for reaction node with ID: ${reactionNode.reaction_node.id}.
      `);
    }

    return apiMolecule;
  }

  private throwGraphBuildingError(error) {
    throw new Error(error);
  }
}

/* tslint:enable:prefer-for-of */
