<div class="ch-comment-dialog">
  <div class="ch-dialog-comment">
    <span> Comments {{ unreadCount }} </span>
    <span>
      <mat-icon (click)="dialogRef.close(commentList?.length)" class="icon-18">close</mat-icon>
    </span>
  </div>
  <div class="ch-dialog-body-comment" #comment>
    <div class="ch-comment-list">
      <div #commentDiv class="ch-single-comment" *ngFor="let comment of commentList; let i = index">
        <div *ngIf="firstUnreadIndex === i && firstUnreadIndex !== -1" class="ch-unread">
          <div class="continuous-line"></div>
          <div class="unread-message-text">Unread Messages</div>
          <div class="continuous-line"></div>
        </div>
        <div class="all-comments-view">
          <div
            *ngIf="
              comment.username === userEmail || comment.username === userName;
              then myView;
              else authorView
            "
          ></div>
          <ng-template #myView>
            <div class="ch-align-right">
              <div class="ch-comment-title">
                <span class="ch-comment-author">{{ getAuthorName(comment) }} </span>
                <div class="ch-divider"></div>
                <span class="ch-comment-date">{{ formattedDate(comment.date) }}</span>
              </div>
              <span class="ch-comment-content" [innerHTML]="setContent(comment.content)"></span>
            </div>
          </ng-template>
          <ng-template #authorView>
            <div class="ch-comment-author-view">
              <div
                class="author-img"
                *ngIf="comment.username !== userEmail && comment.username !== userName"
                [ngStyle]="{ 'background-color': commentsAuthorMap[comment.username] }"
              >
                <span class="profile-pic">
                  {{ setAuthorImg(comment) }}
                </span>
              </div>
              <div class="ch-align-left">
                <div class="ch-comment-title">
                  <span class="ch-comment-author">{{ getAuthorName(comment) }} </span>
                  <div class="ch-divider"></div>
                  <span class="ch-comment-date">{{ formattedDate(comment.date) }}</span>
                </div>
                <span
                  class="ch-comment-content"
                  [class.ch-comment-content-unread]="comment.unread && firstUnreadIndex !== -1"
                  [innerHTML]="setContent(comment.content)"
                ></span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="comment-form-area">
    <div class="ch-comment" #commentForm>
      <textarea
        #textarea
        class="custom-textarea"
        tabIndex="-1"
        matInput
        rows="2"
        cols="80"
        maxlength="1024"
        (focus)="onFocus()"
        placeholder="Type your comment here..."
        [formControl]="commentFormControl"
        (keydown.enter)="saveComment($event)"
      >
      </textarea>
      <a class="bt-emoji" (click)="toggleMart($event)">
        <mat-icon *ngIf="!(toggle || isCommentFocus)" class="ch-post-icon" svgIcon="ch:emoji">
        </mat-icon>
        <mat-icon *ngIf="toggle || isCommentFocus" class="ch-post-icon" svgIcon="ch:emoji-active">
        </mat-icon>
      </a>
    </div>
    <div class="ch-focus" *ngIf="isCommentFocus"></div>
    <div class="ch-comment-post-button">
      <button class="ch-post-button" [disabled]="!isCommentValid()" (click)="saveComment($event)">
        <mat-icon class="ch-post-icon" svgIcon="ch:post"> </mat-icon>
      </button>
    </div>
  </div>
  <div class="ch-emoji" #emojiDiv>
    <emoji-mart
      *ngIf="toggle"
      class="ch-emoji"
      [darkMode]="false"
      [showPreview]="false"
      emoji="point_up"
      (emojiClick)="handleEmoji($event)"
    ></emoji-mart>
  </div>
</div>
