import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, ElementRef, OnInit, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ScoringFunctionsService,
  ParsedConnectionError,
  ErrorsHandlerService,
} from '../../services';
import { ScoringFunction } from '../../services/scoring-functions/models/scoring-function';
import { InfoService } from '../../services/info.service';
import {
  ADVANCED_CSF_AVAILABLE_PARAMS,
  ADVANCED_RSF_AVAILABLE_PARAMS,
  AdvancedSFAvailableParam,
  parseCSF,
  parseRSF,
  ScoringFunctionCategoryType,
  FunctionType,
} from '../scoring-function-utils';

@Component({
  selector: 'ch-scoring-function-calculator',
  styleUrls: ['./scoring-function-calculator.component.scss'],
  templateUrl: './scoring-function-calculator.component.html',
})
export class ScoringFunctionCalculatorComponent implements OnInit, OnDestroy {
  public readonly FunctionType = FunctionType;

  public tempScoringFunction: string = '';
  public originScoringFunction: ScoringFunction;
  public functionType: FunctionType;
  public scoringFunction: ScoringFunction;
  public scoringFunctionCached: string;
  public inputHistory: any[] = [];
  public stopSaving: boolean = false;
  public savingFunction: boolean = false;
  public modifyFunction: boolean = false;
  public deletingFunction: boolean = false;
  public scoringFunctionName: string = '';
  public disableRequestButton: boolean = false;
  public currentFunction: ScoringFunction;
  public changesMade: boolean = false;
  public deletedReactionFunction: boolean = false;
  public deletedChemicalFunction: boolean = false;
  public rpnScoringFunction: string = '';
  public inputError: string = '';
  public advancedCSFAvailableFunctions: AdvancedSFAvailableParam[] = ADVANCED_CSF_AVAILABLE_PARAMS;
  public advancedRSFAvailableFunctions: AdvancedSFAvailableParam[] = ADVANCED_RSF_AVAILABLE_PARAMS;
  public templateScoringFunction: ScoringFunction = new ScoringFunction({
    id: 0,
    name: '',
    source_code: '',
    owner: 0,
    category: '',
    rpn_source_code: '',
  });

  private scoringFunctionSubscription: Subscription;

  constructor(
    public scoringFunctionDialogRef: MatDialogRef<ScoringFunctionCalculatorComponent>,
    public elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA)
    public injectedData: {
      scoringFunction: ScoringFunction;
      moleculeSets: any[];
      smartsSets: any[];
    },
    private scoringFunctionsService: ScoringFunctionsService,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.onWindowResize(event.target.innerHeight);
  }

  public onWindowResize(height: number) {
    if (height <= 600) {
      this.scoringFunctionDialogRef.updateSize('800px', '450px');
    } else if (height <= 750) {
      this.scoringFunctionDialogRef.updateSize('800px', '525px');
    } else {
      this.scoringFunctionDialogRef.updateSize('800px', '700px');
    }
  }

  public ngOnInit() {
    if (this.injectedData && this.injectedData.scoringFunction) {
      this.originScoringFunction = this.injectedData.scoringFunction;
      this.scoringFunction = this.injectedData.scoringFunction;
      this.templateScoringFunction.name = this.injectedData.scoringFunction.name;
      this.templateScoringFunction.source_code = this.injectedData.scoringFunction.source_code;
      this.templateScoringFunction.category = this.injectedData.scoringFunction.category;
      switch (this.injectedData.scoringFunction.category) {
        case ScoringFunctionCategoryType.REACTION_SCORE:
        case ScoringFunctionCategoryType.AUTOMATIC_RETROSYNTHESIS_REACTION:
          this.functionType = FunctionType.Reaction;
          break;
        case ScoringFunctionCategoryType.MOLECULE_SCORE:
        case ScoringFunctionCategoryType.AUTOMATIC_RETROSYNTHESIS_MOLECULE:
          this.functionType = FunctionType.Chemical;
          break;
        default:
          this.functionType = FunctionType.Sorting;
          break;
      }
      this.tempScoringFunction = this.injectedData.scoringFunction.source_code;
      this.inputHistory.push(this.tempScoringFunction);
    }

    if (this.functionType === FunctionType.Sorting) {
      this.scoringFunctionDialogRef.updateSize('760px', '700px');
    }
    const windowHeight = window.innerHeight;
    this.onWindowResize(windowHeight);
  }

  public onCalculatorParameterClick(parameter: {
    parameter_name: string;
    description: string;
    value: string;
    hideSeek: boolean;
  }) {
    if (!parameter.hideSeek) {
      this.addToScoringFunction(parameter.value);
    }
    this.inputError = '';
  }

  public addToScoringFunction(value: string) {
    const textArea = this.elementRef.nativeElement.querySelector(
      '.ch-scoring-function-calculator-textarea',
    );
    textArea.focus();
    let cursorPosition;
    const textAreaValue = textArea.value;
    cursorPosition = textAreaValue.slice(0, textArea.selectionStart).length;
    if (this.tempScoringFunction.length < 2000) {
      this.stopSaving = false;
      if (cursorPosition === this.tempScoringFunction.length) {
        this.tempScoringFunction = this.tempScoringFunction + value;
      } else {
        this.tempScoringFunction =
          this.tempScoringFunction.slice(0, cursorPosition) +
          value +
          this.tempScoringFunction.slice(cursorPosition);
      }
      this.inputHistory.push(this.tempScoringFunction);
    }
    if (this.tempScoringFunction.length > 2000) {
      this.stopSaving = true;
      this.tempScoringFunction = this.tempScoringFunction.substring(0, 1999);
    }
    this.clearInputHistory();
    this.changesMade = true;
    textArea.focus();
    const newCursorPosition = cursorPosition + value.length;
    // This timeout is necessary because setSelectionRange() was executed before focus() and then
    // focus() would lose track of cursors' position.
    setTimeout(() => {
      textArea.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 10);
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.inputError = '';
  }

  public clearScoringFunction() {
    this.scoringFunctionCached = this.tempScoringFunction;
    this.tempScoringFunction = '';
    this.inputHistory.push(this.tempScoringFunction);
    this.clearInputHistory();
    this.changesMade = true;
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.elementRef.nativeElement.querySelector('.ch-scoring-function-calculator-textarea').focus();
    this.inputError = '';
  }

  public resetScoringFunction() {
    this.tempScoringFunction = this.originScoringFunction.source_code;
    this.inputHistory.push(this.tempScoringFunction);
    this.clearInputHistory();
    this.changesMade = false;
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.elementRef.nativeElement.querySelector('.ch-scoring-function-calculator-textarea').focus();
    this.inputError = '';
  }

  public undo() {
    const lastHistoryState = this.inputHistory[this.inputHistory.length - 2];
    this.tempScoringFunction = lastHistoryState;
    this.inputHistory.pop();
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.elementRef.nativeElement.querySelector('.ch-scoring-function-calculator-textarea').focus();
    this.inputError = '';
  }

  public onCalculatorInput() {
    if (this.tempScoringFunction.length === 2000 && !this.stopSaving) {
      this.inputHistory.push(this.tempScoringFunction);
      this.stopSaving = true;
    } else if (this.tempScoringFunction.length < 2000) {
      this.stopSaving = false;
    }
    if (!this.stopSaving) {
      this.inputHistory.push(this.tempScoringFunction);
      this.changesMade = true;
    }
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.clearInputHistory();
    this.inputError = '';
  }

  public clearInputHistory() {
    // This should limit number of history states to 256.
    if (this.inputHistory.length > 256) {
      this.inputHistory.shift();
    }
  }

  public enterSaveFunction() {
    if (this.functionType !== FunctionType.Sorting) {
      try {
        if (this.functionType === FunctionType.Chemical) {
          this.rpnScoringFunction = parseCSF(this.tempScoringFunction);
        } else if (this.functionType === FunctionType.Reaction) {
          this.rpnScoringFunction = parseRSF(this.tempScoringFunction);
        }
      } catch (err) {
        this.inputError = 'The Scoring Function you entered is invalid.';
      }
      if (!this.inputError) {
        this.focusSaveInput();
      }
    } else {
      this.focusSaveInput();
    }
  }

  public focusSaveInput() {
    this.savingFunction = true;
    this.scoringFunctionName = '';
    setTimeout(() => {
      this.elementRef.nativeElement
        .querySelector('.ch-scoring-function-calculator-save-input')
        .focus();
    }, 25);
  }

  public enterModifyFunction() {
    if (this.functionType !== FunctionType.Sorting) {
      try {
        if (this.functionType === FunctionType.Chemical) {
          this.rpnScoringFunction = parseCSF(this.tempScoringFunction);
        } else if (this.functionType === FunctionType.Reaction) {
          this.rpnScoringFunction = parseRSF(this.tempScoringFunction);
        }
      } catch (err) {
        this.inputError = 'The Scoring Function you entered is invalid.';
      }
      if (!this.inputError) {
        this.focusModifyInput();
      }
    } else {
      this.focusModifyInput();
    }
  }

  public focusModifyInput() {
    this.scoringFunctionName = this.scoringFunction.name;
    this.modifyFunction = true;
    setTimeout(() => {
      this.elementRef.nativeElement
        .querySelector('.ch-scoring-function-calculator-save-input')
        .focus();
    }, 25);
  }

  public enterDeleteFunction() {
    this.deletingFunction = true;
  }

  public saveCustomFunction() {
    let category: string;
    let message: string;
    let type: string;
    switch (this.functionType) {
      case FunctionType.Chemical:
        message = 'Chemical scoring function saved';
        category = ScoringFunctionCategoryType.MOLECULE_SCORE;
        type = 'Chemical';
        break;
      case FunctionType.Reaction:
        message = 'Reaction scoring function saved';
        category = ScoringFunctionCategoryType.REACTION_SCORE;
        type = 'Reaction';
        break;
      case FunctionType.Sorting:
        message = 'Sorting function saved';
        category = ScoringFunctionCategoryType.REACTION_ORDER;
        type = 'Sorting';
        break;
      default:
        break;
    }
    this.disableRequestButton = true;
    this.scoringFunctionSubscription = this.scoringFunctionsService
      .saveNewFunction(category, this.scoringFunctionName, this.tempScoringFunction)
      .subscribe(
        (scoringFunction: ScoringFunction) => {
          this.savingFunction = false;
          this.modifyFunction = false;
          this.disableRequestButton = false;
          this.scoringFunction = scoringFunction;
          this.changesMade = false;
          this.infoService.showInfo(message);
        },
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          this.disableRequestButton = false;
          if (parsedError.shouldRedirect) {
            this.infoService.showInfo(parsedError.promptMessage);
            this.errorsHandler.logout();
          } else {
            const errorCode: string = parsedError.bodyJson.code;
            if (errorCode === 'non-unique-scoring-function') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' already exists`,
                3000,
              );
            } else if (errorCode === 'scoring-function-does-not-exist') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' does not exist`,
                3000,
              );
            } else if (parsedError.isRecognized()) {
              this.errorsHandler.showGlobalError(
                parsedError.promptMessage,
                parsedError.detailsMessage,
              );
            }
          }
        },
      );
  }

  public modifyExistingFunction() {
    let category: string = '';
    let message: string = '';
    let type: string = '';
    let msg: string = '';
    switch (this.functionType) {
      case FunctionType.Chemical:
        msg = ' scoring function saved.';
        category = ScoringFunctionCategoryType.MOLECULE_SCORE;
        type = 'Chemical';
        break;
      case FunctionType.Reaction:
        msg = ' scoring function saved.';
        category = ScoringFunctionCategoryType.REACTION_SCORE;
        type = 'Reaction';
        break;
      case FunctionType.Sorting:
        msg = ' function saved.';
        category = ScoringFunctionCategoryType.REACTION_ORDER;
        type = 'Sorting';
        break;
      default:
        break;
    }
    message = type + msg;
    this.disableRequestButton = true;
    this.scoringFunctionSubscription = this.scoringFunctionsService
      .modifyExistingFunction(
        this.scoringFunction.id,
        category,
        this.scoringFunctionName,
        this.tempScoringFunction,
      )
      .pipe(
        finalize(() => {
          this.disableRequestButton = false;
          this.scoringFunctionName = this.scoringFunction.name;
        }),
      )
      .subscribe(
        (scoringFunction: ScoringFunction) => {
          this.deletedReactionFunction = false;
          this.deletedChemicalFunction = false;
          this.savingFunction = false;
          this.modifyFunction = false;
          this.scoringFunction = scoringFunction;
          this.changesMade = false;
          this.infoService.showInfo(message);
        },
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          this.disableRequestButton = false;
          if (parsedError.shouldRedirect) {
            this.infoService.showInfo(parsedError.promptMessage);
            this.errorsHandler.logout();
          } else {
            const errorCode: string = parsedError.bodyJson.code;
            if (errorCode === 'non-unique-scoring-function') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' already exists`,
                3000,
              );
            } else if (errorCode === 'not_found') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' does not exist`,
                3000,
              );
            } else if (errorCode === 'patch-global-scoring-function') {
              this.infoService.showError('Predefined scoring functions cannot be modified', 3000);
            } else if (parsedError.isRecognized()) {
              this.errorsHandler.showGlobalError(
                parsedError.promptMessage,
                parsedError.detailsMessage,
              );
            }
          }
        },
      );
  }

  public deleteFunction() {
    let type: string = '';
    let message: string;
    switch (this.functionType) {
      case FunctionType.Chemical:
        message = 'Chemical scoring function deleted';
        type = 'Chemical';
        break;
      case FunctionType.Reaction:
        message = 'Reaction scoring function deleted';
        type = 'Reaction';
        break;
      case FunctionType.Sorting:
        message = 'Sorting function deleted';
        type = 'Sorting';
        break;
      default:
        break;
    }
    this.disableRequestButton = true;
    this.scoringFunctionSubscription = this.scoringFunctionsService
      .deleteExistingFunction(this.scoringFunction.id)
      .subscribe(
        (response) => {
          if (this.scoringFunction.category === ScoringFunctionCategoryType.REACTION_SCORE) {
            this.deletedReactionFunction = true;
            this.deletedChemicalFunction = false;
          } else if (this.scoringFunction.category === ScoringFunctionCategoryType.MOLECULE_SCORE) {
            this.deletedChemicalFunction = true;
            this.deletedReactionFunction = false;
          }
          this.scoringFunctionName = '';
          this.savingFunction = false;
          this.modifyFunction = false;
          this.deletingFunction = false;
          this.disableRequestButton = false;
          this.scoringFunction = undefined;
          this.changesMade = false;
          this.infoService.showInfo(message);
          this.closeDialog(true);
        },
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          this.disableRequestButton = false;
          if (parsedError.shouldRedirect) {
            this.infoService.showInfo(parsedError.promptMessage);
            this.errorsHandler.logout();
          } else {
            if (parsedError.bodyJson.code === 'non-unique-scoring-function') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' already exists`,
                3000,
              );
            } else if (parsedError.bodyJson.code === 'not_found') {
              this.infoService.showError(
                `
              ${type} scoring function
             '${this.scoringFunctionName}' does not exist`,
                3000,
              );
            } else if (parsedError.bodyJson.code === 'delete-global-scoring-function') {
              this.infoService.showError('Predefined scoring functions cannot be deleted', 3000);
            } else if (parsedError.isRecognized()) {
              this.errorsHandler.showGlobalError(
                parsedError.promptMessage,
                parsedError.detailsMessage,
              );
            }
          }
        },
      );
  }

  public ngOnDestroy() {
    if (this.scoringFunctionSubscription && !this.scoringFunctionSubscription.closed) {
      this.scoringFunctionSubscription.unsubscribe();
    }
  }

  public closeDialog(close: boolean) {
    if (close) {
      if (this.functionType === FunctionType.Chemical) {
        try {
          this.rpnScoringFunction = parseCSF(this.tempScoringFunction);
        } catch (err) {
          this.inputError = 'The Scoring Function you entered is invalid.';
        }
      } else if (this.functionType === FunctionType.Reaction) {
        try {
          this.rpnScoringFunction = parseRSF(this.tempScoringFunction);
        } catch (err) {
          this.inputError = 'The Scoring Function you entered is invalid.';
        }
      }
      if (!this.inputError) {
        this.scoringFunctionDialogRef.close({
          saveInterrupted: this.savingFunction,
          scoringFunction: !this.changesMade ? this.scoringFunction : this.templateScoringFunction,
          rpnScoringFunction: this.rpnScoringFunction,
          deletedReactionFunction: this.deletedReactionFunction,
          deletedChemicalFunction: this.deletedChemicalFunction,
        });
      }
    } else {
      this.scoringFunctionDialogRef.close(false);
    }
  }

  public shouldRequestButtonBeDisabled() {
    return this.disableRequestButton || this.scoringFunction.name === '';
  }

  public isFunctionGlobal() {
    return !this.scoringFunction || this.scoringFunction.owner === null;
  }

  public shouldEditDeleteBtnsBeDisabled() {
    return (
      !this.scoringFunction ||
      this.scoringFunction.owner === null ||
      this.tempScoringFunction === '' ||
      this.scoringFunction.owner === 0
    );
  }

  public isSourceCode() {
    if (this.scoringFunction) {
      return (
        this.tempScoringFunction === this.scoringFunction.source_code ||
        this.inputHistory.length === 0
      );
    }
  }

  public shouldSaveFunctionButtonDisabled() {
    return (
      this.scoringFunctionName === '' ||
      (this.scoringFunction.name === this.scoringFunctionName &&
        this.scoringFunction.owner === null)
    );
  }
}
