import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPreloadStrategyService implements PreloadingStrategy {
  constructor() {
    this.signInPageLoaded.next(false);
  }

  public signInPageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preloadAfter) {
      return this.signInPageLoaded.pipe(
        switchMap((signInPageLoaded) => (signInPageLoaded ? load() : EMPTY)),
      );
    } else {
      return EMPTY;
    }
  }
}
