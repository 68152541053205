<div
  [class.ch-main-bg]="!isSynthiaLiteEnabled"
  [class.ch-synthia-lite-main-bg]="isSynthiaLiteEnabled"
>
  <ch-unauthorized-view-header></ch-unauthorized-view-header>
  <mat-grid-list class="ch-sign-in-grid" cols="1">
    <div class="ch-logo-panel-title" *ngIf="!isSynthiaLiteEnabled" [innerHtml]="logoText"></div>
    <mat-card class="ch-sign-in-card">
      <mat-card-content>
        <mat-progress-bar
          class="ch-sign-in-progress"
          mode="indeterminate"
          color="primary"
          *ngIf="pendingAuthorization || pendingPasswordChange || ssoInProgress"
        ></mat-progress-bar>
        <form
          *ngIf="
            !multifactorAuthorization &&
            !urlConfirmation &&
            !mustChangePassword &&
            !invalidToken &&
            !ssoInProgress
          "
          autocomplete="off"
        >
          <div
            class="ch-sign-in-action-links ch-sign-in-top-link"
            *ngIf="(isStandardLogin || isSSOMode) && isEmailEntered"
          >
            <span class="standard-sso-sign-in sign-in-links bdr" (click)="editLoginEmail()">
              {{ isChinaRegion ? '使用另外的地址' : 'Use a different address' }}
            </span>
          </div>
          <table class="ch-sign-in-layout">
            <tr [hidden]="!isStandardLogin || (isStandardLogin && isEmailEntered)">
              <td>
                <div class="user-name-td">
                  <span>
                    {{ isChinaRegion ? '用户名' : 'Email' }}
                  </span>
                  <mat-form-field
                    appearance="outline"
                    class="ch-sign-in-inputs ch-form-field ch-text-field"
                  >
                    <input
                      type="email"
                      matInput
                      ch-autofocus
                      name="loginInput"
                      #loginInput="ngModel"
                      [(ngModel)]="login"
                      [placeholder]="'user@domain.com'"
                      autocomplete="off"
                      required
                      [minlength]="3"
                      maxlength="150"
                    />
                  </mat-form-field>
                </div>
              </td>
            </tr>
            <tr *ngIf="(isStandardLogin || isSSOMode) && isEmailEntered">
              <td>
                <div class="user-name-td">
                  <span>
                    {{ isChinaRegion ? '用户名' : 'Email' }}
                  </span>
                  <span class="email-entered"> {{ login }} </span>
                </div>
              </td>
            </tr>
            <tr [hidden]="!isStandardLogin || (isStandardLogin && !isEmailEntered)">
              <td>
                <div class="user-name-td">
                  <span>
                    {{ isChinaRegion ? '密码' : 'Password' }}
                  </span>
                  <mat-form-field
                    appearance="outline"
                    class="ch-sign-in-inputs ch-form-field ch-text-field"
                  >
                    <input
                      matInput
                      name="passwordInput"
                      id="password-input"
                      #passwordInput="ngModel"
                      [(ngModel)]="password"
                      [placeholder]="isChinaRegion ? '请输入密码' : 'Password'"
                      type="password"
                      required
                      [minlength]="1"
                      autocomplete="new-password"
                      [maxlength]="maxPasswordLength"
                    />
                  </mat-form-field>
                </div>
              </td>
            </tr>
            <tr *ngIf="isSSOMode && !isStandardLogin">
              <td>
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  color="primary"
                  id="sso-button"
                  [disabled]="pendingAuthorization"
                  (click)="signInFirebase()"
                >
                  <span> {{ isChinaRegion ? '单点登录' : 'Sign in with SSO' }}</span>
                </button>
              </td>
            </tr>

            <tr *ngIf="errors.length">
              <td class="ch-clr-warn">
                <p class="password-change-error" *ngFor="let err of errors">{{ err }}</p>
              </td>
            </tr>
            <tr *ngIf="isStandardLogin && isEmailEntered">
              <td class="ch-sign-in-submit-row">
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  color="primary"
                  id="submit-button"
                  [disabled]="
                    (loginInput && !loginInput.valid) ||
                    (passwordInput && !passwordInput.valid) ||
                    pendingAuthorization
                  "
                  (click)="signIn()"
                >
                  <span>
                    {{ isChinaRegion ? '登录' : 'Sign In' }}
                  </span>
                </button>
              </td>
            </tr>
            <tr *ngIf="isStandardLogin && !isEmailEntered">
              <td class="ch-sign-in-submit-row">
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  color="primary"
                  id="submit-button"
                  [disabled]="(loginInput && !loginInput.valid) || pendingAuthorization"
                  (click)="checkSSOUser()"
                >
                  <span>
                    {{ isChinaRegion ? '继续' : 'Continue' }}
                  </span>
                </button>
              </td>
            </tr>
            <tr *ngIf="isEMDLogin">
              <td class="ch-sign-in-submit-row">
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  color="primary"
                  (click)="redirectToEmdAccess()"
                  [disabled]="pendingAuthorization"
                >
                  <span>Sign In</span>
                </button>
                <ng-container *ngTemplateOutlet="registerButtonSection"></ng-container>
              </td>
            </tr>
            <div class="ch-sign-in-action-links" *ngIf="isStandardLogin">
              <div class="forgot-password" *ngIf="isEmailEntered">
                <a class="ch-sign-in-help sign-in-links bdr" [routerLink]="['/password-reset']">
                  {{ isChinaRegion ? '忘记用户名或密码' : 'Forgot password?' }}
                </a>
              </div>
              <ng-container *ngIf="!isEmailEntered">
                <ng-container *ngTemplateOutlet="registerButtonSection"></ng-container>
              </ng-container>
              <div class="remember-me-section" *ngIf="!isEmailEntered">
                <mat-checkbox color="primary" name="rememberMe" [(ngModel)]="rememberMe">
                  {{ isChinaRegion ? '记住账号' : 'Remember me' }}
                </mat-checkbox>
              </div>
            </div>
          </table>
          <div
            [class.sso-btn-margin]="!isStandardLogin && isSSOMode"
            [class.standard-btn-margin]="isStandardLogin && isSSOMode"
          >
            <span
              *ngIf="!isStandardLogin && isSSOMode"
              class="standard-sso-sign-in sign-in-links bdr"
              (click)="toggleStandardSSOLogin()"
            >
              {{ isChinaRegion ? '密码登录' : 'Sign in with password' }}
            </span>
            <span
              class="standard-sso-sign-in sign-in-links bdr"
              *ngIf="isStandardLogin && isSSOMode"
              (click)="toggleStandardSSOLogin()"
              >{{ isChinaRegion ? '单点登录' : 'Sign in with SSO' }}</span
            >
          </div>
        </form>
        <ch-confirmation-code
          *ngIf="
            multifactorAuthorization &&
            !urlConfirmation &&
            !paramsToken &&
            isShowConfirmationCodeView
          "
          (onConfirmationCodeEmitter)="onSelectConfirmationMethod($event)"
        ></ch-confirmation-code>

        <form
          *ngIf="
            multifactorAuthorization &&
            !urlConfirmation &&
            !paramsToken &&
            !isShowConfirmationCodeView
          "
          (ngSubmit)="confirmAuthorization()"
          autocomplete="off"
        >
          <table class="ch-sign-in-layout">
            <tr>
              <td>
                <p *ngIf="!showNewCodeAlert" align="center">
                  We have sent you a confirmation code by {{ selectedMethod }}
                </p>
                <p *ngIf="showNewCodeAlert" align="center" class="ch-clr-primary-strong">
                  {{ appConstantsService.twoFactorAuthCodeInfo }}
                </p>
                <div class="confirmation-code-td">
                  <span>Confirmation code</span>
                  <mat-form-field
                    appearance="outline"
                    class="ch-form-field ch-text-field ch-sign-in-inputs"
                  >
                    <input
                      matInput
                      ch-autofocus
                      name="confirmationCodeInput"
                      #confirmationCodeInput="ngModel"
                      [(ngModel)]="confirmationCode"
                      placeholder="Code"
                      required
                      minlength="5"
                      maxlength="10"
                    />
                    <mat-hint
                      *ngIf="!confirmationCodeInput.valid && !confirmationCodeInput.pristine"
                      align="end"
                      [class.error]="true"
                    >
                      <span *ngIf="confirmationCodeInput.hasError('minlength')"
                        >At least
                        {{ confirmationCodeInput.errors['minlength']['requiredLength'] }} characters
                        required</span
                      >
                    </mat-hint>
                  </mat-form-field>
                </div>
              </td>
            </tr>
            <tr *ngIf="errors.length">
              <td class="ch-clr-warn">
                <p class="password-change-error" *ngFor="let err of errors">{{ err }}</p>
              </td>
            </tr>
            <tr>
              <td class="ch-multifactor-button-row">
                <button
                  mat-button
                  class="section-button ch-outline-btn cancel-button"
                  [class.ch-synthia-lite-cancel-button]="isSynthiaLiteEnabled"
                  [buttonOutline]="'primary'"
                  (click)="returnToFirstStep()"
                >
                  <span>
                    {{ isChinaRegion ? '取消' : 'Cancel' }}
                  </span>
                </button>
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  color="primary"
                  id="confirm-button"
                  [disabled]="!confirmationCodeInput.valid || pendingAuthorization"
                >
                  <span>
                    {{ isChinaRegion ? '登录' : 'Sign In' }}
                  </span>
                </button>
              </td>
            </tr>

            <div class="ch-sign-in-password-reset">
              <a>Problem receiving a code?</a>
              <a *ngIf="newCodeTimer > 0">Wait {{ newCodeTimer }} seconds</a>
              <a
                *ngIf="newCodeTimer === 0"
                class="ch-sign-in-help sign-in-links ch-hover-primary bdr"
                (click)="resendConfirmationCode()"
                >Try again</a
              >
            </div>
          </table>
        </form>

        <form
          *ngIf="!multifactorAuthorization && !urlConfirmation && mustChangePassword"
          #changePasswordForm="ngForm"
          chEqualFields="newPasswordInput, repeatNewPasswordInput"
        >
          <table class="ch-sign-in-layout">
            <tr>
              <td>
                <p align="center" class="ch-clr-primary-strong">
                  Welcome, {{ username }}. In order to use your account, you need to change your
                  password.
                </p>
                <mat-form-field
                  class="change-pass-input value-field ch-form-field ch-text-field ch-hint-input"
                  appearance="outline"
                  [class.input-error]="
                    !currentPasswordInput.valid && !currentPasswordInput.pristine
                  "
                >
                  <input
                    matInput
                    ch-autofocus
                    #currentPasswordInput="ngModel"
                    name="currentPasswordInput"
                    type="password"
                    [(ngModel)]="currentPassword"
                    placeholder="Present password"
                    required
                    minlength="1"
                    maxlength="{{ maxPasswordLength }}"
                    (input)="onInputPassword($event)"
                  />
                  <mat-hint
                    *ngIf="
                      (!currentPasswordInput.valid || !!presentPasswordError) &&
                      !currentPasswordInput.pristine
                    "
                    align="end"
                    class="ch-clr-warn"
                  >
                    <span *ngIf="currentPasswordInput.hasError('minlength')"
                      >At least 1 character required</span
                    >
                    <span *ngIf="presentPasswordError">{{ presentPasswordError }}</span>
                  </mat-hint>
                </mat-form-field>
                <mat-form-field
                  class="change-pass-input value-field ch-form-field ch-text-field ch-hint-input"
                  appearance="outline"
                  [class.input-error]="!newPasswordInput.valid && !newPasswordInput.pristine"
                >
                  <input
                    matInput
                    #newPasswordInput="ngModel"
                    name="newPasswordInput"
                    type="password"
                    [(ngModel)]="newPassword"
                    placeholder="Type in new password"
                    required
                    [minlength]="minPasswordLength"
                    maxlength="{{ maxPasswordLength }}"
                    pattern="{{ passwordPattern }}"
                    (input)="onInputPassword($event)"
                    (keydown.enter)="confirmPasswordChange()"
                  />
                  <mat-icon
                    matSuffix
                    matTooltip="Password should be minimum {{
                      minPasswordLength
                    }} characters, contain both upper and lower-case characters and contain both numbers and special characters (!@$#%^&*_)"
                    matTooltipPosition="above"
                    matTooltipClass="ch-tooltip"
                    >info_outline</mat-icon
                  >
                  <mat-hint
                    *ngIf="!newPasswordInput.valid && !newPasswordInput.pristine"
                    align="end"
                    [class.error]="true"
                  >
                    <span *ngIf="newPasswordInput.hasError('minlength')"
                      >At least {{ minPasswordLength }} characters required</span
                    >
                  </mat-hint>
                </mat-form-field>
                <mat-form-field
                  class="change-pass-input value-field ch-form-field ch-text-field ch-hint-input"
                  appearance="outline"
                  [class.input-error]="
                    !repeatNewPasswordInput.valid && !repeatNewPasswordInput.pristine
                  "
                >
                  <input
                    matInput
                    #repeatNewPasswordInput="ngModel"
                    name="repeatNewPasswordInput"
                    type="password"
                    [(ngModel)]="repeatNewPassword"
                    placeholder="Repeat new password"
                    required
                    [minlength]="minPasswordLength"
                    maxlength="{{ maxPasswordLength }}"
                    pattern="{{ passwordPattern }}"
                    (input)="onInputPassword($event)"
                    (keydown.enter)="confirmPasswordChange()"
                  />
                  <mat-hint
                    *ngIf="!repeatNewPasswordInput.valid && !repeatNewPasswordInput.pristine"
                    align="end"
                    [class.error]="true"
                  >
                    <span *ngIf="repeatNewPasswordInput.hasError('minlength')"
                      >At least {{ minPasswordLength }} characters required</span
                    >
                  </mat-hint>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td class="ch-clr-warn">
                <span *ngIf="newPasswordInput.hasError('pattern')">
                  Typed password does not meet the required criteria of security.<br />
                </span>
                <span
                  *ngIf="
                    changePasswordForm.hasError('chEqualFields') &&
                    !newPasswordInput.pristine &&
                    !repeatNewPasswordInput.pristine
                  "
                >
                  New password and repeated password do not match.<br />
                </span>
              </td>
            </tr>
            <tr *ngIf="errors" class="ch-clr-warn">
              <td>
                <p class="password-change-error" *ngFor="let err of errors">{{ err }}</p>
              </td>
            </tr>
            <tr>
              <td class="ch-sign-out-submit-row">
                <button
                  class="cancel-button ch-outline-btn"
                  [class.ch-synthia-lite-cancel-button]="isSynthiaLiteEnabled"
                  mat-button
                  [buttonOutline]="'primary'"
                  id="sign-out-button"
                  (click)="authService.logout(); resetInputs()"
                  [disabled]="pendingPasswordChange"
                >
                  Sign Out
                </button>
                <button
                  mat-raised-button
                  class="ch-sign-in-submit-bt"
                  [class.ch-synthia-lite-button]="isSynthiaLiteEnabled"
                  color="primary"
                  id="confirm-pwd-change-btn"
                  (click)="confirmPasswordChange()"
                  [disabled]="
                    !newPasswordInput.valid ||
                    !repeatNewPasswordInput.valid ||
                    pendingPasswordChange ||
                    newPassword !== repeatNewPassword
                  "
                >
                  Change Password & Sign In
                </button>
              </td>
            </tr>
          </table>
        </form>

        <div class="error-invalid-token">
          <ng-container *ngIf="invalidToken && paramsToken && !urlConfirmation">
            <p *ngFor="let err of errors" class="ch-clr-warn password-change-error">{{ err }}</p>
          </ng-container>
        </div>
        <button
          mat-button
          *ngIf="
            multifactorAuthorization &&
            !urlConfirmation &&
            paramsToken &&
            !isShowConfirmationCodeView
          "
          class="ch-sign-in-back-button sign-in-links"
          (click)="returnToFirstStep()"
        >
          Back to sign in page
        </button>

        <ng-template [ngIf]="urlConfirmation">
          <br />
          <mat-spinner [strokeWidth]="2" [diameter]="50" class="ch-sign-in-spinner"> </mat-spinner>
          <p align="center">Please wait...</p>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <ch-footer class="relative-footer"></ch-footer>
  </mat-grid-list>
</div>
<ch-copyright></ch-copyright>

<ng-template #registerButtonSection>
  <div class="register-section" *ngIf="!isSynthiaLiteEnabled">
    {{ isChinaRegion ? '尚未注册？' : 'Not registered yet?' }}

    <button class="register-btn" mat-flat-button (click)="onRegister()">
      {{ isChinaRegion ? '请点击这里' : 'Register now' }}
    </button>
  </div>
  <div class="register-section synthia-lite-register" *ngIf="isSynthiaLiteEnabled">
    {{ isChinaRegion ? '尚未注册？' : "Don't have an account?" }}
    <button class="register-btn" mat-flat-button (click)="onRegister()">
      {{ isChinaRegion ? '请点击这里' : 'Register now' }}
    </button>
    {{ 'to instantly unlock your free molecules' }}
  </div>
</ng-template>
