<div
  [class.ch-main-bg]="!isSynthiaLiteEnabled"
  [class.ch-synthia-lite-main-bg]="isSynthiaLiteEnabled"
  [class.synthia-lite-align]="isSynthiaLiteEnabled"
>
  <ch-unauthorized-view-header></ch-unauthorized-view-header>
  <div class="ch-logo-panel-title" *ngIf="!isSynthiaLiteEnabled" [innerHtml]="logoText"></div>
  <mat-card class="ch-password-reset-card">
    <mat-card-content>
      <mat-progress-bar
        class="ch-password-reset-progress"
        mode="indeterminate"
        color="primary"
        *ngIf="pendingApiCall"
      ></mat-progress-bar>

      <form
        *ngIf="!success"
        (ngSubmit)="resetPassword()"
        #passwordResetForm="ngForm"
        name="passwordResetForm"
      >
        <table class="ch-password-reset-layout">
          <tr>
            <td>
              <ng-container *ngTemplateOutlet="title"></ng-container>
            </td>
          </tr>
          <tr>
            <td>
              <p class="input-label">
                {{
                  isChinaRegion
                    ? '输入您的邮件地址，我们将会发送一个链接用以重置密码。'
                    : 'Enter your email address to receive a link for resetting your password.'
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field
                class="email-field value-field ch-form-field ch-text-field ch-hint-input"
                appearance="outline"
                [class.input-error]="(!emailInput.valid || emailFieldError) && !emailInput.pristine"
              >
                <input
                  matInput
                  ch-autofocus
                  name="emailInput"
                  #emailInput="ngModel"
                  [(ngModel)]="email"
                  type="email"
                  [placeholder]="isChinaRegion ? '邮箱' : 'Email'"
                  [maxlength]="256"
                  [pattern]="minimalEmailRegEx"
                  autocomplete="off"
                  required
                  email="true"
                  (keydown.enter)="resetPassword()"
                />

                <mat-hint *ngIf="emailFieldErrors.length === 0" align="end">
                  {{
                    isChinaRegion
                      ? '使用与您登记资料一致的邮件地址'
                      : 'Use the same email address as the one in your user profile'
                  }}
                </mat-hint>
                <mat-hint *ngIf="emailFieldErrors.length > 0" align="end" class="ch-clr-warn">
                  <span *ngFor="let error of emailFieldErrors">
                    {{ error }}
                  </span>
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td class="ch-clr-warn">
              <span *ngIf="apiCallError">
                {{ apiCallError }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="ch-password-reset-submit-row">
              <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
              <button
                mat-raised-button
                class="ch-password-reset-submit-bt"
                [class.ch-synthia-lite-button]="isSynthiaLiteEnabled"
                id="submit-button"
                [disabled]="!passwordResetForm.valid || !emailInput.valid || pendingApiCall"
                [color]="'primary'"
              >
                <span>{{ isChinaRegion ? '请求重置' : 'Request Reset' }}</span>
              </button>
            </td>
          </tr>
        </table>
      </form>

      <div *ngIf="success">
        <table class="ch-password-reset-layout">
          <tr>
            <td>
              <ng-container *ngTemplateOutlet="title"></ng-container>
            </td>
          </tr>
          <tr>
            <td>
              <p>An email is headed your way.</p>
              <p>
                We have sent a link to <strong>{{ email }}</strong> that you can use to reset your
                password. The link is valid for 15 minutes.
              </p>
              <p>
                Didn't get the email? Try checking your spam folder. If the problem persists,
                contact us.
              </p>
            </td>
          </tr>
          <tr>
            <td class="ch-password-reset-submit-row">
              <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
              <button
                mat-raised-button
                class="ch-password-reset-submit-bt"
                (click)="openNextUrl()"
                color="primary"
              >
                <span>Ok</span>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
  <ch-copyright></ch-copyright>
</div>

<ng-template #cancelButton>
  <button
    mat-button
    type="button"
    class="cancel-button ch-outline-btn"
    [buttonOutline]="'primary'"
    [routerLink]="['/']"
    [class.ch-synthia-lite-cancel-button]="isSynthiaLiteEnabled"
  >
    <span> {{ isChinaRegion ? '取消' : 'Cancel' }}</span>
  </button>
</ng-template>

<ng-template #title>
  <h3 class="title" align="center">
    {{ isChinaRegion ? '重置密码' : 'Reset your password' }}
  </h3>
</ng-template>
