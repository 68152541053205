import { isString } from '../../../components/utils';

export enum KnownReactionBases {
  EXPERT = 'expert',
  HETEROCYCLES = 'heterocycles',
  ARENES = 'arenes',
  SPECIALIZED = 'nonstereo',
  PUBLISHED = 'PUBLISHED_REACTIONS',
  SPRESI = 'spresi',
}

export class GraphReactionNodeEntry {
  /* tslint:disable:variable-name */
  public id: number = 0;
  public analysis: number = 0;
  public base: string = '';
  public child_molecule_masses_per_gram_of_target: any = {};
  public child_molecule_scores: any = {};
  public parent_molecule: string = '';
  public parent_reaction_node: number | null = 0;
  public paths: number[] = [];
  public reaction: string = '';
  public dynamic_strategies: number[] = [];
  public strategies: number[] = [];
  public tunnels: number[] = [];
  public non_selective: null | boolean = null;
  public diastereoselective: null | boolean = null;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const doDefault = (field) => {
      if (typeof jsonData[field] === typeof this[field]) {
        this[field] = jsonData[field];
      } else {
        // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
        console.error(
          `Unexpected type of field '${field}' in data provided to GraphReactionNodeEntry:ctor.` +
            `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
        );
      }
    };

    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        switch (field) {
          case 'non_selective':
          case 'diastereoselective':
            // in case of 'non_selective' field we are currently forced into using null as an indicator
            // that `Mark non-selective` option was not selected during the analysis configuration.
            // If that field is of a boolean type, we can be certain that this option was selected during config.
            // Fun story, chemical workers are sending this information not as a regular field, but as a color
            // which should be used for appropriate nodes' color (in that case the information about any 'rules'
            // chosen is lost).
            if (typeof jsonData[field] === 'boolean' || jsonData[field] === null) {
              this[field] = jsonData[field];
            }
            break;
          case 'parent_reaction_node':
            if (jsonData[field] === null) {
              this[field] = null; // Use null for preTarget reaction nodes
            } else {
              doDefault(field);
            }
            break;
          default:
            doDefault(field);
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to GraphReactionNodeEntry:ctor.`,
        );
      }
    }
  }

  public isDynamicStrategyInPath(pathId: number): boolean {
    return this.dynamic_strategies.includes(pathId);
  }

  public isDynamicStrategyInGraph(): boolean {
    return this.dynamic_strategies.length > 0;
  }

  public isStrategyInPath(pathId: number): boolean {
    return this.strategies.includes(pathId);
  }

  public isStrategyInGraph(): boolean {
    return this.strategies.length > 0;
  }

  public isTunnelInPath(pathId: number): boolean {
    return this.tunnels.includes(pathId);
  }

  public isTunnelInGraph(): boolean {
    return this.tunnels.length > 0;
  }
}
