import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalysisComment } from '../../services/models/comment';
import { AnalysisService } from '../../services/analysis';
import { Subject, Subscription } from 'rxjs';
import { ErrorsHandlerService } from '../../services/errors-handler/errors-handler.service';
import { InfoService } from '../../services/info.service';
import { ParsedConnectionError } from '../../services/errors-handler/errors-handler.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/authorization';
import { UserEntry } from 'src/app/authorization/models/user-entry';
import { formatDate } from '@angular/common';
import { colorPallate } from '../utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ch-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent implements OnInit, OnDestroy {
  private static handleLocalErrors(parsedError: ParsedConnectionError): boolean {
    return false;
  }

  public analysis: any;
  public commentList: AnalysisComment[];
  public commentFormControl: UntypedFormControl;
  public unreadCount: string = '';
  public userEmail: string = '';
  public userName: string = '';
  @ViewChild('comment') commentElement: ElementRef;
  @ViewChild('commentForm') private commentForm: ElementRef;
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;

  public toggle: boolean = false;
  public text: string = '';
  public firstUnreadIndex: number = -1;
  public isCommentFocus: boolean = false;

  private getCommentSubscription: Subscription;
  private postingComment: boolean = false;
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private lastCursorPosition: number = 0;
  private commentsAuthorMap: { [key: string]: string } = {};
  private colorPallateIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    private analysisService: AnalysisService,
    private infoService: InfoService,
    private errorsHandler: ErrorsHandlerService,
    private authorizationService: AuthorizationService,
    private sanitizer: DomSanitizer,
  ) {}

  public ngOnInit() {
    this.commentFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(1024),
    ]);
    this.getAnalysisComments();
    this.getUserDetail();
    this.dialogRef.afterClosed().subscribe(() => {
      this.onDialogClose();
    });
  }

  public scrollToBottom() {
    setTimeout(() => {
      if (this.commentElement) {
        const element = this.commentElement.nativeElement;
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 50);
  }

  public isCommentValid() {
    return this.commentFormControl.valid;
  }

  public ngOnDestroy() {
    this.getCommentSubscription.unsubscribe();
    this.unsubscriberSubject.next();
  }

  public getAnalysisComments() {
    this.getCommentSubscription = this.analysisService
      .getAnalysisComments(this.analysis.id)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((comments: AnalysisComment[]) => {
        this.commentList = comments.reverse();
        const unreadCommentsCount = this.commentList.filter((comment) => comment.unread === true)
          .length;
        this.unreadCount = unreadCommentsCount > 0 ? `(${unreadCommentsCount})` : '';
        this.firstUnreadIndex = this.commentList.findIndex((comment) => comment.unread === true);
        const commentCount = {
          total: this.commentList.length,
          unread: 0,
        };
        this.setProfileIcon(this.commentList);
        this.analysisService.analysisSettingsBehaviorSubjects.commentsCount.next(commentCount);
        this.scrollToBottom();
      });
  }

  public saveComment(event: MouseEvent) {
    if (this.isCommentValid() && !this.postingComment) {
      this.toggle = false;
      this.postingComment = true;
      this.analysisService
        .commentAnalysis(this.analysis.id, this.commentFormControl.value)
        .pipe(
          takeUntil(this.unsubscriberSubject),
          finalize(() => (this.postingComment = false)),
        )
        .subscribe(
          (comment) => {
            this.commentList.push(new AnalysisComment(comment.body));
            const commentCount = {
              total: this.commentList.length,
              unread: 0,
            };
            this.analysisService.analysisSettingsBehaviorSubjects.commentsCount.next(commentCount);
            this.commentFormControl.reset();
            this.infoService.showInfo('Comment added to analysis.');
            this.scrollToBottom();
          },
          (error) => {
            this.commentCallError(error);
          },
        );
    }
    event.preventDefault();
  }

  public handleEmoji(event: any) {
    const emoji = event.emoji.native;
    const cursorPosition = this.textarea.nativeElement.selectionStart || 0;
    const textBeforeCursor = this.textarea.nativeElement.value.substring(0, cursorPosition);
    const textAfterCursor = this.textarea.nativeElement.value.substring(cursorPosition);
    const newText = textBeforeCursor + emoji + textAfterCursor;

    this.textarea.nativeElement.value = newText;
    this.lastCursorPosition = cursorPosition + emoji.length;
    this.textarea.nativeElement.focus();
    this.textarea.nativeElement.setSelectionRange(this.lastCursorPosition, this.lastCursorPosition);
    this.commentFormControl.setValue(newText);
  }

  public toggleMart(event: MouseEvent) {
    event.stopPropagation();
    this.toggle = !this.toggle;
  }

  public onFocus() {
    this.firstUnreadIndex = -1;
    this.unreadCount = '';
    this.isCommentFocus = true;
  }

  public onDialogClose() {
    this.dialogRef.close(this.commentList.length);
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    if (
      !this.commentForm.nativeElement.contains(event.target) &&
      event.target !== this.commentForm.nativeElement
    ) {
      this.toggle = false;
      this.isCommentFocus = false;
    }
  }

  public formattedDate(date: string) {
    return formatDate(date, 'MMM dd yyyy h:mm:ss a', 'en-US');
  }

  public getAuthorName(comment: AnalysisComment) {
    if (!!comment.author.trim()) {
      return comment.author;
    }
    return comment.username;
  }

  public setAuthorImg(comment: AnalysisComment) {
    if (!!comment.author.trim()) {
      const splitName = comment.author.split(' ');
      const profileName =
        splitName[0].charAt(0).toUpperCase() + splitName[1]?.charAt(0).toUpperCase();
      return profileName;
    } else {
      return this.userName.charAt(0).toUpperCase() + this.userName.charAt(1).toUpperCase();
    }
  }

  public setContent(content: string) {
    const regex = this.emojiRegex();
    let emojiCount = 0;
    const modifiedContent = content.replace(regex, (match) => {
      emojiCount++;
      return `<span class="emoji">${match}</span>`;
    });
    const containsOnlyEmojis = emojiCount > 0 && content.replace(regex, '').trim().length === 0;
    let finalContent = modifiedContent;
    if (containsOnlyEmojis && emojiCount < 6) {
      finalContent = modifiedContent.replace(
        /<span class="emoji">/g,
        '<span class="emoji" style="font-size: 45px;">',
      );
    } else {
      finalContent = modifiedContent.replace(
        /<span class="emoji">/g,
        '<span class="emoji" style="font-size: 25px; vertical-align: middle;">',
      );
    }
    return this.sanitizer.bypassSecurityTrustHtml(finalContent);
  }

  private emojiRegex(): RegExp {
    return /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]/gu;
  }

  private commentCallError(error: any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
    } else if (parsedError.isRecognized()) {
      if (!CommentDialogComponent.handleLocalErrors(parsedError)) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage);
      }
    }
  }

  private getUserDetail() {
    this.authorizationService
      .getUserInformation()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((user: UserEntry) => {
        this.userEmail = user.email;
        this.userName = user.username;
      });
  }

  private setProfileIcon(commentList: AnalysisComment[]) {
    const valueMap: { [key: string]: string } = {};
    commentList.forEach((comments: AnalysisComment) => {
      if (!valueMap[comments.username]) {
        valueMap[comments.username] = colorPallate[this.colorPallateIndex];
        this.colorPallateIndex = (this.colorPallateIndex + 1) % colorPallate.length;
      }
    });
    this.commentsAuthorMap = valueMap;
  }
}
