import { isString } from '../../../components/utils';
import { PathwaySubstrates } from './pathway-substrates';
import { TargetSubstrate } from './target-substrate';

export class PathwayBuildingBlocks {
  /* tslint:disable:variable-name */
  public substrates: PathwaySubstrates;
  public target_svg: TargetSubstrate;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
          console.error(
            `Unexpected type of field '${field}' in data provided to PathwayBuildingBlocks:ctor.` +
              `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
          );
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to PathwayBuildingBlocks:ctor.`,
        );
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
