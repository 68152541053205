<div class="logo-container" [class.synthia-lite-container]="isSynthiaLiteEnabled">
  <div
    class="logo-image"
    synthiaLogo
    [logoColor]="logoColor"
    [logoCountry]="appStateService.country | async"
    (click)="onLogoClickEvent()"
  ></div>
  <span
    class="logo-text"
    *ngIf="isSynthiaLiteEnabled"
    [innerHtml]="appConstantsService.synthiaLiteLogoText"
  ></span>
</div>
