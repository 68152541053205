import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../authorization.service';
import { PendoAnalyticsService } from '../../shared/services/analytics/pendo-analytics.service';
import { AppState } from 'src/app/app-state.service';

// TODO: Possible solution of the problem of missing token invalidation in JWT.
//       Implement a way to report "logged-out" but still not expired tokens. As it is enough to compare tokens
//       (no need to understand the content) a list of invalidated tokens can be implemented in a fast nginx filter
//       which will work on Authorization header and return 401 without even sending the request to the backend if it
//       find request's token on a list. Assuming that we will not release tokens valid for a very long time, the max
//       time of keeping invalidated tokens will be reasonably short, and the lookup table will reamin small and fast.
//       We can skip expired tokens as the backend know how to deal with them. Therefore nginx prefiltering can focus
//       on potentially not expired tokens only.

// NOTE: No one will see this component as it signs the user out and redirects to 'next' url just after initialization.
//       We can implement similar functionality with a directive or as a CanActivate guard but we use a component
//       to make sure that after a redirection to SignOutComponent the router will need to reload any other 'next'
//       component which will force it to recalculate CanActivate guards of authorization and prevent loading
//       secret content for not authorized user.
//       On the other hand we can display here "You are logged out" message some day if we decide to.
@Component({
  selector: 'ch-sign-out',
  styles: [],
  template: '<h1>You have been signed out.</h1>',
})
export class SignOutComponent implements OnInit, AfterContentInit, OnDestroy {
  private nextUrl: string;
  private unsubscribeFromAll: Subject<void> = new Subject();

  constructor(
    private authService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
    private pendoAnalyticsService: PendoAnalyticsService,
    private appStateService: AppState,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  public ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        map((params) => params['next']),
      )
      .subscribe((next) => {
        // For missing next parameter resolve to 'dashboard'
        this.nextUrl = next ? next : '';
      });
  }

  public ngAfterContentInit() {
    localStorage.removeItem('isSSOMode');
    this.authService.logout();
    if (
      !!this.appStateService.midendConfiguration.value &&
      this.appStateService.midendConfiguration.value.PENDO_ANALYTICS_ENABLED
    ) {
      this.pendoAnalyticsService.identify();
    }
    this.router.navigateByUrl(this.nextUrl, { replaceUrl: true });
  }
}
