import { isString } from '../../components/utils';
import {
  MoleculeScalingType,
  MoleculeLabelType,
  ReactionLabelType,
  ReactionColorType,
  GraphModeType,
  assertLayoutType,
  assertGraphModeType,
  assertMoleculeLabelType,
  assertMoleculeScalingType,
  assertPathDirectionType,
  assertReactionColorType,
  assertReactionLabelType,
  EdgeColorType,
  assertEdgeColorType,
} from '../frontend-storage/models/unions';
import { GraphLayout, PathDirection } from '../analysis/analysis.service';

export class AnalysisSettings {
  public modeIsDefault: boolean = true;
  public layoutIsDefault: boolean = true;
  public mode: GraphModeType = 'graph';
  public layout: GraphLayout = GraphLayout.FORCE;
  public moleculeScaling: MoleculeScalingType = 'none';
  public colorReactionDiamonds: ReactionColorType = 'default';
  public colorReactionArrows: EdgeColorType = 'nonSelectiveAndDiastereoselectiveOnly';
  public moleculeLabel: MoleculeLabelType = 'none';
  public reactionLabel: ReactionLabelType = 'none';
  public nodeTypeIcons: boolean = false;
  public shopAvailability: boolean = true;
  public colorByReactivity: boolean = false;
  public pathDirection: PathDirection = PathDirection.LeftToRight;
  public filters: any = {
    selectedOnly: false,
    groupByFamily: 100,
    minimumReactions: 1,
    maximumReactions: 0,
    anyMaximumReactions: true,
    conflicts: 'any',
    protections: 'any',
    regulated: 'any',
    pathScoreMin: 0,
    pathScoreMax: 1000,
    pathScoreMaxInfinite: true,
  };

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field of Object.keys(jsonData)) {
      let isFieldCorrect: boolean;
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          isFieldCorrect = true;
          switch (field) {
            case 'reactionLabel':
              isFieldCorrect = assertReactionLabelType(jsonData[field]);
              break;
            case 'moleculeLabel':
              isFieldCorrect = assertMoleculeLabelType(jsonData[field]);
              break;
            case 'pathDirection':
              isFieldCorrect = assertPathDirectionType(jsonData[field]);
              break;
            case 'colorReactionDiamonds':
              isFieldCorrect = assertReactionColorType(jsonData[field]);
              break;
            case 'colorReactionArrows':
              isFieldCorrect = assertEdgeColorType(jsonData[field]);
              break;
            case 'moleculeScaling':
              isFieldCorrect = assertMoleculeScalingType(jsonData[field]);
              break;
            case 'layout':
              isFieldCorrect = assertLayoutType(jsonData[field]);
              break;
            case 'mode':
              isFieldCorrect = assertGraphModeType(jsonData[field]);
              break;
            case 'filters':
              for (const filter of Object.keys(jsonData[field])) {
                if (typeof filter === typeof this.filters[filter]) {
                  this.filters[filter] = jsonData[field][filter];
                }
              }
              break;
            default:
              break;
          }
          if (isFieldCorrect) {
            this[field] = jsonData[field];
          } else {
            this.logWarning(field);
          }
        } else {
          this.logWarning(field);
        }
      } else {
        console.warn(`Unexpected field '${field}' in data provided to AnalysisSettings:ctor.`);
      }
    }
  }

  private logWarning(field: string) {
    console.warn(
      `Unexpected type of field '${field}' in data provided to AnalysisSettings:ctor.` +
        `\nUsed default value instead for `,
      this[field],
    );
  }
}
