<div class="ch-tag-dialog">
  <div class="ch-dialog-top">
    <span
      [matTooltip]="analysis.name"
      matTooltipClass="ch-tooltip full-width-tooltip"
      *ngIf="analysis && analysis.name; else overallTag"
    >
      Tags for {{ truncateName(analysis.name) }}
    </span>
    <ng-template #overallTag>Tags Management</ng-template>
    <span>
      <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
    </span>
  </div>
  <div class="ch-dialog-body">
    <div class="tag ch-bdr-grey-light new-ui-tag">
      <mat-form-field appearance="outline" class="ch-add-tag tag-input">
        <span matPrefix>#</span>
        <input
          [(ngModel)]="tagInput"
          id="addTag"
          (keyup)="showFilteredTags($event.target.value)"
          (keyup.enter)="addAnalysisTag()"
          type="text"
          matInput
          class="input-add-tag"
          placeholder=" Add a single tag and press Enter or click on Add button"
          [matAutocomplete]="addTag"
        />
        <mat-autocomplete autoActiveFirstOption #addTag="matAutocomplete">
          <mat-option
            *ngFor="let tag of filteredTags"
            [value]="tag.name"
            (click)="setTagOption(tag)"
          >
            {{ tag.name }}
          </mat-option>
        </mat-autocomplete>
        <button
          mat-raised-button
          class="button-add-tag"
          color="primary"
          [disabled]="!isAddEnabled"
          (click)="addAnalysisTag()"
        >
          Add
        </button>
      </mat-form-field>
    </div>
    <div class="ch-tag-analysis">
      <mat-spinner
        *ngIf="isTagLoading"
        [strokeWidth]="3"
        [diameter]="50"
        class="tag-spinner"
      ></mat-spinner>
      <mat-chip-list>
        <ng-template ngFor let-tag [ngForOf]="analysisTags" let-i="index">
          <div class="tag-edit-container">
            <mat-chip
              [removable]="true"
              (removed)="deleteTag(tag)"
              matTooltipClass="ch-tooltip full-width-tooltip"
              matTooltipPosition="above"
              [matTooltip]="appConstantsService.getAnalysisTagTooltip(tag.name, tagMaxLength)"
            >
              #{{ truncateName(tag.name) }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <button mat-icon-button (click)="editAnalysisTag(tag, i)" class="tag-edit-button">
              <mat-icon class="tag-edit-icon" svgIcon="ch:edit"> </mat-icon>
            </button>
            <div *ngIf="tag.id == editedTagId && isEditEnabled" class="edit-tag-container">
              <div class="edit-tag-name">
                <mat-form-field class="value-tag-field">
                  <input
                    matInput
                    [(ngModel)]="editedTagName"
                    (keydown)="handleKeyEvents($event)"
                    ch-select-on-autofocus
                  />
                </mat-form-field>
                <div class="button-group">
                  <button
                    mat-icon-button
                    class="ch-action-icon-hover"
                    (click)="saveEditedTag()"
                    [disabled]="!editedTagName"
                  >
                    <mat-icon
                      class="action-icon grey-icon"
                      matTooltip="Save"
                      matTooltipPosition="above"
                      matTooltipClass="ch-tooltip"
                      svgIcon="ch:save-outline"
                    ></mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    class="ch-action-icon-hover"
                    (click)="closeEditedTag(tag)"
                  >
                    <mat-icon
                      class="action-icon grey-icon"
                      matTooltip="Cancel"
                      matTooltipPosition="above"
                      matTooltipClass="ch-tooltip"
                    >
                      clear
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-chip-list>
    </div>
  </div>
  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls">
      <button
        cdkFocusInitial
        mat-button
        class="discard-btn"
        buttonOutline="'primary'"
        (click)="dialogRef.close()"
      >
        {{ !isSaveEnabled ? 'CANCEL' : 'DISCARD CHANGES' }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="saveAnalysisTags()"
        [disabled]="!isSaveEnabled"
      >
        SAVE
      </button>
    </div>
  </div>
</div>
