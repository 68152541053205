<div
  class="ch-authorized-image-wrapper"
  [style.width.px]="width"
  [style.height.px]="height"
  [style.min-width.px]="minWidth"
  [style.min-height.px]="minHeight"
  [style.max-width.px]="maxWidth"
  [style.max-height.px]="maxHeight"
>
  <img class="ch-authorized-image" *ngIf="!loading && !error" [src]="imageObjectUrl" />

  <div *ngIf="loading" class="ch-authorized-image-loading">
    <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
  </div>

  <div *ngIf="error" class="ch-authorized-image-error ch-clr-grey-strong ch-bg-grey-light">
    <table width="100%">
      <tr align="center">
        <td>
          <mat-icon>error_outline</mat-icon>
        </td>
      </tr>
      <tr align="center">
        <td>
          <span class="ch-authorized-image-error-message ch-clr-grey-strong">{{
            errorMessage
          }}</span>
        </td>
      </tr>
    </table>
  </div>
</div>
