<div
  class="ch-molecule-image-wrapper"
  #imageWrapper
  ch-viewport-visibility
  (visibilityChange)="onVisibilityChange($event.value)"
  [style.min-width.px]="minWidth"
  [style.height.px]="height"
  (click)="dispatchNodeEvent()"
>
  <div *ngIf="loading && !hideSpinner" class="ch-molecule-image-loading noprint">
    <mat-spinner [strokeWidth]="2" [diameter]="50"></mat-spinner>
  </div>
  <div
    *ngIf="error"
    class="ch-molecule-image-error ch-bg-grey-light"
    [ngStyle]="{ width: width + 'px', height: height + 'px' }"
  >
    <span class="ch-clr-grey-strong">
      <mat-icon>error_outline</mat-icon><br />
      {{ errorMessage }}
    </span>
  </div>
</div>
