import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app-state.service';

import {
  ParametersStashService,
  AppConstantsService,
  ChematicaRouteStateService,
} from '../../services';

@Component({
  selector: 'ch-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public isSynthiaLiteEnabled: boolean = false;
  public isChinaRegion: boolean = APP_CONFIG.CHINA_REGION_INSTANCE;
  public aboutLink: string = this.appConstantsService.ABOUT_CHEMATICA_LINK;

  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(
    public stashService: ParametersStashService,
    public appConstantsService: AppConstantsService,
    public routeStateService: ChematicaRouteStateService,
    private appStateService: AppState,
  ) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;

    this.appStateService.country
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((countryCode) => {
        const isChinaUser: boolean = countryCode === 'CN';
        this.aboutLink = isChinaUser
          ? this.appConstantsService.ABOUT_CHEMATICA_LINK_CHINA
          : this.appConstantsService.ABOUT_CHEMATICA_LINK;
      });
  }
}
