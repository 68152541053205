import { trigger, style, transition, animate, keyframes } from '@angular/animations';

export const FADE_IN_ANIMATION = trigger('fadeInAnimation', [
  transition('void => *', [
    style({ opacity: '0' }),
    animate(
      '0.18s',
      keyframes([
        style({ opacity: '0' }),
        style({ opacity: '0' }),
        style({ opacity: '0.3' }),
        style({ opacity: '0.67' }),
        style({ opacity: '1' }),
      ]),
    ),
  ]),
]);
