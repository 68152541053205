import { isString } from '../../../components/utils';

/*
 Wrapps a "bond strain" from the list returned by v1/molecular-strain/report entry point on backend.
 */
export class BondStrain {
  /* tslint:disable:variable-name */
  public deviation_limit: number = 0;
  public energy_limit: number = 0;
  public length_deviation: number = 0;
  public atoms: number[] = [];
  public bond_length: number = 0;
  public strain_energy: number = 0;
  public natural_bond_length: number = 0;
  public length_above_threshold: boolean = false;
  public energy_above_threshold: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        this[field] = jsonData[field];
      } else {
        console.error(`Unexpeced field '${field}' in data provided to BondStrain:ctor.`);
      }
    }
  }
}
