import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConstantsService, ParametersStashService } from '../../services';
import { AppState } from '../../../app-state.service';

@Component({
  selector: 'ch-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnInit, OnDestroy {
  public isMilliporeSigmaCountry: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    public appConstantsService: AppConstantsService,
    private appStateService: AppState,
    private stashService: ParametersStashService,
  ) {}

  public ngOnInit(): void {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.appStateService.country.pipe(takeUntil(this.unsubscribeFromAll)).subscribe(() => {
      this.isMilliporeSigmaCountry = this.appStateService.isMilliporeSigmaCountry();
    });
  }

  public ngOnDestroy() {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }
}
