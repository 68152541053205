import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NodeEventType, INodeEvent } from './models/node-event';
import { GraphReactionNode, GraphMoleculeNode } from './graph-builder';
import { NewClipboardItemComponent } from '../components/nodes-clipboard/new-clipboard-item/new-clipboard-item.component';
import { tap } from 'rxjs/operators';
import { InfoService } from './info.service';
import { autodownload, isNullOrUndefined } from '../components/utils';

@Injectable()
export class NodesClipboardService {
  constructor(private infoService: InfoService) {}

  /* tslint:disable:member-ordering*/
  private pinnedStatusRequestsQueue = new Subject<GraphMoleculeNode | GraphReactionNode>();
  private pinningRequestsQueue = new Subject<GraphMoleculeNode | GraphReactionNode>();
  private pointingRequestsQueue = new Subject<object>();
  private clearRequestsQueue = new Subject<null>();
  private nodeEventsQueue = new Subject<INodeEvent>();

  // Queues observables for clipboard <-> node-details-view communication.
  public pinnedStatusRequests = this.pinnedStatusRequestsQueue.asObservable();
  public pinningRequests = this.pinningRequestsQueue.asObservable();
  public pointingRequests = this.pointingRequestsQueue.asObservable();
  public clearRequests = this.clearRequestsQueue.asObservable();
  public selectedMoleculeNodes: BehaviorSubject<
    GraphMoleculeNode | GraphReactionNode[]
  > = new BehaviorSubject<GraphMoleculeNode | GraphReactionNode[]>([]);
  public selectedReactionNodes: BehaviorSubject<GraphReactionNode[]> = new BehaviorSubject<
    GraphReactionNode[]
  >([]);

  // Subscribe to receive events sent from nodes
  public nodeEvents = this.nodeEventsQueue.asObservable();
  /* tslint:enable:member-ordering */

  // Methods dedicated to communication between clipboard and graph-node-popup or clipboard-item
  public requestPinnedStatus(node: GraphMoleculeNode | GraphReactionNode) {
    this.pinnedStatusRequestsQueue.next(node);
  }

  public requestPinning(node: GraphMoleculeNode | GraphReactionNode) {
    this.pinningRequestsQueue.next(node);
  }

  public requestPointing(node: object) {
    this.pointingRequestsQueue.next(node);
  }

  public dispatchNodeEvent(
    node: GraphMoleculeNode | GraphReactionNode,
    eventType: NodeEventType,
    moleculeIdPriority: boolean = false,
  ) {
    this.nodeEventsQueue.next({ node, eventType, moleculeIdPriority });
  }

  // Methods to be used by clipboard clients
  public pointNode(node: object) {
    this.requestPointing(node);
  }

  public addNode(node: GraphReactionNode | GraphMoleculeNode) {
    this.requestPinning(node);
  }

  public clear() {
    this.clearRequestsQueue.next(null);
  }

  public export(nodesToExport: any) {
    this.getFile(nodesToExport, `Synthia clipboard.csv`);
  }

  public getCsvContent(nodesToExport): Observable<String> {
    const headers = [
      'Type',
      'Name',
      'SMILES',
      'Cost from Synthia($/g)',
      'Brand/Vendor Name',
      'Product link',
    ];
    const reaction_data = [];
    const molecule_data = [];

    nodesToExport.forEach((item: NewClipboardItemComponent) => {
      if (item.isReaction) {
        const reaction = item.reactionDetails;
        const data = ['Reaction', `\"${reaction.name}\"`, `\"${reaction.smiles}\"`];

        reaction_data.push(data.join(','));
      } else {
        const molecule = item.moleculeDetails;
        let shopLink = '';
        if (
          !isNullOrUndefined(item) &&
          !isNullOrUndefined(item.shopLinks) &&
          item.shopLinks.length > 0 &&
          'url' in item.shopLinks[0]
        ) {
          shopLink = item.shopLinks[0]['url'];
        }
        let vendor = '';
        if (
          !isNullOrUndefined(molecule) &&
          !isNullOrUndefined(molecule.vendors) &&
          molecule.vendors.length > 0 &&
          'vendor' in molecule.vendors[0]
        ) {
          vendor = molecule.vendors[0]['vendor'];
        }
        const data = [
          'Molecule',
          `\"${molecule.name}\"`,
          `\"${molecule.smiles}\"`,
          molecule.cost ? `\"${molecule.cost}\"` : '',
          `\"${vendor}\"`,
          `\"${shopLink}\"`,
        ];

        molecule_data.push(data.join(','));
      }
    });

    return of(
      headers.join(',') + '\n' + molecule_data.join('\n') + '\n' + reaction_data.join('\n'),
    );
  }

  public getFile(nodesToExport: any, fileName: string) {
    this.getCsvContent(nodesToExport)
      .pipe(
        tap(
          (csvContent) => {
            this.infoService.showInfo('Data collected, creating CSV...');
          },
          (error) => {
            this.infoService.showError('Data collecting fail. CSV export aborted');
          },
        ),
      )
      .subscribe(
        (csvDataBlob: any) => {
          const data = [];
          data.push(csvDataBlob);
          const csvDataUrl = window.URL.createObjectURL(new Blob(data));
          autodownload(csvDataUrl, fileName);
          return true;
        },
        (err) => {
          console.log(err);
        },
      );
  }
}
