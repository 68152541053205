<div class="ch-share-dialog">
  <div class="ch-dialog-top">
    <span>
      Share analysis
    </span>
    <span>
      <mat-icon (click)="dialogRef.close(analysis.shared_with.length)" class="icon-18">close</mat-icon>
    </span>
  </div>

  <div class="ch-dialog-body">
    <div class="share ch-bdr-grey-light new-ui-share">
      <mat-form-field class="share-input" floatLabel="always" appearance="outline">
        <mat-chip-list #userChipList>
          <mat-chip
            *ngFor="let user of selectedUsers; let i = index"
            class="ch-bg-primary-lighter"
            removable
            selectable
            (removed)="removeUser($event, i)"
          >
            <ng-container *ngIf="user.getFullName(); else emailChip">
              {{ user.getFullName() }}
            </ng-container>
            <ng-template #emailChip>
              {{ user.email }}
            </ng-template>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #userListInput
            type="text"
            matInput
            required
            autocomplete="off"
            name="share-with"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            (matChipInputTokenEnd)="onChipInputTokenEnd()"
            [matChipInputFor]="userChipList"
            [formControl]="shareWithFormControl"
            [matAutocomplete]="auto"
            (input)="onSearchChange($event.target.value)"
          />
        </mat-chip-list>
        <mat-label>Start typing name or e-mail address...</mat-label>
        <mat-error></mat-error>
        <mat-autocomplete
          #autoCompletePanel
          (optionSelected)="selectUser($event)"
          autoActiveFirstOption
          #auto="matAutocomplete"
        >
          <mat-option *ngFor="let user of filteredAssociatedUsers" [value]="user">
            <ng-container *ngIf="user.getFullName()"> "{{ user.getFullName() }}" </ng-container>
            <{{ user.email }}>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="shared-info">
      <div class="owner" *ngIf="analysis?.owner">
        <strong>Owner: {{ analysis.owner.getFullName() }}</strong> ({{ analysis.owner.email }})
      </div>
      <div class="access-list" *ngIf="analysis?.shared_with">
        <div
          *ngFor="let user of analysis.shared_with; trackBy: trackByForUserList"
          class="user ch-bdr-grey-steel ch-bg-grey-light"
          [@fadeOutAnimation]
        >
          <div class="user-info">
            <div class="name">{{ user.getFullName() }}</div>
            &nbsp; ({{ user.email }})
          </div>
          <span class="unshare">
            <button
              *ngIf="unsharingUser?.email !== user.email"
              class="unshare-btn"
              (click)="unshareAnalysis(user)"
              mat-button
            >
              <mat-icon matTooltip="Stop sharing" matTooltipShowDelay="1500">close</mat-icon>
            </button>
            <mat-spinner
              *ngIf="unsharingUser?.email === user.email"
              class="loading-unshare"
              [diameter]="25"
              [strokeWidth]="3"
            >
            </mat-spinner>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls">
      <button
        cdkFocusInitial
        mat-button
        class="ch-outline-btn"
        buttonOutline="primary"
        (click)="dialogRef.close(analysis.shared_with.length)"
      >
        CLOSE
      </button>
      <button
        mat-raised-button
        [disabled]="!isSharingPossible()"
        (click)="shareAnalysis()"
        color="primary"
      >
        <ng-container *ngIf="sharing">
          <mat-spinner class="ch-starting-spinner" [diameter]="20" [strokeWidth]="2"> </mat-spinner>
        </ng-container>
        {{ sharing ? 'SHARING...' : 'SHARE' }}
      </button>
    </div>
  </div>
</div>
