import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ScoringFunctionFilterComponent } from '../scoring-function-filter/scoring-function-filter.component';
import { ScoringFunction } from '../../services/scoring-functions/models/scoring-function';
import {
  ADVANCED_CSF_AVAILABLE_PARAMS,
  ADVANCED_RSF_AVAILABLE_PARAMS,
  AdvancedSFAvailableParam,
  ScoringFunctionCategoryType,
  FunctionType,
} from '../scoring-function-utils';

@Component({
  selector: 'ch-new-calculator',
  templateUrl: './new-calculator.component.html',
  styleUrls: ['./new-calculator.component.scss'],
})
export class NewCalculatorComponent implements OnInit, OnChanges {
  public readonly FunctionType = FunctionType;

  public originScoringFunction: ScoringFunction;
  public functionType: FunctionType;
  public scoringFunctionCached: string;
  public inputDisabled: boolean = false;
  public inputHistory: any[] = [];
  public stopSaving: boolean = false;
  public scoringFunctionName: string = '';
  public changesMade: boolean = false;
  public freezingSets: boolean = false;

  public scoringFunctionFormControl = new UntypedFormControl('', [Validators.required]);

  public templateScoringFunction: ScoringFunction = new ScoringFunction({
    id: 0,
    name: '',
    source_code: '',
    owner: 0,
    category: '',
    rpn_source_code: '',
  });

  public advancedRSFAvailableFunctions: AdvancedSFAvailableParam[] = ADVANCED_RSF_AVAILABLE_PARAMS;
  public advancedCSFAvailableFunctions: AdvancedSFAvailableParam[] = ADVANCED_CSF_AVAILABLE_PARAMS;

  @ViewChild('scoringFunctionTextarea')
  public scoringFunctionTextarea: ElementRef;
  @ViewChild('scoringFunctionField') public scoringFunctionField: any;
  @Input() public functionCategory: string;
  @Input() public tempScoringFunction: string = '';
  @Input() public scoringFunction: ScoringFunction;
  @Input() public moleculeSets: any[] = [];
  @Input() public smartsSets: any[] = [];
  @Input() public isAuthorizedToStartAnalysis: boolean = false;
  @Input() public isDisabledDialogSave: boolean = false;

  @Output() public freezeSets: EventEmitter<{
    setsToFreeze: number[];
    setsType: 'molecule' | 'smarts';
  }> = new EventEmitter<{ setsToFreeze: number[]; setsType: 'molecule' | 'smarts' }>();
  @Output() public openSaveDialogEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onInit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onDeleteScoringFunction: EventEmitter<{
    scoringFunction: ScoringFunction;
  }> = new EventEmitter<{ scoringFunction: ScoringFunction }>();

  @Output() public onScoringFunctionChange: EventEmitter<{
    scoringFunction: ScoringFunction;
  }> = new EventEmitter<{ scoringFunction: ScoringFunction }>();

  private filterDialogRef: MatDialogRef<ScoringFunctionFilterComponent>;
  private filterDialogConfig: MatDialogConfig = {
    disableClose: false,
    width: '450px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: '',
    },
    minHeight: '200px',
    maxHeight: '500px',
  };

  constructor(private filterDialog: MatDialog) {}

  public ngOnInit() {
    this.setInitialVariables();
    this.originScoringFunction = this.scoringFunction;
    this.onInit.emit();
    this.scoringFunctionFormControlChange(this.tempScoringFunction);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.scoringFunction) {
      this.originScoringFunction = changes.scoringFunction.currentValue;
    }
    if (changes.hasOwnProperty('tempScoringFunction')) {
      this.scoringFunctionFormControlChange(this.tempScoringFunction);
    }
  }

  public setInitialVariables() {
    switch (this.functionCategory) {
      case ScoringFunctionCategoryType.REACTION_SCORE:
      case ScoringFunctionCategoryType.AUTOMATIC_RETROSYNTHESIS_REACTION:
        this.functionType = FunctionType.Reaction;
        this.advancedRSFAvailableFunctions = ADVANCED_RSF_AVAILABLE_PARAMS;
        break;
      case ScoringFunctionCategoryType.MOLECULE_SCORE:
      case ScoringFunctionCategoryType.AUTOMATIC_RETROSYNTHESIS_MOLECULE:
        this.functionType = FunctionType.Chemical;
        this.advancedCSFAvailableFunctions = ADVANCED_CSF_AVAILABLE_PARAMS;
        break;
      default:
        break;
    }
  }

  public onCalculatorParameterClick(parameter: {
    parameter_name: string;
    description: string;
    value: string;
    hideSeek: boolean;
  }) {
    if (
      parameter.hideSeek &&
      ['HIDE_SMILES', 'HIDE_SMARTS', 'HIDE_NAME'].includes(parameter.value)
    ) {
      this.hideInScoringFunction(parameter.value);
    } else if (!parameter.hideSeek) {
      this.addToScoringFunction(parameter.value);
    }
  }

  public focusTextarea() {
    this.scoringFunctionTextarea.nativeElement.focus();
    return this.scoringFunctionTextarea.nativeElement;
  }

  public addToScoringFunction(value: string) {
    const textArea = this.focusTextarea();
    if (this.scoringFunctionFormControl.value === null) {
      // it means that form control has been .reset()
      this.scoringFunctionFormControlChange('');
    }
    const textAreaValue = textArea.value;
    const cursorPosition = textAreaValue.slice(0, textArea.selectionStart).length;
    this.stopSaving = false;
    if (cursorPosition === this.scoringFunctionFormControl.value.length) {
      this.scoringFunctionFormControlChange(this.scoringFunctionFormControl.value + value);
    } else {
      this.scoringFunctionFormControlChange(
        this.scoringFunctionFormControl.value.slice(0, cursorPosition) +
          value +
          this.scoringFunctionFormControl.value.slice(cursorPosition),
      );
    }
    this.tempScoringFunction = this.scoringFunctionFormControl.value;
    this.inputHistory.push(this.tempScoringFunction);
    if (this.scoringFunctionFormControl.value.length > 2000) {
      this.stopSaving = true;
      this.tempScoringFunction = this.tempScoringFunction.substring(0, 1999);
    }
    this.scoringFunctionFormControlChange(this.tempScoringFunction);
    this.clearInputHistory();
    this.changesMade = true;
    this.focusTextarea();
    const newCursorPosition = cursorPosition + value.length;
    // This timeout is necessary because setSelectionRange() was executed before focus() and then
    // focus() would lose track of cursors' position.
    setTimeout(() => {
      textArea.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 10);
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.onScoringFunctionChange.emit({ scoringFunction: this.templateScoringFunction });
  }

  public hideInScoringFunction(value: string) {
    this.filterDialogRef = this.filterDialog.open(
      ScoringFunctionFilterComponent,
      this.filterDialogConfig,
    );
    this.filterDialogRef.componentInstance.filter = value;
    this.filterDialogRef.beforeClosed().subscribe((filter) => {
      if (filter) {
        this.addToScoringFunction(`${value}(${filter})`);
      }
    });
  }

  public addHideSetToScoringFunction(frozenSets: any[], setsType: 'molecule' | 'smarts') {
    let parameter: string;
    switch (setsType) {
      case 'molecule':
        parameter = 'HIDE_MOLSET';
        break;
      case 'smarts':
        parameter = 'HIDE_SMARTSSET';
        break;
    }
    this.addToScoringFunction(
      `${parameter}(#${frozenSets.map((frozenSet) => frozenSet.id).join(',#')})`,
    );
  }

  public onMatMenuClose(event: any, elementSet: any[], setsType: 'molecule' | 'smarts') {
    const setsToFreeze: number[] = elementSet
      .filter((element) => element.selected)
      .map((element) => element.id);
    if (event !== 'keydown' && setsToFreeze.length > 0) {
      this.freezingSets = true;
      this.freezeSets.emit({ setsToFreeze, setsType });
    }
    this.moleculeSets.forEach((moleculeSet) => (moleculeSet.selected = false));
    this.smartsSets.forEach((smartsSet) => (smartsSet.selected = false));
  }

  public deleteScoringFunction() {
    this.onDeleteScoringFunction.emit({ scoringFunction: this.scoringFunction });
  }

  public onMatMenuButtonClick(event: any, elementSet: any) {
    event.stopPropagation();
    elementSet.selected = !elementSet.selected;
  }

  public clearScoringFunction() {
    this.scoringFunctionCached = this.scoringFunctionFormControl.value;
    this.tempScoringFunction = '';
    this.inputHistory.push(this.tempScoringFunction);
    this.clearInputHistory();
    this.changesMade = true;
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.focusTextarea();
    this.scoringFunctionFormControl.reset();
    this.onScoringFunctionChange.emit({ scoringFunction: this.templateScoringFunction });
  }

  public resetScoringFunction() {
    this.tempScoringFunction = this.originScoringFunction.source_code;
    this.inputHistory.push(this.tempScoringFunction);
    this.clearInputHistory();
    this.changesMade = false;
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.focusTextarea();
    this.scoringFunctionFormControlChange(this.tempScoringFunction);
    this.onScoringFunctionChange.emit({ scoringFunction: this.templateScoringFunction });
  }

  public undo() {
    const lastHistoryState = this.inputHistory[this.inputHistory.length - 2];
    this.tempScoringFunction = lastHistoryState;
    this.inputHistory.pop();
    this.templateScoringFunction.source_code = this.tempScoringFunction;
    this.focusTextarea();
    this.scoringFunctionFormControlChange(this.tempScoringFunction);
    this.onScoringFunctionChange.emit({ scoringFunction: this.templateScoringFunction });
  }

  public onCalculatorInput() {
    if (this.scoringFunctionFormControl.value.length === 2000 && !this.stopSaving) {
      this.inputHistory.push(this.scoringFunctionFormControl.value);
      this.stopSaving = true;
    } else if (this.scoringFunctionFormControl.value.length < 2000) {
      this.stopSaving = false;
    }
    if (!this.stopSaving) {
      this.inputHistory.push(this.scoringFunctionFormControl.value);
      this.changesMade = true;
    }
    this.templateScoringFunction.source_code = this.scoringFunctionFormControl.value;
    this.clearInputHistory();
    this.onScoringFunctionChange.emit({ scoringFunction: this.templateScoringFunction });
  }

  public clearInputHistory() {
    // This should limit number of history states to 256.
    if (this.inputHistory.length > 256) {
      this.inputHistory.shift();
    }
  }

  public isSourceCode() {
    if (this.scoringFunction) {
      return (
        this.scoringFunctionFormControl.value === this.scoringFunction.source_code ||
        this.inputHistory.length === 0
      );
    }
  }

  public shouldSaveFunctionButtonDisabled() {
    return (
      this.scoringFunctionName === '' ||
      (this.scoringFunction.name === this.scoringFunctionName &&
        this.scoringFunction.owner === null)
    );
  }

  public scoringFunctionFormControlChange(valueToSet: string) {
    this.scoringFunctionFormControl.setValue(valueToSet);
  }

  public openSaveDialog() {
    const name = this.scoringFunction.owner ? this.scoringFunction.name : null;
    this.openSaveDialogEvent.emit({
      scoringFunctionName: name,
      scoringFunctionSourceCode: this.scoringFunctionFormControl.value,
      scoringFunctionType: this.functionCategory,
    });
  }
}
