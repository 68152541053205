<div class="container">
  <div class="popup-container">
    <div [ngClass]="[isScrollTop ? 'popup-header' : 'popup-header box-shadow']">
      <div class="clear-icon-container">
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon class="clickable-icon">clear</mat-icon>
        </button>
      </div>
      <div *ngIf="isUserEcommValidated !== 'true'">
        <div *ngIf="isWaitingUserAuth; else noAuthTemplate" class="wait-ecomm-auth">
          <span>Waiting Authentication from Sigma-Aldrich ...</span>
          <mat-spinner [strokeWidth]="2" [diameter]="15" color="primary"></mat-spinner>
        </div>
      </div>
      <div *ngIf="isUserEcommValidated === 'true'" class="popup-title-container">
        <span class="title">Pricing and Availability</span>
        <button mat-icon-button>
          <mat-icon
            *ngIf="cartItemCount === 0; else iconWithBadge"
            class="shopping-list-icon"
            svgIcon="ch:shopping-list-icon"
            (click)="toggleCart()"
          ></mat-icon>
        </button>
      </div>
    </div>

    <div class="popup-middle-part" (scroll)="onScroll($event)">
      <div *ngIf="isUserEcommValidated === 'true'">
        <div *ngIf="isWaitingProductInfoList" class="product-list-loading-indicator">
          <mat-spinner [strokeWidth]="3" [diameter]="50" color="primary"></mat-spinner>
        </div>
        <div
          *ngIf="
            isWaitingProductInfoList === false &&
            isGetProductInfoListFailedForAllCasNumbers === false &&
            allItemList.length === 0
          "
          class="search-result-empty"
        >
          <span *ngIf="receivedCasNumberList.length === 1">
            Searched 0 result for "{{ receivedCasNumberList.join(' ') }}"
          </span>
          <span *ngIf="receivedCasNumberList.length > 1">
            Searched 0 result for this pathway
          </span>
        </div>
        <div
          class="popup-main-content"
          [hidden]="
            isWaitingProductInfoList ||
            isGetProductInfoListFailedForAllCasNumbers ||
            allItemList.length === 0
          "
        >
          <div class="applied-filters">
            <mat-label class="label">Applied Filters:</mat-label>
            <div class="chip-list">
              <mat-chip-list aria-label="Applied Filters">
                <mat-chip *ngFor="let cas of casNumberListForFilter" class="chip">
                  {{ cas }}
                  <mat-icon
                    class="clear-icon clickable-icon"
                    (click)="removeCasNumberAndRefreshDisplayingData(cas)"
                    >clear</mat-icon
                  >
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="sort-by-container">
            <div>
              Showing {{ displayingItemList.length }} results for "
              {{ casNumberListForDisplayingProductDetailList.join(', ') }} " within Products
            </div>
            <div>
              <span>Sort By</span>
              <mat-form-field appearance="outline" class="select-container">
                <mat-select
                  [(value)]="selectedSortByValue"
                  (selectionChange)="sortByValueChanged($event.value)"
                  [panelClass]="'pricing-availability-dialog-select-panel'"
                >
                  <mat-option *ngFor="let sortby of sortBy" [value]="sortby.value">
                    {{ sortby.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="divider">
            <mat-divider></mat-divider>
          </div>
          <div
            class="product-detail-list-container"
            *ngIf="isDisplayProductByGrouping; else displayUngroupedProductInfoList"
          >
            <mat-accordion multi>
              <div *ngFor="let substanceInfo of displayingItemList; let last = last">
                <div class="product-detail-info-list-item">
                  <ch-product-detail
                    [substanceOrProductDetailInfo]="substanceInfo"
                    [isDisplayProductByGrouping]="isDisplayProductByGrouping"
                  ></ch-product-detail>
                </div>
                <mat-divider *ngIf="!last"></mat-divider>
              </div>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-footer" *ngIf="isUserEcommValidated === 'true' && allItemList.length > 0">
      <div class="paginator">
        <mat-paginator
          [pageSize]="pageSize"
          [length]="allItemList.length"
          [pageIndex]="pageIndex"
          [hidePageSize]="true"
          (page)="updatePage($event)"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #noAuthTemplate>
  <div class="wait-ecomm-auth">
    <span>You are not authorized by Sigma-Aldrich at this moment.</span>
  </div>
</ng-template>
<ng-template #iconWithBadge>
  <mat-icon
    class="shopping-list-icon"
    [matBadge]="cartItemCount > 99 ? '99+' : cartItemCount"
    svgIcon="ch:shopping-list-icon"
    (click)="toggleCart()"
  ></mat-icon>
</ng-template>
<ng-template #displayUngroupedProductInfoList>
  <div class="product-detail-list-container">
    <mat-accordion multi>
      <div *ngFor="let productDetailInfo of displayingItemList; let last = last">
        <div class="product-detail-info-list-item">
          <ch-product-detail [substanceOrProductDetailInfo]="productDetailInfo"></ch-product-detail>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </mat-accordion>
  </div>
</ng-template>
<ch-cart-widget #cartWidget> </ch-cart-widget>
