import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScoringFunctionCalculatorComponent } from '../../components/scoring-function-calculator';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService {
  private scoringFunctionDialogRef: MatDialogRef<ScoringFunctionCalculatorComponent>;

  constructor(private scoringFunctionDialog: MatDialog) {}

  public openScoringFunctionDialog(config: MatDialogConfig): Observable<any> {
    this.scoringFunctionDialogRef = this.scoringFunctionDialog.open(
      ScoringFunctionCalculatorComponent,
      config,
    );
    return this.scoringFunctionDialogRef.beforeClosed();
  }
}
