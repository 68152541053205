import {
  Component,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { AuthorizedTextResourceService, ErrorsHandlerService } from '../../services';

@Component({
  selector: 'ch-molecule-3d-viewer',
  styleUrls: ['./molecule-3d-viewer.component.scss'],
  templateUrl: './molecule-3d-viewer.component.html',
})
export class Molecule3DViewerComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() public src: string;
  @Input() public minWidth: number = 100;
  @Input() public minHeight: number = 100;
  // Kekule.Widget.Position
  @Input() public toolbarPosition:
    | 'AUTO'
    | 'TOP'
    | 'LEFT'
    | 'BOTTOM'
    | 'RIGHT'
    | 'TOP_LEFT'
    | 'TOP_RIGHT'
    | 'BOTTOM_LEFT'
    | 'BOTTOM_RIGHT' = 'BOTTOM';
  // use # colors
  @Input() public backgroundColor: string = '#ffffff';

  @Output() public error: boolean = false;
  @Output() public errorMessage: string = 'Loading error';
  @Output() public loading: boolean = false;
  @Output() public molFileString: string = '';

  @ViewChild('kekule3DViewerWrapper') private kekule3DViewerWrapper: ElementRef;
  private kekule3DViewer: any;

  constructor(
    private authTextService: AuthorizedTextResourceService,
    private errorsHandler: ErrorsHandlerService,
  ) {}

  public ngOnDestroy(): void {
    this.kekule3DViewer.setChemObj(null);
    this.kekule3DViewer.destroyElement();
    this.kekule3DViewer = null;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('src')) {
      if (this.src !== '') {
        this.loading = true;
        this.error = false;

        this.authTextService.getText(this.src).subscribe(
          (molFileString: string) => {
            this.molFileString = molFileString;

            const loaded = this.set3DViewerWithMolFileData(molFileString);
            this.error = !loaded;
            this.loading = false;
          },
          (error: any) => {
            // connection errors are already served by AuthorizedTextService, no others expected here but code bugs
            console.error(`Error on access to mol file "${this.src}": ${error}`);
            this.error = true;
            this.loading = false;
          },
        );
      } else {
        this.molFileString = '';
        const loaded = this.set3DViewerWithMolFileData();
        this.error = !loaded;
        this.loading = false;
      }
    }

    // Update 3D viewer widget params if it was already created
    if (this.kekule3DViewer) {
      if (changes.hasOwnProperty('toolbarPosition')) {
        this.kekule3DViewer.setToolbarPos(Kekule.Widget.Position[this.toolbarPosition]);
      }
      if (changes.hasOwnProperty('backgroundColor') && this.kekule3DViewer) {
        this.kekule3DViewer.setBackgroundColor(this.backgroundColor);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    if (this.kekule3DViewer) {
      this.kekule3DViewer.setDimension(
        `${this.kekule3DViewerWrapper.nativeElement.clientWidth}px`,
        `${this.kekule3DViewerWrapper.nativeElement.clientHeight}px`,
      );
    }
  }

  public ngAfterViewInit() {
    this.resetViewer();
  }

  private resetViewer() {
    if (this.kekule3DViewer) {
      this.kekule3DViewer.destroyElement();
    }
    this.kekule3DViewer = new Kekule.ChemWidget.Viewer(document);
    this.kekule3DViewer.setDimension(
      `${this.kekule3DViewerWrapper.nativeElement.clientWidth}px`,
      `${this.kekule3DViewerWrapper.nativeElement.clientHeight}px`,
    );
    this.kekule3DViewer.appendToElem(this.kekule3DViewerWrapper.nativeElement);
    this.kekule3DViewer
      .setRenderType(Kekule.Render.RendererType.R3D)
      .setMoleculeDisplayType(Kekule.Render.Molecule3DDisplayType.BALL_STICK)
      .setBackgroundColor(this.backgroundColor);

    // Configure toolbar. For list of possible options see:
    // http://partridgejiang.github.io/Kekule.js/documents/tutorial/content/chemViewer.html#customizing-toolbar
    this.kekule3DViewer
      .setEnableToolbar(true)
      .setEnableDirectInteraction(true)
      .setEnableTouchInteraction(true)
      .setEnableEdit(false)
      .setToolButtons([
        'molDisplayType',
        'molHideHydrogens',
        'zoomIn',
        'zoomOut',
        'reset',
        'config',
      ])
      .setToolbarEvokeModes([
        Kekule.Widget.EvokeMode.EVOKEE_CLICK,
        Kekule.Widget.EvokeMode.EVOKEE_MOUSE_ENTER,
        Kekule.Widget.EvokeMode.EVOKEE_TOUCH,
      ])
      .setToolbarRevokeModes([
        Kekule.Widget.EvokeMode.EVOKEE_MOUSE_LEAVE,
        Kekule.Widget.EvokeMode.EVOKER_TIMEOUT,
      ])
      .setToolbarPos(Kekule.Widget.Position[this.toolbarPosition]);

    // TODO:  Adjust default viewer options to our needs
    // let options = Kekule.Render.RenderOptionUtils.convertConfigsToPlainHash(
    //                 Kekule.Render.Render3DConfigs.getInstance()
    //               );
    // this.kekule3DViewer.setRenderConfigs(options);

    // reset with data we have
    const molFileString = this.molFileString || undefined;
    this.error = !this.set3DViewerWithMolFileData(molFileString);
  }

  private set3DViewerWithMolFileData(molString?: string): boolean {
    if (!this.kekule3DViewer) {
      return false;
    }

    if (molString) {
      try {
        const kekuleMolecule = Kekule.IO.loadFormatData(molString, 'mol');
        this.kekule3DViewer.setChemObj(kekuleMolecule);
        return true;
      } catch (e) {
        console.error(`Loading mol file '${this.src}' error: ${e}\nContent: ${molString}`);
        this.errorsHandler.showGlobalError(
          '3D file format error',
          `<p><b>'mol' file:</b> '${this.src}'</p><p><b>Error:</b>${e}</p>`,
        );

        return false;
      }
    } else {
      this.kekule3DViewer.setChemObj(null);
      return true;
    }
  }
}
