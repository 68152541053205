import { MatDialogRef } from '@angular/material/dialog';
import { KeywordsData } from './../../results-default-filter.models';
import { AppConstantsService } from 'src/app/shared/services';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'ch-results-keyword-filter-dialog',
  templateUrl: './results-keyword-filter-dialog.component.html',
  styleUrls: ['./results-keyword-filter-dialog.component.scss'],
})
export class ResultsKeywordFilterDialogComponent implements OnInit {
  public keyword: string;
  public keywordFilterType: string;

  constructor(
    private appConstantService: AppConstantsService,
    private dialogRef: MatDialogRef<ResultsKeywordFilterDialogComponent>,
  ) {}

  public ngOnInit() {
    this.keywordFilterType = this.appConstantService.exclude;
  }

  public saveKeyword() {
    if (!!this.keyword && this.keyword.length) {
      const keywordDetails: KeywordsData = {
        keyword: this.keyword,
        sectionName: this.keywordFilterType,
      };
      this.dialogRef.close(keywordDetails);
    }
  }

  public closeDialog() {
    this.dialogRef.close(null);
  }

  // There is an additional key listener added because esc does
  // not always catched for some reason
  @HostListener('window:keyup', ['$event'])
  public handleKeyEvents(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.saveKeyword();
        break;
      case 'Escape':
        this.closeDialog();
        break;
      default:
        event.stopPropagation();
    }
  }
}
