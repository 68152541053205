<div (mouseenter)="updateNodePresentFlag(true)" (mouseleave)="updateNodePresentFlag(false)">
  <ng-container *ngIf="!isMoleculeDetailFromConfigure; else buyOnlineButton">
    <div class="clear-icon-container" *ngIf="!isNodeDetailPopup">
      <button class="ch-action-icon-hover" mat-icon-button>
        <mat-icon class="grey-icon">clear</mat-icon>
      </button>
    </div>
    <button
      class="graph-menu-btn"
      mat-menu-item
      *ngIf="!isNodeDetailPopup"
      (click)="popupService.dispatchNodeEvent(node, 'details', true)"
    >
      <mat-icon svgIcon="ch:visibility-outline"></mat-icon>
      <span>More information</span>
    </button>
    <ng-container *ngTemplateOutlet="buyOnlineButton"></ng-container>
    <button
      class="graph-menu-btn"
      mat-menu-item
      *ngIf="!node.isLastSubstrate && !isReaction"
      (click)="showReactionReport()"
      [disabled]="popupService.isSimilarMoleculePopupInfo.value"
      [ngClass]="{ 'disabled-menu-item': popupService.isSimilarMoleculePopupInfo.value }"
    >
      <mat-icon svgIcon="ch:description-outline"></mat-icon>
      <span>Reaction report</span>
    </button>
    <button
      class="graph-menu-btn"
      mat-menu-item
      (click)="addToClipboard()"
      [disabled]="popupService.isSimilarMoleculePopupInfo.value"
      [ngClass]="{ 'disabled-menu-item': popupService.isSimilarMoleculePopupInfo.value }"
      *ngIf="!isPinned"
    >
      <mat-icon svgIcon="ch:new-pin"></mat-icon>
      <span>Add to clipboard</span>
    </button>
    <button
      class="graph-menu-btn"
      mat-menu-item
      *ngIf="userMoleculeSets?.length && !isReaction"
      [matMenuTriggerFor]="moleculeSetsNestedMenu"
      [disabled]="popupService.isSimilarMoleculePopupInfo.value"
      [ngClass]="{ 'disabled-menu-item': popupService.isSimilarMoleculePopupInfo.value }"
    >
      <mat-icon svgIcon="ch:format-color-reset-outline"></mat-icon>
      <span>Add to molecule set</span>
    </button>
    <button class="graph-menu-btn" mat-menu-item *ngIf="isReaction" (click)="getStructure()">
      <mat-icon svgIcon="ch:file-copy-outline"></mat-icon>
      <span>Copy reaction</span>
    </button>
    <div class="multiple-btn-container" [class.is-node-detail-popup]="isNodeDetailPopup">
      <button class="graph-menu-btn" mat-menu-item *ngIf="!isReaction" (click)="getStructure()">
        <mat-icon svgIcon="ch:file-copy-outline"></mat-icon>
        <span>Copy structure</span>
      </button>

      <button
        class="graph-menu-btn"
        mat-menu-item
        *ngIf="!isReaction && isNodeDetailPopup"
        (click)="copySmiles()"
      >
        <mat-icon svgIcon="ch:file-copy-outline"></mat-icon>
        <span>Copy SMILES</span>
      </button>
    </div>
    <button
      class="graph-menu-btn"
      mat-menu-item
      *ngIf="!isReaction"
      (click)="startNewAnalysis()"
      synthiaLiteDisabledFeature
      [disabledInSynthiaLite]="isFeatureDisabled"
      [isShowExpiryMessage]="true"
    >
      <mat-icon>add</mat-icon>
      <span>Start new analysis</span>
    </button>
    <button
      class="graph-menu-btn"
      mat-menu-item
      *ngIf="isGraphExpansionPossible()"
      (click)="expandGraph()"
    >
      <mat-icon>device_hub</mat-icon>
      <span>Expand Graph</span>
    </button>
    <mat-divider class="menu-divider" *ngIf="!isNodeDetailPopup"></mat-divider>
    <div
      class="filter-container"
      (click)="stopMenuClosing($event)"
      *ngIf="!isNodeDetailPopup"
      [class.disabled-filter]="!isFilterEnabled"
      [matTooltipDisabled]="isFilterEnabled"
      [matTooltip]="appConstantService.filtersDisabledTooltip"
      matTooltipClass="ch-tooltip"
    >
      <div class="menu-overlay ch-overlay-div" *ngIf="!isFilterEnabled"></div>
      <button class="graph-menu-btn" mat-menu-item disabled>
        <mat-icon>filter_list</mat-icon>
        <span>Add filter</span>
      </button>
      <div class="last-reaction" *ngIf="isLastReaction()">
        <mat-checkbox [(ngModel)]="lastReaction" class="last-reaction-checkbox" color="primary">
          Last reaction only
        </mat-checkbox>
      </div>
      <div class="select-filter-container">
        <mat-form-field class="ch-form-field ch-select-field select-filter" appearance="outline">
          <mat-select [(ngModel)]="filterType">
            <mat-option
              value="Limit To"
              (mouseenter)="updateNodePresentFlag(true)"
              (mouseleave)="updateNodePresentFlag(false)"
              >Limit To</mat-option
            >
            <mat-option
              value="Exclude"
              (mouseenter)="updateNodePresentFlag(true)"
              (mouseleave)="updateNodePresentFlag(false)"
              >Exclude</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button class="ch-action-icon-hover add-icon-btn" mat-icon-button (click)="addFilter()">
          <mat-icon class="grey-icon">add</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #buyOnlineButton>
  <button
    class="graph-menu-btn"
    [disabled]="node['molecule'].cas.length === 0"
    [ngClass]="{ 'disabled-menu-item': node['molecule'].cas.length === 0 }"
    mat-menu-item
    *ngIf="shouldShowBuyButton() && isECommIntegrationEnabled"
    (click)="openPricingAvailabilityPopup()"
  >
    <mat-icon svgIcon="ch:molset-ind" class="shop-now-icon"></mat-icon>
    <span>Shop now</span>
  </button>
</ng-template>

<mat-menu #moleculeSetsNestedMenu="matMenu" class="molecule-sets-menu">
  <ng-template [ngIf]="userMoleculeSets?.length">
    <div (mouseenter)="updateNodePresentFlag(true)" (mouseleave)="updateNodePresentFlag(false)">
      <button mat-menu-item md-autofocus style="height: 0px;"></button>
      <button
        mat-menu-item
        (click)="addMoleculeToSet(moleculeSet)"
        [matTooltip]="moleculeSet.name"
        matTooltipShowDelay="1000"
        matTooltipPosition="after"
        matTooltipClass="ch-tooltip"
        *ngFor="let moleculeSet of userMoleculeSets"
      >
        {{ moleculeSet.name }}
      </button>
    </div>
  </ng-template>
  <ng-template [ngIf]="userMoleculeSets?.length === 0">
    <button mat-menu-item disabled>
      No molecule sets available
    </button>
  </ng-template>
</mat-menu>

<mat-menu #shopLinksNestedMenu="matMenu" class="molecule-sets-menu">
  <ng-template [ngIf]="shopLinks.length !== 0">
    <div (mouseenter)="updateNodePresentFlag(true)" (mouseleave)="updateNodePresentFlag(false)">
      <button mat-menu-item md-autofocus style="height: 0px;"></button>
      <div *ngFor="let shop of shopLinks">
        <a
          *ngIf="shop.url"
          [href]="shop.url"
          class="shop-link"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button
            mat-menu-item
            [matTooltip]="shop.name"
            matTooltipShowDelay="1000"
            matTooltipPosition="after"
            matTooltipClass="ch-tooltip"
          >
            <span [class.main-shop]="shop.name === 'Sigma-Aldrich'">
              {{ shop.name }}
            </span>
          </button>
        </a>
        <button
          mat-menu-item
          [matTooltip]="shop.name"
          matTooltipShowDelay="1000"
          matTooltipPosition="after"
          matTooltipClass="ch-tooltip"
          *ngIf="shop.productId"
          (click)="openProductID(shop.productId)"
        >
          <span [class.main-shop]="shop.name === 'Sigma-Aldrich'">
            {{ shop.name }}
          </span>
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>
