<div *ngIf="isSliderFilterSection" class="slider-filter-section">
  <div class="price-filter-section">
    <span class="slider-filter-label">Starting Material Price $/g</span>
    <div class="filter-price-input" [class.price-slider-disabled]="priceFilterDisabled">
      <mat-form-field
        class="ch-form-field ch-text-field value-field price-min"
        [class.price-slider-disabled]="priceFilterDisabled"
        appearance="outline"
      >
        <input
          class="price-right-align"
          [class.price-slider-disabled]="priceFilterDisabled"
          name="priceMinInput"
          matInput
          type="text"
          (click)="$event.target.select()"
          (focus)="removeCommaMinValue()"
          (keydown)="priceInputEvent($event)"
          [(ngModel)]="priceFilterMinValue"
          (ngModelChange)="priceMinValueChange($event)"
        />
      </mat-form-field>

      <mat-form-field
        class="ch-form-field ch-text-field value-field price-max"
        [class.price-slider-disabled]="priceFilterDisabled"
        appearance="outline"
      >
        <input
          class="price-right-align"
          [class.price-slider-disabled]="priceFilterDisabled"
          name="priceMaxInput"
          matInput
          type="text"
          (click)="$event.target.select()"
          (focus)="removeCommaMaxValue()"
          (keydown)="priceInputEvent($event)"
          [(ngModel)]="priceFilterMaxValue"
          (ngModelChange)="priceMaxValueChange($event)"
        />
      </mat-form-field>
    </div>
    <div class="dynamic-range-input-div" [class.price-slider-disabled]="priceFilterDisabled">
      <div class="dynamic-range-input-container">
        <div
          class="dynamic-range-input"
          [style.width.%]="priceRangeWidth"
          [style.marginLeft.%]="priceRangeMinPos"
          [matTooltip]="inputPriceRangeTooltip"
          matTooltipShowDelay="100"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
        >
          <div class="input-handler"></div>
          <div class="input-center-line"></div>
          <div class="input-handler"></div>
        </div>
      </div>
    </div>
    <div class="filter-max-min price-max-min-div">
      <span class="filter-min">{{ priceMin | number: '1.2-2' }}</span>
      <span class="filter-max">{{ priceMax | number: '1.2-2' }}</span>
    </div>
  </div>
  <div class="similarity-slider-label">
    <span class="slider-filter-label">Pathway Similarity</span>
    <span class="mat-button-wrapper similarity-info">
      <mat-icon
        class="grey-icon"
        [matTooltip]="pathwaySimilarityInfo"
        matTooltipClass="ch-tooltip"
        matTooltipPosition="above"
        >info_outline</mat-icon
      >
    </span>
  </div>
  <div class="similarity-slider">
    <mat-slider
      [(ngModel)]="pathwaySimilarity"
      class="ch-slider"
      (change)="pathwaysSimilarityChange()"
      min="10"
      max="100"
      step="10"
      [thumbLabel]="true"
      color="primary"
    >
    </mat-slider>
    <div class="filter-max-min">
      <span class="filter-min">10</span>
      <span class="filter-max">100</span>
    </div>
  </div>

  <div class="slider-section filter-reaction">
    <span class="slider-filter-label">Number of Reactions in Path</span>
    <div class="filter-slider-padding" [class.reaction-slider-disabled]="reactionFilterDisabled">
      <ch-mat-range-slider
        [max]="reactionMax"
        [min]="reactionMin"
        [step]="1"
        [thumbLabel]="true"
        [(ngModel)]="numberOfReactionValue"
        [vertical]="false"
        [showRange]="true"
        [dynamicRangeMax]="dynamicRangeNumberOfReactions.max"
        [dynamicRangeMin]="dynamicRangeNumberOfReactions.min"
        (input)="applyNumberOfReactionsFilter()"
        (isSliderDisabled)="isSliderDisabled(sliderType.REACTION, $event)"
      >
      </ch-mat-range-slider>
      <div class="filter-max-min">
        <span class="filter-min">{{ reactionMin }}</span>
        <span class="filter-max">{{ reactionMax }}</span>
      </div>
    </div>
  </div>

  <div class="slider-section">
    <span class="slider-filter-label">Protecting Groups</span>
    <div
      class="filter-slider-padding"
      [class.protection-slider-disabled]="protectionGroupsFilterDisabled"
    >
      <ch-mat-range-slider
        [max]="protectionMax"
        [min]="protectionMin"
        [step]="1"
        [thumbLabel]="true"
        [(ngModel)]="protectionGroups"
        [vertical]="false"
        [showRange]="true"
        [dynamicRangeMax]="dynamicRangeProtectionGroups.max"
        [dynamicRangeMin]="dynamicRangeProtectionGroups.min"
        (input)="applyProtectiveStepsFilter()"
        (isSliderDisabled)="isSliderDisabled(sliderType.PROTECTION_GROUPS, $event)"
      >
      </ch-mat-range-slider>
      <div class="filter-max-min">
        <span class="filter-min">{{ protectionMin }}</span>
        <span class="filter-max">{{ protectionMax }}</span>
      </div>
    </div>
  </div>
</div>
