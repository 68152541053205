import { isString } from '../../components/utils';

export class AnalysisComment {
  public username: string = '';
  public content: string = '';
  public date: string = '';
  public unread: boolean = false;
  public author: string = '';

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to Comment:ctor.`);
        }
      } else {
        console.warn(`Unexpected type of '${field}' in data provided to Comment:ctor.`);
      }
    }
  }
}
