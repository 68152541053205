import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorsHandlerService, ParsedConnectionError } from '../errors-handler/index';
import { FrontendStorage } from '../models/frontend-storage';
import { InfoService } from '../info.service';

export const backendEntryPointFrontendStorage =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/frontend-storage/';

@Injectable()
export class FrontendStorageService {
  private static resolveFrontendStorage(storage) {
    try {
      const jsonResult = storage.body.content;
      if (jsonResult.length !== 0) {
        return new FrontendStorage(JSON.parse(jsonResult));
      }
    } catch (error) {
      return throwError('Unexpected format of response.');
    }
  }

  public userSettings: FrontendStorage;
  // loadingFrontendStorage is used to solve race condition issues in visualization settings
  public loadingFrontendStorage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
  ) {}

  public getStorage() {
    this.loadingFrontendStorage.next(true);
    return this.http
      .get(backendEntryPointFrontendStorage, { observe: 'response' })
      .pipe(
        map(FrontendStorageService.resolveFrontendStorage),
        catchError(this.frontendStorageError.bind(this)),
      ) as Observable<any>;
  }

  public setStorage() {
    this.loadingFrontendStorage.next(true);
    const params = {
      content: JSON.stringify(this.userSettings),
    };
    return this.http
      .post(backendEntryPointFrontendStorage, JSON.stringify(params), { observe: 'response' })
      .pipe(
        map(FrontendStorageService.resolveFrontendStorage),
        catchError(this.frontendStorageError.bind(this)),
      ) as Observable<any>;
  }

  public makeStorageLocal(storage: FrontendStorage) {
    this.userSettings = storage;
  }

  public formNewStorage() {
    // should be called only when user has empty storage.
    this.userSettings = {
      appSettings: {
        analysisResults: {
          expandedPath: {
            labelDisplaySettings: {
              repeatedReaction: true,
              repeatedMolecule: true,
              reactionName: true,
              typicalConditions: false,
              legendTags: true,
              publishedReferences: true,
            },
          },
        },
      },
      tutorialSettings: {
        autoRetro: {
          skip: false,
          remindRequestedAt: 0,
        },
      },
    };
    return this.setStorage();
  }

  private frontendStorageError(
    error: Response | any,
    caughtObservable?: Observable<FrontendStorage>,
  ) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
      return throwError(parsedError);
    } else if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      return throwError(parsedError);
    } else {
      return throwError('Something went wrong. ' + error);
    }
  }
}
