import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { TAB } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { SocialService, AssociatedUser } from '../../services/social';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { AnalysisEntry } from '../../services';
import { AnalysisService } from '../../services/analysis/analysis.service';
import { FADE_OUT_ANIMATION } from '../../animations';
import { isString } from 'underscore';

@Component({
  selector: 'ch-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
  animations: [FADE_OUT_ANIMATION],
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  public filteredAssociatedUsers: AssociatedUser[] = [];
  public sharingEligibleUsers: AssociatedUser[] = [];
  public associatedUsers: AssociatedUser[] = [];
  public shareWithFormControl: UntypedFormControl = new UntypedFormControl();
  public selectedUsers: AssociatedUser[] = [];
  public sharing: boolean = false;
  public unsharingUser: AssociatedUser;
  public separatorKeyCodes: number[] = [TAB];
  public analysis: AnalysisEntry = null;

  @ViewChild('userChipList') public userChipList: MatChipList;
  @ViewChild('userListInput') public userListInput: ElementRef<HTMLInputElement>;
  // see it as MatAutocompleteTrigger for selecting options with TAB key
  @ViewChild('userListInput', { read: MatAutocompleteTrigger })
  public matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autoCompletePanel') public autoCompletePanel: MatAutocomplete;

  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    public socialService: SocialService,
    @Inject(MAT_DIALOG_DATA) public analysisId: number,
    public analysisService: AnalysisService,
  ) {}

  public ngOnInit() {
    this.analysisService
      .getAnalysis(this.analysisId)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysis) => {
        this.analysis = analysis;
        this.analysisService.analysisSettingsBehaviorSubjects.sharesCount.next(
          analysis.shared_with.length,
        );
      });

    this.dialogRef.afterClosed().subscribe(() => {
      this.onDialogClose();
    });
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public onSearchChange(searchValue: string) {
    if (searchValue.length > 2) {
      this.getAssociatedUsers(searchValue);
    } else {
      this.filteredAssociatedUsers = [];
    }
  }

  public onChipInputTokenEnd() {
    this.autoCompletePanel.optionSelected.emit({
      option: this.matAutocompleteTrigger.activeOption,
      source: this.autoCompletePanel,
    });
  }

  public getAssociatedUsers(searchText: string) {
    this.socialService
      .getAssociatedUsers(searchText)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((associatedUsers: AssociatedUser[]) => {
        this.associatedUsers = associatedUsers;
        this.getSharingEligibleUsers();
        this.filteredAssociatedUsers = this.sharingEligibleUsers;
      });
  }

  public unshareAnalysis(user: AssociatedUser) {
    this.unsharingUser = user;
    this.socialService
      .unshareAnalysisAsOwner(this.analysis.id, [user.email])
      .pipe(
        takeUntil(this.unsubscriberSubject),
        // allow animation to finish
        finalize(() => setTimeout(() => (this.unsharingUser = undefined), 200)),
      )
      .subscribe((analysis: AnalysisEntry) => {
        this.analysis = analysis;
        this.getSharingEligibleUsers();
        this.analysisService.analysisSettingsBehaviorSubjects.sharesCount.next(
          analysis.shared_with.length,
        );
      });
  }

  public selectUser(event: MatAutocompleteSelectedEvent) {
    const isUserSelectedAlready: boolean = !!this.selectedUsers.find((user: AssociatedUser) => {
      return event.option.value.getEmailUsername() === user.getEmailUsername();
    });
    if (!isUserSelectedAlready) {
      this.selectedUsers.push(event.option.value);
    }
    this.shareWithFormControl.setValue('');
    this.userListInput.nativeElement.value = '';
    this.filteredAssociatedUsers = [];
  }

  public removeUser(_: any, index: number) {
    this.selectedUsers.splice(index, 1);
  }

  public shareAnalysis() {
    this.getSharingEligibleUsers();
    this.sharing = true;
    this.socialService
      .shareAnalysis(
        this.analysis.id,
        this.selectedUsers.map((user: AssociatedUser) => user.email),
      )
      .pipe(
        takeUntil(this.unsubscriberSubject),
        finalize(() => (this.sharing = false)),
      )
      .subscribe((analysis: AnalysisEntry) => {
        this.analysis = analysis;
        this.selectedUsers = [];
        this.getSharingEligibleUsers();
        this.analysisService.analysisSettingsBehaviorSubjects.sharesCount.next(
          analysis.shared_with.length,
        );
      });
  }

  public isSharingPossible(): boolean {
    return !this.sharing && this.selectedUsers.length > 0;
  }

  public trackByForUserList(_: number, item: AssociatedUser) {
    if (!item) {
      return null;
    }
    return item.email;
  }

  /**
   * Filters out users that this analysis has been shared with already.
   */
  public getSharingEligibleUsers() {
    this.sharingEligibleUsers = [];
    this.associatedUsers.forEach((user: AssociatedUser) => {
      const isUserInSharedWith: boolean =
        Array.isArray(this.analysis.shared_with) &&
        !!this.analysis.shared_with.find((sharedWithUser: AssociatedUser) => {
          return sharedWithUser.email === user.email;
        });
      if (!isUserInSharedWith) {
        this.sharingEligibleUsers.push(user);
      }
    });
    this.filteredAssociatedUsers = this.sharingEligibleUsers;
  }

  public onDialogClose() {
    this.dialogRef.close(this.analysis.shared_with.length);
  }
}
