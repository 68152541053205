import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IntroTourDialogComponent } from '../components/intro-tour-dialog';

@Injectable()
export class IntroTourDialogService {
  private dialogRef: MatDialogRef<IntroTourDialogComponent>;

  constructor(private dialog: MatDialog) {}

  public showIntro(tourName: string) {
    this.dialogRef = this.dialog.open(IntroTourDialogComponent);

    this.dialogRef.componentInstance.tourName = tourName;

    return this.dialogRef.afterClosed();
  }
}
