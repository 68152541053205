import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorsHandlerService, ParsedConnectionError } from './errors-handler';
import { InfoService } from './info.service';

export const backendEntryPointTechSupport = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/tech-support/';

@Injectable()
export class TechSupportService {
  public requestTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
  ) {}

  public sendEmail(content: any) {
    return this.http.post(backendEntryPointTechSupport, content, { observe: 'response' }).pipe(
      map((result) => result),
      catchError(this.sendEmailError.bind(this)),
    );
  }

  private sendEmailError(error: Response | any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage, 1000 * 60 * 60);
      this.errorsHandler.logout();
      return throwError(parsedError);
    }

    if (parsedError.isRecognized() && parsedError.isGlobal()) {
      if (parsedError.bodyJson.errors[0].code === 'aha-request-error') {
        this.errorsHandler.showGlobalError(
          'Could not create a request to technical support',
          'There is a problem with ticket creation. Please contact with your Administrator',
        );
        return throwError(parsedError);
      } else if (parsedError.bodyJson.errors[0].code === 'not_found') {
        this.infoService.showError('Specified user not found');
        return throwError(parsedError);
      } else {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
        return throwError(parsedError);
      }
    } else {
      this.infoService.showError('Email has not been sent. Try again');
      return throwError('Email has not been sent. ' + error);
    }
  }
}
