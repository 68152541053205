// We have to use custom encoder as a workaround of known Angular (up to v4.2.3) issue:
// by default some characters in search parameters are not encoded properly.
// See: https://github.com/angular/angular/issues/11058

import { HttpParameterCodec } from '@angular/common/http';

export class FixedQueryEncoderHelper implements HttpParameterCodec {
  public encodeKey(k: string): string {
    return this.encode(k);
  }

  public encodeValue(v: string): string {
    return this.encode(v);
  }

  public decodeKey(k: string): string {
    return this.decode(k);
  }

  public decodeValue(v: string): string {
    return this.decode(v);
  }

  private encode(val: string): string {
    return val
      .replace(/\%/gi, '%25')
      .replace(/\+/gi, '%2B')
      .replace(/\(/gi, '%28')
      .replace(/\)/gi, '%29')
      .replace(/=/gi, '%3D')
      .replace(/\#/gi, '%23')
      .replace(/\//gi, '%2F');
  }

  private decode(val: string): string {
    return val
      .replace(/\%25/gi, '%')
      .replace(/\%2B/gi, '+')
      .replace(/\%28/gi, '(')
      .replace(/\%29/gi, ')')
      .replace(/\%3D/gi, '=')
      .replace(/\%23/gi, '#')
      .replace(/\%2F/gi, '/');
  }
}
