import { isString } from '../../../components/utils';
import { PathwayBuildingBlocks } from './pathway-building-blocks';

export class LibrarySyntheticPathEntry {
  private static dateFields = new Set(['created_at', 'updated_at']);

  /* tslint:disable:variable-name */
  public created_at: Date = null;
  public id: number = 0;
  public reaction_nodes: number[] = [];
  public score: number = 0;
  public target_score: string = '';
  public updated_at: Date = null;
  public graph: number = 0;
  public analysis: number = 0;
  public computation: number = 0;
  public path_hash: number = 0;
  public rank: number = 0;
  public set_rank: number = 0;
  public is_favorite: boolean = false;
  public building_blocks: PathwayBuildingBlocks[] = [];
  public edges: any[] = [];
  public nodes: any[] = [];
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (LibrarySyntheticPathEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else {
          switch (field) {
            case 'score':
            case 'graph':
              if (jsonData[field] === null) {
                // Use null instead of default value because
                // null define that this attribute is empty and
                // not a 0 as we take info from the midend
                // side. Maybe it will be changed to more robust data structure.
                this[field] = null;
                break;
              }
            default:
              if (typeof jsonData[field] === typeof this[field]) {
                this[field] = jsonData[field];
              } else {
                console.error(
                  `Unexpected type of field '${field}' in data provided to LibrarySyntheticPathEntry:ctor.` +
                    `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
                );
              }
          }
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to LibrarySyntheticPathEntry:ctor.`,
        );
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
