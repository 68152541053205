import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RDKitModule } from '@rdkit/rdkit';
import { of, Subject, Subscription, forkJoin } from 'rxjs';
import { finalize, flatMap, takeUntil } from 'rxjs/operators';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'src/app/shared/animations';
import { AppConstantsService, MoleculeSearchService } from 'src/app/shared/services';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { TargetMoleculeSelectionService } from 'src/app/shared/services/target-molecule-selection/target-molecule-selection.service';
import { KetcherEditorComponent } from '../../ketcher-editor/ketcher-editor.component';
import { MoleculeEditorDialogComponent } from '../../molecule-editor-dialog';
import { isNullOrUndefined, StructureInputType } from '../../utils';
import { EditStructureFilter } from '../results-filter-default/results-default-filter.models';

enum parentClassEnum {
  StructureEdit = 'StructureEdit',
  StructureDraw = 'StructureDraw',
}

export enum filterTypes {
  Drawn = 'Drawn',
  Structure = 'Structure',
  Similar = 'Similar',
}

export interface IFilterCriteria {
  smiles: string;
  smarts: string;
  filterType: string;
  similarity?: number;
  showCategory: string;
  isFilterToBeRemoved: boolean;
}

@Component({
  selector: 'ch-results-filter-molecule-editor',
  templateUrl: './results-filter-molecule-editor.component.html',
  styleUrls: ['./results-filter-molecule-editor.component.scss'],
  animations: [FADE_IN_ANIMATION, FADE_OUT_ANIMATION],
})
export class ResultsFilterMoleculeEditorComponent extends MoleculeEditorDialogComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public selectedItemIndex = null;
  public structureFilterType: string;
  public similarity: number = 0;
  public filterLabel: string;
  public filterShownCategory: string = 'Exclude';

  public unSubscriberSubject: Subject<void> = new Subject<void>();
  public marvinEditorSubscriptions: Subscription = new Subscription();

  @ViewChild('smilesContainer') public smilesContainer: ElementRef;
  @ViewChild('moleculeEditor') public moleculeEditor: KetcherEditorComponent;

  public isEditFilterMode: boolean = false;
  public isMoleculeEditorLoaded: boolean = false;
  public searchLoading: boolean = false;
  public hiddenFormControl: UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<MoleculeEditorDialogComponent>,
    public changeDetectorRef: ChangeDetectorRef,
    public confirmDialogService: ConfirmDialogService,
    // needs to have this because this component extends MoleculeEditorDialogComponent
    @Inject(MAT_DIALOG_DATA)
    public injectedData: {
      elementType: 'SMILES' | 'SMARTS';
      inputData: any[];
      inputMode: string;
      isAuthorizedToStartAnalysis: boolean;
      parentClass: string;
    },
    public moleculeSearchService: MoleculeSearchService,
    private appConstantService: AppConstantsService,
    public targetMoleculeSelectionService: TargetMoleculeSelectionService,
  ) {
    super(
      dialogRef,
      changeDetectorRef,
      confirmDialogService,
      targetMoleculeSelectionService,
      injectedData,
      moleculeSearchService,
    );
  }

  public ngOnInit() {
    super.ngOnInit();
    this.searchLoading = true;
    if (this.injectedData.inputData && Array.isArray(this.injectedData.inputData)) {
      const {
        sectionName,
        categoryName,
        smarts,
        similarity,
      }: EditStructureFilter = this.injectedData.inputData[0];
      this.elementList = [smarts];
      this.filterShownCategory =
        sectionName === 'limitTo'
          ? this.appConstantService.limitTo
          : this.appConstantService.exclude;
      this.structureFilterType =
        categoryName === 'exact'
          ? filterTypes.Drawn
          : categoryName === 'structure'
          ? filterTypes.Structure
          : filterTypes.Similar;
      this.similarity = similarity / 100;
    }
    if (this.injectedData.inputMode === 'EDIT FILTER') {
      this.isEditFilterMode = true;
    }

    this.targetMoleculeSelectionService.isIframeLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isLoaded: boolean) => {
        this.isMoleculeEditorLoaded = isLoaded;
        if (isLoaded) {
          if (this.injectedData && this.injectedData.inputData) {
            if (!isNullOrUndefined(this.moleculeEditor)) {
              setTimeout(() => {
                if (this.structureFilterType === filterTypes.Drawn) {
                  this.moleculeEditor.setMolecule(this.injectedData.inputData[0].smiles);
                } else {
                  this.selectEditMolecule();
                }
              }, 50);
            }
          }
        }
      });

    this.hiddenFormControl = new UntypedFormControl();
    this.hiddenFormControl.valueChanges
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((newValue: string) => {
        if (newValue) {
          this.setValueFromPaste(newValue);
        }
      });

    this.moleculeSearchService.getRdkitObject().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });

    this.targetMoleculeSelectionService.isMoleculeLoading
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isMoleculeLoading: boolean) => {
        this.isMoleculeEdited = isMoleculeLoading;
      });

    this.moleculeSearchService.getRdkitObject().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });

    this.targetMoleculeSelectionService.isMoleculeEditorLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isLoaded: boolean) => {
        this.searchLoading = !isLoaded;
        if (isLoaded) {
          if (
            !isNullOrUndefined(this.moleculeEditor) &&
            isNullOrUndefined(this.moleculeEditor.ketcher)
          ) {
            this.moleculeEditor.loadMoleculeEditor();
          }
        }
      });
  }

  public ngAfterViewInit() {
    this.searchLoading = true;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getTitle() {
    if (this.injectedData.parentClass === parentClassEnum.StructureEdit) {
      return this.appConstantService.filterMoleculeEditorTitle.EditorTitleForEdit;
    }
    return this.appConstantService.filterMoleculeEditorTitle.EditorTitle;
  }

  public canAddMolecules(): boolean {
    return !!this.moleculeEditor && this.isMoleculeEditorLoaded && this.isAuthorizedToStartAnalysis;
  }

  public onElementListItemClick(elementListItem) {
    super.onElementListItemClick(elementListItem);
  }

  public selectEditMolecule() {
    if (this.elementList.length === 1 && this.isMoleculeEditorLoaded) {
      this.onElementListItemClick(this.elementList[0]);
    }
  }

  public displayInvalidError(msg: string = 'Invalid molecule') {
    this.errorMessage = msg;
    setTimeout(() => {
      this.errorMessage = '';
    }, 3500);
  }

  public getFilterButtonTitle() {
    if (this.injectedData.inputMode === 'EDIT FILTER') {
      return this.appConstantService.filterMoleculeEditorTitle.EditButtonTitle;
    }
    return this.appConstantService.filterMoleculeEditorTitle.ButtonTitle;
  }

  public getSmilesAndSmartsForFilter() {
    if (this.structureFilterType === filterTypes.Drawn) {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        const smilesString = this.moleculeSearchService.molToSmileWithRdkit(molFile);
        const smilesArray: string[] = smilesString.split('.');
        smilesArray.forEach((smiles) => {
          if (this.moleculeSearchService.verifySmilesWithRdkit(smiles)) {
            const smartsSmiles = [];
            if (smiles !== '') {
              smartsSmiles.push(smiles);
              const smarts = this.moleculeSearchService.smilesToSmartsWithRdkit(smiles);
              smartsSmiles.push(smarts[0]);
            }
            this.displayFilterStructure(smartsSmiles);
          } else {
            this.displayInvalidError();
          }
        });
      });
    } else {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        this.moleculeSearchService
          .MolToSmartsWithRdkit(molFile)
          .subscribe((smartsArray: string[]) => {
            const smartsSmiles = [];
            smartsArray.forEach((smarts) => {
              if (smarts !== '') {
                const smiles = this.moleculeSearchService.smartsToSmileWithRdkit(smarts);
                if (smiles !== '') {
                  smartsSmiles.push(smiles);
                }
                smartsSmiles.push(smarts);
                this.displayFilterStructure(smartsSmiles);
              } else {
                this.displayErrorMessage(`No ${this.elementType} to add.`);
              }
            });
          });
      });
    }
  }

  public removeFilter() {
    const filterCriteria: IFilterCriteria = {
      smiles: '',
      smarts: '',
      filterType: '',
      similarity: 0,
      showCategory: this.filterShownCategory,
      isFilterToBeRemoved: true,
    };
    this.dialogRef.close(filterCriteria);
  }

  private setFilterTypeWithSmilesSmarts(smartsSmiles: string[]) {
    const filterCriteria: IFilterCriteria = {
      smiles: '',
      smarts: '',
      filterType: '',
      similarity: 0,
      showCategory: this.filterShownCategory,
      isFilterToBeRemoved: false,
    };
    filterCriteria.smiles = smartsSmiles[0];
    filterCriteria.smarts = smartsSmiles[1];
    filterCriteria.filterType = this.structureFilterType;
    if (this.structureFilterType === filterTypes.Drawn) {
      filterCriteria.similarity = 100;
    } else if (this.structureFilterType === filterTypes.Similar) {
      filterCriteria.similarity = this.similarity * 100;
    } else {
      filterCriteria.similarity = this.similarity = 0;
    }
    return filterCriteria;
  }

  public validateSimilarity() {
    return this.similarity >= 0 && this.similarity <= 1;
  }

  public displayFilterStructure(smartsSmiles: any) {
    if (smartsSmiles && smartsSmiles.length > 0) {
      if (this.structureFilterType && this.validateSimilarity()) {
        this.dialogRef.close(this.setFilterTypeWithSmilesSmarts(smartsSmiles));
      } else if (!this.structureFilterType) {
        this.displayInvalidError('Select filter type');
      } else if (!this.validateSimilarity()) {
        this.displayInvalidError(this.appConstantService.invalidSimilarity);
      }
    } else {
      this.displayInvalidError();
    }
  }
}
