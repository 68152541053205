import { isString } from '../../components/utils';

export class Tag {
  /* tslint:disable:variable-name */
  public id: number = 0;
  public name: string = '';
  public user: number = 0;
  public analyses?: number[] = [];
  public checked: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to Tag.`);
        }
      } else {
        console.warn(`Unexpected field '${field}' in data provided to Tag.`);
      }
    }
  }
}
