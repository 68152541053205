import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageHandlerService } from 'src/app/shared/services/storage-handler.service';

@Component({
  selector: 'ch-ecomm-user-auth-dialog',
  templateUrl: './ecomm-user-auth-dialog.component.html',
  styleUrls: ['./ecomm-user-auth-dialog.component.scss'],
})
export class ECommUserAuthDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ECommUserAuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    private storageService: StorageHandlerService,
  ) {}

  public open() {
    if (this.data.url) {
      window.open(this.data.url, '_blank');
    }
    this.dialogRef.close();
  }

  public cancel() {
    this.storageService.eCommUserValidated.next('false');
    this.dialogRef.close();
  }
}
