<div #body [style.position]="'relative'">
  <div [ngSwitch]="displayMode">
    <div
      class="ch-reaction-details-card ch-bdr-grey-light"
      *ngSwitchCase="'normal'"
      [class.ch-bg-accent-semilight]="reaction.pointed && viewMode === ResultsView.GRAPH"
      [style.border-bottom]="reactionIndex === 1 ? '1px solid #e0e0e0' : null"
    >
      <div class="card-header">
        <span
          *ngIf="reactionFilter === ReactionListMode.GRAPH && !!reaction.count"
          [matTooltip]="appConstantsService.reactionAppearancesTooltip"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
          class="reaction-appearances"
        >
          <mat-icon>compare_arrows</mat-icon>
          <span>
            {{ reaction.count }}
          </span>
        </span>
        <mat-card-title class="reaction-name">
          <strong>{{ getReactionName() }}</strong>
        </mat-card-title>
        <div class="ch-reaction-details-corner-menu">
          <button
            mat-button
            [matMenuTriggerFor]="menuMolecule"
            class="ch-reaction-details-button ch-action-icon-hover"
            (menuOpened)="updateMenuOpenedValue(true)"
            (menuClosed)="updateMenuOpenedValue(false)"
          >
            <mat-icon class="corner-menu-icon grey-icon">more_vert</mat-icon>
          </button>
          <mat-menu #menuMolecule="matMenu" class="graph-node-menu">
            <ng-container>
              <ch-new-graph-node-menu
                [node]="reaction"
                [moleculeImageComponent]="moleculeImageComponent"
                [algorithmType]="algorithmType"
                [parentClass]="getParentClass()"
                (closeMenuInstance)="closeMenuInstance()"
                *ngIf="menuOpened"
              >
              </ch-new-graph-node-menu>
            </ng-container>
          </mat-menu>
        </div>
      </div>
      <mat-card-content class="normal-reaction-content">
        <div
          #image
          class="ch-reaction-details-image"
          [ngClass]="{ 'ch-cursor-pointer': viewMode === ResultsView.GRAPH }"
        >
          <ch-molecule-image
            #moleculeImage
            [format]="'svg'"
            [lazyLoad]="true"
            [formula]="reaction.reaction.smiles"
            [width]="300"
            [height]="200"
            [formulaType]="reactionNodeType"
          >
          </ch-molecule-image>
        </div>
        <span *ngFor="let substrate of reaction.substrateNodes; let i = index">
          <mat-card-subtitle>
            <strong>Reactant {{ i + 1 }}:</strong> {{ substrate.molecule.smiles }}
          </mat-card-subtitle>
        </span>
        <mat-card-subtitle *ngIf="reaction.reaction.year">
          <strong>Published in:</strong> {{ reaction.reaction.year }}
        </mat-card-subtitle>

        <mat-card-subtitle *ngIf="reaction.reaction.reference">
          <strong>Reference:</strong> {{ reaction.reaction.reference }}
        </mat-card-subtitle>
        <mat-card-subtitle
          *ngIf="reaction.notFiniteScore !== undefined && reaction.notFiniteScore !== null"
          class="ch-reaction-detail-infinity-warning ch-clr-warn"
        >
          <mat-icon class="icon-12">error_outline </mat-icon><br />
          <span>
            Sorting function evaluated the score for this reaction to be infinite and was set to 0.
          </span>
        </mat-card-subtitle>
        <mat-card-subtitle
          *ngIf="reaction.totalSortingScore !== undefined"
          class="ch-reaction-detail-sort-score"
        >
          <span> Sort score: {{ reaction.totalSortingScore }} </span>
        </mat-card-subtitle>
        <div class="ch-reaction-details-icons">
          <ng-container
            *ngFor="let molecule of reaction.substrateNodes; index as i; last as isLast"
          >
            <ch-molecule-icon
              class="icon"
              [reactionId]="reaction.reaction_node.id"
              [id]="'mol' + i"
              [color]="molecule.defaultColor"
              [symbol]="molecule.icon"
              borderColor="{{ molecule.defaultBorderColor }}"
            ></ch-molecule-icon>
            <ng-container *ngIf="!isLast">
              <div class="sign">+</div>
            </ng-container>
          </ng-container>
          <div class="sign arrow">&#8594;</div>
          <ng-container *ngFor="let product of reaction.productNodes; index as i; last as isLast">
            <ch-molecule-icon
              class="icon"
              [reactionId]="reaction.reaction_node.id"
              [id]="'product' + i"
              [color]="product.defaultColor"
              [symbol]="product.icon"
              borderColor="{{ product.defaultBorderColor }}"
            ></ch-molecule-icon>
            <ng-container *ngIf="!isLast">
              <div class="sign">+</div>
            </ng-container>
          </ng-container>
        </div>
      </mat-card-content>
    </div>

    <mat-card
      *ngSwitchCase="'similar'"
      class="ch-reaction-details-card ch-bdr-grey-light"
      [class.no-border-top]="reactionIndex === 0"
    >
      <mat-card-content>
        <table width="100%">
          <tr>
            <td width="450" align="center">
              <div class="ch-reaction-details-image-similarity">
                <ch-molecule-image
                  [width]="400"
                  [height]="200"
                  [formula]="reaction.smiles"
                  [formulaType]="reactionNodeType"
                >
                </ch-molecule-image>
              </div>
            </td>
            <td valign="top" class="reaction-info">
              <mat-card-subtitle class="ch-default-blue">
                <strong>{{ reaction.name }}</strong>
              </mat-card-subtitle>

              <mat-divider [inset]="true"></mat-divider>

              <div class="row" *ngIf="reaction.similarity">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Similarity</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : {{ reaction.similarity | number: '1.2-2' }}
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.yield_value">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Yield</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : {{ reaction.yield_value }}
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.conditions">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Reaction Conditions</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : {{ reaction.conditions }}
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.reaction_type">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Reaction type</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : {{ reaction.reaction_type }}
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.reference">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Reference</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : <span [innerHTML]="reaction.reference"></span>
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.doi">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>DOI</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    : <span class="truncate-on-overflow" [innerHTML]="reaction.doi"></span>
                  </mat-card-subtitle>
                </div>
              </div>

              <div class="row" *ngIf="reaction.patent_number">
                <div class="row-title">
                  <mat-card-subtitle class="ch-clr-grey">
                    <strong>Patent</strong>
                  </mat-card-subtitle>
                </div>
                <div class="row-value">
                  <mat-card-subtitle class="ch-clr-grey">
                    :
                    <span class="truncate-on-overflow" [innerHTML]="reaction.patent_number"></span>
                  </mat-card-subtitle>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>

    <mat-card
      *ngSwitchCase="'side'"
      class="ch-reaction-details-card ch-bdr-grey-light"
      [class.no-border-top]="reactionIndex === 0"
    >
      <mat-card-content>
        <table width="100%">
          <tr>
            <td width="50%" align="center">
              <div class="ch-reaction-details-image-similarity">
                <ch-molecule-image
                  [formula]="reaction.smiles"
                  [width]="400"
                  [height]="200"
                  [formulaType]="reactionNodeType"
                >
                </ch-molecule-image>
              </div>
            </td>
            <td valign="top" class="reaction-info">
              <mat-card-subtitle *ngIf="reaction.name" class="ch-clr-grey">
                <strong>Name:</strong> {{ reaction.name }}
              </mat-card-subtitle>
              <mat-card-subtitle *ngIf="reaction.rxid" class="ch-clr-grey">
                <strong>Retrosynthesis ID:</strong> {{ reaction.rxid }}
              </mat-card-subtitle>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
