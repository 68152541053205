import { isString } from '../../../components/utils';
import { stringFormat } from '../../../components/utils';

export class UserInventory {
  /* tslint:disable:variable-name */
  public id: string = '';
  public file_details: string = '';
  public file_name: string = '';
  public salt: string = '';
  /* tslint:enable:variable-name */

  constructor(userInventoryData: any) {
    const invalidFieldMessage: string = 'Unexpected field {0} in data provided to UserInventory';
    const invalidFieldValueMessage: string = `
      Unexpected value of field '{0}' in data provided to UserInventory.
      \nUsed default value of '{0}' instead of provided '{1}'.
    `;
    const modelData = isString(userInventoryData)
      ? JSON.parse(userInventoryData)
      : userInventoryData;
    for (const field of Object.keys(modelData)) {
      if (this.hasOwnProperty(field)) {
        if (typeof modelData[field] === typeof this[field]) {
          this[field] = modelData[field];
        } else {
          console.error(stringFormat(invalidFieldValueMessage, field, modelData[field]));
        }
      } else {
        console.error(stringFormat(invalidFieldMessage, field, modelData[field]));
      }
    }
  }
}
