<div class="ch-warning-dialog">
  <div class="text-container">
    <div class="warning-message" [innerHTML]="warningMessage"></div>
    <div class="sub-message" *ngIf="!isExpiryWarning">
      <span
        >To continue using Synthia<sup class="ch-trade-mark">TM</sup> Lite for new analyses, select
        Upgrade Options</span
      >
    </div>
  </div>
  <div class="dialog-controls">
    <button
      mat-button
      class="dialog-button ch-outline-btn"
      *ngIf="!isExpiryWarning"
      [buttonOutline]="'primary'"
      (click)="closeDialog()"
    >
      Remind Me Later
    </button>
    <button
      class="upgrade-button dialog-button"
      *ngIf="!isExpiryWarning"
      mat-raised-button
      color="primary"
      (click)="upgradePlan()"
    >
      Upgrade Options
    </button>
    <button
      class="ok-button"
      *ngIf="isExpiryWarning"
      mat-raised-button
      color="primary"
      (click)="closeDialog()"
    >
      OK
    </button>
  </div>
</div>
