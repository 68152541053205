import { map, flatMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ScoringFunction } from './models/scoring-function';
import { SimpleScoringFunction } from './models/simple-scoring-function';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { Catalog } from '../models/catalog';
import { ScoringFunctionCategoryType } from '../../components/scoring-function-utils';

const midendApiVersion = 'v2';

export const backendEntryPointScoringFunctions =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/scoring-functions/`;
export const backendEntryPointValidateFunction =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/validate-scoring-function/`;
export const backendEntryPointSimpleScoringFunctions =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/simplified-scoring-functions/`;
export const backendEntryPointCatalogs =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/buyable-catalogs`;
export const backendEntryPoint_FGI_TUNNEL_COEFFS =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/fgi-tunnel-coeffs/';

@Injectable()
export class ScoringFunctionsService {
  private static emptyListErrorMsg: string = 'Unexpected empty function list received from server';
  private static unexpectedFormatErrorMsg: string = 'Unexpected format of response.';

  private static resolveScoringFunctions(response: HttpResponse<any>): ScoringFunction[] {
    try {
      const scoringFunctionList = response.body;
      if (scoringFunctionList.length > 0) {
        return scoringFunctionList.map((func) => new ScoringFunction(func));
      } else {
        throw new Error(ScoringFunctionsService.emptyListErrorMsg);
      }
    } catch (err) {
      throw new Error(ScoringFunctionsService.unexpectedFormatErrorMsg);
    }
  }

  private static resolveSimpleScoringFunctions(
    response: HttpResponse<any>,
  ): SimpleScoringFunction[] {
    try {
      const simpleScoringFunctionList = response.body;
      if (simpleScoringFunctionList.length > 0) {
        return simpleScoringFunctionList.map((func) => new SimpleScoringFunction(func));
      }
      return [];
    } catch (err) {
      throw new Error(ScoringFunctionsService.unexpectedFormatErrorMsg);
    }
  }

  public reactionScoringFunctions: BehaviorSubject<ScoringFunction[]> = new BehaviorSubject<
    ScoringFunction[]
  >([]);
  public chemicalScoringFunctions: BehaviorSubject<ScoringFunction[]> = new BehaviorSubject<
    ScoringFunction[]
  >([]);
  public sortingScoringFunctions: BehaviorSubject<ScoringFunction[]> = new BehaviorSubject<
    ScoringFunction[]
  >([]);
  public simplifiedScoringFunctions: BehaviorSubject<SimpleScoringFunction[]> = new BehaviorSubject<
    SimpleScoringFunction[]
  >([]);

  constructor(private http: HttpClient) {
    this.getAndStoreScoringFunctions().subscribe();
  }

  public getAndStoreScoringFunctions(): Observable<Array<ScoringFunction[]>> {
    return this.getScoringFunctions().pipe(
      switchMap((scoringFunctionList: ScoringFunction[]) => {
        const reactionScoringFunctionList: ScoringFunction[] = [];
        const chemicalScoringFunctionList: ScoringFunction[] = [];
        const sortingScoringFunctionList: ScoringFunction[] = [];
        for (const scoringFunction of scoringFunctionList) {
          if (scoringFunction.category === ScoringFunctionCategoryType.REACTION_SCORE) {
            reactionScoringFunctionList.push(scoringFunction);
          } else if (scoringFunction.category === ScoringFunctionCategoryType.MOLECULE_SCORE) {
            chemicalScoringFunctionList.push(scoringFunction);
          } else if (scoringFunction.category === ScoringFunctionCategoryType.REACTION_ORDER) {
            sortingScoringFunctionList.push(scoringFunction);
          }
        }
        this.reactionScoringFunctions.next(reactionScoringFunctionList);
        this.chemicalScoringFunctions.next(chemicalScoringFunctionList);
        this.sortingScoringFunctions.next(sortingScoringFunctionList);
        return of([reactionScoringFunctionList, chemicalScoringFunctionList]);
      }),
    );
  }

  public getScoringFunctions() {
    return this.http.get(backendEntryPointScoringFunctions, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return ScoringFunctionsService.resolveScoringFunctions(response);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public saveNewFunction(functionCategory: string, functionName: string, functionCode: string) {
    const backendUrl: string = backendEntryPointScoringFunctions;
    const options = {
      category: functionCategory,
      name: functionName,
      source_code: functionCode,
    };
    return this.sendPostData(backendUrl, options);
  }

  public modifyExistingFunction(
    functionId: number,
    functionCategory: string,
    functionName: string,
    functionCode: string,
  ) {
    const backendUrl: string = backendEntryPointScoringFunctions + functionId + '/';
    const options = {
      category: functionCategory,
      name: functionName,
      source_code: functionCode,
    };
    return this.http.patch(backendUrl, options, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new ScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response.');
        }
      }),
    );
  }

  public deleteExistingFunction(functionId: number) {
    const backendUrl: string = backendEntryPointScoringFunctions + functionId + '/';

    return this.http.delete(backendUrl, { observe: 'response' }).pipe(map((result) => result.body));
  }

  // Simple scoring functions

  public getSimpleScoringFunctions() {
    return this.http.get(backendEntryPointSimpleScoringFunctions, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return ScoringFunctionsService.resolveSimpleScoringFunctions(response);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public getAndStoreSimpleScoringFunctions(): Observable<SimpleScoringFunction[]> {
    return this.getSimpleScoringFunctions().pipe(
      flatMap((simpleScoringFunctionList: SimpleScoringFunction[]) => {
        this.simplifiedScoringFunctions.next(simpleScoringFunctionList);
        return of(simpleScoringFunctionList);
      }),
    );
  }

  public saveSimpleScoringFunction(options: any) {
    const backendUrl: string = backendEntryPointSimpleScoringFunctions;
    return this.http.post(backendUrl, options, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new SimpleScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public deleteExistingSimpleScoringFunction(ssfId: number) {
    // this method actually gives the function an null name due to how
    // SSF work.
    const backendUrl: string = backendEntryPointSimpleScoringFunctions + ssfId + '/';
    const options = {
      name: null,
    };
    return this.http.patch(backendUrl, options, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new SimpleScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public updateExistingSimpleScoringFunction(ssfId: number, options: any) {
    const backendUrl: string = backendEntryPointSimpleScoringFunctions + ssfId + '/';
    return this.http.patch(backendUrl, options, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new SimpleScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public getDefaultSimpleScoringFunction(ssfId: number) {
    const backendUrl: string = backendEntryPointSimpleScoringFunctions + ssfId + '/';
    return this.http.get(backendUrl, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new SimpleScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public validateScoringFunction(functionCategory: string, functionCode: string) {
    const backendUrl: string = backendEntryPointValidateFunction;
    const options = {
      category: functionCategory,
      source_code: functionCode,
    };
    return this.sendPostData(backendUrl, options);
  }

  private sendPostData(backendUrl: string, options: any) {
    return this.http.post(backendUrl, options, { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        try {
          return new ScoringFunction(result.body);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public getCatalogs(): Observable<Catalog[]> {
    return this.http.get(backendEntryPointCatalogs, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((catalog) => new Catalog(catalog));
        } catch (e) {
          throw new Error('Unexpected format of catalog response.');
        }
      }),
    );
  }

  public getFgiAndTunnelCoeffs() {
    return this.http.get(backendEntryPoint_FGI_TUNNEL_COEFFS, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response.body;
        }
      }),
    );
  }
}
