import { Observable } from 'rxjs';
import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PdfExportDialogComponent } from './pdf-export-dialog.component';
import { IBackendPrintToPdfContent } from '../backend-print.service';

export interface IPdfContentProducer {
  getPdfContent(): Observable<IBackendPrintToPdfContent>;
}

@Injectable({
  providedIn: 'root',
})
export class PdfExportDialogService {
  private config: MatDialogConfig = {
    disableClose: false,
    width: `${PdfExportDialogComponent.defaultDialogWidthPercent}%`,
    height: `${PdfExportDialogComponent.defaultDialogHeightPercent}%`,
    maxWidth: `${PdfExportDialogComponent.defaultDialogWidthPercent}%`,
    maxHeight: `${PdfExportDialogComponent.defaultDialogHeightPercent}%`,
    position: { top: '', bottom: '', left: '', right: '' },
  };

  constructor(private dialog: MatDialog) {}

  /**
   * This method creates and open a standard PDF export dialog which is supposed to allow some user interaction before
   * PDF definition is sent to PDF export backend service. Created dialog take care for default layout of the dialog
   * (header, Cancel/Export buttons) as well as for further proceeding with provided PDF definition (sending it to print
   * backend, uploading the result, errors handling).
   *
   * Content component specified by the first parameter is included in the central part of dialog. It's only role is
   * to handle interaction related to preparation of the PDF definition.
   *
   * Content component class need to implement IPdfContentProducer interface which method is called when the user
   * selects Export button; returned IBackendPrintToPdfContent object is then sent to PDF export backend service
   * and produced PDF uploaded.
   *
   * Content component will receive the data parameter as usual with materials dialog components. However data object
   * it will have an additional 'host' field set which refers to the PdfExportDialogComponent in which the content
   * component is embedded. This allows to adjust some public attributes such as title (presented in dialog header)
   * or readyToExport flag which controls activity of Export button.
   *
   * If the content component implements onContentResize(width: number, height: number) method (optional) it will be
   * called each time the area of the content component is modified.
   *
   * @param contentComponentClass Class name of component to include in the content part of opened print dialog.
   * @param {object} data A data object available in component as MAT_DIALOG_DATA injection.
   * @param {ViewContainerRef} viewContainerRef
   * @returns {Observable<any>}
   */
  public open(contentComponentClass: any, data: object, viewContainerRef?: ViewContainerRef) {
    this.config.viewContainerRef = viewContainerRef;
    this.config.data = data;
    this.config.data.contentComponent = contentComponentClass;
    const dialogRef: MatDialogRef<PdfExportDialogComponent> = this.dialog.open(
      PdfExportDialogComponent,
      this.config,
    );
    return dialogRef.beforeClosed();
  }
}
