import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AnalysisResultsService } from '../services/analysis-results/analysis-results.service';
import { GraphNodePopupService } from '../services/graph-node-popup/graph-node-popup.service';
import { GraphReactionNode, GraphNodeType, GraphMoleculeNode } from '../services/graph-builder';
import { setTopologicalNodesRank } from '../components/graph-utils';
import { PathDirection } from '../services/analysis/analysis.service';

export enum NavigationButtonType {
  UP_ARROW = 'UP_ARROW',
  DOWN_ARROW = 'DOWN_ARROW',
  LEFT_ARROW = 'LEFT_ARROW',
  RIGHT_ARROW = 'RIGHT_ARROW',
}

@Injectable()
export class ReactionNavigationService {
  public buttonsEnabled: NavigationButtonType[] = [];
  public node: GraphReactionNode | GraphMoleculeNode = null;
  public path: any = null;
  public allReactionNodes: GraphReactionNode[] = [];
  public allReactionNodesSorted: GraphReactionNode[] = [];
  public pathDirection: PathDirection = PathDirection.LeftToRight;

  constructor(
    public analysisResultsService: AnalysisResultsService,
    public popupService: GraphNodePopupService,
  ) {}

  public getPath(pathId: number): Observable<any> {
    let selectedPath = [];

    return new Observable((observer) => {
      this.analysisResultsService.filteredPathList.subscribe((pathList) => {
        selectedPath = pathList.filter((path) => {
          return path.syntheticPath === pathId;
        });
        observer.next(selectedPath);
      });
    });
  }

  public getEnabledNavigationButtonList(
    node: GraphReactionNode | GraphMoleculeNode,
    pathDirection: PathDirection,
  ): Observable<NavigationButtonType[]> {
    this.resetData();
    this.node = node;
    this.pathDirection = pathDirection;
    return new Observable((observer) => {
      this.getPath(node.graphId).subscribe((path) => {
        if (path && path[0]) {
          const selectedPath = path[0];
          this.path = selectedPath;
          setTopologicalNodesRank(this.path.nodes);
          this.allReactionNodes = this.getAllReactionNodes();
          this.allReactionNodesSorted = this.sortReactionOnTopologicalRank(this.allReactionNodes);
          this.setTopologicalRankToSelectedNode();

          if (!this.isReactionNode(this.node)) {
            this.getMoleculeNodeNavigationButton();
          } else {
            this.getReactionNodeNavigationButton();
          }
        }
        observer.next(this.buttonsEnabled);
      });
    });
  }

  public getMoleculeNodeNavigationButton() {
    if (this.getChildReactionForMolecule()) {
      this.addToButtonList(this.getButtonTypeWithPathDirection(NavigationButtonType.LEFT_ARROW));
    }
    if (this.getParentReactionForMolecule()) {
      this.addToButtonList(this.getButtonTypeWithPathDirection(NavigationButtonType.RIGHT_ARROW));
    }
  }

  public getReactionNodeNavigationButton() {
    if (this.allReactionNodes.length <= 1) {
      this.buttonsEnabled = [];
    } else {
      if (this.getParentReactionForReaction()) {
        this.addToButtonList(this.getButtonTypeWithPathDirection(NavigationButtonType.RIGHT_ARROW));
        if (!this.hasNoChildReaction()) {
          this.addToButtonList(
            this.getButtonTypeWithPathDirection(NavigationButtonType.LEFT_ARROW),
          );
        }
      } else {
        this.addToButtonList(this.getButtonTypeWithPathDirection(NavigationButtonType.LEFT_ARROW));
      }
      this.checkParallelReaction();
    }
  }

  public isReactionNode(node: GraphMoleculeNode | GraphReactionNode): boolean {
    return node.type === GraphNodeType.REACTION || node.type === GraphNodeType.EXPANDED_REACTION;
  }

  public getParentReactionForMolecule(): boolean {
    return this.node.parentReactionNodeId ? this.node.parentReactionNodeId : null;
  }

  public getChildReactionForMolecule(): boolean {
    return this.node.childReactionNodeId ? this.node.childReactionNodeId : null;
  }

  public getParentReactionForReaction() {
    return this.node.reaction_node.parent_reaction_node
      ? this.node.reaction_node.parent_reaction_node
      : null;
  }

  public getAllReactionNodes() {
    let reactionNodes = [];
    reactionNodes = this.path.nodes.filter((node) => {
      if (this.isReactionNode(node)) {
        node.graphId = this.path.syntheticPath;
        return node;
      }
    });
    return reactionNodes;
  }

  public hasNoChildReaction() {
    let noChildReaction = true;
    this.node.nodes.map((moleculeNode) => {
      noChildReaction = noChildReaction && !!moleculeNode.isLastSubstrate;
    });
    return noChildReaction;
  }

  public checkParallelReaction() {
    /**
     * Assume that node with smaller id will be on top
     */
    this.allReactionNodes.map((node) => {
      if (node.topologicalRank === this.node.topologicalRank && node.id !== this.node.id) {
        if (node.id < this.node.id) {
          this.addToButtonList(NavigationButtonType.UP_ARROW);
        } else {
          this.addToButtonList(NavigationButtonType.DOWN_ARROW);
        }
      }
    });
  }

  public sortReactionOnTopologicalRank(allReactionNodes: GraphReactionNode[]) {
    const reactionNodes = [...allReactionNodes];
    return reactionNodes.sort((a, b) => b.topologicalRank - a.topologicalRank);
  }

  public resetData() {
    this.allReactionNodesSorted = [];
    this.allReactionNodes = [];
    this.buttonsEnabled = [];
  }

  public setTopologicalRankToSelectedNode() {
    let topologicalRank = null;
    this.path.nodes.map((node) => {
      if (node.id === this.node.id) {
        topologicalRank = node.topologicalRank;
      }
    });
    this.node.topologicalRank = topologicalRank;
  }

  public getReactionToNavigate(
    type: NavigationButtonType,
    node: GraphMoleculeNode | GraphReactionNode,
    pathDirection: PathDirection,
  ) {
    this.node = node;
    this.pathDirection = pathDirection;
    let reactionNode = null;
    if (!this.isReactionNode(node)) {
      reactionNode = this.getReactionFromMoleculeNode(type);
    } else {
      reactionNode = this.getReactionFromReactionNode(type);
    }
    return reactionNode;
  }

  public getReactionFromMoleculeNode(type: NavigationButtonType) {
    let reactionNode;
    let reactionId;
    if (type === NavigationButtonType.LEFT_ARROW) {
      reactionId =
        this.pathDirection === PathDirection.LeftToRight
          ? this.getChildReactionForMolecule()
          : this.getParentReactionForMolecule();
    } else if (type === NavigationButtonType.RIGHT_ARROW) {
      reactionId =
        this.pathDirection === PathDirection.LeftToRight
          ? this.getParentReactionForMolecule()
          : this.getChildReactionForMolecule();
    }
    reactionNode = this.node.target
      ? this.getFinalReaction()
      : this.findReactionFromList(reactionId);
    return reactionNode;
  }

  public getReactionFromReactionNode(type: NavigationButtonType) {
    let reactionNode;
    let reactionId;
    switch (type) {
      case NavigationButtonType.LEFT_ARROW:
        reactionId =
          this.pathDirection === PathDirection.LeftToRight
            ? this.getChildReactionForReaction()
            : this.getParentReactionForReaction();
        break;
      case NavigationButtonType.RIGHT_ARROW:
        reactionId =
          this.pathDirection === PathDirection.LeftToRight
            ? this.getParentReactionForReaction()
            : this.getChildReactionForReaction();
        break;
      case NavigationButtonType.UP_ARROW:
        reactionId = this.getParallelReactionForReaction(NavigationButtonType.UP_ARROW);
        break;
      case NavigationButtonType.DOWN_ARROW:
        reactionId = this.getParallelReactionForReaction(NavigationButtonType.DOWN_ARROW);
        break;
    }
    reactionNode = this.findReactionFromList(reactionId);
    return reactionNode;
  }

  public getChildReactionForReaction() {
    /**
     * If more than one child reactions
     * Child reaction  of reaction will be 1st child reaction
     * Molecule nodes need to sort by id to get nodes in correct order
     */

    let reactionId = null;
    const moleculeNodes = [...this.node.nodes];
    moleculeNodes.sort((a, b) => a.id - b.id);
    moleculeNodes.map((moleculeNode) => {
      if (moleculeNode.childReactionNodeId && !reactionId) {
        reactionId = moleculeNode.childReactionNodeId;
      }
    });
    return reactionId;
  }

  public getParallelReactionForReaction(type: NavigationButtonType) {
    /**
     * Assume that node with smaller id will be on top
     */
    const parallelNodes = [];
    let reactionId = null;
    this.allReactionNodes.filter((node) => {
      if (node.topologicalRank === this.node.topologicalRank && this.node.id !== node.id) {
        parallelNodes.push(node);
      }
    });
    parallelNodes.sort((a, b) => a.id - b.id);
    parallelNodes.map((node) => {
      if (
        type === NavigationButtonType.DOWN_ARROW &&
        reactionId === null &&
        node.id > this.node.id
      ) {
        reactionId = node.reaction_node.id;
      } else if (type === NavigationButtonType.UP_ARROW && node.id < this.node.id) {
        reactionId = node.reaction_node.id;
      }
    });
    return reactionId;
  }

  public findReactionFromList(reactionId: number) {
    let reactionNode = null;
    this.allReactionNodes.map((node) => {
      if (node.reaction_node.id === reactionId) {
        reactionNode = node;
      }
    });
    return reactionNode;
  }

  public getFinalReaction() {
    let reactionNode = null;
    this.allReactionNodes.map((node) => {
      if (!node.reaction_node.parent_reaction_node) {
        reactionNode = node;
      }
    });
    return reactionNode;
  }

  public addToButtonList(value: NavigationButtonType) {
    this.buttonsEnabled.push(value);
    // To remove if any duplication in values
    this.buttonsEnabled = Array.from(new Set(this.buttonsEnabled));
  }

  public getButtonTypeWithPathDirection(type: NavigationButtonType) {
    if (type === NavigationButtonType.LEFT_ARROW) {
      return this.pathDirection === PathDirection.LeftToRight
        ? NavigationButtonType.LEFT_ARROW
        : NavigationButtonType.RIGHT_ARROW;
    } else if (type === NavigationButtonType.RIGHT_ARROW) {
      return this.pathDirection === PathDirection.LeftToRight
        ? NavigationButtonType.RIGHT_ARROW
        : NavigationButtonType.LEFT_ARROW;
    }
  }
}
