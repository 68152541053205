<ng-container *ngIf="isUsingApplicationAllowed(); else disallowMessage">
  <div id="hidden-marvin"></div>
  <main class="ch-main-container new-ui-main">
    <ch-main-frame class="ch-main-frame-div"></ch-main-frame>
  </main>
</ng-container>

<ng-template #disallowMessage>
  <div
    [class.ch-main-bg]="!isSynthiaLiteEnabled"
    [class.ch-synthia-lite-main-bg]="isSynthiaLiteEnabled"
  >
    <ch-unauthorized-view-header></ch-unauthorized-view-header>
    <div class="ch-logo-panel-title" *ngIf="!isSynthiaLiteEnabled" [innerHtml]="logoText"></div>
    <mat-card class="application-not-allowed-content">
      <span *ngIf="!isBrowserAllowed">
        Your browser version is not compatible with Synthia<sup class="ch-trade-mark fs-11-px"
          >TM</sup
        >
        <span *ngIf="isSynthiaLiteEnabled">Lite</span> application.<br />
        Please use the latest versions of Chrome, Firefox or MS Edge browsers.
      </span>
      <span *ngIf="unauthorizedIFrame">
        If you can see this message it is possible that somebody sent you a false link to Synthia
        <sup class="ch-trade-mark fs-11-px">TM</sup> <span *ngIf="isSynthiaLiteEnabled">Lite</span>
        in order to steal your credentials. If the problem persists, contact your administrator
        about this issue.
      </span>
    </mat-card>
  </div>
  <ch-copyright></ch-copyright>
</ng-template>
