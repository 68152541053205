import { InfoService } from './shared/services/info.service';
import { ParsedConnectionError } from './shared/services/errors-handler/errors-handler.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { getLocalCopy } from './shared/components/utils';
import { MidendConfiguration } from './shared/services/models/midend-configuration';
import { SSOConfiguration } from './shared/services/models/sso-configuration';
import { PasswordEncryptionConfiguration } from './shared/services/models/password-encryption-configuration';
import { AppConstantsService } from './shared/services/app-constants/app-constants.service';
import { map, catchError } from 'rxjs/operators';
import { PictogramData } from './shared/services/models/pictogram-data';
const GET_IP_URL = 'https://ipapi.co/json';
const IP_URL_KEY = 'TlJLaLx1r0o9vAq3FEZUxfaQEVM9XrdT5Kdf7O4BCJGcmJ4xHb';
const BackendEntryPointConfiguration = APP_CONFIG.CHEMATICA_API_URL + `/api/v1/config/`;
const BackendEntryPointFirebaseConfiguration =
  APP_CONFIG.CHEMATICA_API_URL + `/api/v1/firebase-config/`;
const BackendEntryPointPasswordEncryptionConfiguration =
  APP_CONFIG.CHEMATICA_API_URL + `/api/v1/encryption-config/`;
const GetPictogramData = APP_CONFIG.CHEMATICA_API_URL + `/api/v1/pictogram-data/`;
export interface IInternalState {
  [key: string]: any;
}

@Injectable()
export class AppState {
  private static MiliporeSigmaLogoCountries: string[] = ['CA', 'US'];

  public update: BehaviorSubject<{ [key: string]: any }>;
  public country: BehaviorSubject<string>;
  public midendConfiguration: BehaviorSubject<MidendConfiguration>;
  public passwordEncryptionConfiguration: BehaviorSubject<PasswordEncryptionConfiguration>;
  public pictogramData: BehaviorSubject<PictogramData[]>;

  private _state: IInternalState = {};

  constructor(
    private http: HttpClient,
    private appConstantsService: AppConstantsService,
    private infoService: InfoService,
  ) {
    this.update = new BehaviorSubject<{ [key: string]: any }>({ '': null });
    this.country = new BehaviorSubject<string>('');
    this.midendConfiguration = new BehaviorSubject<MidendConfiguration>(null);
    this.passwordEncryptionConfiguration = new BehaviorSubject<PasswordEncryptionConfiguration>(
      null,
    );
    this.pictogramData = new BehaviorSubject<PictogramData[]>([]);
  }

  public get state(): IInternalState {
    return getLocalCopy(this._state);
  }

  public set state(value) {
    throw new Error(
      'You can not mutate the `AppState.state` directly. Use AppState.set(property, value) method.',
    );
  }

  public get(property?: any) {
    return this._state.hasOwnProperty(property) ? getLocalCopy(this._state[property]) : this.state;
  }

  public set(property: string, value: any) {
    const change = this._state[property] !== value;
    const result = (this._state[property] = value);
    if (change) {
      const newState = {};
      newState[property] = value;
      this.update.next(newState);
    }
    return result;
  }

  // Get user public IP and country
  public getIpAddress() {
    const queryParams: HttpParams = new HttpParams().set('key', IP_URL_KEY);
    return this.http.get(GET_IP_URL, { params: queryParams, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        this.setCountryCode(response);
        return response;
      }),
    );
  }

  public isMilliporeSigmaCountry(country: string = this.country.value) {
    return AppState.MiliporeSigmaLogoCountries.includes(country);
  }

  public loadFirebaseAppConfig() {
    if (APP_CONFIG.SSO_MODE) {
      return this.http
        .get(BackendEntryPointFirebaseConfiguration)
        .toPromise()
        .then((data: SSOConfiguration) => {
          fireBaseConfig = new SSOConfiguration(data);
        })
        .catch((error) => {
          const parsedError: ParsedConnectionError = new ParsedConnectionError(error);
          this.infoService.showError(parsedError.promptMessage);
        });
    } else {
      fireBaseConfig = new SSOConfiguration(this.appConstantsService.dummyFirebaseConfig);
    }
  }

  public getMidendConfiguration() {
    this.http.get(BackendEntryPointConfiguration, { observe: 'response' }).subscribe(
      (response) => {
        this.midendConfiguration.next(new MidendConfiguration(response.body));
      },
      (error) => {
        console.error('Midend config API is unavailable: ', error);
        const parsedError: ParsedConnectionError = new ParsedConnectionError(error);
        this.infoService.showError(parsedError.promptMessage);
      },
    );
  }

  public getPaswordEncryptionConfiguration() {
    this.http
      .get(BackendEntryPointPasswordEncryptionConfiguration, { observe: 'response' })
      .subscribe(
        (response) => {
          this.passwordEncryptionConfiguration.next(
            new PasswordEncryptionConfiguration(response.body),
          );
        },
        (error) => console.error('Pasword encryption config API is unavailable: ', error),
      );
  }

  public getPictogramData() {
    return this.http.get(GetPictogramData, { observe: 'response', responseType: 'json' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const pictogramData: PictogramData[] = [...response.body];
          this.pictogramData.next(pictogramData);
        } catch (e) {
          throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  private setCountryCode(response: HttpResponse<any>) {
    try {
      const ipInfo = response.body;
      this.country.next(ipInfo.country_code);
    } catch (e) {
      throw new Error('Unexpected format of response');
    }
  }
}

export let fireBaseConfig: SSOConfiguration = {
  apiKey: '',
  authDomain: '',
  providerId: '',
};
