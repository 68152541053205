<div class="ch-search-bar" [class.no-results]="noResults">
  <div class="ch-search-bar-input-container" tourAnchor="search-molecule">
    <input
      maxlength="2048"
      type="text"
      ch-autofocus
      autocomplete="off"
      class="ch-search-bar-input"
      [(ngModel)]="searchTerm"
      (input)="onInput.emit($event)"
      (ngModelChange)="onChanges.emit($event)"
      (keyup.enter)="onConfirm($event)"
      name="search"
      #input
    />
    <button
      mat-raised-button
      color="primary"
      class="search-button"
      [disabled]="searchLoading"
      (click)="onConfirm()"
      [matTooltip]="searchButtonTooltip"
      matTooltipShowDelay="750"
      matTooltipPosition="above"
    >
      <mat-icon *ngIf="!searchLoading" class="noselect">search</mat-icon>
      <mat-spinner
        [strokeWidth]="2"
        [diameter]="25"
        *ngIf="searchLoading"
        class="search-spinner"
        mode="indeterminate"
      ></mat-spinner>
    </button>
  </div>
  <div *ngIf="noResults" class="ch-search-bar-no-results">
    {{ noResultsMessage }}
  </div>
</div>
