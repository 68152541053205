<div
  class="ch-pdf-export-dialog-wrapper"
  [style.width.px]="contentWidth"
  [style.height.px]="headerHeight + contentHeight + footerHeight"
>
  <div class="ch-pdf-export-dialog-header ch-dialog-top" [style.height.px]="headerHeight">
    <span>{{ title }}</span>
  </div>
  <div
    class="ch-pdf-export-dialog-content"
    [style.max-height.px]="contentHeight"
    [style.top.px]="headerHeight"
  >
    <ng-template [cdkPortalOutlet]></ng-template>
  </div>
  <div class="ch-pdf-export-dialog-footer ch-dialog-bottom" [style.height.px]="footerHeight">
    <div class="ch-dialog-controls">
      <button mat-stroked-button color="primary" (click)="dialogRef.close()" class="cancel-btn-pdf">
        Cancel
      </button>
      <button mat-raised-button (click)="export()" color="primary" [disabled]="!isReadyToExport()">
        Export to PDF
      </button>
    </div>
  </div>
</div>
