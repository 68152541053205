import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit,
  Renderer2,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSlider } from '@angular/material/slider';
import { merge, Subject } from 'rxjs';
import { tap, takeUntil, map, debounceTime } from 'rxjs/operators';

import { AppConstantsService } from '../../services';

export interface MatRangeSliderChange<T> {
  value: T;
}

@Component({
  selector: 'ch-mat-range-slider',
  templateUrl: './mat-range-slider.component.html',
  styleUrls: ['./mat-range-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MatRangeSliderComponent,
      multi: true,
    },
  ],
})
export class MatRangeSliderComponent<T extends { min: number; max: number }>
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy {
  @ViewChild('sMin') public sliderMin: MatSlider;
  @ViewChild('sMax') public sliderMax: MatSlider;
  @Input() public tabIndex: number;
  @Input() public max: number;
  @Input() public min: number;
  @Input() public step: number;
  @Input() public vertical: boolean;
  @Input() public thumbLabel: boolean;
  @Input() public disabled: boolean;
  @Input()
  get value(): T | null {
    if (this.value === null) {
      this.value = this.formGroupValueCorrected;
    }
    return this.value;
  }
  set value(value: T | null) {
    this.writeToFormGroup(value);
  }
  @Input() public showRange: boolean = false;
  @Input() public dynamicRangeMin: number = 0;
  @Input() public dynamicRangeMax: number = 0;
  @Output() public readonly change = new EventEmitter<MatRangeSliderChange<T>>();
  /** Event emitted when the slider thumb moves. */
  @Output() public readonly input = new EventEmitter<MatRangeSliderChange<T>>();
  /**
   * Emits when the raw value of the slider changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   * @docs-private
   */
  @Output() public readonly valueChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() public readonly isSliderDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public beforeOnInit = true;
  public fillBarEl: HTMLElement;
  public switchCase = false;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    min: new UntypedFormControl(),
    max: new UntypedFormControl(),
  });
  public rangeWidth: number = 0;
  public rangeMinPos: number = 0;
  public rangeTooltip: string;
  public disableRangeTooltip: boolean = false;
  public isSameMaxMin: boolean = false;
  public isNoValueSlider: boolean = false;
  public isNormalSlider: boolean = true;
  public isSameMaxMinRange: boolean = false;

  private unsubscribe = new Subject();

  get formGroupValueCorrected() {
    const correctedFormValue =
      this.formGroup.value.max < this.formGroup.value.min
        ? { min: this.formGroup.value.max, max: this.formGroup.value.min }
        : this.formGroup.value;
    return correctedFormValue;
  }

  constructor(public appConstantsService: AppConstantsService, private renderer: Renderer2) {
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value: T) => {
      if (value.min > value.max) {
        value = { min: value.max, max: value.min } as T;
      }
      this._onChange(value);
    });
  }

  /** `View -> model callback called when value changes` */
  public _onChange: (value: T) => void = () => {};

  /** `View -> model callback called when autocomplete has been touched` */
  public _onTouched = () => {};

  public ngOnInit() {
    this.vertical = false;
    this.thumbLabel = true;
    this.formGroup.enable();
    this.resetFormGroup(true);
    this.checkSliderType();
    this.getDynamicRange();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.beforeOnInit) {
      return;
    }
    const fgv = this.formGroup.value;
    if (changes['max']) {
      if (changes['max'].currentValue < fgv.max) {
        if (this.formGroup.get('max')) {
          const c = this.switchCase ? 'min' : 'max';
          this.formGroup.get(c).setValue(changes[c].currentValue);
        }
      }
      this.checkSliderType();
      this.calculateFillBar();
    }
    if (changes['min']) {
      if (changes['min'].currentValue > fgv.min) {
        if (this.formGroup.get('min')) {
          const c = this.switchCase ? 'max' : 'min';
          this.formGroup.get(c).setValue(changes[c].currentValue);
        }
      }
      this.checkSliderType();
      this.calculateFillBar();
    }
    if (changes['value']) {
      this.calculateFillBar();
    }
    if (changes['vertical']) {
      if (changes['vertical'].currentValue) {
        this.renderer.setStyle(this.fillBarEl, 'margin-left', null);
        this.renderer.setStyle(this.fillBarEl, 'width', null);
      } else {
        this.renderer.setStyle(this.fillBarEl, 'bottom', null);
        this.renderer.setStyle(this.fillBarEl, 'height', null);
      }
      this.calculateFillBar();
    }

    if (changes['dynamicRangeMax'] || changes['dynamicRangeMin']) {
      this.getDynamicRange();
    }
  }

  public ngAfterViewInit() {
    const a = merge(
      this.sliderMax.valueChange.pipe(map((max) => this.correctRange(max, 'max'))),
      this.sliderMin.valueChange.pipe(map((min) => this.correctRange(min))),
    ).pipe(tap((value) => this.valueChange.next(value)));
    const b = merge(
      this.sliderMax.input.pipe(map((_) => this.correctRange(_.value, 'max'))),
      this.sliderMin.input.pipe(map((_) => this.correctRange(_.value))),
    ).pipe(
      debounceTime(1000),
      tap((value) => {
        this.input.next({ value: value });
        this.calculateFillBar(value);
      }),
    );

    const c = merge(
      this.sliderMax.change.pipe(map((_) => this.correctRange(_.value, 'max'))),
      this.sliderMin.change.pipe(map((_) => this.correctRange(_.value))),
    ).pipe(tap((value) => this.change.next({ value: value })));
    merge(a, b, c).pipe(takeUntil(this.unsubscribe)).subscribe();
    this.fillBarEl = this.sliderMax._elementRef.nativeElement.children[0].children[0].children[1];
    this.beforeOnInit = false;
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** If min overtakes max or other way around we have to correct for that */
  public correctRange(
    value: number,
    useCase: 'min' | 'max' = 'min',
    formGroupValue = this.formGroup.value,
  ) {
    let correctedRange: any;
    if (useCase === 'min') {
      if (value <= formGroupValue.max) {
        correctedRange = { ...formGroupValue, min: value };
        this.switchCase = false;
      } else {
        correctedRange = { min: formGroupValue.max, max: value };
        this.switchCase = true;
      }
    } else {
      if (value > formGroupValue.min) {
        correctedRange = { ...formGroupValue, max: value };
        this.switchCase = false;
      } else {
        correctedRange = { min: value, max: formGroupValue.min };
        this.switchCase = true;
      }
    }
    return correctedRange;
  }

  /** On (input) of mat-slider we need to span the fillbar between min and max */
  public calculateFillBar(value: T = this.formGroupValueCorrected) {
    // TODO(optimise) we dont have to calc this every time! Use onChanges hook
    if (!this.fillBarEl) {
      return;
    }
    const range = this.max - this.min;
    // width in percent
    const widthInPercent = ((value.max - value.min) / range) * 100;
    const myDim = this.vertical ? 'height' : 'width';
    this.renderer.setStyle(this.fillBarEl, myDim, widthInPercent + '%');
    let marginLeftPercent;
    // margin-left in percent
    marginLeftPercent = ((value.min - this.min) / range) * 100;
    const myMargin = this.vertical ? 'bottom' : 'margin-left';
    this.renderer.setStyle(this.fillBarEl, myMargin, marginLeftPercent + '%');
  }

  // Implemented as part of ControlValueAccessor.
  public writeValue(value: any): void {
    this.writeToFormGroup(value);
    this.calculateFillBar();
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnChange(fn: (value: T) => {}): void {
    this._onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnTouched(fn: () => {}) {
    this._onTouched = fn;
  }
  // Implemented as part of ControlValueAccessor.
  public setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  public resetFormGroup(emit: boolean = false) {
    this.formGroup.setValue(
      {
        min: this.min ? this.min : 0,
        max: this.max ? this.max : 100,
      },
      { emitEvent: emit },
    );
  }

  public writeToFormGroup(value: any) {
    if (!value && value !== 0) {
      this.resetFormGroup();
      return;
    }
    const tryValue = Number(value);
    const formValue = this.formGroup.value;
    if (!Number.isNaN(tryValue)) {
      if (tryValue !== formValue.min && tryValue !== formValue.max) {
        let correctedFormValue = {};
        correctedFormValue =
          tryValue <= formValue.max
            ? { ...formValue, min: tryValue }
            : { ...formValue, max: tryValue };
        this.formGroup.setValue(correctedFormValue, { emitEvent: false });
      }
      return;
    }
    const valueFromMinSlider =
      typeof value === 'object' &&
      value !== null &&
      value.hasOwnProperty('min') &&
      value.hasOwnProperty('max');
    if (valueFromMinSlider) {
      const valueFromMaxSlider =
        !Number.isNaN(Number(value.min)) && !Number.isNaN(Number(value.max));
      if (valueFromMaxSlider) {
        this.formGroup.setValue(value);
      } else {
        this.resetFormGroup();
      }
      this.setIsSameMaxMinRange();
      return;
    }
    this.resetFormGroup();
  }

  public minSliderChange(event: any) {
    if (event.value > this.formGroup.value.max - 1) {
      this.formGroup.patchValue({ min: this.formGroup.value.max, max: event.value });
      this.formGroup.disable();
      setTimeout(() => {
        this.formGroup.enable();
        this.disableRangeTooltip = false;
      }, 2000);
    }
    this.setIsSameMaxMinRange();
    this.disableRangeTooltip = true;
  }

  public maxSliderChange(event: any) {
    if (event.value < this.formGroup.value.min + 1) {
      this.formGroup.patchValue({ max: this.formGroup.value.min, min: event.value });
      this.formGroup.disable();
      setTimeout(() => {
        this.formGroup.enable();
        this.disableRangeTooltip = false;
      }, 500);
    }
    this.setIsSameMaxMinRange();
    this.disableRangeTooltip = true;
  }

  public sliderFocusOut() {
    this.setIsSameMaxMinRange();
    this.disableRangeTooltip = false;
  }

  private checkSliderType() {
    this.isSameMaxMin = this.min === this.max && this.min !== 0;
    this.isNoValueSlider = this.min === this.max && this.min === 0;
    this.isNormalSlider = !this.isSameMaxMin && !this.isNoValueSlider;
    this.isSliderDisabled.emit(!this.isNormalSlider);
  }

  private setIsSameMaxMinRange() {
    this.isSameMaxMinRange =
      this.formGroup &&
      this.formGroup.value &&
      this.formGroup.value.max === this.formGroup.value.min;
  }

  private getDynamicRange() {
    if (!this.showRange) {
      return;
    }

    if (
      this.dynamicRangeMin > this.dynamicRangeMax ||
      this.dynamicRangeMin > this.max ||
      this.dynamicRangeMax < this.min ||
      this.min === this.max
    ) {
      this.rangeWidth = 0;
      this.rangeMinPos = 0;
      return;
    }

    this.dynamicRangeMin = Math.max(this.dynamicRangeMin, this.min);
    this.dynamicRangeMax = Math.min(this.dynamicRangeMax, this.max);

    const diff = this.max - this.min;
    const percentageMin = ((this.dynamicRangeMin - this.min) / diff) * 100;
    const percentageMax = ((this.dynamicRangeMax - this.min) / diff) * 100;
    this.rangeWidth = percentageMax - percentageMin;
    this.rangeMinPos = percentageMin;

    if (this.dynamicRangeMin === this.dynamicRangeMax) {
      this.rangeWidth = 1;
    }

    let tooltip = this.appConstantsService.replaceVariableFromMessage(
      this.appConstantsService.filterRangeTooltip,
      '<min>',
      this.dynamicRangeMin,
    );
    tooltip = this.appConstantsService.replaceVariableFromMessage(
      tooltip,
      '<max>',
      this.dynamicRangeMax,
    );
    this.rangeTooltip = tooltip;
  }
}
