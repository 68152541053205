import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  ExtendedSnackBarComponent,
  SnackBarData,
} from '../components/extended-snack-bar/extended-snack-bar.component';
import { InfoDialogService, InfoDialogContent } from './info-dialog.service';

@Injectable()
export class InfoService {
  private snackBarRef: MatSnackBarRef<any>;
  private snackBarConfig: MatSnackBarConfig = {
    panelClass: 'info-snackbar-wide',
  };

  constructor(
    private snackBar: MatSnackBar,
    private infoDialogService: InfoDialogService,
    private zone: NgZone,
  ) {}

  public showError(message: string, duration?: number, errorDetails?: string) {
    const snackBarData: SnackBarData = {
      message: message,
      buttonText: errorDetails ? 'More' : undefined,
      icon: 'error',
    };
    this.zone.run(() => {
      setTimeout(() => {
        this.snackBarRef = this.showSnackBar(snackBarData, duration);
        this.snackBarRef.onAction().subscribe(() => {
          const errorContent = new InfoDialogContent();
          errorContent.title = 'An error has occurred';
          errorContent.message = errorDetails;
          this.infoDialogService.info(errorContent);
        });
      }, 0);
    });
  }

  public showInfoWithUndoBtn(message: string, duration?: number) {
    const snackBarData: SnackBarData = {
      message: message,
      buttonText: 'UNDO',
      icon: 'info',
    };
    this.snackBarRef = this.showSnackBar(snackBarData, duration ? duration : 7500);
    return this.snackBarRef.onAction();
  }

  public showInfo(message: string, duration?: number, infoDetails?: string) {
    const snackBarData: SnackBarData = {
      message: message,
      buttonText: infoDetails ? 'More' : undefined,
      icon: 'info',
    };

    this.snackBarRef = this.showSnackBar(snackBarData, duration ? duration : 3000);
    this.snackBarRef.onAction().subscribe(() => {
      const infoContent = new InfoDialogContent();
      infoContent.title = 'Information';
      infoContent.message = infoDetails;
      this.infoDialogService.info(infoContent);
    });
  }

  public showWarning(
    message: string,
    buttonText?: string,
    duration?: number,
    warningDetails?: string,
  ) {
    const snackBarData: SnackBarData = {
      message: message,
      buttonText: buttonText,
      icon: 'warning',
    };

    this.snackBarRef = this.showSnackBar(snackBarData, duration ? duration : 2000);
    this.snackBarRef.onAction().subscribe(() => {
      const warnContent = new InfoDialogContent();
      warnContent.title = 'Warning';
      warnContent.message = warningDetails;
      this.infoDialogService.info(warnContent);
    });
  }

  public dismissSnackBar() {
    this.snackBarRef?.dismiss();
  }

  private showSnackBar(snackBarData: SnackBarData, duration?: number) {
    this.snackBarConfig.data = snackBarData;
    this.snackBarConfig.duration = duration ? duration : null;
    return this.snackBar.openFromComponent(ExtendedSnackBarComponent, this.snackBarConfig);
  }
}
