<div class="default-filter-section">
  <div class="default-filter-label">
    <span class="default-structure">
      Structures
    </span>
    <span class="structure-add-icon" (click)="addFilterForStructure()">
      <mat-icon class="grey-icon">add</mat-icon>
    </span>
  </div>
  <div *ngIf="structureFilters">
    <div *ngIf="structureFilterView.isLimitToSection">
      <ch-results-structure-filter
        [filterSection]="structureFilters.limitTo"
        (removeStructuralFilter)="removeStructuralFilter($event)"
        (editStructuralFilter)="editStructureFilter($event)"
      >
      </ch-results-structure-filter>
    </div>
    <div *ngIf="structureFilterView.isExcludeSection">
      <ch-results-structure-filter
        [filterSection]="structureFilters.exclude"
        (removeStructuralFilter)="removeStructuralFilter($event)"
        (editStructuralFilter)="editStructureFilter($event)"
      >
      </ch-results-structure-filter>
    </div>
    <div class="note-section" *ngIf="enableMaxStructureFiltersWarning">
      <span class="heading">Note: </span>
      {{ appConstantsService.structureFilterMaxWarning }}
    </div>
  </div>
  <ch-results-keyword-filter
    [keywordFilters]="keywordFiltersList"
    (addKeywordFilter)="saveKeyword($event)"
    (removeKeywordFilter)="removeKeyword($event)"
    (editKeywordFilter)="editKeyword($event)"
  ></ch-results-keyword-filter>
  <ch-result-reaction-filter
    *ngIf="isReactionFilters"
    [reactionFilters]="reactionFiltersList"
    (removeReactionFilter)="removeReactionFilter($event)"
  >
  </ch-result-reaction-filter>
</div>
