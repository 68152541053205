import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageHandlerService implements OnDestroy {
  public remainingAnalysisCount: BehaviorSubject<number> = new BehaviorSubject(1);
  public eCommUserValidated: BehaviorSubject<string | null> = new BehaviorSubject(
    this.getECommUserValidated(),
  );
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(private cookieService: CookieService) {
    fromEvent(window, 'storage')
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((storageEvent: StorageEvent) => {
        if (storageEvent.key === 'remainingAnalysisCount') {
          if (storageEvent.newValue !== 'null') {
            this.remainingAnalysisCount.next(parseInt(storageEvent.newValue, 10));
          } else {
            this.remainingAnalysisCount.next(null);
          }
        }
        if (storageEvent.key === 'ecomm_user_validated') {
          if (storageEvent.newValue !== 'null') {
            this.eCommUserValidated.next(storageEvent.newValue);
          } else {
            this.eCommUserValidated.next(null);
          }
        }
      });
  }

  public getECommUserValidated(): string | null {
    return localStorage.getItem('ecomm_user_validated');
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.unsubscribe();
  }

  public setCookie(cookieKey: string, value: string, expires?: Date) {
    if (!expires) {
      expires = new Date(2147483647 * 1000);
    }
    return this.cookieService.set(cookieKey, value, expires);
  }

  public getCookie(cookieKey: string) {
    return this.cookieService.get(cookieKey);
  }

  public deleteCookie(cookieKey: string) {
    this.cookieService.delete(cookieKey);
  }
}
