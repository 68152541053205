import { Component, OnDestroy, ElementRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { MatSnackBarRef } from '@angular/material/snack-bar';

export type IconType = 'info' | 'warning' | 'error';

/* tslint:disable:max-classes-per-file */
export class SnackBarData {
  constructor(public message: string, public buttonText: string, public icon: IconType = 'info') {}
}

@Component({
  selector: 'extended-snack-bar-component',
  templateUrl: './extended-snack-bar.component.html',
  styleUrls: ['./extended-snack-bar.component.scss'],
})
export class ExtendedSnackBarComponent implements OnDestroy {
  /* tslint:enable:max-classes-per-file */
  public message: string = 'Information';
  public buttonText: string;
  public icon: IconType = 'info';
  public iconColor: string = '';
  private snackBarDismissTimeout: any;
  private eventListenerSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private snackBarRef: MatSnackBarRef<ExtendedSnackBarComponent>,
  ) {
    this.message = this.snackBarRef.containerInstance.snackBarConfig.data.message;
    this.buttonText = this.snackBarRef.containerInstance.snackBarConfig.data.buttonText;
    this.icon = this.snackBarRef.containerInstance.snackBarConfig.data.icon;
    switch (this.icon) {
      case 'info':
        this.iconColor = 'secondary';
        break;
      case 'warning':
        this.iconColor = 'accent';
        break;
      default:
        this.iconColor = 'warn';
        break;
    }
    this.snackBarDismissTimeout = setTimeout(
      () => {
        const clickObservable = fromEvent(document, 'click');
        this.eventListenerSubscription = clickObservable.subscribe((eventFired) => {
          if (
            eventFired.target !== this.elementRef.nativeElement &&
            eventFired.target !== this.elementRef.nativeElement.offsetParent
          ) {
            this.closeSnackBar();
          }
        });
      },
      this.icon === 'error' ? 5000 : 3000,
    );
  }

  public barAction() {
    clearTimeout(this.snackBarDismissTimeout);
    this.snackBarDismissTimeout = undefined;
    this.snackBarRef.dismissWithAction();
    // Add your functionality in the method that opened the snackbar
  }

  public closeSnackBar() {
    clearTimeout(this.snackBarDismissTimeout);
    this.snackBarDismissTimeout = undefined;
    this.snackBarRef.dismiss();
  }

  public ngOnDestroy() {
    if (this.eventListenerSubscription && !this.eventListenerSubscription.closed) {
      this.eventListenerSubscription.unsubscribe();
    }
  }
}
