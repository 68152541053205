import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TagManagerComponent } from '../components/tag-manager/tag-manager.component';

@Injectable()
export class TagManagerService {
  public dialogRef: MatDialogRef<TagManagerComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public openTagManager(analysis: any, config?: any): Observable<any> {
    const config_data = config ? config : this.config;
    this.dialogRef = this.dialog.open(TagManagerComponent, config_data);
    this.dialogRef.componentInstance.analysis = analysis;

    return this.dialogRef.beforeClosed();
  }
}
