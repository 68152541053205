import { timer, throwError, Subscription, Observable, ObservableInput } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCacheItem } from './image-cache-item';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedImageService {
  private imagesCache: { [url: string]: ImageCacheItem } = {};
  private cleaningTimer: Subscription;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {
    (ImageCacheItem.http = this.http), (ImageCacheItem.sanitizer = this.sanitizer);
  }

  /**
   * Load an image using an authorized connection and returns an observable resolving to ObjectURL to the loaded image.
   * Images are cached for a few minutes.
   *
   * @param url
   * @returns {Observable<SafeUrl>} Safe (in Angular sense) ObjectURL which can be used as src attribute of img tag.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
   */
  public getImage(url: string): Observable<SafeUrl> {
    // FIXME: Missing url normalization can compromise the use of cache for pathological usepatterns
    if (!this.imagesCache[url]) {
      this.imagesCache[url] = new ImageCacheItem(url, this.itemErrorHandler.bind(this));
    }

    this.activateCacheCleaning();

    return this.imagesCache[url].image;
  }

  private itemErrorHandler(
    error: { url: string; originalError: any },
    caughtObservable?: Observable<SafeUrl>,
  ): ObservableInput<{}> {
    this.imagesCache[error.url].release();
    delete this.imagesCache[error.url];

    return throwError(error.originalError);
  }

  private activateCacheCleaning() {
    if (!this.cleaningTimer || this.cleaningTimer.closed) {
      const cleaningPeriod: number = 1000 * 60 * 5;
      this.cleaningTimer = timer(cleaningPeriod, cleaningPeriod).subscribe(() => this.cleanCache());
    }
  }

  private cleanCache() {
    const now: number = Date.now();
    let released: number = 0;

    Object.keys(this.imagesCache)
      .filter((url) => this.imagesCache[url].expired(now))
      .forEach((expiredUrl) => {
        this.imagesCache[expiredUrl].release();
        delete this.imagesCache[expiredUrl];
        released++;
      });

    const itemsLeft: number = Object.keys(this.imagesCache).length;
    if (itemsLeft === 0) {
      this.cleaningTimer.unsubscribe();
    }

    console.log(`AuthorizedImageService cache cleanup: ${itemsLeft} left / ${released} released`);
  }
}
