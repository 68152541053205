<div class="ch-like-dislike-dialog ch-text-gray">
  <div class="ch-dialog-top">
    <span>
      Your feedback
      <mat-icon (click)="closeDialog(false)" class="icon-18">close</mat-icon>
    </span>
  </div>

  <div class="ch-dialog-body">
    <div class="ch-comment-navigation">
      <a (click)="selectedTab.setValue(0)" [class.active]="selectedTab.value === 0" class="button">
        {{ getDialogFeedbackButtonName() }}
      </a>
      <a (click)="selectedTab.setValue(1)" [class.active]="selectedTab.value === 1" class="button">
        Modify the reaction scheme
      </a>
    </div>

    <div class="ch-your-feedback-section" [class.active]="selectedTab.value === 0">
      <ng-container *ngIf="feedbackType === feedback.ThumbsDown; else thumbsUpFeedback">
        <section class="ch-problems">
          <mat-checkbox
            color="primary"
            [(ngModel)]="currentAnnotation.thumbsDown.steric_or_strain_problem"
          >
            Steric/strain problem </mat-checkbox
          ><br />
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsDown.not_selective">
            Non-selective </mat-checkbox
          ><br />
          <mat-checkbox
            color="primary"
            [(ngModel)]="currentAnnotation.thumbsDown.reactivity_conflict"
          >
            Reactivity conflict </mat-checkbox
          ><br />
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsDown.other_problems">
            Other problems </mat-checkbox
          ><br />
        </section>

        <mat-form-field
          *ngIf="currentAnnotation.thumbsDown.other_problems"
          [@fadeInAnimation]
          [@fadeOutAnimation]
          class="ch-other-explanation"
        >
          <textarea
            matInput
            focus
            placeholder="Specify problem"
            maxlength="1024"
            [(ngModel)]="currentAnnotation.thumbsDown.problem_explanation"
            name="problem_explanation"
          >
          </textarea>
        </mat-form-field>

        <mat-form-field class="ch-like-dislike-input-form-field">
          <textarea
            matInput
            focus
            placeholder="Additional information"
            maxlength="1024"
            [(ngModel)]="currentAnnotation.thumbsDown.additional_comment"
            name="additional_comment"
            class="ch-comment-textarea"
          >
          </textarea>
        </mat-form-field>
      </ng-container>
      <ng-template #thumbsUpFeedback>
        <section class="ch-problems">
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsUp.robust">
            Robust </mat-checkbox
          ><br />
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsUp.elegant">
            Elegant </mat-checkbox
          ><br />
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsUp.novel">
            Novel </mat-checkbox
          ><br />
          <mat-checkbox color="primary" [(ngModel)]="currentAnnotation.thumbsUp.other_advantages">
            Other </mat-checkbox
          ><br />
        </section>

        <mat-form-field
          *ngIf="currentAnnotation.thumbsUp.other_advantages"
          [@fadeInAnimation]
          [@fadeOutAnimation]
          class="ch-other-explanation"
        >
          <textarea
            matInput
            focus
            placeholder="Specify how is the reaction beneficial"
            maxlength="1024"
            [(ngModel)]="currentAnnotation.thumbsUp.advantages_explanation"
            name="adventage_explanation"
          >
          </textarea>
        </mat-form-field>

        <mat-form-field class="ch-like-dislike-input-form-field">
          <textarea
            matInput
            focus
            placeholder="Additional information"
            maxlength="1024"
            [(ngModel)]="currentAnnotation.thumbsUp.additional_comment"
            name="additional_comment"
            class="ch-comment-textarea"
          >
          </textarea>
        </mat-form-field>
      </ng-template>

      <div class="additional-info">
        <div class="warning">Warning</div>
        The selected reaction will be sent to the Synthia<sup class="ch-trade-mark fs-8-px">TM</sup>
        team for review. Click 'Next' to check the reaction scheme, and modify it as needed.
      </div>
    </div>

    <div class="ch-your-feedback-section" [class.active]="selectedTab.value === 1">
      <div class="warning">Warning</div>
      Your reaction scheme will be sent to our team for review. If you do not wish to share your
      reaction scheme with us in whole or in part, you may modify the structures by using the
      molecular editor below. Please note that significant modifications to your reaction scheme may
      affect our ability to fully understand and resolve your issue.
      <ch-ketcher-editor
        class="molecule-editor"
        #moleculeEditor
        [isAnotherKetcherInstance]="true"
      ></ch-ketcher-editor>
    </div>
  </div>

  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls">
      <button
        mat-button
        class="ch-outline-btn"
        (click)="closeDialog(false)"
        [buttonOutline]="'primary'"
      >
        CANCEL
      </button>
      <button
        mat-button
        *ngIf="selectedTab.value === 1"
        class="ch-outline-btn"
        (click)="selectedTab.setValue(0)"
        [buttonOutline]="'primary'"
      >
        PREVIOUS
      </button>
      <button
        mat-raised-button
        *ngIf="selectedTab.value === 0"
        color="primary"
        (click)="selectedTab.setValue(1)"
      >
        NEXT
      </button>
      <button
        mat-raised-button
        *ngIf="selectedTab.value === 1"
        color="primary"
        [disabled]="!isAnnotationChangedAndMeaningful()"
        (click)="closeDialog(true)"
      >
        SEND
      </button>
    </div>
  </div>
</div>
