<div class="ch-related-reaction-list-body">
  <div *ngIf="isReactionListAvailable">
    <span *ngFor="let reaction of reactionList; let i = index">
      <ch-reaction-details [reactionIndex]="i" [displayMode]="reactionType" [reaction]="reaction">
      </ch-reaction-details>
    </span>
  </div>

  <div *ngIf="!isReactionListAvailable" class="ch-related-reaction-list-unavailable">
    <div *ngIf="loadingReactions" class="ch-related-reaction-list-loading">
      <mat-spinner [strokeWidth]="2" [diameter]="30"> </mat-spinner>
      <div>Searching {{ reactionType }} reactions. Please wait...</div>
    </div>
    <h4 *ngIf="!loadingReactions && isSideReactionsShown">
      No {{ reactionType }} reactions were found
    </h4>
    <h4 *ngIf="!loadingReactions && !isSideReactionsShown">
      Side reactions are unavailable for sub-reactions from multi reaction
    </h4>
  </div>
</div>
