<div class="ch-rename-dialog">
  <div class="ch-dialog-top">
    {{ title }}
  </div>
  <div class="ch-dialog-body">
    <mat-form-field class="ch-rename-dialog-input">
      <input matInput [(ngModel)]="newValue" (keydown.enter)="save()" maxlength="255" required />
    </mat-form-field>
  </div>

  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls">
      <button
        mat-button
        class="ch-outline-btn"
        [buttonOutline]="'primary'"
        (click)="dialogRef.close()"
      >
        CANCEL
      </button>
      <button mat-raised-button (click)="save()" color="primary" [disabled]="isNoName()">
        OK
      </button>
    </div>
  </div>
</div>
