/*
 Meaningful colors related to a specific properties of app elements (not just a style colors adjustable at will).
 */

// TODO: Collect here all such a colors used in the app.

export const COLOR_MOLECULE_BORDER_REGULATED = '#000000';
export const COLOR_MOLECULE_BORDER_PROTECTION_NEEDED = '#009CE1';

export const COLOR_NODE_LABEL = '#000';
export const COLOR_NODE_TARGET = '#FFC832';
export const COLOR_NODE_SECONDARY = '#B2B2B2';
export const COLOR_NODE_COMMERCIALLY_AVAILABLE = '#EE3D96';
export const COLOR_NODE_CUSTOM_INVENTORY = '#0F69AF';
export const COLOR_NODE_KNOWN = '#2DBECD';
export const COLOR_NODE_UNKNOWN = '#503291';

export const COLOR_REACTION_DEFAULT = '#ABABAB';
export const COLOR_REACTION_EXPERT_CHEMISTRY = '#ffffff';
export const COLOR_REACTION_HETEROCYCLES = '#96C9F9';
export const COLOR_REACTION_ARENES = '#9F9BD4';
export const COLOR_REACTION_SPECIALIZED = '#FFB7CC';
export const COLOR_REACTION_PUBLISHED = '#D1FF96';
export const COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY = '#D21F1F';
export const COLOR_REACTION_STRATEGY_OR_TUNNELS = '#5FC66F';

export const COLOR_EDGE_DEFAULT = '#BFBFBF';
