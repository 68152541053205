import { Substance } from './Substance';

export class SubstanceSearchResponse {
  public apiCallFailedCount: number;
  public substance: Substance[];
  constructor() {
    this.apiCallFailedCount = 0;
    this.substance = new Array<Substance>();
  }
}
