import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import {
  InfoDialogService,
  InfoDialogContent,
  ChematicaRouteStateService,
  InteractiveTourHandleService,
  DialogService,
  AppConstantsService,
  ParametersStashService,
  AnalysisService,
} from '../../services';
import {
  jwtHelperService,
  AuthTokenStorageName,
} from '../../../authorization/authorization.service';
import { AuthorizationService } from '../../../authorization';
import { UserEntry, UserSubscriptionType } from '../../../authorization/models/user-entry';
import * as TermsHtml from '!raw-loader!../../../../terms.html';
import { ErrorsHandlerService, ParsedConnectionError } from '../../services/errors-handler';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import {
  getTooltip,
  getMinutesFromSeconds,
  getHoursFromSeconds,
  getTrainingSiteUrlWithAttachedToken,
  getDifferenceInDays,
  isDateExpired,
} from '../../components/utils';
import { OverlayActionMessageData } from '../overlay-action-message/overlay-action-message.component';
import { AppState } from 'src/app/app-state.service';
import { TargetMoleculeSelectionService } from '../../services/target-molecule-selection/target-molecule-selection.service';
import { isNullOrUndefined } from '../utils';
import { StorageHandlerService } from '../../services/storage-handler.service';
import { DatePipe } from '@angular/common';
import { CartWidgetComponent } from 'src/app/ecomm-integration/components/cart-widget/cart-widget.component';
import { ECommHandlerService } from 'src/app/ecomm-integration/services/ecomm-handler.service';
import { ActiveCartItem } from 'src/app/ecomm-integration/models/ActiveCartItem';

@Component({
  selector: 'ch-new-top-toolbar',
  templateUrl: './new-top-toolbar.component.html',
  styleUrls: ['./new-top-toolbar.component.scss'],
})
export class NewTopToolbarComponent implements OnInit, OnDestroy {
  public menuRoutes: string[] = [
    '/password-change',
    '/permission-tokens',
    '/information/terms',
    '/information/privacy',
    '/tech-support',
    '/compliance-statement',
    '/subscription-plans',
    '/terms',
  ];
  public user: UserEntry;
  public toolBarDoesNotExist: boolean = true;
  public analysisId: string = '';
  public sessionHoursLeft: number;
  public sessionMinutesLeft: number;
  public showTour: boolean = false;
  public isHelpMenuOpened: boolean = false;
  public isAllowedToUploadInventory: boolean = false;
  public logoRouterLink: string;
  public maxNameLength = 32;
  public getTooltip = getTooltip;
  public overlayActionMessageData: OverlayActionMessageData = {
    message: this.appConstantsService.homeOverlayActionMessage,
    buttonTitle: this.appConstantsService.homeOverlayActionButtonTitle,
  };
  public trainingSupportLink: string = '';
  public isToolbarActivated: boolean = false;
  public isComplianceStatementAccepted: boolean = false;
  public enableCustomerPolicyAcceptance: boolean = false;
  public isLearningCenterEnabled: boolean = false;
  public isSSOUser: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  public remainingAnalysisCount: number | null;
  public username: string = '';
  public isSessionExpiringSoon: boolean = false;
  public remainingDaysToDeactivate: number;
  public isUserManagementActivated: boolean = false;
  public isCustomerPolicyAccepted: boolean = false;
  public isPlanSubscribed: boolean = false;
  public isPlanExpired: boolean = false;
  public planExpireInfoTooltip: string = '';
  public logoText: string = '';
  public isCartWidgetOpened: boolean = false;
  @ViewChild('cartWidget') public cartWidget: CartWidgetComponent;
  public cartItemCount: number = 0;
  public isAllowToShowShoppingListIcon: boolean = false;
  private isECommIntegrationEnabled: boolean = false;
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  @ViewChild('breadcrumb') public breadcrumbComponent: BreadcrumbComponent;
  constructor(
    public routeStateService: ChematicaRouteStateService,
    public stashService: ParametersStashService,
    public authService: AuthorizationService,
    public storageHandlerService: StorageHandlerService,
    public appStateService: AppState,
    public appConstantsService: AppConstantsService,
    private infoDialogService: InfoDialogService,
    private tourHandler: InteractiveTourHandleService,
    private dialogService: DialogService,
    private errorHandler: ErrorsHandlerService,
    private router: Router,
    private targetMoleculeSelectionService: TargetMoleculeSelectionService,
    private eCommHandlerService: ECommHandlerService,
    private analysisService: AnalysisService,
  ) {
    this.authService
      .isAuthorized()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAuthorized) => {
        this.isToolbarActivated = isAuthorized;
        if (isAuthorized) {
          this.getUserInformation();
        }
      });

    try {
      const jwtToken = sessionStorage.getItem(AuthTokenStorageName);
      this.user = jwtHelperService.decodeToken(jwtToken);
    } catch (e) {
      console.log('Exception when accessing current user id: ' + e);
      return null;
    }

    this.authService.sessionTimeLeftSubject
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((timeRemaining: number) => {
        this.sessionHoursLeft = getHoursFromSeconds(timeRemaining / 1000);
        this.sessionMinutesLeft = getMinutesFromSeconds(timeRemaining / 1000);
        this.isSessionExpiringSoon = this.sessionMinutesLeft <= 3;
      });

    this.routeStateService.currentAnalysisUrl
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysisUrl: string) => {
        const altMatch = analysisUrl.match(new RegExp(/^(\D*)(\d+)/));
        this.analysisId = altMatch && altMatch[2] ? altMatch[2] : '';
      });

    this.tourHandler.showTour.subscribe((isShow) => {
      this.showTour = isShow;
    });

    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((config) => {
        if (!!config && !!config.TRAINING_AND_SUPPORT_URL) {
          this.trainingSupportLink = getTrainingSiteUrlWithAttachedToken(
            config.TRAINING_AND_SUPPORT_URL,
            AuthTokenStorageName,
            false,
          );
        }
        this.enableCustomerPolicyAcceptance =
          !!config && !!config.ENABLE_CUSTOMER_POLICY_ACCEPTANCE;
        this.isECommIntegrationEnabled = !!config && config.ENABLE_ECOMM_INTEGRATION;
        this.isLearningCenterEnabled = !!config && !!config.PENDO_ANALYTICS_ENABLED;
      });

    this.router.events
      .pipe(
        takeUntil(this.unsubscriberSubject),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.toolBarDoesNotExist = false;

        this.menuRoutes.forEach((route) => {
          const str = event.url;
          const regEx = new RegExp('^' + route);
          if (regEx.test(str)) {
            this.toolBarDoesNotExist = true;
          } else if (str === '/') {
            this.toolBarDoesNotExist = true;
          }
        });
      });
  }

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.logoText = this.isSynthiaLiteEnabled
      ? this.appConstantsService.synthiaLiteLogoText
      : this.appConstantsService.synthiaEnterpriseLogoText;
    this.authService.isComplianceStatementAccepted
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(() => {
        if (this.authService.userInformation.value.id) {
          this.isComplianceStatementAccepted = this.authService.userInformation.value.is_compliance_data_accepted;
          this.user.is_compliance_data_accepted = this.isComplianceStatementAccepted;
          this.setIsStatementAccepted();
          if (this.isComplianceStatementAccepted && this.isECommIntegrationEnabled) {
            this.isAllowToShowShoppingListIcon = true;
            this.eCommHandlerService.tryToGetActiveCartDataWithoutBotherUserAuthenticateECommSite();
          }
        }
      });

    this.stashService.isAnalysisStarted
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAnalysisStarted) => {
        if (isAnalysisStarted) {
          this.getUserInformation();
          this.stashService.isAnalysisStarted.next(false);
        }
      });

    this.storageHandlerService.remainingAnalysisCount
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysisRemaining) => {
        this.remainingAnalysisCount = analysisRemaining;
        this.setPlanExpireInfoTooltip();
      });

    this.authService.userInformation
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((userInformation: UserEntry) => {
        if (userInformation.id) {
          this.onUserUpdated(userInformation);
        }
      });

    this.eCommHandlerService.openedCartWidget
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isOpened) => {
        this.isCartWidgetOpened = isOpened;
      });

    this.eCommHandlerService.activeCartItemListSubject
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((result: Array<ActiveCartItem>) => {
        this.cartItemCount = result.length;
      });

    this.authService
      .getUserInformation()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((userInfo) => {
        if (this.authService.isCustomerPolicyAccepted(userInfo) && this.isECommIntegrationEnabled) {
          this.isAllowToShowShoppingListIcon = true;
          this.eCommHandlerService.tryToGetActiveCartDataWithoutBotherUserAuthenticateECommSite();
        } else {
          this.isAllowToShowShoppingListIcon = false;
        }
      });

    if (!this.enableCustomerPolicyAcceptance && this.isECommIntegrationEnabled) {
      this.isAllowToShowShoppingListIcon = true;
      this.eCommHandlerService.tryToGetActiveCartDataWithoutBotherUserAuthenticateECommSite();
    }
  }

  public getUserInformation() {
    if (this.isSynthiaLiteEnabled) {
      this.authService.userInformation.next(new UserEntry());
    }
    this.authService
      .getUserInformation()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (user: UserEntry) => {
          this.onUserUpdated(user);
        },
        (error) => {
          if (error instanceof ParsedConnectionError) {
            this.errorHandler.showGlobalError(error.promptMessage, error.detailsMessage);
          } else {
            this.errorHandler.showGlobalError('Cannot load user data', error);
          }
        },
      );
  }

  private onUserUpdated(user: UserEntry) {
    this.user = user;
    this.isAllowedToUploadInventory = user.can_upload_inventory;
    this.isComplianceStatementAccepted = user.is_compliance_data_accepted;
    this.isSSOUser = user.is_sso_user;
    this.isUserManagementActivated = user.is_customer_user_manager;
    this.setIsPlanSubscribed();
    this.setIsStatementAccepted();
    if (!isNullOrUndefined(user.plan_expiration_date) && user.plan_expiration_date !== '') {
      this.isPlanExpired = isDateExpired(new Date(user.plan_expiration_date));
    }
    if (!isNullOrUndefined(user.deactivation_date) && user.deactivation_date !== '') {
      this.remainingDaysToDeactivate = getDifferenceInDays(new Date(user.deactivation_date));
    }
    this.remainingAnalysisCount = this.isPlanExpired
      ? 0
      : user.remaining_analyses < 0
      ? 0
      : user.remaining_analyses;
    this.storageHandlerService.remainingAnalysisCount.next(this.remainingAnalysisCount);
    if (this.remainingAnalysisCount !== null) {
      localStorage.setItem('remainingAnalysisCount', this.remainingAnalysisCount.toString());
    } else {
      localStorage.setItem('remainingAnalysisCount', 'null');
    }
    this.username =
      user.username.length > this.maxNameLength
        ? user.username.substring(0, this.maxNameLength - 3) + '...'
        : user.username;
  }

  public onSynthiaLogoClick() {
    if (this.isCustomerPolicyAccepted && this.isPlanSubscribed) {
      this.routeStateService.navigateToHome();
    } else {
      this.router.navigateByUrl('/sign-out');
    }
  }

  public navigateWithAnalysisId(link: [any]) {
    this.router.navigate(link);
  }

  public onUserInventoryClick() {
    this.dialogService.openUserInventory().pipe(takeUntil(this.unsubscriberSubject)).subscribe();
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public showTerms() {
    let terms: string = TermsHtml;
    if (!terms || terms === '') {
      terms = '<h4 align="center">Something is wrong... Please try again later</h4>';
    }
    const termsContent = new InfoDialogContent();
    termsContent.title = 'Terms and conditions';
    termsContent.message = terms;
    termsContent.closeButtonText = 'Accept';
    termsContent.dimensions = { width: '600px', height: '600px' };
    this.infoDialogService.info(termsContent);
  }

  public showAbout() {
    this.dialogService
      .openAboutApplicationDialog()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe();
  }

  public hideBreadCrumb() {
    return this.breadcrumbComponent !== undefined &&
      this.breadcrumbComponent.breadcrumb !== undefined &&
      this.breadcrumbComponent.breadcrumb.length > 0
      ? false
      : true;
  }

  public isTrainingSupportLinkAvailable() {
    return !!this.trainingSupportLink && !!this.user && !!this.isCustomerPolicyAccepted;
  }

  public setIsStatementAccepted() {
    this.isCustomerPolicyAccepted =
      this.user && this.authService.isCustomerPolicyAccepted(this.user);
  }

  public setIsPlanSubscribed() {
    this.isPlanSubscribed = this.user && this.authService.isPlanSubscribed(this.user);
  }

  public toggleCart() {
    this.isCartWidgetOpened = !this.cartWidget.sideNav.opened;
    this.isCartWidgetOpened ? this.cartWidget.sideNav.open() : this.cartWidget.sideNav.close();
    this.eCommHandlerService.openedCartWidget.next(this.isCartWidgetOpened);
  }

  public onNewAnalysisClick() {
    this.targetMoleculeSelectionService.setMolecule(null);
    this.targetMoleculeSelectionService.analysisRerun.next(false);
    this.targetMoleculeSelectionService.isAnalysisFromNode.next(false);
    this.targetMoleculeSelectionService.molFile = '';
    this.analysisService.isDiversityLibrary.next(false);
    setTimeout(() => {
      // To reload page when url is same. 'reload' is a dummy query param
      this.router.navigateByUrl('home/new?reload=true', { skipLocationChange: true }).then(() => {
        this.router.navigate(['home', 'new']);
        this.targetMoleculeSelectionService.onNewAnalysisClick.next(true);
      });
    }, 5);
  }

  private setPlanExpireInfoTooltip() {
    let message = '';

    if (this.user && this.user.id) {
      let formattedDate: string = '';

      if (
        !isNullOrUndefined(this.user.plan_expiration_date) &&
        this.user.plan_expiration_date !== ''
      ) {
        const expiryDate: Date = new Date(this.user.plan_expiration_date);
        const pipe: DatePipe = new DatePipe('en-US');
        formattedDate = pipe.transform(expiryDate, 'longDate');
      }

      if (this.user.subscription_type === UserSubscriptionType.TRIAL) {
        if (!this.isPlanExpired) {
          message = this.replaceExpiryInfoTooltipVariables(
            this.appConstantsService.flaskTooltipActivePlan,
            'Free trial',
            formattedDate,
          );
        } else {
          message = this.replaceExpiryInfoTooltipVariables(
            this.appConstantsService.flaskTooltipExpirePlan,
            'Free trial',
          );
        }
      } else if (this.user.subscription_type === UserSubscriptionType.PAID) {
        if (!this.isPlanExpired) {
          message = this.replaceExpiryInfoTooltipVariables(
            this.appConstantsService.flaskTooltipActivePlan,
            'Subscription',
            formattedDate,
          );
        } else {
          message = this.replaceExpiryInfoTooltipVariables(
            this.appConstantsService.flaskTooltipExpirePlan,
            'Subscription',
          );
        }
      }
    }
    this.planExpireInfoTooltip = message;
  }

  private replaceExpiryInfoTooltipVariables(
    tooltipMessage: string,
    subscriptionTypeLabel: string,
    expiryDate?: string,
  ) {
    let analysisCountInfo: string = '';
    if (this.remainingAnalysisCount !== null) {
      analysisCountInfo = this.appConstantsService.replaceVariableFromMessage(
        this.appConstantsService.flaskTooltipAnalysisCount,
        '<count>',
        this.remainingAnalysisCount,
      );
    }
    let subscriptionExpiryInfo: string = this.appConstantsService.replaceVariableFromMessage(
      tooltipMessage,
      '<subscriptionTypeLabel>',
      subscriptionTypeLabel,
    );
    if (expiryDate) {
      subscriptionExpiryInfo = this.appConstantsService.replaceVariableFromMessage(
        subscriptionExpiryInfo,
        '<expiryDate>',
        expiryDate,
      );
    }
    const message: string = [analysisCountInfo, subscriptionExpiryInfo].filter(Boolean).join(' ');
    return message;
  }
}
