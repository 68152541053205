import { isString } from '../../../components/utils';
import { CustomerInventoryMoleculeDataEntry } from '../../user-inventory/models/customer-inventory-molecule-data-entry';

export class GraphMoleculeEntry {
  /* tslint:disable:variable-name */
  public id: string = '';
  public mushroom: number = 0;
  public name: string = '';
  public formula: string = '';
  public smiles: string = '';
  public mapped_smiles: string = '';
  public in_degree: number = 0;
  public out_degree: number = 0;
  public cas: string[] = [];
  public brn: number[] = [];
  public cost: number = 0;
  public log_p: number = 0;
  public log_p_cmx: number = 0;
  public mol_weight: number = 0;
  public real_mass: number = 0;
  public functional_groups: any = {};
  public regulatory_databases: any[] = [];
  public vendors: any[] = [];
  public customer_inventory_data: CustomerInventoryMoleculeDataEntry[] = [];
  public compound_metadata: any = {};
  public dois: string[] = [];
  public patents: string[] = [];
  public references: string[] = [];
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const checkAndAssignIfNullValue = (jsonDataFieldValue: any, field: string) => {
      if (jsonDataFieldValue === null) {
        // Use null instead of default value because of null values received from midend
        // that means empty attribute value for different types
        this[field] = null;
        return true;
      }

      return false;
    };
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        switch (field) {
          case 'customer_inventory_data':
            for (const metadata of jsonData[field]) {
              const formattedData = new CustomerInventoryMoleculeDataEntry(metadata);
              this[field].push(formattedData);
            }
            break;
          case 'mushroom':
          case 'mapped_smiles':
          case 'in_degree':
          case 'out_degree':
          case 'cost':
          case 'log_p':
          case 'log_p_cmx':
          case 'mol_weight':
          case 'real_mass':
            if (checkAndAssignIfNullValue(jsonData[field], field)) {
              break;
            }
          default:
            if (typeof jsonData[field] === typeof this[field]) {
              this[field] = jsonData[field];
            } else {
              // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
              console.error(
                `Unexpected type of field '${field}' in data provided to GraphMoleculeEntry:ctor.` +
                  `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
              );
            }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to GraphMoleculeEntry:ctor.`);
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
