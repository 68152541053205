import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ErrorsHandlerService,
  ParsedConnectionError,
} from './errors-handler/errors-handler.service';

@Injectable()
export class AuthorizedTextResourceService {
  constructor(private http: HttpClient, private errorsHandlerService: ErrorsHandlerService) {}

  public getText(url: string, handleErrors: boolean = true): Observable<string> {
    return this.http.get(url, { observe: 'response', responseType: 'text' }).pipe(
      map((res: HttpResponse<any>) => res.body),
      catchError((err) => (handleErrors ? this.handleError(url, err) : null)),
    );
  }

  private handleError(url: string, err: any): Observable<any> {
    const parsedError = new ParsedConnectionError(err);
    if (parsedError.isRecognized()) {
      this.errorsHandlerService.showGlobalError(
        parsedError.promptMessage,
        `<h4>Resource url:</h4><span>${url}</span>${parsedError.detailsMessage}`,
      );
    } else {
      this.errorsHandlerService.showGlobalError(
        'Unable to download a text resource',
        `<b>Resource url:</b>${url}<br/><b>Error:</b><br>${err}`,
      );
    }
    return throwError(err);
  }
}
