import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccessibilityIconType, buildMoleculeNodeSVG } from '../graph-utils';

@Component({
  selector: 'ch-molecule-icon',
  template: '',
  styles: [':host { display:inline-block; }'],
})
export class MoleculeIconComponent implements OnChanges {
  @Input() public color: string;
  @Input() public borderColor: string;
  @Input() public symbol: AccessibilityIconType;
  @Input() public reactionId: number;
  @Input() public id: string;

  @HostBinding('id') public hostId: string;
  @HostBinding('style.max-width.px') public width: number = 24;
  @HostBinding('style.max-height.px') public height: number = 24;
  @HostBinding('innerHtml') public svgImage;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges) {
    if ('color' in changes || 'borderColor' in changes || 'symbol' in changes) {
      this.svgImage = this.sanitizer.bypassSecurityTrustHtml(
        buildMoleculeNodeSVG(this.color, this.borderColor, this.symbol),
      );
    }
    if ('reactionId' in changes || `id` in changes) {
      this.hostId = `${this.reactionId}` + this.id;
    }
  }
}
