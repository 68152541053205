import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorsHandlerService } from 'src/app/shared/services';
import { GraphMoleculeNode, GraphReactionNode } from 'src/app/shared/services/graph-builder';
import { ActiveCartItem } from '../../models/ActiveCartItem';
import { BBESkuInfo } from '../../models/BBESkuInfo';
import { CasNumberSearchResponse } from '../../models/CasNumberSearchResponse';
import { SkuInfo } from '../../models/SkuInfo';
import { SortBy } from '../../models/SortBy';
import { SubstanceSearchResponse } from '../../models/SubstanceSearchResponse';
import { ECommHandlerService } from '../../services/ecomm-handler.service';
import { CartWidgetComponent } from '../cart-widget/cart-widget.component';

@Component({
  selector: 'ch-pricing-availability-popup',
  templateUrl: './pricing-availability-popup.component.html',
  styleUrls: ['./pricing-availability-popup.component.scss'],
})
export class PricingAvailabilityPopupComponent implements OnInit, OnDestroy {
  public isUserEcommValidated: string | null = '';
  public isWaitingUserAuth = false;
  public isWaitingProductInfoList: boolean = true;
  public isGetProductInfoListFailedForAllCasNumbers: boolean = false;
  public isScrollTop: boolean = true;
  public casNumberListForFilter: string[] = [];
  public casNumberListForDisplayingProductDetailList: string[] = [];
  public allItemList: any[] = [];
  public displayingItemList: any[] = [];
  public pageIndex: number = 0;
  public pageSize: number = 5;
  public sortBy: SortBy[] = [
    { text: 'Relevance', value: 'relevance' },
    { text: 'Name(Ascending)', value: 'name_asc' },
    { text: 'Name(Descending)', value: 'name_des' },
  ];
  public selectedSortByValue: string = this.sortBy[0].value;
  public isCartWidgetOpened: boolean = false;
  @ViewChild('cartWidget') public cartWidget: CartWidgetComponent;
  public cartItemCount: number = 0;
  public receivedCasNumberList: string[] = [];
  public isDisplayProductByGrouping: boolean = true;
  private unsubscribeFromAll: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<PricingAvailabilityPopupComponent>,
    private eCommHandlerService: ECommHandlerService,
    @Inject(MAT_DIALOG_DATA) private nodeDataList: Array<GraphMoleculeNode | GraphReactionNode>,
    private errorsHandlerService: ErrorsHandlerService,
  ) {}

  public ngOnInit(): void {
    this.isDisplayProductByGrouping = true;
    this.isWaitingUserAuth = true;
    this.isUserEcommValidated = 'false';
    this.eCommHandlerService
      .isECommUserAuth()
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (result: boolean) => {
          this.isWaitingUserAuth = false;
          if (result) {
            this.isUserEcommValidated = 'true';
            if (this.isDisplayProductByGrouping) {
              this.getCasSearchDataForGroupBySubstance();
            } else {
              this.getCasSearchData();
            }
            this.checkActiveCartItemNumber();
          } else {
            this.isUserEcommValidated = 'false';
          }
        },
        (error) => {
          this.isWaitingUserAuth = false;
          this.isUserEcommValidated = 'false';
        },
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public removeCasNumberAndRefreshDisplayingData(casNumber: string): void {
    if (this.casNumberListForFilter.length < 2) {
      return;
    }
    this.pageIndex = 0;
    this.allItemList = this.allItemList.filter((x) => x.cas_number !== casNumber);
    this.prepareDisplayingData();
  }

  public sortByValueChanged(value: string): void {
    if (value === 'name_asc') {
      this.allItemList.sort(this.eCommHandlerService.prepareCompareFunction('molecule_name', true));
    } else if (value === 'name_des') {
      this.allItemList.sort(
        this.eCommHandlerService.prepareCompareFunction('molecule_name', false),
      );
    } else {
      this.allItemList.sort(this.eCommHandlerService.prepareCompareFunction('cas_number', true));
    }
    this.prepareDisplayingData();
  }

  public updatePage(event: any): void {
    this.pageIndex = event.pageIndex;
    this.prepareDisplayingData();
  }

  public onScroll(event: any): void {
    this.isScrollTop = event.target.scrollTop === 0 ? true : false;
  }

  public toggleCart(): void {
    this.isCartWidgetOpened = !this.cartWidget.sideNav.opened;
    this.isCartWidgetOpened ? this.cartWidget.sideNav.open() : this.cartWidget.sideNav.close();
    this.eCommHandlerService.openedCartWidget.next(this.isCartWidgetOpened);
  }

  private checkActiveCartItemNumber(): void {
    this.eCommHandlerService.activeCartItemListSubject
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((result: Array<ActiveCartItem>) => {
        this.cartItemCount = result.length;
      });
  }

  private getCasSearchData(): void {
    if (this.allItemList.length > 0) {
      return;
    }
    const casNumberSet = new Set<string>();
    for (const node of this.nodeDataList) {
      for (const cas of node.molecule.cas) {
        casNumberSet.add(cas);
      }
    }
    this.receivedCasNumberList = [...casNumberSet];
    this.eCommHandlerService
      .getCasSearchResponse(this.receivedCasNumberList)
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (result: CasNumberSearchResponse) => {
          this.isWaitingProductInfoList = false;
          this.processAPIReturnedData(result);
        },
        (error) => {
          this.isWaitingProductInfoList = false;
          this.isGetProductInfoListFailedForAllCasNumbers = true;
          this.showErrorMessageForGetProductDetailInfoListFailed();
        },
      );
  }

  private getCasSearchDataForGroupBySubstance(): void {
    if (this.allItemList.length > 0) {
      return;
    }
    const casNumberSet = new Set<string>();
    for (const node of this.nodeDataList) {
      for (const cas of node.molecule.cas) {
        casNumberSet.add(cas);
      }
    }
    this.receivedCasNumberList = [...casNumberSet];
    this.eCommHandlerService
      .getCasSearchDataForGroupBySubstance(this.receivedCasNumberList)
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (result: SubstanceSearchResponse) => {
          this.processAPIReturnedData(result);
          this.isWaitingProductInfoList = false;
        },
        (error) => {
          this.isWaitingProductInfoList = false;
          this.isGetProductInfoListFailedForAllCasNumbers = true;
          this.showErrorMessageForGetProductDetailInfoListFailed();
        },
      );
  }

  private processAPIReturnedData(data: CasNumberSearchResponse | SubstanceSearchResponse): void {
    if (this.isDisplayProductByGrouping) {
      const responseData = data as SubstanceSearchResponse;
      for (const substance of responseData.substance) {
        for (const product of substance.products) {
          if (product.brand === '') {
            this.eCommHandlerService
              .getSkuInfoList(product)
              .pipe(takeUntil(this.unsubscribeFromAll))
              .subscribe((skuInfoList: Array<SkuInfo> | Array<BBESkuInfo>) => {
                for (const skuInfo of skuInfoList) {
                  for (const skuDetail of skuInfo.skuDetailList) {
                    if (skuDetail?.brand) {
                      product.brand = skuDetail?.brand;
                      break;
                    }
                  }
                }
              });
          }
        }
        this.allItemList.push(substance);
      }
      this.prepareDisplayingData();
      this.sortByValueChanged(this.selectedSortByValue);
      if (data.apiCallFailedCount > 0 && this.allItemList.length === 0) {
        this.isGetProductInfoListFailedForAllCasNumbers = true;
        this.showErrorMessageForGetProductDetailInfoListFailed();
      }
    } else {
      const responseData = data as CasNumberSearchResponse;
      for (const pInfo of responseData.productInfoList) {
        this.allItemList.push(...pInfo.product_detail_info_list);
      }
      this.prepareDisplayingData();
      this.sortByValueChanged(this.selectedSortByValue);
      if (data.apiCallFailedCount > 0 && this.allItemList.length === 0) {
        this.isGetProductInfoListFailedForAllCasNumbers = true;
        this.showErrorMessageForGetProductDetailInfoListFailed();
      }
    }
  }

  private showErrorMessageForGetProductDetailInfoListFailed(): void {
    this.errorsHandlerService.showGlobalError(
      'Cas search service is unavailable for current molecule(s) at this moment, please try another one.',
    );
  }

  private prepareDisplayingData(): void {
    this.displayingItemList = this.allItemList.slice(
      this.pageIndex * this.pageSize,
      this.pageIndex * this.pageSize + this.pageSize,
    );
    const casNumbersForFilterSet = new Set<string>();
    for (const item of this.allItemList) {
      casNumbersForFilterSet.add(item.cas_number);
    }
    this.casNumberListForFilter = [...casNumbersForFilterSet];
    const casNumbersForDisplayingProductDetail = new Set<string>();
    for (const item of this.displayingItemList) {
      casNumbersForDisplayingProductDetail.add(item.cas_number);
    }
    this.casNumberListForDisplayingProductDetailList = [...casNumbersForDisplayingProductDetail];
  }
}
