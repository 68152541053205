import { Component, OnInit } from '@angular/core';
import {
  TermsAndConditions,
  AcknowledgementSection,
} from 'src/app/shared/services/user-registration/models';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ParsedConnectionError,
  InfoService,
  ErrorsHandlerService,
  AppConstantsService,
} from 'src/app/shared/services';

@Component({
  selector: 'ch-terms-synthia-lite',
  templateUrl: './terms-synthia-lite.component.html',
  styleUrls: ['./terms-synthia-lite.component.scss'],
})
export class TermsSynthiaLiteComponent implements OnInit {
  public termsAndConditionsData: TermsAndConditions = new TermsAndConditions();
  public termsAndConditionsHtmlContent: string;
  public acknowledgementHtmlContent: string;
  public isDataLoading: boolean = true;
  public acknowledgementSections: AcknowledgementSection[] = [];
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthorizationService,
    private infoService: InfoService,
    private errorsHandler: ErrorsHandlerService,
    private appConstantsService: AppConstantsService,
  ) {}

  public ngOnInit(): void {
    this.authService
      .getTermsAndConditions()
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        finalize(() => (this.isDataLoading = false)),
      )
      .subscribe(
        (response: TermsAndConditions) => {
          this.termsAndConditionsData = response;
          this.termsAndConditionsHtmlContent = response.content;
          this.acknowledgementHtmlContent = response.acknowledgement_statement;
          this.acknowledgementSections = response.statement_sections;
        },
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          if (parsedError.isRecognized()) {
            this.errorsHandler.showGlobalError(parsedError.promptMessage);
          } else {
            this.infoService.showError(this.appConstantsService.somethingWentWrongError);
          }
        },
      );
  }
}
