<div class="ch-computation-list">
  <div>
    <h3 class="ch-title">COMPUTATIONS</h3>
  </div>
  <div class="ch-computation-list-body">
    <div *ngIf="showLoadingComputations && visible" class="ch-computation-list-loading">
      <mat-spinner [strokeWidth]="1" [diameter]="25" class="ch-computation-list-spinner">
      </mat-spinner>
      <div class="ch-computation-list-status">
        Loading...
      </div>
    </div>

    <div
      class="ch-computation-list-computations ch-bdr-grey-light"
      [style.display]="showLoadingComputations ? 'none' : null"
    >
      <div *ngFor="let computation of computationList; trackBy: trackByComputationId">
        <ch-computation-detail
          [visible]="visible"
          [state]="computation.state"
          [algorithm]="analysis.initial_computation.algorithm"
          [computationTime]="computation.computation_time"
          [computationId]="computation.id"
          [stopRequested]="computation.stop_requested"
          [name]="computation.name"
          [computation]="computation"
          [fixedDuration]="computation.fixed_duration"
          [errorMessage]="computation.status_message"
          [errorCode]="computation.status_code"
          [isAnalysisShared]="!!analysis.shared_at"
        >
        </ch-computation-detail>
      </div>
    </div>
  </div>
</div>
