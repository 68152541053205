import { isString, isObject } from '../../../components/utils';
import { stringFormat } from '../../../components/utils';

export class CustomerInventoryMoleculeDataEntry {
  /* tslint:disable:variable-name */
  public customer_inventory: any = '';
  public compound_id: string = '';
  public meta_data: any = {};
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const invalidFieldMessage: string =
      'Unexpected field {0} in data provided to CustomerInventoryMoleculeDataEntry';
    const invalidFieldValueMessage: string = `
      Unexpected value of field '{0}' in data provided to CustomerInventoryMoleculeDataEntry.
      \nUsed default value of '{0}' instead of provided '{1}'.
    `;
    for (const field of Object.keys(jsonData)) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else if (field === 'meta_data' && isObject(jsonData[field])) {
          this[field] = jsonData[field];
        } else {
          console.error(stringFormat(invalidFieldValueMessage, field, jsonData[field]));
        }
      } else {
        console.error(stringFormat(invalidFieldMessage, field));
      }
    }
  }
}
