import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { svgToImageDataURI } from 'src/app/shared/components/graph-utils';
import { RDKitLoaderService } from '../rdkit-loader/rdkit-loader.service';
import { RDKitModule } from '@rdkit/rdkit';

@Injectable({
  providedIn: 'root',
})
export class RdKitBridgeService {
  private rdkit?: RDKitModule;
  constructor(private sanitizer: DomSanitizer, private rdkitService: RDKitLoaderService) {
    this.rdkitService.getRDKit().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });
  }

  public getMolecule(smiles: string) {
    return this.rdkit.get_mol(smiles);
  }

  public getFingerPrint(rdKitMolecule: any) {
    return rdKitMolecule.get_morgan_fp();
  }

  public getQMolecule(smiles: string) {
    return this.rdkit.get_qmol(smiles);
  }

  public getSubstructureMatch(rdKitQMolecule: any, mol: any) {
    return rdKitQMolecule.get_substruct_match(mol);
  }

  public getSVGImage(rdKitMolecule: any) {
    const config = JSON.stringify({ explicitMethyl: true, rotate: 270 });
    return rdKitMolecule.get_svg_with_highlights(config);
  }

  public getTanimoto(key1: string, key2: string) {
    let nfA: number = 0;
    let nfB: number = 0;
    let nfAB: number = 0;
    let score: number = 0;
    for (let i = 0; i < key1.length; i++) {
      if ('1' === key1[i]) {
        nfA++;
        if ('1' === key2[i]) {
          // feature ON in B, so no diff to count!
          nfAB++;
        }
      }
      if ('1' === key2[i]) {
        nfB++;
      }
    }
    if (0 !== nfA + nfB - nfAB) {
      score = nfAB / (nfA + nfB - nfAB); // normalize result
    } else {
      score = NaN;
    }
    return score;
  }

  public drawSVGByRDKit(smiles: string) {
    const mol = this.getMolecule(smiles);
    const svg: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      svgToImageDataURI(this.getSVGImage(mol)),
    );
    mol.delete();
    return svg;
  }
}
