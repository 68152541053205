import { Observable } from 'rxjs';
import { RenameDialogComponent } from '../components/rename-dialog';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RenameDialogService {
  public dialogRef: MatDialogRef<RenameDialogComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public renameDialog(title: string, value?: any): Observable<any> {
    this.dialogRef = this.dialog.open(RenameDialogComponent, this.config);
    this.dialogRef.componentInstance.title = title;

    if (value) {
      this.dialogRef.componentInstance.oldValue = value;
    }

    return this.dialogRef.beforeClosed();
  }
}
