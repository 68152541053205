import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, ViewContainerRef } from '@angular/core';
import { isUndefined } from '../components/utils';

export interface ConfirmDialogConfiguration {
  title: string;
  message: string;
  trueActionName?: string;
  falseActionName?: string;
  showFalseAction?: boolean;
  newUiStyle?: boolean;
  switchButtonsPriority?: boolean;
  showCheckbox?: boolean;
  checkboxMessage?: string;
  viewContainerRef?: ViewContainerRef;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  public dialogRef: MatDialogRef<ConfirmDialogComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public confirm(dialogCongiguration: ConfirmDialogConfiguration): Observable<boolean> {
    this.config.viewContainerRef = dialogCongiguration.viewContainerRef;
    if (!!dialogCongiguration.newUiStyle) {
      this.config.minHeight = 200;
      this.config.minWidth = 550;
      this.config.panelClass = 'dialog-container-new';
    } else {
      this.config.minHeight = null;
      this.config.minWidth = null;
      this.config.panelClass = null;
    }

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, this.config);

    if (dialogCongiguration.trueActionName) {
      this.dialogRef.componentInstance.trueActionName = dialogCongiguration.trueActionName;
    }
    if (dialogCongiguration.falseActionName) {
      this.dialogRef.componentInstance.falseActionName = dialogCongiguration.falseActionName;
    }
    if (!isUndefined(dialogCongiguration.showFalseAction)) {
      this.dialogRef.componentInstance.showFalseAction = dialogCongiguration.showFalseAction;
    }

    if (!!dialogCongiguration.checkboxMessage) {
      this.dialogRef.componentInstance.checkboxMessage = dialogCongiguration.checkboxMessage;
    }

    this.dialogRef.componentInstance.title = dialogCongiguration.title;
    this.dialogRef.componentInstance.message = dialogCongiguration.message;
    this.dialogRef.componentInstance.newUiStyle = !!dialogCongiguration.newUiStyle;
    this.dialogRef.componentInstance.switchButtonsPriority = !!dialogCongiguration.switchButtonsPriority;
    this.dialogRef.componentInstance.showCheckbox = !!dialogCongiguration.showCheckbox;

    return this.dialogRef.beforeClosed();
  }
}
