

(function (root, factory) {
  if (typeof exports === 'object') {
    // Node, CommonJS-like, for example: module.exports = factory(require('jquery'));
    module.exports = factory();
  } else if (typeof define === 'function' && define.amd) {
    // AMD, for example if your module need jquery: define(['jquery'], factory);
    define([], factory);
  } else {
    // Browser globals (root is window), for example: root.marvin_bridge = factory(root.jQuery);
    root.marvin_bridge = factory();
  }
  // factory, for example: (this, function ($) {
}(this, function () {

  function _getWrapperElement (id) {
    var re = new RegExp(/^#.*/);
    if (typeof id !== "string") {
      return null;
    }
    // remove hash mark if present
    return document.getElementById( (re.test(id)) ? id.substr(1) : id );
  }

  function _getPackage (wrapperElement) {
    if (typeof wrapperElement.contentWindow.marvin !== "undefined") {
      return wrapperElement.contentWindow.marvin;
    }
    return null;
  }

  function _createPackage(elementId, resolve, reject) {
    if(elementId === null){
      reject("Element id can not be null.");
      return;
    }

    var	wrapperElement = _getWrapperElement(elementId);

    if (wrapperElement === null) {
      reject("Unable to get element with id '" + elementId + "' in document '" + document.documentURI + "'" );
      return;
    }

    var marvinPackage = _getPackage(wrapperElement);
    if (marvinPackage) {
      // onReady calls any registered callback nevertheless it's added before or after initialization
      marvinPackage.onReady(function() {
        resolve(marvinPackage);
      });
    } else {
      // Wait until wrapperElement finish loading
      wrapperElement.addEventListener("load", function handleSketchLoad (e) {
        var marvin = _getPackage(wrapperElement);
        if (marvin) {
          marvin.onReady(function() {
            resolve(marvin);
          });
        } else {
          reject("Unable to find marvin package in loaded wrapper element.");
        }

      });
    }
  }

  function _createEditorFromDOM(wrapperElement, resolve, reject) {
    var marvinPackage = _getPackage(wrapperElement);
    if (marvinPackage) {

      marvinPackage.onReady(function() {
        if (typeof marvinPackage.sketcherInstance !== "undefined") {
          resolve(marvinPackage.sketcherInstance);
        } else if (!marvinPackage.Sketch.isSupported()) {
          reject("Cannot initiate MarvinJS sketcher. Current browser may not support HTML5 canvas or may run in Compatibility Mode.");
        } else {
          reject("Unable to find sketcherInstance in element with id: " + wrapperElement);
        }
      });

    } else { // use listener

      wrapperElement.addEventListener("load", function handleSketchLoad (e) {
        var marvin = _getPackage(wrapperElement);
        if (marvin) {
          marvin.onReady(function() {
            if (typeof marvin.sketcherInstance !== 'undefined') {
              resolve(marvin.sketcherInstance);
            } else if (!marvinPackage.Sketch.isSupported()) {
              reject("Cannot initiate MarvinJS sketcher. Current browser may not support HTML5 canvas or may run in Compatibility Mode.");
            } else {
              reject("Unable to find sketcherInstance in iframe with id: " + wrapperElement);
            }
          });
        } else {
          reject("Unable to find marvin package, cannot retrieve sketcher instance");
        }
      });
    }
  }

  function _createEditor(elementId, resolve, reject) {
    if(elementId === null){
      reject("Element id can not be null.");
      return;
    }

    var	wrapperElement = _getWrapperElement(elementId);

    if (wrapperElement === null) {
      reject("Unable to get element with id: " + elementId);
      return;
    }

    _createEditorFromDom(wrapperElement, resolve, reject)
  }

  function getEditor (elementId) {
    return new Promise(function createEditor (resolve, reject) {
      _createEditor(elementId, resolve, reject);
    });
  }

  function getEditorFromDOM (element) {
    return new Promise(function createEditor (resolve, reject) {
      _createEditorFromDOM(element, resolve, reject);
    });
  }

  function getMarvin (elementId) {
    return new Promise(function createPackage (resolve, reject) {
      _createPackage(elementId, resolve, reject);
    });
  }

  // This method assumes that Marvin iframe has been fully initialized.
  // It should be called after getMarvin promise has been resolved.
  function getDefaultServices(elementId) {
    var wrapperElement = _getWrapperElement(elementId);

    if (wrapperElement === null) {
      throw "Unable to get element with id: " + elementId;
    }

    return wrapperElement.contentWindow.getDefaultServices();
  }

  // public methods
  return {
    getMarvin: getMarvin,
    getEditor: getEditor,
    getEditorFromDOM: getEditorFromDOM,
    getDefaultServices: getDefaultServices
  };
}));
