import { ESCAPE } from '@angular/cdk/keycodes';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import * as moleculeUtils from '../../../shared/components/molecule-utils';
import { AlgorithmType, ResultsView } from '../../../shared/services/analysis';
import { AppConstantsService } from '../../../shared/services/app-constants';
import { GraphModeType } from '../../../shared/services/frontend-storage/models/unions';
import { GraphNodeType, ChildReactionNodeType } from '../../../shared/services/graph-builder';
import { GRAPH_NODE_DATA } from '../../../shared/services/graph-node-popup/graph-node-popup.models';
import { InfoDialogService } from '../../../shared/services/info-dialog.service';
import { FADE_IN_ANIMATION } from '../../animations';
import { MoleculeImageComponent } from '../../components/molecule-image';
import { AnalysisResultsService } from '../../services/analysis-results/analysis-results.service';
import { isReactionNode } from '../graph-utils';

type DetailsType = {
  smiles: string;
  name: string;
};

export enum NodeType {
  REACTION = 'reaction',
  MOLECULE = 'molecule',
}

@Component({
  selector: 'ch-new-graph-node-popup',
  templateUrl: './new-graph-node-popup.component.html',
  styleUrls: ['./new-graph-node-popup.component.scss'],
  animations: [FADE_IN_ANIMATION],
})
export class NewGraphNodePopupComponent implements OnInit, OnDestroy {
  public moleculeSets: any[] = [];
  public node: any;
  public shopLinks: any[] = [];
  public graphId: any;
  public algorithm: AlgorithmType;
  public mode: GraphModeType;
  public isReaction: boolean;
  public moleculeDetails: DetailsType = {
    smiles: '',
    name: '',
  };
  public reactionDetails: DetailsType = {
    smiles: '',
    name: '',
  };
  public popupService: any; // should be GraphNodePopupService but we want to avoid circular dependency
  public filterType = 'Limit To';
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public isPathSelectable: boolean = true;
  public selectPathTooltip: string = '';
  public nodeName: string = '';
  public nodeImageFormula: string = '';
  public nodeType: NodeType;
  public nodeImageHeight: number = 160;

  @ViewChild('moleculeImage', { static: true })
  public moleculeImageComponent: MoleculeImageComponent;
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  constructor(
    public infoDialogService: InfoDialogService,
    public appConstantsService: AppConstantsService,
    public analysisResultsService: AnalysisResultsService,
    @Inject(GRAPH_NODE_DATA) private data: any,
    private router: Router,
  ) {
    this.popupService = this.data.popupService;
    this.node = data.node;
  }

  public ngOnInit() {
    this.listenToNavigationChanges();
    this.listenToEscapeKeypress();
    this.updateNodeDetails();
    this.updateUI();
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
  }

  public setNodeType() {
    this.nodeType =
      this.node.type === GraphNodeType.REACTION ? NodeType.REACTION : NodeType.MOLECULE;
  }

  public isReactionNode(): boolean {
    return this.node.type === GraphNodeType.REACTION;
  }

  public isAlgorithmManualOrAutoRetro(): boolean {
    return (
      this.algorithm === AlgorithmType.AutomaticRetrosynthesis ||
      this.algorithm === AlgorithmType.ManualRetrosynthesis ||
      this.algorithm === AlgorithmType.LibraryMode
    );
  }

  public setNodeName() {
    if (!this.isAlgorithmManualOrAutoRetro() && this.isReactionNode()) {
      this.nodeName = this.appConstantsService.nocReactionTitle;
    } else {
      this.nodeName = this.isReaction ? this.reactionDetails.name : this.moleculeDetails.name;
    }
  }

  public setNodeImageFormula() {
    this.nodeImageFormula = this.isReaction
      ? this.reactionDetails.smiles
      : this.moleculeDetails.smiles;
  }

  public menuOpened() {
    this.popupService.menuInPopupOpen();
  }

  public menuClosed() {
    this.popupService.hidePopupOnMouseOut = true;
  }

  public updateNodeDetails() {
    if (!this.popupService) {
      return false;
    }
    this.isReaction = this.isReactionNode();
    if (this.isReaction) {
      this.reactionDetails = {
        smiles: this.node.reaction.smiles,
        name: this.node.reaction.name ? this.node.reaction.name : 'Reaction details',
      };
    } else {
      this.moleculeDetails.name = this.node.molecule.name
        ? this.node.molecule.name
        : this.node.molecule.smiles;
      this.moleculeDetails.smiles = this.node.molecule.smiles;
      this.shopLinks = moleculeUtils.scanSigmaAldrichShopLinks(this.node);
    }

    this.mode = this.node.chNodeAnalysisContext.mode;
    this.graphId = this.node.chNodeAnalysisContext.graphId;
    this.algorithm = this.node.chNodeAnalysisContext.algorithm;

    // request pinned status info from clipboard (which is updated in subscription above)
    this.popupService.clipboard?.requestPinnedStatus(this.node);
    this.setNodeType();
    this.setNodeName();
    this.setNodeImageFormula();
    this.nodeImageHeight = this.isReactionNode() ? 160 : 140;
  }

  public listenToEscapeKeypress() {
    fromEvent(document, 'keyup')
      .pipe(
        takeUntil(this.unsubscriberSubject),
        filter((event: KeyboardEvent) => event.keyCode === ESCAPE),
      )
      .subscribe((_) => this.closePopup());
  }

  public listenToNavigationChanges() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscriberSubject),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((_) => this.closePopup());
  }

  public updateUI() {
    this.isPathSelectable =
      this.node.childReactionNodeId !== ChildReactionNodeType.PRETARGET &&
      this.mode !== ResultsView.PATHWAYS &&
      isReactionNode(this.node);
    this.selectPathTooltip = this.node.markedForPrint
      ? this.appConstantsService.deselectPathTooltip
      : this.appConstantsService.selectPathTooltip;
  }

  public closeMenuInstance() {
    this.trigger.closeMenu();
    this.closePopup();
  }

  public selectPathway() {
    this.popupService.dispatchOtherNodeEvent(
      { node: this.node, select: !this.node.markedForPrint },
      'printpathway',
    );
    this.pushToSelectedNodes(this.node);
  }

  public pushToSelectedNodes(newNode) {
    const currentNodes = this.popupService.selectedNodes.getValue();
    if (!currentNodes.some((node) => node.id === newNode.id)) {
      this.popupService.selectedNodes.next([...currentNodes, newNode]);
    }
  }

  private closePopup() {
    this.popupService.disposeNodeOverlay();
  }
}
