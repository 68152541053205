import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-scoring-function-filter',
  styleUrls: ['./scoring-function-filter.component.scss'],
  templateUrl: './scoring-function-filter.component.html',
})
export class ScoringFunctionFilterComponent implements OnInit {
  public filter: string;
  public userInput: string = '';
  public isTextInput: boolean = true;
  public separator: 'space' | 'semicolon' = 'space';
  public placeholder: string;

  constructor(public filterDialogRef: MatDialogRef<ScoringFunctionFilterComponent>) {}

  public ngOnInit() {
    switch (this.filter) {
      case 'SEEK_NAME':
      case 'HIDE_NAME':
        this.placeholder = 'Enter name of reaction to be filtered...';
        this.separator = 'semicolon';
        break;
      case 'SEEK_SMARTS':
      case 'HIDE_SMARTS':
        this.placeholder = 'Enter SMARTS to be filtered...';
        break;
      case 'SEEK_ID':
      case 'HIDE_ID':
        this.placeholder = 'Enter reaction ID to be filtered...';
        break;
      case 'HIDE_SMILES':
      case 'SEEK_SMILES':
        this.placeholder = 'Enter SMILES to be filtered...';
        break;
      default:
        break;
    }
  }

  public applyFilter(close: boolean) {
    if (!close) {
      this.filterDialogRef.close();
    } else {
      if (this.separator === 'space') {
        this.filterDialogRef.close(
          `'` + this.getRidOfEmptyStrings(this.userInput.trim().split(/[\s]+/g)).join(`','`) + `'`,
        );
      } else if (this.separator === 'semicolon') {
        this.filterDialogRef.close(
          `'` + this.getRidOfEmptyStrings(this.userInput.trim().split(/[\;]+/g)).join(`','`) + `'`,
        );
      }
    }
  }

  private getRidOfEmptyStrings(inputArray: string[]) {
    return inputArray.filter((str) => str.length).map((str) => str.trim());
  }
}
