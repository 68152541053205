<div class="ch-permission-tokens-top"></div>

<mat-card class="ch-permission-tokens-card">
  <mat-card-title color="primary">Permission Tokens</mat-card-title>
  <mat-card-content>
    <mat-progress-bar
      class="ch-permission-tokens-progress"
      mode="indeterminate"
      color="primary"
      *ngIf="pendingApiCall"
    ></mat-progress-bar>
    <form *ngIf="!formSentError && !formSentSuccess">
      <table class="ch-permission-tokens-layout">
        <tr>
          <td>
            Enter tokens in format: <i>token, token, token</i> (separated by <b>commas</b>) here.
            <mat-form-field class="ch-permission-tokens-textarea" floatLabel="never">
              <textarea
                matInput
                name="permissionTokens"
                [formControl]="permissionTokensFormControl"
                placeholder="Tokens"
                class="ch-permission-tokens-textarea"
                required
              >
              </textarea>
              <mat-error
                *ngIf="
                  permissionTokensFormControl.invalid &&
                  permissionTokensFormControl.hasError('tokensValidity')
                "
              >
                Some tokens are not valid. Valid token has 64 characters, consists of uppercase,
                lowercase letters a-z and numbers 0-9. Remember to separate each token with a comma
                ','.
              </mat-error>
              <mat-error
                *ngIf="
                  permissionTokensFormControl.invalid &&
                  permissionTokensFormControl.hasError('minlength')
                "
              >
                Input is too short.
              </mat-error>
              <mat-error
                *ngIf="
                  permissionTokensFormControl.invalid &&
                  permissionTokensFormControl.hasError('required')
                "
              >
                This field is required.
              </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="ch-permission-tokens-submit-row">
            <button
              mat-raised-button
              class="ch-permission-tokens-submit-bt"
              id="submit-button"
              [disabled]="permissionTokensFormControl.invalid"
              color="primary"
            >
              ADD TOKENS
            </button>
          </td>
        </tr>
      </table>
    </form>

    <div *ngIf="formSentError || formSentSuccess">
      <table class="ch-permission-tokens-layout">
        <tr *ngIf="formSentSuccess">
          <td>
            <span *ngIf="tokensAdded.length"
              >Tokens added:
              <ul>
                <li *ngFor="let token of tokensAdded">
                  {{ token }}
                </li>
              </ul>
            </span>
            <span *ngIf="tokensAlreadyOwned.length"
              >Tokens already owned:
              <ul>
                <li *ngFor="let token of tokensAlreadyOwned">
                  {{ token }}
                </li>
              </ul>
            </span>
            <span *ngIf="tokensExpired.length"
              >Tokens expired:
              <ul>
                <li *ngFor="let token of tokensExpired">
                  {{ token }}
                </li>
              </ul>
            </span>
            <span *ngIf="tokensInvalid.length && tokensInvalid[0].length">
              These tokens couldn't be addeda because they don't exist:
              <ul>
                <li *ngFor="let token of tokensInvalid">
                  {{ token }}
                </li>
              </ul>
            </span>
          </td>
        </tr>
        <tr *ngIf="formSentError && !pendingApiCall">
          <td>
            <p class="ch-clr-warn">
              <b>An error occurred.</b>
            </p>
            <span *ngIf="tokensInvalid.length && tokensInvalid[0].length">
              These tokens couldn't be added, because they don't exist:
              <ul>
                <li *ngFor="let token of tokensInvalid">
                  {{ token }}
                </li>
              </ul>
            </span>
            <span *ngIf="tokensInvalid.length === 1 && !tokensInvalid[0].length">
              Some tokens have been entered in wrong format. Please, verify format of entered data.
            </span>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td class="ch-permission-tokens-submit-row">
            <button
              mat-raised-button
              class="ch-permission-tokens-submit-bt"
              (click)="backToForm()"
              color="primary"
            >
              <span>BACK TO FORM</span>
            </button>
            <button
              mat-raised-button
              class="ch-permission-tokens-submit-bt"
              [routerLink]="['/dashboard', 'analyses', 'all', '1']"
              color="primary"
            >
              <span>OK</span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
