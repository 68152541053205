import { isString } from '../../../components/utils';

export class PathwaySubstrates {
  /* tslint:disable:variable-name */
  public molecule_id: string = '';
  public svg: string = '';
  public smiles: string = '';
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
          console.error(
            `Unexpected type of field '${field}' in data provided to PathwaySubstrates:ctor.` +
              `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
          );
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to PathwaySubstrates:ctor.`);
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
