<div class="ch-computation-detail-computation">
  <div>
    <div>
      <mat-progress-bar
        class="ch-computation-detail-progress-bar"
        *ngIf="
          (state === ComputationState.IN_PROGRESS ||
            state === ComputationState.PENDING ||
            state === ComputationState.IN_QUEUE) &&
          !stopped &&
          visible
        "
        [mode]="state === ComputationState.IN_PROGRESS ? 'indeterminate' : 'buffer'"
        color="primary"
      >
      </mat-progress-bar>
    </div>

    <mat-card-title class="ch-computation-detail-title">
      {{ name }}
      <span
        matTooltip="{{
          isAnalysisShared ? 'Cannot rename computation from shared analysis' : 'Rename computation'
        }}"
        matTooltipPosition="below"
      >
        <button
          mat-button
          [disabled]="nameLoading || isAnalysisShared"
          class="ch-computation-detail-rename"
          (click)="renameComputation()"
        >
          <mat-icon>mode_edit</mat-icon>
        </button>
      </span>
    </mat-card-title>

    <mat-card-subtitle *ngIf="isMinimalCostAnalysis()"> Minimal cost algorithm </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isReactionNetworkAnalysis()"> Step by step </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isGreedyPopularityAnalysis()">
      Maximum popularity algorithm
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isCostAndPopularityAnalysis()">
      Optimize cost and popularity algorithm
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isManualRetrosynthesisAnalysis()">
      Step-by-step Retrosynthesis
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isAutomaticRetrosynthesisAnalysis()">
      Automatic Retrosynthesis
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isLibraryModeAnalysis()">
      Shared Path Library
    </mat-card-subtitle>

    <mat-card-subtitle *ngIf="state !== ComputationState.IN_PROGRESS">
      Status:
      <span
        *ngIf="
          state === ComputationState.SUCCESS &&
          computation.status_code !== appConstantService.noResultComputationStatusCode
        "
        class="ch-clr-ok"
      >
        successfully completed
        <mat-icon
          *ngIf="computation.status_message && computation.status_message.startsWith('No more')"
          [inline]="true"
          [matTooltip]="computation.status_message"
          matTooltipClass="ch-tooltip"
        >
          info_outline
        </mat-icon>
      </span>
      <span
        *ngIf="
          state === ComputationState.SUCCESS &&
          computation.status_code === appConstantService.noResultComputationStatusCode
        "
        class="ch-clr-ok"
      >
        successfully completed, no results found
      </span>
      <ng-template [ngIf]="state === ComputationState.ERROR">
        <span class="ch-clr-warn">finished with errors</span>
        <button
          mat-button
          class="ch-computation-status ch-clr-warn"
          (click)="showErrors()"
          matTooltip="Show details of errors"
          matTooltipPosition="right"
        >
          <mat-icon class="ch-error-icon-hover">error_outline</mat-icon>
        </button>
      </ng-template>
      <span *ngIf="state === ComputationState.STOPPED" class="ch-clr-accent-strong">
        stopped automatically
        <mat-icon
          *ngIf="computation.status_message"
          [inline]="true"
          [matTooltip]="computation.status_message"
          matTooltipClass="ch-tooltip"
        >
          info_outline
        </mat-icon>
      </span>
      <span *ngIf="state === ComputationState.INTERRUPTED_BY_USER" class="ch-clr-accent-strong"
        >interrupted by user</span
      >
      <ng-template
        [ngIf]="state === ComputationState.PENDING || state === ComputationState.IN_QUEUE"
      >
        waiting in the queue
      </ng-template>
    </mat-card-subtitle>

    <mat-card-subtitle *ngIf="computationTime">
      Time elapsed: {{ state === ComputationState.PENDING ? '00:00:00' : parsedComputationTime }}
    </mat-card-subtitle>
    <mat-card-subtitle
      *ngIf="
        computationTime === 0 &&
        state !== ComputationState.PENDING &&
        state !== ComputationState.IN_QUEUE &&
        state !== ComputationState.IN_PROGRESS
      "
    >
      Time elapsed: less than 1 sec.
    </mat-card-subtitle>

    <mat-card-subtitle *ngIf="isIterationsLimit()">
      Autostop at {{ computation.input.max_iterations }} iterations
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="isTimeLimit()"> Autostop at {{ autostopAfter }} </mat-card-subtitle>

    <ng-container *ngIf="!!progressItemSummary">
      <mat-card-subtitle *ngIf="progressItemSummary.iterations">
        Iterations: {{ progressItemSummary.iterations }}
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="progressItemSummary.paths_found">
        Paths found: {{ progressItemSummary.paths_found }}
      </mat-card-subtitle>
    </ng-container>

    <ng-container *ngIf="isComputationInProgress()" class="ch-computation-detail-right">
      <button
        *ngIf="!stopped"
        class="ch-active-stop-button ch-clr-primary ch-computation-detail-stop-button"
        [class.ch-clr-primary-mlight]="stopping"
        (click)="stopComputation()"
        mat-stroked-button
        [disabled]="stopping"
      >
        {{ stopButtonMessage }}
      </button>

      <button
        *ngIf="stopped"
        class="ch-active-stop-button ch-clr-primary ch-clr-primary-mlight ch-computation-detail-stop-button"
        mat-stroked-button
        [disabled]="stopped"
      >
        Stopped
      </button>
    </ng-container>
  </div>
</div>
