import { isString } from '../../../components/utils';

export class AcknowledgementSection {
  public id: number = 0;
  public content: string = '';
  public accepted: boolean = false;

  constructor(data?: any) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to Acknowledgement Section model.`,
        );
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to Acknowledgement Section model.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
