import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// Fully missing feature in the new UI
@Component({
  selector: 'ch-intro-tour-dialog',
  templateUrl: './intro-tour-dialog.component.html',
  styleUrls: ['./intro-tour-dialog.component.scss'],
})
export class IntroTourDialogComponent implements OnInit {
  @Input() public tourName: string = '';

  constructor(public dialogRef: MatDialogRef<IntroTourDialogComponent>) {}

  public ngOnInit() {
    this.dialogRef.updateSize('550px', '500px');
  }

  public startTutorial() {
    this.dialogRef.close('start');
  }

  public remindLater() {
    this.dialogRef.close('remind');
  }

  public hideTutorial() {
    this.dialogRef.close('hide');
  }
}
