import { isString } from '../../shared/components/utils';

export class EmdToken {
  /* tslint:disable:variable-name */
  public token_type: string = '';
  public access_token: string = '';
  public id_token: string = '';
  public refresh_token: string = '';
  public expires_in: number = 100;
  /* tslint:enable:variable-name */

  constructor(data?) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          if (field === 'access_token') {
            this[field] = 'Bearer ' + jsonData[field];
          } else {
            this[field] = jsonData[field];
          }
        } else {
          // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
          console.error(
            `Unexpected type of field '${field}' in data provided to EmdToken:ctor.` +
              `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
          );
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to EmdToken:ctor.`);
      }
    }
  }
}
