<div>
  <div class="ch-dialog-top-new">
    <h3>
      E-Commerce Connection
    </h3>
    <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
  </div>

  <div class="ch-dialog-body-new">
    <span
      >To unlock the power of purchasing materials from your SYNTHIA<sup class="ch-trade-mark"
        >TM</sup
      >
      results,</span
    >
    <span>click ‘Continue’ to sign into your e-commerce account or create a new one.</span>
    <span>Then return to this tab to add materials directly into your shopping list.</span>
  </div>

  <div class="ch-dialog-btn-section-new">
    <button mat-button class="ch-outline-btn" [buttonOutline]="'primary'" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button [color]="'primary'" (click)="open()">
      Continue
    </button>
  </div>
</div>
