<div>
  <div class="results-filter-section">
    <span class="results-filter-heading">
      CURRENT FILTERS
    </span>
    <span class="mat-button-wrapper current-filter-info">
      <mat-icon
        class="grey-icon"
        [matTooltip]="resultsFilterInfo"
        matTooltipClass="ch-tooltip"
        matTooltipPosition="above"
        >info_outline</mat-icon
      >
    </span>
    <span *ngIf="!isSliderFilterDisabled" class="mat-button-wrapper filter-toggle-icon-section">
      <mat-icon
        class="grey-icon"
        [class.selected-filter]="!isSliderFilterSection"
        (click)="toggleFilterSection(filterSection.DEFAULT)"
        svgIcon="ch:horizontal-split"
      >
      </mat-icon>
      <span class="filter-icon-padding">
        <mat-icon
          class="grey-icon"
          [class.selected-filter]="isSliderFilterSection"
          (click)="toggleFilterSection(filterSection.SLIDER)"
          >tune</mat-icon
        >
      </span>
    </span>
  </div>
  <div class="reset-filters-button">
    <button
      class="ch-outline-btn outline-btn mat-button ch-primary-button-font"
      mat-button
      buttonOutline="primary"
      [disabled]="isResetDisabled"
      (click)="resetFilters()"
    >
      Reset Filters
    </button>
  </div>
  <div *ngIf="!isSliderFilterSection">
    <ch-results-filter-default
      (structureFilterEmitter)="applyStructureFilters($event)"
      (keywordFilter)="applyKeywordFilter($event)"
      (reactionFilter)="applyReactionFilter($event)"
    >
    </ch-results-filter-default>
  </div>
  <div *ngIf="isSliderFilterSection">
    <ch-results-filter-slider
      (addPriceFilter)="addPriceFilter($event)"
      (addNumberOfReactionFilter)="addNumberOfReactionFilter($event)"
      (addProtectiveStepsFilter)="addProtectiveStepsFilter($event)"
      (pathwaySimilarityFilter)="addPathwaySimilarity($event)"
    ></ch-results-filter-slider>
  </div>
</div>
