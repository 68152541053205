import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'chIsStopComputationButtonAvailable',
})
export class IsStopComputationButtonAvailablePipe implements PipeTransform {
  public transform(
    computationInProgress: boolean,
    isComputationStopped: boolean,
    isAutomaticRetrosynthesisAnalysis: boolean,
  ): boolean {
    return computationInProgress && !isComputationStopped && isAutomaticRetrosynthesisAnalysis;
  }
}
