import { ProductInfo } from './ProductInfo';

export class CasNumberSearchResponse {
  public apiCallFailedCount: number;
  public productInfoList: ProductInfo[];
  constructor() {
    this.apiCallFailedCount = 0;
    this.productInfoList = new Array<ProductInfo>();
  }
}
