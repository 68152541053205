import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConstantsService, ParametersStashService } from '../../services';
import { AppState } from '../../../app-state.service';

@Component({
  selector: 'ch-about-application-dialog',
  templateUrl: './about-application-dialog.component.html',
  styleUrls: ['./about-application-dialog.component.scss'],
})
export class AboutApplicationDialogComponent implements OnInit, OnDestroy {
  public isMilliporeSigmaCountry: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  public version: string = APP_CONFIG.LAST_TAG;
  public logoText: string = '';
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    public appConstantsService: AppConstantsService,
    public dialogRef: MatDialogRef<AboutApplicationDialogComponent>,
    public appStateService: AppState,
    private stashService: ParametersStashService,
  ) {}

  public ngOnInit(): void {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.logoText = this.isSynthiaLiteEnabled
      ? this.appConstantsService.synthiaLiteLogoText
      : this.appConstantsService.synthiaEnterpriseLogoText;

    this.appStateService.country.pipe(takeUntil(this.unsubscribeFromAll)).subscribe(() => {
      this.isMilliporeSigmaCountry = this.appStateService.isMilliporeSigmaCountry();
    });
  }

  public ngOnDestroy() {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }
}
