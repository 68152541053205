import { Directive, OnChanges, Input, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Directive allows for validation of equality of two fields (for example email and email confirmation).
 * It is realized by validation of the common parent component (Form or FormGroup) therefore to use
 * this directive apply it there.
 *
 * For example:
 *
 *   <form #emailChangeForm="ngForm" name="emailChangeForm" chEqualFields="email, emailConfirmation">
 *
 *     <input name="email" #email="ngModel" [(ngModel)]="email"
 *     ... />
 *
 *     <input name="emailConfirmation" #emailConfirmation="ngModel" [(ngModel)]="emailConfirmation"
 *     ... />
 *
 *     <p *ngIf="emailChangeForm.hasError('chEqualFields') && !email.pristine && !emailConfirmation.pristine">
 *       Email and email confirmation do not match.
 *     </p>
 *   ....
 *
 *   </form>
 *
 *  chEqualFields accepts paths to fields so you can use names such as "groupAuth.secret, groupAuth.secretRepeat"
 *  It does some minimal parameters validation but ignore missing components as is is often initialized before
 *  referred components become available.
 */

export function equalFieldsValidator({
  firstControlPath,
  secondControlPath,
}: {
  firstControlPath: string;
  secondControlPath: string;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const firstControl = control.get(firstControlPath);
    const secondControl = control.get(secondControlPath);
    return !!firstControl && !!secondControl && firstControl.value !== secondControl.value
      ? { chEqualFields: { mismatch: true } }
      : null;
  };
}

@Directive({
  selector: '[chEqualFields]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualFieldsValidatorDirective, multi: true }],
})
export class EqualFieldsValidatorDirective implements Validator, OnChanges {
  @Input() public chEqualFields: string;
  private valFn = Validators.nullValidator;

  public ngOnChanges(changes: SimpleChanges): void {
    const change = changes['chEqualFields'];
    if (change) {
      const paths = change.currentValue.split(/\s*\,\s*/);
      if (paths.length === 2) {
        this.valFn = equalFieldsValidator({
          firstControlPath: paths[0],
          secondControlPath: paths[1],
        });
        return;
      } else {
        console.log(
          'EqualFieldsValidatorDirective expects paths to two child controls' +
            ' separated with a colon in "chEqualFields" attribute, for ex. chEqualFields="inputFirst, inputSecond".' +
            ' Value provided: ' +
            change.currentValue,
        );
      }
    }
    this.valFn = Validators.nullValidator;
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this.valFn(control);
  }
}
