<div class="ch-nodes-clipboard-scroll">
  <h3 class="ch-title" [style.margin-left.px]="10">
    CLIPBOARD
    <button
      *ngIf="nodes.length != 0"
      mat-icon-button
      class="ch-action-icon-hover ch-export-clipboard-items"
      (click)="exportAllNodes()"
    >
      <mat-icon
        class="grey-icon ch-export-clipboard-items"
        matTooltip="Export clipboard"
        matTooltipClass="ch-tooltip full-width-tooltip"
        >save_alt</mat-icon
      >
    </button>
  </h3>
  <div *ngIf="nodes.length === 0" class="ch-nodes-clipboard-empty">
    <ng-container>
      <p *ngIf="!isLibraryMode">
        <span
          >Add molecules or reactions here by clicking 'Add to clipboard' from the corresponding
          three-dot menu or details window.</span
        >
      </p>
      <p *ngIf="isLibraryMode">
        <span
          >Add molecules or reactions here by clicking 'Add to clipboard' in the corresponding
          details window.</span
        >
      </p>
      <p *ngIf="!isLibraryMode && !isPathView && !isManualMode">
        Hold <kbd>Ctrl</kbd> or <kbd>⌘</kbd> (Mac OS) and click on a node to add the corresponding
        molecule or reaction here
      </p>
    </ng-container>
  </div>
  <button
    *ngIf="nodes.length > 0"
    mat-raised-button
    color="primary"
    (click)="deselectAllNodes()"
    [disabled]="!isSelected"
    class="ch-nodes-clipboard-deselect-button"
  >
    DESELECT ALL
  </button>

  <mat-grid-list *ngIf="nodes.length > 0" cols="1" gutterSize="5px" rowHeight="280px">
    <mat-grid-tile *ngFor="let node of nodes">
      <ch-new-clipboard-item
        class="ch-new-clipboard-item"
        #nodeItem
        [node]="node"
        [graphId]="graphId"
        [algorithm]="algorithm"
        [height]="itemHeight"
        [mode]="mode"
        (onAddMoleculeToSet)="addMoleculeToSet($event)"
        (onDelete)="deleteNode($event)"
        (onMouseDown)="clipboardService.dispatchNodeEvent($event, 'mousedown')"
        (onMouseUp)="clipboardService.dispatchNodeEvent($event, 'mouseup')"
        (onStartNewAnalysis)="clipboardService.dispatchNodeEvent($event, 'newanalysis')"
      >
      </ch-new-clipboard-item>
    </mat-grid-tile>
  </mat-grid-list>
</div>
