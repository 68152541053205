import { isString } from '../../shared/components/utils';
export class SubstanceProductDetailInfo {
  public brand: string;
  public brand_key: string;
  public id_substance: string;
  public materials: string[];
  public product_available: boolean;
  public product_key: string;
  public product_name: string;
  public product_name_suffix: string;
  public product_number: string;
  public substance_key: string;
  public is_sial: string;

  constructor(data: any, is_bbe_data: boolean = false, bbe_material: string = null) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    if (is_bbe_data) {
      this.product_key = bbe_material;
    } else {
      this.product_key = jsonData.product_key;
    }
    this.brand = jsonData.brand;
    this.brand_key = jsonData.brand_key;
    this.id_substance = jsonData.id_substance;
    this.materials = jsonData.materials;
    this.product_available = jsonData.product_available;
    this.product_name = jsonData.product_name;
    this.product_name_suffix = jsonData.product_name_suffix;
    this.product_number = jsonData.product_number;
    this.substance_key = jsonData.substance_key;
    this.is_sial = jsonData.is_sial;
  }
}
