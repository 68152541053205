import { isString } from '../../../components/utils';

export class CountryDetails {
  public name: string = '';
  public code: string = '';
  public calling_code: number = 0;

  constructor(data?: any) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to CountryDetails model.`);
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to CountryDetails model.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
