<div *ngIf="newUiStyle; else oldUiTemplate">
  <div class="ch-dialog-top-new">
    <span>
      {{ title }}
    </span>
    <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
  </div>

  <div class="ch-dialog-body-new">
    <p [innerHTML]="message"></p>

    <div *ngIf="!!showCheckbox && !!checkboxMessage">
      <mat-checkbox color="primary" [(ngModel)]="confirmationCheckbox">
        <span [innerHtml]="checkboxMessage"></span>
      </mat-checkbox>
    </div>
  </div>

  <div class="ch-dialog-btn-section-new">
    <ng-template [ngIf]="!switchButtonsPriority">
      <button
        mat-stroked-button
        *ngIf="showFalseAction"
        color="primary"
        [buttonOutline]="'primary'"
        class="ch-outline-btn"
        (click)="dialogRef.close()"
      >
        {{ falseActionName }}
      </button>
      <button
        mat-raised-button
        [color]="!!showCheckbox && !!checkboxMessage ? 'warn' : 'primary'"
        [disabled]="!!showCheckbox && !!checkboxMessage && !confirmationCheckbox"
        (click)="dialogRef.close(true)"
      >
        {{ trueActionName }}
      </button>
    </ng-template>

    <ng-template [ngIf]="switchButtonsPriority">
      <button mat-raised-button *ngIf="showFalseAction" color="primary" (click)="dialogRef.close()">
        {{ falseActionName }}
      </button>
      <button
        mat-button
        class="section-button ch-outline-btn"
        [buttonOutline]="primary"
        [disabled]="!!showCheckbox && !!checkboxMessage && !confirmationCheckbox"
        (click)="dialogRef.close(true)"
      >
        {{ trueActionName }}
      </button>
    </ng-template>
  </div>
</div>

<ng-template #oldUiTemplate>
  <div mat-dialog-title class="ch-info-dialog-top">
    <span>
      {{ title }}
      <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
    </span>
  </div>

  <mat-dialog-content>
    <p [innerHTML]="message"></p>

    <div *ngIf="!!showCheckbox && !!checkboxMessage">
      <mat-checkbox color="primary" [(ngModel)]="confirmationCheckbox">
        <span [innerHtml]="checkboxMessage"></span>
      </mat-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="ch-info-dialog-controls">
      <button
        class="cancel-btn"
        mat-stroked-button
        color="primary"
        *ngIf="showFalseAction"
        [buttonOutline]="'primary'"
        (click)="dialogRef.close()"
      >
        {{ falseActionName }}
      </button>
      <button
        mat-raised-button
        [color]="!!showCheckbox && !!checkboxMessage ? 'warn' : 'primary'"
        [disabled]="!!showCheckbox && !!checkboxMessage && !confirmationCheckbox"
        (click)="dialogRef.close(true)"
      >
        {{ trueActionName }}
      </button>
    </div>
  </mat-dialog-actions>
</ng-template>
