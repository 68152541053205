<div *ngIf="!!report" class="viewer-wrapper new-ui">
  <h4 *ngIf="report.conformers && report.conformers[selectedConformer]" class="conf-energy">
    Conformer energy {{ report.conformers[selectedConformer].energy | number: '1.1-3' }} kcal/mol
  </h4>

  <div class="conformer-viewers">
    <ch-molecule-3d-viewer
      class="conf-3d"
      [backgroundColor]="'#ffffff'"
      [toolbarPosition]="'BOTTOM'"
      [src]="report.conformers[selectedConformer]?.molfile"
    >
    </ch-molecule-3d-viewer>

    <ch-authorized-image class="conf-2d" [src]="report.minimum_energy_conformer_image">
    </ch-authorized-image>
  </div>

  <div
    *ngIf="bondProblems > -1 || angleProblems > -1 || dihedralProblems > -1"
    class="ch-molecular-strain-report-problems-summary"
  >
    <div
      *ngIf="bondProblems > 0 || angleProblems > 0 || dihedralProblems > 0; else noStrainProblems"
    >
      <span class="ch-clr-warn">Strain problems detected</span>
    </div>
    <ng-template #noStrainProblems>
      <span class="ch-clr-ok">No strain problems detected</span>
    </ng-template>
  </div>
</div>

<div class="ch-molecular-strain-report-unavailable" *ngIf="!report || loading">
  <div *ngIf="loading" class="ch-molecular-strain-report-loading">
    <mat-spinner [strokeWidth]="2" [diameter]="50"></mat-spinner>
  </div>
  <h4 *ngIf="!loading">
    Strain report not available.<span *ngIf="errorMessage !== ''"> {{ errorMessage }}</span>
  </h4>
</div>
