<mat-dialog-content>
  <p [innerHTML]="message"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="ch-info-dialog-controls">
    <button mat-raised-button color="primary" (click)="closeDialog()">
      {{ actionName }}
    </button>
  </div>
</mat-dialog-actions>
