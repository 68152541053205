import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { IStepOption } from 'ngx-ui-tour-md-menu';

interface ICustomStepOption extends IStepOption {
  anchorId?: any;
  title?: string;
  content?: string;
  showNextStepButton?: boolean;
  showPrevStepButton?: boolean;
}

export const DASHBOARD_AUTORETRO_TOUR_STEPS: ICustomStepOption[] = [
  {
    anchorId: 'add-analysis',
    title: 'Welcome to the interactive Automatic Retrosynthesis tutorial!',
    content: `Click either the <img src="../assets/img/start-button.png" class="search-button-preview">
      button or the <img src="../assets/img/top-start-button.png"
      class="top-search-button-preview"> button to start a new analysis`,
    enableBackdrop: true,
  },
];

export const ANALYSIS_CREATION_AUTORETRO_TOUR_STEPS: ICustomStepOption[] = [
  {
    anchorId: 'select-auto-retro',
    title: 'Select analysis type',
    content: 'Click on the Automatic Retrosynthesis tile to select this type of analysis',
    enableBackdrop: true,
  },
  {
    anchorId: 'search-molecule',
    title: 'Search bar',
    content: `Use the search bar to search for known molecules.<br>
      Try typing <strong class="ch-clr-primary">oxymetazoline</strong>
      and press <kbd>Enter</kbd> on your keyboard<br>or click on the
      <img src="../assets/img/search-button.png" class="search-button-preview"> button`,
    enableBackdrop: true,
  },
  {
    anchorId: 'oxymetazoline',
    title: 'Search results',
    content: `Click on <kbd>SELECT TARGET</kbd> to choose this molecule as the analysis target.`,
    enableBackdrop: true,
  },
  {
    anchorId: 'molecule-information-step',
    title: 'Selected molecule',
    content: `Your selected molecule is displayed here.
      Click on <kbd>MOLECULE EDITOR</kbd> to edit the molecular structure if needed.`,
    enableBackdrop: true,
  },
  {
    anchorId: 'marvin-editor',
    title: 'Molecule editor',
    content: 'You can modify the selected molecule, draw a new one, or paste a SMILES code.',
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'editor-confirm-button',
    title: 'Confirm structure',
    content: `Once you are satisfied with your structure,
      click on the <kbd>CONFIRM & CONFIGURE RETROSYNTHESIS</kbd> button`,
    enableBackdrop: true,
  },
  {
    anchorId: 'quick-start-analysis-button',
    title: 'Start with Default Parameters',
    content: `If you want to run your initial search with the
      default settings, you can click the <kbd>QUICK START</kbd>
      button, otherwise click <kbd>NEXT</kbd> to modify the
      search parameters. To continue through this tutorial, click <kbd>NEXT</kbd>.`,
    enableBackdrop: true,
  },
  {
    anchorId: 'scoring-function-start',
    title: 'Scoring function configuration',
    content: `The ‘Scoring function’ tells the algorithm how to score the pathways it finds and how to make
      retrosynthetic cuts to your molecule. The default
      parameters below will generate a balanced set of
      pathways, minding the price of starting materials and
      favoring paths that need less or no protecting groups.
      They will also return a combination of linear and
      convergent pathways.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-shorter-paths',
    title: 'Shorter paths',
    content: `This option may be selected if you want
      shorter paths, regardless of the price of the
      starting materials (within the price limit set in
      the Stop Conditions).`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid',
    title: 'Eliminate what you do not need',
    content: `This checkbox will automatically be checked
      if you start filling one of the text boxes below.
      There are three ways to eliminate unwanted reactions
      or molecules from your results. Click <kbd>NEXT</kbd>
      to see more information about each one.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid-smiles',
    title: 'Avoid specific SMILES',
    content: `To eliminate specific molecules from your
      results, just type or paste their corresponding
      SMILES here, separated by spaces or dots.
      Alternatively, open the molecule editor to draw the
      molecules and click <kbd>add SMILES</kbd> and <kbd>ACCEPT</kbd>.
      Click <kbd>NEXT</kbd> to see how to avoid substructures.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid-smarts',
    title: 'Avoid specific SMARTS',
    content: `To eliminate specific substructures from your results,
      paste their corresponding SMARTS here. You can copy SMARTS directly
      from structures drawn in one of the common chemical editors.
      Alternatively, open Synthia's molecule editor by clicking on the icon
      on the right. Draw the substructure to avoid, click on <kbd>add SMARTS</kbd>
      and close the molecule editor.
      Click on <kbd>NEXT</kbd> to see how to avoid keywords.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid-keywords',
    title: 'Avoid specific keywords',
    content: `To eliminate specific reactions from your results,
      just type related keywords from their names or conditions
      here, separated by semicolons.
      Click <kbd>NEXT</kbd> to see how to avoid molecules sets.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid-molset',
    title: 'Avoid molecule sets',
    content: `You can eliminate a whole list of molecules
      at once by selecting a default or custom molecule
      set from the dropdown menu. See the user manual to
      learn how to make your own custom molecule set.
      Click <kbd>NEXT</kbd> to see how to avoid sets of substructures.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-avoid-smartsset',
    title: 'Avoid SMARTS sets',
    content: `You can eliminate a whole list of unwanted substructures at once
      by selecting a default or custom SMARTS set from the dropdown menu.
      See the user manual to learn how to make your own custom SMARTS set.
      Click on <kbd>NEXT</kbd> to see how to configure protecting groups requirements.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-protecting-groups',
    title: 'Configure protecting groups requirements',
    content: `You can decide if you prefer to see no steps
      requiring protecting groups, or if don’t mind them.
      Click <kbd>NEXT</kbd> see how to favor linear or convergent pathways.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'scoring-function-pathways-configuration',
    title: 'Pathways configuration',
    content: `Decide if you want to favor linear or convergent
      pathways, or a mixture of both. Click <kbd>NEXT</kbd>
      to continue.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'next-third-step-button',
    title: 'Advanced Search Parameters',
    content: `Click <kbd>NEXT</kbd> to set up the Stop Conditions.`,
    enableBackdrop: true,
  },
  {
    anchorId: 'buyable',
    title: 'Modify Stop Conditions - Buyable Compounds',
    content: `You can adjust the molecular weight and price per gram thresholds for
      ‘Buyable’ starting materials according to your preferences.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'known',
    title: 'Modify Stop Conditions - Known Compounds',
    content: `You can also adjust the molecular weight threshold and
      the popularity of the ‘Known’ starting materials.
      Popularity refers to the number of times a compound appears in
      our database, either as a reactant or as a product of a
      reaction.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'known',
    title: 'Modify Stop Conditions - Known Compounds',
    content: `Try to adjust the minimum popularity to 5 by clicking
      in the ‘popularity’ box and changing the number to 5 with your
      keyboard, or clicking the <kbd>+</kbd> button until you hit 5.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'analysis-complexity',
    title: 'Select your analysis complexity',
    content: `Analysis complexity will determine how many time Synthia
    is going to explore the tree of synthetic possibilities.
    Increasing the number of iterations may increase the chances of finding more diverse or better pathways.
    The default complexity is set for a simple molecule, with no or few modifications from default parameters.<br><br>
    If you do not find satisfactory results with it, or if you
    are running a more complex search, try <strong class="ch-clr-primary">‘Long Analysis’</strong>.<br>
    If you just want to quickly check what kind of results are
    returned for your search, select <strong class="ch-clr-primary">‘Quick Analysis’</strong>.<br>
    You may also customize the number of iterations by using
    <strong class="ch-clr-primary">‘Custom Analysis’</strong>.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'help-button',
    title: 'Finding the ‘Support’ Section',
    content: `To find Help anytime, click on the <kbd>SUPPORT</kbd> button
      near your username and select <strong class="ch-clr-primary">Training</strong>
      from the drop down menu. To continue with this tutorial click <kbd>
      NEXT</kbd>.`,
    enableBackdrop: true,
    showNextStepButton: true,
  },
  {
    anchorId: 'start-analysis-button',
    title: 'Start Search',
    content: `Once you are happy with all of the search parameters, click <kbd>START</kbd> to start your search.`,
    enableBackdrop: true,
  },
];

export const AUTO_RETRO_TUTORIAL_MOLECULE = 'oxymetazoline';

export type TourNameType = 'dashboardAutoRetro' | 'analysisCreationAutoRetro';

@Injectable()
export class InteractiveTourHandleService {
  public steps: IStepOption[] = [];
  public disableHotKeys: boolean = false;
  public showTour: BehaviorSubject<boolean>;
  public showNextStep: BehaviorSubject<boolean>;
  public showPrevStep: BehaviorSubject<boolean>;
  public activeStepId: string;

  constructor() {
    this.showTour = new BehaviorSubject<boolean>(false);
    this.showNextStep = new BehaviorSubject<boolean>(false);
    this.showPrevStep = new BehaviorSubject<boolean>(false);
  }

  public addSteps(stepList: IStepOption[]) {
    for (const step of stepList) {
      this.steps.push(step);
    }
  }

  public clearStepList() {
    this.steps = [];
  }

  public nextStep() {
    this.showNextStep.next(true);
    this.showNextStep.next(false);
  }

  public prevStep() {
    this.showPrevStep.next(true);
    this.showPrevStep.next(false);
  }

  public startTour(tourName: TourNameType) {
    this.showTour.next(false);
    this.clearStepList();
    this.disableHotKeys = true;

    switch (tourName) {
      case 'dashboardAutoRetro':
        this.steps = DASHBOARD_AUTORETRO_TOUR_STEPS;
        break;
      case 'analysisCreationAutoRetro':
        this.steps = ANALYSIS_CREATION_AUTORETRO_TOUR_STEPS;
        break;
      default:
        console.error(
          'InteractiveTourHandleService: Unexpected tour name. Make sure that tour name matches existing tutorials.',
        );
        break;
    }

    if (this.steps.length > 0) {
      this.showTour.next(true);
    }
  }
}
