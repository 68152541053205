import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { filter, pairwise, tap } from 'rxjs/operators';
import { ParametersStashService } from '../services/parameters-stash/parameters-stash.service';
import { AppConstantsService } from '../services/app-constants';

@Injectable({
  providedIn: 'root',
})
export class ChematicaRouteStateService {
  public lastDashboardUrl: BehaviorSubject<string>;
  public lastAnalysisUrl: BehaviorSubject<string>;
  public fromAnalysisCreation: BehaviorSubject<boolean>;
  public reuseAnalysisCreation: Subject<boolean>;
  public currentAnalysisUrl: BehaviorSubject<string>;
  public landingPageUrl: BehaviorSubject<string>;
  public routerSubscription: Subscription;
  public stopRoutingToHome: BehaviorSubject<boolean>;
  public emdCode: BehaviorSubject<string>;
  public isComingFromChemdraw: BehaviorSubject<boolean>;
  public planSubscriptionStatus: BehaviorSubject<string>;
  public loadMarvinFromChemdraw: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private appConstantsService: AppConstantsService,
    private stashService: ParametersStashService,
  ) {
    this.lastDashboardUrl = new BehaviorSubject<string>('');
    this.lastAnalysisUrl = new BehaviorSubject<string>('');
    this.currentAnalysisUrl = new BehaviorSubject<string>('');
    this.fromAnalysisCreation = new BehaviorSubject<boolean>(false);
    this.reuseAnalysisCreation = new Subject<boolean>();
    this.landingPageUrl = new BehaviorSubject<string>('');
    this.stopRoutingToHome = new BehaviorSubject<boolean>(false);
    this.emdCode = new BehaviorSubject<string>('');
    this.isComingFromChemdraw = new BehaviorSubject<boolean>(false);
    this.loadMarvinFromChemdraw = new BehaviorSubject<boolean>(false);
    this.planSubscriptionStatus = new BehaviorSubject<string>('');
    this.subscribeToRouter();
  }

  public subscribeToRouter() {
    if (
      window.location.href.includes('/ecomm?response_status_code=') &&
      !window.location.href.includes('/#/home/ecomm-authorized') &&
      window.location.href.includes('&code=')
    ) {
      let url = window.location.href;
      url = url.replace('/ecomm', '/home/ecomm-authorized');
      window.location.href = url;
    }
    if (
      window.location.href.includes('/ecomm-authorized') &&
      !window.location.href.includes('/home/ecomm-authorized')
    ) {
      let url = window.location.href;
      url = url.replace('/ecomm-authorized', '/home/ecomm-authorized');
      window.location.href = url;
    }
    const analysisUrlRegex: RegExp = new RegExp(/\/analysis\/[0-9]*/);
    const homeUrlRegex: RegExp = new RegExp(/\/home\/[a-zA-Z_]*\/[\w\-]*\/[0-9]*/);
    this.fromAnalysisCreation.next(!!JSON.parse(sessionStorage.getItem('fromAnalysisCreation')));
    if (window.location.href.includes('?code=')) {
      this.emdCode.next(window.location.href.substring(window.location.href.indexOf('=') + 1));
    }
    if (window.location.href.includes('?source=chemdraw')) {
      if (window.location.href.includes('param=marvin')) {
        this.loadMarvinFromChemdraw.next(true);
      } else {
        this.isComingFromChemdraw.next(true);
        localStorage.setItem('is_coming_from_chemdraw', 'true');
        localStorage.setItem(
          'chemdraw_callback_key',
          window.location.href.substring(window.location.href.indexOf('&state=') + 7),
        );
      }
    }
    if (window.location.href.includes('success=')) {
      this.planSubscriptionStatus.next(
        window.location.href.substring(window.location.href.indexOf('=') + 1),
      );
    }
    this.routerSubscription = this.router.events
      .pipe(
        tap((routerEvent) => {
          if (routerEvent instanceof NavigationStart) {
            if (this.emdCode.value === '') {
              this.currentAnalysisUrl.next(
                analysisUrlRegex.test(routerEvent.url) ? routerEvent.url : '',
              );
            }
          }
        }),
        filter((routerEvent) => routerEvent instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe((routerEvent: [NavigationEnd, NavigationEnd]) => {
        if (this.emdCode.value === '') {
          const analysisUrlMatch: any = routerEvent[0].urlAfterRedirects.match(analysisUrlRegex);
          const homeUrlMatch: any = routerEvent[0].urlAfterRedirects.match(homeUrlRegex);
          if (routerEvent[0].urlAfterRedirects.startsWith('/home/') && homeUrlMatch) {
            this.lastDashboardUrl.next(homeUrlMatch[0]);
          }
          if (analysisUrlMatch) {
            this.lastAnalysisUrl.next(analysisUrlMatch[0]);
          }
          if (
            !(
              routerEvent[0].urlAfterRedirects.startsWith('/sets/') &&
              routerEvent[1].urlAfterRedirects.startsWith('/sets/')
            )
          ) {
            const fromAnalysisCreation: boolean = this.isRoutingFromAnalysisCreationToSets(
              routerEvent[0],
              routerEvent[1],
            );
            sessionStorage.setItem('fromAnalysisCreation', JSON.stringify(fromAnalysisCreation));
            this.fromAnalysisCreation.next(fromAnalysisCreation);
          }
          if (
            routerEvent[0].urlAfterRedirects.startsWith('/sets/') &&
            (routerEvent[1].urlAfterRedirects.startsWith('/new') ||
              routerEvent[1].urlAfterRedirects.startsWith('/rerun'))
          ) {
            this.reuseAnalysisCreation.next(true);
          }
        }
      });
  }

  public navigateToHome() {
    if (!this.stopRoutingToHome.getValue()) {
      if (this.landingPageUrl.value) {
        if (this.landingPageUrl.value !== this.appConstantsService.lastDashboardUrlKey) {
          this.router.navigateByUrl(this.landingPageUrl.value);
        } else if (
          this.landingPageUrl.value === this.appConstantsService.lastDashboardUrlKey &&
          this.lastDashboardUrl.value
        ) {
          this.router.navigateByUrl(this.lastDashboardUrl.value);
        } else {
          this.navigateToDefaultHome();
        }
      } else {
        this.navigateToDefaultHome();
      }
    }
  }

  public navigateToDefaultHome() {
    this.router.navigateByUrl(this.appConstantsService.defaultDashboardUrl);
  }

  public navigateToPrivacyPage(
    openInNewWindow: boolean = true,
    navigateWithAnalysisId: string = '',
  ) {
    if (this.stashService.isSynthiaLiteEnabled) {
      window.open(this.appConstantsService.SynthiaLitePrivacyLink, '_blank', 'noreferrer noopener');
    } else {
      if (openInNewWindow) {
        this.router.navigate([]).then(() => {
          window.open('/#/information/privacy', '_blank', 'noreferrer noopener');
        });
      } else {
        const link: any[] = ['information', 'privacy'];
        if (navigateWithAnalysisId !== '') {
          link.push({ ref: navigateWithAnalysisId });
        }
        this.router.navigate(link);
      }
    }
  }

  public navigateToCnPrivacyPages(
    openInNewWindow: boolean = true,
    navigateWithAnalysisId: string = '',
    path: string,
  ) {
    if (this.stashService.isSynthiaLiteEnabled) {
      window.open(this.appConstantsService.SynthiaLitePrivacyLink, '_blank', 'noreferrer noopener');
    } else {
      if (openInNewWindow) {
        this.router.navigate([]).then(() => {
          window.open(`/#/information/${path}`, '_blank', 'noreferrer noopener');
        });
      } else {
        const link: any[] = ['information', 'privacy'];
        if (navigateWithAnalysisId !== '') {
          link.push({ ref: navigateWithAnalysisId });
        }
        this.router.navigate(link);
      }
    }
  }

  private isRoutingFromAnalysisCreationToSets(previous: NavigationEnd, current: NavigationEnd) {
    return (
      ((previous.urlAfterRedirects.startsWith('/new') ||
        previous.urlAfterRedirects.startsWith('/rerun/')) &&
        current.urlAfterRedirects.startsWith('/sets/')) ||
      (previous.urlAfterRedirects.startsWith('/sets/') &&
        (current.urlAfterRedirects.startsWith('/new') ||
          current.urlAfterRedirects.startsWith('/rerun/')))
    );
  }
}
