import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountSettingsComponent } from './account-settings';
import { AppConstantsService } from './shared/services';
import { ConfirmDialogConfiguration } from './shared/services/confirm-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AskForLeaveGuard {
  constructor(public appConstants: AppConstantsService) {}

  public canDeactivate(component: AccountSettingsComponent): Observable<boolean> | boolean {
    if (!component.newSettingsNotSaved) {
      return true;
    }

    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: this.appConstants.unsavedChangesDialogTitle,
      message: this.appConstants.unsavedChangesDialogMessage,
      newUiStyle: true,
      switchButtonsPriority: true,
      trueActionName: 'Discard Changes',
    };

    return component.confirmDialogService.confirm(dialogConfiguration);
  }
}
