import { MatPaginatorIntl } from '@angular/material/paginator';

/* tslint:disable:max-classes-per-file */
export class PathsLabelMatPaginatorIntl extends MatPaginatorIntl {
  public itemsPerPageLabel: string = 'Paths per page:';
}

export class MoleculeSetsLabelMatPaginatorIntl extends MatPaginatorIntl {
  public itemsPerPageLabel: string = 'Molecule sets per page:';
}
/* tslint:enable:max-classes-per-file */
