import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  public message: string;
  public actionName: string = 'OK';

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {}

  public closeDialog() {
    this.dialogRef.close(true);
  }
}
