import { isString } from '../../../components/utils';
import { translateRPNToScoringFunction } from '../../../../shared/components/scoring-function-utils';
import { AnalysisAlgorithm, ComputationState } from '../../app-constants/app-constants.service';

export class ComputationEntry {
  private static knownStates: Set<string> = new Set([
    ComputationState.PENDING,
    ComputationState.IN_QUEUE,
    ComputationState.IN_PROGRESS,
    ComputationState.ERROR,
    ComputationState.SUCCESS,
    ComputationState.INTERRUPTED_BY_USER,
    ComputationState.STOPPED,
  ]);
  private static dateFields: Set<string> = new Set([
    'created_at',
    'updated_at',
    'finished_at',
    'started_at',
    'end_date',
  ]);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public user: number = 0;
  public analysis: number = 0;
  public algorithm: string = '';
  public name: string = 'Computation';
  public state: string = '';
  public computation_time: number = 0;
  public status_code: string = '';
  public status_message: string = '';
  public country_code: string | null = '';
  public progress_item: string = '';
  public created_at: Date = null;
  public started_at: Date = null;
  public updated_at: Date = null;
  public finished_at: Date = null;
  public parent_molecule: string | null = '';
  public parent_reaction_node: number = 0;
  public permission_token: number | null = null;
  public input: object | any = null;
  public sort: number = 0;
  public end_date: Date = null;
  public fixed_duration: number = 0;
  public stop_requested: boolean = false; // waits for implementation in the midend
  public traversed_nodes: number = 0;
  public traversed_paths: number = 0;
  public warnings: any[] = [];
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const doDefault = (field) => {
      if (typeof jsonData[field] === typeof this[field]) {
        this[field] = jsonData[field];
      } else {
        // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
        console.error(
          `Unexpected type of field '${field}' in data provided to ComputationEntry:ctor.` +
            `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
        );
      }
    };

    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (field === 'state') {
          if (ComputationEntry.knownStates.has(jsonData[field])) {
            this[field] = jsonData[field];
          } else {
            console.error(
              `Unexpected value of field 'state' in data provided to ComputationEntry:ctor` +
                `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
            );
          }
        } else if (ComputationEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else {
          switch (field) {
            case 'parent_reaction_node':
            case 'parent_molecule':
            case 'computation_time':
            case 'country_code':
              if (jsonData[field] !== null) {
                doDefault(field); // leave defaults if null provided
              }
              break;
            case 'traversed_nodes':
            case 'traversed_paths':
              if (jsonData[field] === null) {
                // default value for those fields - if null comes from midend it is note a number then
                this[field] = 0;
              } else {
                doDefault(field);
              }
              break;
            case 'permission_token':
              if (typeof jsonData[field] !== 'number' && jsonData[field] !== null) {
                doDefault(field);
              }
              break;
            case 'input':
              if (
                jsonData[field] &&
                jsonData['algorithm'] === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS
              ) {
                if (jsonData[field].rpn_reaction_scoring_formula) {
                  try {
                    jsonData[field].reaction_scoring_formula = translateRPNToScoringFunction(
                      jsonData[field].rpn_reaction_scoring_formula.split(' '),
                      true,
                    );
                  } catch (err) {
                    jsonData[field].reaction_scoring_formula = jsonData[field]
                      .reaction_scoring_formula
                      ? jsonData[field].reaction_scoring_formula
                      : '';
                    console.error(
                      `rpn_reaction_scoring_formula is in invalid format. Error thrown: ${err}`,
                    );
                  }
                }
                if (jsonData[field].rpn_molecule_scoring_formula) {
                  try {
                    jsonData[field].molecule_scoring_formula = translateRPNToScoringFunction(
                      jsonData[field].rpn_molecule_scoring_formula.split(' '),
                      false,
                    );
                  } catch (err) {
                    jsonData[field].molecule_scoring_formula = jsonData[field]
                      .molecule_scoring_formula
                      ? jsonData[field].molecule_scoring_formula
                      : '';
                    console.error(
                      `rpn_molecule_scoring_formula is in invalid format. Error thrown: ${err}`,
                    );
                  }
                }
              }
              doDefault(field);
              break;
            default:
              doDefault(field);
          }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to ComputationEntry:ctor.`);
      }
    }
  }
}
