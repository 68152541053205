import { Subscription, timer } from 'rxjs';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { isUndefined } from '../utils';

@Component({
  selector: 'ch-number-input',
  styleUrls: ['./incremental-number-input.component.scss'],
  templateUrl: './incremental-number-input.component.html',
})
export class IncrementalNumberInputComponent implements OnDestroy, OnChanges {
  private static countDecimals(number) {
    if (Math.floor(number.valueOf()) === number.valueOf()) {
      return 0;
    } else {
      return number.toString().split('.')[1].length || 0;
    }
  }

  @Input() public inputValue: number = 0;
  @Input() public inputMinimum: number;
  @Input() public inputMaximum: number;
  @Input() public disabled: boolean = false;
  @Input() public step: number = 1;

  @Output() public update = new EventEmitter<any>();

  public defValue: number;
  private decimalPlaces: number;
  private subscription: Subscription;

  public ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty('inputValue')) {
      if (this.inputValue > this.inputMaximum) {
        this.inputValue = this.roundNumber(this.inputMaximum);
      } else if (this.inputValue < this.inputMinimum) {
        this.inputValue = this.roundNumber(this.inputMinimum);
      }
      this.defValue = this.roundNumber(this.inputValue);
    }
  }

  @HostListener('document:contextmenu', ['$event'])
  public onComponentRightClick(event) {
    if (!isUndefined(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }

  public onMouseleave() {
    if (!isUndefined(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }

  public downButtonMousedown() {
    this.subscription = timer(250, 70).subscribe((t) => {
      return this.valueDecrement(t);
    });
  }

  public upButtonMousedown() {
    this.subscription = timer(250, 70).subscribe((t) => {
      return this.valueIncrement(t);
    });
  }

  public changeValue(event: any) {
    if (
      event.target.value !== null &&
      event.target.value >= this.inputMinimum &&
      event.target.value <= this.inputMaximum
    ) {
      this.defValue = this.roundNumber(this.inputValue);
    }
    if (this.inputValue === null) {
      this.inputValue = this.roundNumber(this.defValue);
    } else if (event.target.value > this.inputMaximum) {
      this.inputValue = this.roundNumber(this.inputMaximum);
    } else if (event.target.value < this.inputMinimum) {
      this.inputValue = this.roundNumber(this.inputMinimum);
    } else {
      this.inputValue = this.roundNumber(event.target.value);
    }
    return this.update.emit({
      outputValue: this.roundNumber(this.inputValue),
    });
  }

  public valueIncrement(val: number) {
    if (this.inputValue < this.inputMaximum) {
      this.inputValue = this.roundNumber((this.inputValue += this.step));
      this.defValue = this.roundNumber(this.inputValue);
    }
    return this.update.emit({
      outputValue: this.roundNumber(this.inputValue),
    });
  }

  public valueDecrement(val: number) {
    if (this.inputValue > this.inputMinimum) {
      this.inputValue = this.roundNumber((this.inputValue -= this.step));
      this.defValue = this.roundNumber(this.inputValue);
    }
    return this.update.emit({
      outputValue: this.roundNumber(this.inputValue),
    });
  }

  public onMouseup() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private roundNumber(number) {
    if (this.step < 1) {
      this.decimalPlaces = IncrementalNumberInputComponent.countDecimals(this.step);
      return Number(Number(number).toFixed(this.decimalPlaces));
    } else {
      return Math.round(number);
    }
  }
}
