import {
  ATOMS_MAP,
  ATOMIC_MASSES,
  AROMATIC_ATOMS_MAP,
  AROMATIC_ATOMIC_MASSES,
  EXCEPTION_FOR_RINGS,
} from './reaction-list.models';
import { Subscription, Subject } from 'rxjs';
import * as math from 'mathjs';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  ScoringFunctionsService,
  GraphReactionEntry,
  AppConstantsService,
  AnalysisService,
  AnalysisEntry,
  NodesClipboardService,
  AnalysisResultsService,
} from '../../services';
import { CalculatorService } from '../../services/calculator/calculator.service';
import { ScoringFunction } from '../../services/scoring-functions/models/scoring-function';
import { ReactionDetailsComponent } from '../reaction-details';
import { InfoService } from '../../services/info.service';
import { GraphReactionNode, GraphMoleculeNode } from '../../services/graph-builder';
import { UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReactionListMode, ResultsView } from '../../services/analysis/analysis.service';
import { ScoringFunctionCategoryType } from '../scoring-function-utils';

@Component({
  selector: 'ch-reaction-list',
  templateUrl: './reaction-list.component.html',
  styleUrls: ['./reaction-list.component.scss'],
})
export class ReactionListComponent implements OnChanges, OnDestroy, OnInit {
  private static atomsMap: string[] = ATOMS_MAP;
  private static atomicMasses: number[] = ATOMIC_MASSES;
  private static aromaticAtomsMap: string[] = AROMATIC_ATOMS_MAP;
  // This list can be expanded to handle more exceptions
  private static aromaticAtomMasses: number[] = AROMATIC_ATOMIC_MASSES;
  private static exceptionsForRings: Array<{
    exception: string;
    exceptionValue: number;
  }> = EXCEPTION_FOR_RINGS;
  public readonly ReactionListMode = ReactionListMode;
  public readonly ResultsView = ResultsView;
  @Input() public analysis: AnalysisEntry;
  @Input() public reactions: GraphReactionNode[] = [];
  @Input() public targetMolecule: GraphMoleculeNode;
  @Input() public selectedMolecule: GraphMoleculeNode;
  @Input() public markedNodesCount: number = 0;
  @Input() public viewMode: ResultsView;
  @Input() public selectionReactions: any;
  @Input() public moleculeReactionsList: GraphReactionNode[];

  @Output() public onFilter = new EventEmitter<any>();
  @Output() public deselectAllNodes = new EventEmitter<any>();
  @Output() public onDetails = new EventEmitter<object>();
  @Output() public onReactionMouseDown = new EventEmitter<object>();
  @Output() public printReady = new EventEmitter<any>();
  @Output() public tabIndex = new EventEmitter<any>();
  @Output() public onProtectionInformation = new EventEmitter<object>();
  @Output() public onSimilarReactions = new EventEmitter<object>();
  @Output() public onSideReactions = new EventEmitter<object>();
  @Output() public onInit = new EventEmitter<any>();

  public sort: string = 'DISTANCE';
  public previousSortingFunction: string = 'DISTANCE';
  public direction: string = 'ascending';
  public prevDirection: string = 'ascending';
  public filter: ReactionListMode = ReactionListMode.GRAPH;
  public shortSmiles: string = '';
  public customSortingFunction: ScoringFunction;
  public parsingError: boolean = false;
  public thrownError: string;
  public errorTimeout: any;
  public reactionLoadingLimiter: number = 20;
  public sortingFunctions: ScoringFunction[] = [];
  public loadingMoreReactionsTimeout: any;
  public searchFormControl: UntypedFormControl;
  public isSearchReactionResult: boolean = false;
  public filteredReactions: GraphReactionNode[] = [];
  public filteredMoleculeReactionsList: GraphReactionNode[] = [];
  public filteredSelectionReactions: GraphReactionNode[] = [];
  public distanceSortingFunction: ScoringFunction;
  public isFromMoleculePopUp: boolean = false;
  public isAllPathwaysLoaded: boolean = false;
  public isReactionReportLoading: boolean = true;

  @ViewChildren('reactionItem') public reactionItems: QueryList<ReactionDetailsComponent>;
  // FIXME: This can be refactored after midend adds 'Distance to target' function to its db.
  public currentlySelectedSortingFunction: ScoringFunction = new ScoringFunction({
    id: 0,
    name: 'Distance to target',
    source_code: 'DISTANCE',
    owner: null,
    category: ScoringFunctionCategoryType.REACTION_ORDER,
  });
  public templateSortingFunction: ScoringFunction = new ScoringFunction({
    id: 0,
    name: '',
    source_code: '',
    owner: 0,
    category: ScoringFunctionCategoryType.REACTION_ORDER,
  });
  public isSortAndOrderBeDisabled: boolean = false;
  public isLoadMoreReactions: boolean;
  private sortingFunctionCalculatorSubscription: Subscription;
  private scoringFunctionsSubscription: Subscription;
  private sortingFunctionConfig: MatDialogConfig = {
    disableClose: false,
    width: '760px',
    height: '620px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: '',
    },
  };
  private interval: any;
  private unsubscribeSubject: Subject<void> = new Subject();

  constructor(
    public appConstantsService: AppConstantsService,
    public clipboardService: NodesClipboardService,
    public analysisResultsService: AnalysisResultsService,
    private calculatorService: CalculatorService,
    private scoringFunctionsService: ScoringFunctionsService,
    private infoService: InfoService,
    private analysisService: AnalysisService,
  ) {
    this.searchFormControl = new UntypedFormControl('', [Validators.required]);
    this.isLoadMoreReactions = false;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('targetMolecule')) {
      if (this.targetMolecule !== undefined) {
        this.searchFormControl.reset('');
        this.filter = ReactionListMode.GRAPH;
        this.pluckUnwantedFunctions();
        this.tabIndex.emit();
        this.sort = 'DISTANCE';
        this.cutSmiles();
        this.reactionLoadingLimiter = 20;
        this.sortingFunctionParser(this.sort);
        this.setIsSortAndOrderBeDisabled();
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
      }
    }
    if (changes.hasOwnProperty('selectedMolecule')) {
      this.searchFormControl.reset('');
      if (this.selectedMolecule !== undefined) {
        this.filter = ReactionListMode.MOLECULE;
        this.pluckUnwantedFunctions();
        this.sort = 'DISTANCE';
        this.direction = 'ascending';
        this.tabIndex.emit();
        this.cutSmiles();
        this.reactionLoadingLimiter = 20;
        this.sortingFunctionParser(this.sort);
        this.setIsSortAndOrderBeDisabled();
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
      }
    }
    if (changes.hasOwnProperty('markedNodesCount')) {
      this.searchFormControl.reset('');
      if (this.markedNodesCount > 0) {
        this.tabIndex.emit();
        this.cutSmiles();
        this.sort = 'DISTANCE';
        this.filter = ReactionListMode.SELECTION;
        this.pluckUnwantedFunctions();
        this.sortingFunctionParser(this.sort);
      } else if (this.markedNodesCount === 0 && this.filter === ReactionListMode.SELECTION) {
        this.filter = undefined;
      }
      this.reactionLoadingLimiter = 20;
      this.setIsSortAndOrderBeDisabled();
      this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
    }
    if (changes.hasOwnProperty('reactions')) {
      if (this.reactions && this.reactions.length > 0) {
        this.tabIndex.emit();
        this.filteredReactions = this.reactions;
        if (this.searchFormControl.value.length > 0) {
          this.searchReactionFilter(this.searchFormControl.value);
        }
        this.sortingFunctionParser(this.sort);
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
      }
      this.setIsSortAndOrderBeDisabled();
    }
    if (changes.hasOwnProperty('selectionReactions')) {
      if (this.selectionReactions && this.selectionReactions.length > 0) {
        this.filteredSelectionReactions = this.selectionReactions;
        this.setIsSortAndOrderBeDisabled();
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
      } else {
        this.searchFormControl.reset('');
      }
      this.sortingFunctionParser(this.sort);
    }
    if (changes.hasOwnProperty('moleculeReactionsList')) {
      if (this.moleculeReactionsList && this.moleculeReactionsList.length > 0) {
        this.analysisResultsService.isReactionReportFromMolecule.next(true);
        this.filter = ReactionListMode.MOLECULE;
        this.filteredMoleculeReactionsList = this.moleculeReactionsList;
        this.setIsSortAndOrderBeDisabled();
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
      } else {
        this.isFromMoleculePopUp = false;
        this.filteredMoleculeReactionsList = [];
        this.searchFormControl.reset('');
        if (!this.isInitialSelectedPathway(changes.moleculeReactionsList.firstChange)) {
          this.filter = ReactionListMode.GRAPH;
        }
        this.pluckUnwantedFunctions();
        this.setIsSortAndOrderBeDisabled();
      }
      this.sortingFunctionParser(this.sort);
    }
  }

  public ngOnInit() {
    this.scoringFunctionsService.sortingScoringFunctions
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((sortingFunctions: ScoringFunction[]) => {
        if (sortingFunctions && sortingFunctions.length > 0) {
          this.sortingFunctions = [...sortingFunctions];
          this.distanceSortingFunction = this.sortingFunctions.find((sortingFunction) => {
            return sortingFunction.source_code === 'DISTANCE';
          });
          this.pluckUnwantedFunctions();
        }
      }),
      this.analysisService.hasResult
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((hasResult) => {
          if (hasResult && (!this.reactions || this.reactions.length === 0)) {
            this.onInit.emit();
          }
        });
    this.searchReactions();
    this.analysisResultsService.isReactionReportFromMolecule
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((isFromMolecule) => {
        this.isFromMoleculePopUp = isFromMolecule;
      });
    this.analysisResultsService.isAllPathwaysLoaded
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((isAllPathwaysLoaded) => {
        this.isAllPathwaysLoaded = isAllPathwaysLoaded;
        this.isReactionReportLoading = isAllPathwaysLoaded ? false : true;
      });
  }

  public onReactionClick(reactionNode: GraphReactionNode, pointAllSameReactions: boolean = false) {
    reactionNode.pointed = !reactionNode.pointed;
    reactionNode.pointAllSameReactions = pointAllSameReactions;
    this.onReactionMouseDown.emit(reactionNode);
    if (this.isFromMoleculePopUp) {
      this.clipboardService.selectedReactionNodes.next([]);
    } else {
      const selectedNodes = this.clipboardService.selectedReactionNodes.value;
      const index = selectedNodes.findIndex((graphNode) => {
        return graphNode.reaction.id === reactionNode.reaction.id;
      });
      if (index === -1) {
        selectedNodes.push(reactionNode);
      } else {
        selectedNodes.splice(index, 1);
      }
      this.clipboardService.selectedReactionNodes.next(selectedNodes);
    }
  }

  public deselectAllReactions() {
    this.reactionItems.forEach((reactionItem: ReactionDetailsComponent) => {
      reactionItem.reaction.pointed = false;
    });
    this.deselectAllNodes.emit();
    this.clipboardService.selectedReactionNodes.next([]);
  }

  public setIsSelected(reactionNode: GraphReactionNode) {
    this.reactionItems.forEach((reactionItem: ReactionDetailsComponent) => {
      if (!reactionItem.reaction.pointed) {
        reactionItem.reaction.pointed =
          reactionItem.reaction.reaction.id === reactionNode.reaction.id;
      }
    });
  }

  public isAnyReactionSelected() {
    if (!!this.reactionItems) {
      return this.reactionItems.some((reactionItem: ReactionDetailsComponent) => {
        return reactionItem.reaction.pointed;
      });
    } else {
      return false;
    }
  }

  public sortByName(reaction: any) {
    return reaction.reaction.name;
  }

  public setIsSortAndOrderBeDisabled() {
    this.isSortAndOrderBeDisabled =
      this.filter === undefined ||
      (this.filter === ReactionListMode.GRAPH &&
        this.filteredReactions &&
        this.filteredReactions.length === 0) ||
      (this.filter === ReactionListMode.SELECTION &&
        this.filteredSelectionReactions &&
        this.filteredSelectionReactions.length === 0) ||
      (this.filter === ReactionListMode.MOLECULE &&
        this.filteredMoleculeReactionsList &&
        this.filteredMoleculeReactionsList.length === 0);
  }

  public searchReactions() {
    this.searchFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((searchValue: string) => {
        this.searchReactionFilter(searchValue);
      });
  }

  public onSortingSelectionChange(changeEvent: any) {
    if (changeEvent.value) {
      const findMatchingFunction = this.sortingFunctions.find((sortingFunction) => {
        return (
          sortingFunction.name === changeEvent.source.triggerValue &&
          sortingFunction.source_code === changeEvent.value
        );
      });
      if (findMatchingFunction) {
        this.previousSortingFunction = changeEvent.value;
        this.currentlySelectedSortingFunction = findMatchingFunction;
        this.sortingFunctionParser(changeEvent.value);
      } else {
        this.currentlySelectedSortingFunction = this.customSortingFunction;
        this.sortingFunctionParser(changeEvent.value);
      }
    }
  }

  public editSortingFunction(newSort?: boolean) {
    this.sortingFunctionConfig.data = {};
    this.sortingFunctionConfig.data.scoringFunction = newSort
      ? this.templateSortingFunction
      : this.currentlySelectedSortingFunction;
    this.sortingFunctionCalculatorSubscription = this.calculatorService
      .openScoringFunctionDialog(this.sortingFunctionConfig)
      .subscribe((calculatorResult) => {
        this.getSortingFunctions();
        if (calculatorResult) {
          this.currentlySelectedSortingFunction = calculatorResult.scoringFunction;
          if (calculatorResult.scoringFunction.id === 0) {
            this.customSortingFunction = calculatorResult.scoringFunction;
          }
          this.sort = calculatorResult.scoringFunction.source_code;
          this.currentlySelectedSortingFunction = calculatorResult.scoringFunction;
          this.sortingFunctionParser(this.currentlySelectedSortingFunction.source_code);
        }
      });
  }

  public sortByStereo(reaction: GraphReactionNode) {
    let subStereocenters: number = 0;
    let prodStereocenters: number = 0;
    const stereoRegex = /[^@]@[^@]|[^@]@@[^@]/g;
    for (let i = 0; i < reaction.substrateNodes.length; ++i) {
      const listOfMatches = reaction.substrateNodes[i].molecule.smiles.match(stereoRegex);
      if (listOfMatches !== null) {
        subStereocenters += listOfMatches.length;
      }
    }
    for (let e = 0; e < reaction.productNodes.length; ++e) {
      const listOfMatches = reaction.productNodes[e].molecule.smiles.match(stereoRegex);
      if (listOfMatches !== null) {
        prodStereocenters += listOfMatches.length;
      }
    }
    return prodStereocenters - subStereocenters;
  }

  public findRings(smiles: string) {
    const numbers = smiles.match(/\d+/g);
    if (numbers !== null) {
      let rings: number = numbers.length;
      let exceptionCount: number = 0;
      for (const ex of ReactionListComponent.exceptionsForRings) {
        let stringIndex: number = 0;
        while (stringIndex <= ex.exception.length) {
          if (smiles.indexOf(ex.exception, stringIndex) !== -1) {
            exceptionCount++;
            stringIndex += ex.exception.length;
          } else {
            break;
          }
        }
        if (exceptionCount > 0) {
          rings -= exceptionCount * ex.exceptionValue;
        }
      }
      return rings / 2;
    } else {
      return 0;
    }
  }

  /*tslint:disable:no-bitwise*/
  public sortByRings(reaction: GraphReactionNode) {
    let substrateRings: number = 0;
    let productRings: number = 0;
    for (let i = 0; i < reaction.substrateNodes.length; ++i) {
      substrateRings += this.findRings(reaction.substrateNodes[i].molecule.smiles);
    }
    for (let n = 0; n < reaction.productNodes.length; ++n) {
      productRings += this.findRings(reaction.productNodes[n].molecule.smiles);
    }
    return productRings - substrateRings;
  }
  /*tslint:enable:no-bitwise*/

  public sortByBuyable(reaction: GraphReactionNode) {
    let buyable: number = 0;
    for (const node of reaction.substrateNodes) {
      if (
        node.molecule.cost !== null &&
        node.molecule.mushroom !== null &&
        node.molecule.mushroom !== undefined
      ) {
        buyable++;
      }
    }
    return buyable;
  }

  public sortByUnknown(reaction: GraphReactionNode) {
    let unknown: number = 0;
    for (const node of reaction.substrateNodes) {
      if (node.molecule.cost === null && node.molecule.mushroom === null) {
        unknown++;
      }
    }
    return unknown;
  }

  public sortByKnown(reaction: GraphReactionNode) {
    let known: number = 0;
    for (const node of reaction.substrateNodes) {
      if (node.molecule.cost === null && node.molecule.mushroom !== null) {
        known++;
      }
    }
    return known;
  }

  public sortByMrel(reaction: GraphReactionNode) {
    if (reaction.substrateNodes.length > 1) {
      let mrel: number;
      const reactants: string[] = [];
      const tmpReactants: string[] = [];
      let heaviestReactantSmiles: string;
      let secondHeaviestReactantSmiles: string;
      for (let i = 0; i < reaction.substrateNodes.length; ++i) {
        reactants.push(reaction.substrateNodes[i].molecule.smiles);
      }
      heaviestReactantSmiles = this.getHeaviestAtom(reactants);

      for (let n = 0; n < reactants.length; ++n) {
        if (reactants[n] !== heaviestReactantSmiles) {
          tmpReactants.push(reactants[n]);
        }
      }

      secondHeaviestReactantSmiles = this.getHeaviestAtom(tmpReactants);

      mrel =
        2.3 -
        Math.log(
          this.getHeavyAtomsCount(heaviestReactantSmiles) /
            this.getHeavyAtomsCount(secondHeaviestReactantSmiles),
        );
      return mrel;
    } else {
      return 0;
    }
  }

  public sortBySynthons(reaction: GraphReactionNode) {
    return reaction.substrateNodes.length;
  }

  public sortByProducts(reaction: GraphReactionNode) {
    return reaction.productNodes.length;
  }

  public sortByWeird(reactionNode: GraphReactionNode) {
    const substratesSmiles: any = reactionNode.reaction.smiles;
    let isAnyMoleculeHeavy: boolean = true;
    let substrateMolWeight: number;
    let substrateAtoms: string[];
    let carbonHeteroatomRatio: number;
    let carbonCount: number;
    let substrateSmiles: string;
    for (const substrate of reactionNode.substrateNodes) {
      carbonCount = 0;
      if (
        substrate.molecule.mol_weight !== 0 &&
        substrate.molecule.mol_weight !== null &&
        substrate.molecule.mol_weight !== undefined
      ) {
        substrateMolWeight = substrate.molecule.mol_weight;
      } else {
        substrateSmiles = substrate.molecule.smiles;
        const atoms = this.getAtomsFromSmiles(substrateSmiles);
        for (const atom of atoms) {
          const atomIndex =
            ReactionListComponent.atomsMap.indexOf(atom) !== -1
              ? ReactionListComponent.atomsMap.indexOf(atom)
              : ReactionListComponent.aromaticAtomsMap.indexOf(atom);
          substrateMolWeight =
            ReactionListComponent.atomsMap.indexOf(atom) !== -1
              ? ReactionListComponent.atomicMasses[atomIndex]
              : ReactionListComponent.aromaticAtomMasses[atomIndex];
          if (substrateMolWeight < 100) {
            isAnyMoleculeHeavy = false;
            break;
          }
        }
      }
      substrateAtoms = this.getAtomsFromSmiles(substratesSmiles);
      for (const atom of substrateAtoms) {
        if (atom === 'C' || atom === 'c') {
          carbonCount++;
        }
      }
      carbonHeteroatomRatio = substrateAtoms.length / carbonCount;

      if ((isAnyMoleculeHeavy || carbonHeteroatomRatio > 1.5) && substrate.mushroom === null) {
        return -1;
      }
    }

    return 0;
  }

  public sortByMdiff(reaction: GraphReactionNode) {
    let synthonWeight: number;
    const retronWeight: number = this.getAtomsFromSmiles(reaction.productNodes[0].molecule.smiles)
      .length;
    for (const substrate of reaction.substrateNodes) {
      synthonWeight = this.getAtomsFromSmiles(substrate.molecule.smiles).length;
      if (synthonWeight > retronWeight) {
        return 1;
      }
    }
    return 0;
  }

  public sortByProtect(reaction: GraphReactionEntry) {
    if (reaction.isProtectionNeeded()) {
      return -Object.keys(reaction.substrates_with_unprotected_groups).length;
    } else {
      return 0;
    }
  }

  public sortingFunctionParser(sortingFunctionString: string) {
    let reactionList: GraphReactionNode[];
    switch (this.filter) {
      case ReactionListMode.GRAPH:
        reactionList = this.filteredReactions;
        break;
      case ReactionListMode.MOLECULE:
        reactionList = this.filteredMoleculeReactionsList;
        break;
      case ReactionListMode.SELECTION:
        reactionList = this.filteredSelectionReactions;
        break;
    }
    // FIND ALL OCCURRENCES OF EACH RECOGNIZED/ALLOWED FUNCTION AS SUBSTRING IN CUSTOM FUNCTION ->
    // -> ITERATE OVER EACH REACTION AND GET SCORE AS IF IT WAS BEING SORTED WITH JUST THIS FUNCTION ->
    // -> REPLACE SUBSTRING FOR THIS REACTION WITH SCORE (E.G. 'STEREO+RINGS', WHERE 'STEREO' = 10, 'RINGS' = 1
    // WILL YIELD '10+1' -> EVALUATE EXPRESSION AS TOTAL REACTION SCORE -> SORT REACTIONS BY TOTAL SCORE
    if (sortingFunctionString === 'NOSORTING') {
      reactionList.sort((a, b) => {
        if (this.direction === 'ascending') {
          return a.reaction.id.localeCompare(b.reaction.id);
        } else if (this.direction === 'descending') {
          return b.reaction.id.localeCompare(a.reaction.id);
        }
      });
    } else if (sortingFunctionString === 'DISTANCE') {
      reactionList.sort((a, b) => {
        if (this.direction === 'ascending') {
          return parseFloat(a.topologicalRank) - parseFloat(b.topologicalRank);
        } else if (this.direction === 'descending') {
          return parseFloat(b.topologicalRank) - parseFloat(a.topologicalRank);
        }
      });
    } else if (sortingFunctionString === 'NAME') {
      reactionList.sort((a, b) => {
        if (this.direction === 'ascending') {
          return (
            a.reaction['name'].localeCompare(b.reaction['name']) ||
            a.reaction['id'].localeCompare(b.reaction['id'])
          );
        } else if (this.direction === 'descending') {
          return (
            b.reaction['name'].localeCompare(a.reaction['name']) ||
            b.reaction['id'].localeCompare(a.reaction['id'])
          );
        }
      });
    } else if (sortingFunctionString === 'YEAR') {
      reactionList.sort((a, b) => {
        if (this.direction === 'ascending') {
          return a.reaction['publication_year'] - b.reaction['publication_year'];
        } else if (this.direction === 'descending') {
          return b.reaction['publication_year'] + a.reaction['publication_year'];
        }
      });
    } else if (sortingFunctionString === 'REACTANTS') {
      reactionList.sort((a, b) => {
        if (this.direction === 'ascending') {
          return (
            a.substrateNodes.length +
              a.productNodes.length -
              (b.substrateNodes.length + b.productNodes.length) ||
            a.reaction['id'].localeCompare(b.reaction['id'])
          );
        } else if (this.direction === 'descending') {
          return (
            b.substrateNodes.length +
              b.productNodes.length -
              (a.substrateNodes.length + a.productNodes.length) ||
            b.reaction['id'].localeCompare(a.reaction['id'])
          );
        }
      });
      switch (this.filter) {
        case ReactionListMode.GRAPH:
          this.filteredReactions = reactionList;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.MOLECULE:
          this.filteredMoleculeReactionsList = reactionList;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.SELECTION:
          this.filteredSelectionReactions = reactionList;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
      }
    } else {
      try {
        let totalReactionScore: number;
        for (const graphReactionNode of reactionList) {
          let tempSortFn = sortingFunctionString;
          if (tempSortFn.indexOf('[') !== -1 || tempSortFn.indexOf(']') !== -1) {
            throw new Error('Square brackets are not supported.');
          }
          if (tempSortFn.indexOf('STEREO') !== -1) {
            tempSortFn = tempSortFn
              .split('STEREO')
              .join(this.sortByStereo(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('RINGS') !== -1) {
            tempSortFn = tempSortFn
              .split('RINGS')
              .join(this.sortByRings(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('BUYABLE') !== -1) {
            tempSortFn = tempSortFn
              .split('BUYABLE')
              .join(this.sortByBuyable(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('UNKNOWN') !== -1) {
            tempSortFn = tempSortFn
              .split('UNKNOWN')
              .join(this.sortByKnown(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('KNOWN') !== -1) {
            tempSortFn = tempSortFn
              .split('KNOWN')
              .join(this.sortByKnown(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('MDIFF') !== -1) {
            tempSortFn = tempSortFn
              .split('MDIFF')
              .join(this.sortByMdiff(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('MREL') !== -1) {
            tempSortFn = tempSortFn
              .split('MREL')
              .join(this.sortByMrel(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('PRODUCTS') !== -1) {
            tempSortFn = tempSortFn
              .split('PRODUCTS')
              .join(this.sortByProducts(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('SYNTHONS') !== -1) {
            tempSortFn = tempSortFn
              .split('SYNTHONS')
              .join(this.sortBySynthons(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('WEIRD') !== -1) {
            tempSortFn = tempSortFn
              .split('WEIRD')
              .join(this.sortByWeird(graphReactionNode).toString());
          }
          if (tempSortFn.indexOf('PROTECT') !== -1) {
            tempSortFn = tempSortFn
              .split('PROTECT')
              .join(this.sortByProtect(graphReactionNode.reaction).toString());
          }
          if (tempSortFn.indexOf('sqrt') !== -1) {
            tempSortFn = tempSortFn.split('sqrt').join('Math.sqrt');
          }
          if (tempSortFn.indexOf('log') !== -1) {
            tempSortFn = tempSortFn.split('log').join('Math.log');
          }
          if (tempSortFn.indexOf('ln') !== -1) {
            tempSortFn = tempSortFn.split('ln').join('Math.log');
          }
          if (tempSortFn.indexOf('e') !== -1) {
            tempSortFn = tempSortFn.split('e').join('2.718281828');
          }

          const evaluate = math.evaluate;
          totalReactionScore = evaluate(tempSortFn);

          // IN CURRENT VERSION EACH SCORE GOING TO INFINITY WILL BE FIXED TO 0.
          // WARN USER IF THERE ARE ANY REACTIONS WHICH WOULD EVALUATE TO INFINITY.
          if (isFinite(totalReactionScore)) {
            graphReactionNode['totalSortingScore'] = totalReactionScore;
            graphReactionNode['notFiniteScore'] = null;
          } else {
            graphReactionNode['totalSortingScore'] = 0;
            graphReactionNode['notFiniteScore'] = true;
          }
        }
        reactionList.sort((a, b) => {
          if (this.direction === 'ascending') {
            return (
              parseFloat(a.totalSortingScore) - parseFloat(b.totalSortingScore) ||
              a.reaction['id'].localeCompare(b.reaction['id'])
            );
          } else if (this.direction === 'descending') {
            return (
              parseFloat(b.totalSortingScore) - parseFloat(a.totalSortingScore) ||
              b.reaction['id'].localeCompare(a.reaction['id'])
            );
          }
        });
        switch (this.filter) {
          case ReactionListMode.GRAPH:
            this.filteredReactions = reactionList;
            this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
            break;
          case ReactionListMode.MOLECULE:
            this.filteredMoleculeReactionsList = reactionList;
            this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
            break;
          case ReactionListMode.SELECTION:
            this.filteredSelectionReactions = reactionList;
            this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
            break;
        }
        this.parsingError = false;
      } catch (err) {
        this.parsingError = true;
        if (err.message.indexOf('Square') !== -1) {
          this.thrownError = err.message;
        } else {
          this.thrownError = 'The function you entered could not be understood.';
        }
        clearTimeout(this.errorTimeout);
        this.errorTimeout = setTimeout(() => {
          this.parsingError = false;
        }, 10000);
      }
    }
  }

  public changeFilter(_filter: ReactionListMode) {
    this.deselectAllReactions();
    this.sort = 'NOSORTING';
    this.direction = 'ascending';
    this.prevDirection = 'ascending';
    this.reactionLoadingLimiter = 20;
    this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
    if (_filter === ReactionListMode.GRAPH) {
      this.onInit.emit();
    }
    this.searchFormControl.reset('');
    this.pluckUnwantedFunctions();
  }

  public searchReactionFilter(searchValue: string) {
    if (searchValue) {
      let searchResultReactions = [];
      this.filteredReactions = this.reactions;
      this.filteredMoleculeReactionsList = this.moleculeReactionsList;
      this.filteredSelectionReactions = this.selectionReactions;
      searchResultReactions = this.getReactionSearchResults(searchValue);
      switch (this.filter) {
        case ReactionListMode.GRAPH:
          this.filteredReactions = searchResultReactions;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.MOLECULE:
          this.filteredMoleculeReactionsList = searchResultReactions;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.SELECTION:
          this.filteredSelectionReactions = searchResultReactions;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
      }
      this.isSearchReactionResult = !(searchResultReactions.length > 0);
    } else {
      switch (this.filter) {
        case ReactionListMode.GRAPH:
          this.filteredReactions = this.reactions;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.MOLECULE:
          this.filteredMoleculeReactionsList = this.moleculeReactionsList;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
        case ReactionListMode.SELECTION:
          this.filteredSelectionReactions = this.selectionReactions;
          this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();
          break;
      }
      this.isSearchReactionResult = false;
    }
    if (this.clipboardService.selectedReactionNodes.value.length > 0) {
      this.clipboardService.selectedReactionNodes.value.forEach((node: GraphReactionNode) => {
        this.setIsSelected(node);
      });
    } else {
      this.filteredReactions.forEach((reaction: GraphReactionNode) => {
        reaction.pointed = false;
      });
      this.filteredSelectionReactions.forEach((reaction: GraphReactionNode) => {
        reaction.pointed = false;
      });
    }
    this.setIsSortAndOrderBeDisabled();
  }

  public changeDirection(direction: string) {
    if (direction !== this.prevDirection) {
      switch (this.filter) {
        case ReactionListMode.GRAPH:
          this.filteredReactions.reverse();
          break;
        case ReactionListMode.MOLECULE:
          this.filteredMoleculeReactionsList.reverse();
          break;
        case ReactionListMode.SELECTION:
          this.filteredSelectionReactions.reverse();
          break;
      }
      this.prevDirection = direction;
      this.parsingError = false;
    }
  }

  public changeSortDirection() {
    this.prevDirection = this.direction;
    if (this.direction === 'ascending') {
      this.direction = 'descending';
    } else {
      this.direction = 'ascending';
    }
    this.changeDirection(this.direction);
  }

  public getSortOrderTooltip() {
    return this.direction === 'ascending'
      ? this.appConstantsService.sortAscendingTooltip
      : this.appConstantsService.sortDescendingTooltip;
  }

  public loadMoreReactions(visible: boolean) {
    if (!this.loadingMoreReactionsTimeout && visible) {
      this.loadingMoreReactionsTimeout = setTimeout(() => {
        this.reactionLoadingLimiter += 20;
        this.loadingMoreReactionsTimeout = undefined;
        this.isLoadMoreReactions = this.shouldShowLoadMoreReactions();

        this.clipboardService.selectedReactionNodes.value.forEach((node) => {
          this.setIsSelected(node);
        });
      }, 500);
    }
  }

  public shouldShowLoadMoreReactions() {
    switch (this.filter) {
      case ReactionListMode.GRAPH:
        return (
          this.filteredReactions && this.filteredReactions.length > this.reactionLoadingLimiter
        );
      case ReactionListMode.MOLECULE:
        return (
          this.filteredMoleculeReactionsList &&
          this.filteredMoleculeReactionsList.length > this.reactionLoadingLimiter
        );
      case ReactionListMode.SELECTION:
        return (
          this.filteredSelectionReactions &&
          this.filteredSelectionReactions.length > this.reactionLoadingLimiter
        );
      default:
        return false;
    }
  }

  public reactionId(idx: number, reaction): string {
    return JSON.stringify(reaction.reaction);
  }

  public cutSmiles() {
    if (this.targetMolecule !== undefined) {
      this.shortSmiles = this.targetMolecule.molecule.smiles.substring(0, 40);
      if (this.targetMolecule.molecule.smiles.length > this.shortSmiles.length) {
        this.shortSmiles += '...';
      }
    } else if (this.selectedMolecule) {
      this.shortSmiles = this.selectedMolecule.molecule.smiles.substring(0, 40);
      if (this.selectedMolecule.molecule.smiles.length > this.shortSmiles.length) {
        this.shortSmiles += '...';
      }
    } else {
      this.shortSmiles = '';
    }
  }

  public ngOnDestroy() {
    if (this.scoringFunctionsSubscription && !this.scoringFunctionsSubscription.closed) {
      this.scoringFunctionsSubscription.unsubscribe();
    }
    if (
      this.sortingFunctionCalculatorSubscription &&
      !this.sortingFunctionCalculatorSubscription.closed
    ) {
      this.sortingFunctionCalculatorSubscription.unsubscribe();
    }
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  private findAtom(symbol: string) {
    for (let i = 0; i < ReactionListComponent.atomsMap.length; ++i) {
      if (symbol === ReactionListComponent.atomsMap[i]) {
        return i;
      }
    }
    return null;
  }

  private findAromaticAtom(symbol: string) {
    for (let i = 0; i < ReactionListComponent.aromaticAtomsMap.length; ++i) {
      if (symbol === ReactionListComponent.aromaticAtomsMap[i]) {
        return i;
      }
    }
    return null;
  }

  private getOneNextAtom(smiles: string) {
    let acceptedSmiles: string = smiles;
    let consumed: number = 0;

    while (acceptedSmiles.length > 1 && !/[a-zA-Z]/.test(acceptedSmiles.charAt(0))) {
      acceptedSmiles = acceptedSmiles.substring(1);
      consumed++;
    }

    if (acceptedSmiles.length > 0) {
      const atomPair: any = {
        atom: undefined,
        consumed: undefined,
      };
      const atomKey1 =
        acceptedSmiles.length > 0 ? this.findAtom(acceptedSmiles.substring(0, 1)) : null;
      const atomKey2 =
        acceptedSmiles.length > 1 ? this.findAtom(acceptedSmiles.substring(0, 2)) : null;
      const atomKey3 =
        acceptedSmiles.length > 2 ? this.findAtom(acceptedSmiles.substring(0, 3)) : null;

      if (atomKey3 !== null) {
        atomPair.atom = acceptedSmiles.substring(0, 3);
        atomPair.consumed = consumed + 3;
        return atomPair;
      }
      if (atomKey2 !== null) {
        atomPair.atom = acceptedSmiles.substring(0, 2);
        atomPair.consumed = consumed + 2;
        return atomPair;
      }
      if (atomKey1 !== null) {
        atomPair.atom = acceptedSmiles.substring(0, 1);
        atomPair.consumed = consumed + 1;
        return atomPair;
      }

      const aromaticAtomKey1 =
        acceptedSmiles.length > 0 ? this.findAromaticAtom(acceptedSmiles.substring(0, 1)) : null;
      const aromaticAtomKey2 =
        acceptedSmiles.length > 1 ? this.findAromaticAtom(acceptedSmiles.substring(0, 2)) : null;

      if (aromaticAtomKey2 !== null) {
        atomPair.atom = acceptedSmiles.substring(0, 2);
        atomPair.consumed = consumed + 2;
        return atomPair;
      }
      if (aromaticAtomKey1 !== null) {
        atomPair.atom = acceptedSmiles.substring(0, 1);
        atomPair.consumed = consumed + 1;
        return atomPair;
      }
    }
    return null;
  }

  private getAtomsFromSmiles(_smiles: string) {
    let smiles = _smiles;
    const atoms: any[] = [];
    let nextAtomPair: any = this.getOneNextAtom(smiles);

    while (nextAtomPair !== null) {
      atoms.push(nextAtomPair.atom);
      smiles = smiles.substring(nextAtomPair.consumed);
      nextAtomPair = this.getOneNextAtom(smiles);
    }
    return atoms;
  }

  private getHeavyAtomsCount(smiles: string) {
    const atoms: any[] = this.getAtomsFromSmiles(smiles);
    let heavyAtomsCount: number = 0;

    for (let i = 0; i < atoms.length; ++i) {
      if (atoms[i] !== 'H') {
        heavyAtomsCount++;
      }
    }
    return heavyAtomsCount;
  }

  private getHeaviestAtom(smiles: any) {
    if (smiles.length > 0) {
      let heavyAtomsCount: number = this.getHeavyAtomsCount(smiles[0]);
      let heaviestSmiles = smiles[0];

      for (let i = 0; i < smiles.length; ++i) {
        const tmpHeavyAtomsCount: number = this.getHeavyAtomsCount(smiles[i]);
        if (tmpHeavyAtomsCount > heavyAtomsCount) {
          heavyAtomsCount = tmpHeavyAtomsCount;
          heaviestSmiles = smiles[i];
        }
      }
      return heaviestSmiles;
    }
    return null;
  }

  private getSortingFunctions() {
    this.scoringFunctionsSubscription = this.scoringFunctionsService
      .getAndStoreScoringFunctions()
      .subscribe(
        (_) => {},
        (error) => {
          this.infoService.showError('An error occurred while retrieving sorting functions', 3000);
        },
      );
  }

  private pluckUnwantedFunctions() {
    // because we do not want to sort by publication year in manual retro and name in network
    if (this.sortingFunctions.length) {
      if (this.analysis.isManualRetrosynthesis() || this.analysis.isAutomaticRetrosynthesis()) {
        const yearFunction = this.sortingFunctions.find((sortingFunction) => {
          if (!!sortingFunction.source_code) {
            return sortingFunction.source_code === 'YEAR';
          }
        });
        if (yearFunction) {
          const index = this.sortingFunctions.indexOf(yearFunction);
          if (index > -1) {
            this.sortingFunctions.splice(index, 1);
          }
        }
      }

      if (this.analysis.isReactionNetwork()) {
        const nameFunction = this.sortingFunctions.find((sortingFunction) => {
          if (!!sortingFunction.source_code) {
            return sortingFunction.source_code === 'NAME';
          }
        });
        if (nameFunction) {
          const index = this.sortingFunctions.indexOf(nameFunction);
          if (index > -1) {
            this.sortingFunctions.splice(index, 1);
          }
        }
      }

      const distanceOfTargetFunction = this.sortingFunctions.find((sortingFunction) => {
        if (!!sortingFunction.source_code) {
          return sortingFunction.source_code === 'DISTANCE';
        }
      });

      if (this.filter === ReactionListMode.GRAPH && !this.analysis.isAlgorithmWithoutPathways()) {
        if (distanceOfTargetFunction) {
          const index = this.sortingFunctions.indexOf(distanceOfTargetFunction);
          if (index > -1) {
            this.sortingFunctions.splice(index, 1);
          }
        }
      } else if (!distanceOfTargetFunction) {
        this.sortingFunctions.push(this.distanceSortingFunction);
      }
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  private getReactionSearchResults(searchValue: string): any[] {
    let reactionList: GraphReactionNode[] = [];
    const searchResultReactions = [];
    switch (this.filter) {
      case ReactionListMode.GRAPH:
        reactionList = this.filteredReactions;
        break;
      case ReactionListMode.MOLECULE:
        reactionList = this.filteredMoleculeReactionsList;
        break;
      case ReactionListMode.SELECTION:
        reactionList = this.filteredSelectionReactions;
        break;
    }

    reactionList.forEach((reactionNode) => {
      if (
        reactionNode.reaction.name.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
        reactionNode.reaction.smiles.toLowerCase().includes(searchValue.toLowerCase().trim())
      ) {
        searchResultReactions.push(reactionNode);
      }
    });

    return searchResultReactions;
  }

  private isInitialSelectedPathway(firstChange: boolean = false) {
    return (
      firstChange &&
      this.selectionReactions &&
      this.selectionReactions.length > 0 &&
      this.filter === ReactionListMode.SELECTION
    );
  }
}
