import { Observable, Subscription, fromEvent, timer, of, Subject, merge } from 'rxjs';
import { throttle, retryWhen, scan, takeUntil, mapTo, switchMap, delayWhen } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthorizationService } from './authorization';
import { MoleculeImageService } from './shared/components/molecule-image/molecule-image.service';
import {
  ErrorsHandlerService,
  FrontendStorageService,
  InfoService,
  ParsedConnectionError,
  AppConstantsService,
} from './shared/services';
import { environment } from '../environments/environment';
import { AppState } from './app-state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PendoAnalyticsService } from './shared/services/analytics/pendo-analytics.service';
import { MidendConfiguration } from './shared/services/models/midend-configuration';
import FontFaceObserver from 'fontfaceobserver';

@Component({
  selector: 'ch-app',
  /* tslint:disable-next-line:use-view-encapsulation */
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public authorizedUser: Observable<boolean>;
  public unauthorizedIFrame: boolean = false;
  public usedEnvironment = environment;
  public isBrowserAllowed: boolean = true;
  public isSynthiaLiteEnabled: boolean = APP_CONFIG.SYNTHIA_LITE_ENABLED;
  public logoText: string = this.appConstantsService.synthiaEnterpriseLogoText;

  private isOnline: Observable<boolean>;
  private subscriptions: Subscription;
  private eventListenerSubscription: Subscription;
  private authServiceSubscriptions: Subscription = new Subscription();
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private unsubscriberAllSubject: Subject<void> = new Subject<void>();

  constructor(
    private domSanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private authService: AuthorizationService,
    private frontendStorageService: FrontendStorageService,
    private errorsHandlerService: ErrorsHandlerService,
    private infoService: InfoService,
    private moleculeImageService: MoleculeImageService,
    private http: HttpClient,
    private appStateService: AppState,
    private deviceDetectorService: DeviceDetectorService,
    private appConstantsService: AppConstantsService,
    private pendoAnalyticsService: PendoAnalyticsService,
    private renderer: Renderer2,
  ) {
    this.checkBrowserVersion();
    this.unauthorizedIFrame = top !== self;

    if (this.usedEnvironment.production && !APP_CONFIG.TEST_MODE) {
      window.console.log = function () {}; // disable any console.log debugging statements in production mode
      window.console.error = function () {}; // disable any console.error statements in production mode
      window.console.warn = function () {}; // disable any console.warn statements in production mode
    }
    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    );

    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscriberAllSubject))
      .subscribe((midendConfiguration: MidendConfiguration) => {
        if (!!midendConfiguration && midendConfiguration.PENDO_ANALYTICS_ENABLED) {
          this.pendoAnalyticsService.initialize();
        }
      });
  }

  public checkBrowserVersion() {
    const usersBrowser: string = this.deviceDetectorService.browser;
    const usersBrowserVersion: number = parseInt(this.deviceDetectorService.browser_version, 10);
    const allowedBrowsers = APP_CONFIG.ALLOWED_BROWSERS;
    if (!!allowedBrowsers) {
      this.isBrowserAllowed = !!allowedBrowsers.find((browser) => {
        return usersBrowser === browser.name && usersBrowserVersion >= browser.minimumVersion;
      });
    }
  }

  public isUsingApplicationAllowed() {
    return !this.unauthorizedIFrame && this.isBrowserAllowed;
  }

  public networkStatus() {
    this.isOnline.pipe(takeUntil(this.unsubscriberAllSubject)).subscribe((value) => {
      if (!value) {
        this.infoService.showError(this.appConstantsService.offlineMessage, 0);
      }
    });
  }

  public ngOnInit() {
    this.renderer.addClass(document.body, 'material-icon-font-not-loaded');
    const matIconFont = new FontFaceObserver('Material Icons');
    matIconFont
      .load()
      .then(() => {
        this.renderer.removeClass(document.body, 'material-icon-font-not-loaded');
      })
      .catch(() => {
        this.renderer.addClass(document.body, 'material-icon-font-not-loaded');
        setTimeout(() => {
          if (matIconFont) {
            this.renderer.removeClass(document.body, 'material-icon-font-not-loaded');
          }
        }, 2000);
      });
    this.networkStatus();
    this.authorizedUser = this.authService.isAuthorized();
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.authorizedUser.subscribe((authorized) => {
        if (authorized) {
          this.subscribeToInputEvents();
          this.loadAfterAuthorized();
        } else {
          this.frontendStorageService.userSettings = undefined;
          this.unsubscribeFromEvents();
          this.unsubscriberSubject.next();
        }
      }),
    );

    this.authService.isComplianceStatementAccepted
      .pipe(takeUntil(this.unsubscriberAllSubject))
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.loadAfterAuthorized();
        }
      });

    this.authService.mustChangePasswordCompleted
      .pipe(takeUntil(this.unsubscriberAllSubject))
      .subscribe((isCompleted: boolean) => {
        if (isCompleted) {
          this.loadAfterAuthorized();
        }
      });

    // load custom icons to be used in app (we can add more icons here)
    // FIXME: Some of legacy custom icons will be removed after components cleanup
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'upload',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/upload.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/comment.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'rerun',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/rerun.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'share',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/share.svg'),
    );
    // Up to here
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'new-folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/create_new_folder_outline.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'save-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/save_outline.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/edit.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'benzene',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/new-benzene.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/folder.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'automatic-retro-home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/automatic-retro-home-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'manual-retro-home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/manual-retro-home-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/delete.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'batch-folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/batch-folder.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/refresh.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/calendar-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/home.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'select-all',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/select_all.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'deselect-all',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/deselect_all.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'expand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/expand.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'shrink',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/show_list.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'mol-set',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/mol-set.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'mol-set-ind',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/mol-set-ind.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'legend',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/legend.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'description-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/description-outline.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'visibility-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/visibility-outline.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'new-pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/new-pin.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'format-color-reset-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/img/format-color-reset-outline.svg',
      ),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'file-copy-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/file-copy-outline.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'thumb-up-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/thumb-up-alt.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'thumb-down-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/thumb-down-alt.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'horizontal-split',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/horizontal-split-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'print',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/print.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'edit-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/edit-settings.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'visualization-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/visualization-settings.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'lightbulb',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/lightbulb.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'delete-large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/delete_large.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'user-management',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/user-management.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'hex-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/hex-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'unlimited-plan-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/unlimited-plan-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'analyses-flask-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/analyses-flask.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'scroll-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/scroll-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'shop-now-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/shop-now-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'shipping-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/shipping-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'shopping-list-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/shopping-list-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'infinity-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/infinity-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/settings.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/person.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'settings-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/settings-active.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'report-problem',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/report-problem.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'user-interrupt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/user-interrupt.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'add-analysis-tag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/add-analysis-tag.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'shared-analysis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/shared-analysis.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'analysis-path-no',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/analysis-path-no.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'no-result-analysis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/no-result-analysis.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'molset-ind',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/molset-ind.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'automatic-retro-home-menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/automatic-retro-home-menu.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'file-copy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/file-copy.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'pub-chem',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/pub-chem.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'flask',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/flask.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'reaction',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/chemical-reaction.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'book100',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/book_100.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'book3star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/book_3star.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'book2star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/book_2star.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'book1star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/book_1star.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'known_molecule',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/known_molecule.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'protection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/protection.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'regulated',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/regulated.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'block_view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icon_building_blocks.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'path_view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icon_pathview.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icon_help.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'learning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/learning_center.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'structure_small',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/structure-small-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'structure_large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/structure-large-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'resize_pathway',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/resize.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'pathway_option',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/pathway-option-icon.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'new-analysis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/new-analysis.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'popularity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/popularity.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'post',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/post.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'emoji',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/emoji.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'emoji-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/emoji-active.svg'),
    );
    this.iconRegistry.addSvgIconInNamespace(
      'ch',
      'diversity_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/diversity_view_icon.svg'),
    );
    this.appStateService.getIpAddress().subscribe();
    this.appStateService.getMidendConfiguration();
    this.appStateService.getPaswordEncryptionConfiguration();
  }

  public ngOnDestroy() {
    if (this.authServiceSubscriptions && !this.authServiceSubscriptions.closed) {
      this.authServiceSubscriptions.unsubscribe();
    }
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
    this.unsubscriberAllSubject.next();
    this.unsubscriberAllSubject.complete();
  }

  private subscribeToInputEvents() {
    const events: string[] = ['keydown', 'keyup', 'mousedown', 'mouseup'];
    const allEventsObservable = merge(...events.map((eventType) => fromEvent(document, eventType)));
    this.eventListenerSubscription = allEventsObservable
      .pipe(
        throttle(() => timer(60000)),
        takeUntil(this.unsubscriberSubject),
      )
      .subscribe((eventFired) => {
        this.authServiceSubscriptions.add(
          this.authService.refreshJwtToken().subscribe(
            () => {},
            (error: ParsedConnectionError) => this.callJwtRefreshErrors(error),
          ),
        );
      });
  }

  private unsubscribeFromEvents() {
    if (this.eventListenerSubscription && !this.eventListenerSubscription.closed) {
      this.eventListenerSubscription.unsubscribe();
    }
  }

  private callJwtRefreshErrors(error: ParsedConnectionError) {
    if (
      (error.bodyJson &&
        error.bodyJson.non_field_errors &&
        error.bodyJson.non_field_errors[0].indexOf('has expired') !== -1) ||
      (error.error &&
        error.error.error &&
        error.error.error.token &&
        error.error.error.token.indexOf('may not be null'))
    ) {
      this.infoService.showInfo(error.promptMessage, 1000 * 60 * 60);
      this.errorsHandlerService.logout();
    } else if (error.shouldRedirect) {
      this.errorsHandlerService.logout();
    }
  }

  private loadAfterAuthorized() {
    this.frontendStorageService
      .getStorage()
      .pipe(
        takeUntil(this.unsubscriberSubject),
        switchMap((storage) => {
          if (!!storage) {
            return of(storage);
          } else {
            return this.frontendStorageService.formNewStorage();
          }
        }),
      )
      .subscribe((newStorage: any) => {
        this.frontendStorageService.makeStorageLocal(newStorage);
        this.frontendStorageService.loadingFrontendStorage.next(false);
      });
  }
}
