import {
  trigger,
  style,
  stagger,
  transition,
  animate,
  keyframes,
  query,
} from '@angular/animations';

// Animation can be used for animating *ngFor elements one after another. You must give each
// element class 'animate-stagger' in order for it to work.
// Note: Due to their triggers having same name, you are only able to use one of those in each component.
//       Changing trigger names will require updating their triggers in component templates.
// NOTE: Although it is tempting to define an animation builder instead of repeated definitions with the delay
//       provided as parameter (aka build(delay) => trigger(...delay...) this won't work with the AOT compiler
//       due to "Function expressions are not supported in decorators" error.
export const LIST_STAGGERED_ANIMATION_65 = trigger('listStaggeredAnimation', [
  transition('void => *', [
    query('.animate-stagger', style({ opacity: 0 }), { optional: true }),
    query(
      '.animate-stagger',
      stagger(`65ms`, [
        animate(
          '0.18s ease-in',
          keyframes([
            style({ opacity: 0 }),
            style({ opacity: 0 }),
            style({ opacity: 0.3 }),
            style({ opacity: 0.67 }),
            style({ opacity: 1 }),
          ]),
        ),
      ]),
      { optional: true },
    ),
  ]),
]);
