import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SimilarReactionsEntry } from './models/similar-reactions-entry';
import { SideReactionsEntry } from './models/side-reactions-entry';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { ComputationState } from '../app-constants/app-constants.service';
import { FixedQueryEncoderHelper } from '../../fixed-httpparams';

export const backendEntryPointReactionSimilarity =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/reaction-similarity-search/';
export const backendEntryPointReactionSide =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/side-reactions/search/';

export enum RelatedReactionsRequestParameters {
  SMILES = 'smiles',
  REACTION = 'reaction',
  MAX_REACTIONS = 'max_reactions',
  RXID = 'rxid',
}

@Injectable()
export class RelatedReactionListService {
  constructor(private http: HttpClient) {}

  public getSimilarReactions(reactionSmiles: string, maxReactions: number, rxid?: number) {
    let params: HttpParams = new HttpParams({ encoder: new FixedQueryEncoderHelper() }).append(
      RelatedReactionsRequestParameters.SMILES,
      reactionSmiles,
    );
    params = params.append(
      RelatedReactionsRequestParameters.MAX_REACTIONS,
      maxReactions.toString(),
    );
    if (rxid) {
      params = params.append(RelatedReactionsRequestParameters.RXID, rxid.toString());
    }

    return this.http.get(backendEntryPointReactionSimilarity, { params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((similarReaction) => new SimilarReactionsEntry(similarReaction));
        } catch (error) {
          throw new Error('Unexpected format of response.');
        }
      }),
    );
  }

  public getSideReactions(reactionId: string, maxReactions: number) {
    const params = {};
    params[RelatedReactionsRequestParameters.REACTION] = reactionId;
    params[RelatedReactionsRequestParameters.MAX_REACTIONS] = maxReactions;

    return this.http.get(backendEntryPointReactionSide, { params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.body.status !== ComputationState.SUCCESS) {
          throw new Error('Task has not been yet completed');
        }
        try {
          const responseJson = response.body.result.side_reactions;
          return responseJson.map((sideReaction) => new SideReactionsEntry(sideReaction));
        } catch (error) {
          throw new Error('Unexpected format of response.');
        }
      }),
    );
  }
}
