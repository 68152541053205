import { DecimalPipe } from '@angular/common';
import { AppConstantsService } from 'src/app/shared/services/app-constants/app-constants.service';
import { ResultsFilterModels } from 'src/app/shared/components/results-filter/results-filter-base/results-filter-models';
import { GraphNodeType, GraphReactionNode } from './../../../services/graph-builder';
import { delay, takeUntil, filter, debounceTime } from 'rxjs/operators';
import { AnalysisResultsService } from './../../../services';
import { Component, OnInit, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { GraphMoleculeNode } from 'src/app/shared/services/graph-builder';
import { Subject } from 'rxjs';
import { checkInputForNumbers } from '../../utils';

export interface ISliderRange {
  min: number;
  max: number;
}

export enum SliderType {
  PRICE = 'price',
  REACTION = 'reaction',
  PROTECTION_GROUPS = 'protectionGroups',
}

export enum RangeLineParams {
  MAX = 100,
  MIN = 1,
  LEFT_POSITION_START = 1,
  MAX_WIDTH = 95,
  MIN_WIDTH = 5,
}

@Component({
  selector: 'ch-results-filter-slider',
  templateUrl: './results-filter-slider.component.html',
  styleUrls: ['./results-filter-slider.component.scss'],
})
export class ResultsFilterSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() public addPriceFilter: EventEmitter<ISliderRange | null> = new EventEmitter();
  @Output()
  public addNumberOfReactionFilter: EventEmitter<ISliderRange | null> = new EventEmitter();
  @Output()
  public addProtectiveStepsFilter: EventEmitter<ISliderRange | null> = new EventEmitter();
  @Output()
  public pathwaySimilarityFilter: EventEmitter<number> = new EventEmitter();

  public isSliderFilterSection: boolean = true;
  public pathwaySimilarityInfo: string = this.appConstantService.pathwaySimilarityInfo;
  public pathwaySimilarity: number = 100;
  public priceMin: number = 0;
  public priceMax: number = 0;
  public reactionMax: number = 0;
  public reactionMin: number = 0;
  public isPriceFilter: boolean = false;
  public priceFilterMinValue: string = '';
  public priceFilterMaxValue: string = '';
  public priceFilterValue: ISliderRange = { min: this.priceMin, max: this.priceMax };
  public priceMinInput: Subject<string> = new Subject<string>();
  public priceMaxInput: Subject<string> = new Subject<string>();
  public inputPriceRangeTooltip: string = '';
  public protectionMax: number = 0;
  public protectionMin: number = 0;
  public protectionGroups: ISliderRange = { min: this.protectionMin, max: this.protectionMax };
  public numberOfReactionValue: ISliderRange = { min: this.reactionMin, max: this.reactionMax };
  public dynamicRangePrice: ISliderRange = { min: this.priceMin, max: this.priceMax };
  public dynamicRangeNumberOfReactions: ISliderRange = {
    min: this.reactionMin,
    max: this.reactionMax,
  };
  public dynamicRangeProtectionGroups: ISliderRange = {
    min: this.protectionMin,
    max: this.protectionMax,
  };
  public filteredPathList: any;
  public priceFilterDisabled: boolean = false;
  public reactionFilterDisabled: boolean = false;
  public protectionGroupsFilterDisabled: boolean = false;
  public readonly sliderType = SliderType;

  public unSubscriberSubject: Subject<void> = new Subject();
  private isFilterPathLoaded: boolean;
  private filtersFromUserSettings: boolean;
  public priceRangeWidth: number = 95;
  public priceRangeMinPos: number = 1;

  constructor(
    private analysisResultsService: AnalysisResultsService,
    private appConstantService: AppConstantsService,
    private decimal: DecimalPipe,
  ) {}
  public ngAfterViewInit(): void {
    this.showPriceDynamicLine();
    this.priceMinInput
      .pipe(debounceTime(2000), takeUntil(this.unSubscriberSubject))
      .subscribe((value: string) => {
        const formattedValue = this.numberWithComma(value);
        if (!formattedValue) {
          this.priceFilterMinValue = null;
          this.priceFilterMinValue = this.decimal.transform(String(this.priceMin), '1.2-2');
        } else {
          this.priceFilterMinValue = formattedValue;
          const formattedNumberValue = Number(formattedValue.replace(/,/g, ''));
          this.priceFilterValue.min = formattedNumberValue;
          this.applyPriceFilter();
        }
      });
    this.priceMaxInput
      .pipe(debounceTime(2000), takeUntil(this.unSubscriberSubject))
      .subscribe((value: string) => {
        const formattedValue = this.numberWithComma(value);
        if (!formattedValue) {
          this.priceFilterMaxValue = null;
          this.priceFilterMaxValue = this.decimal.transform(String(this.priceMax), '1.2-2');
        } else {
          this.priceFilterMaxValue = formattedValue;
          const formattedNumberValue = Number(formattedValue.replace(/,/g, ''));
          this.priceFilterValue.max = formattedNumberValue;
          this.applyPriceFilter();
        }
      });
  }

  public ngOnDestroy(): void {
    this.unSubscriberSubject.next();
    this.unSubscriberSubject.complete();
  }

  public ngOnInit() {
    this.filtersFromUserSettings = false;
    this.isFilterPathLoaded = false;

    this.analysisResultsService.filteredPathList
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((pathList: any[]) => {
        this.filteredPathList = pathList;
        this.getDynamicRange();
      });
    this.analysisResultsService.consolidatedFilteredPathList
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((pathList: any[]) => {
        this.isSliderFilterSection = true;
        if (pathList.length > 0) {
          this.getPriceMinMax(pathList);
          this.getMaxMinNumberOfReactions(pathList);
          this.getMaxMinProtectiveSteps(pathList);
          this.isFilterPathLoaded = true;
        }
      });

    this.analysisResultsService.resetFilterSubject
      .pipe(delay(0), takeUntil(this.unSubscriberSubject))
      .subscribe((value: boolean) => {
        if (value) {
          this.isSliderFilterSection = false;
          this.addPriceFilter.emit(null);
          this.addNumberOfReactionFilter.emit(null);
          this.addProtectiveStepsFilter.emit(null);
          this.pathwaySimilarity = 100;
          const pathList = this.analysisResultsService.consolidatedFilteredPathList.value;
          if (pathList) {
            this.getPriceMinMax(pathList);
            this.getMaxMinNumberOfReactions(pathList);
            this.getMaxMinProtectiveSteps(pathList);
          }
        }
      });

    this.analysisResultsService.resultsFilterSubject
      .pipe(
        takeUntil(this.unSubscriberSubject),
        filter((value: any) => {
          this.isSliderFilterSection = true;
          if (
            value &&
            (value.priceFilter ||
              value.numberOfReactionSliderFilter ||
              value.numberOfProtectiveSteps ||
              value.pathwaySimilarity) &&
            !this.filtersFromUserSettings
          ) {
            return value;
          }
        }),
      )
      .subscribe((value: ResultsFilterModels) => {
        if (value) {
          this.filtersFromUserSettings = true;
          if (value.priceFilter && value.priceFilter.min && value.priceFilter.max) {
            this.priceFilterValue = value.priceFilter;
            this.priceFilterMinValue = this.decimal.transform(
              String(this.priceFilterValue.min),
              '1.2-2',
            );
            this.priceFilterMaxValue = this.decimal.transform(
              String(this.priceFilterValue.max),
              '1.2-2',
            );
          }
          this.numberOfReactionValue = value.numberOfReactionSliderFilter;
          this.protectionGroups = value.numberOfProtectiveSteps;
          this.pathwaySimilarity = value.pathwaySimilarity;
        }
      });
  }

  public getDynamicRange() {
    if (this.filteredPathList && this.filteredPathList.length > 0) {
      this.dynamicRangePrice = this.populateMaxMinPrice(this.filteredPathList);
      this.dynamicRangeNumberOfReactions = this.populateMaxMinReactions(this.filteredPathList);
      this.dynamicRangeProtectionGroups = this.populateMaxMinProtection(this.filteredPathList);
    } else {
      this.dynamicRangePrice = { min: 0, max: 0 };
      this.dynamicRangeNumberOfReactions = { min: 0, max: 0 };
      this.dynamicRangeProtectionGroups = { min: 0, max: 0 };
    }
    this.showPriceDynamicLine();
    let tooltip = this.appConstantService.replaceVariableFromMessage(
      this.appConstantService.filteredPriceRangeTooltip,
      '<min>',
      this.dynamicRangePrice.min,
    );
    tooltip = this.appConstantService.replaceVariableFromMessage(
      tooltip,
      '<max>',
      this.dynamicRangePrice.max,
    );
    this.inputPriceRangeTooltip = tooltip;
  }

  public priceMinValueChange(value: string) {
    if (value) {
      this.priceMinInput.next(value);
    }
  }

  public priceMaxValueChange(value: string) {
    if (value) {
      this.priceMaxInput.next(value);
    }
  }

  public removeCommaMinValue() {
    this.priceFilterMinValue = this.priceFilterMinValue.replace(/,/g, '');
  }
  public removeCommaMaxValue() {
    this.priceFilterMaxValue = this.priceFilterMaxValue.replace(/,/g, '');
  }

  public priceInputEvent(event: KeyboardEvent) {
    if (!checkInputForNumbers(event)) {
      event.preventDefault();
    }
  }

  public pathwaysSimilarityChange() {
    this.pathwaySimilarityFilter.emit(this.pathwaySimilarity);
  }

  public applyPriceFilter() {
    if (
      this.priceFilterValue.min <= this.priceFilterValue.max &&
      this.priceFilterValue.min >= this.priceMin &&
      this.priceFilterValue.max <= this.priceMax
    ) {
      this.addPriceFilter.emit(this.priceFilterValue);
    } else if (this.priceFilterValue.min < this.priceMin) {
      this.priceFilterMinValue = null;
      this.priceFilterValue.min = this.priceMin;
      this.priceFilterMinValue = this.decimal.transform(String(this.priceMin), '1.2-2');
      this.addPriceFilter.emit(this.priceFilterValue);
    } else if (
      this.priceFilterValue.max > this.priceMax ||
      this.priceFilterValue.max < this.priceMin
    ) {
      this.priceFilterMaxValue = null;
      this.priceFilterValue.max = this.priceMax;
      this.priceFilterMaxValue = this.decimal.transform(String(this.priceMax), '1.2-2');
      this.addPriceFilter.emit(this.priceFilterValue);
    } else if (this.priceFilterValue.min > this.priceFilterValue.max) {
      this.priceFilterMinValue = null;
      this.priceFilterValue.min = this.priceMin;
      this.priceFilterMinValue = this.decimal.transform(String(this.priceMin), '1.2-2');
      this.addPriceFilter.emit(this.priceFilterValue);
    }
  }

  public applyNumberOfReactionsFilter() {
    this.addNumberOfReactionFilter.emit(this.numberOfReactionValue);
  }

  public applyProtectiveStepsFilter() {
    this.addProtectiveStepsFilter.emit(this.protectionGroups);
  }

  public isSliderDisabled(type: SliderType, isDisabled: boolean) {
    switch (type) {
      case SliderType.PRICE:
        this.priceFilterDisabled = isDisabled;
        break;
      case SliderType.REACTION:
        this.reactionFilterDisabled = isDisabled;
        break;
      case SliderType.PROTECTION_GROUPS:
        this.protectionGroupsFilterDisabled = isDisabled;
        break;
    }
  }

  private getPriceMinMax(filteredPathList: any[]) {
    const maxMinRange: ISliderRange = this.populateMaxMinPrice(filteredPathList);
    this.priceMax = maxMinRange.max;
    this.priceMin = maxMinRange.min;
    this.priceFilterValue = { min: this.priceMin, max: this.priceMax };
    this.priceFilterMinValue = this.decimal.transform(String(this.priceFilterValue.min), '1.2-2');
    this.priceFilterMaxValue = this.decimal.transform(String(this.priceFilterValue.max), '1.2-2');
  }

  private getMaxMinNumberOfReactions(filteredPathList: any[]) {
    const maxMinRange: ISliderRange = this.populateMaxMinReactions(filteredPathList);
    this.reactionMax = maxMinRange.max;
    this.reactionMin = maxMinRange.min === maxMinRange.max ? 1 : maxMinRange.min;
    this.numberOfReactionValue = { min: this.reactionMin, max: this.reactionMax };
  }

  private getMaxMinProtectiveSteps(filteredPathList: any[]) {
    const maxMinRange: ISliderRange = this.populateMaxMinProtection(filteredPathList);
    this.protectionMax = maxMinRange.max;
    this.protectionMin = maxMinRange.min === maxMinRange.max ? 0 : maxMinRange.min;
    this.protectionGroups = { min: this.protectionMin, max: this.protectionMax };
  }

  private populateMaxMinPrice(filteredPathList: any[]) {
    let min = 99999;
    let max = 0;
    for (const path of filteredPathList) {
      let pathMolecules: GraphMoleculeNode[] = path.nodes.filter(
        (pathNode: GraphMoleculeNode) =>
          (pathNode.type === GraphNodeType.MOLECULE || pathNode.type === GraphNodeType.STRUCTURE) &&
          !pathNode.childReactionNodeId,
      );
      pathMolecules = pathMolecules.filter(
        (pathNode: GraphMoleculeNode) =>
          pathNode.molecule.cost !== null &&
          pathNode.molecule.cost !== 0 &&
          pathNode.molecule.mushroom !== null,
      );
      pathMolecules.sort((a, b) => a.molecule.cost - b.molecule.cost);
      if (pathMolecules.length > 0) {
        if (pathMolecules.length === 1) {
          if (pathMolecules[0].molecule.cost && pathMolecules[0].molecule.cost <= min) {
            min = pathMolecules[0].molecule.cost;
          }
          if (pathMolecules[0].molecule.cost && pathMolecules[0].molecule.cost >= max) {
            max = pathMolecules[0].molecule.cost;
          }
        } else {
          const pathMoleculesLength = pathMolecules.length;
          if (pathMolecules[0].molecule.cost && pathMolecules[0].molecule.cost <= min) {
            min = pathMolecules[0].molecule.cost;
          }
          if (
            pathMolecules[pathMoleculesLength - 1].molecule.cost &&
            pathMolecules[pathMoleculesLength - 1].molecule.cost >= max
          ) {
            max = pathMolecules[pathMoleculesLength - 1].molecule.cost;
          }
        }
      }
    }
    if (min <= 0.01) {
      min = 0.01;
    } else if (min === 99999) {
      min = 0;
    }
    min = Number(this.decimal.transform(min, '1.2-2').replace(/,/g, ''));
    max = Number(this.decimal.transform(max, '1.2-2').replace(/,/g, ''));

    if (max <= 0) {
      max = min;
    }
    const maxMinRange: ISliderRange = { min, max };
    return maxMinRange;
  }

  private populateMaxMinReactions(filteredPathList: any[]) {
    let min = 0;
    let max = 0;
    const firstPath = filteredPathList[0];
    const firstPathReactions: GraphReactionNode[] = firstPath.nodes.filter(
      (pathNode: GraphReactionNode) => pathNode.type === GraphNodeType.REACTION,
    );
    min = firstPathReactions.length;
    max = firstPathReactions.length;
    for (const path of filteredPathList) {
      const pathReactions: GraphReactionNode[] = path.nodes.filter(
        (pathNode: GraphReactionNode) => pathNode.type === GraphNodeType.REACTION,
      );
      if (min > pathReactions.length) {
        min = pathReactions.length;
      } else if (pathReactions.length > max) {
        max = pathReactions.length;
      }
    }
    const maxMinRange: ISliderRange = { min, max };
    return maxMinRange;
  }
  private populateMaxMinProtection(filteredPathList: any) {
    let min = 0;
    let max = 0;
    const firstPath = filteredPathList[0];
    min = max = this.getNumberOfProtectiveSteps(firstPath);
    for (const path of filteredPathList) {
      const protectiveStepsLength = this.getNumberOfProtectiveSteps(path);
      if (min > protectiveStepsLength) {
        min = protectiveStepsLength;
      } else if (protectiveStepsLength > max) {
        max = protectiveStepsLength;
      }
    }
    const maxMinRange: ISliderRange = { min, max };
    return maxMinRange;
  }

  private getNumberOfProtectiveSteps(path: any) {
    return path.nodes.filter((node) => node.type !== GraphNodeType.REACTION && node.needsProtection)
      .length;
  }

  private numberWithComma(value: string) {
    if (value) {
      try {
        const parts: string[] = value.toString().split('.');
        if (parts.length === 2) {
          parts[0] = parts[0] ? this.decimal.transform(parts[0].replace(/,/g, '')) : '0';

          if (!parts[1] || isNaN(Number(parts[1]))) {
            return parts[0];
          }
          if (parts[1].length === 1) {
            parts[1] += '0';
            return parts.join('.');
          }
          if (parts[1].length > 2) {
            parts[1] = parts[1].substring(0, 2);
            return parts.join('.');
          }
          return parts.join('.');
        }
        if (parts.length === 1) {
          parts[0] = this.decimal.transform(parts[0].replace(/,/g, ''), '1.2-2');
          return parts[0];
        } else {
          return '';
        }
      } catch (error) {
        return '';
      }
    }
  }

  private showPriceDynamicLine() {
    if (this.priceMax !== 0 && this.priceMin !== 0) {
      const diff = this.priceMax - this.priceMin;
      const percentageMin = ((this.dynamicRangePrice.min - this.priceMin) / diff) * 100;
      const percentageMax = ((this.dynamicRangePrice.max - this.priceMin) / diff) * 100;
      this.priceRangeWidth = percentageMax - percentageMin;
      percentageMin < RangeLineParams.MIN
        ? (this.priceRangeMinPos = RangeLineParams.LEFT_POSITION_START)
        : (this.priceRangeMinPos = percentageMin);
      if (this.priceRangeWidth >= RangeLineParams.MAX) {
        this.priceRangeWidth = RangeLineParams.MAX_WIDTH;
        this.priceRangeMinPos = RangeLineParams.LEFT_POSITION_START;
      }
      if (this.dynamicRangePrice.min === this.dynamicRangePrice.max) {
        this.priceRangeWidth = RangeLineParams.MIN_WIDTH;
        this.priceRangeMinPos = RangeLineParams.LEFT_POSITION_START;
      }
    }
  }
}
