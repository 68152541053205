import { timer, of, from, Observable, Subscription, BehaviorSubject, Subscriber } from 'rxjs';
import { switchMap, mergeMap, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { getMarvin, getDefaultServices } from '../../../../vendor/marvinjs_bridge';
import { MoleculeSearchService } from '../../services/molecule-search/molecule-search.service';

export enum MoleculeImageZoomMode {
  FIT = 'fit',
  AUTOSHRINK = 'autoshrink',
}

export enum ImageCreatorType {
  MARVIN = 'marvinJsImageExporter',
  WEBSERVICES = 'webservicesMolExport',
}

const CleaningCycle = 10 * 60 * 1000;
const ExpirationTime = 10 * 60 * 1000;

@Injectable()
export class MoleculeImageService {
  public static readonly marvinIframeId = 'molecule-renderer';

  private marvinLoadState: boolean = false;
  private marvinObservable: Observable<any>;
  private exporters: any = {};
  private imagesCache: {
    [key: string]: {
      expirationTime: number;
      image: any;
    };
  } = {};
  private cleaningTimer: Subscription;

  constructor(public moleculeSearchService: MoleculeSearchService) {
    this.activateMoleculeCacheCleaning();
  }

  public render(
    formula: string,
    inputFormat: string,
    format: string,
    width: number,
    height: number,
    zoomMode: MoleculeImageZoomMode,
    imageCreator: ImageCreatorType = ImageCreatorType.WEBSERVICES,
  ): Observable<any> {
    const formulaKey: string = this.createMoleculeCacheKey(
      formula,
      format,
      width,
      height,
      imageCreator,
    );
    if (this.cacheHasKey(formulaKey)) {
      this.postponeExpirationTime(formulaKey);
      return of(this.imagesCache[formulaKey].image);
    } else {
      return Observable.create((observer: Subscriber<any>) => {
        let obs: Observable<any>;
        obs = this.moleculeSearchService.structureToSvg(formula, inputFormat, width, height);

        obs.pipe(finalize(() => observer.complete())).subscribe(
          (image: any) => {
            this.addImageToCache(formulaKey, image);
            observer.next(image);
          },
          (error) => {
            observer.error(error);
          },
        );
      });
    }
  }

  private addImageToCache(formulaKey: string, image: string) {
    const expirationTime = Date.now() + ExpirationTime;
    this.imagesCache[formulaKey] = { expirationTime, image };
  }

  private getOrCreateExporter(
    marvin: any,
    inputFormat: string,
    format: string,
    width: number,
    height: number,
    zoomMode: MoleculeImageZoomMode,
  ) {
    const exporterKey: string = `[${inputFormat}][${format}][${width}][${height}][${zoomMode}]`;
    if (this.exporters[exporterKey]) {
      return this.exporters[exporterKey];
    } else {
      const exporter = this.createExporter(marvin, inputFormat, format, width, height, zoomMode);
      this.exporters[exporterKey] = exporter;
      return exporter;
    }
  }

  public getMolFile(formula: string = '') {
    return this.moleculeSearchService.smilesToMolWithRdkit(formula);
  }

  public createExporter(
    marvin: any,
    inputFormat: string,
    format: string,
    width: number,
    height: number,
    zoomMode: MoleculeImageZoomMode,
  ): any {
    const nodeSettings = {
      carbonLabelVisible: false,
      cpkColoring: true,
      chiralFlagVisible: true,
      atomIndicesVisible: false,
      implicitHydrogen: 'TERMINAL_AND_HETERO',
      displayMode: 'WIREFRAME',
      'background-color': '#ffffff',
      zoomMode: zoomMode,
      width: width,
      height: height,
    };

    const params = {
      imageType: 'image/' + format,
      settings: nodeSettings,
      inputFormat: inputFormat,
      services: getDefaultServices(MoleculeImageService.marvinIframeId),
    };
    return new marvin.ImageExporter(params);
  }

  private createMoleculeCacheKey(
    formula: string,
    format: string,
    width: number,
    height: number,
    imageCreator: ImageCreatorType,
  ): string {
    // Remove spaces to decrease formula length
    const trimmedFormula = formula.replace(/\s+/g, '');
    return `[${trimmedFormula}][${format}][${width}][${height}][${imageCreator}]`;
  }

  private cacheHasKey(key: string): boolean {
    return !!this.imagesCache[key];
  }

  private activateMoleculeCacheCleaning() {
    if (!this.cleaningTimer || this.cleaningTimer.closed) {
      const cleaningPeriod: number = CleaningCycle;
      this.cleaningTimer = timer(cleaningPeriod, cleaningPeriod).subscribe(() =>
        this.cleanMoleculeCache(),
      );
    }
  }

  private postponeExpirationTime(formula: string) {
    this.imagesCache[formula].expirationTime = Date.now() + ExpirationTime;
  }

  private cleanMoleculeCache() {
    const now: number = Date.now();
    let released: number = 0;

    Object.keys(this.imagesCache)
      .filter((key) => this.imagesCache[key].expirationTime < now)
      .forEach((expiredKey) => {
        delete this.imagesCache[expiredKey];
        released++;
      });

    const itemsLeft: number = Object.keys(this.imagesCache).length;

    console.log(`image cache cleanup: ${itemsLeft} left / ${released} released`);
  }
}
