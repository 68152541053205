import { Subject } from 'rxjs';
import {
  Component,
  Input,
  Output,
  QueryList,
  ViewChildren,
  EventEmitter,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  NodesClipboardService,
  MoleculeSetsService,
  GraphModeType,
  ParsedConnectionError,
  AnalysisAlgorithm,
  AnalysisResultsService,
  SidePanelViewType,
  AnalysisService,
  ResultsView,
} from '../../services';
import { InfoService } from '../../services/info.service';
import { takeUntil } from 'rxjs/operators';
import { GraphNodeType, GraphMoleculeNode, GraphReactionNode } from '../../services/graph-builder';
import { NewClipboardItemComponent } from './new-clipboard-item/new-clipboard-item.component';

@Component({
  selector: 'ch-nodes-clipboard',
  styleUrls: ['./nodes-clipboard.component.scss'],
  templateUrl: './nodes-clipboard.component.html',
})
export class NodesClipboardComponent implements OnInit, OnChanges, OnDestroy {
  public nodes: object[] = [];
  public scrollTop: number = 0;
  public isSelected: boolean = false;
  public moleculeSets: any[] = [];

  @Input() public algorithm: AnalysisAlgorithm;
  @Input() public mode: GraphModeType;

  @Output() public itemHeight: number = 288;
  @Output() public needVisibility = new EventEmitter();
  @Output() public graphId: object;

  @ViewChildren('nodeItem') public items: QueryList<NewClipboardItemComponent>;

  private unsubscriberSubject: Subject<void> = new Subject<void>();
  public isLibraryMode: boolean = false;
  public isManualMode: boolean = false;
  public isPathView: boolean = false;

  constructor(
    public clipboardService: NodesClipboardService,
    private moleculeSetsService: MoleculeSetsService,
    private infoService: InfoService,
    private analysisResultsService: AnalysisResultsService,
    public analysisService: AnalysisService,
  ) {}

  public ngOnInit() {
    this.clipboardService.clearRequests
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(() => this.clearClipboard());

    this.clipboardService.pinningRequests
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((node) => {
        const added = this.addNode(node);
        this.clipboardService.dispatchNodeEvent(node, added ? 'pinned' : 'unpinned');
        this.analysisResultsService.isMenuOpen.next(true);
        this.analysisResultsService.selectedMenu.next(SidePanelViewType.Clipboard);
      });

    this.clipboardService.pinnedStatusRequests
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((node) => {
        this.clipboardService.dispatchNodeEvent(
          node,
          this.getNodeIdx(node) !== -1 ? 'pinned' : 'unpinned',
        );
      });

    this.clipboardService.pointingRequests
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((node) => this.pointNode(node));

    this.moleculeSetsService
      .getSortedMoleculeSetTemplates()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (moleculeSets: any[]) =>
          (this.moleculeSets = moleculeSets.filter((molSet) => {
            return molSet.owner !== null;
          })),
      );

    this.analysisService.analysisSettingsBehaviorSubjects.mode
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((mode) => {
        this.isPathView = mode === ResultsView.PATHWAYS;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('algorithm')) {
      this.isLibraryMode = this.algorithm === AnalysisAlgorithm.LIBRARY_MODE;
      this.isManualMode = this.algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS;
    }
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public pointNode(node: object) {
    let nodeIdx = -1;
    const nodeItem = this.items.find((item, idx) => {
      /* tslint:disable */
      if (item.node == node) {
        nodeIdx = idx;
        return true;
      }
      /* tslint:enable */
    });
    if (nodeItem) {
      this.assertVisible(nodeIdx);
      setTimeout(() => nodeItem.bounceIt(), 100); // slightly delayed for possible scroll
    }
  }

  public deselectAllNodes() {
    this.items.forEach((item: NewClipboardItemComponent) => {
      if (item.pointed) {
        item.pointNode();
      }
    });
  }

  public exportAllNodes() {
    this.clipboardService.export(this.items.toArray());
  }

  public setIsSelected() {
    this.isSelected = false;
    if (this.items) {
      this.items.forEach((item: NewClipboardItemComponent) => {
        if (item.pointed) {
          this.isSelected = true;
        }
      });
    }
  }

  public deleteNode(node: GraphMoleculeNode | GraphReactionNode) {
    const idx = this.getNodeIdx(node);
    if (idx !== -1) {
      this.nodes.splice(idx, 1);
    }
  }

  public clearClipboard() {
    this.deselectAllNodes();
    this.nodes = [];
  }

  public assertVisible(nodeIdx: number) {
    const offset = this.items.reduce((accumulator, item, idx) => {
      if (idx < nodeIdx) {
        accumulator += item.height + 5;
      }
      return accumulator;
    }, 0);
    // FIXME: Do not scroll node to the top if it is visible.
    // FIXME: Implement smooth scrolling animation.
    this.scrollTop = offset;
  }

  public addNode(node: GraphReactionNode | GraphMoleculeNode): boolean {
    this.needVisibility.emit();
    const existingNodeIdx = this.getNodeIdx(node);

    if (existingNodeIdx === -1) {
      this.nodes.push(node);

      setTimeout(() => {
        this.assertVisible(this.nodes.length - 1);
      }, 100);

      return true;
    } else {
      // As user wants to add and an existing node lets point it
      setTimeout(() => this.pointNode(node), 1);

      return false;
    }
  }

  public addMoleculeToSet(event: { setId: number; moleculeId: string; setName: string }) {
    this.moleculeSetsService
      .addMoleculesToSet({ setId: event.setId, moleculeId: event.moleculeId })
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (_) => this.infoService.showInfo(`Molecule added to set '${event.setName}'`),
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          if (parsedError.bodyJson.code === 'non-unique-molecule-set-molecule') {
            this.infoService.showError(
              `Molecule set '${event.setName}' already contains this molecule`,
              3000,
            );
          }
          if (parsedError.bodyJson.code === 'molecule-set-not-found') {
            this.infoService.showError(`Molecule set: '${event.setName}' does not exist`, 3000);
          }
        },
      );
  }

  private getNodeIdx(node: GraphMoleculeNode | GraphReactionNode): number {
    if (node.type === GraphNodeType.REACTION) {
      return this.nodes.findIndex(
        (currentNode: any) =>
          currentNode.type === GraphNodeType.REACTION &&
          currentNode.reaction.id === node.reaction.id,
      );
    }
    return this.nodes.findIndex(
      (currentNode: any) =>
        currentNode.type !== GraphNodeType.REACTION && currentNode.molecule.id === node.molecule.id,
    );
  }
}
