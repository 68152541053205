import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { ChematicaRouteStateService } from './shared/services/ch-route-state.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToHomeGuard implements OnDestroy {
  public userDashboardUrl: string;
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(private routeStateService: ChematicaRouteStateService) {
    this.routeStateService.landingPageUrl
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((url) => {
        this.userDashboardUrl = url;
      });
  }

  public canActivate(): boolean {
    if (this.userDashboardUrl) {
      this.routeStateService.navigateToHome();
      return false;
    }
    return true;
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }
}
