import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { isNumber } from '../components/utils';

/**
 * Display number formated same as Angular's number pipe but adding also the metric prefixes to accordingly scaled
 * values.
 *
 * We apply only a widely used subset of known sufixes at distance x1000 ignoring others such as hecto, deca, etc):
 * nano(n) for values less than 1e6,
 * micro(µ) [1e-6, 1e-3),
 * mili(m) [1e-3, 1),
 * no sufix [1, 1e3),
 * kilo(k) [1e3, 1e5)
 * mega(M) [1e6, 1e8),
 * giga(G) [1e9, 1e12),
 * tera(T) for 1e12 and above
 *
 * For example:
 *   <span>
 *     {{123000| chMetricSufix:'1.0-2' }} {{123456000| chMetricSufix:'1.0-2' }} {{123456000000| chMetricSufix:'1.0-0' }}
 *   </span>
 *
 *   prints '123k 123.46M 123G'
 *
 */
@Pipe({
  name: 'chMetricSufix',
})
export class MetricSuffixPipe extends DecimalPipe implements PipeTransform {
  public transform(value: string, digits?: string): any {
    const numberValue = Number(value);
    if (!isNumber(numberValue) || numberValue === 0) {
      // let DecimalPipe deal with not interesting input
      return super.transform(value, digits);
    }

    const exponent = this.exponent(numberValue);
    const sufixes = ['n', 'µ', 'm', '', 'k', 'M', 'G', 'T'];
    let sufixIndex = Math.floor(exponent / 3) + 3;
    if (sufixIndex < 0) {
      sufixIndex = 0;
    } else if (sufixIndex >= sufixes.length) {
      sufixIndex = sufixes.length - 1;
    }
    const scaleFactor = Math.pow(10, -3 * (sufixIndex - 3)); // ex: upscale by 1e9 for nano, downscale by 1e6 for mega

    return super.transform(numberValue * scaleFactor, digits) + sufixes[sufixIndex];
  }

  private exponent(x: number, b = 10) {
    let exp = 0;
    if (x !== 0) {
      if (x < 0) {
        x = -x;
      }
      while (x > b) {
        x /= b;
        exp++;
      }
      while (x < 1) {
        x *= b;
        exp--;
      }
    }
    return exp;
  }
}
