import { Injectable } from '@angular/core';
import { Plan } from '../models/plan';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserSubscription } from '../models';

export const backendEntryPointPlans =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/emd/subscription-plans/';
export const backendEntryPointSubscription =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/emd/user-subscriptions/';
export const backendEntryPointCreateSubscription =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/emd/subscribe-plan/';
export const backendEntryPointValidateSubscription =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/emd/subscribe-plan-status/';

export interface CreateSubscriptionResponseType {
  session_id?: string;
  subscription_id?: string;
  checkout_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmdHandlerService {
  public isPlanSubscribed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public getPlans(): Observable<Plan[]> {
    return this.http.get(backendEntryPointPlans, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.plans.map((plan) => new Plan(plan));
        } catch (e) {
          throw new Error('Unexpected format of plans response.');
        }
      }),
    );
  }

  public getUserSubscription(): Observable<UserSubscription> {
    return this.http.get(backendEntryPointSubscription, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return this.resolveUserSubscriptionEntry(response);
      }),
    );
  }

  public createSubscription(
    selectedPlanId: string,
    isSamePlan: boolean,
  ): Observable<CreateSubscriptionResponseType> {
    const params: any = {
      plan_id: selectedPlanId,
      cancel_url: APP_CONFIG.CHEMATICA_API_URL + '/?success=false',
      success_url: APP_CONFIG.CHEMATICA_API_URL + '/?success=true',
    };
    if (isSamePlan) {
      params.behavior = 'FORCE_NEW';
    }
    return this.http
      .post(backendEntryPointCreateSubscription, JSON.stringify(params), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        }),
      );
  }

  public validateSubscription(): Observable<UserSubscription> {
    return this.http.get(backendEntryPointValidateSubscription, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return this.resolveUserSubscriptionEntry(response);
      }),
    );
  }

  private resolveUserSubscriptionEntry(response: HttpResponse<any>) {
    try {
      const responseJson = response.body;
      if (responseJson.subscriptions.length) {
        return new UserSubscription(responseJson.subscriptions[0]);
      }
      return null;
    } catch (e) {
      throw new Error('Unexpected format of user subscription response.');
    }
  }
}
