import { Component, Input } from '@angular/core';
import { AppConstantsService, ErrorsHandlerService, InfoService } from 'src/app/shared/services';
import { ActiveCartItem } from '../../models/ActiveCartItem';
import { ECommHandlerService } from '../../services/ecomm-handler.service';

@Component({
  selector: 'ch-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  @Input() public activeCartItem: ActiveCartItem;
  public isImageLoading: boolean = true;
  public isRemovingCartItem: boolean = false;

  constructor(
    private eCommHandlerService: ECommHandlerService,
    private infoService: InfoService,
    private errorsHandlerService: ErrorsHandlerService,
    private appConstantsService: AppConstantsService,
  ) {}

  public hideImageLoader(event): void {
    this.isImageLoading = false;
    if (event.type === 'error') {
      this.activeCartItem.material.image.mediumUrl = '../assets/img/Vibrant_M.png';
    }
  }

  public deleteCartItem(activeCartItem: ActiveCartItem): void {
    this.isRemovingCartItem = true;
    this.eCommHandlerService.deleteCartItems([activeCartItem]).subscribe(
      (result: boolean) => {
        this.isRemovingCartItem = false;
        if (result) {
          this.infoService.showInfo(
            this.appConstantsService.removeSingleItemFromShoppingListSuccess,
          );
        } else {
          this.errorsHandlerService.showGlobalError(
            this.appConstantsService.removeItemFromShoppingListFailed,
          );
        }
      },
      (error) => {
        this.isRemovingCartItem = false;
        this.errorsHandlerService.showGlobalError(
          this.appConstantsService.removeItemFromShoppingListFailed,
        );
      },
    );
  }

  public onCheckBoxChange(): void {
    this.eCommHandlerService.updateCart.next(this.activeCartItem.is_selected);
  }
}
