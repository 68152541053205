import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  @Input() public title: string = 'Information';
  @Input() public message: string = '';
  @Input() public closeButtonText: string = 'Close';
  @Input() public reportErrorButton: boolean = false;
  @Input() public synthiaPromoButtton: boolean = false;
  @Input() public showUpgradeOptionButton: boolean = false;
  @Input() public synthiaLiteEnabled: boolean = false;
  @Input() public showAcceptButton: boolean = false;
  @Input() public acceptButtonText: string = 'Ok';
  @Input() public showCloseButton: boolean = true;

  public messageIsHtml: boolean = false;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {}

  public ngOnInit() {
    if (this.message) {
      const htmlRegex: RegExp = new RegExp(/<[a-z][\s\S]*>/i);
      this.messageIsHtml = htmlRegex.test(this.message);
    } else {
      this.messageIsHtml = false;
    }
  }
}
