<div class="terms-conditions-data-container">
  <div class="main-container" *ngIf="!isDataLoading">
    <div class="data-container">
      <div class="terms-condition-section">
        <div [innerHtml]="termsAndConditionsHtmlContent | safeHtml"></div>
      </div>
      <div class="acknowledgement-section">
        <div class="content-head" [innerHtml]="acknowledgementHtmlContent | safeHtml"></div>
        <div class="accept-section" *ngFor="let section of acknowledgementSections; let i = index">
          <div class="accept-section-content" [innerHtml]="section.content | safeHtml"></div>
          <hr class="accept-line" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isDataLoading">
    <ch-loader></ch-loader>
  </div>
</div>
