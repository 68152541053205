import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PortalModule } from '@angular/cdk/portal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import 'intersection-observer';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppState, fireBaseConfig } from './app-state.service';
import { NoContentComponent } from './no-content';
import { AddHeaderInterceptor } from './add-header-request-interceptor';
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { MainFrameComponent } from './main-frame/main-frame.component';
import { TechSupportComponent } from './tech-support';
import { SignOutComponent } from './authorization';
import {
  MoleculeImageService,
  ExtendedSnackBarComponent,
  InformationComponent,
} from './shared/components';
import {
  MolecularStrainService,
  AuthorizedTextResourceService,
  BackendPushService,
  ReactionAnnotationsService,
  NodesClipboardService,
  ScoringFunctionsService,
  RelatedReactionListService,
  GraphBuilder,
  BackendPrintService,
  MoleculeSetsService,
  ViewportVisibilityService,
  ParametersStashService,
  CopyToClipboardService,
  FrontendStorageService,
  InfoService,
  ChematicaRouteStateService,
  InteractiveTourHandleService,
  IntroTourDialogService,
  TechSupportService,
  UserInventoryService,
  FolderDragService,
  ReactionNavigationService,
  VersionService,
  PasswordChangeDialogService,
  TagManagerService,
} from './shared/services';
import { GraphService } from './shared/components/analysis-graph/graph.service';
import { ALL_MATERIALS_MODULES } from './shared/all-materials-modules';
import { ChematicaRoutingStrategy } from './ch-route-reuse-strategy';
import { SocialService } from './shared/services/social';
import { NewTopToolbarComponent } from './shared/components/new-top-toolbar/new-top-toolbar.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';

import { RedirectToHomeGuard } from './redirect-to-home.guard';

import { NoAuthGuard } from './no-auth.guard';
import { AskForLeaveGuard } from './ask-for-leave.guard';
import { CookieService } from 'ngx-cookie-service';
import { TermsSynthiaLiteComponent } from './synthia-lite/components/terms-synthia-lite/terms-synthia-lite.component';
import { SharedModule } from './shared/shared.module';
import { CustomPreloadStrategyService } from 'src/app/core/custom-preload-strategy.service';

import { provideFirebaseApp } from '@angular/fire/app';
import { initializeApp } from 'firebase/app';
import { provideAuth } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
import { SSOConfiguration } from './shared/services/models/sso-configuration';

const firebaseAppInitializer = (appConfig: AppState) => {
  return () => {
    return appConfig.loadFirebaseAppConfig();
  };
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

const COMPONENTS = [
  AppComponent,
  MainFrameComponent,
  NoContentComponent,
  NewTopToolbarComponent,
  BreadcrumbComponent,
  SignOutComponent,
  ExtendedSnackBarComponent,
  InformationComponent,
  TermsSynthiaLiteComponent,
  TechSupportComponent,
];

const APP_PROVIDERS = [
  AppState,
  {
    provide: APP_INITIALIZER,
    useFactory: firebaseAppInitializer,
    multi: true,
    deps: [AppState],
  },
  MoleculeImageService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddHeaderInterceptor,
    multi: true,
  },
  MolecularStrainService,
  GraphService,
  AuthorizedTextResourceService,
  BackendPushService,
  ReactionAnnotationsService,
  NodesClipboardService,
  ScoringFunctionsService,
  RelatedReactionListService,
  GraphBuilder,
  BackendPrintService,
  MoleculeSetsService,
  ViewportVisibilityService,
  ParametersStashService,
  CopyToClipboardService,
  FrontendStorageService,
  ExtendedSnackBarComponent,
  InfoService,
  ChematicaRouteStateService,
  InteractiveTourHandleService,
  IntroTourDialogService,
  TechSupportService,
  { provide: RouteReuseStrategy, useClass: ChematicaRoutingStrategy },
  UserInventoryService,
  SocialService,
  FolderDragService,
  ReactionNavigationService,
  RedirectToHomeGuard,
  VersionService,
  DecimalPipe,
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  NoAuthGuard,
  PasswordChangeDialogService,
  AskForLeaveGuard,
  CookieService,
  TagManagerService,
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [...COMPONENTS],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TextFieldModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: CustomPreloadStrategyService,
    }),
    provideFirebaseApp(() => {
      const config = {
        apiKey: fireBaseConfig?.apiKey,
        authDomain: fireBaseConfig?.authDomain,
      };
      return initializeApp(new SSOConfiguration(config));
    }),
    provideAuth(() => getAuth()),
    SharedModule,
  ],
  providers: [APP_PROVIDERS],
})
export class AppModule {
  constructor(private versionService: VersionService) {
    if (APP_CONFIG.APP_VERSION_PROBE_PERIOD < 5000) {
      console.error('Configuration error. APP_VERSION_PROBE_PERIOD is too short.');
    }

    this.versionService.startAutoUpdate(APP_CONFIG.APP_VERSION_PROBE_PERIOD);
  }
}
