import { rsfParser } from '../../../vendor/rsf-parser.js';
import { csfParser } from '../../../vendor/csf-parser.js';

export interface AdvancedSFAvailableParam {
  parameter_name: string;
  description: string;
  value: string;
  hideSeek: boolean;
}

export const ADVANCED_CSF_AVAILABLE_PARAMS: AdvancedSFAvailableParam[] = [
  {
    value: 'RINGS',
    description: 'Promote rings formation',
    parameter_name: 'Rings',
    hideSeek: false,
  },
  {
    value: 'STEREO',
    description: 'Promote formation of stereocenters',
    parameter_name: 'Stereo',
    hideSeek: false,
  },
  {
    value: 'SMALLER',
    description: `Evaluate complexity of synthons (comprises mass, branches and stereocenters).
                  By taking this variable to a power greater than 1,
                  promote cuts into synthons of similar sizes`,
    parameter_name: 'Smaller',
    hideSeek: false,
  },
  {
    value: 'HETEROATOMS',
    description: 'Penalizes the use of heavy atoms',
    parameter_name: 'Heteroatoms',
    hideSeek: false,
  },
];

export const ADVANCED_RSF_AVAILABLE_PARAMS: AdvancedSFAvailableParam[] = [
  {
    parameter_name: 'Hide SMILES',
    description: 'Penalize molecules with a given SMILES',
    value: 'HIDE_SMILES',
    hideSeek: true,
  },
  {
    parameter_name: 'Hide SMILES set',
    description: 'Penalize molecules with a given SMILES fragment(s) contained in selected sets',
    value: 'HIDE_MOLSET',
    hideSeek: true,
  },
  {
    parameter_name: 'Hide SMARTS',
    description: 'Penalize molecules matching specified SMARTS fragment(s)',
    value: 'HIDE_SMARTS',
    hideSeek: true,
  },
  {
    parameter_name: 'Hide SMARTS set',
    description:
      'Penalize molecules matching specified SMARTS fragment(s) contained in selected sets',
    value: 'HIDE_SMARTSSET',
    hideSeek: true,
  },
  {
    parameter_name: 'Hide NAME',
    description: 'Penalize reactions with selected name',
    value: 'HIDE_NAME',
    hideSeek: true,
  },
  {
    parameter_name: 'Steps',
    description: 'Add a penalty for each synthetic step',
    value: 'STEP',
    hideSeek: false,
  },
  {
    parameter_name: 'Filters',
    description: 'Penalize relatively unstable molecules',
    value: 'FILTERS',
    hideSeek: false,
  },
  {
    parameter_name: 'Conflicts',
    description: 'Penalize reactions involving incompatible/cross-reactive functional groups',
    value: 'CONFLICT',
    hideSeek: false,
  },
  {
    parameter_name: 'Non-selectivity',
    description: `Penalize 'competing' reaction that can occur on several
                  non-equivalent centers within the same molecule`,
    value: 'NON_SELECTIVITY',
    hideSeek: false,
  },
  {
    parameter_name: 'Protections',
    description: 'Penalize reactions that require protection of functional groups',
    value: 'PROTECT',
    hideSeek: false,
  },
];

export enum ScoringFunctionCategoryType {
  REACTION_SCORE = 'REACTION_SCORE',
  MOLECULE_SCORE = 'MOLECULE_SCORE',
  AUTOMATIC_RETROSYNTHESIS_REACTION = 'AUTOMATIC_RETROSYNTHESIS_REACTION',
  AUTOMATIC_RETROSYNTHESIS_MOLECULE = 'AUTOMATIC_RETROSYNTHESIS_MOLECULE',
  REACTION_ORDER = 'REACTION_ORDER',
}

export enum FunctionType {
  Sorting,
  Chemical,
  Reaction,
}

/*
 * Converts a Reaction Scoring Function to a Reverse Polish Notation version following a set of rules.
 * Consult rsf-grammar.txt for more information.
 * returns a space separated tokens in RPN.
 *
 */
export function parseRSF(scoringFunction: string): string {
  if (scoringFunction.indexOf('**') !== -1) {
    scoringFunction = scoringFunction.split('**').join('^');
  }
  return rsfParser.parse(scoringFunction);
}

/*
 * Converts a Chemical Scoring Function to a Reverse Polish Notation version following a set of rules.
 * Consult csf-grammar.txt for more information.
 * returns a space separated tokens in RPN.
 *
 */
export function parseCSF(scoringFunction: string): string {
  if (scoringFunction.indexOf('**') !== -1) {
    scoringFunction = scoringFunction.split('**').join('^');
  }
  return csfParser.parse(scoringFunction);
}

export function translateRPNToScoringFunction(tokens: any[], shouldGroup?: boolean): string {
  const isOperator = (o) => new RegExp(/\+|\-|\*|\/|\^/).test(o);
  const stack = [];
  const rsfSet: Set<string> = new Set(
    ADVANCED_RSF_AVAILABLE_PARAMS.map((rsfParam) => rsfParam.value),
  );
  if (shouldGroup) {
    tokens = groupHideSeekParametersAndArguments(tokens, rsfSet);
  }
  const operatorPrecedences = {
    '+': 1,
    '-': 1,
    '*': 2,
    '/': 2,
    '^': 3,
    null: 4,
  };
  const operatorStack = [];
  for (const token of tokens) {
    let strA: string;
    let strB: string;
    if (!isOperator(token)) {
      stack.push(token);
    } else {
      const [tokenA, tokenB, operatorA, operatorB] = [
        stack.pop(),
        stack.pop(),
        operatorStack.pop(),
        operatorStack.pop(),
      ];
      if (operatorPrecedences[operatorB] < operatorPrecedences[token]) {
        strB = `(${tokenB})`;
      } else {
        strB = `${tokenB}`;
      }
      if (
        operatorPrecedences[operatorA] < operatorPrecedences[token] ||
        (operatorPrecedences[operatorA] === operatorPrecedences[token] &&
          ['/', '-'].includes(token))
      ) {
        strA = `(${tokenA})`;
      } else {
        strA = `${tokenA}`;
      }
      operatorStack.push(token);
      stack.push(strB + token + strA);
    }
  }
  if (stack.length === 1) {
    return stack[0];
  } else {
    throw new Error(`Scoring Function parsing error.
                     Make sure Scoring Function is in correct format.`);
  }
}

export function groupHideSeekParametersAndArguments(
  rpnTokens: string[],
  functionSet: Set<string>,
): string[] {
  const grouped: string[] = [];
  for (let i = 0; i < rpnTokens.length; i++) {
    if (functionSet.has(rpnTokens[i])) {
      if (
        !!ADVANCED_RSF_AVAILABLE_PARAMS.find(
          (rsfParam) => rpnTokens[i] === rsfParam.value && rsfParam.hideSeek,
        )
      ) {
        const hideSeekNumberOfArgs = parseInt(rpnTokens[i + 1], 10);
        let jointArguments: string = `${rpnTokens[i]}(`;
        let arg: number = 1;
        while (hideSeekNumberOfArgs && arg <= hideSeekNumberOfArgs) {
          jointArguments +=
            arg === hideSeekNumberOfArgs
              ? `${rpnTokens[i + 1 + arg]}`
              : `${rpnTokens[i + 1 + arg]},`;
          arg++;
        }
        jointArguments += ')';
        grouped.push(jointArguments);
      } else {
        grouped.push(rpnTokens[i]);
      }
    } else {
      grouped.push(rpnTokens[i]);
    }
  }
  return grouped;
}
