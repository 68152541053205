import { isString } from '../../shared/components/utils';
import { ActiveCartItemMaterial } from './ActiveCartItemMaterial';

export class ActiveCartItem {
  public material_number: string;
  public quantity: number;
  public material: ActiveCartItemMaterial;
  public cart_id: string;
  public cart_item_id: string;
  public brand: string;
  public brand_key: string;
  public product: string;
  public price: number;
  public description: string;
  public is_selected: boolean;

  constructor(data: any, ecommAPIUrl: string) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    this.material_number = jsonData.materialNumber;
    this.quantity = jsonData.quantity ?? 0;
    this.cart_id = jsonData.cartId;
    this.cart_item_id = jsonData.cartItemId;
    this.brand = jsonData.brand ? jsonData.brand.split(';')[0] : '';
    this.brand_key =
      jsonData.brand && jsonData.brand.split(';').length > 1 ? jsonData.brand.split(';')[1] : '';
    this.product = jsonData.product;
    this.price = jsonData.price;
    this.description = jsonData.materialDescription;
    this.is_selected = false;
    this.material = new ActiveCartItemMaterial(jsonData.material, ecommAPIUrl);
  }
}
