import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-data-loss-popup',
  styleUrls: ['./data-loss-popup.component.scss'],
  templateUrl: './data-loss-popup.component.html',
})
export class DataLossPopupComponent {
  public popupText: string;
  public dontShowAgain: boolean;

  constructor(public dialogRef: MatDialogRef<DataLossPopupComponent>) {}

  public close(confirm: boolean) {
    this.dialogRef.close({ confirm, dontShowAgain: this.dontShowAgain });
  }
}
