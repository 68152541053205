import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ITopicArticle } from '../../services/user-manual/models/manual';
import { ParametersStashService } from '../../services';

@Component({
  selector: 'ch-nested-tree',
  styleUrls: ['./nested-tree.component.scss'],
  templateUrl: './nested-tree.component.html',
})
export class NestedTreeComponent implements OnInit {
  @Input() public treeData: any[] = [];
  // FIXME: This and another related changes are only fast and ugly fix for new manual articles hierarchy,
  // so we will make deeper changes at bug fixing phase
  @Input() public fullTreeData: any[] = [];
  @Input() public expandedArticle = {};
  @Input() public activeArticle: any = '';
  @Input() public color: 'primary' | 'secondary' | 'accent' | 'warn' = 'primary';

  @Output() public openArticle: EventEmitter<object> = new EventEmitter<object>();
  @Output() public openSubArticle: EventEmitter<object> = new EventEmitter<object>();

  public isSynthiaLiteEnabled: boolean = false;

  constructor(public stashService: ParametersStashService) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
  }

  public selectArticle(article: ITopicArticle, event: Event) {
    if (article.children.length) {
      this.expandedArticle[article.id] = this.expandedArticle[article.id] ? false : true;
    }
    if (this.clickedArticleIsParentOfActiveArticle(article)) {
      this.expandedArticle[article.id] = false;
    } else {
      this.openArticle.emit(article);
    }
    event.stopPropagation();
  }

  public isActive(article: ITopicArticle) {
    if (this.activeArticle) {
      const parentArray = this.findParentOfArticle(this.activeArticle);
      parentArray.push(this.activeArticle.id);
      return parentArray.includes(article.id);
    }
  }

  private clickedArticleIsParentOfActiveArticle(article: ITopicArticle) {
    if (this.activeArticle) {
      const parentArray = this.findParentOfArticle(this.activeArticle);
      if (parentArray.includes(article.id) && !this.expandedArticle[article.id]) {
        return true;
      }
    }
  }

  private findParentOfArticle(article: ITopicArticle) {
    const tableOfParentsId = [];
    let currentParent = article.article_id;
    while (currentParent) {
      tableOfParentsId.push(currentParent);
      const parentArticle = this.findArticleById(
        currentParent,
        this.fullTreeData.length > 0 ? this.fullTreeData : this.treeData,
      );
      if (parentArticle) {
        currentParent = parentArticle.article_id;
      }
    }
    return tableOfParentsId;
  }

  private findArticleById(id: number, articleArray: ITopicArticle[]): ITopicArticle {
    let articleToReturn;
    articleArray.forEach((article: ITopicArticle) => {
      if (article.id === id) {
        articleToReturn = article;
      }
    });
    articleArray.forEach((article) => {
      const foundArticle = this.findArticleById(id, article.children);
      if (foundArticle) {
        articleToReturn = foundArticle;
      }
    });
    return articleToReturn;
  }
}
