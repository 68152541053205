import { Subject, of } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  AnalysisService,
  ErrorsHandlerService,
  ParsedConnectionError,
  RenameDialogService,
  ConfirmDialogService,
  AnalysisAlgorithm,
  ComputationState,
  InfoService,
  AppConstantsService,
  AnalysisResultsService,
} from '../../services';
import { ComputationEntry } from '../../services/analysis/models/computation-entry';
import { Router } from '@angular/router';
import { parseProgressItem, IProgressItem } from '../utils';
import { takeUntil, flatMap, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ConfirmDialogConfiguration } from '../../services/confirm-dialog.service';
@Component({
  selector: 'ch-computation-detail',
  styleUrls: ['./computation-detail.component.scss'],
  templateUrl: './computation-detail.component.html',
})
export class ComputationDetailComponent implements OnInit, OnDestroy {
  private static handleLocalErrors(parsedError: ParsedConnectionError): boolean {
    return false; // no errors considered to be local for this view
  }

  public readonly ComputationState = ComputationState;

  @Input() public algorithm: string;
  @Input() public state: string;
  @Input() public computationTime: number;
  @Input() public analysisId: number | null;
  @Input() public computationId: number | null;
  @Input() public stopRequested: boolean = false;
  @Input() public name: string;
  @Input() public computation: ComputationEntry;
  @Input() public fixedDuration: any;
  @Input() public errorMessage: string;
  @Input() public errorCode: string;
  @Input() public visible: boolean = false;
  @Input() public isAnalysisShared: boolean = false;

  public autostopAfter: string;
  public progressItemSummary: IProgressItem;
  public parsedComputationTime: string;
  public stopping: boolean = false;
  public stopped: boolean = false;
  public timer: any;
  public nameLoading: boolean = false;
  public stopButtonMessage: string = 'Stop';
  public isStopButtonDisabled: boolean = false;

  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private stopComputationUnsubscriberSubject: Subject<any> = new Subject();

  constructor(
    public appConstantService: AppConstantsService,
    public analysisService: AnalysisService,
    public router: Router,
    private errorsHandler: ErrorsHandlerService,
    private renameDialogService: RenameDialogService,
    private confirmDialogService: ConfirmDialogService,
    private infoService: InfoService,
    private appConstantsService: AppConstantsService,
    private analysisResultsService: AnalysisResultsService,
  ) {}

  public ngOnInit() {
    this.getAutostopAfter();
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);

    this.subscribeToStoppingAnalyses();

    if (this.isAutomaticRetrosynthesisAnalysis() || this.isLibraryModeAnalysis()) {
      this.analysisService.progressItemSummary
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe((progressItemSummary: string) => {
          this.progressItemSummary = parseProgressItem(progressItemSummary);
        });
    }
  }

  public subscribeToStoppingAnalyses() {
    this.analysisService.stoppingAnalyses
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((stoppingAnalyses) => {
        if (stoppingAnalyses.some((analysisId) => analysisId === this.computation.analysis)) {
          if (
            (this.isAutomaticRetrosynthesisAnalysis() || this.isLibraryModeAnalysis()) &&
            (this.state === ComputationState.IN_PROGRESS ||
              this.state === ComputationState.PENDING ||
              this.state === ComputationState.IN_QUEUE)
          ) {
            this.stopping = true;
            this.stopButtonMessage = 'Stopping';
          } else {
            this.stopping = false;
            this.stopButtonMessage = 'Stop';
            const newStoppingAnalyses: number[] = this.analysisService.stoppingAnalyses.value.filter(
              (analysisId) => analysisId !== this.computation.analysis,
            );
            this.analysisService.stoppingAnalyses.next(newStoppingAnalyses);
          }
        }
      });
  }

  public ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = undefined;
    this.unsubscriberSubject.next();
  }

  public getAutostopAfter() {
    this.autostopAfter = this.getComputationTime(this.fixedDuration);
  }

  public updateTime() {
    if (this.computationTime <= this.fixedDuration || !this.fixedDuration) {
      // We can not use the Date object because computation time
      // may go over than 24 hours and we need to show all full hours
      if (
        this.state === ComputationState.PENDING ||
        this.state === ComputationState.IN_PROGRESS ||
        this.state === ComputationState.IN_QUEUE
      ) {
        this.computationTime += 1;
      }
      this.parsedComputationTime = this.getComputationTime(this.computationTime);
    } else {
      this.parsedComputationTime = this.autostopAfter;
      this.stopped = true;
    }
  }

  public stopComputation() {
    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: 'Stop analysis computation',
      message: `Do you want to stop ${this.computation.name}?`,
      trueActionName: 'STOP',
    };

    this.confirmDialogService
      .confirm(dialogConfiguration)
      .pipe(
        takeUntil(this.stopComputationUnsubscriberSubject),
        flatMap((action) => {
          if (action) {
            this.stopping = !this.stopping;
            return this.analysisService
              .stopComputation(this.computation.analysis, this.computationId)
              .pipe(
                catchError((error) => {
                  if (error.status === 403) {
                    const parsedError = new ParsedConnectionError(error);
                    if (!parsedError.isCustomerPolicyNotAcceptedError()) {
                      this.infoService.showError(
                        this.appConstantsService.stopComputationPermissionErrorMessage,
                        5000,
                      );
                    }
                  }
                  return of(error);
                }),
              );
          } else {
            this.stopComputationUnsubscriberSubject.next();
          }
        }),
      )
      .subscribe(
        (computation: ComputationEntry) => {
          if (computation.state === ComputationState.INTERRUPTED_BY_USER) {
            this.stopping = false;
            this.stopped = true;
          }
        },
        (error) => {
          this.stopping = false;
          this.computationDetailCallError(error);
        },
      );
  }

  public renameComputation() {
    this.renameDialogService
      .renameDialog('Rename', this.computation.name)
      .pipe(
        takeUntil(this.unsubscriberSubject),
        flatMap((name: string) => {
          if (name && name !== this.computation.name) {
            this.nameLoading = true;
            return this.analysisService.renameComputation(
              this.computation.analysis,
              this.computation.id,
              name,
            );
          }
          return of(null);
        }),
      )
      .subscribe(
        (result: HttpResponse<any>) => {
          if (result && result.status === 202) {
            this.nameLoading = false;
            this.name = result.body.name;
          }
        },
        (error) => {
          this.nameLoading = false;
          this.computationDetailCallError(error);
        },
      );
  }

  public showErrors() {
    this.analysisResultsService.loadComputationError();
  }

  public isManualRetrosynthesisAnalysis() {
    return this.algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS;
  }

  public isAutomaticRetrosynthesisAnalysis() {
    return this.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS;
  }

  public isLibraryModeAnalysis() {
    return this.algorithm === AnalysisAlgorithm.LIBRARY_MODE;
  }

  public isMinimalCostAnalysis() {
    return this.algorithm === AnalysisAlgorithm.MINIMAL_COST;
  }

  public isGreedyPopularityAnalysis() {
    return this.algorithm === AnalysisAlgorithm.GREEDY_POPULARITY;
  }

  public isCostAndPopularityAnalysis() {
    return this.algorithm === AnalysisAlgorithm.COST_AND_POPULARITY;
  }

  public isReactionNetworkAnalysis() {
    return (
      this.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK ||
      this.algorithm === AnalysisAlgorithm.REACTION_NETWORK
    );
  }

  public isIterationsLimit() {
    return (
      this.computation.input.main_limit === 'iterations' &&
      (this.isAutomaticRetrosynthesisAnalysis() || this.isLibraryModeAnalysis())
    );
  }

  public isTimeLimit() {
    return (
      this.autostopAfter &&
      this.computation.input.main_limit === 'time' &&
      (this.isAutomaticRetrosynthesisAnalysis() || this.isLibraryModeAnalysis())
    );
  }

  public isComputationInProgress() {
    return (
      (this.state === ComputationState.PENDING || this.state === ComputationState.IN_PROGRESS) &&
      (this.isAutomaticRetrosynthesisAnalysis() || this.isLibraryModeAnalysis())
    );
  }

  private addZero(time: number, sliceValue: number = -2) {
    return ('00' + time).slice(sliceValue);
  }

  private computationDetailCallError(error: any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage);
      this.errorsHandler.logout();
    } else if (parsedError.isRecognized()) {
      if (!ComputationDetailComponent.handleLocalErrors(parsedError)) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      }
    }
  }

  private getComputationTime(timeData: number) {
    const hour = Math.floor(timeData / 3600);
    const min = Math.floor(timeData / 60) % 60;
    const sec = timeData % 60;
    const hoursSubstrSliceValue =
      this.computation.input &&
      this.computation.input.developer_mode_parameters &&
      hour.toString().length === 3
        ? -3
        : -2;
    return (
      this.addZero(hour, hoursSubstrSliceValue) + ':' + this.addZero(min) + ':' + this.addZero(sec)
    );
  }
}
