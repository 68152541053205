import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NestedTreeComponent } from '../nested-tree/nested-tree.component';
import { ITopicArticle } from '../../services/user-manual/models/manual';
import * as PrivacyUSHtml from '!raw-loader!../../../../privacyUS.html';
import * as PrivacyWorldHtml from '!raw-loader!../../../../privacyWorld.html';
import * as PersonalAgreeCnHTML from '!raw-loader!../../../../personalAgreeCN.html';
import * as TermsAndUsageCnHTML from '!raw-loader!../../../../termsAndUsageCn.html';
import * as WebSiteAndUsagePrivacy from '!raw-loader!../../../../webSiteAndUsagePrivacy.html';
import { AppState } from 'src/app/app-state.service';
import { ParametersStashService } from '../../services';

@Component({
  selector: 'ch-information-component',
  styleUrls: ['./information.component.scss'],
  templateUrl: './information.component.html',
})
export class InformationComponent implements OnInit, OnDestroy {
  public articles: ITopicArticle[] = [];
  public loadingInformation: boolean = false;
  public expandedArticle = {};
  public activeArticle: ITopicArticle;
  public isSynthiaLiteEnabled: boolean = false;
  public englishPrivacyArticle: string = 'Privacy';
  public chinesePrivacyArticle: string = '数据隐私声明';
  public chineseTechSupport: string = '技术支持';
  public chineseAboutUs: string = '关于我们';
  public isChinaRegion: boolean = APP_CONFIG.CHINA_REGION_INSTANCE;
  public readonly chinesePersonalInformationDataAgreements: string = '个人信息收集同意书';
  public readonly chineseTermsAndUsage: string = '使用条款';
  public readonly chineseWebSiteUsagePrivacy: string = '网站和应用程序隐私声明';
  private routeParamsSubscription: Subscription;

  @ViewChild('nestedTreeComponent')
  public nestedTreeComponent: NestedTreeComponent;

  constructor(
    public stashService: ParametersStashService,
    public appStateService: AppState,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;

    this.routeParamsSubscription = this.route.params.subscribe((params) => {
      if (
        ['terms', 'privacy', 'personal', 'termscn', 'website-and-usage-privacy'].includes(
          params.article,
        )
      ) {
        this.loadInformation();
      }
    });
  }

  public loadInformation() {
    this.loadingInformation = true;

    if (this.isChinaRegion) {
      this.articles = [
        {
          id: 2,
          topic_article: this.getPrivacyArticle(),
          content: {
            innerHTML: this.getPrivacyContent(),
          },
          children: [],
          article_id: null,
          children_as_tile: false,
        },
        {
          id: 3,
          topic_article: this.getChinesePersonalAgreeArticle(),
          content: {
            innerHTML: this.getPersonalInfoDataAgree(),
          },
          children: [],
          article_id: null,
          children_as_tile: false,
        },
        {
          id: 4,
          topic_article: this.getChineseTermAndUsageArticle(),
          content: {
            innerHTML: this.getChineseTermAndUsageContent(),
          },
          children: [],
          article_id: null,
          children_as_tile: false,
        },
      ];
    } else {
      this.articles = [
        {
          id: 2,
          topic_article: 'Privacy',
          content: {
            innerHTML: PrivacyUSHtml,
          },
          children: [],
          article_id: null,
          children_as_tile: false,
        },
      ];
    }

    switch (this.router.url) {
      case '/information/privacy':
        this.activeArticle = this.articles[0];
        break;
      case '/information/personal':
        this.activeArticle = this.articles[1];
        break;
      case '/information/termscn':
        this.activeArticle = this.articles[2];
        break;
      default:
        this.activeArticle = this.articles[0];
        break;
    }

    this.loadingInformation = false;
  }

  public getPrivacyContent() {
    if (this.isChinaRegion) {
      return WebSiteAndUsagePrivacy;
    } else {
      const userCountry = this.appStateService.country.value;
      return userCountry !== 'US' ? PrivacyWorldHtml : PrivacyUSHtml;
    }
  }

  public getPersonalInfoDataAgree() {
    return PersonalAgreeCnHTML;
  }

  public getChineseTermAndUsageContent() {
    return TermsAndUsageCnHTML;
  }

  public getWebSiteAndUsagePrivacyContent() {
    return WebSiteAndUsagePrivacy;
  }

  public getPrivacyArticle() {
    return this.isChinaRegion ? this.chineseWebSiteUsagePrivacy : this.englishPrivacyArticle;
  }

  public getChinesePersonalAgreeArticle() {
    return this.chinesePersonalInformationDataAgreements;
  }

  public getChineseTermAndUsageArticle() {
    return this.chineseTermsAndUsage;
  }

  public ngOnDestroy() {
    if (this.routeParamsSubscription && !this.routeParamsSubscription.closed) {
      this.routeParamsSubscription.unsubscribe();
    }
  }

  public openTreeArticle(article: ITopicArticle) {
    article = this.findFirstChild(article);
    switch (article.topic_article) {
      case 'Privacy':
      case this.chineseWebSiteUsagePrivacy:
        this.router.navigate(['information/', 'privacy']);
        break;
      case this.chinesePersonalInformationDataAgreements:
        this.router.navigate(['information/', 'personal']);
        break;
      case this.chineseTermsAndUsage:
        this.router.navigate(['information/', 'termscn']);
        break;
      default:
        this.router.navigate(['information/', 'terms']);
        break;
    }
  }

  private findFirstChild(article: ITopicArticle) {
    if (article.hasOwnProperty('children')) {
      let firstArticle = article;
      let firstArticleChildren = article.children;
      while (firstArticleChildren.length !== 0) {
        firstArticle = firstArticleChildren[0];
        firstArticleChildren = firstArticle.children;
      }
      return firstArticle;
    } else {
      return null;
    }
  }
}
