<div class="ch-number-input" (contextmenu)="onMouseup()">
  <button
    *ngIf="!disabled"
    (click)="valueDecrement()"
    (mousedown)="downButtonMousedown()"
    (mouseup)="onMouseup()"
    (contextmenu)="onComponentRightClick()"
    (mouseleave)="onMouseleave()"
    class="ch-number-input-button ch-bg-secondary down"
  >
    -</button
  ><button *ngIf="disabled" class="ch-number-input-button down disabled">-</button
  ><input
    type="number"
    min="inputMinimum"
    max="inputMaximum"
    [step]="step"
    [(ngModel)]="inputValue"
    (change)="changeValue($event)"
    class="ch-number-input-field"
    [disabled]="disabled"
  /><button
    *ngIf="!disabled"
    (click)="valueIncrement()"
    (mousedown)="upButtonMousedown()"
    (mouseup)="onMouseup()"
    (contextmenu)="onComponentRightClick()"
    (mouseleave)="onMouseleave()"
    class="ch-number-input-button ch-bg-secondary up"
  >
    +</button
  ><button *ngIf="disabled" class="ch-number-input-button up disabled">+</button>
</div>
