import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { CredentialsService } from '../credentials';
import { ErrorsHandlerService, ParsedConnectionError } from '../errors-handler';

@Component({
  selector: 'permission-tokens',
  templateUrl: './permission-tokens.component.html',
  styleUrls: ['./permission-tokens.component.scss'],
})
export class PermissionTokensComponent implements OnInit {
  public tokensArray: string[] = [];
  public formSentSuccess: boolean = false;
  public formSentError: boolean = false;
  public response: any = '';
  public tokensAdded: string[] = [];
  public tokensExpired: string[] = [];
  public tokensAlreadyOwned: string[] = [];
  public tokensInvalid: string[] = [];
  public pendingApiCall: boolean = false;
  public permissionTokensFormControl: UntypedFormControl;

  private permissionTokensRegex: RegExp = new RegExp(/[0-9a-zA-Z]{64}/);

  constructor(
    private credentialsService: CredentialsService,
    private errorsHandlerService: ErrorsHandlerService,
  ) {}

  public ngOnInit() {
    this.permissionTokensFormControl = new UntypedFormControl('', [
      Validators.minLength(64),
      this.tokensValidator({
        tokensValidity: `Some tokens are not valid. Valid token has 64 characters,
                             consists of uppercase, lowercase letters a-z and numbers 0-9.
                             Remember to separate each token with a comma ','.`,
      }),
    ]);
  }

  public backToForm(): void {
    this.formSentError = false;
    this.formSentSuccess = false;
    this.permissionTokensFormControl.setValue('');
  }

  private convertTokensToArray(inputTokens: string): void {
    this.tokensArray = [];
    this.tokensArray = inputTokens.split(',');
    this.parseTokens(this.tokensArray);
  }

  private addPermissionTokensCallSuccess(tokens: any): void {
    this.formSentSuccess = true;
    this.tokensAdded = tokens.tokens_added;
    this.tokensExpired = tokens.tokens_expired;
    this.tokensAlreadyOwned = tokens.tokens_already_owned;
    this.tokensInvalid = tokens.tokens_not_exist;
  }

  private addPermissionTokensCallError(error): void {
    this.formSentError = true;
    if (!this.handleLocalErrors(error)) {
      const responseLength = error.message.length;
      this.response = error.message;
      this.tokensInvalid = this.response.substring(7, responseLength - 14).split(',');
      this.handleLocalErrors(new ParsedConnectionError(error));
      this.parseTokens(this.tokensInvalid);
      this.removeInvalidTokens();
    }
  }

  private handleLocalErrors(parsedError): boolean {
    // for now just check if it is 403 error
    if (parsedError.code === 'permission_denied') {
      this.formSentError = false;
      this.formSentSuccess = false;
      this.errorsHandlerService.showGlobalError('Insufficient access to perform this action.');
      return true;
    }
    return false;
  }

  private removeInvalidTokens(): void {
    this.tokensInvalid.forEach((token) => {
      this.tokensArray.splice(this.tokensArray.indexOf(token), 1);
    });
    this.permissionTokensFormControl.setValue(this.tokensArray.join(','));
  }

  private parseTokens(array: string[]): void {
    array.forEach((token, i) => {
      array[i] = token.trim();
    });
  }

  private tokensValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const splitTokens: string[] = control.value.trim().split(/\s*\,\s*/);
      const areAllTokensValid: boolean = splitTokens.every((token) => {
        return token.trim().length === 64 && this.permissionTokensRegex.test(token.trim());
      });
      return areAllTokensValid ? null : error;
    };
  }
}
