<div class="keyword-filter-section" #keywordSection>
  <div class="keyword-filter-label">
    <span class="keyword-filter-category">
      Keywords
    </span>
    <span class="keyword-filter-add-icon" (click)="addFilterForKeywords()">
      <mat-icon class="grey-icon">add</mat-icon>
    </span>
  </div>
  <div class="chips-section" *ngIf="!isEditKeywordFilter">
    <div *ngIf="isKeywordsInLimitTo()">
      <div class="section-label"><span>Limit to</span></div>
      <mat-chip-list>
        <mat-chip
          *ngFor="let keyword of keywordFilters.limitTo.keywords; let keywordIndex = index"
          (click)="editKeyword(keyword, keywordIndex, 'limitTo')"
        >
          <div
            class="truncate-on-overflow keyword-chip ch-default-blue"
            [matTooltip]="getTooltip(keyword, tooltipMaxLength)"
            matTooltipClass="ch-tooltip"
            matTooltipPosition="above"
          >
            {{ keyword }}
          </div>
          <mat-icon matChipRemove (click)="removeKeywords(keywordIndex, keyword, 'limitTo')"
            >clear</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="isKeywordsInExclude()">
      <div class="section-label"><span>Exclude</span></div>
      <mat-chip-list>
        <mat-chip
          *ngFor="let keyword of keywordFilters.exclude.keywords; let keywordIndex = index"
          (click)="editKeyword(keyword, keywordIndex, 'exclude')"
        >
          <div
            class="truncate-on-overflow keyword-chip ch-default-blue"
            [matTooltip]="getTooltip(keyword, tooltipMaxLength)"
            matTooltipClass="ch-tooltip"
            matTooltipPosition="above"
          >
            {{ keyword }}
          </div>
          <mat-icon matChipRemove (click)="removeKeywords(keywordIndex, keyword, 'exclude')"
            >clear</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div *ngIf="isEditKeywordFilter">
    <div class="save-keyword-name">
      <mat-form-field class="value-keyword-field">
        <input matInput [(ngModel)]="keyword" ch-select-on-autofocus />
      </mat-form-field>
      <div class="button-group">
        <button
          mat-icon-button
          class="ch-action-icon-hover"
          (click)="saveEditedKeyword()"
          [disabled]="!keyword"
        >
          <mat-icon
            class="action-icon grey-icon"
            matTooltip="Save"
            matTooltipPosition="above"
            matTooltipClass="ch-tooltip"
            svgIcon="ch:save-outline"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          class="ch-action-icon-hover"
          (click)="deleteKeyword()"
          [disabled]="!keyword"
        >
          <mat-icon
            class="action-icon grey-icon"
            matTooltip="Delete"
            matTooltipPosition="above"
            matTooltipClass="ch-tooltip"
          >
            delete_outline
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
