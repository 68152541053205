import { Routes } from '@angular/router';
import { NoContentComponent } from './no-content';
import { AuthorizationService } from './authorization';
import { SignOutComponent } from './authorization';
import { InformationComponent } from './shared/components/information-component';
import { TechSupportComponent } from './tech-support';
import { SynthiaLiteRoutesGuard } from './synthia-lite-routes.guard';
import { TermsSynthiaLiteComponent } from './synthia-lite/components/terms-synthia-lite/terms-synthia-lite.component';
import { RedirectRouteGuard } from './core/redirect-route.guard';
export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./authorization/authorization.module').then((m) => m.AuthorizationModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./new-home/new-home.module').then((m) => m.NewHomeModule),
    data: { preloadAfter: ['./auth/auth.module'] },
  },
  {
    path: 'information/:article',
    component: InformationComponent,
    data: { shouldReuse: true },
  },
  { path: 'tech-support', component: TechSupportComponent, data: { shouldReuse: false } },
  {
    path: 'terms',
    component: TermsSynthiaLiteComponent,
    canActivate: [SynthiaLiteRoutesGuard],
    data: { shouldReuse: false },
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    canActivate: [AuthorizationService],
    data: { shouldReuse: false },
  },
  { path: '**', component: NoContentComponent, canActivate: [RedirectRouteGuard] },
];
