import { typeAssertionBuilder } from '../../../components/utils';

export type GraphModeType = 'graph' | 'path';
export const assertGraphModeType = typeAssertionBuilder(['graph', 'path']);

export type MoleculeLabelType =
  | 'none'
  | 'brn'
  | 'cas'
  | 'name'
  | 'formula'
  | 'molWeight'
  | 'cost'
  | 'popularity'
  | 'score'
  | 'massPerGramOfTarget'
  | 'costOrPopularity';
export const assertMoleculeLabelType = typeAssertionBuilder([
  'none',
  'brn',
  'cas',
  'name',
  'formula',
  'molWeight',
  'cost',
  'popularity',
  'score',
  'massPerGramOfTarget',
  'costOrPopularity',
]);

export type ReactionLabelType = 'none' | 'year' | 'rxid' | 'reaction_id' | 'name';
export const assertReactionLabelType = typeAssertionBuilder([
  'none',
  'year',
  'rxid',
  'reaction_id',
]);

export type ReactionColorType = 'default' | 'base' | 'point';
export const assertReactionColorType = typeAssertionBuilder(['default', 'base', 'year', 'point']);

export type MoleculeColorType = 'default' | 'point';
export const assertMoleculeColorType = typeAssertionBuilder(['default', 'point']);

export type LayoutType = 'force' | 'polar' | 'dagre';
export const assertLayoutType = typeAssertionBuilder(['force', 'polar', 'dagre']);

export type EdgeColorType =
  | 'default'
  | 'nonSelectiveAndDiastereoselectiveOnly'
  | 'point'
  | 'all'
  | 'tunnelsAndStrategy';
export const assertEdgeColorType = typeAssertionBuilder(['default', 'base', 'year', 'point']);

export type MoleculeScalingType =
  | 'none'
  | 'molWeight'
  | 'cost'
  | 'popularity'
  | 'massPerGramOfTarget';
export const assertMoleculeScalingType = typeAssertionBuilder([
  'none',
  'molWeight',
  'cost',
  'popularity',
  'massPerGramOfTarget',
]);

export type PathDirectionType = 'LR' | 'RL'; // LR for left-to-right, RL for right-to-left
export const assertPathDirectionType = typeAssertionBuilder(['LR', 'RL']);
