import { ElementRef, Directive, AfterViewInit } from '@angular/core';

// Focus and select element with 'ch-select-on-autofocus' attribute on ngAfterViewInit.

@Directive({
  selector: '[ch-select-on-autofocus]',
})
export class SelectOnAutoFocusDirective implements AfterViewInit {
  constructor(public elementRef: ElementRef) {}

  public ngAfterViewInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
      this.elementRef.nativeElement.select();
    });
  }
}
