<div class="ch-uncommon-db-popup">
  <div class="popup-text">
    Note: You are leaving expert mode. Please note that any changes made to your scoring functions
    will not be transferred.
  </div>
  <div class="popup-buttons">
    <div class="popup-confirm">
      <button mat-button [buttonOutline]="'primary'" (click)="close(true)">GOT IT</button>
    </div>
    <div class="popup-cancel">
      <button mat-button (click)="close(false)">CANCEL</button>
    </div>
  </div>
  <mat-checkbox [(ngModel)]="dontShowAgain" class="popup-checkbox" color="primary"
    >Don't show again</mat-checkbox
  >
</div>
