import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, iif } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { AppConstantsService, ErrorsHandlerService, InfoService } from 'src/app/shared/services';
import { StorageHandlerService } from 'src/app/shared/services/storage-handler.service';
import { ActiveCartItem } from '../../models/ActiveCartItem';
import { ECommHandlerService } from '../../services/ecomm-handler.service';

@Component({
  selector: 'ch-cart-widget',
  templateUrl: './cart-widget.component.html',
  styleUrls: ['./cart-widget.component.scss'],
})
export class CartWidgetComponent implements OnInit, OnDestroy {
  public isOpened: boolean = false;
  public isUserValidated: string | null = 'false';
  @ViewChild('sideNav') public sideNav: MatSidenav;
  public activeCartItemList: ActiveCartItem[] = [];
  public isRemovingItems: boolean = false;
  public isGettingCheckoutUrl: boolean = false;
  public isAllItemSelected: boolean = false;
  public isWaitingUserAuth: boolean = true;
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    public storageService: StorageHandlerService,
    public eCommHandlerService: ECommHandlerService,
    private infoService: InfoService,
    private errorsHandlerService: ErrorsHandlerService,
    private appConstantsService: AppConstantsService,
  ) {}

  public ngOnInit(): void {
    this.eCommHandlerService.openedCartWidget
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        map((opened: boolean) => {
          if (opened) {
            this.isOpened = true;
            this.isUserValidated = 'false';
            this.isWaitingUserAuth = true;
          }
          return opened;
        }),
        mergeMap((opened: boolean) =>
          iif(() => opened, this.eCommHandlerService.isECommUserAuth()),
        ),
      )
      ?.subscribe(
        (result: boolean) => {
          this.isWaitingUserAuth = false;
          if (result) {
            this.isUserValidated = 'true';
          } else {
            this.activeCartItemList = [];
          }
        },
        (error) => {
          this.activeCartItemList = [];
          this.isWaitingUserAuth = false;
        },
      );

    this.eCommHandlerService.activeCartItemListSubject
      .pipe(takeUntil(this.unsubscribeFromAll))
      ?.subscribe((result) => {
        const isEqual =
          result.length === this.activeCartItemList.length &&
          result.every(
            (value, index) => value.cart_item_id === this.activeCartItemList[index].cart_item_id,
          );
        if (!isEqual) {
          const selectedItemsId = new Map<string, boolean>();
          for (const originalItem of this.activeCartItemList) {
            selectedItemsId.set(originalItem.cart_item_id, originalItem.is_selected);
          }
          this.isAllItemSelected = false;
          this.activeCartItemList = result;
          for (const currentItem of this.activeCartItemList) {
            currentItem.is_selected = selectedItemsId.get(currentItem.cart_item_id);
          }
        }
      });

    this.eCommHandlerService.updateCart?.subscribe((result: boolean) => {
      this.isAllItemSelected = this.activeCartItemList.every((item) => item.is_selected);
    });
  }

  public closeSideNav() {
    this.isOpened = false;
    this.sideNav.close();
    this.eCommHandlerService.closeECommAuthorizationDialog();
    this.eCommHandlerService.openedCartWidget.next(false);
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
    this.eCommHandlerService.updateCart?.unsubscribe();
  }

  public RemoveItems(): void {
    if (this.activeCartItemList.length === 0) {
      this.isAllItemSelected = false;
      this.infoService.showInfo('No item in list');
      return;
    }
    if (this.isAllItemSelected) {
      this.isRemovingItems = true;
      const allItemsCount = this.activeCartItemList.length;
      this.eCommHandlerService.clearCart(this.activeCartItemList[0].cart_id).subscribe(
        (result: boolean) => {
          this.isRemovingItems = false;
          if (result) {
            this.isAllItemSelected = false;
            if (allItemsCount === 1) {
              this.infoService.showInfo(
                this.appConstantsService.removeSingleItemFromShoppingListSuccess,
              );
            } else {
              this.infoService.showInfo(
                this.appConstantsService.removeMultipleItemFromShoppingListSuccess,
              );
            }
          } else {
            this.errorsHandlerService.showGlobalError(
              this.appConstantsService.removeItemFromShoppingListFailed,
            );
          }
        },
        (error) => {
          this.isRemovingItems = false;
          this.errorsHandlerService.showGlobalError(
            this.appConstantsService.removeItemFromShoppingListFailed,
          );
        },
      );
    } else {
      const selectedItemsForRemove = this.activeCartItemList.filter((item) => item.is_selected);
      if (selectedItemsForRemove.length === 0) {
        this.infoService.showInfo('Please select item(s)');
        return;
      }
      this.isRemovingItems = true;
      this.eCommHandlerService.deleteCartItems(selectedItemsForRemove).subscribe(
        (result: boolean) => {
          this.isRemovingItems = false;
          if (result) {
            if (selectedItemsForRemove.length === 1) {
              this.infoService.showInfo(
                this.appConstantsService.removeSingleItemFromShoppingListSuccess,
              );
            } else {
              this.infoService.showInfo(
                this.appConstantsService.removeMultipleItemFromShoppingListSuccess,
              );
            }
          } else {
            this.errorsHandlerService.showGlobalError(
              this.appConstantsService.removeItemFromShoppingListFailed,
            );
          }
        },
        (error) => {
          this.isRemovingItems = false;
          this.errorsHandlerService.showGlobalError(
            this.appConstantsService.removeItemFromShoppingListFailed,
          );
        },
      );
    }
  }

  public checkOut(): void {
    this.isGettingCheckoutUrl = true;
    this.eCommHandlerService.getCheckOutUrl().subscribe(
      (result: string) => {
        this.isGettingCheckoutUrl = false;
        if (result) {
          window.open(result, '_blank');
        } else {
          this.errorsHandlerService.showGlobalError('Redirect failed');
        }
      },
      (error) => {
        this.isGettingCheckoutUrl = false;
        this.errorsHandlerService.showGlobalError('Redirect failed');
      },
    );
  }

  public onCheckBoxChange(event): void {
    this.activeCartItemList.map((item) => (item.is_selected = event.checked));
  }

  public exportShoppingList(): void {
    if (this.activeCartItemList.length === 0) {
      this.infoService.showInfo('No item in list');
      return;
    }
    const selectedItemsForExport = this.activeCartItemList.filter((item) => item.is_selected);
    if (selectedItemsForExport.length === 0) {
      this.infoService.showInfo('Please select item(s)');
      return;
    }
    this.eCommHandlerService.getFile(selectedItemsForExport);
  }
}
