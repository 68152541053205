<mat-expansion-panel
  #expansionPanel
  class="molecule-card"
  [class.card-selected]="molecule.moleculeSelected"
  [class.is-collapsed]="!isMoleculeExpanded"
  hideToggle
  [expanded]="isMoleculeExpanded"
  [disabled]="true"
>
  <mat-expansion-panel-header class="molecule-header">
    <div
      class="list-molecule-card-title"
      [matTooltip]="getNameTooltip()"
      matTooltipClass="ch-tooltip full-width-tooltip"
      matTooltipPosition="below"
      appShowIfTruncated
    >
      <strong *ngIf="!isTargetViewLibrary" class="ch-default-blue">{{ getMoleculeName() }}</strong>
      <span *ngIf="isTargetViewLibrary" class="normal-text-view">{{ getMoleculeName() }}</span>
    </div>
    <div class="action-buttons">
      <button
        mat-icon-button
        *ngIf="!molecule.moleculeExpanded"
        (click)="expandCard($event)"
        class="delete-icon ch-action-icon-hover"
      >
        <mat-icon
          class="grey-icon default-icon"
          [matTooltip]="getExpandTooltip()"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
          [svgIcon]="isMoleculeExpanded ? 'ch:shrink' : 'ch:expand'"
        ></mat-icon>
      </button>
      <button
        class="copy-smiles ch-action-icon-hover"
        mat-icon-button
        (click)="onSmilesClick($event)"
      >
        <mat-icon
          [ngClass]="smilesCopied ? 'ch-clr-ok' : 'grey-icon default-icon'"
          [matTooltip]="getCopyTooltip()"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
        >
          {{ smilesCopied ? 'check_circle' : 'content_copy' }}
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="isCustomList"
        (click)="deleteMolecule($event)"
        class="delete-icon ch-action-icon-hover"
      >
        <mat-icon
          class="grey-icon default-icon"
          [matTooltip]="deleteMoleculeTooltip"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
          >delete_outline</mat-icon
        >
      </button>
    </div>
  </mat-expansion-panel-header>
  <ch-molecule-image
    *ngIf="isMoleculeExpanded"
    class="ch-molecule-card-image"
    [formula]="formulaValueToUse"
    [viewType]="viewType"
    [inputFormat]="inputFormatToUse"
    [width]="imageWidth"
    [height]="imageHeight"
  >
  </ch-molecule-image>
  <div class="bottom-button-container" *ngIf="isMoleculeExpanded">
    <button
      mat-icon-button
      *ngIf="isCustomList"
      (click)="editMolecule($event)"
      class="marvin-editor-icon ch-action-icon-hover"
    >
      <mat-icon
        class="card-svg-icon"
        [matTooltip]="editMoleculeTooltip"
        matTooltipPosition="above"
        matTooltipClass="ch-tooltip"
        svgIcon="ch:benzene"
      ></mat-icon>
    </button>
  </div>
</mat-expansion-panel>
