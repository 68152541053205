import { trigger, style, transition, animate, keyframes } from '@angular/animations';

export const FADE_OUT_ANIMATION = trigger('fadeOutAnimation', [
  transition('* => void', [
    style({ opacity: '1' }),
    animate(
      '0.18s',
      keyframes([
        style({ opacity: '1' }),
        style({ opacity: '0.85' }),
        style({ opacity: '0.7' }),
        style({ opacity: '0.35' }),
        style({ opacity: '0' }),
      ]),
    ),
  ]),
]);
