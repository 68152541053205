<div class="molecule-viewer">
  <div *ngIf="loading && !error" class="ch-molecule-3d-viewer-loading">
    <mat-spinner [diameter]="50" [strokeWidth]="2"></mat-spinner>
  </div>

  <div *ngIf="error" class="ch-molecule-3d-viewer-error ch-clr-grey-strong ch-bg-grey-light">
    <table width="100%">
      <tr align="center">
        <td>
          <mat-icon>error_outline</mat-icon>
        </td>
      </tr>
      <tr align="center">
        <td>
          <span class="ch-molecule-3d-viewer-error-message ch-clr-grey-strong">{{
            errorMessage
          }}</span>
        </td>
      </tr>
    </table>
  </div>

  <div
    class="ch-molecule-3d-viewer-widget-wrapper"
    [style.display]="!error ? 'flex' : 'hidden'"
    [style.width]="'100%'"
    [style.height]="'100%'"
    #kekule3DViewerWrapper
  ></div>
</div>
