<div class="ch-training-dialog">
  <div class="training-title">
    <span
      >Welcome to SYNTHIA<sup class="ch-trade-mark fs-28-px">TM</sup>
      <span *ngIf="isSynthiaLiteEnabled">Lite</span></span
    >
  </div>

  <img src="../assets/img/welcome-screen.svg" />

  <div class="training-question">
    Would you like to visit the Learning Center, where you can learn how to set up to a New
    Automatic Retrosynthesis Analysis?
  </div>

  <div class="training-controls">
    <div>
      <button
        mat-button
        class="section-button ch-outline-btn"
        [buttonOutline]="'primary'"
        (click)="closeTutorial()"
      >
        Not now, thanks
      </button>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="startTutorial()">
        Take me to the Learning Center
      </button>
    </div>
  </div>
  <div>
    You can also click the <strong>Support</strong> button to visit the Learning Center any time.
  </div>
  <div class="dont-show-again">
    <mat-checkbox
      color="primary"
      [(ngModel)]="hidePopupForUser"
      (ngModelChange)="checkToHideTutorial()"
    >
      Please don’t show me this option again
    </mat-checkbox>
  </div>
</div>
