import { isString } from '../../../components/utils';

export class AnalysisStateEntry {
  /* tslint:disable:variable-name */
  public graph_hash: string = '';
  public computations_state_hash: string = '';
  public computations_name_hash: string = '';
  public progress_item: string = '';
  public can_stop_analysis_state_polling: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.error(
            `Unexpected type of field '${field}' in data provided to AnalysisStateEntry:ctor.` +
              `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
          );
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to AnalysisEntry:ctor.`);
      }
    }
  }
}
