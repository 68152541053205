import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ChematicaRoutingStrategy implements RouteReuseStrategy {
  public handlers: { [key: string]: DetachedRouteHandle } = {};

  // those are custom guards which help determine if route should be reused
  private signedOut: boolean = false;
  private samePageReload: boolean = false;
  private allowCase1: boolean = false;

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!!route.data.isHomeComponent) {
      return !!route.data.shouldReuse && !this.signedOut && this.samePageReload;
    } else {
      return !!route.data.shouldReuse && !this.signedOut && !this.samePageReload && this.allowCase1;
    }
  }

  public store(route: ActivatedRouteSnapshot, handle: {}): void {
    if (route.data.shouldReuse) {
      this.handlers[route.routeConfig.path] = handle;
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  public retrieve(route: ActivatedRouteSnapshot): {} {
    if (!route.routeConfig) {
      return null;
    }
    return this.handlers[route.routeConfig.path];
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot,
  ): boolean {
    this.assignCustomGuards(future, current);
    return !!future.data.shouldReuse;
  }

  private assignCustomGuards(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot,
  ): void {
    if (future.firstChild && current.firstChild) {
      const futureConfig = future.firstChild.routeConfig;
      const currentConfig = current.firstChild.routeConfig;
      this.signedOut = futureConfig.path === 'sign-in' || futureConfig.path === 'sign-out';
      this.samePageReload = futureConfig.path === currentConfig.path;
      // case 1: only reuse /new or /rerun when going back from element sets
      this.allowCase1 =
        currentConfig.path.startsWith('new') || currentConfig.path.startsWith('rerun')
          ? futureConfig.path.startsWith('sets/')
          : false;
    }
    if (this.signedOut) {
      for (const handle of Object.keys(this.handlers)) {
        try {
          (this.handlers[handle] as any).componentRef.destroy();
          delete this.handlers[handle];
        } catch (error) {
          console.log('Attempted to destroy component but failed. ' + error);
        }
      }
    }
  }
}
