<div
  [@fadeInAnimation]
  (mouseenter)="popupService.isCursorWithinPopupBounds = true"
  (mouseleave)="popupService.isCursorWithinPopupBounds = false"
>
  <div class="ch-graph-node-popup">
    <div class="header-container">
      <div class="main-header">
        <div
          class="title truncate-on-overflow ch-default-blue"
          [matTooltip]="nodeName"
          matTooltipClass="ch-tooltip full-width-tooltip"
          appShowIfTruncated
        >
          {{ nodeName }}
        </div>
        <button
          class="ch-action-icon-hover vert-icon"
          mat-icon-button
          [matMenuTriggerFor]="menuNode"
          (menuOpened)="menuOpened()"
          (menuClosed)="menuClosed()"
        >
          <mat-icon class="grey-icon">more_vert</mat-icon>
        </button>
      </div>
      <div class="sub-header" *ngIf="!isReaction">
        <span
          class="truncate-on-overflow"
          [matTooltip]="node.molecule.smiles"
          matTooltipClass="ch-tooltip dialog-width-tooltip"
          appShowIfTruncated
          >{{ node.molecule.smiles }}</span
        >
      </div>
    </div>
    <div class="image-container">
      <ch-molecule-image
        #moleculeImage
        [minWidth]="350"
        [width]="350"
        [height]="nodeImageHeight"
        [formula]="nodeImageFormula"
        [hostElementHovered]="popupService.isCursorWithinPopupBounds"
        [formulaType]="nodeType"
        [allowStructureCopying]="false"
      >
      </ch-molecule-image>
    </div>
    <div class="bottom-container">
      <button
        class="node-popup-select-btn"
        *ngIf="isPathSelectable"
        [matTooltip]="selectPathTooltip"
        matTooltipShowDelay="750"
        matTooltipPosition="after"
        matTooltipClass="ch-tooltip"
        mat-button
        (click)="selectPathway()"
      >
        <mat-icon>timeline</mat-icon>
      </button>

      <ch-node-warning
        [node]="node"
        [showLabel]="false"
        class="warning-icon-container"
      ></ch-node-warning>
    </div>
  </div>
  <mat-menu #menuNode="matMenu" class="graph-node-menu">
    <ch-new-graph-node-menu
      [node]="node"
      [moleculeImageComponent]="moleculeImageComponent"
      [parentClass]="'node-popup'"
      [algorithmType]="algorithm"
      (closeMenuInstance)="closeMenuInstance()"
    >
    </ch-new-graph-node-menu>
  </mat-menu>
</div>
