import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public trueActionName: string = 'OK';
  public falseActionName: string = 'CANCEL';
  public showFalseAction: boolean = true;
  public newUiStyle: boolean = false;
  public switchButtonsPriority: boolean = false;
  public showCheckbox: boolean = false;
  public checkboxMessage: string;
  public confirmationCheckbox: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
