import { BehaviorSubject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

export interface IViewportVisibilityServiceRegistryObject {
  targets: Element[];
  rootElement: Element;
  observer: IntersectionObserver;
}

@Injectable()
export class ViewportVisibilityService {
  private static getRootElement(element: Element): Element | null {
    return element && element.nodeType === Node.ELEMENT_NODE ? element : null;
  }

  public registry: IViewportVisibilityServiceRegistryObject[];
  public serviceTrigger: BehaviorSubject<IntersectionObserverEntry[]>;
  public trigger: EventEmitter<IntersectionObserverEntry[]>;

  constructor() {
    this.serviceTrigger = new BehaviorSubject<IntersectionObserverEntry[]>([]);
    this.registry = [];
  }

  public addTarget(target: Element, rootElement?: Element): void {
    let registryEntry = this.findRegistryEntry(rootElement);
    if (!registryEntry) {
      const registryEntryObserverOptions: IntersectionObserverInit = {
        root: ViewportVisibilityService.getRootElement(rootElement),
        threshold: Array(101)
          .fill(void 0)
          .map((item, i) => i / 100),
      };
      registryEntry = {
        targets: [target],
        rootElement: ViewportVisibilityService.getRootElement(rootElement),
        observer: new IntersectionObserver(
          (entries: IntersectionObserverEntry[]) => this.serviceTrigger.next(entries),
          registryEntryObserverOptions,
        ),
      };
      registryEntry.observer.observe(target);
      this.registry.push(registryEntry);
    } else if (registryEntry.targets.indexOf(target) < 0) {
      registryEntry.targets.push(target);
      registryEntry.observer.observe(target);
    }
  }

  public removeTarget(target: Element, rootElement?: Element): void {
    const registryEntry = this.findRegistryEntry(rootElement);
    const registryEntryIdx = this.registry.indexOf(registryEntry);
    if (registryEntry) {
      const targetIdx = registryEntry.targets.indexOf(target);
      if (targetIdx >= 0) {
        registryEntry.observer.unobserve(target);
        registryEntry.targets.splice(targetIdx, 1);
      }
      if (registryEntry.targets.length === 0) {
        registryEntry.observer.disconnect();
        this.registry.splice(registryEntryIdx, 1);
      }
    }
  }

  public findRegistryEntry(rootElement: Element) {
    const root = ViewportVisibilityService.getRootElement(rootElement);
    return this.registry.find((item) => item.rootElement === root);
  }
}
