<div
  [class.self-registration-country]="formType === formTypes.SelfRegistration"
  [class.lite-registration-country]="formType === formTypes.LiteRegistration"
>
  <span *ngIf="formType === formTypes.LiteRegistration">Country<sup>*</sup></span>
  <mat-form-field
    appearance="outline"
    class="registration-inputs ch-form-field ch-text-field"
    [class.form-input-error]="
      formType === formTypes.SelfRegistration
        ? countryFieldValidationProperties.errorMessages.length > 0
        : isFieldInvalid
    "
  >
    <mat-select
      [placeholder]="isChinaRegion ? '选择国家' : 'Select country'"
      [formControl]="countryFormControl"
      (selectionChange)="onCountryChange($event.value)"
    >
      <mat-option *ngFor="let country of countries" [value]="country">
        {{ country.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="loadingValues">
  <ch-loader></ch-loader>
</div>
