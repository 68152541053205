import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalysisService, ResultsView } from '../../services';
import { MoleculeNodeTagType } from '../../services/graph-builder';

@Component({
  selector: 'ch-graph-key',
  styleUrls: ['./graph-key.component.scss'],
  templateUrl: './graph-key.component.html',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', animate('100ms')),
      transition('* => void', animate('100ms')),
    ]),
  ],
})
export class GraphKeyComponent implements OnInit {
  public readonly moleculeNodeTagType = MoleculeNodeTagType;
  @Input() public showOnlyMoleculeRelatedTags: boolean = false;
  @Input() public moleculeRelatedTags: MoleculeNodeTagType[] = [];
  public isPathView: boolean = false;
  public enableLegendView: boolean = false;
  public unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(public analysisService: AnalysisService) {}

  public showTag(tag: MoleculeNodeTagType) {
    if (!this.showOnlyMoleculeRelatedTags) {
      return true;
    }
    return this.moleculeRelatedTags.indexOf(tag) > -1;
  }

  public ngOnInit(): void {
    this.analysisService.analysisSettingsBehaviorSubjects.mode
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((mode) => {
        this.isPathView = mode === ResultsView.PATHWAYS;
        this.enableLegendView = false;
        setTimeout(() => {
          this.enableLegendView = true;
        }, 50);
      });
  }
}
