import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ManagedUser } from './models/managed-user';
import { AppConstantsService } from '../app-constants/app-constants.service';
import {
  ErrorsHandlerService,
  ParsedConnectionError,
} from '../errors-handler/errors-handler.service';
import { InfoService } from '../info.service';

export const BackendEntryPointUserManagement =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/user-management/';
export const BackendEntryPointUser = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/users/';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(
    public infoService: InfoService,
    public errorsHandlerService: ErrorsHandlerService,
    public appConstantsService: AppConstantsService,
    private http: HttpClient,
  ) {}

  public getManagedUsersList(): Observable<ManagedUser[]> {
    return this.http.get(BackendEntryPointUserManagement, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((user) => new ManagedUser(user));
        } catch (e) {
          throw new Error('Unexpected format of managed user list response.');
        }
      }),
    );
  }

  public userManagementApiCallError(error: HttpErrorResponse) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandlerService.logout();
    } else if (parsedError.bodyJson.code === 'permission_denied') {
      this.infoService.showError(parsedError.promptMessage);
    } else if (parsedError.isRecognized()) {
      this.errorsHandlerService.showGlobalError(parsedError.promptMessage);
    } else {
      this.infoService.showError(this.appConstantsService.somethingWentWrongError);
    }
  }

  public getUserDetail(userId: number): Observable<ManagedUser> {
    const apiUrl: string = BackendEntryPointUserManagement + userId + '/';

    return this.http.get(apiUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return new ManagedUser(responseJson);
        } catch (e) {
          throw new Error('Unexpected format of managed user response.');
        }
      }),
    );
  }

  public updateUser(userId: number, dataToUpdate: { [key: string]: any }): Observable<ManagedUser> {
    const apiUrl: string = BackendEntryPointUserManagement + userId + '/';
    return this.http.patch(apiUrl, dataToUpdate, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return new ManagedUser(responseJson);
        } catch (e) {
          throw new Error('Unexpected format of managed user response.');
        }
      }),
    );
  }

  public deleteUser(userId: number) {
    const apiUrl: string = BackendEntryPointUser + userId + '/';
    return this.http.delete(apiUrl, { observe: 'response' }).pipe(map((result) => result));
  }
}
