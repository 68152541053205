import { Component, Inject, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendPrintService, IBackendPrintToPdfContent } from '../backend-print.service';
import { autodownload } from '../../components/utils';
import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { InfoService } from '../info.service';
import { flatMap, map, tap } from 'rxjs/operators';

@Component({
  selector: 'ch-pdf-export-dialog',
  templateUrl: './pdf-export-dialog.component.html',
  styleUrls: ['./pdf-export-dialog.component.scss'],
})
export class PdfExportDialogComponent implements OnInit {
  // Dialog size relative to the app window
  public static defaultDialogWidthPercent = 95;
  public static defaultDialogHeightPercent = 90;

  public title: string = 'Export to PDF';
  public readyToExport: boolean = false;
  public headerHeight: number = 32;
  public contentWidth: number = 100;
  public contentHeight: number = 100;
  public footerHeight: number = 48;

  @ViewChild(CdkPortalOutlet, { static: true }) private contentPortalHost: any;
  private contentComponent: any;

  constructor(
    public dialogRef: MatDialogRef<PdfExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private backendPrintService: BackendPrintService,
    private infoService: InfoService,
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.adjustSizes(event.target.innerWidth, event.target.innerHeight);
  }

  public adjustSizes(width: number, height: number) {
    const size = {
      width: (width * PdfExportDialogComponent.defaultDialogWidthPercent) / 100,
      height: (height * PdfExportDialogComponent.defaultDialogHeightPercent) / 100,
    };
    this.dialogRef.updateSize(`${size.width}px`, `${size.height}px`);
    this.contentWidth = size.width - 48; // 2 x 24px for padding used in materials dialog wrapper style
    // With Materials 6 style class cdk-overlay-pane limit the max-height to 90% of parent. Assuming that
    // this new 90% limit is required by some other design constraints we don't override styles to 100%
    // but just apply the limit here.
    this.contentHeight = 0.9 * (size.height - 48 - this.headerHeight - this.footerHeight);
    if (this.contentComponent.onContentResize) {
      this.contentComponent.onContentResize(this.contentWidth, this.contentHeight);
    }
  }

  public ngOnInit() {
    // FIXME: Update when Materials 6 becomes available in our codebase.
    // Usually a portal is set to the host in template <ng-template [cdkPortalHost]="contentComponentPortal" /> but here
    // we need also an access to the component created. It can be done easily in materials > 6 where cdkPortalHost
    // supports "attached" event and emits created component ref. For now we have to attach the portal manually.
    this.data.host = this;
    const contentComponentPortal = new ComponentPortal(this.data.contentComponent);
    // Note 1: As contentComponent is expected to be created the way a regular material dialog content component
    //       one  might expect that we should provide an injector for MAT_DIALOG_DATA below to attachment procedure
    //       which creates the component. We do not need it however.
    //       As PortalHost by default uses it's parent injector and all of the data for content component are directly
    //       included in this.data parameter (see pdf-export-dialog.service) all works as expected.
    this.contentComponent = this.contentPortalHost.attachComponentPortal(
      contentComponentPortal,
    ).instance;
    // resize to available area
    this.adjustSizes(window.innerWidth, window.innerHeight);
  }

  public isReadyToExport() {
    return this.contentComponent.allowExport;
  }

  public export() {
    if (!this.contentComponent.getPdfContent) {
      this.dialogRef.close();
      throw Error(
        'Missing implementation of IPdfContentProducer interface in content component. Nothing to export.',
      );
    }

    this.contentComponent
      .getPdfContent()
      .pipe(
        tap(
          () => {
            this.infoService.showInfo('Data collected, creating PDF...');
          },
          (_error) => {
            this.infoService.showError('Data collecting fail. PDF export aborted');
          },
        ),
        flatMap((pdfContent: IBackendPrintToPdfContent) =>
          this.backendPrintService.printToPdf(pdfContent),
        ),
        map((pdfDataBlob: any) => {
          const pdfDataUrl = window.URL.createObjectURL(pdfDataBlob);
          const fileName = this.data.pdfName ? this.data.pdfName : `Synthia analysis.pdf`;
          autodownload(pdfDataUrl, fileName);
          return true;
        }),
      )
      .subscribe();

    this.infoService.showInfo('Collecting data to export to PDF...');
    this.dialogRef.close();
  }
}
