import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { AnalysisService } from '../../services/analysis';
import { takeUntil } from 'rxjs/operators';
import { Tag } from '../../services/models/tag';
import { AppConstantsService, ErrorsHandlerService, InfoService } from '../../services';
import {
  AuthTokenStorageName,
  jwtHelperService,
} from '../../../authorization/authorization.service';
import {
  ConfirmDialogConfiguration,
  ConfirmDialogService,
} from '../../services/confirm-dialog.service';

@Component({
  selector: 'ch-tag-manager',
  templateUrl: './tag-manager.component.html',
  styleUrls: ['./tag-manager.component.scss'],
})
export class TagManagerComponent implements OnInit, OnDestroy {
  public getTagSubscription: Subscription;
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public tags: Tag[];
  public filteredTags: Tag[];
  public tagSelected: string;
  public isAddEnabled: boolean = false;
  public analysisTags: any;
  public newAnalysisTag: any;
  public editedTagId: number;
  public tagInput: string = '';
  public editedTagName: string = '';
  public isEditEnabled: boolean = false;
  public isSaveEnabled: boolean = false;
  public updatedTags = [];
  public tagMaxLength = 36;
  public deleteConfirmationMessage: string = '';
  public isTagLoading: boolean = false;
  public editedTagIndex: number;
  constructor(
    public dialogRef: MatDialogRef<TagManagerComponent>,
    public analysisService: AnalysisService,
    public appConstantsService: AppConstantsService,
    private infoService: InfoService,
    public confirmDialogService: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public analysis: any,
    private errorsHandler: ErrorsHandlerService,
  ) {}
  public ngOnInit() {
    this.getTags();
  }

  public getTags() {
    this.isTagLoading = true;
    this.getTagSubscription = this.analysisService
      .getTags()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (tag: Tag[]) => {
          this.tags = tag;
          if (this.analysis !== null) {
            this.analysisTags = this.analysis.tags;
          } else {
            this.analysisTags = tag;
          }
          this.filterTags(tag);
        },
        (error) => {
          this.infoService.showInfo(this.appConstantsService.tagListingError);
        },
      );
  }

  public showFilteredTags(value: string) {
    this.tagSelected = value.toLowerCase().trim();
    this.filteredTags = [];
    if (this.tagSelected.length > 0) {
      this.isAddEnabled = true;
      this.filteredTags = this.tags.filter((tag) =>
        tag.name.toLowerCase().includes(String(value).toLowerCase()),
      );
    } else {
      this.isAddEnabled = false;
    }
  }

  public setTagOption(tag: Tag) {
    this.newAnalysisTag = tag;
    this.isAddEnabled = true;
  }

  public addAnalysisTag() {
    const newTagAdded = this.tagInput.toLowerCase().trim();
    if (newTagAdded.length > 0) {
      this.isSaveEnabled = true;
      const userTagExists = this.tags.filter(
        (tag) => tag.name.toLowerCase() === this.tagInput.toLowerCase(),
      );
      if (this.newAnalysisTag !== undefined) {
        if (this.newAnalysisTag.name !== this.tagInput) {
          const newUserTag: Tag = {
            name: this.tagInput,
            id: userTagExists.length > 0 ? userTagExists[0].id : null,
            analyses: this.analysis && this.analysis.id ? [this.analysis.id] : null,
            user: jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName))
              ? jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName)).user_id
              : 0,
            checked: false,
          };
          this.newAnalysisTag = newUserTag;
        }
        this.analysisTags?.push(this.newAnalysisTag);
        const index = this.filteredTags?.indexOf(this.newAnalysisTag);
        if (index >= 0) {
          this.filteredTags?.splice(index, 1);
        }
        this.tagInput = '';
        this.filterTags(this.tags);
      } else {
        const tagExists = this.analysisTags?.filter(
          (tag) => tag.name.toLowerCase() === this.tagInput.toLowerCase(),
        );
        if (tagExists.length === 0) {
          const newUserTag: Tag = {
            name: this.tagInput,
            id: userTagExists.length > 0 ? userTagExists[0].id : null,
            analyses: this.analysis && this.analysis.id ? [this.analysis.id] : null,
            user: jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName))
              ? jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName)).user_id
              : 0,
            checked: false,
          };
          this.analysisTags?.push(newUserTag);
        }
        this.tagInput = '';
      }
    }
    this.newAnalysisTag = undefined;
    this.isAddEnabled = false;
  }

  public filterTags(tag: Tag[]) {
    let analysisTags = this.tags;
    if (this.analysis !== null) {
      analysisTags = this.analysis.tags;
    }
    const analysisIds = new Set(analysisTags?.map(({ id }) => id));
    this.tags = tag.filter(({ id }) => !analysisIds.has(id));
    this.isTagLoading = false;
  }

  public removeAnalysisTag(tag: Tag) {
    this.isSaveEnabled = true;
    const index = this.analysisTags?.indexOf(tag);
    if (index >= 0) {
      this.analysisTags.splice(index, 1);
    }
    if (this.analysis !== null) {
      this.analysis.tags = this.analysisTags;
      this.getTags();
    }
  }

  public editAnalysisTag(tag: Tag, i: number) {
    this.isEditEnabled = true;
    this.editedTagId = tag.id;
    this.editedTagName = tag.name;
    this.editedTagIndex = i;
  }

  public saveEditedTag() {
    const tagExists = this.analysisTags.filter((tag) => tag.name === this.editedTagName);
    if (tagExists.length === 0) {
      if (this.analysis !== null) {
        const editedTag = this.editedTagName.toLowerCase().trim();
        if (editedTag.length > 0) {
          this.isSaveEnabled = true;
          this.analysisTags[this.editedTagIndex]['name'] = this.editedTagName;
        }
      } else {
        this.editTagConfirm(this.editedTagIndex);
      }
    }
    this.isEditEnabled = false;
  }

  public deleteTag(tag: Tag) {
    if (this.analysis !== null) {
      this.deleteConfirmationMessage = this.appConstantsService.replaceVariableFromMessage(
        this.appConstantsService.deleteTagConfimationMessage,
        '<tag>',
        tag.name,
      );
      this.deleteConfirmationMessage = this.appConstantsService.replaceVariableFromMessage(
        this.deleteConfirmationMessage,
        '<analysisName>',
        this.analysis.name,
      );
    } else {
      this.deleteConfirmationMessage = this.appConstantsService.replaceVariableFromMessage(
        this.appConstantsService.deleteUserTagConfimationMessage,
        '<tag>',
        tag.name,
      );
    }
    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: 'Delete Tag',
      message: this.deleteConfirmationMessage,
      trueActionName: 'DELETE',
    };

    this.confirmDialogService
      .confirm(dialogConfiguration)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((confirm) => {
        if (confirm) {
          this.removeAnalysisTag(tag);
        }
      });
  }

  public saveAnalysisTags() {
    this.isSaveEnabled = false;
    this.updatedTags = this.analysisTags.map((updatedTag: Tag[]) => {
      return {
        name: updatedTag['name'],
        id: updatedTag['id'],
      };
    });
    const analysisToSend = {
      analysis: this.analysis && this.analysis.id ? this.analysis.id : null,
      user: jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName))
        ? jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName)).user_id
        : 0,
      tags: this.updatedTags,
    };
    this.analysisService
      .saveAnalysisTags(analysisToSend)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (result) => {
          if (result.status === 201) {
            this.analysisService.isUserTagsChanged.next(true);
            if (this.analysis !== null) {
              this.analysisService.setTagManagerId(this.analysis.id);
              this.infoService.showInfo(this.appConstantsService.analysisTagsUpdateMessage);
            } else {
              this.infoService.showInfo(this.appConstantsService.userTagsUpdateMessage);
            }
            this.dialogRef.close();
          }
        },
        (error) => {
          if (error.status === 400) {
            this.errorsHandler.showGlobalError(error.error.errors[0].message);
          }
        },
      );
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public closeEditedTag(tag: Tag) {
    this.isEditEnabled = false;
  }

  public handleKeyEvents(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.saveEditedTag();
        break;
      default:
        event.stopPropagation();
    }
  }

  public truncateName(name: string) {
    if (name) {
      return name.length > this.tagMaxLength ? name.substring(0, this.tagMaxLength) + '...' : name;
    }
  }

  public editTagConfirm(tagIndex: number) {
    let confirmationMessage: string = this.appConstantsService.replaceVariableFromMessage(
      this.appConstantsService.editUserTagConfimationMessage,
      '<oldTag>',
      this.analysisTags[tagIndex]['name'],
    );
    confirmationMessage = this.appConstantsService.replaceVariableFromMessage(
      confirmationMessage,
      '<newTag>',
      this.editedTagName,
    );

    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: 'Rename Tag',
      message: confirmationMessage,
      trueActionName: 'RENAME',
    };

    this.confirmDialogService
      .confirm(dialogConfiguration)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((confirm) => {
        if (confirm) {
          const editedAnalysisTag = this.editedTagName.toLowerCase().trim();
          if (editedAnalysisTag.length > 0) {
            this.isSaveEnabled = true;
            this.analysisTags[tagIndex]['name'] = this.editedTagName;
          }
        }
      });
  }
}
