import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'ch-search-bar',
  styleUrls: ['./search-bar.component.scss'],
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent {
  @Input() public searchLoading: boolean = false;
  @Input() public searchTerm: string = '';
  @Input() public noResults: boolean = false;
  @Input() public noResultsMessage: string = 'No result matches criteria';
  @Input() public searchButtonTooltip: string = 'Start search';

  @Output() public onSearch = new EventEmitter();
  @Output() public onInput = new EventEmitter();
  @Output() public onChanges = new EventEmitter();

  @ViewChild('input') private input;

  public onConfirm(event?: any) {
    if (event) {
      this.searchTerm = event.target.value.trim();
    } else {
      this.searchTerm = this.searchTerm.trim();
    }
    if (this.searchTerm.length > 0) {
      const element = this.input.nativeElement;
      this.onSearch.emit(this.searchTerm);
      element.blur();
    }
  }
}
