import { Directive, ElementRef, OnInit, Input, Inject } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { InjectionToken } from '@angular/core';

export const STRING = new InjectionToken<string>('string');

@Directive({
  selector: '[buttonOutline]',
  providers: [{ provide: STRING, useValue: '' }],
})
export class ButtonOutlineDirective implements OnInit {
  @Input() public buttonOutline: string;

  constructor(
    private elementRef: ElementRef,
    _focusMonitor: FocusMonitor,
    @Inject(STRING) _animationMode: string,
  ) {}

  public ngOnInit() {
    switch (this.buttonOutline) {
      case 'primary':
        this.elementRef.nativeElement.className += ' outline-primary';
        break;
      case 'secondary':
        this.elementRef.nativeElement.className += ' outline-secondary';
        break;
      case 'accent':
        this.elementRef.nativeElement.className += ' outline-accent';
        break;
      case 'warn':
        this.elementRef.nativeElement.className += ' outline-warn';
        break;
      case 'default-grey':
        this.elementRef.nativeElement.className += ' outline-default-grey';
        break;
      default:
        break;
    }
  }
}
