import { isString } from '../../../components/utils';

export class SideReactionsEntry {
  public smiles: string = '';
  public rxid: number = 0;
  public name: string = '';

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (this.isValidType(jsonData[field], field)) {
          this[field] = jsonData[field];
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to SimilarReactionsEntry:ctor.`,
        );
      }
    }
  }

  private isValidType(jsonDataField: any, field: any) {
    if (typeof jsonDataField === typeof this[field]) {
      return true;
    } else {
      // FIXME: Fallback to default is temporal only -- change to exception throwing when backend data become robust
      console.error(
        `Unexpected type of field '${field}' in data provided to ComputationEntry:ctor.` +
          `\nUsed default value instead `,
        jsonDataField,
      );
      return false;
    }
  }
}
