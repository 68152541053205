import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { catchError, switchMap, takeUntil, flatMap } from 'rxjs/operators';
import { Subject, EMPTY, of, empty } from 'rxjs';
import { BreadcrumbService, BreadCrumb } from '../../services/breadcrumb/breadcrumb.service';
import {
  getTooltip,
  IProgressItem,
  isDisableFeature,
  parseProgressItem,
} from '../../components/utils';
import {
  AnalysisEntry,
  AnalysisResultsService,
  AnalysisService,
  AppConstantsService,
  ConfirmDialogService,
  DialogService,
  ErrorsHandlerService,
  InfoService,
  ParsedConnectionError,
  ResultsView,
  SocialService,
  ChematicaRouteStateService,
  SortOptions,
  ComputationEntry,
  ComputationState,
  AnalysisAlgorithm,
  CommentDialogService,
  DashboardAnalysisEntry,
} from '../../services';
import { TargetMoleculeSelectionService } from '../../services/target-molecule-selection/target-molecule-selection.service';
import { HttpResponse } from '@angular/common/http';
import { isNull, isNullOrUndefined } from '../../components/utils';
import { RING_ANIMATION } from '../../animations';
import { AuthorizationService } from 'src/app/authorization';
import { StorageHandlerService } from '../../services/storage-handler.service';
import { ConfirmDialogConfiguration } from '../../services/confirm-dialog.service';
import { ExpandedPathLabels } from '../../services/models/frontend-storage';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TargetViewDialogComponent } from 'src/app/home-analysis-card/target-view-dialog/target-view-dialog.component';
import { LibraryTargetsEntry } from '../../services/analysis/models/library-targets';

export enum PathwaysOptions {
  REPEATED_REACTIONS = 'Highlight repeated reactions',
  REPEATED_MOLECULES = 'Highlight repeated molecules',
  REACTION_NAME = 'Reaction Names',
  TYPICAL_CONDITIONS = 'Typical Conditions',
  LEGEND_TAGS = 'Legend Tags',
  PUBLISHED_REACTION = 'Published Reaction References',
}

enum nodeLabelTypes {
  repeatedReaction,
  repeatedMolecule,
  reactionName,
  typicalConditions,
  legendTags,
  publishedReferences,
}

@Component({
  selector: 'ch-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  animations: [RING_ANIMATION],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumb: BreadCrumb[] = [];
  public maxBreadCrumbLength: number = 15;
  public getTooltip = getTooltip;
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public analysis: AnalysisEntry = null;
  public disableFavoriting: boolean = false;
  public newResults: boolean = false;
  public processingOfNewResults: boolean = false;
  public loadingAnalysis: boolean = false;
  public resultsLoadingIndicator: boolean = false;
  public didSelectPath: boolean = false;
  public hasComputationError: boolean = false;
  public mode: ResultsView;
  public isNewResultsAvailable: boolean = false;
  public isFavoritingAllowed: boolean = false;
  public isShared: boolean = false;
  public isFavorited: boolean = false;
  public disableShareButton: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public disabledShareTooltip: string = this.appConstantsService.shareTooltip;
  public ringAnimationState: string = 'active';
  public rerunTooltip: string = this.appConstantsService.shareTooltip;
  public ringAnimationTimeout: any;
  public isRenameClicked: boolean = false;
  public analysisRename: string;
  public hasWarnings: boolean = false;
  public isRerunDisabledForNoc: boolean = false;
  public isAllPathwaysLoaded: boolean = false;
  public readonly ResultsView = ResultsView;
  public isComputationActive: boolean = false;
  public breadcrumbIcon: boolean = false;
  public showMoreIcons: boolean = false;
  public showHeaderLabels: boolean = false;
  public showPathsCount: boolean = false;
  public isFilterApplied: boolean = false;
  public progressItemSummary: IProgressItem = {
    iterations: '',
    paths_found: '',
  };
  public filteredResultsLength: number = 0;
  public diversityTarget: number = 0;
  public isAllPathLoaded: boolean = false;
  public isFirstChunkLoaded: boolean = false;
  public selectedSort: string = SortOptions.PATH_SCORE;
  public isAscending: boolean = true;
  public sortOptions = [
    SortOptions.PATH_SCORE,
    SortOptions.STEPS,
    SortOptions.PROTECTION_STEPS,
    SortOptions.SIMILARITY,
  ];
  public sortOrderTooltip: string;
  public isSinglePathwaySelected: boolean = false;
  public isPathwayViewOnly: boolean = false;
  public isPathwayView: boolean = false;
  public nodeLabelControl: ExpandedPathLabels = {
    repeatedReaction: true,
    repeatedMolecule: true,
    reactionName: true,
    typicalConditions: true,
    legendTags: true,
    publishedReferences: true,
  };
  public pathwayOptions = [
    PathwaysOptions.REPEATED_REACTIONS,
    PathwaysOptions.REPEATED_MOLECULES,
    PathwaysOptions.REACTION_NAME,
    PathwaysOptions.TYPICAL_CONDITIONS,
    PathwaysOptions.LEGEND_TAGS,
    PathwaysOptions.PUBLISHED_REACTION,
  ];
  public readonly nodeLabelTypes = nodeLabelTypes;
  public isComputationStopped: boolean = false;
  public isAutomaticRetrosynthesisAnalysis: boolean = false;
  public stopButtonMessage: string = 'Stop';
  public stoppingComputation: boolean = false;
  private selectedOptions: string[] = [];
  public targetSummary: Object = [];
  public showTargetsCount: boolean = false;
  public libraryView: boolean = false;
  public commentDialogConfig: MatDialogConfig = {
    panelClass: 'new-comment-dialog',
  };
  public maxCount: number = 99;
  public libraryTargets: LibraryTargetsEntry;
  public targetViewDialogRef: MatDialogRef<TargetViewDialogComponent>;
  public shareCount: number = 0;
  public commentsCount: {
    unread: number;
    total: number;
  } = {
    unread: 0,
    total: 0,
  };
  public isDiversityLibrary: boolean = false;
  public isDiversityResultView: boolean = false;
  public breadcrumbLabel: string = '';

  constructor(
    public routeStateService: ChematicaRouteStateService,
    public analysisResultsService: AnalysisResultsService,
    public appConstantsService: AppConstantsService,
    public storageHandlerService: StorageHandlerService,
    public router: Router,
    public breadCrumbService: BreadcrumbService,
    public analysisService: AnalysisService,
    public infoService: InfoService,
    public errorsHandler: ErrorsHandlerService,
    public targetMoleculeSelectionService: TargetMoleculeSelectionService,
    public dialogService: DialogService,
    public confirmDialogService: ConfirmDialogService,
    public socialService: SocialService,
    private authService: AuthorizationService,
    private commentDialogService: CommentDialogService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsubscriberSubject)).subscribe((event) => {
      if (event instanceof ResolveEnd) {
        this.breadcrumb = this.breadCrumbService.createBreadcrumbs(event.state.root);
      }
    });
    this.breadCrumbService.breadCrumbSubject.pipe(takeUntil(this.unsubscriberSubject)).subscribe({
      next: (crumb: BreadCrumb[]) => {
        this.breadcrumb = [...this.breadcrumb, ...crumb];
      },
    });

    this.breadCrumbService.analysis
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysis: AnalysisEntry) => {
        this.isRenameClicked = false;
        if (analysis) {
          this.analysis = analysis;
          this.analysisRename = analysis.name;
          this.updateAnalysisFlags();
          this.setTooltips();
          this.subscribeToStoppingAnalyses();
        } else {
          this.analysis = null;
          this.analysisRename = '';
        }
      });

    this.breadCrumbService.breadCrumbDiversity
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((breadCrumbText: string) => {
        this.breadcrumbLabel = breadCrumbText;
      });

    this.analysisResultsService.isNewResultAvailable
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isNewResultsAvailable) => {
        this.newResults = isNewResultsAvailable;
        this.setIsNewResultsAvailable();
      });

    this.analysisResultsService.resultsLoadingIndicator
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((resultsLoadingIndicator) => {
        this.resultsLoadingIndicator = resultsLoadingIndicator;
        this.setIsNewResultsAvailable();
      });

    this.analysisResultsService.loadingAnalysis
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((loadingAnalysis) => {
        this.loadingAnalysis = loadingAnalysis;
        this.setIsNewResultsAvailable();
      });

    this.analysisResultsService.processingOfNewResults
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((processingOfNewResults) => {
        this.processingOfNewResults = processingOfNewResults && this.mode === ResultsView.PATHWAYS;
        this.setIsNewResultsAvailable();
      });

    this.analysisService.analysisSettingsBehaviorSubjects.mode
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((mode) => {
        if (this.mode !== mode) {
          this.mode = mode;
        }
      });

    this.analysisResultsService.didSelectPath
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((selected) => {
        if (this.didSelectPath !== selected) {
          this.didSelectPath = selected;
        }
      });

    this.analysisResultsService.hasComputationError
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((error) => {
        if (this.hasComputationError !== error) {
          this.hasComputationError = error;
        }
      });

    this.analysisResultsService.hasWarnings
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((hasWarnings) => {
        if (this.hasWarnings !== hasWarnings) {
          this.hasWarnings = hasWarnings;
        }
      });

    this.analysisResultsService.isAllPathwaysLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((allPathwaysLoaded) => {
        this.isAllPathwaysLoaded = allPathwaysLoaded;
      });

    this.analysisResultsService.isComputationActive
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isComputationActive) => {
        this.isComputationActive = isComputationActive;
        this.showTargetsCount =
          this.analysisResultsService.isLibraryResultsView.value && !this.isComputationActive;
      });

    this.analysisService.pathwayOptions
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((pathwayOption) => {
        this.selectedOptions = pathwayOption;
      });

    this.analysisResultsService.showHeaderLabels
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((showHeaderLabels) => {
        this.showHeaderLabels = showHeaderLabels;
      });

    this.analysisResultsService.showPathsCount
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((showPathsCount) => {
        this.showPathsCount = showPathsCount;
      });

    this.analysisResultsService.isFilterApplied
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isFilterApplied) => {
        this.isFilterApplied = isFilterApplied;
      });

    this.analysisService.progressItemSummary
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((progressItem: string) => {
        this.progressItemSummary = parseProgressItem(progressItem);
        this.showTargetsCount = false;
      });

    this.analysisService.targetDetailsSummary
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((librarySummaryItem: Object) => {
        this.targetSummary = librarySummaryItem;
        this.showTargetsCount =
          this.targetSummary['mode'] === 'library' && !this.isComputationActive ? true : false;
      });

    this.analysisResultsService.filteredResultsLength
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((filteredResultsLength) => {
        this.filteredResultsLength = filteredResultsLength;
      });

    this.analysisResultsService.isAllPathwaysLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAllPathLoaded) => {
        this.isAllPathLoaded = isAllPathLoaded;
      });

    this.analysisResultsService.isFirstChunkLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isFirstChunkLoaded) => {
        this.isFirstChunkLoaded = isFirstChunkLoaded;
      });

    this.analysisService.isPathwayViewOnly
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((pathwayViewOnly: boolean) => {
        this.isPathwayViewOnly = pathwayViewOnly;
      });

    this.analysisResultsService.pathListSubject
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((pathList: any[]) => {
        this.isSinglePathwaySelected = false;
        if (pathList.length > 0) {
          for (const path of pathList) {
            if (path.isExpanded) {
              return (this.isSinglePathwaySelected = true);
            }
            this.isSinglePathwaySelected = false;
          }
        }
      });

    this.analysisResultsService.isAutomaticRetrosynthesisAnalysis
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAutoAnalysis: boolean) => {
        this.isAutomaticRetrosynthesisAnalysis = isAutoAnalysis;
      });

    this.analysisResultsService.stopButtonMessage
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((stopButtonMessage: string) => {
        this.stopButtonMessage = stopButtonMessage;
      });

    this.analysisResultsService.stoppingComputation
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((stoppingComputation: boolean) => {
        this.stoppingComputation = stoppingComputation;
      });

    this.analysisResultsService.selectedSortOption
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((sortOption) => {
        this.selectedSort = sortOption;
      });

    this.analysisResultsService.isAscending
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAscending) => {
        this.isAscending = isAscending;
      });

    this.analysisService.isLibraryResultAvailable
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((libraryView: boolean) => {
        this.libraryView = libraryView;
      });

    this.analysisService.analysis
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysis: DashboardAnalysisEntry) => {
        this.commentsCount = !isNullOrUndefined(analysis)
          ? analysis.comments_count
          : this.analysisService.analysisSettingsBehaviorSubjects.commentsCount.getValue();
        this.shareCount = !isNullOrUndefined(analysis)
          ? analysis.share_count
          : this.analysisService.analysisSettingsBehaviorSubjects.sharesCount.getValue();
      });

    this.analysisService.isDiversityLibrary
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isDiversify: boolean) => {
        this.isDiversityLibrary = isDiversify;
      });

    this.analysisService.isDiversityResultView
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isDiversity: boolean) => {
        this.isDiversityResultView = isDiversity;
      });

    this.analysisResultsService.libraryTargets
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((libraryTargets: LibraryTargetsEntry) => {
        this.libraryTargets = libraryTargets;
      });

    this.analysisResultsService.diversityTarget
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((diversityTarget: number) => {
        this.diversityTarget = diversityTarget;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
    this.clearRingAnimationTimeout();
  }

  public handleKeyEvents(event: KeyboardEvent, index: number) {
    switch (event.key) {
      case 'Enter':
        this.saveEditedAnalysisName(index);
        break;
      case 'Escape':
        this.closeRenameDialog();
        this.analysisRename = this.analysis.name;
        break;
      default:
        event.stopPropagation();
    }
  }

  public navigateHome() {
    this.showMoreIcons = false;
    this.routeStateService.navigateToHome();
  }

  public getBreadCrumbTitle(breadcrumbLabel: string) {
    if (this.isDiversityLibrary) {
      breadcrumbLabel = this.breadcrumbLabel;
    }
    if (breadcrumbLabel) {
      this.maxBreadCrumbLength =
        this.isDiversityLibrary || this.isDiversityResultView ? 40 : this.maxBreadCrumbLength;
      return breadcrumbLabel.length > this.maxBreadCrumbLength
        ? breadcrumbLabel.substring(0, this.maxBreadCrumbLength) + '...'
        : breadcrumbLabel;
    }
  }

  public getBreadCrumbTitleClass(index: number) {
    return index === this.breadcrumb.length - 1
      ? 'breadcrumb-label-bold'
      : 'breadcrumb-label-normal';
  }

  public onMenuItemClick(menuItem: BreadCrumb) {
    if (menuItem.url) {
      this.router.navigateByUrl(menuItem.url);
    }
  }

  public setTooltips() {
    if (!isNullOrUndefined(this.analysis)) {
      if (this.isShared) {
        this.disabledShareTooltip = this.appConstantsService.shareDisableTooltip;
      } else if (this.analysis.isRemoved()) {
        this.disabledShareTooltip = this.appConstantsService.shareDeletedTooltip;
      } else {
        this.disabledShareTooltip = this.appConstantsService.shareTooltip;
      }
      this.rerunTooltip = this.isRerunDisabledForNoc
        ? this.appConstantsService.rerunDisableTooltipNoc
        : this.analysis.rerun
        ? this.appConstantsService.rerunTooltip
        : this.appConstantsService.rerunCustomMoleculeTooltip;
    }
  }

  public shareAnalysis(event: MouseEvent) {
    if (!this.disableShareButton) {
      this.dialogService
        .openAnalysisSharing(this.analysis.id)
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe(() => {
          this.shareCount = this.analysisService.analysisSettingsBehaviorSubjects.sharesCount.getValue();
        });
    } else {
      event.stopPropagation();
    }
  }

  public rerunAnalysis(event: MouseEvent) {
    this.showMoreIcons = false;
    if (this.analysis.rerun) {
      this.targetMoleculeSelectionService.setMolecule(null);
      this.targetMoleculeSelectionService.analysisRerun.next(true);
      if (this.analysis.initial_computation.algorithm === AnalysisAlgorithm.LIBRARY_MODE) {
        this.router.navigateByUrl('home/rerun/library/' + this.analysis.id);
      } else {
        this.router.navigateByUrl('home/rerun/analysis/' + this.analysis.id);
      }
    } else {
      event.stopPropagation();
    }
  }

  public renameAnalysis() {
    if (!this.isShared) {
      this.isRenameClicked = true;
    }
  }

  public commentAnalysis(event: MouseEvent) {
    event.stopPropagation();
    this.commentsCount.unread = 0;
    this.commentDialogService
      .openCommentDialog(this.analysis, this.commentDialogConfig)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(() => {
        this.commentsCount.total = this.analysisService.analysisSettingsBehaviorSubjects.commentsCount.getValue().total;
      });
  }

  public saveEditedAnalysisName(index: number) {
    if (this.analysisRename) {
      this.analysisService
        .renameAnalysis(this.analysis.id, this.analysisRename)
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe(
          (result: HttpResponse<any>) => {
            this.isRenameClicked = false;
            if (result && result.status === 200) {
              this.breadcrumb[index].label = this.analysisRename;
              this.analysis.name = this.analysisRename;
              this.infoService.showInfo(this.appConstantsService.analysisRenamedInfo);
            }
          },
          (error) => {
            this.breadcrumbCallError(error);
            this.isRenameClicked = false;
          },
        );
    }
  }

  public closeRenameDialog() {
    this.isRenameClicked = false;
  }

  public getFavoriteButtonText() {
    return this.analysis.isFavorited()
      ? this.appConstantsService.unfavoriteTooltip
      : this.appConstantsService.favoriteTooltip;
  }

  public handleFavoriteAnalysis(event: MouseEvent) {
    if (this.analysis && this.analysis.isFavoritingAllowed()) {
      this.disableFavoriting = true;
      if (this.analysis.isFavorited()) {
        this.unmarkAnalysiAsFavorite();
      } else {
        this.markAnalysisAsFavorite();
      }
    } else {
      event.stopPropagation();
    }
  }

  public deleteOrRestoreAnalysis(event: MouseEvent) {
    if (!this.isDeleteButtonDisabled) {
      if (this.analysis.isRemoved()) {
        this.restoreAnalysis();
      } else {
        this.deleteAnalysis();
      }
    } else {
      event.stopPropagation();
    }
  }

  public deleteAnalysis() {
    const confirmationMessage: string = this.appConstantsService.replaceVariableFromMessage(
      this.appConstantsService.deleteAnalysisConfimationMessage,
      '<analysisName>',
      this.analysis.name,
    );
    const infoMessage: string =
      `${this.analysis.name} ` + this.appConstantsService.deletedAnalysisInfoMessage;

    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: 'Delete Analysis',
      message: confirmationMessage,
      trueActionName: 'DELETE',
    };

    this.confirmDialogService
      .confirm(dialogConfiguration)
      .pipe(
        takeUntil(this.unsubscriberSubject),
        switchMap((remove) => {
          if (remove) {
            if (this.isShared) {
              return this.socialService.unshareAnalysisAsRecipient(this.analysis.id);
            } else {
              return this.analysisService.deleteAnalysis(this.analysis.id);
            }
          }
          return EMPTY;
        }),
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          if ((res && res.status === 200) || (this.analysis.shared_at && isNull(res))) {
            this.navigateHome();
            this.infoService.showInfo(infoMessage);
          }
        },
        (error) => {
          this.breadcrumbCallError(error);
        },
      );
  }

  public restoreAnalysis() {
    this.analysisService
      .restoreAnalysis(this.analysis.id)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (result: HttpResponse<any>) => {
          if (result.status === 200) {
            this.analysis.removed_at = null;
            this.updateAnalysisFlags();
            this.infoService.showInfo(
              `'${this.analysis.name}' ` + this.appConstantsService.restoredAnalysisInfoMessage,
            );
          }
        },
        (error) => {
          this.breadcrumbCallError(error);
        },
      );
  }

  public getDeleteRestoreAnalysisIcon() {
    return this.analysis.isRemoved() ? 'restore_from_trash' : 'delete_outline';
  }

  public getDeleteRestoreAnalysisText() {
    return this.analysis.isRemoved() ? 'Restore' : 'Delete';
  }

  public setIsNewResultsAvailable() {
    this.isNewResultsAvailable =
      this.newResults &&
      !this.processingOfNewResults &&
      !this.loadingAnalysis &&
      !this.resultsLoadingIndicator &&
      !!this.analysis &&
      !this.analysis.isManualRetrosynthesis();
  }

  public loadNewResults() {
    this.isNewResultsAvailable = false;
    setTimeout(() => {
      this.analysisResultsService.consolidatedFilteredPathList.next([]);
      this.analysisResultsService.isNewGraphAvailable.next(true);
      this.analysisResultsService.showGraphUpdates.next(true);
      if (
        !this.isComputationActive &&
        !this.analysisService.isComputationActivePreviousValue &&
        !this.isAllPathLoaded
      ) {
        this.analysisResultsService.reloadMoleculeReport.next(true);
      }
    }, 0);
  }

  public onClickShowWarnings() {
    this.analysisResultsService.loadWarningMessage();
  }

  public continueRingAnimation() {
    this.clearRingAnimationTimeout();
    this.ringAnimationTimeout = setTimeout(() => {
      this.ringAnimationState = this.ringAnimationState === 'active' ? 'ring' : 'active';
    }, 5000);
  }

  public clearRingAnimationTimeout() {
    if (this.ringAnimationTimeout) {
      clearTimeout(this.ringAnimationTimeout);
      this.ringAnimationTimeout = undefined;
    }
  }

  public onClickComputationError() {
    this.analysisResultsService.loadComputationError();
  }

  public unmarkAnalysiAsFavorite() {
    this.analysisService
      .unmarkAnalysisAsFavorite(this.analysis.id)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (result) => {
          if (result.status === 204) {
            this.disableFavoriting = false;
            this.analysis.is_favorite = false;
            this.updateAnalysisFlags();
          }
        },
        (error) => {
          this.breadcrumbCallError(error);
          this.disableFavoriting = false;
        },
      );
  }

  public markAnalysisAsFavorite() {
    this.analysisService
      .markAnalysisAsFavorite(this.analysis.id)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (result) => {
          if (result.status === 201) {
            this.disableFavoriting = false;
            this.analysis.is_favorite = true;
            this.updateAnalysisFlags();
          }
        },
        (error) => {
          this.breadcrumbCallError(error);
          this.disableFavoriting = false;
        },
      );
  }

  public breadcrumbCallError(error) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
    } else if (parsedError.isRecognized()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage);
    }
  }

  public updateAnalysisFlags() {
    if (!isNullOrUndefined(this.analysis)) {
      this.isFavoritingAllowed = this.analysis.isFavoritingAllowed();
      this.isShared = this.analysis.isShared();
      this.isFavorited = this.analysis.isFavorited();
      this.disableShareButton = this.isShared || this.analysis.isRemoved();
      this.isDeleteButtonDisabled = this.analysis.hasPendingComputations();
      this.isRerunDisabledForNoc = this.analysis.isNOCAnalysis();
    }
  }

  public changeMode(resultView: ResultsView) {
    this.analysisService.analysisSettingsBehaviorSubjects.mode.next(resultView);
  }

  public getPathViewButtonTooltip() {
    if (!!this.analysis) {
      return this.analysis.isAlgorithmWithoutPathways()
        ? this.appConstantsService.disabledPathwaysViewTooltip
        : this.appConstantsService.pathwaysViewTooltip;
    }
    return this.appConstantsService.pathwaysViewTooltip;
  }

  public breadcrumbMoreIcon(showIcon: boolean) {
    this.showMoreIcons = showIcon ? true : false;
  }

  public isSortDisabled() {
    if (this.isComputationActive) {
      return !this.showHeaderLabels || !this.isAllPathLoaded;
    }
    return !this.isFirstChunkLoaded;
  }

  public onSortChange(sort: string) {
    this.selectedSort = sort;
    this.analysisResultsService.selectedSortOption.next(this.selectedSort);
    this.isAscending = sort !== SortOptions.SIMILARITY;
    this.analysisResultsService.isAscending.next(this.isAscending);
  }

  public onSortOrderChange() {
    this.isAscending = !this.isAscending;
    this.analysisResultsService.isAscending.next(this.isAscending);
    this.sortOrderTooltip = this.getSortOrderTooltip();
  }

  public getSortOrderTooltip() {
    return this.isAscending
      ? this.appConstantsService.sortAscendingTooltip
      : this.appConstantsService.sortDescendingTooltip;
  }

  public pathwayViewEnabled(event) {
    this.analysisService.isPathwayViewOnly.next(event.checked);
    if (!event.checked) {
      this.isSinglePathwaySelected = false;
    }
  }

  public updateNodeLabelControlChange() {
    this.nodeLabelControl.legendTags = this.selectedOptions.includes(PathwaysOptions.LEGEND_TAGS);
    this.nodeLabelControl.reactionName = this.selectedOptions.includes(
      PathwaysOptions.REACTION_NAME,
    );
    this.nodeLabelControl.typicalConditions = this.selectedOptions.includes(
      PathwaysOptions.TYPICAL_CONDITIONS,
    );
    this.nodeLabelControl.publishedReferences = this.selectedOptions.includes(
      PathwaysOptions.PUBLISHED_REACTION,
    );
    this.nodeLabelControl.repeatedReaction = this.selectedOptions.includes(
      PathwaysOptions.REPEATED_REACTIONS,
    );
    this.nodeLabelControl.repeatedMolecule = this.selectedOptions.includes(
      PathwaysOptions.REPEATED_MOLECULES,
    );
  }

  public nodeLabelControlChange(type: nodeLabelTypes, event: MatCheckboxChange) {
    switch (type) {
      case nodeLabelTypes.reactionName:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.REACTION_NAME)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.REACTION_NAME),
              1,
            );
        break;
      case nodeLabelTypes.legendTags:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.LEGEND_TAGS)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.LEGEND_TAGS),
              1,
            );
        break;
      case nodeLabelTypes.typicalConditions:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.TYPICAL_CONDITIONS)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.TYPICAL_CONDITIONS),
              1,
            );
        break;
      case nodeLabelTypes.publishedReferences:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.PUBLISHED_REACTION)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.PUBLISHED_REACTION),
              1,
            );
        break;
      case nodeLabelTypes.repeatedReaction:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.REPEATED_REACTIONS)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.REPEATED_REACTIONS),
              1,
            );
        break;
      case nodeLabelTypes.repeatedMolecule:
        event.checked
          ? this.selectedOptions.push(PathwaysOptions.REPEATED_MOLECULES)
          : this.selectedOptions.splice(
              this.selectedOptions.indexOf(PathwaysOptions.REPEATED_MOLECULES),
              1,
            );
        break;
    }
    this.analysisService.pathwayOptions.next(this.selectedOptions);
  }

  public stopActiveComputation() {
    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: 'Stop analysis computation',
      message: `Do you want to stop ${this.analysis.initial_computation.name}?`,
      trueActionName: 'STOP',
    };

    this.confirmDialogService
      .confirm(dialogConfiguration)
      .pipe(
        takeUntil(this.unsubscriberSubject),
        flatMap((action) => {
          if (action) {
            this.stoppingComputation = true;
            return this.analysisService
              .stopComputation(this.analysis.id, this.analysis.initial_computation.id)
              .pipe(
                catchError((error) => {
                  const parsedError = new ParsedConnectionError(error);
                  if (parsedError.status === 403) {
                    if (!parsedError.isCustomerPolicyNotAcceptedError()) {
                      this.infoService.showError(
                        this.appConstantsService.stopComputationPermissionErrorMessage,
                        5000,
                      );
                    }
                  }
                  return of(error);
                }),
              );
          } else {
            this.stopButtonMessage = 'Stop';
            return empty();
          }
        }),
      )
      .subscribe(
        (computation: ComputationEntry) => {
          if (!!computation && computation.state === ComputationState.INTERRUPTED_BY_USER) {
            this.isComputationStopped = true;
          }
        },
        (error) => {
          this.stoppingComputation = false;
          this.breadcrumbCallError(error);
        },
      );
  }

  public subscribeToStoppingAnalyses() {
    this.analysisService.stoppingAnalyses
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((stoppingAnalyses) => {
        if (
          this.analysis &&
          stoppingAnalyses.some((analysisId) => analysisId === this.analysis.id)
        ) {
          if (
            (this.analysis.isAutomaticRetrosynthesis() ||
              this.analysis.isLibraryModeRetrosynthesis()) &&
            this.analysis.hasPendingComputations()
          ) {
            this.stoppingComputation = true;
          } else {
            const newStoppingAnalyses: number[] = this.analysisService.stoppingAnalyses.value.filter(
              (analysisId) => analysisId !== this.analysis.id,
            );
            this.analysisService.stoppingAnalyses.next(newStoppingAnalyses);
            this.stopButtonMessage = 'Stop';
          }
        }
      });
  }

  public getCommentsCount(): number {
    const count =
      this.commentsCount?.unread > 0 ? this.commentsCount?.unread : this.commentsCount?.total;
    return Math.min(count, this.maxCount);
  }

  public viewTargetMolecules() {
    const config: MatDialogConfig = {
      backdropClass: 'modal-backdrop-dark',
      width: '90vw',
      minHeight: 'calc(100vh - 240px)',
      height: 'auto',
      panelClass: 'target-view-dialog-container',
    };

    config.data = {
      target: this.libraryTargets,
      isTargetResultsPage: true,
    };
    this.targetViewDialogRef = this.dialog.open(TargetViewDialogComponent, config);
  }
}
