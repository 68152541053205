import { Subscription } from 'rxjs';
import { Component, Input, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import {
  MolecularStrainService,
  MolecularStrainReport,
  ErrorsHandlerService,
  ParsedConnectionError,
} from '../../../services';
import { isNullOrUndefined, isString } from '../../utils';

@Component({
  selector: 'ch-molecular-strain-report',
  styleUrls: ['./molecular-strain-report.component.scss'],
  templateUrl: './molecular-strain-report.component.html',
})
export class MolecularStrainReportComponent implements OnChanges, OnDestroy {
  public bondProblems: number = -1;
  public angleProblems: number = -1;
  public dihedralProblems: number = -1;
  public selectedConformer: number = -1;
  public errorMessage: string = '';
  public loading: boolean = false;

  @Input() public smiles: string;

  @Output() public report: MolecularStrainReport;

  private strainServiceSubscription: Subscription;

  private readonly errorsWarningsMap = {
    MOLECULE_TOO_BULKY: 'Strain problem detected.',
    MOLECULE_TOO_SMALL: 'No strain problem detected.',
    ONLY_SMALL_IONS_FOUND: 'No strain problem detected.',
    NO_UFF_PARAMS: 'Cannot calculate this molecule.',
    CONSISTENCY_CHECK_FAILED: 'Cannot calculate this molecule.',
  };

  constructor(
    private strainService: MolecularStrainService,
    private errorsHandler: ErrorsHandlerService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('smiles')) {
      this.report = undefined;
      this.errorMessage = '';
      this.abortPendingStrainService();

      if (this.smiles !== '') {
        // '' happens in an attempt of parent control to mark an unrecognized molecule
        this.loading = true;
        this.strainServiceSubscription = this.strainService.getStrainReport(this.smiles).subscribe(
          (report: MolecularStrainReport) => this.applyReport(report),
          (error: any) => this.handleStrainServiceError(error),
          () => (this.loading = false),
        );
      }
    }
  }

  public ngOnDestroy(): void {
    this.abortPendingStrainService();
  }

  private applyReport(report: MolecularStrainReport) {
    // Note: We ignore report.warnings per user request ("It's enough to say that no strains were found.")
    if (isNullOrUndefined(report.error)) {
      if (
        report.angles_strain.length ||
        report.bonds_strain.length ||
        report.dihedrals_strain.length
      ) {
        this.report = report;
        this.selectedConformer = report.conformers && report.conformers.length ? 0 : -1;
        this.angleProblems = report.angles_strain.filter(
          (s) => s.angle_above_threshold || s.energy_above_threshold,
        ).length;
        this.bondProblems = report.bonds_strain.filter(
          (s) => s.length_above_threshold || s.energy_above_threshold,
        ).length;
        this.dihedralProblems = report.dihedrals_strain.filter(
          (s) => s.angle_above_threshold,
        ).length;
      } else {
        // Special case per users request to not display empty reports at all
        this.errorMessage = 'No strain problem detected.';
        this.bondProblems = -1;
        this.angleProblems = -1;
        this.dihedralProblems = -1;
      }
    } else {
      this.errorMessage = this.errorsWarningsMap[report.error] || report.error;
      this.bondProblems = -1;
      this.angleProblems = -1;
      this.dihedralProblems = -1;
    }
  }

  private handleStrainServiceError(error: any) {
    // FIXME: Errors handling (when it becomes known if/what reports specific errors backend can return)
    //        At the moment only standard 'global' errors are expected (400, 401, 502)
    this.loading = false;
    if (error instanceof ParsedConnectionError) {
      const parsedError: ParsedConnectionError = error;
      this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
    } else {
      this.errorsHandler.showGlobalError(
        'Unable to load strains report',
        isString(error) ? error : `Unknown error: ${error}`,
      );
    }
  }

  private abortPendingStrainService() {
    if (this.strainServiceSubscription && !this.strainServiceSubscription.closed) {
      this.strainServiceSubscription.unsubscribe();
    }
  }
}
