<ch-new-top-toolbar *ngIf="isNavBarEnabled()"></ch-new-top-toolbar>
<ng-template #siteAnnouncementTemplate let-data>
  <div class="announcement">
    <div class="info">{{ data.message }}</div>
    <div class="dismiss">
      <button mat-flat-button color="primary" (click)="matDialogRef.close(data.message)">
        GOT IT
      </button>
    </div>
  </div>
</ng-template>
<div class="ch-overlay-div" *ngIf="isLoading">
  <ch-loader></ch-loader>
</div>

<div [class.hide-div]="isLoading">
  <router-outlet>
    <div id="background-div" *ngIf="showLoader" class="splash-loading">
      <div id="logo" class="splash-logo">
        <h1 *ngIf="!isSynthiaLiteEnabled"><span>SYNTHIA&trade;</span> Retrosynthesis Software</h1>
        <h1 *ngIf="isSynthiaLiteEnabled" class="lite-logo">
          <span>SYNTHIA&trade;</span> Lite Retrosynthesis Software
        </h1>
        <div class="splash-message">loading</div>
        <div class="splash-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
  </router-outlet>
</div>
