import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ALL_MATERIALS_MODULES } from './all-materials-modules';
import {
  AutoFocusDirective,
  ButtonOutlineDirective,
  CenterMatMenuDirective,
  EqualFieldsValidatorDirective,
  SelectOnAutoFocusDirective,
  ShowIfTruncatedDirective,
  SynthiaLiteDisabledFeatureDirective,
  SynthiaLogoDirective,
  ViewportVisibilityDirective,
} from './directives';
import { RolloverDirective } from './directives/rollover.directive';
import { MetricSuffixPipe, SafeHtmlPipe } from './pipes';
import { UnauthorizedViewHeaderComponent } from './components/unauthorized-view-header/unauthorized-view-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { CartWidgetComponent } from '../ecomm-integration/components/cart-widget/cart-widget.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsStopComputationButtonAvailablePipe } from '../analysis-results/analysis-results-base/pipes/is-stop-computation-button-available.pipe';
import { InfoDialogComponent } from './components';
import { CartItemComponent } from '../ecomm-integration/components/cart-item/cart-item.component';

const COMPONENTS = [
  UnauthorizedViewHeaderComponent,
  FooterComponent,
  CopyrightComponent,
  SelectCountryComponent,
  CartWidgetComponent,
  CartItemComponent,
  LoaderComponent,
  InfoDialogComponent,
];
const DIRECTIVES = [
  AutoFocusDirective,
  ButtonOutlineDirective,
  CenterMatMenuDirective,
  EqualFieldsValidatorDirective,
  RolloverDirective,
  SelectOnAutoFocusDirective,
  ShowIfTruncatedDirective,
  SynthiaLiteDisabledFeatureDirective,
  SynthiaLogoDirective,
  ViewportVisibilityDirective,
];

const PIPES = [MetricSuffixPipe, SafeHtmlPipe, IsStopComputationButtonAvailablePipe];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ...ALL_MATERIALS_MODULES],
  exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES, ...ALL_MATERIALS_MODULES],
})
export class SharedModule {}
