import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertDialogService {
  public dialogRef: MatDialogRef<AlertDialogComponent>;
  private config: MatDialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public showAlert(message: string, actionName: string): Observable<boolean> {
    this.config.disableClose = true;
    this.dialogRef = this.dialog.open(AlertDialogComponent, this.config);

    if (actionName) {
      this.dialogRef.componentInstance.actionName = actionName;
    }
    this.dialogRef.componentInstance.message = message;

    return this.dialogRef.beforeClosed();
  }
}
