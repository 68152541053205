import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, onErrorResumeNext } from 'rxjs';
import { ThumbsUpAnnotation } from './models/thumbs-up-annotation';
import { ThumbsDownAnnotation } from './models/thumbs-down-annotation';

export const backendEntryPointAnnotations =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/<feedbackType>/<reactionId>/';
export enum Feedback {
  ThumbsUp,
  ThumbsDown,
}
export interface IReactionAnnotation {
  thumbsUp: ThumbsUpAnnotation;
  thumbsDown: ThumbsDownAnnotation;
}

@Injectable()
export class ReactionAnnotationsService {
  constructor(private http: HttpClient) {}

  public getReactionAnnotations(reactionId: string) {
    const getAnnotationObservables: Observable<any>[] = [
      this.http
        .get(this.makeThumbsUrl('thumbs-up', reactionId), { observe: 'response' })
        .pipe(map((response: HttpResponse<any>) => this.resolveThumbsUpResponse(response.body))),
      this.http
        .get(this.makeThumbsUrl('thumbs-down', reactionId), { observe: 'response' })
        .pipe(map((response: HttpResponse<any>) => this.resolveThumbsDownResponse(response.body))),
    ];
    return onErrorResumeNext(...getAnnotationObservables).pipe(map((response: any) => response));
  }

  public sendThumbsUpFeedback(
    reactionId: string,
    thumbsUp: ThumbsUpAnnotation,
    put?: boolean,
  ): Observable<ThumbsUpAnnotation> {
    const thumbsUpUrl: string = this.makeThumbsUrl('thumbs-up', reactionId);
    const method: 'post' | 'put' = put ? 'put' : 'post';
    return this.http[method](thumbsUpUrl, JSON.stringify(thumbsUp), { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.resolveThumbsUpResponse(response.body)),
    );
  }

  public sendThumbsDownFeedback(
    reactionId: string,
    thumbsDown: ThumbsDownAnnotation,
    put?: boolean,
  ): Observable<ThumbsDownAnnotation> {
    const thumbsDownUrl: string = this.makeThumbsUrl('thumbs-down', reactionId);
    const method: 'post' | 'put' = put ? 'put' : 'post';
    return this.http[method](thumbsDownUrl, JSON.stringify(thumbsDown), {
      observe: 'response',
    }).pipe(map((response: HttpResponse<any>) => this.resolveThumbsDownResponse(response.body)));
  }

  private makeThumbsUrl(thumbsType: 'thumbs-down' | 'thumbs-up', reactionId: string): string {
    return backendEntryPointAnnotations
      .replace('<feedbackType>', thumbsType)
      .replace('<reactionId>', reactionId);
  }

  private resolveThumbsUpResponse(thumbsUpResponse: any): ThumbsUpAnnotation {
    try {
      return new ThumbsUpAnnotation(thumbsUpResponse);
    } catch (err) {
      throw new Error('Unexpected format of response.');
    }
  }

  private resolveThumbsDownResponse(thumbsDownResponse: any): ThumbsDownAnnotation {
    try {
      return new ThumbsDownAnnotation(thumbsDownResponse);
    } catch (err) {
      throw new Error('Unexpected format of response.');
    }
  }
}
