<div
  [formGroup]="formGroup"
  [class.no-visibility]="!isNormalSlider"
  [class.same-max-min-range]="isSameMaxMinRange"
>
  <mat-slider
    #sMin
    class="ch-slider mat-range-slider mat-range-min-slider"
    [tabIndex]="tabIndex"
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [vertical]="vertical"
    color="primary"
    [formControlName]="switchCase ? 'max' : 'min'"
    (input)="minSliderChange($event)"
    (change)="sliderFocusOut()"
  >
  </mat-slider>

  <mat-slider
    #sMax
    class="ch-slider mat-range-slider mat-range-max-slider"
    [tabIndex]="tabIndex"
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [vertical]="vertical"
    color="primary"
    [formControlName]="switchCase ? 'min' : 'max'"
    (input)="maxSliderChange($event)"
    (change)="sliderFocusOut()"
  >
  </mat-slider>
</div>
<div class="same-value-slider-container" *ngIf="!isNormalSlider">
  <mat-slider
    *ngIf="isSameMaxMin"
    #sSameValue
    class="ch-slider mat-range-slider same-value-slider"
    [tabIndex]="tabIndex"
    disabled="true"
    [max]="max * 2"
    [min]="0"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [vertical]="vertical"
    color="primary"
    [value]="max"
  >
  </mat-slider>

  <div class="disabled-slider" *ngIf="isNoValueSlider">
    <div class="handler left-handler"></div>
    <div class="center-line"></div>
    <div class="handler right-handler"></div>
  </div>
</div>
<div class="dynamic-range-div" *ngIf="showRange">
  <div class="dynamic-range-container">
    <div
      class="dynamic-range"
      [style.width.%]="rangeWidth"
      [style.marginLeft.%]="rangeMinPos"
      [matTooltip]="rangeTooltip"
      matTooltipShowDelay="100"
      matTooltipPosition="above"
      matTooltipClass="ch-tooltip"
      [matTooltipDisabled]="disableRangeTooltip"
    >
      <div class="handler left-handler"></div>
      <div class="center-line"></div>
      <div class="handler right-handler"></div>
    </div>
  </div>
</div>
