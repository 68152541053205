<mat-dialog-content class="dialog-content">
  <button mat-icon-button class="close-btn ch-action-icon-hover" (click)="closeDialog()">
    <mat-icon class="action-icon grey-icon">close</mat-icon>
  </button>
  <div class="target-view-text target-with-path" *ngIf="!isTargetResultsPage">
    <p
      class="library-name ch-default-blue truncate-on-overflow"
      [matTooltip]="analysis.name"
      matTooltipPosition="below"
      matTooltipClass="ch-tooltip name-tooltip"
      appShowIfTruncated
    >
      {{ analysis.name }}
    </p>
    <p *ngIf="analysis.created_at" class="library-name-details">
      Shared Path Library&nbsp;
      {{
        (analysis.shared_at ? analysis.shared_at : analysis.created_at) | date: 'MM/dd/yy, h:mm a'
      }}
    </p>
  </div>
  <div
    class="molecules-grid"
    #moleculesGrid
    [class.no-molecule]="analysis.smiles.length === 0"
    *ngIf="!isTargetResultsPage"
  >
    <mat-grid-list [cols]="columns" [rowHeight]="300" gutterSize="0px">
      <ng-container *ngFor="let molecule of moleculesToDisplay">
        <mat-grid-tile (click)="selectSetItem(molecule)" #gridTile>
          <ch-list-molecule-card
            class="molecule-card-container"
            [molecule]="molecule"
            [isMoleculeExpanded]="molecule.moleculeExpanded"
            [isCustomList]="isCustomList"
            [viewType]="viewType"
            [isTargetViewLibrary]="true"
          >
          </ch-list-molecule-card>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </div>
  <div class="target-content" *ngIf="isTargetResultsPage">
    <div
      class="target-view-text target-with-path-results-view"
      *ngIf="targetsWithPathsFound.length > 0"
    >
      <p class="library-name ch-default-blue truncate-on-overflow">Shared path found:</p>
    </div>
    <div class="results-molecules-grid" #moleculesGrid *ngIf="targetsWithPathsFound.length > 0">
      <mat-grid-list [cols]="columns" [rowHeight]="300" gutterSize="0px">
        <ng-container *ngFor="let molecule of targetsWithPathsFound">
          <mat-grid-tile (click)="selectSetItem(molecule)" #gridTile>
            <ch-list-molecule-card
              class="molecule-card-container"
              [molecule]="molecule"
              [isMoleculeExpanded]="molecule.moleculeExpanded"
              [isCustomList]="isCustomList"
              [viewType]="viewType"
              [isTargetViewLibrary]="true"
            >
            </ch-list-molecule-card>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>
    <div
      class="target-view-text"
      *ngIf="targetsWithNoPathsFound.length > 0"
      [ngClass]="
        targetsWithPathsFound.length > 0 ? 'target-no-path' : 'target-with-path-results-view'
      "
    >
      <p class="library-name ch-default-blue truncate-on-overflow">
        Targets with no shared path found:
      </p>
    </div>
    <div class="results-molecules-grid" #moleculesGrid *ngIf="targetsWithNoPathsFound.length > 0">
      <mat-grid-list [cols]="columns" [rowHeight]="300" gutterSize="0px">
        <ng-container *ngFor="let molecule of targetsWithNoPathsFound">
          <mat-grid-tile (click)="selectSetItem(molecule)" #gridTile>
            <ch-list-molecule-card
              class="molecule-card-container"
              [molecule]="molecule"
              [isMoleculeExpanded]="molecule.moleculeExpanded"
              [isCustomList]="isCustomList"
              [viewType]="viewType"
              [isTargetViewLibrary]="true"
            >
            </ch-list-molecule-card>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>
  </div>
</mat-dialog-content>
<div class="view-overlay" *ngIf="libraryTargetDetailsLoading">
  <ch-loader></ch-loader>
</div>
