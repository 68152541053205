<div class="ch-sign-in-footer" [class.lite-footer]="isSynthiaLiteEnabled">
  <ul>
    <li class="privacy-policy" *ngIf="isChinaRegion">
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer noopener">
        沪ICP备14038167号-4
      </a>
    </li>
    <li class="privacy-policy">
      <button (click)="routeStateService.navigateToPrivacyPage()">
        {{ isChinaRegion ? '网站和应用程序隐私声明' : 'Privacy' }}
      </button>
    </li>
    <li class="privacy-policy" *ngIf="isChinaRegion">
      <button (click)="routeStateService.navigateToCnPrivacyPages(true, '', 'personal')">
        个人信息收集同意书
      </button>
    </li>
    <li class="privacy-policy" *ngIf="isChinaRegion">
      <button (click)="routeStateService.navigateToCnPrivacyPages(true, '', 'termscn')">
        使用条款
      </button>
    </li>
    <li class="privacy-policy" *ngIf="isSynthiaLiteEnabled">
      <a [routerLink]="['/terms']" target="_blank" rel="noreferrer noopener">
        Terms
      </a>
    </li>
    <li class="privacy-policy">
      <a [routerLink]="['/tech-support']" target="_blank" rel="noreferrer noopener">
        {{ isChinaRegion ? '技术支持' : 'Technical Support' }}
      </a>
    </li>
    <li class="about">
      <a href="{{ aboutLink }}" target="_blank" rel="noreferrer noopener">
        {{ isChinaRegion ? '关于我们' : 'About' }}</a
      >
    </li>
  </ul>
</div>
