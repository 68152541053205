import { isString } from '../../components/utils';

/*
 A class wrapping a single json from frontend-storage entry point on backend.
 */

// It should be used and tested after midend bugfix in FS
export interface ITutorialSettings {
  autoRetro: {
    skip: boolean;
    remindRequestedAt: number;
  };
}

export interface ExpandedPathLabels {
  repeatedReaction: boolean;
  repeatedMolecule: boolean;
  reactionName: boolean;
  typicalConditions: boolean;
  legendTags: boolean;
  publishedReferences: boolean;
}

export interface AppSettings {
  analysisResults: {
    expandedPath: {
      labelDisplaySettings: ExpandedPathLabels;
    };
  };
}

export class FrontendStorage {
  public appSettings: AppSettings = {
    analysisResults: {
      expandedPath: {
        labelDisplaySettings: {
          repeatedReaction: true,
          repeatedMolecule: true,
          reactionName: true,
          typicalConditions: false,
          legendTags: true,
          publishedReferences: true,
        },
      },
    },
  };
  public tutorialSettings: ITutorialSettings = {
    autoRetro: {
      skip: false,
      remindRequestedAt: 0,
    },
  };

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (typeof jsonData[field] === typeof this[field]) {
        this[field] = jsonData[field];
      } else {
        console.warn(`Unexpected type of '${field}' in data provided to FrontendStorage:ctor.`);
      }
    }
  }
}
