import { number } from 'mathjs';
import { isSameArrayByValue, isString } from '../../../components/utils';

export interface Protection {
  groupPreview: string | null;
  chemicalClass: string;
  protectiveGroups: string[];
}

export class GraphReactionEntry {
  /* tslint:disable:variable-name */
  public id: string = '';
  public name: string = '';
  public smiles: string = '';
  public yield: string = '';
  public yield_level: string = '';
  public normalized_yield_level: string = '';
  public publication_year: number = 0;
  public rxids: number[] = [];
  public reaction_id: number = 0;
  public reference: string = '';
  public reference_doi: string[] = [];
  public patent_number: string = '';
  public procedure: string = '';
  public conditions: string = '';
  public reaction_cost: number = 0;
  public substrates: string[] = [];
  public substrates_with_unprotected_groups: any = {};
  public products: string[] = [];
  public reactions_data: any = {};
  public reaction_type: string = '';
  public doi: string = '';
  public similarity_score: number | null = 0;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const checkAndAssignIfNullValue = (jsonDataFieldValue: any, field: string) => {
      if (jsonDataFieldValue === null) {
        // Use null instead of default value because of null values received from midend
        // that means empty attribute value for different types
        this[field] = null;
        return true;
      }

      return false;
    };
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        switch (field) {
          case 'reaction_id':
          case 'yield':
          case 'publication_year':
          case 'reaction_cost':
          case 'conditions':
          case 'similarity_score':
            if (checkAndAssignIfNullValue(jsonData[field], field)) {
              break;
            }
          default:
            if (typeof jsonData[field] === typeof this[field]) {
              this[field] = jsonData[field];
            } else {
              // FIXME: Fallback to default is temporal. Change to exception throwing when API stabilizes.
              console.error(
                `Unexpected type '${typeof jsonData[field]}' of field '${field}'
                \nin data provided to GraphReactionEntry:ctor.
                \nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
              );
            }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to GraphReactionEntry:ctor.`);
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }

  public isProtectionNeeded(): boolean {
    // FIXME: IF statement Should be removed before merge to develop
    if (!Array.isArray(this.substrates_with_unprotected_groups.isArray)) {
      return Object.keys(this.substrates_with_unprotected_groups).length > 0;
    }

    return false;
  }

  public isSubstrateNeedsProtection(substrateId: string): boolean {
    return this.substrates_with_unprotected_groups[substrateId];
  }

  public getProtectionList() {
    let protectionList: Protection[] = [];
    if (this.isProtectionNeeded()) {
      for (const key in this.substrates_with_unprotected_groups) {
        if (this.substrates_with_unprotected_groups.hasOwnProperty(key)) {
          for (const unprotectedGroup of this.substrates_with_unprotected_groups[key]) {
            const protection: Protection = {
              groupPreview: unprotectedGroup.functional_group,
              chemicalClass: unprotectedGroup.classification,
              protectiveGroups: unprotectedGroup.protecting_groups,
            };
            protectionList = this.removeDuplicateProtection(protectionList, protection);
          }
        }
      }
    }
    return protectionList;
  }

  public removeDuplicateProtection(protectionList: Protection[], newProtection: Protection) {
    // Remove duplicate functional group info in protection list
    let duplicateProtection: Protection;
    duplicateProtection = protectionList.find((protection) => {
      if (
        protection.groupPreview === newProtection.groupPreview &&
        protection.chemicalClass === newProtection.chemicalClass &&
        isSameArrayByValue(protection.protectiveGroups, newProtection.protectiveGroups)
      ) {
        return true;
      }
    });
    if (!duplicateProtection) {
      protectionList.push(newProtection);
    }
    return protectionList;
  }
}
