import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../../../authorization';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(private authorizationService: AuthorizationService) {}

  public getAnalysisPermission(): Observable<boolean> {
    return of(this.authorizationService.userInformation.value.is_authorised_to_run_analyses);
  }
}
