import { GraphMoleculeEntry } from './graph-molecule-entry';
import { GraphReactionNodeEntry } from './graph-reaction-node-entry';
import { GraphReactionEntry } from './graph-reaction-entry';
import { GraphSyntheticPathEntry } from './graph-synthetic-path-entry';
import { isString } from '../../../components/utils';

export class DiversityResources {
  public molecules: GraphMoleculeEntry[] = [];
  public reactions: GraphReactionEntry[] = [];
  public reaction_nodes: GraphReactionNodeEntry[] = [];
  public paths: GraphSyntheticPathEntry[] = [];
  public total_pages: number = 0;
  public active_page_index: number = 0;

  constructor(data?) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        switch (field) {
          case 'molecules':
            this.molecules = jsonData['molecules'].map(
              (molecule) => new GraphMoleculeEntry(molecule),
            );
            break;
          case 'reactions':
            this.reactions = jsonData['reactions'].map(
              (reaction) => new GraphReactionEntry(reaction),
            );
            break;
          case 'reaction_nodes':
            this.reaction_nodes = jsonData['reaction_nodes'].map(
              (reactionNode) => new GraphReactionNodeEntry(reactionNode),
            );
            break;
          case 'paths':
            this.paths = jsonData['paths'].map((path) => new GraphSyntheticPathEntry(path));
            break;
          case 'total_pages':
            this.total_pages = jsonData['total_pages'];
            break;
          case 'active_page_index':
            this.active_page_index = jsonData['active_page_index'];
            break;
          default:
            console.error(
              `Unexpected field '${field}' in data provided to DiversityResources:ctor.`,
            );
            break;
        }
      }
    }
  }

  public isEmpty(): boolean {
    return (
      this.molecules.length === 0 &&
      this.reaction_nodes.length === 0 &&
      this.reactions.length === 0 &&
      this.paths.length === 0
    );
  }
}
