<div class="new-keyword-section">
  <div class="close-dialog-button">
    <button class="ch-action-icon-hover" mat-icon-button (click)="closeDialog()">
      <mat-icon
        class="grey-icon"
        matTooltip="Close"
        matTooltipPosition="above"
        matTooltipClass="ch-tooltip"
      >
        close</mat-icon
      >
    </button>
  </div>
  <div class="save-keyword-name">
    <mat-form-field [floatLabel]="'never'" class="value-keyword-field">
      <input
        matInput
        [(ngModel)]="keyword"
        placeholder="Add Keyword"
        (keydown)="handleKeyEvents($event)"
      />
    </mat-form-field>
  </div>
  <div class="select-filter-container">
    <mat-form-field class="ch-form-field ch-select-field select-filter" appearance="outline">
      <mat-select [(ngModel)]="keywordFilterType">
        <mat-option value="Limit to">Limit To</mat-option>
        <mat-option value="Exclude">Exclude</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      class="ch-action-icon-hover add-icon-btn"
      mat-icon-button
      (click)="saveKeyword()"
      [disabled]="!keyword"
    >
      <mat-icon class="grey-icon">add</mat-icon>
    </button>
  </div>
</div>
