import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Batch } from '../../services/models/batch';

export type BatchMolecule = {
  smiles: string;
  molfile?: string;
  id?: number;
};
const newBatch: Batch = new Batch({
  name: '',
});

@Injectable({
  providedIn: 'root',
})
export class BatchAnalysisService {
  public batchMolecules: BehaviorSubject<BatchMolecule[]>;
  public isShowLoading: BehaviorSubject<boolean>;
  public newBatch: BehaviorSubject<Batch>;
  public isBatchMode: BehaviorSubject<boolean>;
  public smilesArrayForBatch: BehaviorSubject<string[]>;
  public isLibraryMode: BehaviorSubject<boolean>;

  constructor() {
    this.setDefaultValues();
  }

  public setDefaultValues() {
    this.batchMolecules = new BehaviorSubject<BatchMolecule[]>([]);
    this.isShowLoading = new BehaviorSubject<boolean>(false);
    this.newBatch = new BehaviorSubject<Batch>(newBatch);
    this.isBatchMode = new BehaviorSubject<boolean>(false);
    this.smilesArrayForBatch = new BehaviorSubject<string[]>([]);
    this.isLibraryMode = new BehaviorSubject<boolean>(false);
  }
}
