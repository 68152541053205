<div mat-dialog-title class="ch-info-dialog-top">
  <span>
    <span [innerHtml]="title"></span>
    <mat-icon *ngIf="!synthiaLiteEnabled" (click)="dialogRef.close()" class="icon-18"
      >close</mat-icon
    >
  </span>
</div>
<mat-dialog-content>
  <div class="info-msg" *ngIf="messageIsHtml" [innerHTML]="message"></div>

  <p class="info-msg" *ngIf="!messageIsHtml">
    {{ message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="ch-info-dialog-controls">
    <button
      *ngIf="reportErrorButton"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('reportComputationError')"
    >
      Report this error
    </button>
    <button
      mat-button
      *ngIf="!synthiaLiteEnabled && showCloseButton"
      class="ch-outline-btn"
      [buttonOutline]="'primary'"
      (click)="dialogRef.close()"
    >
      {{ closeButtonText }}
    </button>
    <button
      mat-button
      class="ch-synthia-lite-button"
      *ngIf="synthiaLiteEnabled && showCloseButton"
      (click)="dialogRef.close()"
    >
      {{ closeButtonText }}
    </button>
    <button
      *ngIf="synthiaPromoButtton"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('goToSynthiaPromoPage')"
    >
      Contact us to learn more
    </button>
    <button
      *ngIf="showUpgradeOptionButton"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('goToUpgradeOptions')"
    >
      Upgrade Options
    </button>
    <button
      *ngIf="showAcceptButton"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close(true)"
    >
      {{ acceptButtonText }}
    </button>
  </div>
</mat-dialog-actions>
