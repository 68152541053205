import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ch-plan-warning-dialog',
  templateUrl: './plan-warning-dialog.component.html',
  styleUrls: ['./plan-warning-dialog.component.scss'],
})
export class PlanWarningDialogComponent implements OnInit {
  @Input() public warningMessage: string = '';
  @Input() public isExpiryWarning: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PlanWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public injectedData: {
      warningMessage: string;
      isExpiryWarning: boolean;
    },
  ) {}

  public ngOnInit(): void {
    if (this.injectedData.warningMessage) {
      this.warningMessage = this.injectedData.warningMessage;
    }
    if (this.injectedData.isExpiryWarning) {
      this.isExpiryWarning = this.injectedData.isExpiryWarning;
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public upgradePlan() {
    this.dialogRef.close('upgrade');
  }

  @HostListener('window:keyup.esc')
  public onKeyUp() {
    this.dialogRef.close();
  }
}
