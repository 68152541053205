<div class="ch-tech-support" [class.synthia-lite-tech-support]="isSynthiaLiteEnabled">
  <div class="main-content">
    <div class="information-container">
      <p class="title">We are here to help</p>
      <p>Our Technical Service Team can help you with any immediate issues you may be having.</p>
      <p class="phone-label">Contact by Phone</p>
      <div class="phone-container" [class.synthia-lite-phone-container]="isSynthiaLiteEnabled">
        <mat-icon>phone</mat-icon>
        800-325-5832
      </div>
      <p class="call-hours">(representatives available M-F 8 am to 8 pm EST)</p>
      OR
      <p>Send us an EMAIL by filling out the simple form below</p>
    </div>

    <div class="form-container">
      <form autocomplete="off" #techSupportForm="ngForm">
        <table class="form-table">
          <tr *ngIf="!isAuthorized">
            <td>
              <p class="input-title">Please provide your account email<span>*</span></p>
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  placeholder="Email"
                  [formControl]="userEmailControl"
                  required="true"
                  name="userNameInput"
                  [(value)]="email"
                />
                <mat-error *ngIf="userEmailControl.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <p class="input-title">What can we help you with? <span>*</span></p>
              <mat-form-field class="form-field" hideRequiredMarker="true" appearance="outline">
                <mat-label>Issue</mat-label>
                <mat-select
                  placeholder="Please select..."
                  [formControl]="issueTitleControl"
                  required="true"
                  name="titleInput"
                  [(value)]="issueTitle"
                >
                  <mat-option *ngFor="let title of predefinedTitles" [value]="title.name">
                    {{ title.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="issueTitleControl.hasError('required')">
                  Issue is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>

          <tr *ngIf="issueTitleControl.value === 'Other'">
            <td>
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Specify other issue</mat-label>
                <input
                  matInput
                  name="otherIssueTitle"
                  maxlength="250"
                  [(ngModel)]="otherIssueTitle"
                  required="true"
                  #otherIssueTitleInput="ngModel"
                  [disabled]="sendingForm"
                />
                <mat-error *ngIf="otherIssueTitleInput.hasError('required')">
                  This field is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              <p class="input-title">
                Please describe step by step what you were doing before the problem appeared.<br />Add
                analysis parameters and target molecule SMILES if the problem is related to an
                analysis. <span>*</span>
              </p>
              <mat-form-field class="form-field" hideRequiredMarker="true" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  placeholder="Please share more details about the issue you are experiencing"
                  rows="5"
                  required="true"
                  name="messageInput"
                  maxlength="300000"
                  #messageInput="ngModel"
                  [(ngModel)]="description"
                  [disabled]="sendingForm"
                ></textarea>
                <mat-error *ngIf="messageInput.hasError('required')">
                  Description is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <p class="notice ch-clr-secondary-strong">
                <strong>Notice:</strong> If you do not wish to share confidential information with
                the Synthia<sup class="ch-trade-mark fs-8-px">TM</sup> team, please modify any
                relevant chemical structures accordingly.
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p class="input-title">
                <strong
                  >If applicable, please upload screenshots of the problem via the upload
                  below.</strong
                ><br />
                <span class="notice ch-clr-secondary"
                  >Allowed formats: JPEG, PNG, GIF, BMP. Maximum size of all attachments
                  {{ bytesToSize(maximumMailAttachmentsSize, 'decimal') }}</span
                >
              </p>
              <div class="file-input">
                <label>
                  <input
                    type="file"
                    accept="image/bmp, image/gif, image/jpeg, image/png, image/pjpeg"
                    (change)="fileChangeListener($event)"
                    multiple
                    title=" "
                    [disabled]="sendingForm"
                  />
                  <div
                    class="select-button-imitation"
                    [class.ch-synthia-lite-button]="isSynthiaLiteEnabled"
                    [class.select-button-diabled]="sendingForm"
                  >
                    Select files
                  </div>
                </label>
              </div>
              <div>
                <div *ngFor="let image of selectedFiles" class="image-info">
                  <mat-icon class="image-icon">image</mat-icon> {{ image.name }} ({{
                    getImageSize(image)
                  }})<button mat-icon-button [disabled]="sendingForm" (click)="removeImage(image)">
                    <mat-icon
                      [class.remove-image-icon]="!sendingForm"
                      [class.disabled-icon]="sendingForm"
                      >remove_circle</mat-icon
                    >
                  </button>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <p class="terms">
                <mat-checkbox
                  name="rules"
                  color="primary"
                  [(ngModel)]="agreed"
                  [disabled]="sendingForm"
                  required="true"
                >
                </mat-checkbox>

                <ng-template [ngIf]="isUserFromUSA()">
                  I agree that Merck KGaA, Darmstadt, Germany and its affiliates may process my
                  personal data such as name, address, email address, financial information,
                  profession, area of expertise, purchasing history or browsing behavior in order to
                  (1) provide me with information via various channels including but not limited to
                  email, mail, SMS, and phone about (a) products, innovations, and special offers
                  regarding products and fields for which I have demonstrated interest and (b)
                  upcoming events like trade and vendor shows, seminars and webinars, (2) optimize
                  and personalize advertising measures using online marketing services including but
                  not limited to Facebook Custom Audience, LinkedIn Contact Targeting, and Google
                  Ads Customer Match and (3) provide me with customized information by creating a
                  customer profile. My consent is valid until revoked. I can
                  <a
                    href="https://www.sigmaaldrich.com/help-welcome/registration/privacy-policy.html#revoke"
                    target="_blank"
                    rel="noreferrer noopener"
                    >revoke my</a
                  >
                  consent at any time via email. For more information, see our
                  <a
                    href="https://www.sigmaaldrich.com/help-welcome/registration/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    >privacy policy</a
                  >.
                </ng-template>
                <ng-template [ngIf]="!isUserFromUSA()">
                  I agree that Merck KGaA, Darmstadt, Germany and its affiliates may process my
                  personal data such as name, address, email address, financial information,
                  profession, area of expertise, purchasing history or browsing behavior in order to
                  (1) provide me with information via various channels including but not limited to
                  email, mail, SMS, and phone about (a) products, innovations, and special offers
                  regarding products and fields for which I have demonstrated interest and (b)
                  upcoming events like trade and vendor shows, seminars and webinars, (2) optimize
                  and personalize advertising measures using online marketing services including but
                  not limited to Facebook Custom Audience, LinkedIn Contact Targeting, and Google
                  Ads Customer Match and (3) provide me with customized information by creating a
                  customer profile. My consent is valid until revoked. I can
                  <a
                    href="https://www.sigmaaldrich.com/help-welcome/registration/privacy-policy.html#revoke"
                    target="_blank"
                    rel="noreferrer noopener"
                    >revoke my consent</a
                  >
                  at any time via email privacy&#64;merckgroup.com. For more information, see our
                  <a
                    href="https://www.sigmaaldrich.com/help-welcome/registration/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    >privacy policy</a
                  >.
                </ng-template>
                <span>*</span>
              </p>
              <p class="terms">The fields marked with <span>*</span> are required.</p>
            </td>
          </tr>

          <tr>
            <td class="btn-container">
              <button
                mat-raised-button
                color="primary"
                [class.ch-synthia-lite-button]="isSynthiaLiteEnabled"
                id="submit-button"
                [disabled]="
                  !userEmailControl.value ||
                  !issueTitleControl.value ||
                  (issueTitleControl.value === 'Other' && !otherIssueTitle) ||
                  !messageInput.valid ||
                  !agreed ||
                  sendingForm
                "
                (click)="sendEmail()"
              >
                <span>{{ sendingForm ? 'SENDING' : 'SUBMIT' }}</span
                ><mat-spinner
                  *ngIf="sendingForm"
                  [strokeWidth]="2"
                  [diameter]="20"
                  class="sending-spinner"
                ></mat-spinner>
              </button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</div>
