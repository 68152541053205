import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { AuthorizationService } from './authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements OnDestroy {
  public isAuthorized: boolean;
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(private authService: AuthorizationService) {
    this.authService
      .isAuthorized()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((authorized: boolean) => {
        this.isAuthorized = authorized;
      });
  }

  public canActivate(): boolean {
    if (this.isAuthorized) {
      return false;
    }
    return true;
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }
}
