<nav class="breadcrumb-nav" aria-label="breadcrumb" *ngIf="breadcrumb.length > 0">
  <div class="breadcrumb" (mouseleave)="breadcrumbMoreIcon(false)">
    <div
      class="home-icon ch-text-gray"
      [class.disabled-home]="routeStateService.stopRoutingToHome.value"
    >
      <a routerLink (click)="navigateHome()">
        <mat-icon class="ch-icon-hover" svgIcon="ch:home"></mat-icon>
      </a>
    </div>
    <span *ngIf="isDiversityResultView" class="diversity-library">
      Targets Found: {{ diversityTarget }}</span
    >
    <div
      class="breadcrumb breadcrumb-text ch-text-gray"
      *ngFor="let item of breadcrumb; let i = index"
    >
      <div class="ch-text-gray" [class.has-url]="item.url" (click)="onMenuItemClick(item)">
        <span>/ &nbsp;</span>
        <span>
          <mat-icon
            class="ch-icon-hover grey-icon"
            *ngIf="item.svgIcon"
            [svgIcon]="item.svgIcon"
          ></mat-icon>
          <mat-icon class="ch-icon-hover grey-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        </span>
        <span
          *ngIf="!isRenameClicked || (isRenameClicked && item.label !== analysis.name)"
          [ngClass]="getBreadCrumbTitleClass(i)"
          [matTooltip]="getTooltip(item.label, maxBreadCrumbLength)"
          matTooltipClass="ch-tooltip full-width-tooltip"
        >
          &nbsp; {{ getBreadCrumbTitle(item.label) }}
        </span>
        <span
          (mouseenter)="breadcrumbMoreIcon(true)"
          class="breadcrumb-more-icons"
          *ngIf="!!analysis && !isRenameClicked"
        >
          <mat-icon class="mat-icon-analysis-header"> more_vert</mat-icon>
        </span>
        <div *ngIf="!isRenameClicked && !!analysis" class="breadcrumb-results-right">
          <button
            *ngIf="isNewResultsAvailable"
            class="vert-icon"
            mat-icon-button
            [matTooltip]="appConstantsService.newResultsFoundTooltip"
            matTooltipPosition="after"
            matTooltipClass="ch-tooltip"
            (click)="loadNewResults()"
          >
            <mat-icon
              class="bell-ring ch-bell-icon-hover"
              [@ringAnimation]="ringAnimationState"
              (@ringAnimation.done)="continueRingAnimation()"
              >notifications</mat-icon
            >
          </button>
          <mat-spinner
            *ngIf="
              (isComputationActive || analysisService.isComputationActivePreviousValue) &&
              processingOfNewResults
            "
            [strokeWidth]="2"
            [diameter]="18"
            color="accent"
            class="vert-icon processing-new-results-spinner"
            [matTooltip]="appConstantsService.processingNewResultsTooltip"
            matTooltipClass="ch-tooltip"
            matTooltipPosition="after"
          >
          </mat-spinner>
          <span
            class="loading label-container"
            *ngIf="mode === ResultsView.PATHWAYS && isComputationActive"
          >
            COMPUTATION IN PROGRESS
          </span>
          <button
            *ngIf="
              !stoppingComputation &&
              (mode === ResultsView.PATHWAYS && isComputationActive
                | chIsStopComputationButtonAvailable
                  : isComputationStopped
                  : isAutomaticRetrosynthesisAnalysis || libraryView)
            "
            class="ch-active-stop-button ch-clr-primary"
            [class.ch-clr-primary-mlight]="stoppingComputation"
            (click)="stopActiveComputation()"
            mat-stroked-button
            [disabled]="stoppingComputation"
          >
            {{ stopButtonMessage }}
          </button>
          <span
            class="stopping-btn"
            *ngIf="stoppingComputation && !isComputationStopped && isComputationActive"
          >
            Stopping
          </span>
          <button
            *ngIf="hasWarnings"
            class="ch-clr-warn"
            mat-icon-button
            [matTooltip]="appConstantsService.analysisGenericWarningMessageTitle"
            matTooltipShowDelay="250"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            (click)="onClickShowWarnings()"
          >
            <mat-icon class="ch-clr-accent-strong ch-warn-icon">warning</mat-icon>
          </button>
          <button
            *ngIf="hasComputationError"
            class="ch-clr-warn"
            mat-icon-button
            [matTooltip]="appConstantsService.computationErrorTitleMessage"
            matTooltipShowDelay="250"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            (click)="onClickComputationError()"
          >
            <mat-icon class="computation-error ch-error-icon-hover">error_outline</mat-icon>
          </button>
          <span
            class="ch-text-gray label-container"
            *ngIf="
              showPathsCount &&
              !isFilterApplied &&
              !libraryView &&
              !isDiversityLibrary &&
              !isDiversityResultView
            "
          >
            Paths Found: {{ progressItemSummary.paths_found }}
          </span>
          <span
            class="ch-text-gray label-container filter-number"
            *ngIf="showPathsCount && isFilterApplied"
          >
            Filtered results: {{ filteredResultsLength }}
          </span>
          <span class="target-header targets-library" *ngIf="showTargetsCount">
            Targets in the Library ({{ libraryTargets?.targets?.with_result?.length }}/{{
              libraryTargets?.targets?.with_result?.length +
                libraryTargets?.targets?.without_result?.length
            }})
          </span>
          <button
            mat-icon-button
            [matTooltip]="appConstantsService.targetMoleculesMessage"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            [ngClass]="{ 'ch-action-icon-hover': true }"
            *ngIf="showTargetsCount && libraryView"
            (click)="viewTargetMolecules()"
          >
            <mat-icon
              class="action-icon grey-icon visibility-icon"
              matTooltipPosition="above"
              matTooltipClass="ch-tooltip"
              svgIcon="ch:visibility-outline"
            >
            </mat-icon>
          </button>
          <div
            class="breadcrumb-icon-container"
            *ngIf="showMoreIcons && !!analysis && !isRenameClicked"
          >
            <div class="breadcrumb-icon-list">
              <div class="button-group">
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="isShared"
                  (click)="renameAnalysis()"
                >
                  <mat-icon
                    [matTooltip]="
                      isShared
                        ? appConstantsService.editDisableTooltip
                        : appConstantsService.editAnalysisTooltip
                    "
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon edit-icon"
                    svgIcon="ch:edit"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="!analysis?.rerun || isRerunDisabledForNoc"
                  (click)="rerunAnalysis($event)"
                >
                  <mat-icon
                    [matTooltip]="rerunTooltip"
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon custom-icon rerun-icon"
                    svgIcon="ch:rerun"
                  >
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  (click)="commentAnalysis($event)"
                >
                  <p
                    *ngIf="commentsCount?.total > 0"
                    class="count-card"
                    [class.unread-count]="commentsCount?.unread > 0"
                  >
                    {{ getCommentsCount() }}
                  </p>
                  <mat-icon
                    class="grey-icon comment-icon"
                    [matTooltip]="appConstantsService.commentTooltip"
                    matTooltipPosition="below"
                    matTooltipClass="ch-tooltip"
                    svgIcon="ch:comment"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  synthiaLiteDisabledFeature
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="disableShareButton"
                  (click)="shareAnalysis($event)"
                >
                  <p *ngIf="shareCount > 0" class="count-card">
                    {{ shareCount > maxCount ? maxCount : shareCount }}
                  </p>
                  <mat-icon
                    class="grey-icon share-icon"
                    [matTooltip]="appConstantsService.shareTooltip"
                    matTooltipPosition="below"
                    matTooltipClass="ch-tooltip"
                    svgIcon="ch:share"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="!isFavoritingAllowed"
                  (click)="handleFavoriteAnalysis($event)"
                >
                  <mat-icon
                    class="grey-icon default-icon"
                    [matTooltip]="
                      isFavoritingAllowed
                        ? analysis.is_favorite
                          ? appConstantsService.unfavoriteTooltip
                          : appConstantsService.favoriteTooltip
                        : appConstantsService.favoriteDeletedTooltip
                    "
                    matTooltipClass="ch-tooltip"
                  >
                    {{ isFavorited ? 'star' : 'star_outline' }}
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button delete-analysis"
                  [disabled]="isDeleteButtonDisabled"
                  (click)="deleteOrRestoreAnalysis($event)"
                >
                  <mat-icon
                    [matTooltip]="
                      isDeleteButtonDisabled
                        ? appConstantsService.deleteAnalysisDisableTooltip
                        : appConstantsService.deleteAnalysisTooltip
                    "
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon"
                  >
                    {{ getDeleteRestoreAnalysisIcon() }}
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              mode === ResultsView.PATHWAYS &&
              !libraryView &&
              !isDiversityLibrary &&
              !isDiversityResultView
            "
            [ngClass]="{
              'dropdown-container': true,
              'computation-progress': isComputationActive
            }"
          >
            <span class="dropdown-label" [class.disabled-label]="isSortDisabled()">Sort By</span>
            <mat-form-field
              appearance="outline"
              class="ch-form-field ch-select-field pathway-field"
            >
              <mat-select
                class="sort-select"
                [(ngModel)]="selectedSort"
                (selectionChange)="onSortChange($event.value)"
                [disabled]="isSortDisabled()"
              >
                <ng-container *ngFor="let sortOption of sortOptions">
                  <mat-option [value]="sortOption" class="sort-by-dropdown-option">
                    <span>{{ sortOption }}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <button
            mat-icon-button
            [ngClass]="{
              'ch-action-icon-hover': true,
              'sort-icon': isComputationActive
            }"
            *ngIf="
              mode === ResultsView.PATHWAYS &&
              !libraryView &&
              !isDiversityLibrary &&
              !isDiversityResultView
            "
            [disabled]="isSortDisabled()"
            (click)="onSortOrderChange()"
          >
            <mat-icon
              class="action-icon grey-icon"
              [class.ascending-icon]="isAscending"
              matTooltipPosition="above"
              matTooltipClass="ch-tooltip"
            >
              sort
            </mat-icon>
          </button>
        </div>
        <div *ngIf="isRenameClicked && item.label === analysis.name" class="analysis-rename">
          <mat-form-field class="value-rename-field">
            <input
              matInput
              [(ngModel)]="analysisRename"
              ch-select-on-autofocus
              (keydown)="handleKeyEvents($event, i)"
            />
          </mat-form-field>
          <div class="button-group">
            <button
              mat-icon-button
              class="ch-action-icon-hover"
              (click)="saveEditedAnalysisName(i)"
              [disabled]="!analysisRename"
            >
              <mat-icon
                class="action-icon grey-icon"
                matTooltip="Save"
                matTooltipPosition="above"
                matTooltipClass="ch-tooltip"
                svgIcon="ch:save-outline"
              ></mat-icon>
            </button>
            <button mat-icon-button class="ch-action-icon-hover" (click)="closeRenameDialog()">
              <mat-icon
                class="action-icon grey-icon"
                matTooltip="Close"
                matTooltipPosition="above"
                matTooltipClass="ch-tooltip"
                >close</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="right-side"
    *ngIf="!!analysis"
    [ngClass]="libraryView ? 'pathway-view-library' : 'pathway-view'"
  >
    <div
      *ngIf="
        mode === ResultsView.PATHWAYS &&
        !libraryView &&
        !isDiversityLibrary &&
        !isDiversityResultView
      "
      class="label-container"
      matTooltipPosition="below"
      matTooltipClass="ch-tooltip"
      [matTooltip]="
        !isAllPathLoaded
          ? appConstantsService.globalPathwaysDisabledTooltip
          : appConstantsService.toggleTooltip
      "
    >
      <mat-icon
        class="toggle-icon"
        svgIcon="ch:block_view"
        [class.slide-toggle]="!isAllPathLoaded"
      ></mat-icon>
      <mat-slide-toggle
        color="primary"
        [class.slide-toggle]="!isAllPathLoaded"
        (change)="pathwayViewEnabled($event)"
        [(ngModel)]="isPathwayViewOnly"
      >
      </mat-slide-toggle>
      <mat-icon
        class="toggle-icon"
        svgIcon="ch:path_view"
        [class.slide-toggle]="!isAllPathLoaded"
      ></mat-icon>
    </div>
    <button
      [ngClass]="
        isSinglePathwaySelected ||
        isPathwayViewOnly ||
        ((libraryView || isDiversityLibrary) && isAllPathwaysLoaded)
          ? 'ch-action-icon-hover'
          : 'pathway-option-hidden'
      "
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="
        (mode === ResultsView.PATHWAYS || libraryView || isDiversityLibrary) &&
        !isDiversityResultView
      "
      (click)="updateNodeLabelControlChange()"
    >
      <mat-icon class="grey-icon" svgIcon="ch:pathway_option"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="grid-menu">
      <div>
        <div class="option-header">Pathway Options</div>
        <mat-divider class="div-options-header"></mat-divider>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.repeatedReaction"
          (change)="nodeLabelControlChange(nodeLabelTypes.repeatedReaction, $event)"
          *ngIf="libraryView"
        >
          Highlight repeated reactions
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.repeatedMolecule"
          (change)="nodeLabelControlChange(nodeLabelTypes.repeatedMolecule, $event)"
          *ngIf="libraryView"
        >
          Highlight repeated molecules
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.reactionName"
          (change)="nodeLabelControlChange(nodeLabelTypes.reactionName, $event)"
        >
          Reaction Names
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.typicalConditions"
          (change)="nodeLabelControlChange(nodeLabelTypes.typicalConditions, $event)"
        >
          Typical Conditions
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.legendTags"
          (change)="nodeLabelControlChange(nodeLabelTypes.legendTags, $event)"
        >
          Legend Tags
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.publishedReferences"
          (change)="nodeLabelControlChange(nodeLabelTypes.publishedReferences, $event)"
        >
          Published Reaction References
        </mat-checkbox>
      </div>
    </mat-menu>

    <span
      *ngIf="!libraryView && !isDiversityLibrary && !isDiversityResultView"
      [matTooltip]="getPathViewButtonTooltip()"
      matTooltipClass="ch-tooltip"
    >
      <button
        mat-button
        class="view-button"
        [class.active-button]="mode === ResultsView.PATHWAYS"
        (click)="changeMode(ResultsView.PATHWAYS)"
        [disabled]="loadingAnalysis || analysis?.isAlgorithmWithoutPathways()"
      >
        <mat-icon>linear_scale</mat-icon> PATH
      </button>
    </span>
    <button
      [matTooltip]="appConstantsService.graphViewTooltip"
      matTooltipClass="ch-tooltip"
      mat-button
      class="view-button"
      [class.active-button]="mode === ResultsView.GRAPH"
      (click)="changeMode(ResultsView.GRAPH)"
      [disabled]="loadingAnalysis || !isAllPathwaysLoaded"
      *ngIf="!libraryView && !isDiversityLibrary && !isDiversityResultView"
    >
      <mat-icon>bubble_chart</mat-icon> GRAPH
    </button>
  </div>
</nav>
