import { Observable } from 'rxjs';
import { CommentDialogComponent } from '../components/comment-dialog';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommentDialogService {
  public dialogRef: MatDialogRef<CommentDialogComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public openCommentDialog(analysis: any, config?: any): Observable<any> {
    const config_data = config ? config : this.config;
    this.dialogRef = this.dialog.open(CommentDialogComponent, config_data);
    this.dialogRef.componentInstance.analysis = analysis;

    return this.dialogRef.beforeClosed();
  }
}
