import { trigger, state, style, transition, animate } from '@angular/animations';
export enum AnimateTypes {
  slideLeftOut = 'slideLeftOut',
  slideRightIn = 'slideRightIn',
  slideRightOut = 'slideRightOut',
  slideLeftIn = 'slideLeftIn',
  slideUpOut = 'slideUpOut',
  slideDownIn = 'slideDownIn',
  slideDownOut = 'slideDownOut',
  slideUpIn = 'slideUpIn',
  slideUpReset = 'slideUpReset',
  slideDownReset = 'slideDownReset',
  default = '',
}
export const transitionTime = 300;

export const SLIDE_ANIMATION = trigger('slideAnimation', [
  state(AnimateTypes.slideRightOut, style({ left: '200%', position: 'relative' })),
  state(AnimateTypes.slideRightIn, style({ left: '0%', position: 'relative' })),
  state(AnimateTypes.slideLeftOut, style({ left: '-200%', position: 'relative' })),
  state(AnimateTypes.slideLeftIn, style({ left: '0%', position: 'relative' })),
  state(AnimateTypes.slideUpOut, style({ transform: 'translate(0px, -80%)' })),
  state(AnimateTypes.slideUpIn, style({ transform: 'translate(0px, 0%)' })),
  state(AnimateTypes.slideDownOut, style({ transform: 'translate(0px, 80%)' })),
  state(AnimateTypes.slideDownIn, style({ transform: 'translate(0px, 0%)' })),
  state(AnimateTypes.slideUpReset, style({ transform: 'translate(0px, -200%)' })),
  state(AnimateTypes.slideDownReset, style({ transform: 'translate(0px, 200%)' })),
  transition('slideUpOut => slideDownReset', [animate('0ms ease-out')]),
  transition('slideDownOut => slideUpReset', [animate('0ms ease-out')]),
  transition('slideLeftOut => slideRightOut', [animate('0ms ease-out')]),
  transition('slideRightOut => slideLeftOut', [animate('0ms ease-out')]),
  transition('* => *', [animate(transitionTime + 'ms ease-out')]),
]);
