import { isString } from '../../components/utils';

export class DashboardAnalysisCount {
  public favorites: number = 0;
  public status_all: number = 0;
  public pending: number = 0;
  public completed: number = 0;
  public type_all: number = 0;
  public automatic_retrosynthesis: number = 0;
  public manual_retrosynthesis: number = 0;
  public target_library: number = 0;
  public batch: number = 0;
  public shared_all: number = 0;
  public shared_by_me: number = 0;
  public shared_with_me: number = 0;
  public tags: number = 0;
  public diversity_library: number = 0;

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field of Object.keys(jsonData)) {
      if (this.hasOwnProperty(field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        }
      } else {
        switch (field) {
          case 'status':
            const statusDict = jsonData[field];
            this.status_all = statusDict['all'];
            this.pending = statusDict['pending'];
            this.completed = statusDict['completed'];
            break;
          case 'type':
            const typeDict = jsonData[field];
            this.type_all = typeDict['all'];
            this.automatic_retrosynthesis = typeDict['automatic_retrosynthesis'];
            this.manual_retrosynthesis = typeDict['manual_retrosynthesis'];
            this.batch = typeDict['batch'];
            this.target_library = typeDict['target_library'];
            this.diversity_library = typeDict['diversity_library'];
            break;
          case 'shared':
            const sharedDict = jsonData[field];
            this.shared_all = sharedDict['all'];
            this.shared_by_me = sharedDict['shared_by_me'];
            this.shared_with_me = sharedDict['shared_with_me'];
            break;
          default:
            console.warn(`Unexpected field '${field}' in data provided to AnalysisSettings:ctor.`);
            break;
        }
      }
    }
  }
}
