import {
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthTokenStorageName } from './authorization/authorization.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ECommHandlerService } from './ecomm-integration/services/ecomm-handler.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  private endpointsNotRequiringJWTToken: string[] = [
    '/jwt/',
    '/password-reset/',
    '/password-change/',
    '/token-verification/',
    '/tech-support/',
    '/announcements/',
    '/config/',
    '/sso/',
    '/firebase-config/',
    '/encryption-config/',
    '/verification-method/',
    '/register/',
    '/terms-and-conditions/',
    '/country/',
    '/verify-change-password-token/',
    '/resend-complete-registration-mail/',
    '/emd-verification/',
    '/tenant/',
    '/terms-and-conditions-without-placeholder/',
    '/check-blacklisted/',
  ];

  constructor(public router: Router, private eCommHandlerService: ECommHandlerService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest: HttpRequest<any>;
    let headers: HttpHeaders;
    const token = sessionStorage.getItem(AuthTokenStorageName);
    if (this.isJWTTokenRequiredForRequest(req)) {
      headers = req.headers.append('Content-Type', 'application/json');
    } else if (/rest-v[0-9]\//.test(req.url)) {
      // that ensures smiles verification endpoint is working correctly
      headers = req.headers
        .append('Accept', '*/*')
        .append('authorization', `JWT ${token}`)
        .append('Content-Type', 'application/json');
    } else if (
      req.url.startsWith(APP_CONFIG.CHEMATICA_API_URL) &&
      !req.url.endsWith('/index.html')
    ) {
      if (!!token) {
        // HACK: Unfortunately, Angular does not provide a way for passing information to interceptor (as metadata)
        // so we must use ugly ifs and url checking. Should be fixed if anyone finds a simple solution for it.
        // See GitHub issue: https://github.com/angular/angular/issues/18155,
        //                   https://github.com/angular/angular/pull/25751
        if (
          (req.url.includes('customer-inventory/') || req.url.includes('convert-molfile/')) &&
          (req.method === 'POST' || req.method === 'PUT')
        ) {
          headers = req.headers.append('authorization', `JWT ${token}`).append('Accept', '*/*');
        } else {
          headers = req.headers
            .append('Content-Type', 'application/json')
            .append('authorization', `JWT ${token}`)
            .append('Accept', '*/*');
        }
      } else {
        throw new HttpErrorResponse({
          error: 'Session expired.',
          headers: headers,
          status: 401,
          statusText: 'Authorization token has expired or become invalid.',
          url: req.url,
        });
      }
    } else if (/index.html/.test(req.url)) {
      headers = req.headers
        .append('Cache-Control', 'no-cache')
        .append('Pragma', 'no-cache')
        .append('Expires', '0');
    }

    clonedRequest = req.clone({ headers });
    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        // HACK: see https://github.com/angular/angular/issues/23334
        // Basically, HttpErrorResponse always places statusText 'OK' if there is no statusText. For errors, this
        // is highly misleading - `500 OK`, `400 OK`, etc.
        const statusText: string =
          error.error && typeof error.error.code === 'string' ? error.error.code : 'Server error';
        const errorWithoutMisleadingStatusText: HttpErrorResponse = new HttpErrorResponse({
          error: error.error,
          headers: error.headers,
          status: error.status,
          statusText: error.statusText !== 'OK' ? error.statusText : statusText,
          url: error.url,
        });

        if (error.error && error.error.code === 'compliance-statement-not-accepted') {
          this.redirectToSignIn();
        }
        if (
          error.error &&
          error.error.code === 'error-user-invalid-or-expired-access-token' &&
          error.url.includes('/ecomm/')
        ) {
          this.forceECommUserAuth();
          return of(null);
        }
        return throwError(errorWithoutMisleadingStatusText);
      }),
    );
  }

  private redirectToSignIn() {
    setTimeout(() => {
      this.router.navigateByUrl('/sign-out');
    }, 1000);
  }
  private forceECommUserAuth() {
    this.eCommHandlerService.isECommUserAuth();
  }
  private isJWTTokenRequiredForRequest(req: HttpRequest<any>): boolean {
    return this.endpointsNotRequiringJWTToken.some((endpoint: string) => {
      return req.url.startsWith(APP_CONFIG.CHEMATICA_API_URL) && req.url.endsWith(endpoint);
    });
  }
}
