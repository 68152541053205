import {
  MoleculeLabelType,
  ReactionLabelType,
  MoleculeScalingType,
  ReactionColorType,
  MoleculeColorType,
  EdgeColorType,
  GraphModeType,
  PathDirectionType,
} from '../services';
import {
  isNull,
  isNullOrUndefined,
  isUndefined,
  isNumber,
  isString,
  autodownload,
  inventoryFlaskSVG,
  protectionIcon,
  regulatoryIcon,
  popularityIcon,
  similarityOneStar,
  similarityThreeStarFull,
  similarityTwoStar,
  similarityThreeStar,
  similarityThreeStarFullSvg,
  similarityThreeStarSvg,
  similarityTwoStarSvg,
  similarityOneStarSvg,
  popularityIconSvg,
  protectionIconSvg,
  regulatoryIconSvg,
  isValidUrl,
} from './utils';
import {
  COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY,
  COLOR_REACTION_STRATEGY_OR_TUNNELS,
  COLOR_MOLECULE_BORDER_PROTECTION_NEEDED,
  COLOR_MOLECULE_BORDER_REGULATED,
  COLOR_REACTION_PUBLISHED,
} from '../../colors';
import {
  GraphReactionNode,
  Graph,
  GraphNodeType,
  GraphMoleculeNode,
  PathMode,
  GraphEdgeType,
  GraphEdgeLabelValues,
} from '../services/graph-builder';

import * as dagre from 'dagre';
import { KnownReactionBases } from '../services/analysis/models/graph-reaction-node-entry';
import { boolean, string } from 'mathjs';

interface LabelDetails {
  charPerLine: number;
  fillStyle: string;
  font: string;
  textAlign: CanvasTextAlign;
  labelTextLines?: string[];
  typicalConditionLabelTextLines?: string[];
  referenceLabelLines?: string[];
  labelPosX: number;
  longLabelRealWidth: number;
}

/**
 * Adds a topological order to given graph nodes. This order is equal to the distance of target in discrete node units.
 * All nodes are treated equally. Reaction nodes are always given odd numbers and molecule nodes are given even values.
 * Requires continuity - all nodes must be connected, target is required.
 * @param nodes graph nodes to be given ranks
 */
export function setTopologicalNodesRank(nodes: GraphMoleculeNode[] & GraphReactionNode[]): void {
  let currentMolRank: number = 0;
  let currentRxRank: number = 1;
  const zerothRank: GraphMoleculeNode = nodes.find(
    (node) => isMoleculeNode(node) && node.isStartingMolecule,
  );
  if (zerothRank) {
    zerothRank.topologicalRank = currentMolRank;
  }
  currentMolRank += 2;
  const childReactions = nodes.filter(
    (node) => isReactionNode(node) && node.reaction_node.parent_reaction_node === null,
  );
  let childNodesTree: any[] = [];
  childNodesTree.push(childReactions);
  let growingTree: any[];
  while (childNodesTree.length > 0) {
    growingTree = [];
    for (const childNodes of childNodesTree) {
      for (const childNode of childNodes) {
        childNode.topologicalRank = currentRxRank;
        const matchingMolNodes: GraphMoleculeNode[] = nodes.filter(
          (node) =>
            isMoleculeNode(node) &&
            node.parentReactionNodeId === childNode.reaction_node.id &&
            !node.target,
        );
        for (const molNode of matchingMolNodes) {
          molNode.topologicalRank = currentMolRank;
        }

        const matchingSecondaryMolNodes: GraphMoleculeNode[] = nodes.filter(
          (node) =>
            isMoleculeNode(node) &&
            node.childReactionNodeId === childNode.reaction_node.id &&
            !node.target,
        );
        for (const secondaryMolNode of matchingSecondaryMolNodes) {
          secondaryMolNode.topologicalRank = currentMolRank;
        }

        const matchingRxNodes = nodes.filter(
          (node) =>
            isReactionNode(node) &&
            node.reaction_node.parent_reaction_node === childNode.reaction_node.id,
        );
        if (matchingRxNodes.length > 0) {
          growingTree.push(matchingRxNodes);
        }
      }
    }
    childNodesTree = growingTree;
    currentRxRank += 2;
    currentMolRank += 2;
  }
}

/**
 * Calculate molecule node popularity measure.
 * Note: This value is calculated in a kind of hacky way to keep it in synch with other GUIs.
 * @param node
 * @returns {number | void}
 */
export function calculateMoleculePopularity(node: GraphMoleculeNode): number | void {
  if (isNumber(node.molecule.out_degree)) {
    return node.molecule.out_degree;
  } else {
    const score = node['score'];
    if (!isNullOrUndefined(score)) {
      // Wonderment reduction: We do calculate popularity from score avoiding values with $ or N/A.
      if (isString(score)) {
        if (score.indexOf('$') === -1 && score.toLowerCase() !== 'n/a') {
          try {
            return Number(score);
          } catch (e) {} // tslint:disable-line:no-empty
        }
      } else {
        return score;
      }
    }
  }
}

/**
 * Calculate a molecule node label based on the analysis (algorithm), mode and node attributes.
 * Also set color, size and position of label.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {MoleculeLabelType} moleculeLabel Label type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only molecule nodes ignoring others).
 * @param {string} labelColor (optional) Label color.
 * @param {number} labelSize (optional) Size relative to node size.
 * @param {number} labelPosition (optional) Vertical offset from the center of node relative to the size of node.
 */
export function setMoleculeLabels(
  mode: GraphModeType,
  moleculeLabel: MoleculeLabelType,
  nodes: GraphMoleculeNode[] & GraphReactionNode[],
  labelColor: string = '#000',
  labelSize: number = 1.3,
  labelPosition: number = 2.5,
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setMoleculeLabels: Unsupported mode "${mode}"`);
    return;
  }

  const getFormattedCost = (cost: any): string => {
    let formattedCost: string;
    if (isNull(cost) || !isNumber(cost) || cost === 0) {
      formattedCost = '';
    } else {
      formattedCost = `$${cost < 10 ? cost.toFixed(2) : Math.floor(cost).toFixed(0)}`;
    }
    return formattedCost;
  };

  for (const node of nodes) {
    if (isMoleculeNode(node)) {
      node.labelColor = labelColor;
      node.labelSize = labelSize;
      node.labelPosition = labelPosition;
      node.label = undefined;

      switch (moleculeLabel) {
        case 'none':
          break;

        case 'name':
        case 'formula':
          if (node.molecule[moleculeLabel]) {
            node.label = `${node.molecule[moleculeLabel]}`;
          }
          break;

        case 'brn':
        case 'cas':
          const arrayAttribute = node.molecule[moleculeLabel];
          if (arrayAttribute && arrayAttribute.length && !isNullOrUndefined(arrayAttribute[0])) {
            node.label = `${arrayAttribute[0]}`;
          }
          break;

        case 'popularity':
          const popularity = calculateMoleculePopularity(node);
          if (!isUndefined(popularity)) {
            node.label = `${popularity}`;
          }
          break;

        case 'molWeight':
          if (node.molecule['mol_weight']) {
            node.label = `${node.molecule['mol_weight'].toFixed(2)}`;
          }
          break;

        case 'score':
          if (node[moleculeLabel]) {
            node.label = `${node[moleculeLabel]}`;
          }
          break;

        case 'cost':
          const cost = node.molecule[moleculeLabel];
          node.label = getFormattedCost(cost);
          break;

        case 'massPerGramOfTarget':
          const massPerGramOfTarget = node['massPerGramOfTarget'];
          if (massPerGramOfTarget) {
            node.label = `${massPerGramOfTarget}g`;
            if (node.label === '1g') {
              node.label = '1.00g';
            }
          }
          break;

        case 'costOrPopularity':
          if (node.molecule.cost) {
            const moleculeCost = node.molecule['cost'];
            node.label = getFormattedCost(moleculeCost);
          } else if (node.molecule.mushroom !== null && mode.toLowerCase() === 'graph') {
            const moleculePopularity = calculateMoleculePopularity(node);
            if (!isUndefined(moleculePopularity)) {
              node.label = `${moleculePopularity}`;
            }
          }
          break;

        default:
          console.error(`setMoleculeLabels: Unsupported molecule label type "${moleculeLabel}"`);
      }
    }
  }
}

/**
 * Function to set legeng tag visiblity
 * @param showLegendTag
 * @param nodes
 */
export function setLegendTagVisibility(
  showLegendTag: boolean,
  nodes: GraphMoleculeNode[] & GraphReactionNode[],
) {
  for (const node of nodes) {
    node.showLegendTag = showLegendTag;
  }
}

export function setDiverisifyChecked(
  showSelected: boolean,
  nodes: GraphMoleculeNode[],
  nodeInfo?: GraphMoleculeNode,
  resetSelection?: boolean,
) {
  for (const node of nodes) {
    if (resetSelection) {
      node.isCheckedForDiverisity = false;
    } else {
      if (node.id === nodeInfo.id) {
        node.isCheckedForDiverisity = showSelected;
      }
    }
  }
}

/**
 * Set a reaction node color based on the analysis (algorithm), mode and node attributes.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {ReactionColorType} reactionColor type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only reaction nodes ignoring others).
 * @param {boolean} printView Is print view opened.
 * @param {string} pointColor (optional) Color of the pointed reaction.
 * @param {string} selectColor (optional) Color of the selected reaction.
 */
export function setReactionColors(
  mode: GraphModeType,
  reactionColor: ReactionColorType,
  nodes: any[],
  printView: boolean,
  pointColor: string = '#FFA000',
  selectColor: string = '#0F69AF',
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setReactionColors: Unsupported mode "${mode}"`);
    return;
  }

  for (const node of nodes) {
    if (isReactionNode(node)) {
      switch (reactionColor) {
        case 'default':
          node.color = node.defaultColor;
          break;
        case 'base':
          node.color = node.colorByBase;
          break;
        case 'point':
          node.color = pointColor;
          break;
        default:
          console.error(`setReactionColors: Unsupported reaction color type "${reactionColor}"`);
      }

      if (
        (node.markedForPrint || node.highlightAsPathwayPart || node.highlightAsSame) &&
        !printView &&
        reactionColor !== 'point'
      ) {
        node.color = selectColor;
      }

      if (node.pointed && !printView) {
        node.color = pointColor;
      }
    }
  }
}

/**
 * Set a molecule node color based on the analysis (algorithm), mode and node attributes.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {MoleculeColorType} moleculeColor type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only molecule nodes ignoring others).
 * @param {boolean} printView Is print view opened.
 * @param {string} pointColor (optional) Color of the pointed reaction.
 * @param {string} selectColor (optional) Color of the selected reaction.
 */
export function setMoleculeColors(
  mode: GraphModeType,
  moleculeColor: MoleculeColorType,
  nodes: any[],
  printView: boolean,
  pointColor: string = '#FFA000',
  selectColor: string = '#0F69AF',
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setMoleculeColors: Unsupported mode "${mode}"`);
    return;
  }

  for (const node of nodes) {
    if (node.type === 'circle') {
      switch (moleculeColor) {
        case 'point':
          node.color = pointColor;
          node.borderColor = pointColor;
          break;
        default:
          node.color = node.defaultColor;
          node.borderColor = node.defaultBorderColor;
          break;
      }

      if (
        (node.markedForPrint || node.highlightAsPathwayPart || node.highlightAsSame) &&
        !printView &&
        moleculeColor !== 'point'
      ) {
        node.color = selectColor;
        node.borderColor = selectColor;
      }

      if (node.pointed && !printView) {
        node.color = pointColor;
        node.borderColor = pointColor;
      }
    }
  }
}

/**
 * Set edge color based on the analysis (algorithm), mode and node attributes.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {EdgeColorType} edgeColor type to set.
 * @param {any[]} edges Edges of graph.
 * @param {boolean} printView Is print view opened.
 * @param {string} pointColor (optional) Color of the pointed reaction.
 * @param {string} selectColor (optional) Color of the selected reaction.
 */
export function setEdgeColors(
  mode: GraphModeType,
  edgeColor: EdgeColorType,
  edges: any[],
  printView: boolean,
  pointColor: string = '#FFA000',
  selectColor: string = '#0F69AF',
) {
  for (const edge of edges) {
    switch (edgeColor) {
      case 'nonSelectiveAndDiastereoselectiveOnly':
        edge.color = edge.colorByNonSelectiveOrDiasteroselectiveOnly;
        edge.borderColor = edge.colorByNonSelectiveOrDiasteroselectiveOnly;
        break;
      case 'all':
        if (
          edge.colorByNonSelectiveOrDiasteroselectiveOnly ===
          COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY
        ) {
          edge.color = edge.colorByNonSelectiveOrDiasteroselectiveOnly;
          edge.borderColor = edge.colorByNonSelectiveOrDiasteroselectiveOnly;
        } else if (edge.colorByTunnelsAndStrategy === COLOR_REACTION_STRATEGY_OR_TUNNELS) {
          edge.color = edge.colorByTunnelsAndStrategy;
          edge.borderColor = edge.colorByTunnelsAndStrategy;
        } else {
          edge.color = edge.defaultColor;
          edge.borderColor = edge.defaultColor;
        }
        break;
      case 'tunnelsAndStrategy':
        edge.color = edge.colorByTunnelsAndStrategy;
        edge.borderColor = edge.color;
        break;
      case 'point':
        edge.color = pointColor;
        edge.borderColor = pointColor;
        break;
      default:
        edge.color = edge.defaultColor;
        edge.borderColor = edge.defaultColor;
        break;
    }

    if (edge.markedForPrint && !printView && edgeColor !== 'point') {
      edge.color = selectColor;
      edge.borderColor = selectColor;
    }

    if (edge.highlightAsPathwayPart && !printView && edgeColor !== 'point') {
      edge.color = selectColor;
      edge.borderColor = selectColor;
    }

    if (edge.pointed && !printView) {
      edge.color = pointColor;
      edge.borderColor = pointColor;
    }
  }
}

export function setShopAvailabilityHints(nodes: any[], shouldDisplay: boolean) {
  for (const node of nodes) {
    node.displayShopAvailability = shouldDisplay;
  }
}

/**
 * Only for expanded path view
 * Calculate a reaction node typical conditions based on the analysis (algorithm), mode and node attributes.
 * Also set color, size and position of label.
 * @param {boolean} showTypicalConditions Label type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only reaction nodes ignoring others).
 * @param {string} labelColor (optional) Label color.
 * @param {number} labelSize (optional) Size relative to node size.
 * @param {number} labelPosition (optional) Vertical offset from the center of node relative to the size of node.
 */
export function setReactionTypicalConditionsLabels(
  showTypicalConditions: boolean,
  graphNodes: any[],
  labelColor: string = '#000',
  labelSize: number = 1.8,
  labelPosition: number = 1.8,
) {
  const graphReactionNodes: GraphReactionNode[] = graphNodes.filter((graphNode) =>
    isReactionNode(graphNode),
  );

  for (const graphReactionNode of graphReactionNodes) {
    graphReactionNode.typicalConditionLabelColor = labelColor;
    graphReactionNode.typicalConditionLabelSize = labelSize;
    graphReactionNode.typicalConditionLabelPosition = labelPosition;
    graphReactionNode.typicalConditionLabel = undefined;
    if (showTypicalConditions) {
      const name = graphReactionNode.reaction.conditions;
      if (name) {
        graphReactionNode.typicalConditionLabel = isString(name) ? name : `${name}`;
      }
    }
  }
}

/**
 * Only for expanded path view
 * Calculate a reaction node typical conditions based on the analysis (algorithm), mode and node attributes.
 * Also set color, size and position of label.
 * @param {boolean} showTypicalConditions Label type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only reaction nodes ignoring others).
 * @param {string} labelColor (optional) Label color.
 * @param {number} labelSize (optional) Size relative to node size.
 * @param {number} labelPosition (optional) Vertical offset from the center of node relative to the size of node.
 */
export function setPublishedReactionLabels(
  showPublishedReference: boolean,
  graphNodes: any[],
  labelColor: string = '#6F6F6F',
  labelSize: number = 1.8,
  labelPosition: number = 1.8,
) {
  const graphReactionNodes: GraphReactionNode[] = graphNodes.filter((graphNode) =>
    isReactionNode(graphNode),
  );

  for (const graphReactionNode of graphReactionNodes) {
    graphReactionNode.publishedReferencesLabelColor = labelColor;
    graphReactionNode.publishedReferencesLabelSize = labelSize;
    graphReactionNode.publishedReferencesPosition = labelPosition;
    graphReactionNode.publishedReferencesLabel = undefined;
    for (const dynamicKey in graphReactionNode.reaction.reactions_data) {
      if (graphReactionNode.reaction.reactions_data.hasOwnProperty(dynamicKey)) {
        const patentsForDynamicKey =
          graphReactionNode.reaction.reactions_data[dynamicKey]?.patents || [];
        graphReactionNode.patents = patentsForDynamicKey;
      }
    }
    if (showPublishedReference) {
      const name = splitReferenceLinks(
        graphReactionNode.patents.length > 0
          ? graphReactionNode.patents.join('~')
          : graphReactionNode.reaction.reference,
      );
      if (name) {
        graphReactionNode.publishedReferencesLabel = name;
      }
    }
  }
}

export function setRepeatedReactions(showRepeatedReactions: boolean, graphEdges: any[]) {
  for (const edge of graphEdges) {
    if (edge.repeatedReactionColor && edge.repeatedReactionCount) {
      edge.showRepeatedReactions = showRepeatedReactions;
    }
  }
}

export function setRepeatedMolecules(showRepeatedMolecules: boolean, graphNodes: any[]) {
  const graphMoleculeNodes: GraphReactionNode[] = graphNodes.filter((graphNode) =>
    isMoleculeNode(graphNode),
  );
  for (const edge of graphMoleculeNodes) {
    if (edge.repeatedMoleculeColor && edge.repeatedMoleculeCount) {
      edge.showRepeatedMolecules = showRepeatedMolecules;
    }
  }
}

export function splitReferenceLinks(reference) {
  reference = reference.replace(/DOI: /gi, '');
  const tokens = ['~', ' and ', ' AND ', ' or ', ' OR '];
  const joinChar = tokens[0]; // We can use the first token as a temporary join character
  for (let i = 1; i < tokens.length; i++) {
    reference = reference.split(tokens[i]).join(joinChar);
  }
  reference = reference.split(joinChar);
  return reference;
}

/**
 * Calculate a reaction node label based on the analysis (algorithm), mode and node attributes.
 * Also set color, size and position of label.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {ReactionLabelType} reactionLabel Label type to set.
 * @param {any[]} nodes Nodes of graph (the function touches only reaction nodes ignoring others).
 * @param {string} labelColor (optional) Label color.
 * @param {number} labelSize (optional) Size relative to node size.
 * @param {number} labelPosition (optional) Vertical offset from the center of node relative to the size of node.
 */
export function setReactionLabels(
  mode: GraphModeType,
  reactionLabel: ReactionLabelType,
  graphNodes: any[],
  labelColor: string = '#000',
  labelSize: number = 1.8,
  labelPosition: number = 1.8,
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setReactionLabels: Unsupported mode "${mode}"`);
    return;
  }

  const graphReactionNodes: GraphReactionNode[] = graphNodes.filter((graphNode) =>
    isReactionNode(graphNode),
  );

  for (const graphReactionNode of graphReactionNodes) {
    graphReactionNode.labelColor = labelColor;
    graphReactionNode.labelSize = labelSize;
    graphReactionNode.labelPosition = labelPosition;
    graphReactionNode.label = undefined;

    switch (reactionLabel) {
      case 'none':
        graphReactionNode.label = '';
        break;
      case 'year':
      case 'rxid':
        const labelText =
          graphReactionNode.reaction.rxids.length === 1
            ? graphReactionNode.reaction.rxids[0]
            : null;
        if (labelText) {
          graphReactionNode.label = isString(labelText) ? labelText : `${labelText}`;
        }
        break;
      case 'reaction_id':
        const reaction_id = graphReactionNode.reaction.reaction_id;
        if (reaction_id) {
          graphReactionNode.label = isString(reaction_id) ? reaction_id : `${reaction_id}`;
        }
        break;
      case 'name':
        let name = graphReactionNode.reaction.name;

        if (graphReactionNode.reaction_node.base === KnownReactionBases.PUBLISHED) {
          name = 'Published Reaction';
        }

        if (name) {
          graphReactionNode.label = isString(name) ? name : `${name}`;
        }
        break;
      default:
        console.error(`setReactionLabels: Unsupported reaction label type "${reactionLabel}"`);
    }
  }
}

/**
 * Calculate a graph ratio and position based on the graph settings and mode.
 *
 * @param analysis Analysis frome which the graph comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {"in" | "out"} zoomType Type of zoom to use to calculate graph ratio.
 * @param {any} camera Camra of sigma graph instance.
 * @param {number} _duration (optional) Duration of the animation.
 */

export function setGraphRatio(
  mode: GraphModeType,
  zoomType: 'in' | 'out' | 'default',
  camera: any,
  _duration: number = 100,
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setGraphRatio: Unsupported mode "${mode}"`);
    return;
  }

  let newRatio: number;

  switch (zoomType) {
    case 'in':
      newRatio = camera.ratio / camera.settings('zoomingRatio');
      break;
    case 'out':
      newRatio = camera.ratio * camera.settings('zoomingRatio');
      break;
    default:
      newRatio = 1;
      break;
  }

  sigma.misc.animation.killAll();

  if (mode.toLowerCase() === 'graph') {
    sigma.misc.animation.camera(
      camera,
      {
        ratio: newRatio,
        x: zoomType === 'default' ? 0 : camera.x,
        y: zoomType === 'default' ? 0 : camera.y,
      },
      {
        duration: _duration,
      },
    );
  }
}

/**
 * Calculate a molecule node scaling based on the analysis (algorithm), mode and node attributes.
 *
 * @param analysis Analysis frome which the nodes comes.
 * @param {"graph" | "path"} mode Mode of presenting a graph.
 * @param {MoleculeScalingType} moleculeScaling Property to use to calculate node scale.
 * @param {any[]} nodes Nodes of graph (the function touches only molecule nodes ignoring others).
 */
export function setMoleculeScaling(
  mode: GraphModeType,
  moleculeScaling: MoleculeScalingType,
  nodes: GraphMoleculeNode[] & GraphReactionNode[],
) {
  if (mode.toLowerCase() !== 'graph' && mode.toLowerCase() !== 'path') {
    console.error(`setMoleculeScaling: Unsupported mode "${mode}"`);
    return;
  }

  const getPropertyLimits = (_nodes, _propertyGetter): { min: number; max: number } => {
    let min = 1e100;
    let max = -1e100;
    let _value;
    for (const node of _nodes) {
      _value = _propertyGetter(node);
      if (!isUndefined(_value)) {
        try {
          const _numValue = Number(_propertyGetter(node));
          if (_numValue < min) {
            min = _numValue;
          }
          if (_numValue > max) {
            max = _numValue;
          }
        } catch (e) {} // tslint:disable-line:no-empty
      }
    }
    return { min, max };
  };

  const moleculeNodes = nodes.filter((node) => isMoleculeNode(node));

  const scalingToPropertyMap = {
    // none: left undefined to serve it with uniform scaling equal 1.0
    molWeight: (graphMoleculeNode: GraphMoleculeNode) => graphMoleculeNode.molecule.mol_weight,
    cost: (graphMoleculeNode: GraphMoleculeNode) => graphMoleculeNode.molecule.cost,
    popularity: (graphMoleculeNode: GraphMoleculeNode) =>
      calculateMoleculePopularity(graphMoleculeNode),
    massPerGramOfTarget: (graphMoleculeNode: GraphMoleculeNode) =>
      graphMoleculeNode.massPerGramOfTarget,
  };
  const propertyGetter = scalingToPropertyMap[moleculeScaling];
  if (!propertyGetter && moleculeScaling !== 'none') {
    console.log(
      `Missing property getter definition for setMoleculeScaling() with scaling "${moleculeScaling}". ` +
        'Using default scaling with ratio 1.0.',
    );
  }

  if (!propertyGetter) {
    for (const moleculeNode of moleculeNodes) {
      moleculeNode.chScale = 1.0;
    }
    return;
  }

  const valueLimits = getPropertyLimits(moleculeNodes, propertyGetter);
  const valueRange = valueLimits.max - valueLimits.min;

  let value: any;
  let numValue: number;

  const minScale = 0.5;
  const scaleRange = 1.5 - minScale;
  for (const moleculeNode of moleculeNodes) {
    value = propertyGetter(moleculeNode);
    if (isUndefined(value)) {
      moleculeNode.chScale = 1.0;
      continue;
    }
    try {
      numValue = Number(value);
    } catch (e) {
      moleculeNode.chScale = 1.0;
      continue;
    }

    switch (moleculeScaling) {
      case 'molWeight':
      case 'cost':
      case 'popularity':
      case 'massPerGramOfTarget':
        if (valueRange > 0.0) {
          const lerp = (numValue - valueLimits.min) / valueRange;
          moleculeNode.chScale = minScale + scaleRange * lerp;
        } else {
          moleculeNode.chScale = 1.0;
        }
        break;

      default:
        console.error(`setMoleculeScaling: Unsupported molecule scaling type "${moleculeScaling}"`);
    }
  }
}

export function htmlEncodeNonLatinCharacters(svg: string): string {
  return svg.replace(/[^\x00-\x7F]+/g, (match) => '&#' + match.codePointAt(0) + ';');
}
/**
 * Converts an SVG script to the data URI. Returned URI contains all image data and can be sent to the outside
 * (ex. in emails, as data of request, etc)
 * @param {string} svg SVG image script ('<svg... </svg>')
 * @returns {string} data URI (something like 'data:image/svg+xml;base64,PHN2ZyB4bWxucz...')
 */
export function svgToImageDataURI(svg: string): string {
  return 'data:image/svg+xml;base64,' + btoa(svg);
}

/**
 * Converts a string to a data URI. Returned string can be used to force download it as a text file.
 * @param {string} text Text to be transformed
 * @returns {string} data URI ('data:application/octet-stream;charset=utf-8;base64,PHN2ZyB4bWxucz...')
 */
export function textToOctetStreamDataURI(text: string): string {
  return 'data:application/octet-stream;charset=utf-8;base64,' + btoa(text);
}

export function isTouchDevice(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

const defaultSigmaRescaleMiddleware = sigma.middlewares.rescale;
/**
 * Install default set of sigma overloads we use in our graphs rendering. It is safe to call it many times (for example
 * in constructors of sigma-dependent components).
 */
export function installSigmaGraphOverloads() {
  sigma.utils.pkg('sigma.canvas.nodes');
  let edgeHoverTimeout: ReturnType<typeof setTimeout>;

  const labelsCircle = (node, context: CanvasRenderingContext2D, settings) => {
    // declarations
    const prefix = settings('prefix') || '';
    let size = node[prefix + 'size'] / node.labelSize;
    // do not apply nodes scaling to the text of label
    size = node.chScale ? size / node.chScale : size;
    const iconSize = node[prefix + 'size'] * node.iconRelativeSize;
    const nodeX = node[prefix + 'x'];
    const nodeY = node[prefix + 'y'];
    let textWidth;
    context.fillStyle = node.labelColor;
    context.font = '400 ' + size + 'px Arial';
    context.textAlign = 'center';
    if (node.label && typeof node.label === 'string') {
      const labelLinesCount = Math.ceil(node.label.length / 35);
      let loopCounter: number = 1;
      const labelTextLines: string[] = [];
      while (loopCounter <= labelLinesCount) {
        labelTextLines.push(node.label.substring((loopCounter - 1) * 35, loopCounter * 35));
        loopCounter++;
      }
      const longLabelRealWidth: number = context.measureText(node.label.substr(0, 35)).width;
      textWidth = context.measureText(node.label).width;
      if (nodeX - textWidth * 0.5 < 0) {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX + longLabelRealWidth * 0.4,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      } else if (nodeX + longLabelRealWidth * 0.5 > node.graphWidth) {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX - longLabelRealWidth * 0.4,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      } else {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      }
      context.beginPath();
      node.labelWidth = textWidth; // important for clicks
    }

    if (settings('chNodeTypeIcons') && node.icon && typeof node.icon === 'string') {
      context.fillStyle = node.iconColor;
      context.lineWidth = iconSize * 0.1;
      context.font = '400 ' + iconSize + 'px Arial';
      context.textAlign = 'center';
      context.fillText(node.icon, nodeX, nodeY + iconSize * node.iconVerticalPositionOffset);
      // measure text width
      textWidth = context.measureText(node.icon).width;
      node.iconWidth = textWidth; // important for clicks
    }
  };

  const labelsDiamond = (node, context: CanvasRenderingContext2D, settings) => {
    // declarations
    const prefix = settings('prefix') || '';
    const size = node[prefix + 'size'] / (node.labelSize / 2);
    const nodeX = node[prefix + 'x'];
    const nodeY = node[prefix + 'y'];
    let textWidth;
    let labelLinesCount: number;
    let longLabelRealWidth: number;

    if (node.label && typeof node.label === 'string') {
      labelLinesCount = Math.ceil(node.label.length / 35);
      context.fillStyle = node.labelColor;
      context.font = '400 ' + size + 'px Arial';
      context.textAlign = 'center';
      textWidth = context.measureText(node.label).width;
      longLabelRealWidth = context.measureText(node.label.substr(0, 35)).width;
      node.labelWidth = textWidth; // important for square on hover
      node.longLabelWidth = longLabelRealWidth; // important for square on hover
      let loopCounter: number = 1;
      const labelTextLines: string[] = [];
      while (loopCounter <= labelLinesCount) {
        const labelPart: string = node.label.substring((loopCounter - 1) * 35, loopCounter * 35);
        labelTextLines.push(labelPart);
        loopCounter++;
      }
      if (nodeX - textWidth * 0.5 < 0) {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX + longLabelRealWidth * 0.4,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      } else if (nodeX + longLabelRealWidth * 0.5 > node.graphWidth) {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX - longLabelRealWidth * 0.4,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      } else {
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX,
            nodeY + node[prefix + 'size'] + (1.1 + labelTextLines.indexOf(line)) * size,
          );
        }
      }
    }
  };

  const shopAvailabilityIconParams = (nodeX, nodeY, nodeSize, nodeType: GraphNodeType) => {
    const params: any = {
      color: '#149b5f',
      borderColor: 'white',
    };
    switch (nodeType) {
      case GraphNodeType.MOLECULE:
        params.linesWidth = nodeSize / 15;
        params.x = nodeX + nodeSize * 0.6;
        params.y = nodeY + nodeSize * 0.6;
        params.size = nodeSize * 0.41;
        params.checkMarkPath = [
          { x: nodeX + nodeSize * 0.48, y: nodeY + nodeSize * 0.65 },
          { x: nodeX + nodeSize * 0.6, y: nodeY + nodeSize * 0.76 },
          { x: nodeX + nodeSize * 0.765, y: nodeY + nodeSize * 0.49 },
        ];
        break;
      case GraphNodeType.STRUCTURE:
      case GraphNodeType.EXPANDED_IMAGE:
        params.linesWidth = nodeSize / 50;
        params.x = nodeX + nodeSize * 0.88;
        params.y = nodeY + nodeSize * 0.85;
        params.size = nodeSize * 0.125;
        params.checkMarkPath = [
          { x: nodeX + nodeSize * 0.84, y: nodeY + nodeSize * 0.86 },
          { x: nodeX + nodeSize * 0.875, y: nodeY + nodeSize * 0.89 },
          { x: nodeX + nodeSize * 0.928, y: nodeY + nodeSize * 0.81 },
        ];
        break;
      default:
        console.error(`Unkonwn node type '${nodeType}' in shopAvailabilityIconParams.`);
    }
    return params;
  };

  const setLegendTagsExpanded = (
    node: any,
    context: CanvasRenderingContext2D,
    settings: any,
    x: number,
    y: number,
    size: number,
  ) => {
    const color: string = node.color || settings('defaultNodeColor');
    const contrastEdgeWidth: number = size / 15;
    context.fillStyle = color;
    context.beginPath();
    if (node.molecule.customer_inventory_data.length > 0) {
      context.beginPath();
      const image = new Image();
      image.src = inventoryFlaskSVG;
      context.drawImage(image, x - 3.5 * size, y - size * 5, 1.95 * size, 2.5 * size);
      context.fill();
    }

    // Thin white edge for better contrast
    context.lineWidth = contrastEdgeWidth;
    context.strokeStyle = 'white';
    context.stroke();
  };

  const setLegendTags = (
    node: any,
    context: CanvasRenderingContext2D,
    settings: any,
    x: number,
    y: number,
    size: number,
  ) => {
    const color: string = node.color || settings('defaultNodeColor');
    const contrastEdgeWidth: number = size / 15;
    context.fillStyle = color;
    context.beginPath();
    context.arc(x, y, size, 0, Math.PI * 2);
    context.closePath();
    context.fill();

    // Thin white edge for better contrast
    context.lineWidth = contrastEdgeWidth;
    context.strokeStyle = 'white';
    context.stroke();

    if (node.borderColor) {
      const borderWidth = size * settings('moleculeBorderWidthRatio');

      if (node.borderColor === COLOR_MOLECULE_BORDER_REGULATED) {
        // For Regulated Substance white border
        const innerBorderWidth = borderWidth * 2;
        context.beginPath();
        context.arc(x, y, size - borderWidth / 2 - contrastEdgeWidth / 2, 0, Math.PI * 2);
        context.closePath();
        context.lineWidth = innerBorderWidth;
        context.strokeStyle = '#FFFFFF';
        context.stroke();

        // Border of thickness of half radius inside the white edge
        context.beginPath();
        context.arc(x, y, size - borderWidth / 2 - contrastEdgeWidth / 2, 0, Math.PI * 2);
        context.closePath();
        context.lineWidth = borderWidth;
        context.strokeStyle = node.borderColor;
        context.stroke();
      }

      if (node.borderColor === COLOR_MOLECULE_BORDER_PROTECTION_NEEDED) {
        // For Protection Needed we need dashed border
        const arcRadius = size - borderWidth / 2 - contrastEdgeWidth;
        context.beginPath();
        context.arc(x, y, arcRadius, 0, Math.PI * 2);
        context.lineWidth = 1.5 * borderWidth;
        context.strokeStyle = '#FFFFFF';
        context.stroke();
        context.lineCap = 'butt';
        context.strokeStyle = COLOR_MOLECULE_BORDER_PROTECTION_NEEDED;
        const segment = (2 * Math.PI * arcRadius) / 16;
        context.setLineDash([segment]);
        context.stroke();
        context.closePath();
        context.setLineDash([]);
      }
    }

    if (node.displayShopAvailability && node.availableAtSA) {
      const params = shopAvailabilityIconParams(x, y, size, GraphNodeType.MOLECULE);
      context.fillStyle = params.color;
      context.beginPath();
      context.arc(params.x, params.y, params.size, 0, Math.PI * 2);
      context.lineWidth = params.linesWidth;
      context.strokeStyle = params.borderColor;
      context.closePath();
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(params.checkMarkPath[0].x, params.checkMarkPath[0].y);
      context.lineTo(params.checkMarkPath[1].x, params.checkMarkPath[1].y);
      context.lineTo(params.checkMarkPath[2].x, params.checkMarkPath[2].y);
      context.lineWidth = params.linesWidth;
      context.strokeStyle = params.borderColor;
      context.lineCap = 'round';
      context.stroke();
      context.closePath();
      context.lineCap = 'butt';
    }
  };

  const nodesCircle = (node, context: CanvasRenderingContext2D, settings) => {
    const prefix: string = settings('prefix') || '';
    const size: number = node[prefix + 'size'];
    const x: number = node[prefix + 'x'];
    const y: number = node[prefix + 'y'];
    setLegendTags(node, context, settings, x, y, size);
  };

  const defaultDiamondBorderWidth = 2;
  const defaultDiamondBorderColor = '#b3b3b3';

  const nodesDiamond = (node, context: CanvasRenderingContext2D, settings) => {
    if (node.type !== GraphNodeType.EXPANDED_REACTION) {
      const prefix = settings('prefix') || '';
      const size = node[prefix + 'size'];

      context.fillStyle = node.color || settings('defaultNodeColor');
      context.beginPath();
      context.moveTo(node[prefix + 'x'] - size, node[prefix + 'y']);
      context.lineTo(node[prefix + 'x'], node[prefix + 'y'] - size);
      context.lineTo(node[prefix + 'x'] + size, node[prefix + 'y']);
      context.lineTo(node[prefix + 'x'], node[prefix + 'y'] + size);

      context.closePath();
      context.fill();

      // Adding a scalable border
      context.lineWidth = (node.borderWidth || defaultDiamondBorderWidth) * (size / 10);
      context.strokeStyle = node.borderColor || defaultDiamondBorderColor;
      context.stroke();
      for (const dynamicKey in node.reaction.reactions_data) {
        if (node.reaction.reactions_data.hasOwnProperty(dynamicKey)) {
          const patentsForDynamicKey = node.reaction.reactions_data[dynamicKey]?.patents || [];
          node.patents = patentsForDynamicKey;
        }
      }
    } else {
      const prefix = settings('prefix') || '';
      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];
      const size = node[prefix + 'size'];

      context.beginPath();
      context.rect(nodeX - 10 * size, nodeY - 5.6 * size, 18 * size, 3 * size);
      context.fillStyle = '#000'; // Node image background color
      context.fill();
    }
  };

  const defaultEdgesBorderWidth = 4;

  const edgesArrow = (
    edge,
    source: any,
    target: any,
    context: CanvasRenderingContext2D,
    settings,
  ) => {
    // The code that draw arrows comes from SigmaJS
    // (https://github.com/jacomyal/sigma.js/blob/master/src/renderers/canvas/sigma.canvas.edges.arrow.js).
    // Added the part adding border to the arrows.
    let color = edge.color;
    let edgeBorderColor = edge.borderColor;
    const prefix = settings('prefix') || '';
    const edgeColor = settings('edgeColor');
    const defaultNodeColor = settings('defaultNodeColor');
    const defaultEdgeColor = settings('defaultEdgeColor');
    const size = edge[prefix + 'size'] * 1.4 || 1;
    const tSize = target[prefix + 'size'];
    const sX = source[prefix + 'x'];
    const sY = source[prefix + 'y'];
    const tX = target[prefix + 'x'];
    const tY = target[prefix + 'y'];
    const aSize = Math.max(size * 2.5, settings('minArrowSize'));
    const distance = Math.sqrt(Math.pow(tX - sX, 2) + Math.pow(tY - sY, 2));
    const aX = sX + ((tX - sX) * (distance - aSize - tSize - aSize * 0.4)) / distance;
    const aY = sY + ((tY - sY) * (distance - aSize - tSize - aSize * 0.4)) / distance;
    const vX = ((tX - sX) * aSize) / distance;
    const vY = ((tY - sY) * aSize) / distance;

    if (!color) {
      switch (edgeColor) {
        case 'source':
          color = source.color || defaultNodeColor;
          break;
        case 'target':
          color = target.color || defaultNodeColor;
          break;
        default:
          color = defaultEdgeColor;
          break;
      }
    }

    if (!edgeBorderColor) {
      edgeBorderColor = color;
    }

    // Draw arrowline border
    context.strokeStyle = edgeBorderColor;
    context.lineWidth = size;
    context.beginPath();
    context.moveTo(sX, sY);
    context.lineTo(aX, aY);
    context.stroke();

    // Draw arrowhead with border
    context.fillStyle = color;
    context.beginPath();
    context.moveTo(aX + vX, aY + vY);
    context.lineTo(aX + vY * 0.6, aY - vX * 0.6);
    context.lineTo(aX - vY * 0.6, aY + vX * 0.6);
    context.lineTo(aX + vX, aY + vY);
    context.closePath();
    context.fill();

    context.lineWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 20);
    context.strokeStyle = edgeBorderColor;
    context.stroke();

    // Draw arrowline
    context.lineWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 7);
    context.strokeStyle = color;
    context.beginPath();
    context.moveTo(sX, sY);
    context.lineTo(aX, aY);
    context.stroke();
  };

  const edgesArrowExpanded = (
    edge,
    source: any,
    target: any,
    context: CanvasRenderingContext2D,
    settings,
  ) => {
    // The code that draw arrows comes from SigmaJS
    // (https://github.com/jacomyal/sigma.js/blob/master/src/renderers/canvas/sigma.canvas.edges.arrow.js).
    // Added the part adding border to the arrows.
    let color = edge.color;
    let edgeBorderColor = edge.borderColor;
    const prefix = settings('prefix') || '';
    const edgeColor = settings('edgeColor');
    const defaultNodeColor = settings('defaultNodeColor');
    const defaultEdgeColor = settings('defaultEdgeColor');
    const size = edge[prefix + 'size'] * 1.4 || 1;
    const tSize = target[prefix + 'size'];
    const sX = source[prefix + 'x'];
    const sY = source[prefix + 'y'];
    const tX = target[prefix + 'x'];
    const tY = target[prefix + 'y'];
    const aSize = Math.max(size * 2.5, settings('minArrowSize'));
    const distance = Math.sqrt(Math.pow(tX - sX, 2) + Math.pow(tY - sY, 2));
    const aX = sX + ((tX - sX) * (distance - aSize - tSize - aSize * 0.4)) / distance;
    const aY = sY + ((tY - sY) * (distance - aSize - tSize - aSize * 0.4)) / distance;
    const vX = ((tX - sX) * aSize) / distance;
    const vY = ((tY - sY) * aSize) / distance;

    if (!color) {
      switch (edgeColor) {
        case 'source':
          color = source.color || defaultNodeColor;
          break;
        case 'target':
          color = target.color || defaultNodeColor;
          break;
        default:
          color = defaultEdgeColor;
          break;
      }
    }
    if (!edgeBorderColor) {
      edgeBorderColor = color;
    }

    if (edge.showRepeatedReactions) {
      edgeBorderColor = edge.repeatedReactionColor;
      color = edge.repeatedReactionColor;
    }
    // Draw arrowline border
    context.strokeStyle = edgeBorderColor;
    context.lineWidth = size;
    context.beginPath();
    context.moveTo(sX, sY);
    context.lineTo(aX - size * 8, aY);
    context.stroke();

    // Draw arrowhead with border
    context.fillStyle = color;
    context.beginPath();
    context.moveTo(aX + vX - size * 8, aY + vY);
    context.lineTo(aX + vY * 0.6 - size * 8, aY - vX * 0.6);
    context.lineTo(aX - vY * 0.6 - size * 8, aY + vX * 0.6);
    context.lineTo(aX + vX - size * 8, aY + vY);
    context.closePath();
    context.fill();

    context.lineWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 20);
    context.strokeStyle = edgeBorderColor;
    context.stroke();
  };

  const getSimilarityImage = (similarity_score: number) => {
    const similarityImage = new Image();
    if (similarity_score >= 0.9 && similarity_score < 1.0) {
      similarityImage.src = similarityThreeStar;
    } else if (similarity_score >= 0.5 && similarity_score < 0.9) {
      similarityImage.src = similarityTwoStar;
    } else if (similarity_score > 0 && similarity_score < 0.5) {
      similarityImage.src = similarityOneStar;
    }
    return similarityImage;
  };

  const getSimilarityImageSvg = (similarity_score: number) => {
    let similarityImageSvg = '';
    if (similarity_score >= 0.9 && similarity_score < 1.0) {
      similarityImageSvg = similarityThreeStarSvg;
    } else if (similarity_score >= 0.5 && similarity_score < 0.9) {
      similarityImageSvg = similarityTwoStarSvg;
    } else if (similarity_score > 0 && similarity_score < 0.5) {
      similarityImageSvg = similarityOneStarSvg;
    }
    return similarityImageSvg;
  };

  const nodesImage = (node, context: CanvasRenderingContext2D, settings) => {
    const prefix = settings('prefix') || '';
    const size = node[prefix + 'size'];
    const url = node.url;
    const x: number = node[prefix + 'x'];
    const y: number = node[prefix + 'y'];
    context.beginPath();
    context.rect(x - size, y - 0.875 * size, 1.75 * size, 1.75 * size);
    context.fillStyle = '#fff';
    context.fill();
    if (url) {
      context.drawImage(url, x - 0.97 * size, y - 0.825 * size, 1.65 * size, 1.65 * size);
    }
    context.closePath();
    context.lineWidth = node.borderWidth;
    context.strokeStyle = node.borderColor === '#ffffff' ? '#e0e0e0' : node.borderColor;
    if (node.needsProtection) {
      context.setLineDash([(1.75 * size) / 10]);
      context.lineDashOffset = 10;
    }
    context.stroke();
    context.setLineDash([]);
    context.lineDashOffset = 0;

    if (node.needsProtection || node.molecule.regulatory_databases.length > 0) {
      context.strokeStyle = node.color;
      context.beginPath();
      context.rect(x - 0.95 * size, y - 0.825 * size, 1.65 * size, 1.65 * size);
      context.stroke();
    }

    if (node.displayShopAvailability && node.availableAtSA) {
      const params = shopAvailabilityIconParams(x, y, size, GraphNodeType.STRUCTURE);
      context.fillStyle = params.color;
      context.beginPath();
      context.arc(params.x, params.y, params.size, 0, Math.PI * 2);
      context.lineWidth = params.linesWidth;
      context.strokeStyle = params.borderColor;
      context.closePath();
      context.fill();
      context.stroke();
      context.beginPath();
      context.moveTo(params.checkMarkPath[0].x, params.checkMarkPath[0].y);
      context.lineTo(params.checkMarkPath[1].x, params.checkMarkPath[1].y);
      context.lineTo(params.checkMarkPath[2].x, params.checkMarkPath[2].y);
      context.lineWidth = params.linesWidth;
      context.strokeStyle = 'white';
      context.lineCap = 'round';
      context.stroke();
      context.closePath();
      context.lineCap = 'butt';
    }
  };

  const labelsImage = (node, context: CanvasRenderingContext2D, settings) => {
    // declarations
    const prefix = settings('prefix') || '';
    // no need to apply nodes scaling to the text of label
    const fontSize = 12;
    const nodeX = node[prefix + 'x'];
    const nodeY = node[prefix + 'y'];
    let textWidth;

    // define settings
    if (node.label && typeof node.label === 'string') {
      context.fillStyle = node.labelColor;
      context.font = '400 ' + fontSize + 'px Arial';
      context.textAlign = 'center';
      if (node.label.length > 30) {
        const labelLinesCount = Math.ceil(node.label.length / 30);
        let loopCounter: number = 1;
        const labelTextLines: string[] = [];
        const longLabelRealWidth: number = context.measureText(node.label.substr(0, 30)).width;
        node.labelWidth = textWidth; // important for square on hover
        node.longLabelWidth = longLabelRealWidth; // important for square on hover
        while (loopCounter <= labelLinesCount) {
          labelTextLines.push(node.label.substring((loopCounter - 1) * 30, loopCounter * 30));
          loopCounter++;
        }
        for (const line of labelTextLines) {
          context.fillText(
            line,
            nodeX - fontSize,
            nodeY + node.size + fontSize * labelTextLines.indexOf(line),
          );
        }
      } else {
        context.fillText(node.label, nodeX - fontSize, nodeY + node.size);
      }
      // measure text width
      textWidth = context.measureText(node.label).width;
      node.labelWidth = textWidth; // important for clicks
    }
  };

  function splitTextWithWordWrap(node: any, maxLineLength: number) {
    const text = node.typicalConditionLabel;
    const words = text.split(/(?<!1\.)\s/);
    const lines = [];
    let currentLine = '';

    for (const word of words) {
      if (currentLine.length + word.length <= maxLineLength) {
        currentLine += (currentLine.length > 0 ? ' ' : '') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
        if (currentLine.length > maxLineLength) {
          node.isTooltipRequired = true;
          currentLine = currentLine.substring(0, maxLineLength - 3) + '...';
          break;
        }
      }
    }

    if (currentLine.length > 0) {
      lines.push(currentLine);
    }
    return lines;
  }

  const splitNodeLabel = (label: string, maxChar: number) => {
    const checkCombineWord = (wordList: string[], word: string, index: number) => {
      if (index !== wordList.length - 1) {
        const combineWord = word + ' ' + wordList[index + 1];
        if (combineWord.length <= maxChar) {
          return checkCombineWord(wordList, combineWord, index + 1);
        } else {
          return { index: index, combineWord: word };
        }
      } else {
        return { index: index, combineWord: word };
      }
    };

    const lines = [];
    if (label.length > maxChar) {
      const wordList = label.split(' ');
      if (wordList.length > 0) {
        for (let i = 0; i < wordList.length; i++) {
          if (wordList[i].length > maxChar) {
            const croppedWord = wordList[i].substr(0, maxChar);
            const remainingWord = wordList[i].substr(maxChar, wordList[i].length);
            wordList.splice(i + 1, 0, remainingWord);
            lines.push(croppedWord);
          } else {
            const getCombinedWord = checkCombineWord(wordList, wordList[i], i);
            if (getCombinedWord) {
              lines.push(getCombinedWord.combineWord);
              i = getCombinedWord.index;
            }
          }
        }
      }
    } else {
      lines.push(label);
    }
    return lines;
  };

  const getMoleculeLabelDetails = (node: any, context: CanvasRenderingContext2D, settings: any) => {
    const prefix = settings('prefix') || '';
    const fontSize = node[prefix + 'size'];
    const nodeX = node[prefix + 'x'];
    let textWidth: number;
    let labelLinesCount: number;
    let longLabelRealWidth: number;
    node.label = node.label;
    const charPerLine = 30;
    const fillStyle = node.labelColor;

    const font = '600 ' + fontSize * 0.15 + 'px Roboto';
    const textAlign: CanvasTextAlign = 'center';
    const label = node.label || '';
    context.font = font;
    textWidth = context.measureText(label).width;
    longLabelRealWidth = context.measureText(label.substr(0, charPerLine)).width;
    node.labelWidth = textWidth; // important for square on hover
    let labelTextLines: string[] = [];
    labelTextLines = splitNodeLabel(label, charPerLine);
    labelLinesCount = labelTextLines.length;

    const labelTextLineLengthArray = labelTextLines.map((line) => line.length);
    const maxLineIndex = labelTextLineLengthArray.indexOf(Math.max(...labelTextLineLengthArray));
    if (maxLineIndex !== -1) {
      longLabelRealWidth = context.measureText(labelTextLines[maxLineIndex]).width;
    }
    node.longLabelWidth = longLabelRealWidth; // important for square on hover

    let labelPosX = nodeX - fontSize;
    if (nodeX - longLabelRealWidth * 0.5 < 0) {
      labelPosX = nodeX + longLabelRealWidth * 0.4;
    } else if (nodeX + longLabelRealWidth * 0.5 > node.graphWidth) {
      labelPosX = nodeX - longLabelRealWidth * 0.4;
    }
    const legendTagSize = node[prefix + 'size'] * 0.1;

    const returnOb = {
      charPerLine: charPerLine,
      fillStyle: fillStyle,
      font: font,
      fontSize: fontSize,
      textAlign: textAlign,
      labelTextLines: labelTextLines,
      labelPosX: labelPosX,
      longLabelRealWidth: longLabelRealWidth,
      legendTagSize: legendTagSize,
    };

    return returnOb;
  };

  const expandedNodesImage = (node, context: CanvasRenderingContext2D, settings) => {
    const prefix = settings('prefix') || '';
    const size = node[prefix + 'size'];
    const url = node.url;
    const x: number = node[prefix + 'x'];
    const y: number = node[prefix + 'y'];
    let labelLinesCount: number;
    let longLabelRealWidth: number;
    let labelTextLines: string[] = [];
    let labelPosX: number;
    node.labelColor = '#EB3C96';
    const moleculeLabelDetails = getMoleculeLabelDetails(node, context, settings);
    if (node.showRepeatedMolecules) {
      context.beginPath();
      context.rect(x - size * 1.2, y - 0.825 * size, 1.62 * size, 1.65 * size);
      context.fillStyle = '#FFFF';
      context.strokeStyle = node.repeatedMoleculeColor;
      context.lineWidth = 0.9;
      context.fill();
      context.stroke();
      const nodeSize = size / 26;
      const tX = x - size * 0.94;
      const cornerRadius = nodeSize * 3.5;
      const tY = y - 0.98 * size;
      const width =
        (nodeSize + 0.19) * Math.max(node.repeatedMoleculeCount.toString().length * 2, 7);
      const height = (nodeSize + 0.18) * 7;
      context.fillStyle = node.repeatedMoleculeColor;
      context.beginPath();
      context.moveTo(tX + cornerRadius, tY);
      context.lineTo(tX + width - cornerRadius, tY);
      context.arcTo(tX + width, tY, tX + width, tY + cornerRadius, cornerRadius);
      context.lineTo(tX + width, tY + height - cornerRadius);
      context.fillStyle = node.repeatedMoleculeColor;
      context.lineWidth = 1.02;
      context.strokeStyle = '#FFFFFF';
      context.arcTo(tX + width, tY + height, tX + width - cornerRadius, tY + height, cornerRadius);
      context.lineTo(tX + cornerRadius, tY + height);
      context.arcTo(tX, tY + height, tX, tY + height - cornerRadius, cornerRadius);
      context.lineTo(tX, tY + cornerRadius);
      context.arcTo(tX, tY, tX + cornerRadius, tY, cornerRadius);
      context.lineWidth = 1.02;
      context.strokeStyle = '#FFFFFF';
      context.closePath();
      context.fill();
      context.stroke();

      context.beginPath();
      context.fillStyle = '#FFFFFF';
      context.font = '700 ' + nodeSize * 4.8 + 'px Roboto';
      context.textAlign = 'center';
      const textX = tX + width / 2 - 0.05 * nodeSize;
      const textY = tY + height / 2 + 1.5 * nodeSize;
      context.fillText(node.repeatedMoleculeCount, textX, textY);
      context.closePath();
    }
    if (node.isDiversify) {
      const radius = 0.025 * size;
      const width = 1.62 * size;
      const height = 1.65 * size;
      const nx = x - size * 1.2;
      const ny = y - 0.825 * size;
      context.beginPath();
      context.moveTo(nx + radius, ny);
      context.arcTo(nx + width, ny, nx + width, ny + height, radius);
      context.arcTo(nx + width, ny + height, nx, ny + height, radius);
      context.arcTo(nx, ny + height, nx, ny, radius);
      context.arcTo(nx, ny, nx + width, ny, radius);
      context.fillStyle = '#FFFF';
      context.strokeStyle = node.isCheckedForDiverisity ? '#2CBECD' : '#727272';
      context.lineWidth = size * 0.012;
      context.fill();
      context.stroke();

      const radius_c = 0.02 * size;
      const width_c = 0.15 * size;
      const height_c = 0.15 * size;
      const nx_c = x - size * 1.15;
      const ny_c = y - 0.78 * size;
      context.beginPath();
      context.moveTo(nx_c + radius_c, ny_c);
      context.arcTo(nx_c + width_c, ny_c, nx_c + width_c, ny_c + height_c, radius_c);
      context.arcTo(nx_c + width_c, ny_c + height_c, nx_c, ny_c + height_c, radius_c);
      context.arcTo(nx_c, ny_c + height_c, nx_c, ny_c, radius_c);
      context.arcTo(nx_c, ny_c, nx_c + width_c, ny_c, radius_c);
      context.fillStyle = node.isCheckedForDiverisity ? '#2CBECD' : '#FFFF';
      context.strokeStyle = node.isCheckedForDiverisity ? '#2CBECD' : '#727272';
      context.lineWidth = size * 0.012;
      context.fill();
      context.stroke();
      if (node.isCheckedForDiverisity) {
        context.beginPath();
        context.moveTo(nx_c + width_c * 0.2, ny_c + height_c * 0.5);
        context.lineTo(nx_c + width_c * 0.4, ny_c + height_c * 0.75);
        context.lineTo(nx_c + width_c * 0.8, ny_c + height_c * 0.25);
        context.strokeStyle = '#FFFF';
        context.lineWidth = size * 0.012;
        context.stroke();
      }
    }
    if (url) {
      let tempY = y - 0.825 * size;
      let tempSize = 1.65 * size;
      if (node.repeatedMoleculeCount > 0) {
        tempY = y - 0.7 * size;
        tempSize = 1.5 * size;
      }
      if (node.isDiversify) {
        tempY = y - 0.755 * size;
        tempSize = 1.62 * size;
      }
      context.drawImage(url, x - size * 1.2, tempY, tempSize, tempSize);
    }

    if (node.label && typeof node.label === 'string') {
      labelTextLines = moleculeLabelDetails.labelTextLines;
      longLabelRealWidth = moleculeLabelDetails.longLabelRealWidth;
      labelPosX = moleculeLabelDetails.labelPosX;
      labelLinesCount = labelTextLines.length;
      context.fillStyle = moleculeLabelDetails.fillStyle;
      context.font = moleculeLabelDetails.font;
      context.textAlign = 'center';
      context.textAlign = moleculeLabelDetails.textAlign;
      context.beginPath();
      for (const line of labelTextLines) {
        context.fillText(line, x - 0.35 * size, y + size);
      }
    }
    context.closePath();
  };

  const getMoleculeLabelYPosition = (
    node: any,
    settings: any,
    moleculeLabelDetails: any,
    index: number,
  ) => {
    const prefix = settings('prefix') || '';
    const nodeY = node[prefix + 'y'];
    const size = node[prefix + 'size'];
    const fontSize = moleculeLabelDetails.fontSize;
    const legendTagSize = moleculeLabelDetails.legendTagSize;
    return nodeY + 2 * legendTagSize + size + index * fontSize + 2;
  };

  const setProtectionWarningTag = (
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    warningTagSize: number,
  ) => {
    const height = warningTagSize * (Math.sqrt(3) / 2);
    context.fillStyle = '#EEAA44';
    y = y - height * 0.5;
    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x + warningTagSize * 0.5, y + height);
    context.lineTo(x - warningTagSize * 0.5, y + height);
    context.lineTo(x, y);
    context.fill();
    context.closePath();

    const rectangleWidth = warningTagSize * 0.1;
    context.beginPath();
    context.fillStyle = '#FFFFFF';
    context.rect(x - rectangleWidth * 0.5, y + height * 0.4, rectangleWidth, height / 4);
    context.fill();
    context.closePath();

    context.beginPath();
    context.fillStyle = '#FFFFFF';
    context.rect(x - rectangleWidth * 0.5, y + height * 0.75, rectangleWidth, rectangleWidth);
    context.fill();
    context.closePath();
  };

  const expandedLabelsImage = (node: any, context: CanvasRenderingContext2D, settings: any) => {
    // declarations
    const prefix = settings('prefix') || '';
    const nodeX = node[prefix + 'x'];
    const nodeY = node[prefix + 'y'];
    const size = node[prefix + 'size'];
    let labelLinesCount: number;
    let longLabelRealWidth: number;
    let labelTextLines: string[] = [];
    let labelPosX: number;

    const moleculeLabelDetails = getMoleculeLabelDetails(node, context, settings);
    const legendTagSize = moleculeLabelDetails.legendTagSize;
    const warningTagSize = size * 0.22;
    const paddingForWarningTag = 0;

    if (node.showLegendTag) {
      setLegendTagsExpanded(
        node,
        context,
        settings,
        nodeX - legendTagSize + paddingForWarningTag,
        nodeY + size,
        legendTagSize,
      );
    }

    return; // used to remove circles and cost

    if (node.label && typeof node.label === 'string') {
      labelTextLines = moleculeLabelDetails.labelTextLines;
      longLabelRealWidth = moleculeLabelDetails.longLabelRealWidth;
      labelPosX = moleculeLabelDetails.labelPosX;
      labelLinesCount = labelTextLines.length;
      context.fillStyle = moleculeLabelDetails.fillStyle;
      context.font = moleculeLabelDetails.fillStyle.font;
      context.textAlign = moleculeLabelDetails.textAlign;
      context.beginPath();
      for (const line of labelTextLines) {
        context.fillText(
          line,
          labelPosX,
          getMoleculeLabelYPosition(
            node,
            settings,
            moleculeLabelDetails,
            labelTextLines.indexOf(line),
          ),
        );
      }
      context.closePath();
    }
  };

  const drawCurvedLine = (
    context: CanvasRenderingContext2D,
    sourceX: number,
    sourceY: number,
    targetX: number,
    targetY: number,
    lineColor: string,
    size: number,
    lineSize: number,
  ) => {
    context.strokeStyle = lineColor;
    context.lineWidth = lineSize;
    context.beginPath();
    context.moveTo(sourceX, sourceY);
    context.lineTo(targetX - (targetX - sourceX) * 0.5, sourceY);
    context.arcTo(targetX, sourceY, targetX, sourceY + (targetY - sourceY) * 0.5, size * 2);
    context.lineTo(targetX, targetY);
    context.stroke();
    context.closePath();
  };

  const expandedEdgesLine = (
    edge,
    source: any,
    target: any,
    context: CanvasRenderingContext2D,
    settings,
  ) => {
    // The code that draw arrows comes from SigmaJS
    // (https://github.com/jacomyal/sigma.js/blob/master/src/renderers/canvas/sigma.canvas.edges.arrow.js).
    // Added the part adding border to the arrows.
    let color = edge.color;
    let edgeBorderColor = edge.borderColor;
    const prefix = settings('prefix') || '';
    const edgeColor = settings('edgeColor');
    const defaultNodeColor = settings('defaultNodeColor');
    const defaultEdgeColor = settings('defaultEdgeColor');
    const size = edge[prefix + 'size'] * 1.4 || 1;
    const sX = source[prefix + 'x'];
    const sY = source[prefix + 'y'];
    const tX = target[prefix + 'x'];
    const tY = target[prefix + 'y'];

    if (!color) {
      switch (edgeColor) {
        case 'source':
          color = source.color || defaultNodeColor;
          break;
        case 'target':
          color = target.color || defaultNodeColor;
          break;
        default:
          color = defaultEdgeColor;
          break;
      }
    }

    if (!edgeBorderColor) {
      edgeBorderColor = color;
    }

    if (edge.showRepeatedReactions) {
      edgeBorderColor = edge.repeatedReactionColor;
      color = edge.repeatedReactionColor;
    }

    if (edge.type === GraphEdgeType.EXPANDED_LINE) {
      // Draw arrowline border
      context.strokeStyle = edgeBorderColor;
      context.lineWidth = size;
      context.beginPath();
      context.moveTo(sX, sY);
      context.lineTo(tX, tY);
      context.stroke();

      context.lineWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 20);
      context.strokeStyle = edgeBorderColor;
      context.stroke();

      // Draw arrow line
      context.lineWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 7);
      context.strokeStyle = color;
      context.beginPath();
      context.moveTo(sX, sY);
      context.lineTo(tX, tY);
      context.stroke();

      if (edge.showRepeatedReactions && target.nodes.length < 2) {
        const x = tX - size * 13,
          cornerRadius = size * 5,
          y = tY - 5.5 * size,
          width = size * Math.max(edge.repeatedReactionCount.toString().length * 3, 10),
          height = size * 10;
        context.beginPath();
        context.moveTo(x + cornerRadius, y);
        context.lineTo(x + width - cornerRadius, y);
        context.arcTo(x + width, y, x + width, y + cornerRadius, cornerRadius);
        context.lineTo(x + width, y + height - cornerRadius);
        context.fillStyle = edge.repeatedReactionColor;
        context.lineWidth = size * 0.8;
        context.strokeStyle = '#FFFFFF';
        context.arcTo(x + width, y + height, x + width - cornerRadius, y + height, cornerRadius);
        context.lineTo(x + cornerRadius, y + height);
        context.arcTo(x, y + height, x, y + height - cornerRadius, cornerRadius);
        context.lineTo(x, y + cornerRadius);
        context.arcTo(x, y, x + cornerRadius, y, cornerRadius);
        context.lineWidth = size * 0.5;
        context.strokeStyle = '#FFFFFF';
        context.closePath();
        context.fill();
        context.stroke();

        context.beginPath();
        context.fillStyle = '#FFFFFF';
        context.font = '700 ' + size * 6.5 + 'px Roboto';
        context.textAlign = 'center';
        const textX = x + width / 2 - 0.14 * size;
        const textY = y + height / 2 + 2 * size;
        context.fillText(edge.repeatedReactionCount, textX, textY);
        context.closePath();
      }
    } else if (isCurvedEdge(edge)) {
      // For border
      let lineSize = size;
      let lineColor = edgeBorderColor;
      drawCurvedLine(context, sX, sY, tX, tY, lineColor, size, lineSize);

      // For line
      lineSize = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 7);
      lineColor = color;
      drawCurvedLine(context, sX, sY, tX, tY, lineColor, size, lineSize);

      if (edge.showRepeatedReactions) {
        const x = tX - size * 5.2,
          cornerRadius = size * 5,
          y = tY - 5 * size,
          width = size * Math.max(edge.repeatedReactionCount.toString().length * 3, 10),
          height = size * 10;

        context.beginPath();
        context.moveTo(x + cornerRadius, y);
        context.lineTo(x + width - cornerRadius, y);
        context.arcTo(x + width, y, x + width, y + cornerRadius, cornerRadius);
        context.lineTo(x + width, y + height - cornerRadius);
        context.fillStyle = edge.repeatedReactionColor;
        context.lineWidth = size * 0.8;
        context.strokeStyle = '#FFFFFF';
        context.arcTo(x + width, y + height, x + width - cornerRadius, y + height, cornerRadius);
        context.lineTo(x + cornerRadius, y + height);
        context.arcTo(x, y + height, x, y + height - cornerRadius, cornerRadius);
        context.lineTo(x, y + cornerRadius);
        context.arcTo(x, y, x + cornerRadius, y, cornerRadius);
        context.lineWidth = size * 0.5;
        context.strokeStyle = '#FFFFFF';
        context.closePath();
        context.fill();
        context.stroke();

        context.beginPath();
        context.fillStyle = '#FFFFFF';
        context.font = '700 ' + size * 7 + 'px Roboto';
        context.textAlign = 'center';
        const textX = x + width / 2 - 0.14 * size;
        const textY = y + height / 2 + 2.2 * size;
        context.fillText(edge.repeatedReactionCount, textX, textY);
        context.closePath();
      }
    }

    context.beginPath();
    context.rect(sX, sY - 25 * size, 30 * size, 50 * size);
    context.fillStyle = '#FFFFFF'; // Node image background color
    context.fill();
    let usX = sX + size * 16.5;
    let usY = sY - 4.5 * size;

    if (source.showLegendTag) {
      if (calculateMoleculePopularity(source)) {
        context.beginPath();
        const imgPopularity = new Image();
        imgPopularity.src = popularityIcon;
        context.drawImage(imgPopularity, usX, usY, 8 * size, 9 * size);
        context.fill();
        const moleculePopularity = String(calculateMoleculePopularity(source));
        const x = usX + size * 6.5,
          cornerRadius = size * 2,
          y = usY - 0.2 * size,
          width = size * Math.max(moleculePopularity.length + 2, 4),
          height = size * 4;

        context.fillStyle = '#3C2274';
        context.beginPath();
        context.moveTo(x + cornerRadius, y);
        context.lineTo(x + width - cornerRadius, y);
        context.arcTo(x + width, y, x + width, y + cornerRadius, cornerRadius);
        context.lineTo(x + width, y + height - cornerRadius);
        context.fillStyle = '#3C2274';
        context.lineWidth = size * 0.8;
        context.strokeStyle = '#FFFFFF';
        context.arcTo(x + width, y + height, x + width - cornerRadius, y + height, cornerRadius);
        context.lineTo(x + cornerRadius, y + height);
        context.arcTo(x, y + height, x, y + height - cornerRadius, cornerRadius);
        context.lineTo(x, y + cornerRadius);
        context.arcTo(x, y, x + cornerRadius, y, cornerRadius);
        context.lineWidth = size * 0.5;
        context.strokeStyle = '#FFFFFF';
        context.closePath();
        context.fill();
        context.stroke();

        context.beginPath();
        context.fillStyle = '#FFFFFF';
        context.font = '700 ' + size * 2 + 'px Roboto';
        context.textAlign = 'center';
        const textX = x + width / 2 - 0.15 * size;
        const textY = y + height / 2 + 0.8 * size;
        context.fillText(moleculePopularity, textX, textY);
        context.closePath();
      } else {
        usX = usX - 4.5 * size;
        usY = usY - 7 * size;
      }

      if (!!source.needsProtection) {
        usX = usX + 4.5 * size;
        usY = usY + 7 * size;
        context.beginPath();
        const imgProtection = new Image();
        imgProtection.src = protectionIcon;
        context.drawImage(imgProtection, usX, usY, 8 * size, 9 * size);
        context.fill();
      } else {
        usX = usX + 10 * size;
        usY = usY;
      }

      if (!calculateMoleculePopularity(source) && !source.needsProtection) {
        usX = usX - 0.5 * size;
        usY = usY + 0.5 * size;
      }

      if (source.molecule.regulatory_databases.length > 0) {
        usX = usX - 5 * size;
        usY = usY + 6.5 * size;
        context.beginPath();
        const imgRegulatory = new Image();
        imgRegulatory.src = regulatoryIcon;
        context.drawImage(imgRegulatory, usX, usY, 8 * size, 9 * size);
        context.fill();
      }
    }
  };

  const edgeHover = (
    edge: any,
    sourceNode: any,
    targetNode: any,
    context: CanvasRenderingContext2D,
    settings: any,
  ) => {
    // Trigger when hover on edges
    if (edgeHoverTimeout) {
      clearTimeout(edgeHoverTimeout);
    }
    if (
      edge.type === GraphEdgeType.CURVED_DOWN_LINE ||
      edge.type === GraphEdgeType.CURVED_UP_LINE ||
      edge.edgeLabels.length === 0
    ) {
      // Hover on curved arrows are disabled
      // Hover on arrows without label is disabled
      return false;
    }

    edgeHoverTimeout = setTimeout(() => {
      // Need a delay to show edge label tooltip over molecule node

      const prefix: any = settings('prefix') || '';
      const node: any = isReactionNode(targetNode) ? targetNode : sourceNode;
      const nodeX: number = node[prefix + 'x'];
      const nodeY: number = node[prefix + 'y'];
      const nodeSize: number = node[prefix + 'size'];
      const rectangleY: number = nodeY + nodeSize * 1.4;
      let size: number = node[prefix + 'size'] / (node.labelSize / 2);
      const minSize = sourceNode.graphWidth * 0.005;
      if (size < minSize) {
        size = minSize;
      }

      const font: string = '500 ' + size + 'px Roboto';
      const padding: number = size * 0.3;
      const edgeLabels: GraphEdgeLabelValues[] = edge.edgeLabels;

      context.lineWidth = 0.5;
      context.font = font;
      context.fillStyle = '#f1f1f1';
      const textWidthArray: number[] = edgeLabels.map((label) => {
        return context.measureText(label).width;
      });
      const textWidth: number = Math.max(...textWidthArray);
      const rectangleWidth: number = textWidth + 5 * padding;
      let rectangleX: number = nodeX - rectangleWidth * 0.5;
      if (node.nodes && node.nodes.length > 1) {
        // Reaction with more than one substrates . So will have curved edge
        rectangleX = nodeX + nodeSize;
      }

      for (const line of edgeLabels) {
        const index = edgeLabels.indexOf(line);
        context.beginPath();
        context.fillStyle = '#f1f1f1';
        context.strokeStyle = '#494949';
        context.lineWidth = 0.5;
        context.stroke();
        context.strokeRect(
          rectangleX,
          rectangleY,
          rectangleWidth,
          Math.round(size) * edgeLabels.length * 1.05 + 2 * padding,
        );

        context.fillRect(
          rectangleX,
          rectangleY,
          rectangleWidth,
          Math.round(size) * edgeLabels.length * 1.05 + 2 * padding,
        );

        context.closePath();
        context.fillStyle = '#494949';
        context.font = '500 ' + size + 'px Roboto';
        context.textAlign = 'center';
        const textX = rectangleX + rectangleWidth / 2;
        context.fillText(line, textX, rectangleY + (1.1 + index) * size);
      }
    }, 10);
  };

  const getReactionLabelDetails = (node: any, context: CanvasRenderingContext2D, settings: any) => {
    const prefix = settings('prefix') || '';
    const size = node[prefix + 'size'] / (node.labelSize / 2);
    const nodeX = node[prefix + 'x'];
    let textWidth: number;
    let labelLinesCount: number;
    let longLabelRealWidth: number;
    const charPerLine = node.label.length > 100 ? 40 : 30;
    const fillStyle = node.labelColor;
    const font = '500 ' + size + 'px Roboto';
    const textAlign: CanvasTextAlign = 'left';
    context.font = font;
    textWidth = context.measureText(node.label).width;
    longLabelRealWidth = context.measureText(node.label.substr(0, charPerLine)).width;
    node.labelWidth = textWidth; // important for square on hover
    let labelTextLines: string[] = [];
    labelTextLines = splitNodeLabel(node.label, charPerLine);
    labelLinesCount = labelTextLines.length;
    if (labelLinesCount > 6) {
      labelTextLines = labelTextLines.slice(0, 6);
      labelTextLines[5] = labelTextLines[5].trim() + '...';
    }
    const labelTextLineLengthArray = labelTextLines.map((line) => line.length);
    const maxLineIndex = labelTextLineLengthArray.indexOf(Math.max(...labelTextLineLengthArray));
    if (maxLineIndex !== -1) {
      longLabelRealWidth = context.measureText(labelTextLines[maxLineIndex]).width;
    }
    node.longLabelWidth = longLabelRealWidth; // important for square on hover

    let labelPosX;
    if (node.nodes && node.nodes.length > 1) {
      // Reaction with more than one substrates . So will have curved edge
      labelPosX = nodeX + node[prefix + 'size'];
    } else {
      labelPosX = nodeX - longLabelRealWidth * 0.5;
    }

    const returnOb = {
      charPerLine: charPerLine,
      fillStyle: fillStyle,
      font: font,
      textAlign: textAlign,
      labelTextLines: labelTextLines,
      labelPosX: labelPosX,
      longLabelRealWidth: longLabelRealWidth,
    };

    return returnOb;
  };

  const getReactionLabelYPosition = (
    node: any,
    prefix: any,
    size: number,
    typicalConditionLinesPadding: number,
    index: number,
  ) => {
    return (
      node[prefix + 'y'] -
      node[prefix + 'size'] -
      size * typicalConditionLinesPadding -
      (1.1 + index) * size
    );
  };

  const getReactionLabelXPosition = (node: any, prefix: any, size: number, textWidth: number) => {
    if (node.nodes && node.nodes.length > 1) {
      return node[prefix + 'x'] + size;
    } else {
      return node[prefix + 'x'] - textWidth / 2 - 2 * size;
    }
  };

  const getTypicalConditionsLabelDetails = (
    node: any,
    context: CanvasRenderingContext2D,
    settings: any,
  ) => {
    const prefix: string = settings('prefix') || '';
    const size: number = node[prefix + 'size'] / (node.labelSize / 2);
    const nodeX: number = node[prefix + 'x'];
    let textWidth: number;
    let labelLinesCount: number;
    let longLabelRealWidth: number;
    node.label = node.label;
    const charPerLine: number = 35;
    const fillStyle: string = '#9F9F9F';
    const font = '400 italic ' + size + 'px Roboto';
    const textAlign: CanvasTextAlign = 'left';
    let typicalConditionLabelTextLines: string[] = [];
    const typicalConditionLabelTextMaxLengthArray: number[] = [];
    labelLinesCount = Math.ceil(node.typicalConditionLabel.length / charPerLine);
    context.font = font;
    textWidth = context.measureText(node.typicalConditionLabel).width;
    longLabelRealWidth = context.measureText(node.typicalConditionLabel.substr(0, charPerLine))
      .width;
    let loopCounter: number = 1;
    typicalConditionLabelTextLines = [];
    while (loopCounter <= labelLinesCount) {
      const labelPart: string = node.typicalConditionLabel.substring(
        (loopCounter - 1) * charPerLine,
        loopCounter * charPerLine,
      );
      const width = context.measureText(labelPart).width;
      typicalConditionLabelTextMaxLengthArray.push(width);
      loopCounter++;
    }
    typicalConditionLabelTextLines = splitTextWithWordWrap(node, charPerLine);
    if (typicalConditionLabelTextLines.length > 6) {
      node.isTooltipRequired = true;
      typicalConditionLabelTextLines = typicalConditionLabelTextLines.slice(0, 6);
      typicalConditionLabelTextLines[5] = typicalConditionLabelTextLines[5].trim() + '...';
    }
    longLabelRealWidth =
      typicalConditionLabelTextMaxLengthArray.length > 0
        ? Math.max(...typicalConditionLabelTextMaxLengthArray)
        : longLabelRealWidth;

    node.typicalConditionLabelWidth = textWidth; // important for square on hover
    node.typicalConditionLongLabelWidth = longLabelRealWidth; // important for square on hover

    let labelPosX = nodeX - longLabelRealWidth * 0.5;
    if (node.nodes && node.nodes.length > 1) {
      // Reaction with more than one substrates . So will have curved edge
      labelPosX = nodeX + node[prefix + 'size'];
    }
    const returnOb = {
      charPerLine: charPerLine,
      fillStyle: fillStyle,
      font: font,
      textAlign: textAlign,
      typicalConditionLabelTextLines: typicalConditionLabelTextLines,
      labelPosX: labelPosX,
      longLabelRealWidth: longLabelRealWidth,
    };

    return returnOb;
  };

  const getPublishedLabelDetails = (
    node: any,
    context: CanvasRenderingContext2D,
    settings: any,
  ) => {
    const prefix: string = settings('prefix') || '';
    const size: number = node[prefix + 'size'] / (node.labelSize / 2);
    const nodeX: number = node[prefix + 'x'];
    let textWidth: number;
    let labelLinesCount: number;
    let longLabelRealWidth: number = 30;
    node.label = node.label;
    const charPerLine: number = 30;
    const fillStyle: string = node.publishedReferencesLabelColor;
    const font = '400 italic ' + size + 'px Roboto';
    const textAlign: CanvasTextAlign = 'left';
    let publishedReferencesLabelTextLines: string[] = [];
    const publishedReferencesLabelTextMaxLengthArray: number[] = [];
    labelLinesCount = Math.ceil(node.publishedReferencesLabel.length / charPerLine);
    context.font = font;
    textWidth = context.measureText(node.publishedReferencesLabel).width;
    publishedReferencesLabelTextLines = [];
    if (node.publishedReferencesLabel[0].length > 30) {
      node.publishedReferencesLabel[0] =
        node.publishedReferencesLabel[0].substring(0, charPerLine) + '...';
    }
    publishedReferencesLabelTextLines.push(node.publishedReferencesLabel[0]);
    const width = context.measureText(node.publishedReferencesLabel[0]).width;
    publishedReferencesLabelTextMaxLengthArray.push(width);

    longLabelRealWidth =
      publishedReferencesLabelTextMaxLengthArray.length > 0
        ? Math.max(...publishedReferencesLabelTextMaxLengthArray)
        : longLabelRealWidth;

    node.publishedReferencesLabelWidth = textWidth; // important for square on hover
    node.typicalConditionLongLabelWidth = longLabelRealWidth; // important for square on hover
    node.labelWidth = textWidth;

    let labelPosX = nodeX - longLabelRealWidth * 0.6;
    if (node.nodes && node.nodes.length > 1) {
      // Reaction with more than one substrates . So will have curved edge
      labelPosX = nodeX + node[prefix + 'size'];
    }
    const returnOb = {
      charPerLine: charPerLine,
      fillStyle: fillStyle,
      font: font,
      textAlign: textAlign,
      referenceLabelLines: publishedReferencesLabelTextLines,
      labelPosX: labelPosX,
      longLabelRealWidth: longLabelRealWidth,
    };
    return returnOb;
  };

  const expandedReactionLabels = (node: any, context: CanvasRenderingContext2D, settings: any) => {
    // declarations
    const prefix = settings('prefix') || '';
    const size = node[prefix + 'size'] / (node.labelSize / 2);
    const nodeY = node[prefix + 'y'];
    let typicalConditionLabelTextLines: string[] = [];
    let publishedReferencesLabelTextLines: string[] = [];
    let typicalConditionLabelDetails: LabelDetails = null;
    let publishedReferencesLabelDetails: LabelDetails = null;
    let reactionLabelDetails: LabelDetails = null;
    const labelPosXArray: number[] = [];
    const labelPosXArrayRef: number[] = [];
    const labelPosXArrayTypical: number[] = [];
    let labelPosX: number = 0;
    let labelPosXReference: number = 0;
    let labelPosXTypical: number = 0;

    if (node.typicalConditionLabel && typeof node.typicalConditionLabel === 'string') {
      typicalConditionLabelDetails = getTypicalConditionsLabelDetails(node, context, settings);
      typicalConditionLabelDetails.labelPosX = typicalConditionLabelDetails.labelPosX - 0.5 * size;
      labelPosXArrayTypical.push(typicalConditionLabelDetails.labelPosX);
    }

    if (node.label && typeof node.label === 'string') {
      reactionLabelDetails = getReactionLabelDetails(node, context, settings);
      labelPosXArray.push(reactionLabelDetails.labelPosX + 0.5 * size);
    }
    if (
      node.publishedReferencesLabel !== undefined &&
      node.publishedReferencesLabel[0] !== '' &&
      node.colorByBase === COLOR_REACTION_PUBLISHED
    ) {
      publishedReferencesLabelDetails = getPublishedLabelDetails(node, context, settings);
      labelPosXArrayRef.push(publishedReferencesLabelDetails.labelPosX);
    }
    // Find minimum X position to align Typical Condition and Reaction label
    labelPosX = labelPosXArray.length > 0 ? Math.min(...labelPosXArray) : 0;
    labelPosXReference = labelPosXArrayRef.length > 0 ? Math.min(...labelPosXArrayRef) : 0;
    labelPosXTypical = labelPosXArrayTypical.length > 0 ? Math.min(...labelPosXArrayTypical) : 0;

    // For Typical conditions label
    if (typicalConditionLabelDetails) {
      context.fillStyle = typicalConditionLabelDetails.fillStyle;
      context.font = typicalConditionLabelDetails.font;
      context.textAlign = typicalConditionLabelDetails.textAlign;
      typicalConditionLabelTextLines = typicalConditionLabelDetails.typicalConditionLabelTextLines;

      for (const line of typicalConditionLabelTextLines.reverse()) {
        const index = typicalConditionLabelTextLines.indexOf(line);
        context.font = typicalConditionLabelDetails.font;
        context.fillText(
          line,
          getReactionLabelXPosition(node, prefix, size, context.measureText(line).width),
          nodeY - node[prefix + 'size'] - (1.1 + index) * size,
        );
      }
    }

    if (publishedReferencesLabelDetails) {
      context.fillStyle = publishedReferencesLabelDetails.fillStyle;
      context.font = publishedReferencesLabelDetails.font;
      context.textAlign = publishedReferencesLabelDetails.textAlign;
      publishedReferencesLabelTextLines = publishedReferencesLabelDetails.referenceLabelLines;

      for (const line of publishedReferencesLabelTextLines) {
        const index = publishedReferencesLabelTextLines.indexOf(line);
        context.font = publishedReferencesLabelDetails.font;
        context.fillStyle = '#525252';
        context.fillText(
          line,
          getReactionLabelXPosition(node, prefix, size, context.measureText(line).width),
          nodeY + 2.2 * size,
        );
        context.beginPath();
        if (
          node.reaction.reference_doi.length > 0 ||
          node.patents.length > 0 ||
          isValidUrl(node.reaction.reference)
        ) {
          context.moveTo(
            getReactionLabelXPosition(node, prefix, size, context.measureText(line).width),
            nodeY + 2.35 * size,
          );
          const textDimensions = context.measureText(line);
          context.lineTo(
            getReactionLabelXPosition(node, prefix, size, context.measureText(line).width) +
              textDimensions.width,
            nodeY + 2.35 * size,
          );
          context.strokeStyle = '#525252';
          context.lineWidth = 1;
          context.stroke();
        }
      }
    }

    // For Reaction label
    if (reactionLabelDetails) {
      context.fillStyle = reactionLabelDetails.fillStyle;
      context.font = reactionLabelDetails.font;
      context.textAlign = reactionLabelDetails.textAlign;
      let labelTextLines: string[] = [];
      labelTextLines = reactionLabelDetails.labelTextLines;
      const typicalConditionLinesPadding = typicalConditionLabelTextLines.length
        ? typicalConditionLabelTextLines.length + 1
        : 0;

      for (const line of labelTextLines.reverse()) {
        context.fillText(
          line,
          // labelPosX - size,
          getReactionLabelXPosition(node, prefix, size, context.measureText(line).width),
          getReactionLabelYPosition(
            node,
            prefix,
            size,
            typicalConditionLinesPadding,
            labelTextLines.indexOf(line),
          ),
        );
      }
    }
    if (node.showLegendTag) {
      context.beginPath();
      let nodeX = node[prefix + 'x'];
      if (node.nodes && node.nodes.length > 1) {
        // Reaction with more than one substrates . So will have curved edge
        nodeX = nodeX + 5 * size;
      }
      let similarity;
      let y = nodeY;
      let width = 2.75 * size;
      let height = 2.25 * size;
      if (
        node.publishedReferencesLabel !== undefined &&
        node.publishedReferencesLabel[0] !== '' &&
        node.colorByBase === COLOR_REACTION_PUBLISHED
      ) {
        y = y + 2 * size;
      }
      if (node.colorByBase === COLOR_REACTION_PUBLISHED && node.reaction.similarity_score === 1.0) {
        width = 3.25 * size;
        height = 2.75 * size;
        similarity = new Image();
        similarity.src = similarityThreeStarFull;
      } else {
        similarity = getSimilarityImage(node.reaction.similarity_score);
      }
      context.drawImage(similarity, nodeX - 4 * size, y + 1.5 * size, width, height);
      context.fill();
    }
  };

  const progressLoader = (node, context, settings) => {
    const progress = node.progress || 0; // progress value for the node (between 0 and 1)
    const size = node.size || settings('defaultNodeSize');

    // draw the node circle
    context.beginPath();
    context.arc(node.x, node.y, size, 0, Math.PI * 2, true);
    context.closePath();
    context.fillStyle = node.color || settings('defaultNodeColor');
    context.fill();

    // draw the progress loader circle
    const loaderSize = size * 0.8; // set the size of the loader
    context.beginPath();
    context.arc(
      node.x,
      node.y,
      loaderSize,
      -Math.PI / 2,
      -Math.PI / 2 + progress * Math.PI * 2,
      false,
    );
    context.strokeStyle = '#fff'; // set the color of the loader
    context.lineWidth = loaderSize * 0.2; // set the width of the loader
    context.stroke();
  };

  const disableNodeHoverOver = () => {
    // To disable node hover style by sigma
    // Node hovering is enabled in drag
    return false;
  };

  /*tslint:disable:only-arrow-functions*/
  const middlewareRescale = (function (parent) {
    return function (readPrefix, writePrefix, options) {
      // Note: As other middlewares function is called in the scope of sigma instance.
      parent.bind(this)(readPrefix, writePrefix, options);

      const nodes = this.graph.nodes();
      for (const n of nodes) {
        if (n.chScale) {
          // we read from writePrefix to just adjust results of the original rescale middleware
          n[writePrefix + 'size'] = n[writePrefix + 'size'] * n.chScale;
        }
      }
    };
  })(sigma.middlewares.rescale);
  /*tslint:enable:only-arrow-functions*/

  // Most overloads simply exchanges original functions. For those which extend original implementation (ex. by calling
  // it internally) we have to make sure that overloading is done only once.

  sigma.canvas.labels.circle = labelsCircle;
  sigma.canvas.labels.diamond = labelsDiamond;
  sigma.canvas.labels.image = labelsImage;
  sigma.canvas.nodes.circle = nodesCircle;
  sigma.canvas.nodes.diamond = nodesDiamond;
  sigma.canvas.nodes.image = nodesImage;
  sigma.canvas.edges.arrow = edgesArrow;
  sigma.canvas.nodes.expandedImage = expandedNodesImage;
  sigma.canvas.labels.expandedImage = expandedLabelsImage;
  sigma.canvas.edges.expandedArrow = edgesArrowExpanded;
  sigma.canvas.edges.expandedLine = expandedEdgesLine;
  sigma.canvas.edges.curvedDownLine = expandedEdgesLine;
  sigma.canvas.edges.curvedUpLine = expandedEdgesLine;
  sigma.canvas.edgehovers.expandedArrow = edgeHover;
  sigma.canvas.edgehovers.expandedLine = edgeHover;
  sigma.canvas.edgehovers.curvedDownLine = edgeHover;
  sigma.canvas.edgehovers.curvedUpLine = edgeHover;
  sigma.canvas.edgehovers.arrow = edgeHover;
  sigma.canvas.nodes.expandedReaction = expandedReactionLabels;
  sigma.canvas.labels.expandedReaction = expandedReactionLabels;
  sigma.canvas.nodes.progressLoader = progressLoader;

  sigma.canvas.hovers.image = disableNodeHoverOver;
  sigma.canvas.hovers.circle = disableNodeHoverOver;
  sigma.canvas.hovers.diamond = disableNodeHoverOver;
  if (sigma.middlewares.rescale === defaultSigmaRescaleMiddleware) {
    sigma.middlewares.rescale = middlewareRescale;
  }

  // SVG renderers
  // sigma.utils.pkg('sigma.svg.nodes');
  const svgNodesCircle = {
    /**
     * SVG Element creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings The settings function.
     */
    create: (node, settings) => {
      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-node-id', node.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-node');

      // node circle
      const nodeCircle = document.createElementNS(settings('xmlns'), 'circle');
      const innerBorderCircle = document.createElementNS(settings('xmlns'), 'circle');
      const borderCircle = document.createElementNS(settings('xmlns'), 'circle');
      const shopAvailabilityIcon = document.createElementNS(settings('xmlns'), 'circle');
      const shopAvailabilityIconCheck = document.createElementNS(settings('xmlns'), 'path');
      const accessibilityIcon = document.createElementNS(settings('xmlns'), 'text');
      g.appendChild(nodeCircle);
      g.appendChild(innerBorderCircle);
      g.appendChild(borderCircle);
      g.appendChild(shopAvailabilityIcon);
      g.appendChild(shopAvailabilityIconCheck);
      g.appendChild(accessibilityIcon);

      // Returning the DOM Element
      return g;
    },

    /**
     * SVG Element update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} g The node DOM element.
     * @param  {configurable} settings The settings function.
     */
    update: (node, g, settings) => {
      const prefix = settings('prefix') || '';
      const color: string = node.color || settings('defaultNodeColor');
      const size: number = node[prefix + 'size'];
      const x: number = node[prefix + 'x'];
      const y: number = node[prefix + 'y'];
      const contrastEdgeWidth: number = size / 15;
      const iconSize = node[prefix + 'size'] * node.iconRelativeSize;

      const circles = g.getElementsByTagNameNS(settings('xmlns'), 'circle');
      const nodeCircle = circles[0];
      const innerBorderCircle = circles[1];
      const borderCircle = circles[2];
      const shopAvailabilityIcon = circles[3];
      const shopAvailabilityIconCheck = g.getElementsByTagNameNS(settings('xmlns'), 'path')[0];
      const accessibilityIcon = g.getElementsByTagNameNS(settings('xmlns'), 'text')[0];

      nodeCircle.setAttributeNS(
        null,
        'style',
        `fill:${color};stroke:#ffffff;stroke-width:${contrastEdgeWidth};paint-order:markers stroke fill`,
      );
      nodeCircle.setAttributeNS(null, 'cx', x);
      nodeCircle.setAttributeNS(null, 'cy', y);
      nodeCircle.setAttributeNS(null, 'r', size);

      let borderWidth = size * (settings('moleculeBorderWidthRatio') || 0.25);

      if (node.borderColor === COLOR_MOLECULE_BORDER_REGULATED) {
        // Regulated Substance white border
        const innerBorderWidth: number = borderWidth * 2;
        const innerBorderStyle =
          'fill:none;paint-order:markers fill stroke;' +
          `stroke: #FFFFFF;stroke-width:${innerBorderWidth};` +
          `stroke-opacity:${node.borderColor ? '1' : '0'};`;

        innerBorderCircle.setAttributeNS(null, 'style', innerBorderStyle);
        innerBorderCircle.setAttributeNS(null, 'cx', x);
        innerBorderCircle.setAttributeNS(null, 'cy', y);
        innerBorderCircle.setAttributeNS(null, 'r', size - borderWidth / 2);
      }

      let outerBorder: number = borderWidth;
      let strokeStyle: string = '';
      if (node.borderColor === COLOR_MOLECULE_BORDER_PROTECTION_NEEDED) {
        const arcRadius: number = size - borderWidth / 2;
        const segment: number = (2 * Math.PI * arcRadius) / 16;
        outerBorder = borderWidth * 1.5;
        strokeStyle = `stroke-dasharray:${segment};`;
      }
      let style: string =
        'fill:none;paint-order:markers fill stroke;' +
        `stroke:${node.borderColor || '#000'};stroke-width:${outerBorder};` +
        `stroke-opacity:${node.borderColor ? '1' : '0'};`;
      style += strokeStyle;
      borderCircle.setAttributeNS(null, 'style', style);
      borderCircle.setAttributeNS(null, 'cx', x);
      borderCircle.setAttributeNS(null, 'cy', y);
      borderCircle.setAttributeNS(null, 'r', size - borderWidth / 2);

      if (node.borderColor === COLOR_MOLECULE_BORDER_PROTECTION_NEEDED) {
        const innerBorderWidth: number = borderWidth * 2;
        borderWidth = borderWidth * 0.5;

        const innerBorderStyle: string =
          'fill:none;paint-order:markers fill stroke;' +
          `stroke: #FFFFFF;stroke-width:${innerBorderWidth};` +
          `stroke-opacity:${node.borderColor ? '1' : '0'};`;

        innerBorderCircle.setAttributeNS(null, 'style', innerBorderStyle);
        innerBorderCircle.setAttributeNS(null, 'cx', x);
        innerBorderCircle.setAttributeNS(null, 'cy', y);
        innerBorderCircle.setAttributeNS(null, 'r', size - borderWidth / 2);
      }

      if (node.displayShopAvailability && node.availableAtSA) {
        const params = shopAvailabilityIconParams(x, y, size, GraphNodeType.MOLECULE);
        shopAvailabilityIcon.setAttributeNS(
          null,
          'style',
          `fill:${params.color};stroke:${params.borderColor};stroke-width:${params.linesWidth};` +
            'paint-order:markers stroke fill;',
        );
        shopAvailabilityIcon.setAttributeNS(null, 'cx', params.x);
        shopAvailabilityIcon.setAttributeNS(null, 'cy', params.y);
        shopAvailabilityIcon.setAttributeNS(null, 'r', params.size);
        shopAvailabilityIconCheck.setAttributeNS(
          null,
          'd',
          `M${params.checkMarkPath[0].x} ${params.checkMarkPath[0].y}` +
            ` L${params.checkMarkPath[1].x} ${params.checkMarkPath[1].y}` +
            ` L${params.checkMarkPath[2].x} ${params.checkMarkPath[2].y}`,
        );
        shopAvailabilityIconCheck.setAttributeNS(
          null,
          'style',
          `fill:none;stroke-linecap:round;stroke:${params.borderColor}`,
        );
      } else {
        shopAvailabilityIcon.setAttributeNS(null, 'style', 'display: none');
        shopAvailabilityIconCheck.setAttributeNS(null, 'style', 'display: none');
      }

      if (settings('chNodeTypeIcons') && node.icon && typeof node.icon === 'string') {
        accessibilityIcon.setAttributeNS(null, 'x', x);
        accessibilityIcon.setAttributeNS(null, 'y', y + iconSize * node.iconVerticalPositionOffset);
        accessibilityIcon.setAttributeNS(
          null,
          'style',
          `fill:${node.iconColor};font-size:${iconSize}px;` +
            'font-weight:400;font-family:Arial, Helvetica, sans-serif;text-align:center;text-anchor:middle;',
        );
        accessibilityIcon.innerHTML = node.icon;
      } else {
        accessibilityIcon.setAttributeNS(null, 'style', 'display: none');
      }

      // Showing
      g.style.display = '';
      return this;
    },
  };

  const svgNodesDiamond = {
    /**
     * SVG Element creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings The settings function.
     */
    create: (node, settings) => {
      const diamond = document.createElementNS(settings('xmlns'), 'rect');

      // Defining the node's circle
      diamond.setAttributeNS(null, 'data-node-id', node.id);
      diamond.setAttributeNS(null, 'class', settings('classPrefix') + '-node');
      diamond.setAttributeNS(null, 'fill', node.color || settings('defaultNodeColor'));

      // Returning the DOM Element
      return diamond;
    },

    /**
     * SVG Element update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} diamond The node DOM element.
     * @param  {configurable} settings The settings function.
     */
    update: (node, diamond, settings) => {
      const prefix = settings('prefix') || '';
      const x = node[prefix + 'x'];
      const y = node[prefix + 'y'];
      const size = node[prefix + 'size'];
      const color = node.color || settings('defaultNodeColor');
      const borderWidth = (node.borderWidth || defaultDiamondBorderWidth) * (size / 10);
      const borderColor = node.borderColor || defaultDiamondBorderColor;

      // Applying changes
      diamond.setAttributeNS(
        null,
        'transform',
        `translate(${-size / 2} ${-size / 2}) rotate(-45 ${x + size / 2} ${y + size / 2})`,
      );
      diamond.setAttributeNS(null, 'x', x);
      diamond.setAttributeNS(null, 'y', y);
      diamond.setAttributeNS(null, 'width', size);
      diamond.setAttributeNS(null, 'height', size);

      const style =
        'fill-opacity:1;paint-order:markers stroke fill;stroke-opacity:1;' +
        `stroke:${borderColor};stroke-width:${borderWidth};fill:${color};`;
      diamond.setAttributeNS(null, 'style', style);
      diamond.setAttributeNS(null, 'fill', color);

      // Showing
      diamond.style.display = '';

      return this;
    },
  };

  const svgEdgesArrow = {
    /**
     * SVG Element creation.
     *
     * @param  {object}                   edge       The edge object.
     * @param  {object}                   source     The source node object.
     * @param  {object}                   target     The target node object.
     * @param  {configurable}             settings   The settings function.
     */
    create: (edge, source, target, settings) => {
      let color = edge.color;
      const borderColor = edge.borderColor;
      const edgeColor = settings('edgeColor');
      const defaultNodeColor = settings('defaultNodeColor');
      const defaultEdgeColor = settings('defaultEdgeColor');

      if (!color) {
        switch (edgeColor) {
          case 'source':
            color = source.color || defaultNodeColor;
            break;
          case 'target':
            color = target.color || defaultNodeColor;
            break;
          default:
            color = defaultEdgeColor;
            break;
        }
      }

      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-edge-id', edge.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-edge');

      const border = document.createElementNS(settings('xmlns'), 'line');
      const line = document.createElementNS(settings('xmlns'), 'line');
      const arrow = document.createElementNS(settings('xmlns'), 'path');
      g.appendChild(border);
      g.appendChild(arrow);
      g.appendChild(line);

      // Attributes
      border.setAttributeNS(null, 'stroke', borderColor);
      line.setAttributeNS(null, 'stroke', color);
      arrow.setAttributeNS(null, 'fill', color);

      return g;
    },

    /**
     * SVG Element update.
     *
     * @param {object} edge The edge object.
     * @param {DOMElement} g The group element with a line and an arrow path.
     * @param {object} source The source node object.
     * @param {object} target The target node object.
     * @param {configurable} settings The settings function.
     */
    update: (edge, g, source, target, settings) => {
      const prefix = settings('prefix') || '';
      const sX = source[prefix + 'x'];
      const sY = source[prefix + 'y'];
      const tX = target[prefix + 'x'];
      const tY = target[prefix + 'y'];
      // "Diamonds" are squares with edge length equal `size` so they can be inscribed into a circle
      // of radius sqrt(2) * size / 2 and we want to "stop" arrow on this hypothetical surrounding.
      const tSize = target[prefix + 'size'] * (isReactionNode(target) ? Math.sqrt(2) / 2 : 1);
      const size = edge[prefix + 'size'] || 1;
      const arrowSize = Math.max(size * 2.5, settings('minArrowSize'));
      const color = edge.color || settings('defaultEdgeColor');
      const borderWidth = (edge.borderWidth || defaultEdgesBorderWidth) * (size / 7);
      const borderColor = edge.borderColor;

      const border = g.getElementsByTagNameNS(settings('xmlns'), 'line')[0];
      const line = g.getElementsByTagNameNS(settings('xmlns'), 'line')[1];
      const arrow = g.getElementsByTagNameNS(settings('xmlns'), 'path')[0];

      // Calculate arrow aligned with the edge direction and not covered by target node
      const dX = tX - sX;
      const dY = tY - sY;
      const distance = Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2));
      const aX = sX + (dX * (distance - arrowSize - tSize - arrowSize * 0.4)) / distance;
      const aY = sY + (dY * (distance - arrowSize - tSize - arrowSize * 0.4)) / distance;
      const dirX = (dX * arrowSize) / distance;
      const dirY = (dY * arrowSize) / distance;
      arrow.setAttributeNS(
        null,
        'd',
        `M${aX + dirX} ${aY + dirY}` +
          ` L${aX + dirY * 0.6} ${aY - dirX * 0.6}` +
          ` L${aX - dirY * 0.6} ${aY + dirX * 0.6} Z`,
      );
      const arrowStyle =
        'fill-opacity:1;paint-order:markers stroke fill;stroke-opacity:1;' +
        `stroke:${borderColor};stroke-width:${borderWidth};fill:${color};`;
      arrow.setAttributeNS(null, 'style', arrowStyle);

      border.setAttributeNS(null, 'stroke-width', edge[prefix + 'size'] || 1);
      border.setAttributeNS(null, 'x1', sX);
      border.setAttributeNS(null, 'y1', sY);
      border.setAttributeNS(null, 'x2', aX);
      border.setAttributeNS(null, 'y2', aY);

      line.setAttributeNS(null, 'stroke-width', edge[prefix + 'size'] / 2.5 || 1);
      line.setAttributeNS(null, 'x1', sX);
      line.setAttributeNS(null, 'y1', sY);
      line.setAttributeNS(null, 'x2', aX + dirX * 0.5);
      line.setAttributeNS(null, 'y2', aY + dirY * 0.5);

      return this;
    },
  };

  const svgLabelsNode = {
    /**
     * SVG circle node label creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings   Sigma settings.
     */
    create: (node, settings) => {
      const prefix = settings('prefix') || '';
      // avoid influence of nodes scaling on the text of label
      const unscaledNodeCircle = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeCircle / node.labelSize;
      // FIXME: Why not to support the regular scaling of labels as it is in sigmajs?
      // settings('labelSize') === 'fixed' ? settings('defaultLabelSize') : settings('labelSizeRatio') * size;

      const text = document.createElementNS(settings('xmlns'), 'text');

      let fontColor = node.labelColor;
      if ('labelColor' in node) {
        fontColor = node.labelColor;
      } else {
        fontColor =
          settings('labelColor') === 'node'
            ? node.color || settings('defaultNodeColor')
            : settings('defaultLabelColor');
      }

      text.setAttributeNS(null, 'data-label-target', node.id);
      text.setAttributeNS(null, 'class', settings('classPrefix') + '-label');
      text.setAttributeNS(
        null,
        'style',
        'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:400;' +
          `fill:${fontColor};font-size:${fontSize}px;`,
      );

      if (node.label) {
        // Split label to lines -- make them no wider than almost nodes.
        // Using fontSize/2 as a raw estimation of a single letter width seems good
        // enough and more robust than getBBox for not yet rendered elements.
        // FIXME: Split label using more human readable heuristic than by chunk length
        const maxLineLength = Math.ceil((5.8 * unscaledNodeCircle) / (fontSize / 2));
        /* tslint:disable:no-unused-variable */
        const lines = new Array(Math.ceil(node.label.length / maxLineLength))
          .fill('')
          .map((_, i) => node.label.substr(i * maxLineLength, maxLineLength))
          .forEach((line) => {
            const tspan = document.createElementNS(settings('xmlns'), 'tspan');
            tspan.innerHTML = line;
            text.appendChild(tspan);
          });
        /* tslint:enable:no-unused-variable */
      } else {
        text.appendChild(document.createElementNS(settings('xmlns'), 'tspan'));
      }

      return text;
    },

    /**
     * SVG circle node label update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} text The text DOM element which contains label.
     * @param  {configurable} settings Sigma settings.
     */
    update: (node, text, settings) => {
      const prefix = settings('prefix') || '';
      // avoid influence of nodes scaling on the text of label
      const unscaledNodeCircle = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeCircle / node.labelSize;
      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];

      const mediumLetterWidth = fontSize / 2;

      // Updating
      const spans = text.getElementsByTagNameNS(settings('xmlns'), 'tspan');
      const halfFirstLineWidth = (spans[0].innerHTML.length * mediumLetterWidth) / 2;
      const minX = 0;
      const maxX = settings('chDrawAreaWidth');

      let textX;
      if (nodeX - halfFirstLineWidth < minX) {
        textX = minX + (unscaledNodeCircle / 10 + halfFirstLineWidth);
      } else if (maxX && nodeX + halfFirstLineWidth > maxX) {
        textX = maxX - (unscaledNodeCircle / 10 + halfFirstLineWidth);
      } else {
        textX = nodeX;
      }
      const textY = nodeY + unscaledNodeCircle * 1.1 + (fontSize * 5) / 4;

      text.setAttributeNS(null, 'x', textX);
      text.setAttributeNS(null, 'y', textY);
      for (let i = 0; i < spans.length; i++) {
        const span = spans.item(i);
        span.setAttributeNS(null, 'x', textX);
        span.setAttributeNS(null, 'y', textY + i * fontSize);
      }

      // Showing
      text.style.display = node.label ? '' : 'none';

      return this;
    },
  };

  const svgNodesImage = {
    create: (node, settings) => {
      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-node-id', node.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-node');

      // A background/frame rect
      const rect = document.createElementNS(settings('xmlns'), 'rect');
      rect.setAttribute('id', 'ch-svg-image-background');
      g.appendChild(rect);
      const gImage = document.createElementNS(settings('xmlns'), 'g');
      gImage.setAttribute('id', 'ch-svg-image');
      g.appendChild(gImage);

      if (node.needsProtection || node.molecule.regulatory_databases.length > 0) {
        const borderRect = document.createElementNS(settings('xmlns'), 'rect');
        borderRect.setAttribute('id', 'ch-svg-image-border');
        g.appendChild(borderRect);
      }

      const shopAvailabilityIcon = document.createElementNS(settings('xmlns'), 'circle');
      shopAvailabilityIcon.setAttribute('id', 'ch-svg-image-avail-icon');
      const shopAvailabilityIconCheck = document.createElementNS(settings('xmlns'), 'path');
      shopAvailabilityIconCheck.setAttribute('id', 'ch-svg-image-avail-icon-check');
      g.appendChild(shopAvailabilityIcon);
      g.appendChild(shopAvailabilityIconCheck);

      return g;
    },

    update: (node, g, settings) => {
      const prefix = settings('prefix') || '';
      // we have to repeat nodes oversizing proposed in canvas renderers chain
      const oversizeScalingRatio = 1.75;
      const size: number = node[prefix + 'size'] * oversizeScalingRatio;
      const x: number = node[prefix + 'x'];
      const y: number = node[prefix + 'y'];
      // We assume that dimensions set in the provided svg image are equal to the size of node
      const nodeImageWidth = node[prefix + 'size'];
      const nodeImageHeight = node[prefix + 'size'];

      let strokeStyle: string = '';
      if (node.needsProtection) {
        const segment: number = (size * 0.95) / 10;
        strokeStyle = `stroke-dasharray:${segment};`;
      }

      const rect = g.querySelector('#ch-svg-image-background');
      rect.setAttributeNS(null, 'x', `${x - size / 2}`);
      rect.setAttributeNS(null, 'y', `${y - size / 2}`);
      rect.setAttributeNS(null, 'rx', `${size * 0.02}`);
      rect.setAttributeNS(null, 'ry', `${size * 0.02}`);
      rect.setAttributeNS(null, 'width', `${size}`);
      rect.setAttributeNS(null, 'height', `${size}`);
      rect.setAttributeNS(
        null,
        'style',
        'fill:white;paint-order:markers stroke fill;' +
          `stroke:${node.borderColor || '#fff'};stroke-width:${node.borderWidth};` +
          strokeStyle,
      );

      if (node.needsProtection || node.molecule.regulatory_databases.length > 0) {
        const borderRect = g.querySelector('#ch-svg-image-border');
        borderRect.setAttributeNS(null, 'x', `${x - size / 2.1}`);
        borderRect.setAttributeNS(null, 'y', `${y - size / 2.1}`);
        borderRect.setAttributeNS(null, 'rx', `${size * 0.02}`);
        borderRect.setAttributeNS(null, 'ry', `${size * 0.02}`);
        borderRect.setAttributeNS(null, 'width', `${size * 0.95}`);
        borderRect.setAttributeNS(null, 'height', `${size * 0.95}`);
        borderRect.setAttributeNS(
          null,
          'style',
          'fill:white;fill-opacity:0;' +
            `stroke:${node.color || '#fff'};stroke-width:${node.borderWidth * 0.6};`,
        );
      }

      const gImage = g.querySelector('#ch-svg-image');
      gImage.setAttributeNS(null, 'x', '0');
      gImage.setAttributeNS(null, 'y', '0');
      const imgSize = size * 0.5; // slightly downscale image to add some margins
      gImage.setAttributeNS(
        null,
        'transform',
        `translate(${x - imgSize} ${y - imgSize})` +
          ` scale(${imgSize / nodeImageWidth}, ${imgSize / nodeImageHeight})`,
      );
      gImage.innerHTML = node.svgImage ? node.svgImage : '';

      const shopAvailabilityIcon = g.querySelector('#ch-svg-image-avail-icon');
      const shopAvailabilityIconCheck = g.querySelector('#ch-svg-image-avail-icon-check');

      if (node.displayShopAvailability && node.availableAtSA) {
        // shopAvailabilityIconParams were adjusted to a not oversized node
        const params = shopAvailabilityIconParams(
          x,
          y,
          size / oversizeScalingRatio,
          GraphNodeType.STRUCTURE,
        );
        shopAvailabilityIcon.setAttributeNS(
          null,
          'style',
          `fill:${params.color};stroke:${params.borderColor};stroke-width:${params.linesWidth};` +
            'paint-order:markers stroke fill;',
        );
        shopAvailabilityIcon.setAttributeNS(null, 'cx', params.x);
        shopAvailabilityIcon.setAttributeNS(null, 'cy', params.y);
        shopAvailabilityIcon.setAttributeNS(null, 'r', params.size);
        shopAvailabilityIconCheck.setAttributeNS(
          null,
          'd',
          `M${params.checkMarkPath[0].x} ${params.checkMarkPath[0].y}` +
            ` L${params.checkMarkPath[1].x} ${params.checkMarkPath[1].y}` +
            ` L${params.checkMarkPath[2].x} ${params.checkMarkPath[2].y}`,
        );
        shopAvailabilityIconCheck.setAttributeNS(
          null,
          'style',
          `fill:none;stroke-linecap:round;stroke:${params.borderColor}`,
        );
      } else {
        shopAvailabilityIcon.setAttributeNS(null, 'style', 'display: none');
        shopAvailabilityIconCheck.setAttributeNS(null, 'style', 'display: none');
      }

      // Showing
      g.style.display = '';
      return this;
    },
  };

  const svgLabelsImage = {
    /**
     * SVG image node label creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings   Sigma settings.
     */
    create: (node, settings) => {
      const prefix = settings('prefix') || '';
      // avoid influence of nodes scaling on the text of label
      const unscaledNodeSize = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      // Canvas renderer assumes static sizing of image nodes and therefore uses constant font size.
      // We have to reproduce effects hence the constant 100/10 which gives similar result for nodes
      // of size 100 and at least preserves proportion for the other setups.
      const fontSize = unscaledNodeSize / 10;
      // FIXME: Why not to support the regular scaling of labels as it is in sigmajs?
      // settings('labelSize') === 'fixed' ? settings('defaultLabelSize') : settings('labelSizeRatio') * size;

      const text = document.createElementNS(settings('xmlns'), 'text');

      let fontColor = node.labelColor;
      if ('labelColor' in node) {
        fontColor = node.labelColor;
      } else {
        fontColor =
          settings('labelColor') === 'node'
            ? node.color || settings('defaultNodeColor')
            : settings('defaultLabelColor');
      }

      text.setAttributeNS(null, 'data-label-target', node.id);
      text.setAttributeNS(null, 'class', settings('classPrefix') + '-label');
      text.setAttributeNS(
        null,
        'style',
        'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:400;' +
          `fill:${fontColor};font-size:${fontSize}px;`,
      );

      if (node.label) {
        // Split label to lines -- make them no wider than a 1.1 node.
        // Using fontSize/2 as a raw estimation of a single letter width seems good
        // enough and more robust than getBBox for not yet rendered elements.
        // FIXME: Split label using more human readable heuristic than by chunk length
        const maxLineLength = Math.ceil((1.1 * unscaledNodeSize) / (fontSize / 2));
        /* tslint:disable:no-unused-variable */
        const lines = new Array(Math.ceil(node.label.length / maxLineLength))
          .fill('')
          .map((_, i) => node.label.substr(i * maxLineLength, maxLineLength))
          .forEach((line) => {
            const tspan = document.createElementNS(settings('xmlns'), 'tspan');
            tspan.innerHTML = line;
            text.appendChild(tspan);
          });
        /* tslint:enable:no-unused-variable */
      } else {
        text.appendChild(document.createElementNS(settings('xmlns'), 'tspan'));
      }

      return text;
    },

    /**
     * SVG image node label update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} text The text DOM element which contains label.
     * @param  {configurable} settings Sigma settings.
     */
    update: (node, text, settings) => {
      const prefix = settings('prefix') || '';
      // avoid influence of nodes scaling on the text of label
      const unscaledNodeSize = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeSize / (100 / 10); // see note in the create method above
      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];

      const mediumLetterWidth = fontSize / 2;

      // Updating
      const spans = text.getElementsByTagNameNS(settings('xmlns'), 'tspan');
      const halfFirstLineWidth = (spans[0].innerHTML.length * mediumLetterWidth) / 2;
      const minX = -unscaledNodeSize;
      const maxX = settings('chDrawAreaWidth') + unscaledNodeSize;

      let textX;
      if (nodeX - halfFirstLineWidth < minX) {
        textX = minX + (unscaledNodeSize / 50 + halfFirstLineWidth);
      } else if (maxX && nodeX + halfFirstLineWidth > maxX) {
        textX = maxX - (unscaledNodeSize / 50 + halfFirstLineWidth);
      } else {
        textX = nodeX;
      }
      const textY = nodeY + unscaledNodeSize * 0.825 + fontSize * 1.5;

      text.setAttributeNS(null, 'x', textX);
      text.setAttributeNS(null, 'y', textY);
      for (let i = 0; i < spans.length; i++) {
        const span = spans.item(i);
        span.setAttributeNS(null, 'x', textX);
        span.setAttributeNS(null, 'y', textY + i * fontSize);
      }

      // Showing
      text.style.display = node.label ? '' : 'none';

      return this;
    },
  };

  const svgNodesImageExpanded = {
    create: (node, settings) => {
      const prefix = settings('prefix') || '';
      const size = node[prefix + 'size'];
      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-node-id', node.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-node');

      const rect = document.createElementNS(settings('xmlns'), 'rect');
      rect.setAttribute('id', 'ch-svg-image-background');
      g.appendChild(rect);

      if (node.showRepeatedMolecules) {
        const rectNode = document.createElementNS(settings('xmlns'), 'rect');
        rectNode.setAttribute('id', 'hightlight-molecule');
        g.appendChild(rectNode);

        const rectNew = document.createElementNS(settings('xmlns'), 'rect');
        rectNew.setAttribute('id', 'highlight-molecule-count');
        g.appendChild(rectNew);

        const text = document.createElementNS(settings('xmlns'), 'text');
        text.setAttribute('id', 'highlight-molecule-value');
        g.appendChild(text);
        const tspan = document.createElementNS(settings('xmlns'), 'tspan');
        tspan.setAttribute('id', 'highlight-molecule-span');
        text.appendChild(tspan);
        text.setAttributeNS(
          null,
          'style',
          'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:500;' +
            `fill:#FFF;font-size:${size * 0.13}px;`,
        );
      }
      const gImage = document.createElementNS(settings('xmlns'), 'g');
      gImage.setAttribute('id', 'ch-svg-image');
      g.appendChild(gImage);
      return g;
    },

    update: (node, g, settings) => {
      const prefix = settings('prefix') || '';
      const oversizeScalingRatio = 1.75;
      const size: number = node[prefix + 'size'] * oversizeScalingRatio;
      const x: number = node[prefix + 'x'];
      const y: number = node[prefix + 'y'];
      const nodeImageWidth = node[prefix + 'size'];
      const nodeImageHeight = node[prefix + 'size'];

      const rect = g.querySelector('#ch-svg-image-background');
      rect.setAttributeNS(null, 'x', `${x - size / 2}`);
      rect.setAttributeNS(null, 'y', `${y - size / 2}`);
      rect.setAttributeNS(null, 'rx', `${size * 0.02}`);
      rect.setAttributeNS(null, 'ry', `${size * 0.02}`);
      rect.setAttributeNS(null, 'width', `${size * 0.8}`);
      rect.setAttributeNS(null, 'height', `${size}`);
      rect.setAttributeNS(null, 'style', 'fill:white;paint-order:markers stroke fill;');

      if (node.showRepeatedMolecules) {
        const color = node.repeatedMoleculeColor;
        const lineWidth = size * 0.015;
        const rectNew = g.querySelector('#hightlight-molecule');
        rectNew.setAttributeNS(null, 'x', `${x - size * 0.68}`);
        rectNew.setAttributeNS(null, 'y', `${y - size * 0.48}`);
        rectNew.setAttributeNS(null, 'width', `${size * 0.95}`);
        rectNew.setAttributeNS(null, 'height', `${size * 0.96}`);
        rectNew.setAttributeNS(
          null,
          'style',
          `fill:#FFFFFF;paint-order:markers stroke fill;` +
            `stroke:${color || '#fff'};stroke-width:${lineWidth};`,
        );

        const width = size * 0.115;
        const height = size * 0.115;

        const rectCount = g.querySelector('#highlight-molecule-count');
        const textX = x - size * 0.52;
        const textY = y - size * 0.445;
        rectCount.setAttributeNS(null, 'x', x - size * 0.58);
        rectCount.setAttributeNS(null, 'y', y - size * 0.53);
        rectCount.setAttributeNS(null, 'rx', `${size * 0.6}`);
        rectCount.setAttributeNS(null, 'ry', `${size * 0.6}`);
        rectCount.setAttributeNS(null, 'width', width);
        rectCount.setAttributeNS(null, 'height', height);
        rectCount.setAttributeNS(
          null,
          'style',
          `fill:${color};paint-order:markers stroke fill;` +
            `stroke:${'#FFFFFF' || '#fff'};stroke-width:${lineWidth};`,
        );

        const text = g.querySelector('#highlight-molecule-value');
        const textSpan = g.querySelector('#highlight-molecule-span');
        text.setAttributeNS(null, 'x', textX);
        text.setAttributeNS(null, 'y', textY);

        textSpan.setAttributeNS(null, 'x', textX);
        textSpan.setAttributeNS(null, 'y', textY);

        textSpan.innerHTML = node.repeatedMoleculeCount;
      }

      const gImage = g.querySelector('#ch-svg-image');
      gImage.setAttributeNS(null, 'x', '0');
      gImage.setAttributeNS(null, 'y', '0');
      const imgSize = size * 0.45; // slightly downscale image to add some margins
      const xPosition = x - size * 0.7;
      gImage.setAttributeNS(
        null,
        'transform',
        `translate(${xPosition} ${y - 0.95 * imgSize})` +
          ` scale(${imgSize / nodeImageWidth}, ${imgSize / nodeImageHeight})`,
      );

      gImage.innerHTML = node.svgImage ? node.svgImage : '';
      g.style.display = '';
      return this;
    },
  };

  const svgLabelsImageExpanded = {
    /**
     * SVG image node label creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings   Sigma settings.
     */
    create: (node, settings) => {
      const prefix = settings('prefix') || '';
      const unscaledNodeSize = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeSize / 8;
      const text = document.createElementNS(settings('xmlns'), 'text');
      node.labelColor = '#EB3C96';
      let fontColor = node.labelColor;
      if ('labelColor' in node) {
        fontColor = node.labelColor;
      } else {
        fontColor =
          settings('labelColor') === 'node'
            ? node.color || settings('defaultNodeColor')
            : settings('defaultLabelColor');
      }

      text.setAttributeNS(null, 'data-label-target', node.id);
      text.setAttributeNS(null, 'class', settings('classPrefix') + '-label');
      text.setAttributeNS(
        null,
        'style',
        'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:700;' +
          `fill:${fontColor};font-size:${fontSize}px;`,
      );

      if (node.label) {
        const maxLineLength = Math.ceil((1.1 * unscaledNodeSize) / (fontSize / 2));
        const lines = new Array(Math.ceil(node.label.length / maxLineLength))
          .fill('')
          .map((_, i) => node.label.substr(i * maxLineLength, maxLineLength))
          .forEach((line) => {
            const tspan = document.createElementNS(settings('xmlns'), 'tspan');
            tspan.innerHTML = line;
            text.appendChild(tspan);
          });
      } else {
        text.appendChild(document.createElementNS(settings('xmlns'), 'tspan'));
      }

      return text;
    },

    /**
     * SVG image node label update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} text The text DOM element which contains label.
     * @param  {configurable} settings Sigma settings.
     */
    update: (node, text, settings) => {
      const prefix = settings('prefix') || '';
      const unscaledNodeSize = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeSize / (100 / 10);
      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];
      // Updating
      const spans = text.getElementsByTagNameNS(settings('xmlns'), 'tspan');
      const textY = nodeY + unscaledNodeSize * 0.825 + fontSize * 3;

      text.setAttributeNS(null, 'x', nodeX - 23);
      text.setAttributeNS(null, 'y', textY);
      for (let i = 0; i < spans.length; i++) {
        const span = spans.item(i);
        span.setAttributeNS(null, 'x', nodeX - unscaledNodeSize / 3);
        span.setAttributeNS(null, 'y', textY + i * fontSize);
      }

      // Showing
      text.style.display = node.label ? '' : 'none';

      return this;
    },
  };

  const svgEdgesArrowExpanded = {
    /**
     * SVG Element creation.
     *
     * @param  {object}                   edge       The edge object.
     * @param  {object}                   source     The source node object.
     * @param  {object}                   target     The target node object.
     * @param  {configurable}             settings   The settings function.
     */
    create: (edge, source, target, settings) => {
      let color = edge.color;
      let edgeBorderColor = edge.borderColor;
      const edgeColor = settings('edgeColor');
      const defaultNodeColor = settings('defaultNodeColor');
      const defaultEdgeColor = settings('defaultEdgeColor');

      if (edge.showRepeatedReactions) {
        edgeBorderColor = edge.repeatedReactionColor;
        color = edge.repeatedReactionColor;
      }

      if (!color) {
        switch (edgeColor) {
          case 'source':
            color = source.color || defaultNodeColor;
            break;
          case 'target':
            color = target.color || defaultNodeColor;
            break;
          default:
            color = defaultEdgeColor;
            break;
        }
      }

      if (!edgeBorderColor) {
        edgeBorderColor = color;
      }

      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-edge-id', edge.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-edge');

      const border = document.createElementNS(settings('xmlns'), 'line');
      const line = document.createElementNS(settings('xmlns'), 'line');
      const arrow = document.createElementNS(settings('xmlns'), 'path');
      g.appendChild(border);
      g.appendChild(arrow);
      g.appendChild(line);

      // Attributes
      border.setAttributeNS(null, 'stroke', edgeBorderColor);
      line.setAttributeNS(null, 'stroke', color);
      arrow.setAttributeNS(null, 'fill', color);

      return g;
    },

    /**
     * SVG Element update.
     *
     * @param {object} edge The edge object.
     * @param {DOMElement} g The group element with a line and an arrow path.
     * @param {object} source The source node object.
     * @param {object} target The target node object.
     * @param {configurable} settings The settings function.
     */
    update: (edge, g, source, target, settings) => {
      const prefix = settings('prefix') || '';
      const size = edge[prefix + 'size'];
      const sX = source[prefix + 'x'];
      const sY = source[prefix + 'y'];
      const tX = target[prefix + 'x'];
      const tY = target[prefix + 'y'];
      let edgeBorderColor = edge.borderColor;
      let color = edge.color;
      const distance = Math.sqrt(Math.pow(tX - sX, 2) + Math.pow(tY - sY, 2));
      const aSize = Math.max(edge[prefix + 'size'] * 2.5, settings('minArrowSize'));
      const dirX = (tX - sX) / distance;
      const dirY = (tY - sY) / distance;

      if (edge.showRepeatedReactions) {
        edgeBorderColor = edge.repeatedReactionColor;
        color = edge.repeatedReactionColor;
      }
      const aX = sX + dirX * (distance - aSize) * (source.nodes.length > 1 ? 0.6 : 0.45);
      const aY = sY + dirY * (distance - aSize) * 0.6;

      const arrowPath =
        `M${aX + dirX * aSize} ${aY + dirY * aSize}` +
        ` L${aX + dirY * 0.6 * aSize} ${aY - dirX * 0.6 * aSize}` +
        ` L${aX - dirY * 0.6 * aSize} ${aY + dirX * 0.6 * aSize} Z`;
      const border = g.getElementsByTagNameNS(settings('xmlns'), 'line')[0];
      const lineElement = g.getElementsByTagNameNS(settings('xmlns'), 'line')[0];
      const arrowElement = g.getElementsByTagNameNS(settings('xmlns'), 'path')[0];

      border.setAttributeNS(null, 'stroke-width', size);
      border.setAttributeNS(null, 'x1', sX);
      border.setAttributeNS(null, 'y1', sY);
      border.setAttributeNS(null, 'x2', tX);
      border.setAttributeNS(null, 'y2', sY);

      lineElement.setAttributeNS(null, 'stroke-width', size);
      lineElement.setAttributeNS(null, 'x1', sX);
      lineElement.setAttributeNS(null, 'y1', sY);
      lineElement.setAttributeNS(null, 'x2', aX);
      lineElement.setAttributeNS(null, 'y2', aY);

      arrowElement.setAttributeNS(null, 'd', arrowPath);

      const arrowStyle = `fill-opacity:1;paint-order:markers stroke fill;stroke-opacity:1;stroke:${edgeBorderColor};stroke-width:${size};fill:${color};`;
      arrowElement.setAttributeNS(null, 'style', arrowStyle);

      return this;
    },
  };

  const svgEdgesLineExpanded = {
    /**
     * SVG Element creation.
     *
     * @param  {object}                   edge       The edge object.
     * @param  {object}                   source     The source node object.
     * @param  {object}                   target     The target node object.
     * @param  {configurable}             settings   The settings function.
     */
    create: (edge, source, target, settings) => {
      const prefix = settings('prefix') || '';
      const size = edge[prefix + 'size'] * 1.5 || 1;
      let color = edge.color;
      let edgeBorderColor = edge.borderColor;
      const edgeColor = settings('edgeColor');
      const defaultNodeColor = settings('defaultNodeColor');
      const defaultEdgeColor = settings('defaultEdgeColor');
      if (!color) {
        switch (edgeColor) {
          case 'source':
            color = source.color || defaultNodeColor;
            break;
          case 'target':
            color = target.color || defaultNodeColor;
            break;
          default:
            color = defaultEdgeColor;
            break;
        }
      }

      if (!edgeBorderColor) {
        edgeBorderColor = color;
      }

      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-edge-id', edge.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-edge');
      if (edge.type === GraphEdgeType.EXPANDED_LINE) {
        const border = document.createElementNS(settings('xmlns'), 'line');
        border.setAttribute('id', 'expanded-border-line');
        g.appendChild(border);
      } else if (isCurvedEdge(edge)) {
        const line1 = document.createElementNS(settings('xmlns'), 'line');
        line1.setAttribute('id', 'curved-line1');
        const line2 = document.createElementNS(settings('xmlns'), 'line');
        line2.setAttribute('id', 'curved-line2');
        g.appendChild(line1);
        g.appendChild(line2);
      }

      if (edge.showRepeatedReactions) {
        edgeBorderColor = edge.repeatedReactionColor;
        color = edge.repeatedReactionColor;
        const rectNew = document.createElementNS(settings('xmlns'), 'rect');
        rectNew.setAttribute('id', 'highlight-reaction-count');
        g.appendChild(rectNew);

        const text = document.createElementNS(settings('xmlns'), 'text');
        text.setAttribute('id', 'highlight-reaction-value');
        g.appendChild(text);
        const tspan = document.createElementNS(settings('xmlns'), 'tspan');
        tspan.setAttribute('id', 'highlight-reaction-span');
        text.appendChild(tspan);
        text.setAttributeNS(
          null,
          'style',
          'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:500;' +
            `fill:#FFF;font-size:${size * 4.25}px;`,
        );
      }

      if (source.showLegendTag) {
        if (calculateMoleculePopularity(source)) {
          const image = document.createElementNS(settings('xmlns'), 'g');
          image.setAttribute('id', 'popularity-icon');
          g.appendChild(image);

          const rect = document.createElementNS(settings('xmlns'), 'rect');
          rect.setAttribute('id', 'image-popularity-text');
          g.appendChild(rect);

          const text = document.createElementNS(settings('xmlns'), 'text');
          text.setAttribute('id', 'image-popularity-value');
          g.appendChild(text);
          const tspan = document.createElementNS(settings('xmlns'), 'tspan');
          tspan.setAttribute('id', 'image-popularity-span');
          text.appendChild(tspan);
          text.setAttributeNS(
            null,
            'style',
            'text-align:center;text-anchor:middle;font-family:sans-serif;font-feature-settings:normal;font-weight:500;' +
              `fill:#FFFFFF;font-size:${size}px;`,
          );
        }

        if (!!source.needsProtection) {
          const image = document.createElementNS(settings('xmlns'), 'g');
          image.setAttribute('id', 'protection-icon');
          g.appendChild(image);
        }

        if (source.molecule.regulatory_databases.length > 0) {
          const image = document.createElementNS(settings('xmlns'), 'g');
          image.setAttribute('id', 'regulatory-icon');
          g.appendChild(image);
        }
      }
      return g;
    },

    /**
     * SVG Element update.
     *
     * @param {object} edge The edge object.
     * @param {DOMElement} g The group element with a line and an arrow path.
     * @param {object} source The source node object.
     * @param {object} target The target node object.
     * @param {configurable} settings The settings function.
     */
    update: (edge, g, source, target, settings) => {
      const prefix = settings('prefix') || '';
      const size = edge[prefix + 'size'] * 1.4 || 1;
      const sX = source[prefix + 'x'];
      const sY = source[prefix + 'y'];
      const tX = target[prefix + 'x'];
      const tY = target[prefix + 'y'];
      let edgeBorderColor = edge.borderColor;
      let color = edge.color || settings('defaultEdgeColor');

      if (edge.showRepeatedReactions) {
        edgeBorderColor = edge.repeatedReactionColor;
        color = edge.repeatedReactionColor;
      }

      if (edge.type === GraphEdgeType.EXPANDED_LINE) {
        const border = g.querySelector('#expanded-border-line');
        let sourceX = source.x;
        let targetX = target.x;
        if (target.nodes.length > 1) {
          sourceX = sX;
          targetX = tX;
          if (sY === tY) {
            sourceX = sX + 90;
          }
        }
        border.setAttributeNS(null, 'stroke-width', edge[prefix + 'size']);
        border.setAttribute('stroke', edgeBorderColor);
        border.setAttribute('x1', sourceX);
        border.setAttribute('y1', sY);
        border.setAttribute('x2', targetX);
        border.setAttribute('y2', tY);

        if (edge.showRepeatedReactions) {
          const x = tX - 2.5 * size,
            y = tY - 3.2 * size,
            width = size * 7,
            height = size * 7;
          const lineWidth = size * 0.89;
          const rectNew = g.querySelector('#highlight-reaction-count');
          const textX = target.nodes.length > 1 ? x + 2.5 * size : x - 1.5 * size;
          const textY = target.nodes.length > 1 ? y + 1 * size : y + 5 * size;
          rectNew.setAttributeNS(null, 'x', target.nodes.length > 1 ? x : x - 5 * size);
          rectNew.setAttributeNS(null, 'y', y);
          rectNew.setAttributeNS(null, 'rx', `${size * 4}`);
          rectNew.setAttributeNS(null, 'ry', `${size * 4}`);
          rectNew.setAttributeNS(null, 'width', width);
          rectNew.setAttributeNS(null, 'height', height);
          rectNew.setAttributeNS(
            null,
            'style',
            `fill:${color};paint-order:markers stroke fill;` +
              `stroke:${'#FFFFFF' || '#fff'};stroke-width:${lineWidth};`,
          );

          const text = g.querySelector('#highlight-reaction-value');
          const textSpan = g.querySelector('#highlight-reaction-span');
          text.setAttributeNS(null, 'x', textX);
          text.setAttributeNS(null, 'y', textY);

          textSpan.setAttributeNS(null, 'x', textX);
          textSpan.setAttributeNS(null, 'y', textY);

          textSpan.innerHTML = edge.repeatedReactionCount;
        }
      } else if (isCurvedEdge(edge)) {
        if (edge.showRepeatedReactions) {
          const x = tX - 3 * size,
            y = tY - 3.5 * size,
            width = size * 7,
            height = size * 7;
          const lineWidth = size * 0.89;
          const rectNew = g.querySelector('#highlight-reaction-count');
          const textX = x + 3.15 * size;
          const textY = y + 5 * size;
          rectNew.setAttributeNS(null, 'x', x);
          rectNew.setAttributeNS(null, 'y', y);
          rectNew.setAttributeNS(null, 'rx', `${size * 4}`);
          rectNew.setAttributeNS(null, 'ry', `${size * 4}`);
          rectNew.setAttributeNS(null, 'width', width);
          rectNew.setAttributeNS(null, 'height', height);
          rectNew.setAttributeNS(
            null,
            'style',
            `fill:${color};paint-order:markers stroke fill;` +
              `stroke:${'#FFFFFF' || '#fff'};stroke-width:${lineWidth};`,
          );

          const text = g.querySelector('#highlight-reaction-value');
          const textSpan = g.querySelector('#highlight-reaction-span');
          text.setAttributeNS(null, 'x', textX);
          text.setAttributeNS(null, 'y', textY);

          textSpan.setAttributeNS(null, 'x', textX);
          textSpan.setAttributeNS(null, 'y', textY);

          textSpan.innerHTML = edge.repeatedReactionCount;
        }
        const curvedLine = g.querySelector('#curved-line1');
        const curvedLine2 = g.querySelector('#curved-line2');

        curvedLine.setAttribute('x1', sX + 90);
        curvedLine.setAttribute('y1', sY);
        curvedLine.setAttribute('x2', tX);
        curvedLine.setAttribute('y2', sY);
        curvedLine.setAttributeNS(null, 'stroke-width', size);
        curvedLine.setAttribute('stroke', edgeBorderColor);

        curvedLine2.setAttribute('x1', tX);
        curvedLine2.setAttribute('y1', sY);
        curvedLine2.setAttribute('x2', tX);
        curvedLine2.setAttribute('y2', tY);
        curvedLine2.setAttributeNS(null, 'stroke-width', size);
        curvedLine2.setAttribute('stroke', edgeBorderColor);
      }
      if (source.showLegendTag) {
        if (calculateMoleculePopularity(source)) {
          const gImage = g.querySelector('#popularity-icon');

          gImage.setAttributeNS(null, 'x', sX);
          gImage.setAttributeNS(null, 'y', sY);

          const imgSize = size;
          gImage.setAttributeNS(
            null,
            'transform',
            `translate(${sX + 21 * imgSize} ${sY - 10})` +
              ` scale(${imgSize / size}, ${imgSize / size})`,
          );
          gImage.innerHTML = popularityIconSvg;
          const moleculePopularity = String(calculateMoleculePopularity(source));
          if (moleculePopularity) {
            const lineWidth = size * 0.89;
            const usX = sX + size * 16.5;
            const usY = sY - 4.5 * size;
            const x = usX + size * 6.5;
            const y = usY - 0.2 * size;
            const rect = g.querySelector('#image-popularity-text');
            const width = size * Math.max(moleculePopularity.length, 2);
            const height = size * 4;
            const textX = x + width / 2 + 4.3 * size;
            const textY = y + height / 2 + 1.2 * size;
            rect.setAttributeNS(null, 'x', x + 12);
            rect.setAttributeNS(null, 'y', y + 5);
            rect.setAttributeNS(null, 'rx', `${size}`);
            rect.setAttributeNS(null, 'ry', `${size}`);
            rect.setAttributeNS(null, 'width', width);
            rect.setAttributeNS(null, 'height', 6);
            rect.setAttributeNS(
              null,
              'style',
              'fill:#3C2274;paint-order:markers stroke fill;' +
                `stroke:${'#FFFFFF' || '#fff'};stroke-width:${lineWidth};`,
            );

            const text = g.querySelector('#image-popularity-value');
            const textSpan = g.querySelector('#image-popularity-span');
            text.setAttributeNS(null, 'x', textX);
            text.setAttributeNS(null, 'y', textY);

            textSpan.setAttributeNS(null, 'x', textX);
            textSpan.setAttributeNS(null, 'y', textY);

            textSpan.innerHTML = moleculePopularity;
          }
        }
        if (!!source.needsProtection) {
          const gImage = g.querySelector('#protection-icon');
          gImage.setAttributeNS(null, 'x', sX);
          gImage.setAttributeNS(null, 'y', sY);

          const imgSize = size;
          const yPosition = calculateMoleculePopularity(source) ? sY + 8 : sY - 10;
          gImage.setAttributeNS(
            null,
            'transform',
            `translate(${sX + 25 * imgSize} ${yPosition})` +
              ` scale(${imgSize / size}, ${imgSize / size})`,
          );
          gImage.innerHTML = protectionIconSvg;
        }

        if (source.molecule.regulatory_databases.length > 0) {
          const gImage = g.querySelector('#regulatory-icon');
          gImage.setAttributeNS(null, 'x', sX);
          gImage.setAttributeNS(null, 'y', sY);

          const imgSize = size;
          const yPosition = calculateMoleculePopularity(source) ? sY + 8 : sY - 10;
          gImage.setAttributeNS(
            null,
            'transform',
            `translate(${sX + 25 * imgSize} ${yPosition})` +
              ` scale(${imgSize / size}, ${imgSize / size})`,
          );
          gImage.innerHTML = regulatoryIconSvg;
        }
      }
      // Showing
      g.style.display = '';
      return this;
    },
  };

  function rectionLineBreak(text, maxLineLength) {
    if (text.length <= maxLineLength) {
      return text.length;
    }

    for (let i = maxLineLength; i >= 0; i--) {
      if (text[i] === ' ' || text[i] === '\n') {
        return i + 1;
      }
    }

    return maxLineLength;
  }

  function typicalConditionBreak(text, maxLineLength, maxLines) {
    if (text.length <= maxLineLength) {
      return text.length;
    }
    const lines = text.split('\n');
    if (lines.length > maxLines) {
      return maxLineLength - 3;
    }

    for (let i = maxLineLength; i >= 0; i--) {
      if (text[i] === ' ' || text[i] === '\n') {
        return i + 1;
      }
    }

    return maxLineLength;
  }

  const svgExpandedReactionNodes = {
    /**
     * SVG circle node label creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings   Sigma settings.
     */
    create: (node, settings) => {
      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-label-target', node.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-node');
      return g;
    },

    /**
     * SVG circle node label update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} text The text DOM element which contains label.
     * @param  {configurable} settings Sigma settings.
     */
    update: (node, g, settings) => {
      const prefix = settings('prefix') || '';
      const unscaledNodeCircle = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = unscaledNodeCircle / node.labelSize;
      let fontColor = node.labelColor;
      if ('labelColor' in node) {
        fontColor = node.labelColor;
      } else {
        fontColor =
          settings('labelColor') === 'node'
            ? node.color || settings('defaultNodeColor')
            : settings('defaultLabelColor');
      }
      g.style.display = '';
      return this;
    },
  };

  const svgExpandedReactionLabels = {
    /**
     * SVG reaction label creation.
     *
     * @param  {object} node The node object.
     * @param  {configurable} settings   Sigma settings.
     */
    create: (node, settings) => {
      const g = document.createElementNS(settings('xmlns'), 'g');
      g.setAttributeNS(null, 'data-label-target', node.id);
      g.setAttributeNS(null, 'class', settings('classPrefix') + '-node');

      if (node.label) {
        const reaction = document.createElementNS(settings('xmlns'), 'text');
        reaction.setAttribute('id', 'reaction-label');

        g.appendChild(reaction);
        const maxLineLength = node.label.length > 100 ? 45 : 30;
        let remainingText = node.label;
        while (remainingText.length > 0) {
          const lineBreakIndex = rectionLineBreak(remainingText, maxLineLength);
          const line = remainingText.substring(0, lineBreakIndex).trim();
          remainingText = remainingText.substring(lineBreakIndex).trim();
          const tspan = document.createElementNS(settings('xmlns'), 'tspan');
          tspan.setAttribute('id', 'reaction-label-text');
          tspan.innerHTML = line;
          reaction.appendChild(tspan);
        }
      }

      if (node.typicalConditionLabel !== undefined) {
        const textTypical = document.createElementNS(settings('xmlns'), 'text');
        textTypical.setAttribute('id', 'typical-condition-label');

        g.appendChild(textTypical);
        const maxLineLength = 35;
        const maxLines = 6;
        let remainingText = node.typicalConditionLabel;
        let lineCount = 0;

        while (remainingText.length > 0 && lineCount < maxLines) {
          const lineBreakIndex = typicalConditionBreak(
            remainingText,
            maxLineLength,
            maxLines - lineCount,
          );
          let line = remainingText.substring(0, lineBreakIndex).trim();
          let typicalTooltip = false;
          if (line.length >= maxLineLength) {
            typicalTooltip = true;
            line = line.substring(0, maxLineLength - 3) + '...';
            remainingText = '';
          } else {
            remainingText = remainingText.substring(lineBreakIndex).trim();
          }

          const tspan = document.createElementNS(settings('xmlns'), 'tspan');
          tspan.setAttribute('id', 'typical-condition-text');
          tspan.innerHTML = line;
          textTypical.appendChild(tspan);
          if (typicalTooltip) {
            const title = document.createElementNS(settings('xmlns'), 'title');
            title.setAttribute('id', 'typical-condition-title');
            title.innerHTML = node.typicalConditionLabel;
            tspan.appendChild(title);
          }
          lineCount++;
        }
      }

      if (
        node.publishedReferencesLabel !== undefined &&
        node.publishedReferencesLabel[0] !== '' &&
        node.colorByBase === COLOR_REACTION_PUBLISHED
      ) {
        const publishedText = document.createElementNS(settings('xmlns'), 'text');
        publishedText.setAttribute('id', 'published-condition-label');

        g.appendChild(publishedText);
        const maxLineLength = 30;
        let publishedReferencesLabel = node.publishedReferencesLabel[0];
        if (node.publishedReferencesLabel[0].length > 30) {
          publishedReferencesLabel =
            node.publishedReferencesLabel[0].substring(0, maxLineLength) + '...';
        }
        const tspan = document.createElementNS(settings('xmlns'), 'tspan');
        tspan.setAttribute('id', 'published-condition-text');
        if (node.reaction.reference_doi.length > 0) {
          const doiLink = document.createElementNS(settings('xmlns'), 'a');
          doiLink.setAttribute('id', 'published-condition-link');
          doiLink.setAttribute('href', `http://dx.doi.org/${node.reaction.reference_doi}`);
          doiLink.setAttribute('target', '_blank');
          publishedText.appendChild(doiLink);
          tspan.innerHTML = publishedReferencesLabel;
          doiLink.appendChild(tspan);
        } else if (node.patents.length > 0) {
          const patentLink = document.createElementNS(settings('xmlns'), 'a');
          patentLink.setAttribute('id', 'published-condition-link');
          patentLink.setAttribute('href', `https://patents.google.com/?oq=${node.patents[0]}`);
          patentLink.setAttribute('target', '_blank');
          publishedText.appendChild(patentLink);
          tspan.innerHTML = publishedReferencesLabel;
          patentLink.appendChild(tspan);
        } else {
          if (isValidUrl(node.publishedReferencesLabel[0])) {
            const referenceLink = document.createElementNS(settings('xmlns'), 'a');
            referenceLink.setAttribute('id', 'published-condition-link');
            referenceLink.setAttribute('href', node.publishedReferencesLabel[0]);
            referenceLink.setAttribute('target', '_blank');
            publishedText.appendChild(referenceLink);
            tspan.innerHTML = publishedReferencesLabel;
            referenceLink.appendChild(tspan);
          } else {
            tspan.innerHTML = publishedReferencesLabel;
            publishedText.appendChild(tspan);
          }
        }
      }

      if (node.nodes && node.nodes.length >= 1 && node.showLegendTag) {
        const image = document.createElementNS(settings('xmlns'), 'g');
        image.setAttribute('id', 'similarity-image-star');
        g.appendChild(image);
      }
      return g;
    },

    /**
     * SVG circle node label update.
     *
     * @param  {object} node The node object.
     * @param  {DOMElement} text The text DOM element which contains label.
     * @param  {configurable} settings Sigma settings.
     */
    update: (node, g, settings) => {
      const prefix = settings('prefix') || '';
      const size = node[prefix + 'size'] / (node.labelSize / 2);
      // avoid influence of nodes scaling on the text of label
      const unscaledNodeCircle = node.chScale
        ? node[prefix + 'size'] / node.chScale
        : node[prefix + 'size'];
      const fontSize = 10;

      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];
      let lineCount = 0;
      const maxLineLength = 35;
      const maxLines = 6;
      if (node.typicalConditionLabel && typeof node.typicalConditionLabel === 'string') {
        let remainingText = node.typicalConditionLabel;
        while (remainingText.length > 0 && lineCount < maxLines) {
          const lineBreakIndex = typicalConditionBreak(
            remainingText,
            maxLineLength,
            maxLines - lineCount,
          );
          let line = remainingText.substring(0, lineBreakIndex).trim();
          if (line.length >= maxLineLength) {
            line = line.substring(0, maxLineLength - 3) + '...';
            remainingText = '';
          } else {
            remainingText = remainingText.substring(lineBreakIndex).trim();
          }
          lineCount++;
        }
      }

      if (node.label && typeof node.label === 'string') {
        const fontColor = node.labelColor;
        const reaction = g.querySelector('#reaction-label');
        const textPosition = node.nodes.length > 1 ? 'start' : 'middle';
        reaction.setAttributeNS(
          null,
          'style',
          'text-align:left;font-family:Roboto;font-feature-settings:normal;font-weight: bold;' +
            `fill:${fontColor};font-size:${fontSize}px;text-anchor:${textPosition};`,
        );
        const xPosition = node.nodes.length > 1 ? nodeX + size : nodeX - size;
        const reactionText = reaction.querySelectorAll('tspan');
        const yPosition = nodeY - size * (lineCount + reactionText.length + 0.5);
        if (reaction) {
          for (let i = 0; i < reactionText.length; i++) {
            const span = reactionText.item(i);
            span.setAttributeNS(null, 'x', xPosition);
            span.setAttributeNS(null, 'y', yPosition + i * fontSize);
          }
        }
      }

      if (node.typicalConditionLabel !== undefined) {
        const typical = g.querySelector('#typical-condition-label');
        const textPosition = node.nodes.length > 1 ? 'start' : 'middle';
        typical.setAttributeNS(
          null,
          'style',
          'text-align:left;font-family:Roboto;font-feature-settings:normal;font-weight:700;font-style:italic;' +
            `fill:#9F9F9F;font-size:${fontSize}px;text-anchor:${textPosition};`,
        );
        const xPosition = node.nodes.length > 1 ? nodeX + size : nodeX - size;
        const yPosition = nodeY - size * lineCount;
        typical.setAttributeNS(null, 'x', xPosition);
        typical.setAttributeNS(null, 'y', yPosition);
        if (typical) {
          const typicalConditionText = typical.querySelectorAll('tspan');
          for (let i = 0; i < typicalConditionText.length; i++) {
            const span = typicalConditionText.item(i);
            span.setAttributeNS(null, 'x', xPosition);
            span.setAttributeNS(null, 'y', yPosition + i * fontSize);
          }
        }
      }

      if (
        node.publishedReferencesLabel !== undefined &&
        node.publishedReferencesLabel[0] !== '' &&
        node.colorByBase === COLOR_REACTION_PUBLISHED
      ) {
        const textPosition = node.nodes.length > 1 ? 'start' : 'middle';
        const xPosition = node.nodes.length > 1 ? nodeX + size : nodeX - size;
        const yPosition = nodeY + size * 2;
        const published = g.querySelector('#published-condition-label');
        published.setAttributeNS(
          null,
          'style',
          'text-align:left;font-family:Roboto;font-feature-settings:normal;font-weight:700;font-style:italic;' +
            `fill:${node.labelColor};font-size:${fontSize}px;text-anchor:${textPosition};`,
        );
        published.setAttributeNS(null, 'x', xPosition);
        published.setAttributeNS(null, 'y', yPosition);
        if (published) {
          const publishedText = published.querySelectorAll('tspan');
          for (let i = 0; i < publishedText.length; i++) {
            const span = publishedText.item(i);
            if (
              node.reaction.reference_doi.length > 0 ||
              node.patents.length > 0 ||
              isValidUrl(node.publishedReferencesLabel[0])
            ) {
              span.setAttributeNS(null, 'style', 'text-decoration:underline');
            }
            span.setAttributeNS(null, 'x', xPosition);
            span.setAttributeNS(null, 'y', yPosition + i * fontSize);
          }
        }
      }

      if (node.nodes && node.nodes.length >= 1 && node.showLegendTag) {
        let similarity = '';
        if (
          node.colorByBase === COLOR_REACTION_PUBLISHED &&
          node.reaction.similarity_score === 1.0
        ) {
          similarity = similarityThreeStarFullSvg;
        } else {
          similarity = getSimilarityImageSvg(node.reaction.similarity_score);
        }
        const nodeImageWidth = node[prefix + 'size'];
        const nodeImageHeight = node[prefix + 'size'];
        const gImage = g.querySelector('#similarity-image-star');
        gImage.setAttributeNS(null, 'x', nodeX);
        gImage.setAttributeNS(null, 'y', nodeY);
        const imgSize = size * 0.8; // slightly downscale image to add some margins
        const bookPosition = node.nodes.length > 1 ? nodeX + size : nodeX - size * 2.8;
        const yPosition =
          node.publishedReferencesLabel !== undefined &&
          node.publishedReferencesLabel[0] !== '' &&
          node.colorByBase === COLOR_REACTION_PUBLISHED
            ? nodeY + (imgSize + size * 2)
            : nodeY + (imgSize + 10);

        gImage.setAttributeNS(
          null,
          'transform',
          `translate(${bookPosition} ${yPosition})` +
            ` scale(${imgSize / nodeImageWidth}, ${imgSize / nodeImageHeight})`,
        );
        gImage.innerHTML = similarity;
      }
      // Showing
      g.style.display = '';
      return this;
    },
  };

  sigma.svg.nodes.circle = svgNodesCircle;
  sigma.svg.nodes.diamond = svgNodesDiamond;
  sigma.svg.nodes.image = svgNodesImage;
  sigma.svg.edges.arrow = svgEdgesArrow;
  sigma.svg.labels.circle = svgLabelsNode;
  sigma.svg.labels.diamond = svgLabelsNode;
  sigma.svg.labels.image = svgLabelsImage;

  sigma.svg.nodes.expandedImage = svgNodesImageExpanded;
  sigma.svg.labels.expandedImage = svgLabelsImageExpanded;
  sigma.svg.edges.expandedArrow = svgEdgesArrowExpanded;
  sigma.svg.nodes.expandedReaction = svgExpandedReactionNodes;
  sigma.svg.labels.expandedReaction = svgExpandedReactionLabels;
  sigma.svg.edges.expandedLine = svgEdgesLineExpanded;
  sigma.svg.edges.curvedDownLine = svgEdgesLineExpanded;
  sigma.svg.edges.curvedUpLine = svgEdgesLineExpanded;
  // Added experimental listener to touch events. It seems to be dependent on having mouse events.
  // At the moment I am unable to tell if all methods here are essential for the listener to work.
  if (isTouchDevice()) {
    (() => {
      'use strict';

      if (typeof sigma === 'undefined') {
        throw 'sigma is not declared'; /* tslint:disable-line:no-string-throw */
      }

      sigma.utils.pkg('sigma.plugins');

      /**
       * This function will add `mousedown`, `mouseup` & `mousemove` events to the
       * nodes in the `overNode`event to perform drag & drop operations. It uses
       * `linear interpolation` [http://en.wikipedia.org/wiki/Linear_interpolation]
       * and `rotation matrix` [http://en.wikipedia.org/wiki/Rotation_matrix] to
       * calculate the X and Y coordinates from the `cam` or `renderer` node
       * attributes. These attributes represent the coordinates of the nodes in
       * the real container, not in canvas.
       *
       * Recognized parameters:
       * **********************
       * @param  {sigma}    s        The related sigma instance.
       * @param  {renderer} renderer The related renderer instance.
       * @param  {options}  options  Options to enable or disable features.
       */
      sigma.plugins.dragNodes = // FIXME: sigmajs typings is to strict for dragNodes function to compile.
        // As we don't want to modify original typings we can wait for upgrade, or try to refactor this code
        // with use of only available fields/parameters/etc. For now we skip the type checks for renderer
        // and function.
        ((s, renderer, options?) => {
          const typelessRenderer = renderer as any;

          // copy drawEdges value in case it was already disabled
          const oldDrawEdges = typelessRenderer.settings('drawEdges');
          // Creating options to disable touch or mouse drags as well as hiding edges while dragging
          const defaults = {
            enableTouchDrag: true,
            enableMouseDrag: true,
            hideEdgeDragging: false,
          };
          if (typeof options === 'object') {
            for (const i in defaults) {
              if (typeof options[i] === 'undefined') {
                options[i] = defaults[i];
              }
            }
          } else {
            options = defaults;
          }
          // A quick hardcoded rule to prevent people from using this plugin with the
          // WebGL renderer (which is impossible at the moment):
          if (sigma.renderers.webgl && typelessRenderer instanceof sigma.renderers.webgl) {
            throw new Error(
              'The sigma.plugins.dragNodes is not compatible with the WebGL renderer',
            );
          }

          const _body = document.body;
          const _container = typelessRenderer.container;
          const _mouse = _container.lastChild;
          const _camera = typelessRenderer.camera;
          let _node = null;
          let _prefix = '';
          let _isOverNode = false;

          let nodeMouseDown;
          let nodeMouseOver;
          let treatOutNode;
          let nodeMouseUp;
          let nodeMouseMove;
          let nodeTouchOver;
          let nodeTouchEnd;
          let nodeTouchMove;

          // It removes the initial substring ('read_') if it's a WegGL renderer.
          if (typelessRenderer instanceof sigma.renderers.webgl) {
            _prefix = typelessRenderer.options.prefix.substr(5);
          } else {
            _prefix = typelessRenderer.options.prefix;
          }

          nodeMouseOver = (event: any) => {
            if (!_isOverNode) {
              _node = event.data.node;
              _mouse.addEventListener('mousedown', nodeMouseDown);
              _isOverNode = true;
            }
          };

          treatOutNode = (event?: any) => {
            if (_isOverNode) {
              _mouse.removeEventListener('mousedown', nodeMouseDown);
              _isOverNode = false;
            }
          };

          nodeMouseDown = (event: any) => {
            const size = s.graph.nodes().length;
            if (size > 1) {
              _mouse.removeEventListener('mousedown', nodeMouseDown);
              _body.addEventListener('mousemove', nodeMouseMove);
              _body.addEventListener('mouseup', nodeMouseUp);

              typelessRenderer.unbind('outNode', treatOutNode);

              // Deactivate drag graph.
              typelessRenderer.settings({
                mouseEnabled: false,
                enableHovering: false,
                touchEnabled: false,
              });
              if (options['hideEdgeDragging']) {
                typelessRenderer.settings({ drawEdges: false });
              }
              s.refresh();
            }
          };

          nodeMouseUp = (event) => {
            _mouse.addEventListener('mousedown', nodeMouseDown);
            _body.removeEventListener('mousemove', nodeMouseMove);
            _body.removeEventListener('mouseup', nodeMouseUp);

            treatOutNode();
            typelessRenderer.bind('outNode', treatOutNode);

            // Activate drag graph.
            typelessRenderer.settings({
              mouseEnabled: true,
              enableHovering: false,
              touchEnabled: true,
            });
            if (options['hideEdgeDragging']) {
              typelessRenderer.settings({ drawEdges: oldDrawEdges });
            }
            s.refresh();
          };

          nodeMouseMove = (event) => {
            let x = event.pageX - _container.offsetLeft;
            let y = event.pageY - _container.offsetTop;
            const cos = Math.cos(_camera.angle);
            const sin = Math.sin(_camera.angle);
            const nodes = s.graph.nodes();
            const ref = [];

            // Getting and derotating the reference coordinates.
            for (let i = 0; i < 2; i++) {
              const n = nodes[i];
              const aux = {
                x: n.x * cos + n.y * sin,
                y: n.y * cos - n.x * sin,
                renX: n[_prefix + 'x'],
                renY: n[_prefix + 'y'],
              };
              ref.push(aux);
            }

            // if the nodes are on top of each other, we use the camera ratio to interpolate
            let xRatio;
            let yRatio;
            if (ref[0].x === ref[1].x && ref[0].y === ref[1].y) {
              xRatio = ref[0].renX === 0 ? 1 : ref[0].renX;
              yRatio = ref[0].renY === 0 ? 1 : ref[0].renY;
              x = (ref[0].x / xRatio) * (x - ref[0].renX) + ref[0].x;
              y = (ref[0].y / yRatio) * (y - ref[0].renY) + ref[0].y;
            } else {
              xRatio = (ref[1].renX - ref[0].renX) / (ref[1].x - ref[0].x);
              yRatio = (ref[1].renY - ref[0].renY) / (ref[1].y - ref[0].y);

              // if the coordinates are the same, we use the other ratio to interpolate
              if (ref[1].x === ref[0].x) {
                xRatio = yRatio;
              }

              if (ref[1].y === ref[0].y) {
                yRatio = xRatio;
              }

              x = (x - ref[0].renX) / xRatio + ref[0].x;
              y = (y - ref[0].renY) / yRatio + ref[0].y;
            }

            // Rotating the coordinates.
            _node.x = x * cos - y * sin;
            _node.y = y * cos + x * sin;
            s.refresh();
          };

          nodeTouchOver = (event) => {
            _node = event.data.node;
            _mouse.addEventListener('touchmove', nodeTouchMove);
            _mouse.addEventListener('touchend', nodeTouchEnd);
          };

          nodeTouchEnd = (event) => {
            _mouse.removeEventListener('touchmove', nodeTouchMove);
            _mouse.removeEventListener('touchend', nodeTouchEnd);
            typelessRenderer.settings({
              mouseEnabled: true,
              enableHovering: false,
              touchEnabled: true,
            });
            if (options['hideEdgeDragging']) {
              typelessRenderer.settings({ drawEdges: oldDrawEdges });
            }
            s.refresh();
          };

          // New move function because event X and Y coordinates are located somewhere else - YP
          nodeTouchMove = (event) => {
            // Added option to hide Edges when dragging
            if (options['hideEdgeDragging']) {
              typelessRenderer.settings({ drawEdges: false });
            }
            typelessRenderer.settings({
              mouseEnabled: false,
              enableHovering: false,
              touchEnabled: false,
            });
            s.refresh();
            let x = event.targetTouches[0].clientX - _container.offsetLeft;
            let y = event.targetTouches[0].clientY - _container.offsetTop;
            const cos = Math.cos(_camera.angle);
            const sin = Math.sin(_camera.angle);
            const nodes = s.graph.nodes();
            const ref = [];
            // Getting and derotating the reference coordinates.
            for (let i = 0; i < 2; i++) {
              const n = nodes[i];
              const aux = {
                x: n.x * cos + n.y * sin,
                y: n.y * cos - n.x * sin,
                renX: n[_prefix + 'x'],
                renY: n[_prefix + 'y'],
              };
              ref.push(aux);
            }

            // if the nodes are on top of each other, we use the camera ratio to interpolate
            let xRatio;
            let yRatio;
            if (ref[0].x === ref[1].x && ref[0].y === ref[1].y) {
              xRatio = ref[0].renX === 0 ? 1 : ref[0].renX;
              yRatio = ref[0].renY === 0 ? 1 : ref[0].renY;
              x = (ref[0].x / xRatio) * (x - ref[0].renX) + ref[0].x;
              y = (ref[0].y / yRatio) * (y - ref[0].renY) + ref[0].y;
            } else {
              xRatio = (ref[1].renX - ref[0].renX) / (ref[1].x - ref[0].x);
              yRatio = (ref[1].renY - ref[0].renY) / (ref[1].y - ref[0].y);

              // if the coordinates are the same, we use the other ratio to interpolate
              if (ref[1].x === ref[0].x) {
                xRatio = yRatio;
              }

              if (ref[1].y === ref[0].y) {
                yRatio = xRatio;
              }

              x = (x - ref[0].renX) / xRatio + ref[0].x;
              y = (y - ref[0].renY) / yRatio + ref[0].y;
            }

            _node.x = x * cos - y * sin;
            _node.y = y * cos + x * sin;
            s.refresh();
          };

          if (options['enableTouchDrag']) {
            typelessRenderer.bind('overNode', nodeTouchOver);
          }
          if (options['enableMouseDrag']) {
            typelessRenderer.bind('overNode', nodeMouseOver);
            typelessRenderer.bind('outNode', treatOutNode);
          }
        }) as any;
    }).call(window);
  }
}

/**
 * For some Chematica algorithms Dagre sets the positions of starting (or target) nodes behind the others
 * nodes of the nearest rank. This method assures that mentioned nodes are positioned alone in a separate
 * rank (row/column): starting node before other ranks, target node after other ranks.
 *
 * @param {GraphReactionNode[] & GraphMoleculeNode[]} nodes Array of nodes.
 * @param {Array<{source: number, target: number}>} edges Array of edges (we require edges ends only).
 * @param {"x" | "y"} rankDirection Along which coordinate the ranks are spreaded.
 *
 * Computational complexity O(|N| + |E|*|R|), where R is the number of ranks found by Dagre; worst case O(|N|²),
 * typical O(|N|).
 */
export function dagreOverlapsTunning(
  nodes: GraphReactionNode[] & GraphMoleculeNode[],
  edges: Array<{ source: number; target: number }>,
  rankDirection: 'x' | 'y' = 'x',
) {
  const ranks: {
    [rankLabel: string]: {
      [nodeId: string]: {
        [sourceRankLabel: string]: number;
      };
    };
  } = {};
  const nodesToRanks: { [nodeId: number]: string } = {};
  const nodesToIdx: { [nodeId: number]: number } = {};
  let rankLabel: string;
  let targetNodeId: number;
  let startingNodeId: number;

  // group nodes by rank (as far as horizontal layout is considered rank equals column)
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    rankLabel = node[rankDirection].toString();
    if (!ranks[rankLabel]) {
      ranks[rankLabel] = { [node.id]: {} };
    } else {
      ranks[rankLabel][node.id] = {};
    }
    nodesToRanks[node.id] = rankLabel;
    nodesToIdx[node.id] = i;

    if (isMoleculeNode(node)) {
      if (node.target) {
        targetNodeId = node.id;
      }
      if (node.isStartingMolecule) {
        startingNodeId = node.id;
      }
    }
  }

  // find out which other ranks are connected to nodes in each rank
  for (const edge of edges) {
    for (const rank of Object.keys(ranks)) {
      if (ranks[rank][edge.target]) {
        const sourceRank = nodesToRanks[edge.source];
        ranks[rank][edge.target][sourceRank] = 1 + (ranks[rank][edge.target][sourceRank] || 0);
      }
    }
  }

  // push target node to the last rank and make sure it is alone there (create one more rank if required)
  // same for starting molecule but move it to the left
  if (Object.keys(ranks).length > 2) {
    // shouldn't be required for 2 or less ranks

    const orderedRanksLabels = Object.keys(ranks).sort((a, b) => Number(a) - Number(b));
    const ranksDistance = Number(orderedRanksLabels[1]) - Number(orderedRanksLabels[0]);

    if (targetNodeId) {
      const targetNodeRank = nodesToRanks[targetNodeId];
      if (Object.keys(ranks[targetNodeRank]).length > 1) {
        // target is not alone in it's rank
        nodes[nodesToIdx[targetNodeId]].x =
          Number(orderedRanksLabels[orderedRanksLabels.length - 1]) + ranksDistance;
      }
    }

    // overlapping starting node is repositioned in the opposite direction than target (unless it was already moved
    // as target which is preferred)
    if (startingNodeId && startingNodeId !== targetNodeId) {
      const startingNodeRank = nodesToRanks[startingNodeId];
      if (Object.keys(ranks[startingNodeRank]).length > 1) {
        // starting node is not alone in it's rank
        nodes[nodesToIdx[startingNodeId]].x = Number(orderedRanksLabels[0]) - ranksDistance;
      }
    }
  }
}

/**
 * Positioning nodes in ranks Dagre layout don't care if ranks overlap along perpendicular direction (imagine that
 * leafs from the ranks far from the root cast shadows on the lower rank leafs in a three graph). This function
 * repositions nodes rankwise so that the node in a given molecule rank is not obscured (looking toward target node)
 * by the nodes of next rank.
 *
 * @param {GraphMoleculeNode[] & GraphReactionNode[]} nodes Array of nodes.
 * @param {Array<{source: number, target: number}>} edges Array of edges (we require edges ends only).
 * @param {"x" | "y"} rankDirection Along which coordinate the ranks are spreaded.
 *
 * NOTE: This function expects regular tree-like graphs with root at the end (rightmost or at the bottom)
 *       as preformated with Dagre graphs returned for manual retrosyntesis. It is written as "manual-retro-specific"
 *       layout to get this layout without rewriting whole Dagre from scratch. However it is quite universal
 *       end can be a base for a Dagre independent simple tree layout (see fixme notes in the code).
 *
 * Computational complexity: linear time O(|N| + |E| + |Bm|), where |Bm| << |N| is the set of branching molecule nodes
 */
export function spreadLeafMoleculeNodes(
  nodes: GraphMoleculeNode[] & GraphReactionNode[],
  edges: Array<{ source: number; target: number }>,
  rankDirection: 'x' | 'y' = 'x',
) {
  // Find leaf molecule nodes
  const branchingMoleculeNodeIds = {};
  for (const edge of edges) {
    if (nodes[edge.target - 1] && nodes[edge.target - 1].type === 'circle') {
      branchingMoleculeNodeIds[edge.target] = 1;
    }
  }
  const leafMoleculeNodeIds = {};
  let leafNodesCount = 0;
  for (const node of nodes) {
    if (node.type === 'circle' && !branchingMoleculeNodeIds[node.id]) {
      leafMoleculeNodeIds[node.id] = 1;
      leafNodesCount++;
    }
  }

  // Group leaf nodes by common nearest child molecule node
  const childToParents = {};
  edges.forEach((edge) => {
    if (childToParents[edge.target]) {
      childToParents[edge.target].push(edge.source);
    } else {
      childToParents[edge.target] = [edge.source];
    }
  });
  const commonChildGroups = {};
  Object.keys(branchingMoleculeNodeIds).forEach(
    // Note that only branching nodes can be children ones.
    (branchingNodeId) => {
      // traverse backward a parentMolecule-->parentReaction-->branchingNode graph fragment to find nearest molecule
      // parent and add all of them to a group
      commonChildGroups[branchingNodeId] = [];
      childToParents[branchingNodeId].forEach((parentReaction) =>
        childToParents[parentReaction].forEach((parentMolecule) =>
          commonChildGroups[branchingNodeId].push(parentMolecule),
        ),
      );
    },
  );

  // Find a root grouping node. We assume that this should be a "target" node which is as well
  // the one with a highest rank. However as "target" property is not dependent on the results
  // of pre-layout with Dagre, we look for target with fallback to the highest rank.
  let treeRootId;
  for (const id of Object.keys(commonChildGroups)) {
    if (nodes[Number(id) - 1].target) {
      treeRootId = id;
      break;
    }
  }
  // FIXME: This fallback can be removed to avoid Dagre pre-layout dependency.
  if (!treeRootId) {
    console.warn(
      'removeMoleculeRanksOverlaps: Missing target molecule node in the list of branching molecules' +
        ` which was expected. Proceeding anyway with ${
          rankDirection === 'x' ? 'rightmost' : 'lowest'
        }` +
        ' molecule.',
    );
    let maxRank = -1e10;
    Object.keys(commonChildGroups).forEach((id) => {
      const rank = nodes[Number(id) - 1][rankDirection];
      if (maxRank < rank) {
        maxRank = rank;
        treeRootId = id;
      }
    });
  }

  // FIXME: One can use some arbitrary height (function parameter?) to avoid pre-layout dependency.
  // Calculate rank-perpendicular size of graph to span leaf nodes along it later
  const rankPerpendicularDirection = rankDirection === 'x' ? 'y' : 'x';
  let minCoordinate = 1e10;
  let maxCoordinate = -1e10;
  for (const node of nodes) {
    if (minCoordinate > node[rankPerpendicularDirection]) {
      minCoordinate = node[rankPerpendicularDirection];
    }
    if (maxCoordinate < node[rankPerpendicularDirection]) {
      maxCoordinate = node[rankPerpendicularDirection];
    }
  }
  const graphSize = maxCoordinate === minCoordinate ? 1 : maxCoordinate - minCoordinate;
  const coordinateStep = graphSize / leafNodesCount;

  const alignGroup = (groupId, currentCoordinate) => {
    // FIXME: Add our own edges-crossing-solving positioning to remove most critical Dagre pre-layout dependency.
    // Sort the group by perpendicular coordinate to preserve in-rank order of nodes set by Dagre layout
    commonChildGroups[groupId].sort(
      (lowestId, higherId) =>
        nodes[lowestId - 1][rankPerpendicularDirection] -
        nodes[higherId - 1][rankPerpendicularDirection],
    );

    for (const inGroupMoleculeId of commonChildGroups[groupId]) {
      if (leafMoleculeNodeIds[inGroupMoleculeId]) {
        nodes[inGroupMoleculeId - 1][rankPerpendicularDirection] = currentCoordinate;
        currentCoordinate += coordinateStep;
      } else {
        const oldCoordinate = currentCoordinate;
        currentCoordinate = alignGroup(inGroupMoleculeId, currentCoordinate);

        // put grouping node in the middle of it's parent nodes belonging to group
        nodes[inGroupMoleculeId - 1][rankPerpendicularDirection] =
          (currentCoordinate + oldCoordinate) / 2.0;
      }
    }
    return currentCoordinate;
  };

  // Align molecule nodes in each group recurrently starting in the center
  alignGroup(treeRootId, minCoordinate);

  // Put each incoming reactions in the middle of connected molecule nodes
  for (const groupId of Object.keys(commonChildGroups)) {
    childToParents[groupId].forEach((parentReaction) => {
      let meanCoordinate = 0;
      childToParents[parentReaction].forEach((parentMolecule) => {
        meanCoordinate += nodes[parentMolecule - 1][rankPerpendicularDirection];
      });
      meanCoordinate /= childToParents[parentReaction].length;
      nodes[parentReaction - 1][rankPerpendicularDirection] = meanCoordinate;
    });
  }
}

/**
 * Possible symbolic representations of molecule nodes.
 */
export type AccessibilityIconType = '☀' | '?' | '$' | '◉' | '';

/**
 * Convert molecule node symbol to UTF-less representation to make it ready for base64 encoding.
 * @param {AccessibilityIconType} accessibilitySymbol
 * @returns {string}
 */
function accessibilityIconToHTMLCode(accessibilitySymbol: AccessibilityIconType): string {
  switch (accessibilitySymbol) {
    case '☀':
      return '&#9728;';
    case '◉':
      return '&#9673;';
    default:
      return accessibilitySymbol;
  }
}

/**
 * Builds a SVG image of molecule node -- a colored circle with specified border. Returned image is scalable
 * and is set to fill up its container.
 * @param {string} color Color of the center of circle for example '#ffff000' for yellow node.
 * @param {string} borderColor Border color.
 * @param {string} accessibilitySymbol Optional character to add at the center of node as an accessibility icon.
 * @param {string} accessibilitySymbolPosition Symbol position (as symbol, force lower/upper case letter, by value).
 * @returns {string}
 */
export function buildMoleculeNodeSVG(
  color: string,
  borderColor: string,
  accessibilitySymbol: AccessibilityIconType = '',
  accessibilitySymbolPosition:
    | 'from-symbol'
    | 'lower-case'
    | 'upper-case'
    | { x: number; y: number } = 'from-symbol',
): string {
  const upperCase = { x: 5.1, y: 6.8 };
  const symbolPositionMap = {
    '☀': { x: 5.1, y: 6.6 },
    '◉': { x: 5.1, y: 6.4 },
    '': upperCase,
    '?': upperCase,
    $: upperCase,
    'upper-case': upperCase,
    'lower-case': { x: 5.1, y: 6.4 },
    default: upperCase,
  };
  // to make it terse we set the default position and adjust it only if required
  let symbolPosition = symbolPositionMap.default;
  if (accessibilitySymbol) {
    if (accessibilitySymbolPosition === 'from-symbol') {
      if (symbolPositionMap[accessibilitySymbol]) {
        symbolPosition = symbolPositionMap[accessibilitySymbol];
      } else {
        // detect any upper/lover case letters
        if (accessibilitySymbol.toLowerCase() === accessibilitySymbol) {
          symbolPosition = symbolPositionMap['lower-case'];
        } else if (accessibilitySymbol.toUpperCase() === accessibilitySymbol) {
          symbolPosition = symbolPositionMap['upper-case'];
        }
      }
    } else if (
      accessibilitySymbolPosition === 'upper-case' ||
      accessibilitySymbolPosition === 'lower-case'
    ) {
      symbolPosition = symbolPositionMap[accessibilitySymbolPosition];
    } else if (
      typeof accessibilitySymbolPosition === 'object' &&
      !isUndefined(accessibilitySymbolPosition.x) &&
      !isUndefined(accessibilitySymbolPosition.y)
    ) {
      symbolPosition = accessibilitySymbolPosition;
    }
  }

  let outerCircleStyle = `fill:${borderColor};fill-rule:evenodd`;
  let svgStyle = `enable-background:new 0 0 10 10`;
  if (borderColor === '#009CE1') {
    outerCircleStyle =
      'fill: none;paint-order: markers fill stroke;stroke: #009CE1;stroke-width: 3px;stroke-opacity: 1;stroke-dasharray: 2;';
    svgStyle = 'border-radius: 12px;enable-background:new 0 0 10 10';
  }

  // tslint:disable:max-line-length
  return `<svg
    id="MoleculeNode" style="${svgStyle}" xmlns="http://www.w3.org/2000/svg" version="1.1"
    fit="" focusable="false" xml:space="preserve"
    x="0px" y="0px" viewBox="0 0 10 10" height="100%" width="100%" preserveAspectRatio="xMidYMid meet">
    <circle id="outerCircle" cx="5" style="${outerCircleStyle}" r="5" cy="5"/>
    <circle id="innerCircle" cx="5" style="fill:${color};fill-rule:evenodd" r="3.5" cy="5"/>
    ${
      accessibilitySymbol
        ? `<text text-anchor="middle" x="${symbolPosition.x}" y="${symbolPosition.y}"` +
          ' style="font-family: Arial; font-size: 5px; fill: #ffffff; font-weight: bold;">' +
          accessibilityIconToHTMLCode(accessibilitySymbol) +
          '</text>'
        : ''
    }
    </svg>`;
  // tslint:enable:max-line-length
}

/**
 * Fast algorithm for finding reactants list of reaction nodes in graph. Function steps through provided graph and for
 * each reaction node find a list of substrates and products. Lists are saved in node.attributes field under the given
 * names.
 *
 * @param {{nodes: any[], edges: any[]}} graph The graph with reaction nodes to decorate.
 * @param {string} substratesAttributeName Name of substrates list attribute of reaction node.
 * @param {string} productsAttributeName Name of products list attribute of  reaction node.
 * @returns {any[]} An array of reaction nodes.
 *
 * COMPLEXITY: Time O(ElogE), memory O(E), where E is the number of edges.
 * BENCHMARKS: 2.4GHz core, mean of 1000 runs (N - number of nodes, Chrome 62.0.3202.62, FF 52.0.8, Linux):
 *             (N,E):(13,12) -- ~400 000 graphs/s (Chrome:~3µs per graph, FF:2µs)
 *             (N,E):(1500,1500) -- ~1000 graphs/s (Chrome:~1200µs, FF:700µs)
 */
export function setReactionsReactants(graph: Graph) {
  // Sort reactions from start to end (target) path. Works properly only for paths export to PDF.
  // We will use another sorting method for PDF exports from graph view
  const sortReactions = (r: any) => {
    r = r.sort((r1, r2) => {
      return r2.reaction_node.id - r1.reaction_node.id;
    });

    return r;
  };

  // For small graphs use naive algorithm which has minimal initialization cost
  let graphReactionNodes: GraphReactionNode[];
  if (graph.nodes.length * graph.edges.length < 500) {
    graphReactionNodes = graph.nodes.filter((node: GraphReactionNode) => isReactionNode(node));
    for (const graphReactionNode of graphReactionNodes) {
      const substrates = [];
      const products = [];
      graphReactionNode.graphId = graph.syntheticPath;
      for (const e of graph.edges) {
        if (e.target === graphReactionNode.id) {
          substrates.push(graph.nodes[Number(e.source) - 1]);
        }
        if (e.source === graphReactionNode.id) {
          products.push(graph.nodes[Number(e.target) - 1]);
        }
      }
      graphReactionNode.substrateNodes = substrates.sort(
        (s1: GraphMoleculeNode, s2: GraphMoleculeNode) => {
          return (
            graphReactionNode.reaction.substrates.indexOf(s1.molecule.id) -
            graphReactionNode.reaction.substrates.indexOf(s2.molecule.id)
          );
        },
      );
      graphReactionNode.productNodes = products.sort(
        (p1: GraphMoleculeNode, p2: GraphMoleculeNode) => {
          return (
            graphReactionNode.reaction.products.indexOf(p1.molecule.id) -
            graphReactionNode.reaction.products.indexOf(p2.molecule.id)
          );
        },
      );
    }

    graphReactionNodes = sortReactions(graphReactionNodes);
    return graphReactionNodes;
  }

  // Copy edges saving with indexes converted to Numbers, and sort in place by target to group source nodes pointing
  // to the same target node, then go through groups detect the target is reaction and save group as substrates.
  const edgesCopyWithNumbers = graph.edges.map((e) => ({
    source: Number(e.source),
    target: Number(e.target),
  }));
  const edgesTargetSorted = edgesCopyWithNumbers.sort((e1, e2) => e1.target - e2.target);
  let tracedReactionNodeIdx = -1;
  let tracedReactionNodeReactants = [];
  for (const edge of edgesTargetSorted) {
    const nodeIdx = edge.target - 1;
    if (isMoleculeNode(graph.nodes[nodeIdx])) {
      if (tracedReactionNodeIdx >= 0) {
        // save substrates found for reaction node traced so far
        graph.nodes[tracedReactionNodeIdx].substrateNodes = tracedReactionNodeReactants;
        tracedReactionNodeIdx = -1;
      }
      continue;
    }

    if (nodeIdx === tracedReactionNodeIdx) {
      // continued same reaction node as edge target
      tracedReactionNodeReactants.push(graph.nodes[edge.source - 1]); // save the substrate then
    } else {
      if (tracedReactionNodeIdx >= 0) {
        // save substrates found for previously traced reaction node
        graph.nodes[tracedReactionNodeIdx].substrateNodes = tracedReactionNodeReactants;
      }
      tracedReactionNodeIdx = nodeIdx;
      tracedReactionNodeReactants = [graph.nodes[edge.source - 1]]; // init list for just found next reaction
    }
  }
  if (tracedReactionNodeIdx >= 0) {
    // save substrates found for last traced reaction node
    graph.nodes[tracedReactionNodeIdx].substrateNodes = tracedReactionNodeReactants;
  }

  // Same for products of the reactions which are determined by grouping by the same source node.
  const edgesSourceSorted = edgesCopyWithNumbers.sort((e1, e2) => e1.source - e2.source);
  tracedReactionNodeIdx = -1;
  tracedReactionNodeReactants = [];
  for (const edge of edgesSourceSorted) {
    const nodeIdx = edge.source - 1;
    if (isMoleculeNode(graph.nodes[nodeIdx])) {
      if (tracedReactionNodeIdx >= 0) {
        graph.nodes[tracedReactionNodeIdx].productNodes = tracedReactionNodeReactants;
        tracedReactionNodeIdx = -1;
      }
      continue;
    }

    if (nodeIdx === tracedReactionNodeIdx) {
      tracedReactionNodeReactants.push(graph.nodes[edge.target - 1]);
    } else {
      if (tracedReactionNodeIdx >= 0) {
        graph.nodes[tracedReactionNodeIdx].productNodes = tracedReactionNodeReactants;
      }
      tracedReactionNodeIdx = nodeIdx;
      tracedReactionNodeReactants = [graph.nodes[edge.target - 1]];
    }
  }
  if (tracedReactionNodeIdx >= 0) {
    graph.nodes[tracedReactionNodeIdx].productNodes = tracedReactionNodeReactants;
  }

  graphReactionNodes = graph.nodes.filter((node: GraphReactionNode) => isReactionNode(node));

  for (const graphReactionNode of graphReactionNodes) {
    graphReactionNode.graphId = graph.syntheticPath;
    graphReactionNode.substrateNodes = graphReactionNode.substrateNodes.sort(
      (s1: GraphMoleculeNode, s2: GraphMoleculeNode) => {
        return (
          graphReactionNode.reaction.substrates.indexOf(s1.molecule.id) -
          graphReactionNode.reaction.substrates.indexOf(s2.molecule.id)
        );
      },
    );
    graphReactionNode.productNodes = graphReactionNode.productNodes.sort(
      (p1: GraphMoleculeNode, p2: GraphMoleculeNode) => {
        return (
          graphReactionNode.reaction.products.indexOf(p1.molecule.id) -
          graphReactionNode.reaction.products.indexOf(p2.molecule.id)
        );
      },
    );
  }

  graphReactionNodes = sortReactions(graphReactionNodes);
  return graphReactionNodes;
}

/**
 * Export a given graph to SVG with use of the SVG renderer of sigma as set at the time of call -- any overloads
 * of nodes/edges rendering need to be configured else default ones will be used (simple circles joined with
 * thin lines).
 *
 * @param {{nodes: any[], edges: any[]}} graph Graph data.
 * @param sigmaSettings A copy of sigma settings with which the graph is going to be rendered at export.
 * @param {{size?: number, width?: number, height?: number, svgHeader?: boolean}} exportOptions Object instance with
 * export options. Set `size` field to use it for image width/height. Set separate `width` and/or `height` to use
 * the specified dimensions. By default 1000x1000 is used. Setting `svgHeader` option to `false` to get a clean SVG
 * tag without image file header which is useful for using a the content of svg tag in HTML. By default the function
 * returns an image with headers ready to use as a separate file.
 * @returns {string} An SVG image. The image is not a "safe html" in Angular sense hence it has to be sanitized
 * before use inside the DOM.
 *
 * NOTE: There is sigma.toSVG() native exporter. You can use it if you don't care customized sigma renderers.
 */
export function exportGraphAsSVG(
  graph: { nodes: any[]; edges: any[] },
  sigmaSettings?: any,
  exportOptions?: {
    size?: number;
    width?: number;
    height?: number;
    svgHeader?: boolean;
  },
) {
  const options = exportOptions || {};
  const w = options.size || options.width || 1000;
  const h = options.size || options.height || 1000;

  const settings = sigmaSettings || {};
  settings['enableHovering'] = false;

  let div = document.createElement('div');
  div.setAttribute('width', `${w}`);
  div.setAttribute('height', `${h}`);
  div.setAttribute(
    'style',
    'position:absolute; top: 0px; left:0px; width: ' + w + 'px; height: ' + h + 'px;',
  );

  const s = new sigma({ graph });

  s.addRenderer({
    id: 'main',
    type: 'svg',
    container: div,
    freeStyle: true,
  } as any);
  // FIXME: Use a better way to control labels overdraw (shall the limit be obtainable from the existing setup?)
  settings['chDrawAreaWidth'] = w;

  // sigmajs ignores some of our settings (such as chNodeTypeIcons) if not set this way (key by key)
  Object.keys(settings).forEach((key) => s.settings({ [key]: settings[key] }));

  // Resize and refresh to render the graph as initialized
  s.renderers['main'].resize(w, h);
  s.refresh();
  // grab class info and kill sigma as it already done what we needed
  const classPrefix = s.settings('classPrefix');
  // We will adjust viewBox with additional place for labels at the bottom if required.
  // Adding 2 * sideMargin effectively triple the margin at the bottom.
  const bottomLabelMargin = s.settings('drawLabels') ? 2 * s.settings('sideMargin') : 0;
  s.kill();

  // Retrieving svg
  const svg = div.querySelector('svg');
  svg.setAttribute('style', 'enable-background:new 0 0 10 10');
  svg.setAttribute('x', '0px');
  svg.setAttribute('y', '0px');
  svg.setAttribute('width', `100%`);
  svg.setAttribute('height', `100%`);
  svg.setAttribute('viewBox', `0 0 ${w} ${h + bottomLabelMargin}`);
  svg.setAttribute('fit', '');
  svg.setAttribute('focusable', 'false');
  svg.setAttribute('xml:space', 'preserve');
  svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

  // Dropping hovers
  const hoverGroup = svg.querySelector('[id="' + classPrefix + '-group-hovers"]');
  svg.removeChild(hoverGroup);

  const svgCode = svg.outerHTML; // Retrieving an SVG code
  div = null; // cleanup

  const includeSVGHeader = 'svgHeader' in options ? options.svgHeader : true;
  const svgHeader =
    '<?xml version="1.0" encoding="utf-8"?>\n' +
    '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n';

  return includeSVGHeader ? svgHeader + svgCode : svgCode;
}

/**
 * Render and optionally download the graph image as is to the one of supported image formats.
 *
 * @param sigmaGraph Initialized sigma graph to render and make a snapshot.
 * @param {"png" | "jpg" | "gif" | "tiff"} format File format.
 * @param {string} downloadFilename If provided the function starts file downloading with a specified name of file.
 * @returns {string} Data URL of image.
 */
export function getSigmaGraphImage(
  sigmaGraph,
  format: 'png' | 'jpg' | 'gif' | 'tiff' = 'png',
  downloadFilename?: string,
): string {
  const image = sigmaGraph.renderers[0].snapshot({ format });
  if (downloadFilename) {
    autodownload(image, downloadFilename);
  }
  return image;
}

/**
 * Render and optionally download SVG image of graph.
 *
 * @param nodes Nodes of graph ready to render (positioned, decoareted with data, etc.).
 * @param edges Edges of graph ready to render.
 * @param {{w: number, h: number}|'calc-from-nodes'} dimensions Dimensions of area where graph is about
 * to be rendered. If set to `calc-from-nodes` graph area is adjusted to the width and height of graph
 * calculated as differences of extremes of x (width) and y (height) coordinates of nodes. This allows
 * to get an image optimally filled with graph.
 * @param {object} sigmaGraphSettings Object with sigma graph settings other than default set
 * at sigma creation. Do not provide sigma.classes.configurable as sigma c-tor can't copy it.
 * @param {string} downloadFilename If provided the function starts file downloading with a specified name of file.
 * @returns {string} SVG image in a plain text (svg header followed by '<svg...></svg> tag).
 */
export function getSigmaGraphVectorImage(
  nodes: any[],
  edges: any[],
  dimensions: { w: number; h: number } | 'calc-from-nodes',
  sigmaGraphSettings?: object,
  downloadFilename?: string,
): string {
  let image;
  let width = 0;
  let height = 0;

  if (typeof dimensions === typeof {}) {
    const objDimensionOverload = dimensions as { w: number; h: number };
    width = 'w' in objDimensionOverload ? objDimensionOverload.w : width;
    height = 'h' in objDimensionOverload ? objDimensionOverload.h : height;
  } else if (dimensions === 'calc-from-nodes') {
    const graphExtremes = nodes.reduce(
      (extremes, node) => {
        const nodeCircle = 'chScale' in node ? node.size * node.chScale : node.size;
        if (extremes.maxNodeCircle < nodeCircle) {
          extremes.maxNodeCircle = nodeCircle;
        }

        if (extremes.minX > node.x) {
          extremes.minX = node.x;
        }
        if (extremes.maxX < node.x) {
          extremes.maxX = node.x;
        }
        if (extremes.minY > node.y) {
          extremes.minY = node.y;
        }
        if (extremes.maxY < node.y) {
          extremes.maxY = node.y;
        }

        return extremes;
      },
      {
        maxNodeCircle: 0,
        minX: 1e10,
        maxX: -1e10,
        minY: 1e10,
        maxY: -1e10,
      },
    );
    sigmaGraphSettings['rescaleIgnoreSize'] = true;
    sigmaGraphSettings['sideMargin'] = graphExtremes.maxNodeCircle;
    width = graphExtremes.maxX - graphExtremes.minX;
    height = graphExtremes.maxY - graphExtremes.minY;

    // Horizontal/vertical line or single node graphs can have a dimension equal or near 0.
    if (width === 0) {
      width = graphExtremes.maxNodeCircle * 2;
    }
    if (height === 0) {
      height = graphExtremes.maxNodeCircle * 2;
    }
    const ratioThreshold = 100.0; // test for horizontal/vertical graphs with only slightly misplaced nodes
    if (width / height > ratioThreshold) {
      height = graphExtremes.maxNodeCircle * 2;
    } else if (height / width > ratioThreshold) {
      width = graphExtremes.maxNodeCircle * 2;
    }
  } else {
    console.warn(
      `Unsupported dimensions overloading option '${dimensions}'.` + ' Default dimensions used.',
    );
  }

  image = exportGraphAsSVG({ nodes, edges }, sigmaGraphSettings, {
    width,
    height,
    svgHeader: true,
  });
  image = htmlEncodeNonLatinCharacters(image);
  if (downloadFilename) {
    autodownload(svgToImageDataURI(image), downloadFilename);
  }
  return image;
}

/**
 * This function is used to set default setting for Sigma Path
 * @param mode Mode of representing analysis path
 */
export function defaultSigmaSettingsForPath(mode: PathMode): { [key: string]: any } {
  const settings: { [key: string]: any } = {};

  // settings common for all modes
  settings.enableCamera = false;
  settings.enableHovering = false;
  settings.enableEdgeHovering = false;
  settings.doubleClickEnabled = false;
  settings.rightClickEnabled = false;
  settings.drawEdges = true;
  settings.drawLabels = true;
  settings.drawEdgeLabels = false;
  settings.hideEdgesOnMove = false;
  settings.mouseWheelEnabled = false;
  settings.animationsTime = 150;
  settings.dragTimeout = 10000;
  settings.autoRescale = ['edgeSize'];
  settings.autoResize = true;
  settings.rescaleIgnoreSize = false;
  settings.nodesPowRatio = 1;
  settings.edgesPowRatio = 1;
  settings.scalingMode = 'inside';
  settings.defaultLabelColor = '#000';
  settings.labelColor = 'default';
  settings.fontStyle = '#000';
  settings.chNodeTypeIcons = false;
  settings.moleculeBorderWidthRatio = 0.2; // what part of node radius meaningful borders takes

  switch (mode) {
    case PathMode.STRUCTURES: {
      const nodeSize: number = 150;
      const edgeSize: number = 3.5;
      settings.minNodeSize = nodeSize;
      settings.maxNodeSize = nodeSize;
      settings.minEdgeSize = edgeSize;
      settings.maxEdgeSize = edgeSize;
      settings.scale = Math.min(nodeSize / 30, 0.09);
      settings.sideMargin = 5;

      settings.mouseEnabled = false;
      settings.mouseInertiaDuration = 200;
      settings.mouseInertiaRatio = 3;
      break;
    }

    case PathMode.NORMAL: {
      const nodeSize = 10;
      const edgeSize = 3;
      settings.minNodeSize = nodeSize / 2;
      settings.maxNodeSize = nodeSize;
      settings.minEdgeSize = edgeSize / 2;
      settings.maxEdgeSize = edgeSize;
      settings.mouseInertiaDuration = 0;
      settings.mouseInertiaRatio = 0;
      settings.sideMargin = 60;
      settings.mouseEnabled = false;
      settings.scale = Math.min(nodeSize / 30, 0.09); // important for drag none scaling
      break;
    }

    default:
      console.error(`defaultSigmaSettingsForPath: unknown mode '${mode}.'`);
  }
  return settings;
}

export function layoutForPath(
  mode: PathMode,
  direction: PathDirectionType,
  nodes: any[],
  edges: any[],
) {
  const dagreConfig: { [key: string]: any } = {
    rankdir: direction,
    marginy: '10',
    marginx: '100',
    labeloffset: '45',
  };
  switch (mode) {
    case PathMode.NORMAL:
      dagreConfig.ranksep = '75';
      dagreConfig.nodesep = '60';
      break;
    case PathMode.STRUCTURES:
      dagreConfig.ranksep = '200';
      dagreConfig.nodesep = '300';
      break;
    case PathMode.EXPANDED:
      dagreConfig.ranksep = '225';
      dagreConfig.nodesep = '225';
      break;
    case PathMode.NAME:
      dagreConfig.ranksep = '150';
      dagreConfig.nodesep = '150';
      break;
    default:
      console.error(`layoutForPath: unknown mode '${mode}.'`);
  }

  const dagreGraph: any = new dagre.graphlib.Graph();
  dagreGraph.setGraph(dagreConfig);
  dagreGraph.setDefaultNodeLabel(() => ({}));
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id);
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  // Update coordinates of the original nodes assuming that dagre.layout does not change the order
  dagreGraph
    .nodes()
    .map((key) => dagreGraph.node(key))
    .forEach((node, i) => {
      if (
        mode === PathMode.EXPANDED &&
        nodes[i].type === GraphNodeType.EXPANDED_REACTION &&
        nodes[i].nodes &&
        nodes[i].nodes.length > 1
      ) {
        // Need to reposition reaction node for curved arrows
        nodes[i].x = node.x - dagreConfig.nodesep * 0.55;
      } else {
        nodes[i].x = node.x;
      }
      nodes[i].y = node.y;
    });
}

/**
 * Check if node is a reaction node
 *
 * @param node Node of a graph
 * @returns {boolean}
 */
export function isReactionNode(node) {
  return node.type === GraphNodeType.REACTION || node.type === GraphNodeType.EXPANDED_REACTION;
}

/**
 * Check if node is a molecule node
 *
 * @param node Node of a graph
 * @returns {boolean}
 */
export function isMoleculeNode(node) {
  return (
    node.type === GraphNodeType.MOLECULE ||
    node.type === GraphNodeType.STRUCTURE ||
    node.type === GraphNodeType.EXPANDED_IMAGE
  );
}

/**
 * Check if edge is a curved one
 *
 * @param edge edge of a graph
 * @returns {boolean}
 */
export function isCurvedEdge(edge) {
  return edge.type === GraphEdgeType.CURVED_DOWN_LINE || edge.type === GraphEdgeType.CURVED_UP_LINE;
}

/**
 * Update reaction node data with target and substrates
 *
 * @param graph graphResource data
 * @returns array of reaction nodes
 */
export function getReactionInfo(graph: any) {
  // Values will be updated directly to object
  const graphReactionNodes = graph.nodes.filter((node) => node.type === GraphNodeType.REACTION);
  for (const graphReactionNode of graphReactionNodes) {
    graphReactionNode['nodes'] = [];
    graphReactionNode['targets'] = [];
    for (const substrate of graphReactionNode.reaction.substrates) {
      const substrateNode: GraphMoleculeNode = graph.nodes.find(
        (node) =>
          node.type !== GraphNodeType.REACTION && node.molecule && node.molecule.id === substrate,
      );
      graphReactionNode.nodes.push(substrateNode);
    }
    for (const target of graphReactionNode.reaction.products) {
      const targetNode: GraphMoleculeNode = graph.nodes.find(
        (node) =>
          node.type !== GraphNodeType.REACTION && node.molecule && node.molecule.id === target,
      );
      graphReactionNode.targets.push(targetNode);
    }
  }
  return graphReactionNodes;
}

// Sigma JS override functions
// If there is any update on Sigma JS. These functions need to be checked and updated

sigma.utils.isPointOnSegment = function (
  x: number,
  y: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  epsilon: number,
) {
  // http://stackoverflow.com/a/328122
  const crossProduct = Math.abs((y - y1) * (x2 - x1) - (x - x1) * (y2 - y1));
  const distance = sigma.utils.getDistance(x1, y1, x2, y2);
  const nCrossProduct = crossProduct / distance; // normalized cross product
  if (y1 === y2) {
    const epsilonYMin = y1 - epsilon;
    const epsilonYMax = y1 + epsilon;
    return nCrossProduct < epsilon && y < epsilonYMax && y > epsilonYMin;
  } else {
    return (
      nCrossProduct < epsilon &&
      Math.min(x1, x2) <= x &&
      x <= Math.max(x1, x2) &&
      Math.min(y1, y2) <= y &&
      y <= Math.max(y1, y2)
    );
  }
};

sigma.misc.bindEvents = function (prefix: any) {
  let mX, mY;
  const self = this;

  function getNodes(e: any) {
    if (e) {
      mX = 'x' in e.data ? e.data.x : mX;
      mY = 'y' in e.data ? e.data.y : mY;
    }
    let i, j, l, n, x, y, s, inserted;
    const selected = [],
      modifiedX = mX + self.width / 2,
      modifiedY = mY + self.height / 2,
      point = self.camera.cameraPosition(mX, mY),
      nodes = self.camera.quadtree.point(point.x, point.y);

    const mouseX = e.data.x + self.width / 2;
    const mouseY = e.data.y + self.height / 2;
    if (nodes.length) {
      for (i = 0, l = nodes.length; i < l; i++) {
        n = nodes[i];
        x = n[prefix + 'x'];
        y = n[prefix + 'y'];
        s = n[prefix + 'size'];

        let sizeModified = s;
        if (n.type === GraphNodeType.EXPANDED_IMAGE) {
          // By default node shape will be a circle with radius of size mentioned
          // Default sigma function is for circle shape
          // Expanded image is a rectangle with Height and width 1.75 * size  (check expandedNodesImage function)
          // So we have to adjust node size for trigger node hover correctly
          sizeModified = 0.75 * s;
        }

        if (
          !n.hidden &&
          modifiedX > x - s &&
          modifiedX < x + sizeModified &&
          modifiedY > y - s &&
          modifiedY < y + s &&
          Math.sqrt(Math.pow(modifiedX - x, 2) + Math.pow(modifiedY - y, 2)) < s &&
          !n.isDiversityLibrary
        ) {
          // Insert the node:
          inserted = false;
          if (n.isDiversify) {
            return;
          }

          for (j = 0; j < selected.length; j++) {
            if (n.size > selected[j].size) {
              selected.splice(j, 0, n);
              inserted = true;
              break;
            }
          }
          if (!inserted) {
            selected.push(n);
          }
        }
        if (n.needsProtection && !n.isDiversityLibrary) {
          const nodeX = n[prefix + 'x'];
          const nodeY = n[prefix + 'y'];
          const size = n[prefix + 'size'];
          let protectX1 = nodeX + 0.5 * size;
          let protectX2 = nodeX + 0.7 * size;
          let protectY1 = nodeY - 0.11 * size;
          let protectY2 = nodeY + 0.1 * size;
          if (calculateMoleculePopularity(n)) {
            const diffX = protectX2 - protectX1;
            const diffY = protectY2 - protectY1;
            protectX1 = protectX1 + 0.5 * diffX;
            protectX2 = protectX2 + 0.5 * diffX;

            protectY1 = protectY1 + diffY;
            protectY2 = protectY2 + diffY;
          }
          if (
            mouseX > protectX1 &&
            mouseX < protectX2 &&
            mouseY > protectY1 &&
            mouseY < protectY2
          ) {
            n.isProtection = true;
            selected.push(n);
          } else {
            n.isProtection = false;
          }
        }
        if (n.isDiversify) {
          const nodeX = n[prefix + 'x'];
          const nodeY = n[prefix + 'y'];
          const size = n[prefix + 'size'];
          let checkX1 = nodeX - size;
          let checkX2 = nodeX - 1.15 * size;
          let checkY1 = nodeY - 0.75 * size;
          let checkY2 = nodeY - 0.65 * size;

          if (mouseX < checkX1 && mouseX > checkX2 && mouseY > checkY1 && mouseY < checkY2) {
            selected.push(n);
          }
        }
      }
    }
    return selected;
  }

  function getLabelNodes(e: any) {
    if (e) {
      mX = 'x' in e.data ? e.data.x : mX;
      mY = 'y' in e.data ? e.data.y : mY;
    }
    let i;

    const nodes = [];
    const selected = [];

    const nodesOnScreen = self.camera.quadtree.area(
      self.camera.getRectangle(self.width, self.height),
    );

    for (i = 0; i < nodesOnScreen.length; i++) {
      if (nodesOnScreen[i].type === GraphNodeType.EXPANDED_REACTION) {
        nodes.push(nodesOnScreen[i]);
      }
    }
    const mouseX = e.data.x + self.width / 2;
    const mouseY = e.data.y + self.height / 2;
    nodes.forEach((node) => {
      if (node.isDiversify) {
        return;
      }
      const nodeX = node[prefix + 'x'];
      const nodeY = node[prefix + 'y'];
      const size = node[prefix + 'size'];

      let newNodeX1 = nodeX - 10 * size;
      let newNodeX2 = nodeX + 8 * size;

      let RefNodeX1 = nodeX - 11 * size;
      let RefNodeX2 = nodeX + 7 * size;
      const RefNodeY1 = nodeY + 1 * size;
      const RefNodeY2 = nodeY + 3 * size;
      let bookNodeX1 = nodeX - 4 * size;
      let bookNodeX2 = nodeX - 1.5 * size;
      let bookNodeY1 = nodeY + 2 * size;
      let bookNodeY2 = nodeY + 3.5 * size;
      const typicalLines = Math.ceil(
        node.typicalConditionLabelWidth / node.typicalConditionLongLabelWidth,
      )
        ? Math.ceil(node.typicalConditionLabelWidth / node.typicalConditionLongLabelWidth)
        : 1;
      const labelLines = Math.ceil(node.labelWidth / node.longLabelWidth)
        ? Math.ceil(node.labelWidth / node.longLabelWidth)
        : 1;
      const newNodeY2 =
        nodeY -
        (Math.min(
          Math.min(6, Math.max(1, typicalLines)) + Math.min(6, Math.max(1, labelLines)),
          8,
        ) +
          3.5) *
          size;
      const newTypicalY = nodeY - typicalLines * 1.5 * size;
      if (node.nodes && node.nodes.length > 1) {
        newNodeX1 = nodeX;
        newNodeX2 = nodeX + 16 * size;
        bookNodeX1 = nodeX - size;
        bookNodeX2 = nodeX + 4 * size;
        RefNodeX1 = nodeX + size;
        RefNodeX2 = RefNodeX1 + node.publishedReferencesLabelWidth;
      }

      if (mouseX > newNodeX1 && mouseX < newNodeX2 && mouseY > newNodeY2 && mouseY < nodeY) {
        node.showToolTip = mouseY > newTypicalY && mouseY < nodeY;
        selected.push(node);
        return;
      }
      if (
        node.publishedReferencesLabel !== undefined &&
        node.publishedReferencesLabel[0] !== '' &&
        node.colorByBase === COLOR_REACTION_PUBLISHED
      ) {
        bookNodeY1 = nodeY + 4.2 * size;
        bookNodeY2 = nodeY + 7 * size;
        node.isReferenceClick = false;
        if (
          mouseX > RefNodeX1 &&
          mouseX < RefNodeX2 &&
          mouseY > RefNodeY1 &&
          mouseY < RefNodeY2 &&
          (node.reaction.reference_doi.length > 0 ||
            node.patents.length > 0 ||
            isValidUrl(node.reaction.reference))
        ) {
          node.isReferenceClick = true;
          selected.push(node);
        }
      }
      node.isSimilarReaction = false;
      if (
        mouseX > bookNodeX1 &&
        mouseX < bookNodeX2 &&
        mouseY > bookNodeY1 &&
        mouseY < bookNodeY2 &&
        (node.reaction.similarity_score != null ||
          node.reaction.similarity_score === 0 ||
          node.colorByBase === COLOR_REACTION_PUBLISHED)
      ) {
        node.isSimilarReaction = true;
        selected.push(node);
      }
    });
    return selected;
  }

  function getEdges(e: any) {
    if (!self.settings('enableEdgeHovering')) {
      // No event if the setting is off:
      return [];
    }

    const isCanvas = sigma.renderers.canvas && self instanceof sigma.renderers.canvas;

    if (!isCanvas) {
      // A quick hardcoded rule to prevent people from using this feature
      // with the WebGL renderer (which is not good enough at the moment):
      throw new Error('The edge events feature is not compatible with the WebGL renderer');
    }

    if (e) {
      mX = 'x' in e.data ? e.data.x : mX;
      mY = 'y' in e.data ? e.data.y : mY;
    }

    let i,
      j,
      l,
      a,
      edge,
      s,
      source,
      target,
      cp,
      inserted,
      edges = [];

    const maxEpsilon = self.settings('edgeHoverPrecision'),
      nodeIndex = {},
      selected = [],
      modifiedX = mX + self.width / 2,
      modifiedY = mY + self.height / 2,
      point = self.camera.cameraPosition(mX, mY);

    if (isCanvas) {
      const nodesOnScreen = self.camera.quadtree.area(
        self.camera.getRectangle(self.width, self.height),
      );
      for (a = nodesOnScreen, i = 0, l = a.length; i < l; i++) {
        nodeIndex[a[i].id] = a[i];
      }
    }

    if (self.camera.edgequadtree !== undefined) {
      edges = self.camera.edgequadtree.point(point.x, point.y);
    }

    function insertEdge() {
      inserted = false;

      for (j = 0; j < selected.length; j++) {
        if (edge.size > selected[j].size) {
          selected.splice(j, 0, edge);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        selected.push(edge);
      }
    }

    if (edges.length) {
      for (i = 0, l = edges.length; i < l; i++) {
        edge = edges[i];
        source = self.graph.nodes(edge.source);
        target = self.graph.nodes(edge.target);
        // (HACK) we can't get edge[prefix + 'size'] on WebGL renderer:
        s = edge[prefix + 'size'] || edge['read_' + prefix + 'size'];

        // First, let's identify which edges are drawn. To do this, we keep
        // every edges that have at least one extremity displayed according to
        // the quadtree and the "hidden" attribute. We also do not keep hidden
        // edges.
        // Then, let's check if the mouse is on the edge (we suppose that it
        // is a line segment).

        let sourceModifiedSize = source[prefix + 'size'];
        if (source.type === GraphNodeType.EXPANDED_IMAGE) {
          // By default node shape will be a circle with radius of node size mentioned
          // Default sigma function is for circle shape
          // Expanded image is a rectangle with height and width 1.75 * size  (check expandedNodesImage function)
          // So we have adjust node size for trigger node hover correctly
          // That adjusted size need to reflect in edge hover also

          sourceModifiedSize = source[prefix + 'size'] * 0.75;
        }

        if (
          !edge.hidden &&
          !source.hidden &&
          !target.hidden &&
          (!isCanvas || nodeIndex[edge.source] || nodeIndex[edge.target]) &&
          sigma.utils.getDistance(
            source[prefix + 'x'],
            source[prefix + 'y'],
            modifiedX,
            modifiedY,
          ) > sourceModifiedSize &&
          sigma.utils.getDistance(
            target[prefix + 'x'],
            target[prefix + 'y'],
            modifiedX,
            modifiedY,
          ) > target[prefix + 'size']
        ) {
          if (edge.type === 'curve' || edge.type === 'curvedArrow') {
            if (source.id === target.id) {
              cp = sigma.utils.getSelfLoopControlPoints(
                source[prefix + 'x'],
                source[prefix + 'y'],
                sourceModifiedSize,
              );
              if (
                sigma.utils.isPointOnBezierCurve(
                  modifiedX,
                  modifiedY,
                  source[prefix + 'x'],
                  source[prefix + 'y'],
                  target[prefix + 'x'],
                  target[prefix + 'y'],
                  cp.x1,
                  cp.y1,
                  cp.x2,
                  cp.y2,
                  Math.max(s, maxEpsilon),
                )
              ) {
                insertEdge();
              }
            } else {
              cp = sigma.utils.getQuadraticControlPoint(
                source[prefix + 'x'],
                source[prefix + 'y'],
                target[prefix + 'x'],
                target[prefix + 'y'],
              );
              if (
                sigma.utils.isPointOnQuadraticCurve(
                  modifiedX,
                  modifiedY,
                  source[prefix + 'x'],
                  source[prefix + 'y'],
                  target[prefix + 'x'],
                  target[prefix + 'y'],
                  cp.x,
                  cp.y,
                  Math.max(s, maxEpsilon),
                )
              ) {
                insertEdge();
              }
            }
          } else if (
            sigma.utils.isPointOnSegment(
              modifiedX,
              modifiedY,
              source[prefix + 'x'],
              source[prefix + 'y'],
              target[prefix + 'x'],
              target[prefix + 'y'],
              Math.max(s, maxEpsilon),
            )
          ) {
            insertEdge();
          }
        }
      }
    }
    return selected;
  }

  function bindCaptor(captor: any) {
    let nodes,
      edges,
      labels,
      overNodes = {},
      overEdges = {},
      overLabels = {};

    function onClick(e: any) {
      if (!self.settings('eventsEnabled')) {
        return;
      }

      self.dispatchEvent('click', e.data);

      nodes = getNodes(e);
      edges = getEdges(e);
      labels = getLabelNodes(e);

      if (labels.length) {
        self.dispatchEvent('clickNode', {
          node: labels[0],
          captor: e.data,
        });
        self.dispatchEvent('clickNodes', {
          node: labels,
          captor: e.data,
        });
      } else if (nodes?.length) {
        self.dispatchEvent('clickNode', {
          node: nodes[0],
          captor: e.data,
        });
        self.dispatchEvent('clickNodes', {
          node: nodes,
          captor: e.data,
        });
      } else if (edges.length) {
        self.dispatchEvent('clickEdge', {
          edge: edges[0],
          captor: e.data,
        });
        self.dispatchEvent('clickEdges', {
          edge: edges,
          captor: e.data,
        });
      } else {
        self.dispatchEvent('clickStage', { captor: e.data });
      }
    }

    function onDoubleClick(e: any) {
      if (!self.settings('eventsEnabled')) {
        return;
      }

      self.dispatchEvent('doubleClick', e.data);

      nodes = getNodes(e);
      edges = getEdges(e);

      if (nodes.length) {
        self.dispatchEvent('doubleClickNode', {
          node: nodes[0],
          captor: e.data,
        });
        self.dispatchEvent('doubleClickNodes', {
          node: nodes,
          captor: e.data,
        });
      } else if (edges.length) {
        self.dispatchEvent('doubleClickEdge', {
          edge: edges[0],
          captor: e.data,
        });
        self.dispatchEvent('doubleClickEdges', {
          edge: edges,
          captor: e.data,
        });
      } else {
        self.dispatchEvent('doubleClickStage', { captor: e.data });
      }
    }

    function onRightClick(e: any) {
      if (!self.settings('eventsEnabled')) {
        return;
      }

      self.dispatchEvent('rightClick', e.data);

      nodes = getNodes(e);
      edges = getEdges(e);

      if (nodes.length) {
        self.dispatchEvent('rightClickNode', {
          node: nodes[0],
          captor: e.data,
        });
        self.dispatchEvent('rightClickNodes', {
          node: nodes,
          captor: e.data,
        });
      } else if (edges.length) {
        self.dispatchEvent('rightClickEdge', {
          edge: edges[0],
          captor: e.data,
        });
        self.dispatchEvent('rightClickEdges', {
          edge: edges,
          captor: e.data,
        });
      } else {
        self.dispatchEvent('rightClickStage', { captor: e.data });
      }
    }

    function onOut(e: any) {
      if (!self.settings('eventsEnabled')) {
        return;
      }

      let k, i, l, le, j;
      const outNodes = [],
        outEdges = [];

      for (k in overNodes) {
        if (overNodes[k]) {
          outNodes.push(overNodes[k]);
        }
      }
      overLabels = {};
      overNodes = {};
      // Dispatch both single and multi events:
      for (i = 0, l = outNodes.length; i < l; i++) {
        self.dispatchEvent('outNode', {
          node: outNodes[i],
          captor: e.data,
        });
      }
      if (outNodes.length) {
        self.dispatchEvent('outNodes', {
          nodes: outNodes,
          captor: e.data,
        });
      }

      for (j in overEdges) {
        if (overEdges[j]) {
          outEdges.push(overEdges[j]);
        }
      }

      overEdges = {};
      // Dispatch both single and multi events:
      for (i = 0, le = outEdges.length; i < le; i++) {
        self.dispatchEvent('outEdge', {
          edge: outEdges[i],
          captor: e.data,
        });
      }
      if (outEdges.length) {
        self.dispatchEvent('outEdges', {
          edges: outEdges,
          captor: e.data,
        });
      }
    }

    function onMove(e: any) {
      if (!self.settings('eventsEnabled')) {
        return;
      }

      nodes = getNodes(e);
      edges = getEdges(e);
      labels = getLabelNodes(e);

      let i,
        k,
        node,
        edge,
        label,
        le = edges.length,
        l = nodes?.length;
      const newOutNodes = [],
        newOverNodes = [],
        currentOverNodes = {},
        newOutEdges = [],
        newOverEdges = [],
        currentOverEdges = {},
        newOutLabel = [],
        newOverLabel = [],
        currentOverLabel = {};

      // Check newly overred nodes:
      for (i = 0; i < l; i++) {
        node = nodes[i];
        currentOverNodes[node.id] = node;
        if (!overNodes[node.id]) {
          newOverNodes.push(node);
          overNodes[node.id] = node;
        }
      }
      // Check newly overred nodes:

      for (i = 0; i < labels.length; i++) {
        label = labels[i];
        currentOverLabel[label.id] = label;

        if (!overLabels[label.id]) {
          newOverLabel.push(label);
          overLabels[label.id] = label;
        }
      }

      // Check no more overred nodes:
      for (k in overNodes) {
        if (!currentOverNodes[k]) {
          newOutNodes.push(overNodes[k]);
          delete overNodes[k];
        }
      }

      for (k in overLabels) {
        if (!currentOverLabel[k]) {
          newOutLabel.push(overLabels[k]);
          delete overLabels[k];
        }
      }

      // Dispatch both single and multi events:
      for (i = 0, l = newOverNodes.length; i < l; i++) {
        self.dispatchEvent('overNode', {
          node: newOverNodes[i],
          captor: e.data,
        });
      }
      for (i = 0, l = newOutNodes.length; i < l; i++) {
        self.dispatchEvent('outNode', {
          node: newOutNodes[i],
          captor: e.data,
        });
      }
      if (newOverNodes.length) {
        self.dispatchEvent('overNodes', {
          nodes: newOverNodes,
          captor: e.data,
        });
      }
      if (newOutNodes.length) {
        self.dispatchEvent('outNodes', {
          nodes: newOutNodes,
          captor: e.data,
        });
      }

      // Dispatch both single and multi events:
      for (i = 0, l = newOverLabel.length; i < l; i++) {
        self.dispatchEvent('overNode', {
          node: newOverLabel[i],
          captor: e.data,
        });
      }
      for (i = 0, l = newOutLabel.length; i < l; i++) {
        self.dispatchEvent('outNode', {
          node: newOutLabel[i],
          captor: e.data,
        });
      }
      if (newOverLabel.length) {
        self.dispatchEvent('overNodes', {
          nodes: newOverLabel,
          captor: e.data,
        });
      }
      if (newOutLabel.length) {
        self.dispatchEvent('outNodes', {
          nodes: newOutLabel,
          captor: e.data,
        });
      }

      // Check newly overred edges:
      for (i = 0; i < le; i++) {
        edge = edges[i];
        currentOverEdges[edge.id] = edge;
        if (!overEdges[edge.id]) {
          newOverEdges.push(edge);
          overEdges[edge.id] = edge;
        }
      }

      // Check no more overred edges:
      for (k in overEdges) {
        if (!currentOverEdges[k]) {
          newOutEdges.push(overEdges[k]);
          delete overEdges[k];
        }
      }

      // Dispatch both single and multi events:
      for (i = 0, le = newOverEdges.length; i < le; i++) {
        self.dispatchEvent('overEdge', {
          edge: newOverEdges[i],
          captor: e.data,
        });
      }
      for (i = 0, le = newOutEdges.length; i < le; i++) {
        self.dispatchEvent('outEdge', {
          edge: newOutEdges[i],
          captor: e.data,
        });
      }
      if (newOverEdges.length) {
        self.dispatchEvent('overEdges', {
          edges: newOverEdges,
          captor: e.data,
        });
      }
      if (newOutEdges.length) {
        self.dispatchEvent('outEdges', {
          edges: newOutEdges,
          captor: e.data,
        });
      }
    }

    // Bind events:
    captor.bind('click', onClick);
    captor.bind('mousedown', onMove);
    captor.bind('mouseup', onMove);
    captor.bind('mousemove', onMove);
    captor.bind('mouseout', onOut);
    captor.bind('doubleclick', onDoubleClick);
    captor.bind('rightclick', onRightClick);
    self.bind('render', onMove);
  }

  for (let i = 0, l = this.captors.length; i < l; i++) {
    bindCaptor(this.captors[i]);
  }
};
