import { ParametersStashService } from './../../shared/services/parameters-stash/parameters-stash.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorsHandlerService, ParsedConnectionError } from '../../shared/services/errors-handler';
import { AppConstantsService } from 'src/app/shared/services';

/* tslint:disable-next-line:variable-name */
export const backendEntryPoint_PasswordReset =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/password-reset/';

@Component({
  selector: 'ch-password-reset',
  styleUrls: ['./password-reset.component.scss'],
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  public minimalEmailRegEx = '^.+@.+\\...+';
  public nextUrl: string;
  public email: string = '';
  public emailFieldErrors: string[] = [];
  public pendingApiCall: boolean = false;
  public apiCallError: string = null;
  public success: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  public isChinaRegion: boolean = APP_CONFIG.CHINA_REGION_INSTANCE;
  public logoText: string = this.appConstantsService.synthiaEnterpriseLogoText;

  private passwordResetSubscription: Subscription;
  private unsubscribeFromAll: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private errorsHandler: ErrorsHandlerService,
    private stashService: ParametersStashService,
    private appConstantsService: AppConstantsService,
  ) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.route.params
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        map((params) => params['next']),
      )
      .subscribe((next) => {
        this.nextUrl = next ? next : '';
      });
  }

  public ngOnDestroy() {
    this.unsubscribePasswordReset();
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  public openNextUrl() {
    this.router.navigateByUrl(this.nextUrl);
  }

  public resetPassword() {
    this.emailFieldErrors = [];
    this.apiCallError = null;
    this.pendingApiCall = true;
    this.success = false;
    this.unsubscribePasswordReset();

    this.passwordResetSubscription = this.http
      .post(backendEntryPoint_PasswordReset, JSON.stringify({ email: this.email }), {
        observe: 'response',
      })
      .subscribe(
        (res: HttpResponse<any>) => this.resetPasswordCallSuccess(res),
        (error: any) => this.resetPasswordCallError(error),
      );
  }

  private unsubscribePasswordReset() {
    if (this.passwordResetSubscription && !this.passwordResetSubscription.closed) {
      this.passwordResetSubscription.unsubscribe();
    }
  }

  private resetPasswordCallSuccess(res: HttpResponse<any>) {
    // assuming res.status == 204 (no content)
    this.apiCallError = null;
    this.pendingApiCall = false;
    this.success = true;
  }

  private resetPasswordCallError(error: any) {
    this.pendingApiCall = false;
    this.success = false;

    const parsedError = new ParsedConnectionError(error);

    if (parsedError.isRecognized()) {
      if (!this.handleLocalError(parsedError)) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      }
    } else {
      this.apiCallError = 'Unable to request password reset. ' + error;
    }
  }

  private handleLocalError(parsedError: ParsedConnectionError): boolean {
    switch (parsedError.status) {
      case 400:
        // Backend errors with code 400:
        // - for fields: {"email" : ["Enter a valid email address.", "This field is required."]}
        // - other: {"non_field_errors":[?]}
        for (const error of parsedError.bodyJson.errors) {
          this.emailFieldErrors.push(error.message);
          return true;
        }
        break;
      default:
        if (!parsedError.isGlobal()) {
          const errText = parsedError.response.statusText || '';
          this.apiCallError = `Unable to request password reset. ${errText} (code: ${parsedError.status})`;
          return true;
        }
        break;
    }
    return false;
  }
}
