import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const BOUNCE_ANIMATION = trigger('bounceAnimation', [
  state(
    'inactive',
    style({
      transform: 'scale(1)',
    }),
  ),
  state('active', style({})),
  transition('* => active', [
    animate(
      '0.6s ease-in-out',
      keyframes([
        style({ transform: 'scale(1)', offset: 0.0 }),
        style({ transform: 'scale(1.05)', offset: 0.1 }),
        style({ transform: 'scale(1)', offset: 0.3 }),
        style({ transform: 'scale(1)', offset: 1.0 }),
      ]),
    ),
  ]),
]);
