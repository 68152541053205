import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InfoDialogComponent } from '../components/info-dialog';

/* tslint:disable:max-classes-per-file */
export class InfoDialogContent {
  constructor(
    public type: 'info' | 'warning' | 'error' = 'info',
    public title: string = 'Info',
    public message: string = null,
    public details: string = null,
    public dimensions: { width: string; height: string } = null,
    public closeButtonText: string = 'Close',
    public acceptButtonText: string = 'Ok',
    public showAcceptButton: boolean = false,
    public reportErrorButton: boolean = false,
    public synthiaPromoButtton: boolean = false,
    public synthiaLiteEnabled: boolean = false,
    public showCloseButton: boolean = true,
    public showUpgradeOptionButton: boolean = false,
  ) {}
}
/* tslint:disable:max-classes-per-file */

@Injectable({
  providedIn: 'root',
})
export class InfoDialogService {
  private dialogRef: MatDialogRef<InfoDialogComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public info(content: InfoDialogContent) {
    this.config.maxHeight = 800;
    this.config.maxWidth = 1000;
    this.config.minHeight = 100;
    this.config.minWidth = 300;
    if (content.dimensions) {
      this.config.height = content.dimensions.height;
      this.config.width = content.dimensions.width;
    }

    this.dialogRef = this.dialog.open(InfoDialogComponent, this.config);
    this.dialogRef.componentInstance.title = content.title;
    this.dialogRef.componentInstance.message = content.message;
    this.dialogRef.componentInstance.closeButtonText = content.closeButtonText
      ? content.closeButtonText
      : 'Close';
    this.dialogRef.componentInstance.reportErrorButton = content.reportErrorButton;
    this.dialogRef.componentInstance.synthiaPromoButtton = content.synthiaPromoButtton;
    this.dialogRef.componentInstance.showUpgradeOptionButton = content.showUpgradeOptionButton;
    this.dialogRef.componentInstance.synthiaLiteEnabled = content.synthiaLiteEnabled;
    this.dialogRef.componentInstance.showAcceptButton = content.showAcceptButton;
    this.dialogRef.componentInstance.acceptButtonText = content.acceptButtonText;
    this.dialogRef.componentInstance.showCloseButton = content.showCloseButton;

    return this.dialogRef.afterClosed();
  }
}
