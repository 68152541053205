import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ch-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
})
export class RenameDialogComponent implements OnInit {
  public oldValue: string = '';
  public newValue: string = '';
  public title: string;

  constructor(public dialogRef: MatDialogRef<RenameDialogComponent>) {}

  public ngOnInit() {
    this.newValue = this.oldValue;
  }

  public isNoName() {
    return this.newValue.trim() === '';
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {
    if (!this.isNoName() && this.oldValue !== this.newValue) {
      this.dialogRef.close(this.newValue.trim());
    } else {
      this.closeDialog();
    }
  }
}
