<div class="ch-pagination">
  <div class="ch-pagination-nav new-page-ui">
    <button
      mat-button
      [buttonOutline]="'default-grey'"
      *ngIf="hasPreviousPage()"
      class="ch-pagination-navigation-btn"
      (click)="onPreviousPage()"
    >
      <mat-icon class="nav-icon">keyboard_arrow_left</mat-icon>
    </button>
    <button
      mat-button
      [buttonOutline]="'default-grey'"
      class="page-button"
      *ngIf="shouldShowFirstPageNumber()"
      [class.ch-bg-accent-strong]="isActive(1)"
      (click)="onPageSelected(1)"
    >
      {{ 1 }}
    </button>
    <button
      mat-button
      [buttonOutline]="'default-grey'"
      class="page-button"
      *ngIf="shouldShowLeftNavDots()"
      (click)="onPageSelected(currentPage - 10)"
    >
      ...
    </button>

    <ng-container *ngFor="let page of pagesDisplayed">
      <button
        mat-button
        [buttonOutline]="'default-grey'"
        *ngIf="page !== 0"
        class="page-button"
        (click)="onPageSelected(page)"
        [class.ch-bg-accent-strong]="isActive(page)"
      >
        {{ page }}
      </button>
    </ng-container>

    <button
      mat-button
      [buttonOutline]="'default-grey'"
      class="page-button"
      *ngIf="shouldShowRightNavDots()"
      (click)="onPageSelected(currentPage + 10)"
    >
      ...
    </button>
    <button
      mat-button
      [buttonOutline]="'default-grey'"
      class="page-button"
      *ngIf="shouldShowLastPageNumber()"
      [class.ch-bg-accent-strong]="isActive(pages.length)"
      (click)="onPageSelected(pages.length)"
    >
      {{ pages.length }}
    </button>
    <button
      mat-button
      [buttonOutline]="'default-grey'"
      *ngIf="hasNextPage()"
      class="ch-pagination-navigation-btn btn-right"
      (click)="onNextPage()"
    >
      <mat-icon class="nav-icon">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
