import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const RedirectRouteGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const appRoutes = ['home', 'information', 'tech-support', 'terms', 'sign-out'];
  if (appRoutes.includes(state.url?.split('/')[1])) {
    return true;
  } else {
    router.navigate(['/home' + state.url]);
    return false;
  }
};
