import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { AppConstantsService, CopyToClipboardService, MoleculeData } from 'src/app/shared/services';
import { getTooltip, getMoleculeCardTitle } from 'src/app/shared/components';
import { ViewType } from 'src/app/shared/services/molecule-sets';

@Component({
  selector: 'ch-list-molecule-card',
  templateUrl: './list-molecule-card.component.html',
  styleUrls: ['./list-molecule-card.component.scss'],
})
export class ListMoleculeCardComponent implements OnDestroy, OnChanges {
  @Output() public deleteMoleculeEmitter: EventEmitter<MoleculeData> = new EventEmitter(null);
  @Output() public expandMoleculeViewEmitter: EventEmitter<any> = new EventEmitter(null);
  @Output() public editMoleculeEmitter: EventEmitter<MoleculeData> = new EventEmitter();
  @Input() public molecule: MoleculeData;
  @Input() public isCustomList: boolean;
  @Input() public isMoleculeExpanded: boolean = true;
  @Input() public parentClass: string = 'grid-list';
  @Input() public viewType: ViewType;
  @Input() public isTargetViewLibrary: boolean = false;
  @ViewChild('expansionPanel', { read: ElementRef })
  public expansionPanel: ElementRef;
  public smilesCopied: boolean = false;
  public imageHeight = 214;
  public imageWidth = 200;
  public deleteMoleculeTooltip: string;
  public getTooltip = getTooltip;
  public editMoleculeTooltip: string = this.appConstantsService.editMoleculeTooltip;
  public unsubscriberSubject: Subject<void> = new Subject();
  public formulaValueToUse: string;
  public inputFormatToUse: string;

  constructor(
    public appConstantsService: AppConstantsService,
    public copyToClipboardService: CopyToClipboardService,
  ) {
    this.deleteMoleculeTooltip = this.appConstantsService.deleteMoleculeTooltip;
  }
  public ngOnDestroy(): void {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('molecule')) {
      if (this.viewType === ViewType.SmartsSets) {
        this.formulaValueToUse = this.molecule.moleculeSmarts;
        this.inputFormatToUse = 'smarts';
      } else if (this.viewType === ViewType.MoleculeSets) {
        if (this.molecule.moleculeMolFile) {
          this.formulaValueToUse = this.molecule.moleculeMolFile;
          this.inputFormatToUse = 'mol';
        } else {
          this.formulaValueToUse = this.molecule.moleculeSmiles;
          this.inputFormatToUse = 'smiles';
        }
      }
    }
  }

  public getMoleculeName() {
    return getMoleculeCardTitle(this.molecule);
  }

  public getNameTooltip() {
    const title = getMoleculeCardTitle(this.molecule);
    return getTooltip(title, this.appConstantsService.moleculeNameWrapLength);
  }

  public getExpandTooltip() {
    return this.isMoleculeExpanded
      ? this.appConstantsService.collapseCardTooltip
      : this.appConstantsService.expandCardTooltip;
  }

  public getCopyTooltip() {
    return this.molecule.moleculeSmarts
      ? this.appConstantsService.copySmartsTooltip
      : this.appConstantsService.copyTooltip;
  }

  public onSmilesClick(event: Event) {
    event.stopPropagation();
    const selection: Selection = document.getSelection();
    if (selection && selection.toString() !== '') {
      event.stopPropagation();
    } else {
      this.copySmiles();
    }
  }

  public copySmiles() {
    const value = this.molecule.moleculeSmarts
      ? this.molecule.moleculeSmarts
      : this.molecule.moleculeSmiles;
    this.copyToClipboardService
      .copyContent(value)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((success) => {
        this.smilesCopied = true;
        setTimeout(() => {
          this.smilesCopied = false;
        }, 3000);
      });
  }

  public deleteMolecule(event: Event) {
    event.stopPropagation();
    if (this.molecule) {
      this.deleteMoleculeEmitter.emit(this.molecule);
    }
  }

  public expandCard(event: Event) {
    event.stopPropagation();
    if (this.molecule) {
      const data = {
        moleculeData: this.molecule,
        cardData: this.expansionPanel,
      };
      this.expandMoleculeViewEmitter.emit(data);
    }
  }

  public editMolecule(event: Event) {
    event.stopPropagation();
    if (this.molecule) {
      this.editMoleculeEmitter.emit(this.molecule);
    }
  }
}
