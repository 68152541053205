import { isString } from '../../shared/components/utils';
import { prepareImageUrl } from '../services/ecomm-handler.service';
export class ProductDetailInfo {
  public cas_number: string;
  public molecule_name: string;
  public empirical_formula: string;
  public molecular_weight: string;
  public image_url: string;
  public image_alt: string;
  public synonyms: string[];
  public product_key: string;
  public product_number: string;
  public product_name_suffix: string;
  public materials: Array<string>;
  public brand: string;
  public brand_key: string;
  public is_sial: string;

  constructor(
    data: any,
    ecommAPIUrl: string,
    is_bbe_data: boolean = false,
    bbe_material: string = null,
  ) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    this.molecule_name = jsonData.product_name;
    this.empirical_formula = jsonData.empirical_formula;
    this.cas_number = jsonData.cas_number;
    this.molecular_weight = jsonData.molecular_weight;
    if (is_bbe_data) {
      this.image_url = jsonData.images?.length > 0 ? jsonData.images[0].url : '';
      this.product_key = bbe_material;
    } else {
      this.image_url =
        jsonData.images?.length > 0 ? prepareImageUrl(jsonData.images[0].url, ecommAPIUrl) : '';
      this.product_key = jsonData.product_key;
    }
    this.image_alt = jsonData.images?.length > 0 ? jsonData.images[0]?.alt_text : '';
    this.synonyms = jsonData.synonyms;
    this.product_number = jsonData.product_number;
    this.product_name_suffix = jsonData.product_name_suffix;
    this.materials = jsonData.materials;
    this.brand = jsonData.brand;
    this.brand_key = jsonData.brand_key;
    this.is_sial = jsonData.is_sial;
  }
}
