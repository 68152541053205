import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, interval, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardAnalysisEntry } from './models/dashboard-analysis-entry';
import { ErrorsHandlerService, ParsedConnectionError } from './errors-handler';
import { InfoService } from './info.service';
import { DashboardAnalysisCount } from './models/dashboard-analysis-count';
import { isNullOrUndefined } from '../components/utils';

export const backendEntryPointDashboard = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/dashboard/';
export const backendEntryPointDashboardCount =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/dashboard-count/';

export interface IAnalysesList {
  results: DashboardAnalysisEntry[];
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private count: number = 0;

  constructor(
    private http: HttpClient,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
  ) {}

  public observeAnalyses() {
    const intervalObs = interval(10000);
    return intervalObs.pipe(
      map(() => {
        return true;
      }),
    );
  }

  public getAnalyses(
    limit: number,
    offset: number,
    filters: any,
    orderBy?: string,
  ): Observable<IAnalysesList> {
    let queryParams: HttpParams = new HttpParams()
      .set('limit', String(limit))
      .set('offset', String(offset));
    for (const filter in filters) {
      if (filters[filter]) {
        queryParams = queryParams.set(filter, String(filters[filter]));
      }
    }

    if (orderBy) {
      queryParams = queryParams.set('order_by', String(orderBy));
    }

    return this.http
      .get(backendEntryPointDashboard, { params: queryParams, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            this.count = Number(response.headers.get('X-Total-Count'));
            const responseJson = response.body;
            return responseJson.map((analysis) => new DashboardAnalysisEntry(analysis));
          } catch (e) {
            throw new Error('Unexpected format of response for AnalysisEntry.');
          }
        }),
        map((analyses: DashboardAnalysisEntry[]) => {
          const result: IAnalysesList = {
            results: analyses,
            count: this.count,
          };
          return result;
        }),
        catchError(this.getDashboardAnalysisEntryError.bind(this)),
      ) as Observable<IAnalysesList>;
  }

  public getDashboardCount(filters: any): Observable<DashboardAnalysisCount> {
    let queryParams: HttpParams = new HttpParams();
    for (const filter in filters) {
      if (filters[filter]) {
        queryParams = queryParams.set(filter, String(filters[filter]));
      }
    }
    return this.http
      .get(backendEntryPointDashboardCount, { params: queryParams, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => this.resolveDashboardCount(response)),
        catchError(this.getDashboardAnalysisEntryError.bind(this)),
      );
  }

  private resolveDashboardCount(response: HttpResponse<any>): DashboardAnalysisCount {
    try {
      const dashboardCount: DashboardAnalysisCount = new DashboardAnalysisCount(response.body);
      return dashboardCount;
    } catch (e) {
      throw new Error('Unexpected format of response');
    }
  }

  private getDashboardAnalysisEntryError(
    error: Response | any,
    caughtObservable?: Observable<DashboardAnalysisEntry>,
  ) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage, 1000 * 60 * 60);
      this.errorsHandler.logout();
      return throwError(parsedError);
    }
    if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      return throwError(parsedError);
    } else {
      return throwError('Unsuccessful access to analysis database. ' + error);
    }
  }
}
