import {
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy,
  OnInit,
  DoCheck,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmDialogConfiguration,
  ConfirmDialogService,
} from '../../services/confirm-dialog.service';
import { InfoService } from '../../services/info.service';
import { UserInventoryService } from '../../services/user-inventory';
import { Subscription, Subject, Observable } from 'rxjs';
import { UserInventory } from '../../services/user-inventory/models/user-inventory';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppConstantsService } from '../../services/app-constants/app-constants.service';
import { HashingTool } from '../../services/user-inventory/models/hashing-tool';

@Component({
  selector: 'ch-user-inventory',
  templateUrl: './user-inventory.component.html',
  styleUrls: ['./user-inventory.component.scss'],
})
export class UserInventoryComponent implements OnInit, OnDestroy, DoCheck {
  public readonly objectKeys: Function = Object.keys; // for use in template
  @ViewChild('fileInput') public fileInput: ElementRef;
  @ViewChild('fileDropArea') public fileDropArea: ElementRef;
  public inventoryNameFieldFormControl: UntypedFormControl = new UntypedFormControl('');
  public fileDraggedOver: boolean = false;
  public incorrectFileTypeError: boolean = false;
  public inventoryFile: any;
  public fileSelected: boolean = false;
  public fileHeader: string = '';
  public fileColumnOptions: string[] = [];
  public fileName: string = '';
  public fileSalt: string = '';
  public fieldsMap: {
    [key: string]: {
      fieldName: string;
      mappedFieldFormControl: UntypedFormControl;
    };
  } = {
    compound_hash: {
      fieldName: 'Hashed structure',
      mappedFieldFormControl: new UntypedFormControl(''),
    },
    compound_id: {
      fieldName: 'Compound ID',
      mappedFieldFormControl: new UntypedFormControl(''),
    },
  };
  public saveFieldMapping: boolean = false;
  public uploadProgress: number = 0;
  public loadingUserInventories: boolean;
  public loadingHashingTools: boolean = true;
  public userInventory: UserInventory;
  public hashingTools: HashingTool[] = [];

  private dragFileListenersInitialized: boolean = false;
  private fileReadyForUploadSubject: Subject<string> = new Subject<string>();
  private userInventoryUploadSubscription: Subscription = new Subscription();
  private userInventorySubscriptions: Subscription = new Subscription();
  private subjectsSubscription: Subscription = new Subscription();
  private confirmDialogSubscription: Subscription = new Subscription();
  private inventoryProgressSubscription: Subscription = new Subscription();
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(
    public appConstantsService: AppConstantsService,
    private infoService: InfoService,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<UserInventoryComponent>,
    private userInventoryService: UserInventoryService,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  public ngDoCheck() {
    // we only need to check one formControl and inventoryNameFieldFormControl is easiest to reference
    if (this.uploadProgress > 0) {
      if (this.inventoryNameFieldFormControl.enabled) {
        this.disableForm();
      }
    } else {
      if (this.inventoryNameFieldFormControl.disabled) {
        this.enableForm();
      }
    }
    if (!this.dragFileListenersInitialized && !!this.fileDropArea) {
      this.setFileDragEventListeners();
    }
  }

  public ngOnInit() {
    this.awaitUploadReady();
    this.initSpinnerProgress();
    this.getHashingTools();
    this.getInventories();
    this.resetDialogSize();
  }

  public ngOnDestroy() {
    if (this.subjectsSubscription && !this.subjectsSubscription.closed) {
      this.subjectsSubscription.unsubscribe();
    }
    if (this.confirmDialogSubscription && !this.confirmDialogSubscription.closed) {
      this.confirmDialogSubscription.unsubscribe();
    }
    if (this.userInventoryUploadSubscription && !this.userInventoryUploadSubscription.closed) {
      this.userInventoryUploadSubscription.unsubscribe();
    }
    if (this.inventoryProgressSubscription && !this.inventoryProgressSubscription.closed) {
      this.inventoryProgressSubscription.unsubscribe();
    }
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public closeDialog() {
    if (this.uploadProgress === 0) {
      this.dialogRef.close();
    } else {
      this.confirmDialogSubscription.add(
        this.getCloseConfirmationDialogObservable().subscribe((ok) => {
          if (ok) {
            this.dialogRef.close();
          }
        }),
      );
    }
  }

  public readFile(file: File) {
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = (event: ProgressEvent) => {
      try {
        const fileReadlines: string[] = (fileReader.result as string).split('\n');
        const fileHeaderRow: string = fileReadlines[0];
        const fileSalt: string = this.getFileSalt(fileReadlines[1]);
        if (!this.checkFileSize(event.total)) {
          this.displayFileSizeExceededError();
        } else if (!this.checkFileValidity(fileHeaderRow)) {
          this.displayIncorrectFileTypeError();
        } else {
          this.resetDialogSize();
          this.incorrectFileTypeError = false;
          this.fileHeader = fileHeaderRow.substring(2, fileHeaderRow.length); // remove # from the beginning of row
          this.fileColumnOptions = fileReadlines[2].split(',');
          this.fileSalt = fileSalt;
          this.fileSelected = true;
          this.inventoryFile = file;
          this.fileName = file.name;
          this.inventoryNameFieldFormControl.setValue(file.name);
          this.readLocalStorageMappedFields();
        }
      } catch (error) {
        this.displayIncorrectFileTypeError();
      }
    };
    fileReader.readAsText(file);
  }

  public onFileSelected(files: FileList) {
    // When user presses cancel in the file explorer the event will fire with empty array of files.
    if (files.length > 0) {
      this.verifyAndReadFile(files);
    }
  }

  public onUploadClick() {
    if (this.isFormValid()) {
      this.mapUserUploadedFileFieldsAndTriggerUpload();
      if (this.saveFieldMapping) {
        this.storeMappedFields();
      } else {
        this.clearMappedFields();
      }
    }
  }

  public uploadInventory(blob: Blob, trackProgress: boolean = true) {
    this.dialogRef.disableClose = true;
    this.infoService.showInfo(
      `
      File uploading in progress. Please do not close this window until upload is finished.
    `,
      15000,
    );
    this.userInventoryUploadSubscription.add(
      this.userInventoryService
        .uploadUserInventory(
          this.fileSalt,
          this.fileName,
          this.inventoryNameFieldFormControl.value,
          this.fileHeader,
          blob,
          this.userInventory,
        )
        .pipe(
          finalize(() => {
            this.dialogRef.disableClose = false;
          }),
        )
        .subscribe(
          (inventoryId: string) => {
            this.infoService.showInfo(`Inventory upload completed successfully`, 15000);
            this.uploadProgress = 0;
            this.closeDialog();
          },
          (error) => {
            this.uploadProgress = 0;
            this.getInventories();
          },
        ),
    );
  }

  public getInventories() {
    this.loadingUserInventories = true;
    this.userInventorySubscriptions.add(
      this.userInventoryService.getUserInventories().subscribe((inventoryList: UserInventory[]) => {
        // Spec defines that only one inventory should be in use
        this.userInventory = inventoryList[0];
        this.loadingUserInventories = false;
      }),
    );
  }

  public shouldHidePaddingInFormField(formControl: UntypedFormControl): boolean {
    return formControl.touched ? !formControl.hasError('required') : true;
  }

  public isUploadBtnDisabled(): boolean {
    return (
      this.uploadProgress > 0 ||
      (this.confirmDialogService.dialogRef &&
        !!this.confirmDialogService.dialogRef.componentInstance)
    );
  }

  private getHashingTools() {
    this.userInventoryService
      .getHashingTools()
      .pipe(
        takeUntil(this.unsubscriberSubject),
        finalize(() => (this.loadingHashingTools = false)),
      )
      .subscribe((hashingTools: HashingTool[]) => {
        this.hashingTools = hashingTools;
      });
  }

  private awaitUploadReady() {
    this.subjectsSubscription.add(
      this.fileReadyForUploadSubject.subscribe((content: string) =>
        this.makeBlobAndUpload(content),
      ),
    );
  }

  private initSpinnerProgress() {
    this.subjectsSubscription.add(
      this.userInventoryService.uploadProgressSubject.subscribe((progress: number) => {
        this.uploadProgress = progress;
      }),
    );
  }

  private readLocalStorageMappedFields() {
    const storedMappedInventoryFields: string = localStorage.getItem('mappedInventoryFields');
    if (!!storedMappedInventoryFields) {
      this.saveFieldMapping = true;
      try {
        const mappedFields: {
          [key: string]: {
            fieldName: string;
            mappedField: string;
          };
        } = JSON.parse(storedMappedInventoryFields);
        Object.keys(mappedFields).forEach((field: string) => {
          if (this.fileColumnOptions.includes(mappedFields[field].mappedField)) {
            this.fieldsMap[field].mappedFieldFormControl.setValue(mappedFields[field].mappedField);
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      this.saveFieldMapping = false;
    }
  }

  private isFormValid(): boolean {
    let invalidFields: boolean = false;
    this.inventoryNameFieldFormControl.markAsTouched();
    if (this.inventoryNameFieldFormControl.hasError('required')) {
      invalidFields = true;
    }
    for (const field of Object.keys(this.fieldsMap)) {
      this.fieldsMap[field].mappedFieldFormControl.markAsTouched();
      if (this.fieldsMap[field].mappedFieldFormControl.hasError('required')) {
        invalidFields = true;
      }
    }
    return !invalidFields;
  }

  private disableForm() {
    this.inventoryNameFieldFormControl.disable();
    for (const field of Object.keys(this.fieldsMap)) {
      this.fieldsMap[field].mappedFieldFormControl.disable();
    }
  }

  private enableForm() {
    this.inventoryNameFieldFormControl.enable();
    for (const field of Object.keys(this.fieldsMap)) {
      this.fieldsMap[field].mappedFieldFormControl.enable();
    }
  }

  private mapUserUploadedFileFieldsAndTriggerUpload(): string {
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = () => {
      const fileReadlines: string[] = (fileReader.result as string).split('\n');
      fileReadlines.splice(0, 2); // remove two first rows
      for (const field of Object.keys(this.fieldsMap)) {
        fileReadlines[0] = fileReadlines[0].replace(
          this.fieldsMap[field].mappedFieldFormControl.value,
          field,
        );
      }
      this.fileReadyForUploadSubject.next(fileReadlines.join('\n'));
    };
    fileReader.readAsText(this.inventoryFile);
    return fileReader.result as string;
  }

  private storeMappedFields() {
    const fieldsMapForLocalStorage: {
      [key: string]: {
        fieldName: string;
        mappedField: string;
      };
    } = {};
    Object.keys(this.fieldsMap).forEach((field) => {
      fieldsMapForLocalStorage[field] = { fieldName: '', mappedField: '' };
      fieldsMapForLocalStorage[field].fieldName = this.fieldsMap[field].fieldName;
      fieldsMapForLocalStorage[field].mappedField = this.fieldsMap[
        field
      ].mappedFieldFormControl.value;
    });
    localStorage.setItem('mappedInventoryFields', JSON.stringify(fieldsMapForLocalStorage));
  }

  private clearMappedFields() {
    if (!!localStorage.getItem('mappedInventoryFields')) {
      localStorage.removeItem('mappedInventoryFields');
    }
  }

  private getFileSalt(saltRow: string): string {
    const matchSalt: RegExp = new RegExp(/[0-9a-fA-F]{8,}/); // hexadecimal string of at least 8 characters
    const matched: any = saltRow.match(matchSalt);
    return matched[0] ? matched[0] : '';
  }

  private makeBlobAndUpload(blobContent: string) {
    const newFileBlob: Blob = new Blob([blobContent], { type: 'text/csv' });
    this.uploadInventory(newFileBlob);
  }

  private verifyAndReadFile(files: FileList) {
    if (files.length > 1) {
      // No support for multiple files for now.
      this.displayMultipleFilesError();
    } else {
      this.readFile(files[0]);
    }
  }

  private resetDialogSize() {
    this.dialogRef.updateSize('750px', '550px');
  }

  private getCloseConfirmationDialogObservable(): Observable<boolean> {
    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: `Upload in progress`,
      message: `Your upload of ${this.inventoryNameFieldFormControl.value} inventory is still in progress.
        Do you want to abort the upload?`,
      trueActionName: 'ABORT UPLOAD',
    };

    return this.confirmDialogService.confirm(dialogConfiguration);
  }

  private getActiveComputationsConfirmationDialogObservable(): Observable<boolean> {
    const dialogConfiguration: ConfirmDialogConfiguration = {
      title: `Active Computations Notice`,
      message: `There are computations in queue or in progress. Uploading the file may affect results of these
        computations. Do you want to continue?`,
      trueActionName: 'CONTINUE',
    };

    return this.confirmDialogService.confirm(dialogConfiguration);
  }

  private displayIncorrectFileTypeError() {
    this.incorrectFileTypeError = true;
    this.infoService.showError(`Incorrect file type provided.`, 3000);
  }

  private displayMultipleFilesError() {
    this.infoService.showError(`Only one file at a time is supported.`, 3000);
  }

  private displayFileSizeExceededError() {
    this.infoService.showError(`File is too large. Maximum allowed file size is 256MB.`, 3000);
  }

  private checkFileSize(fileSize: number, maxFileSize: number = 256000000) {
    return fileSize <= maxFileSize;
  }

  private checkFileValidity(fileHeader: string) {
    const splitHeader: string[] = fileHeader.split(',');
    const headerRegex: RegExp = new RegExp(/^#\sgenerated\son\s\d\d\/\d\d\/\d{4}/);
    return headerRegex.test(splitHeader[0]);
  }

  private setFileDragEventListeners() {
    this.renderer.listen(this.fileDropArea.nativeElement, 'drop', (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.fileDraggedOver = false;
      this.onFileSelected(event.dataTransfer.files);
    });
    this.renderer.listen(this.fileDropArea.nativeElement, 'dragover', (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.fileDraggedOver = true;
      this.incorrectFileTypeError = false;
    });
    this.renderer.listen(this.fileDropArea.nativeElement, 'dragleave', (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.fileDraggedOver = false;
    });
    this.dragFileListenersInitialized = true;
  }
}
