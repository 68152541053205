import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const RING_ANIMATION = trigger('ringAnimation', [
  state('active', style({ transform: 'translate3d(0, 0, 0)' })),
  state('ring', style({ transform: 'translate3d(0, 0, 0)' })),
  transition('* => *', [
    animate(
      '5s ease-in-out',
      keyframes([
        style({ transform: 'rotate(0)' }),
        style({
          transform: 'rotate(0)',
        }),
        style({
          transform: 'rotate(30deg)',
        }),
        style({
          transform: 'rotate(-28deg)',
        }),
        style({
          transform: 'rotate(34deg)',
        }),
        style({
          transform: 'rotate(-32deg)',
        }),
        style({
          transform: 'rotate(30deg)',
        }),
        style({
          transform: 'rotate(-28deg)',
        }),
        style({
          transform: 'rotate(26deg)',
        }),
        style({
          transform: 'rotate(-24deg)',
        }),
        style({
          transform: 'rotate(22deg)',
        }),
        style({
          transform: 'rotate(-20deg)',
        }),
        style({
          transform: 'rotate(18deg)',
        }),
        style({
          transform: 'rotate(-16deg)',
        }),
        style({
          transform: 'rotate(14deg)',
        }),
        style({
          transform: 'rotate(-12deg)',
        }),
        style({
          transform: 'rotate(10deg)',
        }),
        style({
          transform: 'rotate(-8deg)',
        }),
        style({
          transform: 'rotate(6deg)',
        }),
        style({
          transform: 'rotate(-4deg)',
        }),
        style({
          transform: 'rotate(2deg)',
        }),
        style({
          transform: 'rotate(-1deg)',
        }),
        style({
          transform: 'rotate(1deg)',
        }),
        style({
          transform: 'rotate(0)',
        }),
        style({
          transform: 'rotate(0)',
        }),
      ]),
    ),
  ]),
]);
