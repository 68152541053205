<div class="ch-calculator ch-new-calculator-container">
  <div class="calculator-container">
    <div class="calculator-left">
      <mat-form-field
        class="ch-calculator-input-form-field calculator-input"
        #scoringFunctionField
        appearance="outline"
      >
        <mat-label>Enter your Scoring Function here</mat-label>
        <textarea
          [readOnly]="isDisabledDialogSave"
          #scoringFunctionTextarea
          matInput
          [formControl]="scoringFunctionFormControl"
          maxlength="2000"
          minlength="1"
          (input)="onCalculatorInput()"
          name="scoringFunction"
          class="ch-calculator-textarea"
        >
        </textarea>
        <mat-error
          *ngIf="
            scoringFunctionFormControl.hasError('required') ||
            scoringFunctionFormControl.hasError('minlength')
          "
        >
          scoring function is <strong>required</strong> to use expert settings in analysis
          parameters
        </mat-error>
        <mat-error *ngIf="scoringFunctionFormControl.hasError('maxlength')">
          character limit reached
        </mat-error>
        <mat-error *ngIf="scoringFunctionFormControl.hasError('invalidSmiles')">
          some parameters or SMILES entered may be invalid
        </mat-error>
      </mat-form-field>

      <div class="ch-calculator-edit">
        <button
          mat-button
          [buttonOutline]="'default-grey'"
          [disabled]="inputHistory.length <= 1 || freezingSets || isDisabledDialogSave"
          (click)="undo()"
          class="ch-calculator-edit-button"
        >
          UNDO
        </button>
        <button
          mat-button
          [buttonOutline]="'default-grey'"
          [disabled]="isSourceCode() || freezingSets || isDisabledDialogSave"
          (click)="resetScoringFunction()"
          class="ch-calculator-edit-button"
        >
          RESET
        </button>
        <button
          mat-button
          [buttonOutline]="'default-grey'"
          [disabled]="!scoringFunctionFormControl.valid || freezingSets || isDisabledDialogSave"
          (click)="clearScoringFunction()"
          class="ch-calculator-edit-button"
        >
          CLEAR
        </button>
        <button
          mat-button
          [buttonOutline]="'warn'"
          [disabled]="!scoringFunction?.owner || isDisabledDialogSave"
          (click)="deleteScoringFunction()"
          class="ch-calculator-edit-button save-btn"
        >
          DELETE
        </button>
        <button
          mat-button
          [buttonOutline]="'primary'"
          [disabled]="
            !scoringFunctionFormControl.valid ||
            freezingSets ||
            !isAuthorizedToStartAnalysis ||
            isDisabledDialogSave
          "
          (click)="openSaveDialog()"
          class="ch-calculator-edit-button save-btn"
        >
          SAVE
        </button>
      </div>

      <div class="ch-calculator-buttons-left">
        <div class="ch-button-container">
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('(')"
          >
            (
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction(')')"
          >
            )
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction(',')"
          >
            ,</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('^2')"
          >
            x^2
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('^3')"
          >
            x^3
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('^')"
          >
            x^y</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('10^')"
          >
            10^x
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('sqrt')"
          >
            &radic;
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('1/')"
          >
            1/x</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('log10')"
          >
            log10
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('ln')"
          >
            ln
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('e^')"
          >
            e^x
          </button>
        </div>
      </div>
      <div class="ch-calculator-buttons-right">
        <div class="ch-button-container">
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('7')"
          >
            7
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('8')"
          >
            8
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('9')"
          >
            9
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('+')"
          >
            +</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('4')"
          >
            4
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('5')"
          >
            5
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('6')"
          >
            6
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('-')"
          >
            -</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('1')"
          >
            1
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('2')"
          >
            2
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('3')"
          >
            3
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('*')"
          >
            *</button
          ><br />
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('0')"
          >
            0
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            [disabled]="freezingSets || isDisabledDialogSave"
            class="ch-calculator-button-small numericals"
            (click)="addToScoringFunction(',')"
          >
            ,
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('.')"
          >
            .
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-calculator-button-small numericals"
            [disabled]="freezingSets || isDisabledDialogSave"
            (click)="addToScoringFunction('/')"
          >
            /
          </button>
        </div>
      </div>
    </div>

    <div [ngSwitch]="functionType" class="calculator-right">
      <div class="calculator-type-chemical" *ngSwitchCase="FunctionType.Chemical">
        <button
          mat-button
          *ngFor="let parameter of advancedCSFAvailableFunctions"
          [disabled]="freezingSets || isDisabledDialogSave"
          [buttonOutline]="'primary'"
          class="ch-calculator-button-regular"
          (click)="onCalculatorParameterClick(parameter)"
          matTooltip="{{ parameter.description }}"
          matTooltipPosition="before"
        >
          {{ parameter.parameter_name }}
        </button>
      </div>
      <div class="calculator-type-reaction" *ngSwitchCase="FunctionType.Reaction">
        <ng-container *ngFor="let parameter of advancedRSFAvailableFunctions">
          <button
            mat-button
            *ngIf="parameter.hideSeek && parameter.value === 'HIDE_MOLSET'"
            [disabled]="freezingSets || isDisabledDialogSave"
            [buttonOutline]="'primary'"
            [matMenuTriggerFor]="hideMolSetsMenu"
            (click)="onCalculatorParameterClick(parameter)"
            class="ch-calculator-button-regular"
            matTooltip="{{ parameter.description }}"
            matTooltipPosition="before"
          >
            {{ parameter.parameter_name }}
          </button>
          <button
            mat-button
            *ngIf="parameter.hideSeek && parameter.value === 'HIDE_SMARTSSET'"
            [disabled]="freezingSets || isDisabledDialogSave"
            [buttonOutline]="'primary'"
            [matMenuTriggerFor]="hideSmartsSetsMenu"
            class="ch-calculator-button-regular"
            matTooltip="{{ parameter.description }}"
            matTooltipPosition="before"
          >
            {{ parameter.parameter_name }}
          </button>
          <button
            mat-button
            *ngIf="parameter.value !== 'HIDE_SMARTSSET' && parameter.value !== 'HIDE_MOLSET'"
            [disabled]="freezingSets || isDisabledDialogSave"
            [buttonOutline]="'primary'"
            class="ch-calculator-button-regular"
            (click)="onCalculatorParameterClick(parameter)"
            matTooltip="{{ parameter.description }}"
            matTooltipPosition="before"
          >
            {{ parameter.parameter_name }}
          </button>
        </ng-container>
      </div>
      <mat-menu
        #hideMolSetsMenu="matMenu"
        [class]="'hide-sets-menu menu-wide'"
        (closed)="onMatMenuClose($event, moleculeSets, 'molecule')"
      >
        <button
          mat-menu-item
          *ngFor="let moleculeSet of moleculeSets"
          (click)="onMatMenuButtonClick($event, moleculeSet)"
        >
          <mat-checkbox
            color="primary"
            (click)="$event.preventDefault()"
            [ngModel]="!!moleculeSet.selected" (ngModelChange)="moleculeSet.selected = $event"
          >
            {{ moleculeSet.name }}</mat-checkbox
          >
        </button>
      </mat-menu>
      <mat-menu
        #hideSmartsSetsMenu="matMenu"
        [class]="'hide-sets-menu menu-wide'"
        (closed)="onMatMenuClose($event, smartsSets, 'smarts')"
      >
        <button
          mat-menu-item
          *ngFor="let smartsSet of smartsSets"
          (click)="onMatMenuButtonClick($event, smartsSet)"
        >
          <mat-checkbox
            color="primary"
            (click)="$event.preventDefault()"
            [ngModel]="!!smartsSet.selected" (ngModelChange)="smartsSet.selected = $event"
          >
            {{ smartsSet.name }}</mat-checkbox
          >
        </button>
      </mat-menu>
    </div>
  </div>
</div>
