import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { PasswordChangeComponent } from 'src/app/authorization/password-change/password-change.component';

@Injectable()
export class PasswordChangeDialogService {
  public dialogRef: MatDialogRef<PasswordChangeComponent>;
  private config = new MatDialogConfig();

  constructor(private dialog: MatDialog) {}

  public open(): Observable<boolean> {
    this.config.minHeight = 200;
    this.config.minWidth = 700;
    this.config.panelClass = 'dialog-container-new';

    this.dialogRef = this.dialog.open(PasswordChangeComponent, this.config);

    return this.dialogRef.beforeClosed();
  }
}
