import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ch-pagination',
  styleUrls: ['./pagination.component.scss'],
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnChanges {
  @Input() public pageCount;
  @Input() public currentPage;

  @Output() public pageChanged = new EventEmitter();

  public maxPagesVisible: number = 10;
  public pagesDisplayed: number[] = [];
  public pages: number[] = [];

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('pageCount')) {
      if (this.pageCount) {
        for (let i = 1; i <= this.pageCount; i++) {
          this.pages.push(i);
        }
        this.makeDisplayedPageNumbers();
      }
    }
    if (changes.hasOwnProperty('currentPage')) {
      this.makeDisplayedPageNumbers();
    }
  }

  public makeDisplayedPageNumbers() {
    this.pagesDisplayed = [];
    let floor = Math.floor(this.currentPage / 10) * 10;
    if (this.currentPage < 10) {
      floor++;
    }
    for (
      let i = 0;
      this.pagesDisplayed.length < this.maxPagesVisible && floor + i <= this.pageCount;
      i++
    ) {
      this.pagesDisplayed.push(floor + i);
    }
  }

  public shouldShowLeftNavDots() {
    return this.currentPage - 10 >= 0;
  }

  public shouldShowRightNavDots() {
    return (
      this.pagesDisplayed[this.pagesDisplayed.length - 1] !== this.pages[this.pages.length - 1]
    );
  }

  public isActive(page: number) {
    return page === this.currentPage;
  }

  public shouldShowFirstPageNumber() {
    return this.pagesDisplayed[0] !== 1;
  }

  public shouldShowLastPageNumber() {
    return this.pagesDisplayed[this.pagesDisplayed.length - 1] !== this.pageCount;
  }

  public onNextPage() {
    this.pageChanged.emit({ pageIndex: this.currentPage + 1 });
  }

  public onPreviousPage() {
    this.pageChanged.emit({ pageIndex: this.currentPage - 1 });
  }

  public onPageSelected(page: number) {
    if (page < 1) {
      this.pageChanged.emit({ pageIndex: 1 });
    } else if (page > this.pageCount) {
      this.pageChanged.emit({ pageIndex: this.pageCount });
    } else if (this.currentPage !== page) {
      this.pageChanged.emit({ pageIndex: page });
    }
  }

  public hasNextPage() {
    return this.currentPage < this.pageCount;
  }

  public hasPreviousPage() {
    return this.currentPage > 1;
  }
}
