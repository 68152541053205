import { Subscription, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as dagre from 'dagre';
import {
  MoleculeLabelType,
  ReactionLabelType,
  ReactionColorType,
  MoleculeScalingType,
  AnalysisService,
  EdgeColorType,
  NodesClipboardService,
  AnalysisEntry,
  GraphLayout,
  ResultsView,
  PathDirection,
  AnalysisResultsService,
  GraphNodePopupService,
  AppConstantsService,
} from '../../services';
import {
  setShopAvailabilityHints,
  setMoleculeLabels,
  setReactionLabels,
  setReactionColors,
  setMoleculeColors,
  setEdgeColors,
  setMoleculeScaling,
  setGraphRatio,
  isTouchDevice,
  installSigmaGraphOverloads,
  dagreOverlapsTunning,
  spreadLeafMoleculeNodes,
  getSigmaGraphImage,
  getSigmaGraphVectorImage,
  isReactionNode,
  isMoleculeNode,
  getReactionInfo,
} from '../graph-utils';
import { INodeEvent } from '../../services/models/node-event';
import { isUndefined } from '../../components/utils';
import { GraphNodeType, GraphMoleculeNode, GraphReactionNode } from '../../services/graph-builder';

const ACCIDENTAL_NODE_DRAG_SENSITIVITY: number = 10;
const ACCIDENTAL_NODE_DRAG_SENSITIVITY_TIME: number = 1000;

interface NodeDragDelta {
  startX: number;
  startY: number;
  startTime: number;
  endX: number;
  endY: number;
  endTime: number;
}

export enum ZoomType {
  zoomIn = 'in',
  zoomOut = 'out',
  resetZoom = 'default',
}

@Component({
  selector: 'ch-new-analysis-graph',
  template: '', // graph is directly injected to ch-analysis-graph selector
})
export class NewAnalysisGraphComponent implements OnInit, OnDestroy, OnChanges {
  // Define types of the sigma variables
  public sigmaGraph: any;
  public moleculeLabel: MoleculeLabelType;
  public reactionLabel: ReactionLabelType;
  public moleculeScaling: MoleculeScalingType;
  public hiddenMoleculeLabel: MoleculeLabelType;
  public hiddenReactionLabel: ReactionLabelType;
  public camera: any;
  public graphLayout: GraphLayout;
  public colorReactionArrows: EdgeColorType;
  public colorReactionDiamonds: ReactionColorType;
  public currentRatio: number = 1;
  public labelHideThreshold = 1.2; // inverse of actual zoom (set to 2 to hide labels when graph is 2 times smaller)
  public nodeTypeIcons: boolean;
  public shopAvailability: boolean;
  public nodeIsMoved: boolean = false;
  public isGraphLoaded: boolean = false;
  public nodeOverTimeout: any;
  public nodeOutTimeout: any;
  public cameraId: number = 999;
  public pathDirection: PathDirection = PathDirection.LeftToRight;
  public previousLayout: GraphLayout;

  @Input() public graph: any;
  @Input() public analysis: AnalysisEntry;

  @Output() public onNodeSelectedClick = new EventEmitter<object>();
  @Output() public onNodeSelectedOver = new EventEmitter<object>();
  @Output() public onReactionOverAndOut = new EventEmitter<any>();
  @Output() public changeCursor = new EventEmitter<any>();
  @Output() public pendingLayout = new EventEmitter<boolean>();

  private frontendStorageServiceSubscriptions: Subscription;

  // FIXME: Sort settings to groups: sigma-set-once, sigma-adjusted-dynamicly, ours. Document ours.
  private sigmaGraphSettings = {
    doubleClickEnabled: false,
    rightClickEnabled: false,
    drawEdges: true,
    drawLabels: true,
    drawEdgeLabels: false,
    hideEdgesOnMove: false, // hide edges when moving graph
    zoomingRatio: 1.3, // Determines how much the graph zooms with each turn of the mouse wheel
    zoomMin: 0.001,
    zoomMax: 30,
    mouseZoomDuration: 500,
    mouseInertiaDuration: 1000,
    mouseInertiaRatio: 1,
    touchInertiaDuration: 1000,
    touchInertiaRatio: 1,
    animationsTime: 150,
    sideMargin: 10,
    autoRescale: true,
    nodesPowRatio: 0.75,
    edgesPowRatio: 0.75,
    scalingMode: 'inside', // important for drag without scaling
    defaultLabelColor: '#000',
    labelColor: 'default',
    fontStyle: '#000',
    minNodeSize: 1,
    maxNodeSize: 8,
    minEdgeSize: 3,
    maxEdgeSize: 3,
    // our settings
    moleculeBorderWidthRatio: 0.2, // what part of node radius meaningful borders takes
    scale: 0.09, // required in dragging
    chNodeTypeIcons: false, // Should accessibility icons be rendered
    enableHovering: false,
    enableEdgeHovering: true,
  };

  private sigmaGraphPresenceSubject: Subject<void> = new Subject<void>();
  private nodeDragDelta: NodeDragDelta = {
    startX: 0,
    startY: 0,
    startTime: 0,
    endX: 0,
    endY: 0,
    endTime: 0,
  };

  constructor(
    private analysisService: AnalysisService,
    private elRef: ElementRef,
    private clipboardService: NodesClipboardService,
    private analysisResultsService: AnalysisResultsService,
    private graphNodePopupService: GraphNodePopupService,
    private appConstantsService: AppConstantsService,
    public popupService: GraphNodePopupService,
  ) {
    installSigmaGraphOverloads();
  }

  public ngOnInit() {
    this.initGraph();
    this.subscribeToSigmaGraphChanges();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event) {
    this.updateContainerWidthInNodes();
  }

  public initGraph() {
    this.sigmaGraph = new sigma();
    this.camera = this.sigmaGraph.addCamera('cam' + this.cameraId);
    this.sigmaGraph.addRenderer({
      container: this.elRef.nativeElement,
      type: 'canvas',
      camera: this.camera,
    });

    // Bind instance to our settings
    // NOTE: It seems that this.sigmaGraph.addRenderer overloads at least minEdgeSize and maxEgdeSize
    //       with it's own values. Due to this bug binding can not be done before addRenderer
    //       (for example in with simga c-tor above)
    this.sigmaGraph.settings(this.sigmaGraphSettings);
    this.bindEvents();
    this.updateContainerWidthInNodes();
  }

  public updateContainerWidthInNodes() {
    if (this.sigmaGraph) {
      this.sigmaGraph.graph
        .nodes()
        .forEach((node) => (node.graphWidth = this.elRef.nativeElement.scrollWidth));
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('graph')) {
      if (changes.graph.currentValue.nodes.length) {
        this.sigmaGraphPresenceSubject.next(changes.graph.currentValue.nodes.length);
        this.frontendStorageServiceSubscriptions.add(
          this.popupService.selectedNodes.subscribe((nodes) => {
            nodes.forEach((node) =>
              this.popupService.dispatchOtherNodeEvent(
                { node: node, select: true },
                'printpathway',
              ),
            );
          }),
        );
      }
    }
  }

  public ngOnDestroy() {
    this.unsubscribeFromFrontendStorage();
    this.destroyGraph();
  }

  public updateGraphPosition() {
    setTimeout(() => {
      this.updateContainerWidthInNodes();
      this.sigmaGraph.refresh();
    }, 200);
  }

  /**
   * Render the graph image as is. Directly calls {@link getSigmaGraphImage} with the sigma graph actually used
   * by the component. See {@link getSigmaGraphImage} for details.
   */
  public getImage(
    format: 'png' | 'jpg' | 'gif' | 'tiff' | 'svg' = 'svg',
    downloadFilename?: string,
  ): string {
    if (format === 'svg') {
      const r = this.elRef.nativeElement.getBoundingClientRect();
      const w = r.right > r.left ? r.right - r.left : 500;
      const h = r.bottom > r.top ? r.bottom - r.top : 500;
      const settings = JSON.parse(JSON.stringify(this.sigmaGraphSettings)); // avoid conflicts
      settings['autoRescale'] = true;
      return getSigmaGraphVectorImage(
        this.sigmaGraph.graph.nodes(),
        this.sigmaGraph.graph.edges(),
        { w, h },
        settings,
        downloadFilename,
      );
    } else {
      return getSigmaGraphImage(this.sigmaGraph, format, downloadFilename);
    }
  }

  public loadGraph(graph: any) {
    if (
      !this.isGraphLoaded ||
      this.analysisResultsService.isNewGraphAvailable.value ||
      this.analysisResultsService.loadNewGraphResults.value
    ) {
      this.sigmaGraph.stopChematicaForce();
      this.destroyGraph();
      this.initGraph();
      getReactionInfo(graph);
      this.resetGraph(graph);
      this.layout();
      this.showReactionLabels();
      this.showMoleculeLabels();
      this.setGraphColors(false);
      this.displayShopAvailability(this.shopAvailability);
      this.updateContainerWidthInNodes();
      this.sigmaGraph.refresh();
      this.isGraphLoaded = true;
      this.analysisResultsService.isAllPathwaysLoaded.next(true);
      this.analysisResultsService.isNewGraphAvailable.next(false);
      this.analysisResultsService.loadNewGraphResults.next(false);
    }
  }

  public layout() {
    if (this.graphLayout) {
      const nodes: GraphMoleculeNode[] & GraphReactionNode[] = this.sigmaGraph.graph.nodes();
      const edges = this.sigmaGraph.graph.edges();
      const nodeCount = nodes.length;
      const self: any = this;
      let enableDrag: boolean = true;
      if (nodes.length > 0 && edges.length > 0) {
        this.pendingLayout.emit(true);
        this.setRelativeBaseNodeSize(nodeCount); // set default size as a valid value for most layouts

        switch (this.graphLayout) {
          case GraphLayout.DAGRE: {
            if (this.graphLayout !== this.previousLayout) {
              this.resetGraphPosition();
              this.previousLayout = this.graphLayout;
            }
            this.sigmaGraph.stopChematicaForce();

            const dagreGraph: any = new dagre.graphlib.Graph();
            const baseGridSize = 1 + (nodeCount * nodeCount) / 10;
            dagreGraph.setGraph({
              rankdir: this.pathDirection,
              ranksep: baseGridSize * 3,
              edgesep: baseGridSize,
            });
            dagreGraph.setDefaultNodeLabel(() => ({}));
            dagreGraph.setDefaultEdgeLabel(() => ({}));
            nodes.forEach((_node) => {
              dagreGraph.setNode(_node.id);
            });
            edges.forEach((edge) => {
              dagreGraph.setEdge(edge.source, edge.target);
            });
            dagre.layout(dagreGraph);
            const dagreNodeKeys = dagreGraph.nodes();
            let node;
            for (let i = 0; i < nodeCount; i++) {
              node = dagreGraph.node(dagreNodeKeys[i]);
              nodes[i].x = node.x;
              nodes[i].y = node.y;
            }
            // adjust for columns overlaps
            dagreOverlapsTunning(nodes, edges);

            // adjust proportions to the actual viewport
            let minX = 1e10;
            let maxX = -1e10;
            let minY = 1e10;
            let maxY = -1e10;
            for (let i = 0; i < nodeCount; i++) {
              node = nodes[i];
              if (minX > node.x) {
                minX = node.x;
              }
              if (maxX < node.x) {
                maxX = node.x;
              }
              if (minY > node.y) {
                minY = node.y;
              }
              if (maxY < node.y) {
                maxY = node.y;
              }
            }

            const graphWidth = maxX === minX ? 1 : maxX - minX;
            const graphHeight = maxY === minY ? 1 : maxY - minY;
            const r = this.elRef.nativeElement.getBoundingClientRect();
            const width = r.right > r.left ? r.right - r.left : 500;
            const height = r.bottom > r.top ? r.bottom - r.top : 500;
            const graphRatio = graphWidth / graphHeight;
            const componentRatio = width / height;
            const heightCorrection = graphRatio / componentRatio;

            for (let i = 0; i < nodeCount; i++) {
              nodes[i].y *= heightCorrection;
            }

            this.sigmaGraph.refresh();
            this.pendingLayout.emit(false);
            enableDrag = true;
            break;
          }
          case GraphLayout.POLAR: {
            this.resetGraphPosition();
            this.sigmaGraph.stopChematicaForce();

            // with LR layout target should be the rightmost, but if we have a 'starting' node which is not a target
            // leftmost node should be centered as the graph is "reversed" due to algorithm nature
            let useLeftmostAsCenter: boolean = false;
            let node: any;

            // preformat with Dagre
            const dagreGraph: any = new dagre.graphlib.Graph();
            dagreGraph.setGraph({ rankdir: PathDirection.LeftToRight, ranksep: '3', edgesep: '1' });
            dagreGraph.setDefaultNodeLabel(() => ({}));
            dagreGraph.setDefaultEdgeLabel(() => ({}));
            nodes.forEach((_node: GraphMoleculeNode | GraphReactionNode) => {
              dagreGraph.setNode(_node.id);
              useLeftmostAsCenter =
                useLeftmostAsCenter ||
                (_node.type !== GraphNodeType.REACTION &&
                  _node.isStartingMolecule &&
                  !_node.target);
            });
            edges.forEach((edge: any) => {
              dagreGraph.setEdge(edge.source, edge.target);
            });
            dagre.layout(dagreGraph);
            const dagreNodeKeys: any[] = dagreGraph.nodes();
            for (let i = 0; i < nodeCount; i++) {
              node = dagreGraph.node(dagreNodeKeys[i]);
              nodes[i].x = node.x;
              nodes[i].y = node.y;
            }

            // adjust for overlaps produced for our graphs by Dagre
            if (this.analysis && this.analysis.isManualRetrosynthesis()) {
              spreadLeafMoleculeNodes(nodes, edges);
            } else {
              dagreOverlapsTunning(nodes, edges);
            }

            // adjust proportions to the actual viewport
            let minX: number = 1e10;
            let maxX: number = -1e10;
            let minY: number = 1e10;
            let maxY: number = -1e10;
            for (let i = 0; i < nodeCount; i++) {
              node = nodes[i];
              if (minX > node.x) {
                minX = node.x;
              }
              if (maxX < node.x) {
                maxX = node.x;
              }
              if (minY > node.y) {
                minY = node.y;
              }
              if (maxY < node.y) {
                maxY = node.y;
              }
            }
            const graphHeight: number = maxY === minY ? 1 : maxY - minY;
            let maxAngle: number = 2 * Math.PI;
            // For most algorithms we use the height of graph (this way the number of nodes in the most populated rank)
            // as the base of calculations of angle step, we need to calculate the exact number of nodes in this rank
            // to then prevent overlapping of the first and last node in this rank after transformation to circle
            // count nodes in each rank of dagre layout

            const ranks: { [rankLabel: string]: number } = {};
            let maxNodesRank: string = nodes[0].x.toString();
            for (let i = 0; i < nodeCount; i++) {
              const rankLabel: string = nodes[i].x.toString();
              ranks[rankLabel] = 1 + (ranks[rankLabel] ? ranks[rankLabel] : 0);
              if (ranks[rankLabel] > ranks[maxNodesRank]) {
                maxNodesRank = rankLabel;
              }
            }
            maxAngle = (2 * Math.PI * ranks[maxNodesRank]) / (ranks[maxNodesRank] + 1.0);

            let r: number;
            let alpha: number;
            for (let i = 0; i < nodeCount; i++) {
              node = nodes[i];
              r = useLeftmostAsCenter ? node.x - minX : maxX - node.x;
              alpha = (maxAngle * node.y) / graphHeight;
              nodes[i].x = r * Math.cos(alpha);
              nodes[i].y = r * Math.sin(alpha);
            }

            this.setRelativeBaseNodeSize(nodeCount, 1e-2);
            this.pendingLayout.emit(false);
            this.sigmaGraph.refresh();
            enableDrag = true;
            break;
          }
          case GraphLayout.FORCE: {
            this.resetGraphPosition();
            this.sigmaGraph.stopChematicaForce();

            // preinitialize layout with radial dagre for faster stabilisation
            const dagreGraph: any = new dagre.graphlib.Graph();
            dagreGraph.setGraph({
              rankdir: PathDirection.LeftToRight,
              ranksep: '1',
              edgesep: '0.1',
            });
            dagreGraph.setDefaultNodeLabel(() => ({}));
            dagreGraph.setDefaultEdgeLabel(() => ({}));
            nodes.forEach((_node: any) => {
              dagreGraph.setNode(_node.id);
            });
            edges.forEach((edge: any) => {
              dagreGraph.setEdge(edge.source, edge.target);
            });
            dagre.layout(dagreGraph);
            let node: any;
            const dagreNodeKeys = dagreGraph.nodes();
            let maxX: number = -1e10;
            let minY: number = 1e10;
            let maxY: number = -1e10;

            for (let i = 0; i < nodeCount; i++) {
              node = dagreGraph.node(dagreNodeKeys[i]);
              if (isNaN(node.x) || isNaN(node.y)) {
                console.error('NaNs in graph nodes coordinates.');
              }
              if (maxX < node.x) {
                maxX = node.x;
              }
              if (minY > node.y) {
                minY = node.y;
              }
              if (maxY < node.y) {
                maxY = node.y;
              }
            }

            const graphHeight: number = maxY - minY;
            if (graphHeight > 0) {
              let r: number;
              let alpha: number;
              for (let i = 0; i < nodeCount; i++) {
                node = dagreGraph.node(dagreNodeKeys[i]);
                r = maxX - node.x; // LR layout so rightmost node should be the root of "tree"
                alpha = (2 * Math.PI * node.y) / graphHeight;
                nodes[i].x = r * Math.cos(alpha);
                nodes[i].y = r * Math.sin(alpha);
              }
            } else {
              // For single line graphs use random height to avoid axial-only forces
              for (let i = 0; i < nodeCount; i++) {
                node = dagreGraph.node(dagreNodeKeys[i]);
                nodes[i].x = maxX - node.x;
                nodes[i].y = (Math.random() * maxX) / 10 - maxX / 20;
              }
            }

            this.sigmaGraph.startChematicaForce({});

            // FIXME: Use events from layout to communicate progress instead of initial delay hack
            const initialDelay: number = 100 + (Math.pow(nodes.length, 1.7) + edges.length) * 3e-2;
            setTimeout(() => {
              self.pendingLayout.emit(false);
            }, initialDelay);
            enableDrag = true;
            break;
          }
          default:
            this.pendingLayout.emit(false);
            throw (
              'Unknown layout type: ' + this.graphLayout
            ); /* tslint:disable-line:no-string-throw */
        }
      }
      this.setupDragNodes(enableDrag);
    }
  }

  public pointNode(nodeEvent: INodeEvent) {
    this.updateNodeColor(nodeEvent.node, nodeEvent.moleculeIdPriority);
  }

  public pointReaction(reaction: GraphReactionNode) {
    this.updateReactionPathColor(reaction);
  }

  public pointUniqueReaction(reaction: GraphReactionNode) {
    this.updateUniqueReactionColor(reaction);
  }

  public deselectAllNodes() {
    const graph = {
      nodes: this.sigmaGraph.graph.nodes(),
      edges: this.sigmaGraph.graph.edges(),
    };

    const pointedNodes = graph.nodes.filter((node) => node.pointed);
    for (const node of pointedNodes) {
      node.pointed = false;
    }

    const pointedEdges = graph.edges.filter((edge) => edge.pointed);
    for (const edge of pointedEdges) {
      edge.pointed = false;
    }

    this.setGraphColors(false);
  }

  public updateUniqueReactionColor(reactionNode: GraphReactionNode) {
    const graph = {
      nodes: this.sigmaGraph.graph.nodes(),
      edges: this.sigmaGraph.graph.edges(),
    };
    let sameReactions: any[] = [];
    // Find same reaction nodes
    sameReactions = graph.nodes.filter((graphReactionNode) => {
      return (
        isReactionNode(graphReactionNode) &&
        graphReactionNode.reaction.id === reactionNode.reaction.id
      );
    });

    // Point each reaction via standard function
    sameReactions.forEach((sameReactionNode) => {
      sameReactionNode.pointed = reactionNode.pointed;
      this.pointReaction(sameReactionNode);
    });
  }

  public updateReactionPathColor(reaction: GraphReactionNode) {
    const graph = {
      nodes: this.sigmaGraph.graph.nodes(),
      edges: this.sigmaGraph.graph.edges(),
    };
    const isPointed = reaction.pointed;
    const reactionMolecules: any = [];
    // Find reaction node
    reaction = graph.nodes.find((node) => node.id === reaction.id);
    reaction.pointed = isPointed;
    // Find reaction edges
    for (let i = 0; i < graph.edges.length; ++i) {
      if (graph.edges[i].target === reaction.id || graph.edges[i].source === reaction.id) {
        if (isUndefined(graph.edges[i].originColor) || graph.edges[i].originColor === '') {
          graph.edges[i].pointed = reaction.pointed;
        }
        if (graph.edges[i].source !== reaction.id) {
          reactionMolecules.push(graph.edges[i].source);
        } else {
          reactionMolecules.push(graph.edges[i].target);
        }
      }
    }

    // Find reaction molecules
    if (reactionMolecules.length > 0) {
      for (let i = 0; i < reactionMolecules.length; i++) {
        for (let n = 0; n < graph.nodes.length; ++n) {
          if (reactionMolecules[i] === graph.nodes[n].id) {
            if (isUndefined(graph.nodes[n].originColor) || graph.nodes[n].originColor === '') {
              if (reaction.pointed) {
                graph.nodes[n].pointed = true;
              } else {
                const parentReactionNode = graph.nodes.find(
                  (parentReaction) =>
                    parentReaction.type === GraphNodeType.REACTION &&
                    parentReaction.reaction_node.id === graph.nodes[n].parentReactionNodeId,
                );
                const childReactionNodes = graph.nodes.filter(
                  (_reaction) =>
                    _reaction.type === GraphNodeType.REACTION &&
                    _reaction.reaction_node.parent_reaction_node ===
                      graph.nodes[n].parentReactionNodeId &&
                    _reaction.reaction_node.parent_molecule === graph.nodes[n].molecule.id,
                );
                let childReactionNodesPointed: boolean = false;
                for (const childReactionNode of childReactionNodes) {
                  if (childReactionNode.pointed) {
                    childReactionNodesPointed = true;
                  }
                }
                const preTargetreactions = graph.nodes.filter(
                  (reactionNode) =>
                    reactionNode.type === GraphNodeType.REACTION &&
                    reactionNode.reaction_node.parent_reaction_node === null,
                );
                let preTargetReactionsPointed: boolean = false;
                for (const preTargetReaction of preTargetreactions) {
                  if (preTargetReaction.pointed) {
                    preTargetReactionsPointed = true;
                  }
                }
                if (
                  ((parentReactionNode ? !parentReactionNode.pointed : true) &&
                    !childReactionNodesPointed &&
                    !graph.nodes[n].isStartingMolecule) ||
                  (graph.nodes[n].isStartingMolecule && !preTargetReactionsPointed) ||
                  !graph.nodes[n].parentReactionNodeId ||
                  !graph.nodes[n].childReactionNodeId
                ) {
                  graph.nodes[n].pointed = false;
                }
              }
            }
            reactionMolecules[i] = graph.nodes[n];
          }
        }
      }
    }
    this.setGraphColors(false);
    this.sigmaGraph.refresh();
  }

  public updateNodeColor(
    graphNode: GraphMoleculeNode | GraphReactionNode,
    moleculeIdPriority?: boolean,
  ) {
    const graph = {
      nodes: this.sigmaGraph.graph.nodes(),
      edges: this.sigmaGraph.graph.edges(),
    };

    const nodes: any = [];
    if (moleculeIdPriority) {
      const graphMoleculeNodes: GraphMoleculeNode[] = graph.nodes.filter(
        (_graphNode) => _graphNode.type !== GraphNodeType.REACTION,
      );
      for (const graphMoleculeNode of graphMoleculeNodes) {
        if (graphMoleculeNode.molecule.id === graphNode.molecule.id) {
          graphMoleculeNode.pointed = !graphMoleculeNode.pointed;
          nodes.push(graphMoleculeNode);
        }
      }
    } else {
      const pointNode = graph.nodes.find((_node) => _node.id === graphNode.id);

      if (!isUndefined(pointNode)) {
        pointNode.pointed = !pointNode.pointed;
        nodes.push(pointNode);
      }
    }

    if (graphNode.type === GraphNodeType.MOLECULE || graphNode.type === GraphNodeType.STRUCTURE) {
      setMoleculeColors(ResultsView.GRAPH, 'default', nodes, false);
    } else {
      setReactionColors(ResultsView.GRAPH, this.colorReactionDiamonds, nodes, false);
    }
    this.sigmaGraph.refresh();
  }

  public setGraphColors(printView: boolean) {
    if (this.colorReactionDiamonds && this.colorReactionArrows) {
      const nodes = this.sigmaGraph.graph.nodes();
      const edges = this.sigmaGraph.graph.edges();

      setReactionColors(ResultsView.GRAPH, this.colorReactionDiamonds, nodes, printView);
      setMoleculeColors(ResultsView.GRAPH, 'default', nodes, printView);
      setEdgeColors(ResultsView.GRAPH, this.colorReactionArrows, edges, printView);

      this.sigmaGraph.refresh();
    }
  }

  public resetGraphPosition() {
    this.camera.ratio = 1;
    this.camera.x = 0;
    this.camera.y = 0;
  }

  public setGraphRatio(zoomType: ZoomType) {
    setGraphRatio(ResultsView.GRAPH, zoomType, this.camera);
  }

  private destroyGraph() {
    if (this.sigmaGraph) {
      this.sigmaGraph.stopChematicaForce();
      this.sigmaGraph.kill();
      this.sigmaGraph = undefined;
    }
  }

  private bindEvents() {
    const self = this;
    // Externalize click events:
    this.sigmaGraph.bind('clickNode', (eventNode) => {
      if (eventNode.data.captor.ctrlKey || eventNode.data.captor.metaKey) {
        self.clipboardService.addNode(self.getNodeWithPositionalData(eventNode));
      } else {
        if (!this.nodeIsMoved) {
          this.onNodeSelectedClick.emit(eventNode.data.node);
        }
      }
    });

    this.sigmaGraph.bind('overNode', (eventNode) => {
      const node: any = self.getNodeWithPositionalData(eventNode);
      this.clipboardService.pointNode(node);
      self.changeCursor.emit('pointer');
      if (isReactionNode(node)) {
        if (node.reaction_node.paths.length === 1) {
          this.emitNodeOverOutEvent(node, true);
        }
        this.highlightSameReactions(node, true);
      } else {
        if (node.paths.length === 1) {
          this.emitNodeOverOutEvent(node, true);
        }
        this.highlightSameMolecules(node, true);
      }
      if (!this.nodeOverTimeout) {
        this.nodeOverTimeout = setTimeout(() => {
          this.onNodeSelectedOver.emit(node);
        }, this.appConstantsService.nodePopupAppearDelay);
      }

      if (this.nodeOutTimeout) {
        clearTimeout(this.nodeOutTimeout);
        this.nodeOutTimeout = undefined;
      }
    });

    this.sigmaGraph.bind('outNode', (eventNode) => {
      const node: any = self.getNodeWithPositionalData(eventNode);
      self.changeCursor.emit('default');
      if (isReactionNode(node)) {
        this.highlightSameReactions(node, false);
      } else {
        this.highlightSameMolecules(node, false);
      }
      if (node.highlightAsPathwayPart) {
        this.emitNodeOverOutEvent(node, false);
      }
      if (this.nodeOverTimeout) {
        clearTimeout(this.nodeOverTimeout);
        this.nodeOverTimeout = undefined;
      }

      if (!this.nodeOutTimeout) {
        this.nodeOutTimeout = setTimeout(() => {
          this.dissmissNodePopup();
        }, this.appConstantsService.nodePopupDismissDelay);
      }
    });

    this.sigmaGraph.bind('clickStage', () => {
      this.dissmissNodePopup();
    });

    if (isTouchDevice()) {
      // replaced overNode with doubleClickNode event to show popup
      this.sigmaGraph.bind('clickNode', (eventNode) => {
        this.onNodeSelectedClick.emit(eventNode.data.node);
      });
    }

    this.camera.bind('coordinatesUpdated', (eventNode) => {
      if (
        this.currentRatio !== eventNode.target.ratio &&
        eventNode.target.ratio >= this.labelHideThreshold
      ) {
        this.currentRatio = eventNode.target.ratio;
        if (this.moleculeLabel !== 'none') {
          this.hiddenMoleculeLabel = this.moleculeLabel;
        }
        if (this.reactionLabel !== 'none') {
          this.hiddenReactionLabel = this.reactionLabel;
        }
        this.moleculeLabel = 'none';
        this.reactionLabel = 'none';
      } else if (
        this.currentRatio !== eventNode.target.ratio &&
        eventNode.target.ratio < this.labelHideThreshold
      ) {
        this.currentRatio = eventNode.target.ratio;
        this.moleculeLabel = this.hiddenMoleculeLabel;
        this.reactionLabel = this.hiddenReactionLabel;
      }
    });
  }

  private dissmissNodePopup() {
    if (
      this.graphNodePopupService.isNodePopupPresent &&
      !this.graphNodePopupService.isCursorWithinPopupBounds &&
      this.graphNodePopupService.hidePopupOnMouseOut
    ) {
      this.graphNodePopupService.disposeNodeOverlay();
    }
  }

  private emitNodeOverOutEvent(node: GraphReactionNode | GraphMoleculeNode, highlight: boolean) {
    const event = {
      node: node,
      highlight: highlight,
    };
    this.onReactionOverAndOut.emit(event);
  }

  private highlightSameReactions(graphReactionNode: GraphReactionNode, highlight: boolean) {
    this.sigmaGraph.graph
      .nodes()
      .filter((graphNode: GraphReactionNode) => {
        return (
          isReactionNode(graphNode) &&
          graphNode.reaction.id === graphReactionNode.reaction.id &&
          graphNode.id !== graphReactionNode.id
        );
      })
      .map((reactionNode) => (reactionNode.highlightAsSame = highlight));

    this.setGraphColors(false);
    this.sigmaGraph.refresh();
  }

  private highlightSameMolecules(graphMoleculeNode: GraphMoleculeNode, highlight: boolean) {
    this.sigmaGraph.graph
      .nodes()
      .filter((graphNode: GraphMoleculeNode) => {
        return (
          isMoleculeNode(graphNode) &&
          graphNode.molecule.id === graphMoleculeNode.molecule.id &&
          graphNode.id !== graphMoleculeNode.id
        );
      })
      .map((moleculeNode) => (moleculeNode.highlightAsSame = highlight));

    this.setGraphColors(false);
    this.sigmaGraph.refresh();
  }

  private markNodeStartDrag(x: number, y: number, timestamp: number) {
    this.nodeDragDelta.startX = x;
    this.nodeDragDelta.startY = y;
    this.nodeDragDelta.startTime = timestamp;
  }

  private markNodeDragEnd(x: number, y: number, timestamp: number) {
    this.nodeDragDelta.endX = x;
    this.nodeDragDelta.endY = y;
    this.nodeDragDelta.endTime = timestamp;
  }

  private isNodeAccidentallyMoved(): boolean {
    const deltaX: number = Math.abs(this.nodeDragDelta.startX - this.nodeDragDelta.endX);
    const deltaY: number = Math.abs(this.nodeDragDelta.startY - this.nodeDragDelta.endY);
    const deltaTime: number = this.nodeDragDelta.endTime - this.nodeDragDelta.startTime;
    return (
      deltaTime < ACCIDENTAL_NODE_DRAG_SENSITIVITY_TIME &&
      deltaX < ACCIDENTAL_NODE_DRAG_SENSITIVITY &&
      deltaY < ACCIDENTAL_NODE_DRAG_SENSITIVITY
    );
  }

  private setupDragNodes(enable: boolean = true) {
    if (enable) {
      // Initialize dragging nodes inside sigma component:
      const dragListener = sigma.plugins.dragNodes(this.sigmaGraph, this.sigmaGraph.renderers[0]);
      const self = this;
      if (dragListener) {
        dragListener.bind('drag', (e) => {
          this.nodeIsMoved = true;
          self.changeCursor.emit('move');
        });

        dragListener.bind('startdrag', (e) => {
          this.nodeIsMoved = false;
          this.markNodeStartDrag(e.data.captor.x, e.data.captor.y, Date.now());
        });

        dragListener.bind('dragend', (e) => {
          this.markNodeDragEnd(e.data.captor.x, e.data.captor.y, Date.now());
          if (this.isNodeAccidentallyMoved()) {
            // We interpret user's intention as click (resulting in unintentional drag).
            this.onNodeSelectedClick.emit(e.data.node);
          } else {
            this.changeCursor.emit('pointer');
          }
          this.updateSigmaSettings({ enableHovering: false });
        });
      }
    } else {
      sigma.plugins.killDragNodes(this.sigmaGraph);
    }
  }

  // To change sigma settings use this method. It keeps sigma graph configuration
  // and the list of actual settings in sync.
  private updateSigmaSettings(settings: { [key: string]: any }) {
    const applyInSigma = this.sigmaGraph
      ? (key, value) => this.sigmaGraph.settings(key, value)
      : (key, value) => undefined;
    for (const key of Object.keys(settings)) {
      this.sigmaGraphSettings[key] = settings[key];
      // Sigmajs documentation https://github.com/jacomyal/sigma.js/wiki/Settings:-How-Settings-Work-in-Sigma
      // states that "And all the objects to search in can be dynamically changed, so this function never
      // clones any object and just keeps reference to them.". As we bind this.sigmaGraphSettings
      // whith the created this.sigmaGraph any modifications of it's fields should be visible to sigmaGraph.
      // In fact they are not hence this function.
      applyInSigma(key, settings[key]);
    }
  }

  /**
   * Set the size of nodes (molecules/reactions) relative to the square root of nodeCount-th part of canvas surface.
   * Updates edges thickness proportionally.
   * The size set suits as a base value which could be adjusted per node according to the other chemical properties.
   * @param {number} nodeCount Total number of nodes in the graph.
   * @param {number} relativeSize 1 means that circle node will fill up it's part of surface.
   */
  private setRelativeBaseNodeSize(nodeCount: number, relativeSize: number = 0.1) {
    const r = this.elRef.nativeElement.getBoundingClientRect();
    const width = r.right > r.left ? r.right - r.left : 500;
    const height = r.bottom > r.top ? r.bottom - r.top : 500;
    const newSize = Math.min(
      Math.max(Math.sqrt((width * height) / nodeCount) * relativeSize, 3),
      25,
    );

    this.updateSigmaSettings({
      minNodeSize: newSize / 4,
      maxNodeSize: newSize,
      minEdgeSize: newSize / 3,
      maxEdgeSize: newSize / 3,
      scale: Math.min(newSize / 30, 0.09), // important for drag none scaling
    });
  }

  private resetGraph(graph: any) {
    this.sigmaGraph.graph.clear();
    this.sigmaGraph.graph.read({
      nodes: graph.nodes,
      edges: graph.edges,
    });

    this.setRelativeBaseNodeSize(graph.nodes.length);
  }

  private getNodeWithPositionalData(event) {
    const node: any = event.data.node;
    const rendererName: string = event.data.renderer.options.prefix;
    node.chGraphViewRect = this.elRef.nativeElement.getBoundingClientRect();
    node.chPositionInGraphViewRect = {
      x: event.data.node[`${rendererName}x`],
      y: event.data.node[`${rendererName}y`],
    };
    return node;
  }

  private showReactionLabels() {
    if (!!this.reactionLabel) {
      setReactionLabels(ResultsView.GRAPH, this.reactionLabel, this.sigmaGraph.graph.nodes());
      this.updateSettingsAndRefresh();
    }
  }

  private showMoleculeLabels() {
    if (!!this.reactionLabel) {
      setMoleculeLabels(ResultsView.GRAPH, this.moleculeLabel, this.sigmaGraph.graph.nodes());
      this.updateSettingsAndRefresh();
    }
  }

  private updateSettingsAndRefresh() {
    this.updateSigmaSettings({
      drawLabels: true,
      chNodeTypeIcons: this.nodeTypeIcons,
    });
    this.sigmaGraph.refresh();
  }

  private displayShopAvailability(setting: boolean) {
    const nodes = this.sigmaGraph.graph.nodes();
    setShopAvailabilityHints(nodes, setting);
  }

  private subscribeToSigmaGraphChanges() {
    this.sigmaGraphPresenceSubject.pipe(take(1)).subscribe(() => {
      this.unsubscribeFromFrontendStorage();
      this.subscribeToFrontendStorage();
    });
  }

  private subscribeToFrontendStorage() {
    this.frontendStorageServiceSubscriptions = new Subscription();
    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.layout.subscribe(
        (layout: GraphLayout) => {
          if (this.graphLayout !== layout) {
            this.previousLayout = this.graphLayout ? this.graphLayout : layout;
            this.graphLayout = layout;
            this.layout();
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.moleculeLabel.subscribe(
        (moleculeLabel: MoleculeLabelType) => {
          if (this.moleculeLabel !== moleculeLabel) {
            if (this.currentRatio < this.labelHideThreshold) {
              this.moleculeLabel = moleculeLabel;
            }
            this.hiddenMoleculeLabel = moleculeLabel;
            this.showMoleculeLabels();
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.colorReactionDiamonds.subscribe(
        (colorReactionDiamonds) => {
          if (this.colorReactionDiamonds !== colorReactionDiamonds) {
            this.colorReactionDiamonds = colorReactionDiamonds;
            this.setGraphColors(false);
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.colorReactionArrows.subscribe(
        (colorReactionArrows) => {
          if (this.colorReactionArrows !== colorReactionArrows) {
            this.colorReactionArrows = colorReactionArrows;
            this.setGraphColors(false);
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.reactionLabel.subscribe(
        (reactionLabel: ReactionLabelType) => {
          if (this.reactionLabel !== reactionLabel) {
            this.hiddenReactionLabel = reactionLabel;
            this.reactionLabel = reactionLabel;
            this.showReactionLabels();
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.nodeTypeIcons.subscribe(
        (nodeTypeIcons: boolean) => {
          if (this.nodeTypeIcons !== nodeTypeIcons) {
            this.nodeTypeIcons = nodeTypeIcons;
            this.showMoleculeLabels();
            this.showReactionLabels();
          }
        },
      ),
    );

    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.moleculeScaling.subscribe(
        (scaling: MoleculeScalingType) => {
          if (this.moleculeScaling !== scaling) {
            this.moleculeScaling = scaling;
            setMoleculeScaling(
              ResultsView.GRAPH,
              this.moleculeScaling,
              this.sigmaGraph.graph.nodes(),
            );
            this.sigmaGraph.refresh();
          }
        },
      ),
    );
    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.shopAvailability.subscribe(
        (shopAvailability: boolean) => {
          if (this.shopAvailability !== shopAvailability) {
            this.shopAvailability = shopAvailability;
            this.displayShopAvailability(this.shopAvailability);
            this.sigmaGraph.refresh();
          }
        },
      ),
    );
    this.frontendStorageServiceSubscriptions.add(
      this.analysisService.analysisSettingsBehaviorSubjects.pathDirection.subscribe((direction) => {
        this.pathDirection = direction;
        this.layout();
      }),
    );
  }

  private unsubscribeFromFrontendStorage() {
    if (
      this.frontendStorageServiceSubscriptions &&
      !this.frontendStorageServiceSubscriptions.closed
    ) {
      this.frontendStorageServiceSubscriptions.unsubscribe();
    }
    this.frontendStorageServiceSubscriptions = undefined;
  }
}
