<div class="ch-molecule-editor-dialog">
  <div class="ch-dialog-top">
    <span>
      {{ getTitle() }}
    </span>
    <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
  </div>

  <div class="ch-dialog-body">
    <ch-ketcher-editor
      class="molecule-editor"
      [isAnotherKetcherInstance]="true"
      #moleculeEditor
    ></ch-ketcher-editor>
    <textarea
      matInput
      id="hidden-input-editor"
      class="hidden-field"
      [formControl]="hiddenFormControl"
    ></textarea>
    <div *ngIf="searchLoading" class="loading-indicator">
      <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
    </div>
    <div class="filter-bottom-section">
      <div class="ch-filter-radio-section">
        <mat-radio-group [(ngModel)]="structureFilterType">
          <mat-radio-button class="ch-filter-radio" color="primary" value="Drawn"
            >As drawn</mat-radio-button
          >
          <mat-radio-button class="ch-filter-radio" color="primary" value="Structure"
            >As Substructure</mat-radio-button
          >
          <div class="ch-radio-section-with-input">
            <mat-radio-button color="primary" class="ch-filter-radio-with-input" value="Similar"
              >Similar
            </mat-radio-button>
            <mat-form-field
              class="ch-filter-input ch-form-field ch-text-field"
              appearance="outline"
            >
              <input type="number" matInput [(ngModel)]="similarity" min="0" max="1" step=".1" />
            </mat-form-field>
          </div>
        </mat-radio-group>
      </div>
      <div class="filter-molecule-label">
        <mat-form-field appearance="outline" class="filter-category ch-form-field ch-select-field">
          <mat-select [(ngModel)]="filterShownCategory">
            <mat-option value="Limit to">Limit to</mat-option>
            <mat-option value="Exclude">Exclude</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="btn-section">
      <button
        *ngIf="isEditFilterMode"
        class="ch-outline-btn outline-btn mat-button ch-primary-button-font remove-filter"
        mat-button
        buttonOutline="primary"
        (click)="removeFilter()"
      >
        Remove This Filter
      </button>
      <button
        class="ch-primary-button-font"
        mat-raised-button
        color="primary"
        [disabled]="!canAddMolecules() || isMoleculeEdited"
        (click)="getSmilesAndSmartsForFilter()"
      >
        {{ getFilterButtonTitle() }}
      </button>
      <button
        class="ch-primary-button-font"
        mat-raised-button
        color="primary"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
    </div>
    <div
      *ngIf="errorMessage"
      [@fadeInAnimation]
      [@fadeOutAnimation]
      class="error-message ch-bg-warn"
    >
      {{ errorMessage }}
    </div>
    <div
      *ngIf="isDuplicatesRemovedNoticeDisplayed"
      [@fadeInAnimation]
      [@fadeOutAnimation]
      class="error-message ch-bg-accent-strong ch-clr-grey"
    >
      Some SMILES were duplicated and have been removed
    </div>
  </div>
</div>
