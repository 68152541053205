<div
  class="nav-bar"
  [class.top-bar-height-no-breadcrumb]="hideBreadCrumb()"
  [class.top-bar-height]="toolBarDoesNotExist"
  [class.synthia-lite-top-bar]="isSynthiaLiteEnabled"
  [class.z-index-to-cover-search-box]="isCartWidgetOpened"
>
  <div
    class="ch-top-toolbar"
    [class.ch-bg-primary]="isSynthiaLiteEnabled"
    [class.ch-bg-default-blue]="!isSynthiaLiteEnabled"
    [class.z-index-to-cover-breadcrumb-nav]="isCartWidgetOpened"
  >
    <div class="section">
      <div class="left-section">
        <div
          class="logo"
          [class.synthia-lite-logo]="isSynthiaLiteEnabled"
          (click)="onSynthiaLogoClick()"
          [class.logo-disabled]="routeStateService.stopRoutingToHome.value"
          synthiaLogo
          [logoCountry]="appStateService.country | async"
        ></div>
        <div
          class="logo-text"
          (click)="onSynthiaLogoClick()"
          [class.synthia-lite-logo-text]="isSynthiaLiteEnabled"
          [innerHtml]="logoText"
        ></div>
        <div class="banner-buttons" *ngIf="isToolbarActivated && isCustomerPolicyAccepted">
          <button
            class="ch-action-icon-hover"
            mat-icon-button
            (click)="onNewAnalysisClick()"
            synthiaLiteDisabledFeature
            [disabledInSynthiaLite]="remainingAnalysisCount === 0 || isPlanExpired"
            [isShowExpiryMessage]="true"
            (callWhenEnabled)="onNewAnalysisClick()"
          >
            <mat-icon
              class="new-analysis-btn"
              svgIcon="ch:new-analysis"
              matTooltip="New Analysis"
              matTooltipClass="ch-tooltip"
              matTooltipPosition="above"
            >
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="right-section-container">
        <div
          *ngIf="isSynthiaLiteEnabled && isPlanSubscribed"
          class="expiry-info"
          [matTooltip]="planExpireInfoTooltip"
          [matTooltipClass]="
            remainingAnalysisCount === null ? 'ch-tooltip no-analysis-count-tooltip' : 'ch-tooltip'
          "
        >
          <mat-icon class="analyses-flask-icon" svgIcon="ch:analyses-flask-icon"> </mat-icon>
          <span class="count">
            <ng-container *ngIf="remainingAnalysisCount !== null; else infinityIcon">{{
              remainingAnalysisCount
            }}</ng-container>
          </span>
          <ng-template #infinityIcon>
            <mat-icon class="infinity-icon" svgIcon="ch:infinity-icon"> </mat-icon>
          </ng-template>
        </div>
        <div class="plan-info-section" *ngIf="isSynthiaLiteEnabled && isPlanSubscribed">
          <button
            class="upgrade-button"
            mat-button
            [buttonOutline]="'white'"
            [routerLink]="['/subscription-plans']"
          >
            Upgrade Options
          </button>
        </div>
        <div class="right-section">
          <div class="right-top-row">
            <div
              class="session-timeout"
              [class.ch-clr-accent-strong]="isSessionExpiringSoon"
              *ngIf="isCustomerPolicyAccepted && isPlanSubscribed && !isSynthiaLiteEnabled"
              matTooltip="Time left until automatic logout. This will refresh during active use"
              matTooltipClass="ch-tooltip"
              matTooltipShowDelay="750"
            >
              Session ends in
              <span *ngIf="sessionHoursLeft > 0"> {{ sessionHoursLeft }} h. </span>

              <span *ngIf="sessionMinutesLeft">
                {{
                  sessionMinutesLeft <= 1 && sessionHoursLeft <= 0
                    ? '< ' + sessionMinutesLeft
                    : sessionMinutesLeft
                }}
                min.
              </span>

              <span *ngIf="!sessionMinutesLeft">
                calculating session duration...
              </span>
            </div>
          </div>
          <div class="right-bottom-row">
            <div class="button-section">
              <div
                class="knowledge-center"
                id="knowledge-center-icon"
                [matTooltip]="appConstantsService.newsTutorials"
                matTooltipClass="ch-tooltip"
                matTooltipShowDelay="750"
                matTooltipPosition="below"
                *ngIf="isLearningCenterEnabled"
              >
                <mat-icon class="header-icon" svgIcon="ch:learning"></mat-icon>
              </div>
              <div
                class="header"
                [matTooltip]="appConstantsService.helpCenter"
                matTooltipClass="ch-tooltip"
                matTooltipShowDelay="750"
                matTooltipPosition="below"
                [matMenuTriggerFor]="helpContentMenu"
                (menuClosed)="isHelpMenuOpened = false"
                (menuOpened)="isHelpMenuOpened = true"
                *ngIf="isPlanSubscribed"
              >
                <mat-icon class="header-icon" svgIcon="ch:help"></mat-icon>
              </div>
              <div *ngIf="isAllowToShowShoppingListIcon">
                <div
                  class="header"
                  [matTooltip]="appConstantsService.shopingList"
                  matTooltipClass="ch-tooltip"
                  matTooltipShowDelay="750"
                  matTooltipPosition="below"
                >
                  <mat-icon
                    *ngIf="cartItemCount === 0; else iconWithBadge"
                    class="header-icon"
                    svgIcon="ch:shopping-list-icon"
                    (click)="toggleCart()"
                  ></mat-icon>
                </div>
                <ng-template #iconWithBadge>
                  <mat-icon
                    class="header-icon"
                    [matBadge]="cartItemCount > 99 ? '99+' : cartItemCount"
                    svgIcon="ch:shopping-list-icon"
                    (click)="toggleCart()"
                  ></mat-icon>
                </ng-template>
                <ch-cart-widget #cartWidget> </ch-cart-widget>
              </div>

              <div class="user-menu" *ngIf="isCustomerPolicyAccepted">
                <span
                  class="user-name"
                  [matTooltip]="getTooltip(user.username, maxNameLength)"
                  matTooltipClass="ch-tooltip full-width-tooltip"
                >
                  {{ username }}
                </span>
                <button
                  class="toolbar-menu-btn"
                  [matTooltip]="appConstantsService.userMenu"
                  matTooltipClass="ch-tooltip"
                  matTooltipShowDelay="750"
                  matTooltipPosition="below"
                  mat-button
                  [matMenuTriggerFor]="menuUser"
                >
                  <mat-icon
                    class="top-toolbar-btn"
                    [class.black-button]="isSynthiaLiteEnabled"
                    [class.white-button]="!isSynthiaLiteEnabled"
                    >keyboard_arrow_down</mat-icon
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO auxillary routing should enabled when analysisId exist-->
    <mat-menu #menuUser="matMenu" class="user-menu-links">
      <ng-container *ngIf="isPlanSubscribed; else signOutLink">
        <ng-container *ngIf="analysisId; else noAnalysisIdMenuLinks">
          <a (click)="navigateWithAnalysisId(['account-settings'])">
            <button mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>Account settings</span>
            </button>
          </a>
          <a
            (click)="navigateWithAnalysisId(['user-management'])"
            *ngIf="isUserManagementActivated"
          >
            <button mat-menu-item>
              <mat-icon svgIcon="ch:user-management"></mat-icon>
              <span>User Management</span>
            </button>
          </a>
          <!-- <a (click)="navigateWithAnalysisId(['permission-tokens'])">
            <button mat-menu-item class="permission-tokens-btn">
              <mat-icon>swap_vertical_circle</mat-icon>
              <span>Permission tokens</span>
            </button>
          </a> -->
          <button
            (click)="routeStateService.navigateToPrivacyPage(false)"
            mat-menu-item
            class="user-manual-btn"
          >
            <mat-icon>security</mat-icon>
            <span>Privacy policy</span>
          </button>
        </ng-container>
        <ng-template #noAnalysisIdMenuLinks>
          <a [routerLink]="['/home/account-settings']">
            <button mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>Account settings</span>
            </button>
          </a>
          <a [routerLink]="['/home/user-management']" *ngIf="isUserManagementActivated">
            <button mat-menu-item>
              <mat-icon svgIcon="ch:user-management"></mat-icon>
              <span>User Management</span>
            </button>
          </a>
          <!-- <a [routerLink]="['/permission-tokens']">
            <button mat-menu-item class="permission-tokens-btn">
              <mat-icon>swap_vertical_circle</mat-icon>
              <span>Permission tokens</span>
            </button>
          </a> -->
          <button
            (click)="routeStateService.navigateToPrivacyPage(false)"
            mat-menu-item
            class="user-manual-btn"
          >
            <mat-icon>security</mat-icon>
            <span>Privacy policy</span>
          </button>
        </ng-template>
        <!-- <button mat-menu-item class="allowances-btn" (click)="onAllowancesClick()">
          <mat-icon>account_balance_wallet</mat-icon>
          <span>Allowances</span>
        </button> -->
        <!-- FIXME: Endpoints not ready yet  -->
        <button
          *ngIf="isAllowedToUploadInventory"
          mat-menu-item
          synthiaLiteDisabledFeature
          class="terms-btn"
          (click)="onUserInventoryClick()"
        >
          <mat-icon>cloud_upload</mat-icon>
          <span>Upload Inventory</span>
        </button>
        <button mat-menu-item class="terms-btn" (click)="showAbout()">
          <mat-icon>info</mat-icon>
          <span>About</span>
        </button>
        <ng-container *ngTemplateOutlet="signOutLink"></ng-container>
      </ng-container>
      <ng-template #signOutLink>
        <button mat-menu-item class="sign-out-btn" [routerLink]="['/sign-out']">
          <mat-icon>directions</mat-icon>
          <span>Sign Out</span>
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #helpContentMenu="matMenu">
      <ng-container *ngIf="analysisId; else noAnalysisIdHelpMenuLinks">
        <a
          [href]="trainingSupportLink"
          *ngIf="isTrainingSupportLinkAvailable()"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button mat-menu-item class="user-manual-btn">
            <mat-icon>support</mat-icon>
            <span>Learning center</span>
          </button>
        </a>
        <a (click)="navigateWithAnalysisId(['tech-support'])">
          <button mat-menu-item class="user-manual-btn">
            <mat-icon>contact_support</mat-icon>
            <span>Bug/Question</span>
          </button>
        </a>
        <!-- INFO: Hidden in version v5.0.0 -->
        <!-- <a href="https://synthiaonline.ideas.aha.io/" target="_blank" rel="noreferrer noopener">
        <button mat-menu-item class="user-manual-btn">
          <mat-icon class="ch-icon-lightbulb icon-30 ch-clr-secondary" svgIcon="ch:lightbulb"></mat-icon>
          <span class="ch-clr-secondary">Idea</span>
        </button>
      </a> -->
      </ng-container>
      <ng-template #noAnalysisIdHelpMenuLinks>
        <a [href]="trainingSupportLink" *ngIf="isTrainingSupportLinkAvailable()" target="_blank">
          <button mat-menu-item class="user-manual-btn">
            <mat-icon>support</mat-icon>
            <span>Learning center</span>
          </button>
        </a>
        <a [routerLink]="['/tech-support']">
          <button mat-menu-item class="user-manual-btn">
            <mat-icon>contact_support</mat-icon>
            <span>Bug/Question</span>
          </button>
        </a>
        <!-- INFO: Hidden in version v5.0.0 -->
        <!-- <a href="https://synthiaonline.ideas.aha.io/" target="_blank" rel="noreferrer noopener">
        <button mat-menu-item class="user-manual-btn">
          <mat-icon class="ch-icon-lightbulb icon-30 ch-clr-secondary" svgIcon="ch:lightbulb"></mat-icon>
          <span class="ch-clr-secondary">Idea</span>
        </button>
      </a> -->
      </ng-template>
    </mat-menu>
  </div>
  <div class="breadcrumb" [class.hide-breadcrumb]="hideBreadCrumb()">
    <ch-breadcrumb #breadcrumb></ch-breadcrumb>
  </div>
</div>
