import { isString } from '../../shared/components/utils';

export class ActiveCartItemMaterial {
  public cas_number: string;
  public material_name: string;
  public name_suffix: string;
  public brand: string;
  public material_uom: string;
  public is_sial: string;
  public image: {
    smallUrl: string;
    mediumUrl: string;
    largeUrl: string;
  };

  constructor(data: any, ecommAPIUrl: string) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    this.cas_number = jsonData.cas_number;
    this.material_name = jsonData.material_name;
    this.name_suffix = jsonData.name_suffix;
    this.brand = jsonData.brand;
    this.material_uom = jsonData.material_uom;
    this.is_sial = jsonData.is_sial;
    if (jsonData.is_sial === '0') {
      this.image = {
        smallUrl: jsonData.image,
        mediumUrl: jsonData.image,
        largeUrl: jsonData.image,
      };
    } else {
      this.image = {
        smallUrl: ecommAPIUrl + jsonData.image?.smallUrl,
        mediumUrl: ecommAPIUrl + jsonData.image?.mediumUrl,
        largeUrl: ecommAPIUrl + jsonData.image?.largeUrl,
      };
    }
  }
}
