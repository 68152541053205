<div class="ch-user-inventory">
  <div class="ch-dialog-top">
    <span>
      Upload Inventory Data
      <mat-icon (click)="closeDialog()" class="btn-close icon-18">close</mat-icon>
    </span>
  </div>
  <div class="ch-dialog-body">
    <div class="hashing-tool-section" *ngIf="!fileSelected">
      <a
        class="learn-more-link section-link"
        href="https://www.learningcenter.synthiaonline.com/us-en/home/usermanuals/custom-inventory-management.html"
        target="_blank"
        rel="noreferrer noopener"
        >Learn more about uploading inventory data</a
      >
      <div class="hashing-tool-links-section">
        <h5>Download Hashing Tool:</h5>
        <div class="hashing-tool-links" *ngIf="!loadingHashingTools">
          <span class="section-link" *ngFor="let hashingTool of hashingTools"
            ><a target="_blank" rel="noopener noreferrer" href="{{ hashingTool.link }}">
              {{ hashingTool.os }}
            </a></span
          >
        </div>
      </div>
    </div>
    <div class="inventory-upload">
      <label>
        <input
          #fileInput
          class="file-input"
          type="file"
          accept=".csv"
          [disabled]="isUploadBtnDisabled()"
          (change)="onFileSelected($event.target.files)"
          multiple
          title="Upload your inventory data"
        />
      </label>
    </div>
    <ng-container *ngIf="loadingUserInventories">
      <div class="inventory-loading">
        <div class="center">
          <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!fileSelected && !loadingUserInventories; else fileSelectedView">
      <p class="choose-file-label">
        Choose File
        <mat-icon
          class="grey-icon action-icon"
          [matTooltip]="appConstantsService.inventoryMaxSizeTooltip"
          matTooltipPosition="above"
          matTooltipClass="ch-tooltip"
        >
          info_outline
        </mat-icon>
      </p>
      <div
        #fileDropArea
        class="inventory-drop-area"
        [class.file-drag]="fileDraggedOver"
        [class.ch-bg-secondary-vlight]="fileDraggedOver"
        [class.ch-bdr-secondary-light]="fileDraggedOver"
      >
        <div class="drop-browse" [class.no-events]="fileDraggedOver">
          <ng-container *ngIf="!fileDraggedOver; else fileDragged">
            <span>Drop file here or</span>
            <button mat-flat-button color="primary" class="browse-btn" (click)="fileInput.click()">
              Browse
            </button>
            <p *ngIf="incorrectFileTypeError" class="ch-clr-warn">
              Incorrect file format.
            </p>
          </ng-container>
          <ng-template #fileDragged>
            Drop file here
            <div class="drop-area-bg" *ngIf="fileDraggedOver">
              <mat-icon class="ch-icon-upload drop-area-icon" svgIcon="ch:upload"></mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #fileSelectedView>
    <ng-container *ngIf="!loadingUserInventories">
      <div class="inventory-upload-config">
        <div class="file-details">
          <h4>
            File Details:
            <mat-icon
              class="change-file"
              [class.ch-clr-grey]="isUploadBtnDisabled()"
              [class.ch-clr-secondary]="!isUploadBtnDisabled()"
              matTooltip="Click to select different file"
              matTooltipShowDelay="500"
              matTooltipClass="ch-tooltip"
              (click)="fileInput.click()"
              [inline]="true"
              >mode_edit</mat-icon
            >
          </h4>
          <ul class="details-list">
            <li>
              {{ fileName }}
            </li>
            <li>
              {{ fileHeader }}
            </li>
          </ul>
        </div>
        <div class="inventory-field-form">
          <div class="inventory-field-map">
            <div class="inventory-field" *ngFor="let field of objectKeys(fieldsMap)">
              <div class="field-name">{{ fieldsMap[field].fieldName }}:</div>
              <div class="field-input">
                <mat-form-field
                  class="inventory-input ch-form-field ch-select-field ch-select-field-48-px ch-hint-input"
                  [class.mat-field-no-padding]="
                    shouldHidePaddingInFormField(fieldsMap[field].mappedFieldFormControl)
                  "
                  appearance="outline"
                  color="primary"
                >
                  <mat-select
                    [formControl]="fieldsMap[field].mappedFieldFormControl"
                    required
                    placeholder="Select field"
                  >
                    <mat-option *ngFor="let column of fileColumnOptions" [value]="column">
                      {{ column }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="fieldsMap[field].mappedFieldFormControl.hasError('required')">
                    This field is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-checkbox
            class="ch-text-gray"
            [(ngModel)]="saveFieldMapping"
            color="primary"
            [disabled]="isUploadBtnDisabled()"
          >
            Save mapping for later uploads
          </mat-checkbox>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <div class="ch-dialog-bottom" *ngIf="fileSelected">
    <div class="ch-dialog-controls">
      <button
        class="action-btn close-btn ch-outline-btn"
        mat-button
        [buttonOutline]="'primary'"
        (click)="closeDialog()"
      >
        {{ uploadProgress > 0 ? 'Cancel' : 'Close' }}
      </button>
      <button
        mat-raised-button
        color="primary"
        class="action-btn upload-btn"
        [disabled]="isUploadBtnDisabled()"
        (click)="onUploadClick()"
      >
        <mat-progress-spinner
          *ngIf="uploadProgress > 0"
          [strokeWidth]="2"
          [diameter]="20"
          [value]="uploadProgress"
          class="ch-starting-spinner"
        ></mat-progress-spinner>
        <ng-container
          *ngIf="isUploadBtnDisabled() && !uploadProgress; else uploadingOrDefaultBtnText"
        >
          Please Wait...
        </ng-container>
        <ng-template #uploadingOrDefaultBtnText>
          {{ uploadProgress > 0 ? 'Uploading...' : 'Load Inventory' }}
        </ng-template>
      </button>
    </div>
  </div>
</div>
