import { DatePipe } from '@angular/common';
import { isString } from 'src/app/shared/components/utils';
import { ProductDetailInfo } from './ProductDetailInfo';
import { SkuAvailabilityInfo } from './SkuAvailabilityInfo';
import { SkuDetailInfo } from './SkuDetailInfo';
import { SubstanceProductDetailInfo } from './SubstanceProductDetailInfo';
import { currencySymbolDict } from './SkuInfo';

export class BBESkuInfo {
  public skuDetailList: SkuDetailInfo[];
  public productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo;
  public availability: string;
  public brand: string;
  public brand_key: string;
  public cas_number: string;
  public commodity_code: string;
  public country_origin: string;
  public currency: string;
  public customs_fee: string;
  public customs_fee_currency: string;
  public global_product_hierarchy: string;
  public gross_weight: string;
  public id_material: string;
  public id_product: string;
  public id_substance: string;
  public image: string;
  public is_sial: string;
  public linear_formula: string;
  public material_name: string;
  public material_number: string;
  public material_qty: string;
  public material_type: string;
  public material_uom: string;
  public net_weight: string;
  public package_size: string;
  public price_list: string;
  public price_offer: string;
  public product_key: string;
  public product_status: string;
  public sales_orgs: Array<string>;
  public shipping_fee: string;
  public strategic_business_unit: string;
  public substance_key: string;
  public template_material: string;
  public uom: string;
  public vendor_currency: string;
  public vendor_id: string;
  public vendor_number: string;
  public vendor_price: string;
  public vendor_sku: string;
  public country: string;
  private available_in_days: number;

  constructor(
    productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo,
    pricingAndAvailabilityData: any,
  ) {
    const jsonData = isString(pricingAndAvailabilityData)
      ? JSON.parse(pricingAndAvailabilityData)
      : pricingAndAvailabilityData;
    this.availability = jsonData.availability;
    this.brand = jsonData.brand;
    this.brand_key = jsonData.brand_key;
    this.cas_number = jsonData.cas_number;
    this.commodity_code = jsonData.commodity_code;
    this.country_origin = jsonData.country_origin;
    this.country = jsonData.country_origin;
    this.currency = jsonData.currency;
    this.customs_fee = jsonData.customs_fee;
    this.customs_fee_currency = jsonData.customs_fee_currency;
    this.global_product_hierarchy = jsonData.global_product_hierarchy;
    this.gross_weight = jsonData.gross_weight;
    this.id_material = jsonData.id_material;
    this.id_product = jsonData.id_product;
    this.id_substance = jsonData.id_substance;
    this.image = jsonData.image;
    this.is_sial = jsonData.is_sial;
    this.linear_formula = jsonData.linear_formula;
    this.material_name = jsonData.material_name;
    this.material_number = jsonData.material_number;
    this.material_qty = jsonData.material_qty;
    this.material_type = jsonData.material_type;
    this.material_uom = jsonData.material_uom;
    this.net_weight = jsonData.net_weight;
    this.package_size = jsonData.package_size;
    this.price_list = jsonData.price_list;
    this.price_offer = jsonData.price_offer;
    this.product_key = jsonData.product_key;
    this.product_status = jsonData.product_status;
    this.sales_orgs = jsonData.sales_orgs;
    this.shipping_fee = jsonData.shipping_fee;
    this.strategic_business_unit = jsonData.strategic_business_unit;
    this.substance_key = jsonData.substance_key;
    this.template_material = jsonData.template_material;
    this.uom = jsonData.uom;
    this.vendor_currency = jsonData.vendor_currency;
    this.vendor_id = jsonData.vendor_id;
    this.vendor_number = jsonData.vendor_number;
    this.vendor_price = jsonData.vendor_price;
    this.vendor_sku = jsonData.vendor_sku;
    this.productDetailInfo = productDetailInfo;
    this.skuDetailList = new Array<SkuDetailInfo>();
    this.available_in_days = parseInt(jsonData.availability, 10);

    const availableInfo = new SkuAvailabilityInfo();
    availableInfo.key = this.available_in_days > 0 ? 'available' : 'unavailable';
    availableInfo.quantity = parseInt(jsonData.material_qty, 10);
    availableInfo.date =
      this.available_in_days > 0
        ? new Date().setDate(new Date().getDate() + this.available_in_days)
        : null;
    availableInfo.plant_loc = 'Aldrich Partner';
    this.skuDetailList.push({
      sku: this.material_number,
      pack_size: this.package_size,
      material_description: null,
      availability: availableInfo,
      availabilityMessage: this.prepareAvailableMsg(availableInfo),
      price: parseFloat(this.price_list),
      quantity: 0,
      currency: this.currency,
      currencySymbol: this.prepareCurrencySymbol(this.currency),
      brand: this.brand,
      brand_key: productDetailInfo.brand_key,
      product_key: productDetailInfo.product_key,
    });
  }

  private prepareAvailableMsg(availableInfo: SkuAvailabilityInfo): string {
    if (availableInfo.key === 'available') {
      return `Ships in ${this.available_in_days} days - from ${availableInfo.plant_loc}`;
    } else {
      return '';
    }
  }

  private prepareCurrencySymbol(currencyStr: string): string {
    const code = currencyStr.toUpperCase();
    if (code in currencySymbolDict) {
      return currencySymbolDict[code];
    } else {
      return '';
    }
  }
}
