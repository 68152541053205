import { isString } from '../../shared/components/utils';
import { ProductDetailInfo } from './ProductDetailInfo';

export class ProductInfo {
  public count: number;
  public cas_number: string;
  public product_detail_info_list: ProductDetailInfo[];

  constructor(data: any, ecommAPIUrl: string) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    this.count = jsonData.count;
    this.cas_number = jsonData.term;
    this.product_detail_info_list = new Array<ProductDetailInfo>();

    for (const productDetailInfo of jsonData.products) {
      if (productDetailInfo.cas_number) {
        if (productDetailInfo.is_sial === '0') {
          for (const material of productDetailInfo.materials) {
            this.product_detail_info_list.push(
              new ProductDetailInfo(productDetailInfo, ecommAPIUrl, true, material),
            );
          }
        } else {
          this.product_detail_info_list.push(new ProductDetailInfo(productDetailInfo, ecommAPIUrl));
        }
      }
    }
  }
}
