import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ParametersStashService } from './shared/services/parameters-stash/parameters-stash.service';
import { AuthorizationService } from './authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class SynthiaLiteRoutesGuard {
  constructor(
    private router: Router,
    private stashService: ParametersStashService,
    private authorizationService: AuthorizationService,
  ) {}

  public canActivate(): boolean {
    if (!this.stashService.isSynthiaLiteEnabled) {
      this.authorizationService.logout();
      this.router.navigate(['sign-out']);
      return false;
    }
    return true;
  }
}
