import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CountryDetails, RegisteredUser, SelfRegisteredUser, CompanyDetails } from './models';
import { TermsAndConditions } from './models/terms-and-conditions-entry';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../app-state.service';

export enum RegistrationViewType {
  REGISTRATION = 'registration',
  REGISTRATION_SUCCESS = 'registration-success',
  REGISTRATION_FAILURE = 'registration-failed',
  REGISTRATION_TERMS_AND_CONDITIONS = 'registration-terms-and-conditions',
  REGISTRATION_COMPLETED = 'registration-completed',
  REGISTRATION_SUBMITTED = 'registration-submitted',
  CONFIRM_ACCOUNT = 'confirm-account',
  EMAIL_BLACKLISTED = 'blacklisted_email',
  PENDING_APPROVAL = 'user-registration-approval-pending',
}

export interface SelfRegistrationFormFieldValidationProperties {
  [index: string]: {
    displayName: string;
    errorMessages: string[];
    maxLength: number;
  };
}

export interface UserDataForRegistrationType {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company: CompanyDetails;
  emd_access_token: string;
  is_marketing_consent_accepted: boolean;
}

const midEndApiVersion = 'v1';
export const backendEntryPointUserRegistration: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midEndApiVersion}/register/`;
export const backendEntryPointTermsAndConditions: string = `${APP_CONFIG.CHEMATICA_API_URL}/api/${midEndApiVersion}/terms-and-conditions/`;
export const backendEntryPointGetCountries: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midEndApiVersion}/country/`;
export const backendEntryPointVerifyChangePasswordToken: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midEndApiVersion}/verify-change-password-token/`;
export const backendEntryPointResendChangePasswordToken: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midEndApiVersion}/resend-complete-registration-mail/`;
export const backendEntryPointCheckDomainBlacklisted: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midEndApiVersion}/check-blacklisted/`;
export const backendEntryPointForEloqua: string =
  'https://sial.tfaforms.net/api_v2/workflow/processor';

export interface UserAcceptTermsAndConditions {
  terms_and_conditions: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  accepted_from_ip?: string;
}

export interface FormFieldApiError {
  field: string;
  code: string;
  message: string;
}

export enum RegistrationFormTypes {
  SelfRegistration,
  LiteRegistration,
}

export interface TermsAndConditionsResponseStatus {
  status: boolean;
  is_domain_whitelisted?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  private static unexpectedFormatErrorMsg: string = 'Unexpected format of response.';
  private static resolveCountries(response: HttpResponse<any>): CountryDetails[] {
    try {
      const countries = response.body;
      if (countries.length > 0) {
        return countries.map((func) => new CountryDetails(func));
      }
      return [];
    } catch (err) {
      throw new Error(UserRegistrationService.unexpectedFormatErrorMsg);
    }
  }

  public userRegistered: RegisteredUser;
  public selfRegisteredUser: SelfRegisteredUser;
  public usedEnvironment = environment;

  constructor(private http: HttpClient, private appStateService: AppState) {}

  public registerUser(options: any, formType: RegistrationFormTypes): Observable<any> {
    const backendUrl: string = backendEntryPointUserRegistration;
    return this.http.post(backendUrl, JSON.stringify(options), { observe: 'response' }).pipe(
      map((result: HttpResponse<any>) => {
        if (!!result.body.code) {
          return result.body;
        } else {
          try {
            return formType === RegistrationFormTypes.LiteRegistration
              ? new RegisteredUser(result.body)
              : new SelfRegisteredUser(result.body);
          } catch (err) {
            throw new Error('Unexpected format of response');
          }
        }
      }),
    );
  }

  public getTermsAndConditions() {
    return this.http.get(backendEntryPointTermsAndConditions, { observe: 'response' }).pipe(
      map((response: any) => {
        const jsonResult = response.body;
        return new TermsAndConditions(jsonResult);
      }),
    );
  }

  public acceptTermsAndConditions(
    data: UserAcceptTermsAndConditions,
  ): Observable<TermsAndConditionsResponseStatus> {
    return this.http.post(backendEntryPointTermsAndConditions, data, { observe: 'response' }).pipe(
      switchMap((response: HttpResponse<any>) => {
        if (response.status === 201) {
          return of({ status: true, is_domain_whitelisted: response.body.is_domain_whitelisted });
        } else {
          return of({ status: false });
        }
      }),
    );
  }

  public getCountries(): Observable<any> {
    return this.http.get(backendEntryPointGetCountries, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return UserRegistrationService.resolveCountries(response);
        } catch (err) {
          throw new Error('Unexpected format of response');
        }
      }),
    );
  }

  public verifyPasswordChangeToken(token: string): Observable<any> {
    return this.http
      .post(backendEntryPointVerifyChangePasswordToken, JSON.stringify({ token: token }), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        }),
      );
  }

  public resendPasswordChangeTokenEmail(token: string): Observable<any> {
    return this.http
      .post(backendEntryPointResendChangePasswordToken, JSON.stringify({ token: token }), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        }),
      );
  }

  public checkDomainBlacklisted(email: string) {
    const queryParams: HttpParams = new HttpParams().set('email', String(email));

    return this.http
      .get(backendEntryPointCheckDomainBlacklisted, { params: queryParams, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return response.body.is_domain_blacklisted;
          }
        }),
        catchError((error) => {
          return error;
        }),
      );
  }

  public sendValueToEloqua(userData: UserDataForRegistrationType): Observable<any> {
    const formData: FormData = this.makeFormData(userData);
    return this.http
      .post(backendEntryPointForEloqua, formData, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.status;
        }),
      );
  }

  private makeFormData(userData: UserDataForRegistrationType) {
    let nonProdInstance = true;
    if (!!this.appStateService.midendConfiguration) {
      nonProdInstance = this.appStateService.midendConfiguration.value?.NON_PROD_INSTANCE;
    }
    const formId: string = nonProdInstance ? '8728' : '8729';
    const dbControl: string = nonProdInstance
      ? '38b03fb701182320e98255f9834968cb'
      : 'b6ae783b1c7c50360fe0a21e200d5b8c';
    const formData: FormData = new FormData();
    formData.append('tfa_12', userData.first_name);
    formData.append('tfa_13', userData.last_name);
    formData.append('tfa_15', userData.email);
    formData.append('tfa_16', userData.phone_number);
    formData.append('tfa_18', userData.company.name);
    formData.append('tfa_1652', userData.company.address_line_1);
    formData.append('tfa_1654', userData.company.address_line_2);
    formData.append('tfa_19', userData.company.postal_code);
    formData.append('tfa_20', userData.company.locality);
    formData.append('tfa_4922', userData.company.country_details.name);
    formData.append('tfa_1657', userData.company.state);
    formData.append('tfa_1647', userData.is_marketing_consent_accepted ? 'tfa_1647' : '');
    // adding the hidden control values
    formData.append('tfa_dbFormId', formId);
    formData.append('tfa_dbResponseId', '');
    formData.append('tfa_dbControl', dbControl);
    return formData;
  }
}
