import { AfterContentInit, Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from '../../animations';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ThumbsUpAnnotation } from '../../services/reaction-annotations/models/thumbs-up-annotation';
import { ThumbsDownAnnotation } from '../../services/reaction-annotations/models/thumbs-down-annotation';
import {
  IReactionAnnotation,
  Feedback,
} from '../../services/reaction-annotations/reaction-annotations.service';
import { isEqual } from 'lodash';
import { KetcherEditorComponent } from '../ketcher-editor/ketcher-editor.component';
import { TargetMoleculeSelectionService } from '../../services/target-molecule-selection/target-molecule-selection.service';
import { takeUntil } from 'rxjs/operators';
import { StructureInputType } from '../utils';
@Component({
  selector: 'ch-like-dislike-dialog',
  styleUrls: ['./like-dislike-dialog.component.scss'],
  templateUrl: './like-dislike-dialog.component.html',
  animations: [FADE_IN_ANIMATION, FADE_OUT_ANIMATION],
})
export class LikeDislikeDialogComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  public readonly feedback = Feedback;

  public originalAnnotation: IReactionAnnotation;
  public currentAnnotation: IReactionAnnotation = {
    thumbsDown: null,
    thumbsUp: null,
  };
  public reactionSmiles: string;
  public selectedTab = new UntypedFormControl(0);
  public feedbackType: number;
  public moleculeEditorSubscriptions: Subscription = new Subscription();
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  @ViewChild('moleculeEditor') private moleculeEditor: KetcherEditorComponent;

  constructor(
    public likesDialogRef: MatDialogRef<LikeDislikeDialogComponent>,
    public targetMoleculeSelectionService: TargetMoleculeSelectionService,
  ) {}

  public getDialogFeedbackButtonName() {
    if (this.feedbackType === Feedback.ThumbsDown) {
      return 'Explain the issue';
    } else if (this.feedbackType === Feedback.ThumbsUp) {
      return 'Send your feedback';
    }
  }

  public ngAfterContentInit() {
    this.currentAnnotation.thumbsUp = new ThumbsUpAnnotation(this.originalAnnotation.thumbsUp);
    this.currentAnnotation.thumbsDown = new ThumbsDownAnnotation(
      this.originalAnnotation.thumbsDown,
    );
  }

  public ngAfterViewInit() {
    this.selectedTab.valueChanges
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((selectedTab: number) => {
        if (selectedTab === 1) {
          if (this.moleculeEditor) {
            this.moleculeEditor.setMolecule(
              this.reactionSmiles,
              StructureInputType.REACTION_SMILES,
            );
            this.moleculeEditorSubscriptions.add(
              this.targetMoleculeSelectionService.isMoleculeEditorLoaded.subscribe(
                (editorLoaded: boolean) => {
                  if (editorLoaded) {
                    this.moleculeEditor.setMolecule(
                      this.reactionSmiles,
                      StructureInputType.REACTION_SMILES,
                    );
                  }
                },
              ),
            );
          }
        }
      });
  }

  public ngOnDestroy() {
    if (this.moleculeEditorSubscriptions && !this.moleculeEditorSubscriptions.closed) {
      this.moleculeEditorSubscriptions.unsubscribe();
    }
  }

  public closeDialog(close: boolean) {
    if (close) {
      const options = {
        reactionAnnotation: this.currentAnnotation,
        feedback:
          this.feedbackType === Feedback.ThumbsUp
            ? this.currentAnnotation.thumbsUp
            : this.currentAnnotation.thumbsDown,
      };

      this.moleculeEditor.getMoleculeSmiles().then((reactionSmiles: string) => {
        options.feedback.smarts = reactionSmiles;
        this.likesDialogRef.close(options);
      });
    } else {
      this.likesDialogRef.close(false);
    }
  }

  public isAnnotationChangedAndMeaningful(): boolean {
    let isAnnotationMeaningful: boolean = false;
    let isChanged: boolean = false;
    if (this.targetMoleculeSelectionService.isMoleculeEdited) {
      if (this.feedbackType === Feedback.ThumbsUp) {
        isAnnotationMeaningful =
          this.currentAnnotation.thumbsUp.elegant ||
          this.currentAnnotation.thumbsUp.robust ||
          this.currentAnnotation.thumbsUp.novel ||
          this.currentAnnotation.thumbsUp.other_advantages ||
          !!this.currentAnnotation.thumbsUp.additional_comment ||
          !!this.currentAnnotation.thumbsUp.advantages_explanation;
      } else if (this.feedbackType === Feedback.ThumbsDown) {
        isAnnotationMeaningful =
          this.currentAnnotation.thumbsDown.not_selective ||
          this.currentAnnotation.thumbsDown.reactivity_conflict ||
          this.currentAnnotation.thumbsDown.steric_or_strain_problem ||
          this.currentAnnotation.thumbsDown.other_problems ||
          !!this.currentAnnotation.thumbsDown.problem_explanation ||
          !!this.currentAnnotation.thumbsDown.additional_comment;
      }
      isChanged = !isEqual(this.currentAnnotation, this.originalAnnotation);
    }
    return isChanged && isAnnotationMeaningful;
  }
}
