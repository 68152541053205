import { ResultsKeywordFilterDialogComponent } from './results-keyword-filter-dialog/results-keyword-filter-dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import {
  KeywordsFilter,
  KeywordsData,
  EditKeywordFilter,
} from './../results-default-filter.models';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { getTooltip } from '../../../utils';

@Component({
  selector: 'ch-results-keyword-filter',
  templateUrl: './results-keyword-filter.component.html',
  styleUrls: ['./results-keyword-filter.component.scss'],
})
export class ResultsKeywordFilterComponent implements OnInit {
  @Input() public keywordFilters: KeywordsFilter;
  @Output() public removeKeywordFilter: EventEmitter<KeywordsData> = new EventEmitter();
  @Output() public addKeywordFilter: EventEmitter<KeywordsData> = new EventEmitter();
  @Output() public editKeywordFilter: EventEmitter<EditKeywordFilter> = new EventEmitter();
  @ViewChild('keywordSection') public keywordSectionRef: ElementRef;

  public isNewKeyword: boolean;
  public keyword: string;
  public isEditKeywordFilter: boolean;
  public editedKeyword: EditKeywordFilter;
  public tooltipMaxLength = 36;
  public getTooltip = getTooltip;
  public keywordFilterType: string;
  public keywordDialogRef: MatDialogRef<ResultsKeywordFilterDialogComponent>;

  constructor(private dialog: MatDialog) {}

  public ngOnInit() {
    this.isEditKeywordFilter = false;
  }

  public addFilterForKeywords() {
    this.isEditKeywordFilter = false;
    this.keyword = '';
    const elementRectangle: ClientRect = this.keywordSectionRef.nativeElement.getBoundingClientRect();
    const config: MatDialogConfig = {
      disableClose: false,
      width: elementRectangle.width + 5 + 'px',
      maxHeight: '200px',
      position: {
        left: elementRectangle.left + 'px',
        top: elementRectangle.top + 'px',
      },
      panelClass: 'keyword-dialog-editor',
    };

    this.keywordDialogRef = this.dialog.open(ResultsKeywordFilterDialogComponent, config);
    this.keywordDialogRef.beforeClosed().subscribe((value: KeywordsData) => {
      if (value) {
        this.addKeywordFilter.emit(value);
      }
    });
  }

  public removeKeywords(keywordIndex: number, keyword: string, sectionName: string) {
    const keywordDetails: KeywordsData = {
      keyword,
      sectionName,
      keywordIndex,
    };
    this.removeKeywordFilter.emit(keywordDetails);
  }

  public editKeyword(keyword: string, keywordIndex: number, section: string) {
    this.isEditKeywordFilter = true;
    this.isNewKeyword = false;
    this.keyword = keyword;
    this.editedKeyword = {
      keyword: this.keyword,
      index: keywordIndex,
      isKeywordRemoved: false,
      sectionName: section,
    };
  }

  public saveEditedKeyword() {
    this.editedKeyword.keyword = this.keyword;
    this.editKeywordFilter.emit(this.editedKeyword);
    this.isEditKeywordFilter = false;
    this.editedKeyword = null;
  }

  public deleteKeyword() {
    this.editedKeyword.isKeywordRemoved = true;
    this.editKeywordFilter.emit(this.editedKeyword);
    this.isEditKeywordFilter = false;
    this.editedKeyword = null;
  }

  public isKeywordsInLimitTo() {
    const { limitTo }: KeywordsFilter = this.keywordFilters;
    return limitTo && Array.isArray(limitTo.keywords) && limitTo.keywords.length > 0;
  }

  public isKeywordsInExclude() {
    const { exclude }: KeywordsFilter = this.keywordFilters;
    return exclude && Array.isArray(exclude.keywords) && exclude.keywords.length > 0;
  }
}
