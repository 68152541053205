import { throwError } from 'rxjs';
import { isString } from '../../shared/components/utils';
import { Plan } from './plan';

export enum UserSubscriptionStatusType {
  ACTIVE = 'ACTIVE',
  EXPIRING = 'EXPIRING',
  CANCELED = 'CANCELED',
}

export class UserSubscription {
  /* tslint:disable:variable-name */
  public subscription_id: string = '';
  public period_start_date: string = '';
  public period_end_date: string = '';
  public metering_key_id: string = '';
  public status: UserSubscriptionStatusType = UserSubscriptionStatusType.ACTIVE;
  public plan: Plan = null;

  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (this.hasOwnProperty(field)) {
        if (field === 'status') {
          try {
            this.status = Object.values(UserSubscriptionStatusType).includes(jsonData[field])
              ? jsonData[field]
              : throwError('Unexpected value');
          } catch (error) {
            console.warn(`Unexpected type of field '${field}' in data provided to UserSubscription:ctor.\n
            Used default value '${this[field]}' instead of provided '${jsonData[field]}'.`);
          }
        } else if (field === 'plan') {
          this.plan = new Plan(jsonData[field]);
        } else if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to UserSubscription:ctor.`);
        }
      } else {
        console.warn(`Unexpected type of '${field}' in data provided to UserSubscription:ctor.`);
      }
    }
  }
}
