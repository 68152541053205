import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  jwtHelperService,
  AuthTokenStorageName,
  backendEntryPoint_User,
  backendEntryPoint_PasswordChange,
} from '../authorization.service';
import { ErrorsHandlerService, ParsedConnectionError } from '../../shared/services/errors-handler';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConstantsService } from 'src/app/shared/services/app-constants/app-constants.service';
import { UserRegistrationService } from 'src/app/shared/services/user-registration/user-registration.service';
import { AppState } from '../../app-state.service';

/**
 * Password change component works in two modes:
 * -- without :token parameter in active route should be available only to authorized users and allows for password
 *    change if user provides current password; this mode uses user patching API of the backend
 * -- with :token parameter requires only new password and uses provided token to authorize request to password change
 *    API; this mode supports the last step of password restore functionality when user opens password restore url
 *    sent with an email
 */

@Component({
  selector: 'ch-password-change',
  styleUrls: ['./password-change.component.scss'],
  templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  public passwordChangeToken: string = null;
  public pendingApiCall: boolean = false;
  public success: boolean = false;
  public isTokenExpiredOrInvalid: boolean = false;
  public isNewTokenSent: boolean = false;
  public minPasswordLength: number = 8;
  public maxPasswordLength: number = 128;
  public passwordPattern: string =
    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])' + "(?=.*['@', '$', '#', '%', '^', '&', '*', '_', '!']).*$$";
  public presentPasswordError: string = null;
  public apiCallError: string[] = [];
  public presentPassword: string = '';
  public newPassword: string = '';
  public newPasswordConfirmation: string = '';
  public newPasswordPlaceholder: string = '';
  public repeatNewPasswordPlaceholder: string = '';
  public isPendingTokenVerification: boolean = false;

  @Input() public isRegistrationView: boolean = false;

  private nextUrl: string;
  private passwordChageSubscription: Subscription;
  private unsubscribeFromAll: Subject<void> = new Subject();

  constructor(
    @Optional() public dialogRef: MatDialogRef<PasswordChangeComponent>,
    public userRegistrationService: UserRegistrationService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private errorsHandler: ErrorsHandlerService,
    private appConstantsService: AppConstantsService,
    private appStateService: AppState,
  ) {}

  public ngOnInit() {
    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((midendConfiguration) => {
        if (!!midendConfiguration) {
          this.minPasswordLength = midendConfiguration.MINIMUM_PASSWORD_LENGTH;
        }
      });

    this.route.params.pipe(takeUntil(this.unsubscribeFromAll)).subscribe((params) => {
      this.isPendingTokenVerification = true;
      this.nextUrl = params.next ? params.next : '';
      this.passwordChangeToken = params.token;
      if (!this.dialogRef && !!this.passwordChangeToken) {
        this.verifyToken();
      } else {
        this.isPendingTokenVerification = false;
      }
      // FIXME: Better workaround for broken validation when *ngIf(!passwordChangeToken) rules presentPasswordInput
      // For now instead of *ngIf we just hide presentPasswordInput preintialized with long enough password
      // to prevent validation errors.
      if (this.passwordChangeToken) {
        this.presentPassword = '*'.repeat(this.minPasswordLength); // password valid with respect to rules in template
      }

      if (this.isRegistrationView) {
        this.setInputPlaceholders();
      }
    });
  }

  public setInputPlaceholders() {
    this.newPasswordPlaceholder = this.appConstantsService.newPasswordPlaceholder;
    this.repeatNewPasswordPlaceholder = this.appConstantsService.repeatNewPasswordPlaceholder;
  }

  public resendConfirmationEmail() {
    this.pendingApiCall = true;
    this.userRegistrationService
      .resendPasswordChangeTokenEmail(this.passwordChangeToken)
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        finalize(() => (this.pendingApiCall = false)),
      )
      .subscribe(
        (result) => {
          if (result.status === 204) {
            this.isNewTokenSent = true;
          }
        },
        (error) => {
          this.handleLocalError(new ParsedConnectionError(error));
        },
      );
  }

  public onOkClick() {
    if (!!this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.router.navigateByUrl(this.nextUrl);
    }
  }

  public changePassword() {
    this.apiCallError = [];
    this.pendingApiCall = true;

    // abort pending change if any
    this.unsubscribePasswordChange();

    if (this.passwordChangeToken) {
      this.passwordChageSubscription = this.http
        .post(
          backendEntryPoint_PasswordChange,
          JSON.stringify({ token: this.passwordChangeToken, password: this.newPassword }),
          { observe: 'response' },
        )
        .subscribe(
          (res: HttpResponse<any>) => this.changePasswordCallSuccess(res),
          (error: any) => this.changePasswordCallError(error),
        );
    } else {
      const userUrl = this.getCurrentUserUrl();
      if (userUrl) {
        this.passwordChageSubscription = this.http
          .patch(
            userUrl,
            JSON.stringify({ old_password: this.presentPassword, password: this.newPassword }),
            { observe: 'response' },
          )
          .subscribe(
            (res: HttpResponse<any>) => this.changePasswordCallSuccess(res),
            (error: any) => this.changePasswordCallError(error),
          );
      } else {
        // As the component should not remain visible for unauthorized user we can safely ignore this error
        this.apiCallError = [];
        this.pendingApiCall = false;
        return;
      }
    }
  }

  public ngOnDestroy() {
    this.unsubscribePasswordChange();
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  public onInputPassword() {
    this.apiCallError = [];
    this.presentPasswordError = '';
  }

  public verifyToken() {
    this.userRegistrationService
      .verifyPasswordChangeToken(this.passwordChangeToken)
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        finalize(() => (this.isPendingTokenVerification = false)),
      )
      .subscribe(
        (result) => {
          this.isTokenExpiredOrInvalid = false;
        },
        (error) => {
          if (error.status === 400) {
            this.isTokenExpiredOrInvalid = true;
          } else {
            this.handleLocalError(new ParsedConnectionError(error));
          }
        },
      );
  }

  // Extract all errors related to the local context (such as wrong password) and other non global.
  public handleLocalError(parsedError: ParsedConnectionError): boolean {
    switch (parsedError.status) {
      case 400:
        // Backend errors with code 400:
        // - for fields: {"password":["This field may not be blank."]}
        // - other: {"non_field_errors":[?]}
        if ('username' in parsedError.bodyJson) {
          this.apiCallError.push('Missing login.'); // Should not happen with a proper form validation.
          return true;
        } else if ('password' in parsedError.bodyJson) {
          this.apiCallError.push('Missing password.'); // Should not happen with a proper form validation.
          return true;
        } else if (parsedError.bodyJson.errors[0].code === 'account-locked') {
          this.apiCallError.push(`The number of allowed login attempts has been exceeded.
                                  Please contact your administrator`);
          return true;
        } else if ('password' in parsedError.bodyJson) {
          // can happen if password validation on backend is stronger than here
          this.apiCallError.push(parsedError.bodyJson.password.join(' '));
          return true;
        } else if (parsedError.bodyJson.errors[0].field === 'token') {
          this.errorsHandler.showGlobalError(parsedError.promptMessage);
          return true;
        } else if (parsedError.bodyJson.errors) {
          for (const validationError of Object.keys(parsedError.bodyJson.errors)) {
            this.apiCallError.push(parsedError.bodyJson.errors[validationError].message);
          }
          return true;
        } else if ('non_field_errors' in parsedError.bodyJson) {
          // possible at all for this entry point?
          this.apiCallError.push(parsedError.bodyJson.non_field_errors.join(' '));
          return true;
        }
        break;

      case 403:
        if (
          !parsedError.isCustomerPolicyNotAcceptedError() &&
          parsedError.bodyJson.code === 'permission_denied' &&
          parsedError.bodyJson.message.indexOf('missing or does not match') !== -1
        ) {
          this.presentPasswordError = 'Wrong password';
          return true;
        }
        break;

      default:
        if (!parsedError.isGlobal()) {
          const err = parsedError.response.statusText || '';
          this.apiCallError.push(
            `Something went wrong. Please try again. ${err} (code: ${parsedError.status})`,
          );
          return true;
        }
    }

    // Leave unrecognized global errors to errorsHandler
    return false;
  }

  private unsubscribePasswordChange() {
    if (this.passwordChageSubscription && !this.passwordChageSubscription.closed) {
      this.passwordChageSubscription.unsubscribe();
    }
  }

  private changePasswordCallSuccess(res: HttpResponse<any>) {
    // assuming res.status == 200 (content ignored)
    this.apiCallError = [];
    this.pendingApiCall = false;
    this.success = true;
  }

  private changePasswordCallError(error: any) {
    this.pendingApiCall = false;
    this.success = false;
    const parsedError = new ParsedConnectionError(error);

    if (parsedError.isRecognized()) {
      if (!this.handleLocalError(parsedError)) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage);
      }
    } else {
      this.apiCallError.push('Unable to change password. ' + error);
    }
  }

  // TODO: Create a service (or property of the authorization one?) with BehaviorSubject providing current user data
  private getCurrentUserUrl(): string {
    let userId: number;

    try {
      userId = jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName)).user_id;
    } catch (e) {
      console.log('Exception when accessing current user id: ' + e);
      return null;
    }
    return backendEntryPoint_User.replace(/\$\{id\}/, userId.toString());
  }
}
