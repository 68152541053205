<div class="section-filter" *ngIf="filterSection">
  <div class="section-label">
    <span>{{ filterSection.name }}</span>
  </div>
  <div *ngIf="filterSection.exact">
    <div class="category-label">{{ filterSection.exact.name }}</div>
    <div>
      <mat-card
        class="category-image-container"
        *ngFor="let item of filterSection.exact.criteria; let i = index"
      >
        <span
          class="category-card-close ch-action-icon-hover"
          (click)="removeItemFromFilters(i, filterSection.exact.name, filterSection.name)"
        >
          <mat-icon class="grey-icon">close</mat-icon>
        </span>
        <img
          class="filter-mol-image"
          [src]="item.svg"
          (mouseenter)="onMouseEnter($event, item.svg)"
          (mouseleave)="onMouseLeave()"
          (click)="
            editStructureFilter(
              i,
              filterSection.exact.name,
              filterSection.name,
              item.smiles,
              item.smarts
            )
          "
        />
      </mat-card>
    </div>
  </div>

  <div *ngIf="filterSection.structure">
    <div class="category-label">{{ filterSection.structure.name }}</div>
    <div>
      <mat-card
        class="category-image-container"
        *ngFor="let item of filterSection.structure.criteria; let i = index"
      >
        <span
          class="category-card-close ch-action-icon-hover"
          (click)="removeItemFromFilters(i, filterSection.structure.name, filterSection.name)"
        >
          <mat-icon class="grey-icon">close</mat-icon>
        </span>

        <img
          class="filter-mol-image"
          [src]="item.svg"
          (mouseenter)="onMouseEnter($event, item.svg)"
          (mouseleave)="onMouseLeave()"
          (click)="
            editStructureFilter(
              i,
              filterSection.structure.name,
              filterSection.name,
              item.smiles,
              item.smarts
            )
          "
        />
      </mat-card>
    </div>
  </div>
  <div *ngIf="filterSection.similar">
    <div class="category-label">{{ filterSection.similar.name }}</div>
    <div>
      <mat-card
        class="category-image-container"
        *ngFor="let item of filterSection.similar.criteria; let i = index"
      >
        <span
          class="category-card-close ch-action-icon-hover"
          (click)="removeItemFromFilters(i, filterSection.similar.name, filterSection.name)"
        >
          <mat-icon class="grey-icon">close</mat-icon>
        </span>
        <img
          class="filter-mol-image"
          [src]="item.svg"
          (mouseenter)="onMouseEnter($event, item.svg)"
          (mouseleave)="onMouseLeave()"
          (click)="
            editStructureFilter(
              i,
              filterSection.similar.name,
              filterSection.name,
              item.smiles,
              item.smarts,
              item.similarity
            )
          "
        />
      </mat-card>
    </div>
  </div>
</div>
