import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch((err) => console.log(err));

// FIXME: Restore HMR functionality, see https://github.com/angular/angular-cli/wiki/stories-configure-hmr

// FIXME: Restore enableDebugTools/disableDebugTools from '@angular/platform-browser'
//        Refer to removed pre angular-cli file app/environmet.ts

// FIXME: preserveWhitespaces should be left out from bootstrapping but simply adding this field to tscongif.json and
// tsconfig.app.json didnt work in our app - possible bug in angular.
