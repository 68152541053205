import { Subscription, Subject } from 'rxjs';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';
import {
  NodesClipboardService,
  MoleculeSetsService,
  GraphModeType,
  ParsedConnectionError,
  AnalysisService,
  ResultsView,
  AnalysisResultsService,
} from '../../services';
import { InfoService } from '../../services/info.service';
import { takeUntil } from 'rxjs/operators';
import { NewClipboardItemComponent } from '../nodes-clipboard/new-clipboard-item/new-clipboard-item.component';

@Component({
  selector: 'ch-molecule-list',
  templateUrl: './molecule-list.component.html',
  styleUrls: ['./molecule-list.component.scss'],
})
export class MoleculeListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public molecules: any[] = [];
  @Input() public mode: GraphModeType;
  @Input() public algorithm: string;
  @Input() public loadingMolecules: boolean;

  @Output() public onInit = new EventEmitter<any>();
  @ViewChildren('nodeItem') public nodeItems: QueryList<NewClipboardItemComponent>;

  public moleculeLoadingLimiter: number = 6;
  public loadingMoreMoleculesTimeout: any;
  public moleculeSets: any[] = [];
  public subtitle: string = '';
  public readonly ResultsView = ResultsView;
  public isAllPathwaysLoaded: boolean;
  public isMoleculeReportLoading: boolean = true;
  public reloadMoleculeReport: boolean = false;

  private moleculeSetsSubscriptions: Subscription;
  private unsubscribeSubject: Subject<void> = new Subject<void>();

  constructor(
    public clipboardService: NodesClipboardService,
    private moleculeSetsService: MoleculeSetsService,
    private infoService: InfoService,
    private analysisService: AnalysisService,
    private analysisResultsService: AnalysisResultsService,
  ) {}

  public ngOnInit() {
    this.analysisService.hasResult
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((hasResult) => {
        if (hasResult && this.molecules.length === 0) {
          this.onInit.emit();
        }
      });
    this.analysisResultsService.isAllPathwaysLoaded
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((isAllPathwaysLoaded) => {
        this.isAllPathwaysLoaded = isAllPathwaysLoaded;
        this.isMoleculeReportLoading = isAllPathwaysLoaded ? false : true;
      });
    this.analysisResultsService.reloadMoleculeReport
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((reloadMoleculeReport) => {
        this.reloadMoleculeReport = reloadMoleculeReport;
      });
    this.subscribeToMoleculeSets();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('molecules') && this.molecules.length > 0) {
      this.loadingMolecules = false;
      this.setSubtitle();
      if (this.nodeItems) {
        this.nodeItems.forEach((clipboardItem: NewClipboardItemComponent) => {
          clipboardItem.updateNodeDetails();
        });
      }
    }
  }

  public subscribeToMoleculeSets() {
    this.moleculeSetsService
      .getSortedMoleculeSetTemplates()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((moleculeSets: any[]) => (this.moleculeSets = moleculeSets));
  }

  public ngOnDestroy() {
    if (this.moleculeSetsSubscriptions && !this.moleculeSetsSubscriptions.closed) {
      this.moleculeSetsSubscriptions.unsubscribe();
    }
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  public shouldShowLoadMoreMolecules() {
    return this.molecules.length > this.moleculeLoadingLimiter;
  }

  public loadMoreMolecules(visible: boolean) {
    if (!this.loadingMoreMoleculesTimeout && visible) {
      this.loadingMoreMoleculesTimeout = setTimeout(() => {
        this.moleculeLoadingLimiter += 6;
        this.loadingMoreMoleculesTimeout = undefined;
      }, 1000);
    }
  }

  public deselectAllNodes() {
    this.nodeItems.forEach((item: NewClipboardItemComponent) => {
      if (item.pointed) {
        item.pointNode();
      }
    });
  }

  public isAnyNodeSelected() {
    let isSelected: boolean = false;
    if (this.nodeItems) {
      this.nodeItems.forEach((item: NewClipboardItemComponent) => {
        if (item.pointed) {
          isSelected = true;
        }
      });
    }
    return isSelected;
  }

  public addMoleculeToSet(event: { setId: number; moleculeId: string; setName: string }) {
    if (!this.moleculeSetsSubscriptions || this.moleculeSetsSubscriptions.closed) {
      this.moleculeSetsSubscriptions = new Subscription();
    }
    this.moleculeSetsSubscriptions.add(
      this.moleculeSetsService
        .addMoleculesToSet({ setId: event.setId, moleculeId: event.moleculeId })
        .subscribe(
          (response) => {
            this.infoService.showInfo(`Molecule added to set '${event.setName}'`);
          },
          (error) => {
            const parsedError = new ParsedConnectionError(error);
            if (parsedError.bodyJson.code === 'non-unique-molecule-set-molecule') {
              this.infoService.showError(
                `Molecule set '${event.setName}' already contains this molecule`,
                3000,
              );
            }
            if (parsedError.bodyJson.code === 'molecule-set-not-found') {
              this.infoService.showError(`Molecule set: '${event.setName}' does not exist`, 3000);
            }
          },
        ),
    );
  }

  public setSubtitle() {
    this.subtitle = 'Results contain ' + this.molecules.length + ' unique molecules';
  }
}
