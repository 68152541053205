import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, Subject, throwError, Observable, empty, BehaviorSubject } from 'rxjs';
import { map, catchError, share, mergeAll } from 'rxjs/operators';
import { ParsedConnectionError, ErrorsHandlerService } from '../errors-handler';
import { InfoService } from '../info.service';
import { UserInventory } from './models/user-inventory';
import { stringFormat } from '../../components/utils';
import { AppConstantsService } from '../app-constants/app-constants.service';
import { HashingTool } from './models/hashing-tool';

const apiVersion: string = '/api/v1';
const backendEntryPoint_CustomerInventory: string =
  APP_CONFIG.CHEMATICA_API_URL + apiVersion + '/customer-inventory/';
const backendEntryPoint_CustomerInventoryById: string =
  APP_CONFIG.CHEMATICA_API_URL + apiVersion + '/customer-inventory/' + '{0}/';
const backendEntryPoint_ActiveAutomaticRetrosynthesisComputations: string =
  backendEntryPoint_CustomerInventory + 'active-computations/';
const backendEntryPoint_HashingTools: string =
  backendEntryPoint_CustomerInventory + 'hashing-tools';

@Injectable()
export class UserInventoryService {
  public uploadProgressSubject: Subject<number> = new Subject<number>();
  public processingProgressSubject: Subject<number> = new Subject<number>();
  public backgroundProgressTrackingSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0,
  );
  public inventoryProgressObservable: Observable<any>;

  private userInventoryParseErrorMessage: string =
    'Unexpected format of response for UserInventory';

  constructor(
    private httpClient: HttpClient,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
    private appConstantsService: AppConstantsService,
  ) {}

  public getUserInventories(): Observable<UserInventory[]> {
    return this.httpClient
      .get(backendEntryPoint_CustomerInventory, { observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => this.resolveInventoriesResponse(response.body)));
  }

  public getUserInventoryById(inventoryId: number): Observable<UserInventory> {
    return this.httpClient
      .get(stringFormat(backendEntryPoint_CustomerInventoryById, inventoryId.toString()), {
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<any>) => this.resolveInventoryByIdResponse(response.body)));
  }

  public uploadUserInventory(
    fileSalt: string,
    fileName: string,
    inventoryName: string,
    description: string,
    inventoryFile: Blob,
    existingInventory?: any,
  ) {
    const formData: FormData = this.makeFormData(
      fileSalt,
      fileName,
      description,
      inventoryFile,
      !!existingInventory ? null : inventoryName, // for PUT method (updating file)
    );
    const options: any = {
      responseType: 'json',
      reportProgress: true,
      observe: 'events',
    };
    let uploadInventoryObservable: Observable<ArrayBuffer>;
    // if there is an existing inventory we need to update it instead of creating new.
    if (existingInventory) {
      uploadInventoryObservable = this.httpClient.put(
        `${backendEntryPoint_CustomerInventory}${existingInventory.id}/`,
        formData,
        options,
      );
    } else {
      uploadInventoryObservable = this.httpClient.post(
        backendEntryPoint_CustomerInventory,
        formData,
        options,
      );
    }
    return uploadInventoryObservable.pipe(
      map(this.resolveInventoryUploadHttpEvent.bind(this)),
      mergeAll(),
      share(),
      catchError(this.handleInventoryUploadErrors.bind(this)),
    );
  }

  public getActiveAutomaticRetrosynthesisComputations(): Observable<number> {
    return this.httpClient.get(backendEntryPoint_ActiveAutomaticRetrosynthesisComputations).pipe(
      map((response: any) => {
        try {
          return response.active_automatic_retrosynthesis_count;
        } catch (error) {
          return new Error('Unexpected format of response');
        }
      }),
    );
  }

  public getHashingTools(): Observable<HashingTool[]> {
    return this.httpClient.get(backendEntryPoint_HashingTools).pipe(
      map((hashingTools: any) => {
        return hashingTools.map((hashingTool: any) => new HashingTool(hashingTool));
      }),
    );
  }

  private makeFormData(
    fileSalt: string,
    fileName: string,
    description: string,
    inventoryFile: Blob,
    inventoryName?: string,
  ) {
    const formData: FormData = new FormData();
    formData.append('salt', fileSalt);
    formData.append('file_name', fileName);
    formData.append('file_details', description);
    formData.append('file', inventoryFile);
    if (inventoryName) {
      formData.append('name', inventoryName);
    }
    return formData;
  }

  private resolveInventoriesResponse(inventoryList: any): UserInventory[] {
    try {
      return inventoryList.map((inventory: any) => new UserInventory(inventory));
    } catch (error) {
      throw new Error(this.userInventoryParseErrorMessage);
    }
  }

  private resolveInventoryByIdResponse(inventory: any): UserInventory {
    try {
      return new UserInventory(inventory);
    } catch (error) {
      throw new Error(this.userInventoryParseErrorMessage);
    }
  }

  private resolveInventoryUploadHttpEvent(httpEvent: any) {
    try {
      if (httpEvent.type === HttpEventType.UploadProgress) {
        this.uploadProgressSubject.next((httpEvent.loaded / httpEvent.total) * 100);
        // on success, POST returns status 201, PUT returns 200
      } else if (
        httpEvent.type === HttpEventType.Response &&
        [200, 201].includes(httpEvent.status)
      ) {
        return of(httpEvent.body);
      }
      return empty();
    } catch (error) {
      return throwError(error);
    }
  }

  private handleInventoryUploadErrors(error: any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.status === 400) {
      if (
        (parsedError.bodyJson.errors.length > 0 &&
          parsedError.bodyJson.errors[0].message.indexOf(
            'need to be member of exactly one permission group',
          ) > 0) ||
        parsedError.bodyJson.errors[0].message.indexOf('molecules limit exceeded') > 0
      ) {
        this.infoService.showError(parsedError.promptMessage);
      } else {
        this.infoService.showError(this.appConstantsService.inventoryUploadError);
      }
    } else if (parsedError.status === 409) {
      // That error message contains some unwanted information, TODO: change it after midend fixes message
      this.infoService.showError(`${parsedError.bodyJson.errors[0].message.split('.')[0]}.`);
      return throwError(parsedError);
    } else if (parsedError.status === 500) {
      this.infoService.showError(this.appConstantsService.inventoryUploadError);
    } else if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
      return throwError(parsedError);
    } else if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      return throwError(parsedError);
    } else {
      return throwError(`An error occurred while uploading inventory. Please try again.`);
    }
  }
}
