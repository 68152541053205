import {
  Component,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import {
  ConfirmDialogConfiguration,
  ConfirmDialogService,
} from '../../services/confirm-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from '../../animations';
import { finalize, takeUntil } from 'rxjs/operators';
import { KetcherEditorComponent } from '../ketcher-editor/ketcher-editor.component';
import { isNullOrUndefined, StructureInputType } from '../utils';
import { MoleculeSearchService } from '../../services/molecule-search';
import { RDKitModule } from '@rdkit/rdkit';
import { UntypedFormControl } from '@angular/forms';
import { TargetMoleculeSelectionService } from '../../services/target-molecule-selection/target-molecule-selection.service';

export enum ExcludeOrSeekType {
  Exclude = 'Exclude',
  Seek = 'Seek',
}

@Component({
  selector: 'ch-molecule-editor-dialog',
  styleUrls: ['./molecule-editor-dialog.component.scss'],
  templateUrl: './molecule-editor-dialog.component.html',
  animations: [FADE_IN_ANIMATION, FADE_OUT_ANIMATION],
  // ViewEncapsulation.None is added here so that component which extends it is able to use its styles without
  // duplicating them.
  /* tslint:disable-next-line */
  encapsulation: ViewEncapsulation.None,
})
export class MoleculeEditorDialogComponent implements OnInit, OnDestroy {
  public userInput: string = '';
  public elementList: string[] = [];
  public elementType: 'SMILES' | 'SMARTS';
  public inputData: string[];
  public editorLoaded: boolean = false;
  public errorMessage: string = '';
  public isDuplicatesRemovedNoticeDisplayed: boolean = false;
  public highlightedItemIndex: number | null = null;
  public unsavedChangesInEditor: boolean = true;
  public isAuthorizedToStartAnalysis: boolean = false;
  public editorInstance: any;
  public forbidDuplicates: boolean = true;
  public excludeOrSeek: ExcludeOrSeekType;
  public rdkit?: RDKitModule;
  public hiddenFormControl: UntypedFormControl;
  public isMoleculeEdited: boolean = false;
  public searchLoading: boolean = true;

  @ViewChild('moleculeEditor', { static: true }) public moleculeEditor: KetcherEditorComponent;

  public moleculeEditorSubscriptions: Subscription = new Subscription();
  public molChangeSubscription: Subscription = new Subscription();
  public unsubscriberSubject: Subject<void> = new Subject();
  public errorMessageTimeout: any;
  public selectedSmiles: string = '';

  constructor(
    public dialogRef: MatDialogRef<MoleculeEditorDialogComponent>,
    public changeDetectorRef: ChangeDetectorRef,
    public confirmDialogService: ConfirmDialogService,
    public targetMoleculeSelectionService: TargetMoleculeSelectionService,
    @Inject(MAT_DIALOG_DATA)
    public injectedData: {
      elementType: 'SMILES' | 'SMARTS';
      excludeOrSeek?: ExcludeOrSeekType;
      inputData: string[];
      isAuthorizedToStartAnalysis: boolean;
    },
    public moleculeSearchService: MoleculeSearchService,
  ) {}

  public ngOnInit() {
    this.elementType = this.injectedData.elementType;
    this.excludeOrSeek = this.injectedData.excludeOrSeek || ExcludeOrSeekType.Exclude;
    this.isAuthorizedToStartAnalysis = this.injectedData.isAuthorizedToStartAnalysis;
    if (this.injectedData.inputData && this.injectedData.inputData.length) {
      const hideSeekListWithoutEmptyStrings: string[] = this.removeEmptyStrings(
        this.injectedData.inputData,
      );
      if (this.forbidDuplicates) {
        this.elementList = this.removeDuplicates(hideSeekListWithoutEmptyStrings);
        this.isDuplicatesRemovedNoticeDisplayed =
          this.elementList.length !== hideSeekListWithoutEmptyStrings.length;
        this.fadeDuplicatesRemovedNotice();
      }
    }

    this.hiddenFormControl = new UntypedFormControl();
    this.hiddenFormControl.valueChanges
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((newValue: string) => {
        if (newValue) {
          this.setValueFromPaste(newValue);
        }
      });

    this.moleculeSearchService.getRdkitObject().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });

    this.targetMoleculeSelectionService.isMoleculeLoading
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isMoleculeLoading: boolean) => {
        this.isMoleculeEdited = isMoleculeLoading;
      });

    this.targetMoleculeSelectionService.isMoleculeEditorLoaded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isLoaded: boolean) => {
        this.searchLoading = !isLoaded;
        if (isLoaded) {
          if (
            !isNullOrUndefined(this.moleculeEditor) &&
            isNullOrUndefined(this.moleculeEditor.ketcher)
          ) {
            this.moleculeEditor.loadMoleculeEditor();
          }
        }
      });
  }

  public addToElementList() {
    this.errorMessage = '';
    if (this.elementType === 'SMILES') {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        const smilesString = this.moleculeSearchService.molToSmileWithRdkit(molFile);
        const smilesArray: string[] = smilesString.split('.');
        smilesArray.forEach((smiles) => {
          if (this.moleculeSearchService.verifySmilesWithRdkit(smiles)) {
            this.addElement(smiles);
            this.changeDetectorRef.detectChanges();
          } else {
            this.displayErrorMessage('Invalid molecule');
          }
        });
        this.editorLoaded = false;
      });
    } else {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        this.moleculeSearchService
          .MolToSmartsWithRdkit(molFile)
          .subscribe((smartsArray: string[]) => {
            smartsArray.forEach((smarts) => {
              this.addElement(smarts);
              this.changeDetectorRef.detectChanges();
            });
            this.editorLoaded = false;
          });
      });
    }
  }

  public addElement(element: string) {
    if (element !== '') {
      if (
        !this.forbidDuplicates ||
        (this.forbidDuplicates && !this.isElementDuplicated(element, this.elementList))
      ) {
        this.elementList.push(element);
        this.unsavedChangesInEditor = false;
      } else {
        this.displayErrorMessage(`Given ${this.elementType} is already present in the list.`);
      }
    } else {
      this.displayErrorMessage(`No ${this.elementType} to add.`);
    }
  }

  public removeElementListItem(index: number, event: MouseEvent) {
    event.stopPropagation();
    this.elementList.splice(index, 1);
    this.unsavedChangesInEditor = true;
  }

  public onElementListItemClick(element: string) {
    let moleculeObj: string = element;
    if (!isNullOrUndefined(this.moleculeEditor)) {
      // ###### - Warning
      // The following lines are added to enable the SMARTS edit in the Ketcher editor
      // We are replacing the first occurrence of [ in SMARTS with [0# to make Ketcher understands its SMARTS
      // We need to update the logic in future
      if (this.elementType === 'SMARTS') {
        moleculeObj = element.replace('[', '[#0,');
      }
      this.moleculeEditor.isAnotherKetcherInstance = true;
      let inputFormat = StructureInputType.SMILES;
      if (this.elementType === 'SMARTS') {
        inputFormat = StructureInputType.SMARTS;
      }
      this.moleculeEditor.setMolecule(moleculeObj, inputFormat);
    }
  }

  public highlightItemOn(index: number) {
    this.highlightedItemIndex = index;
    this.changeDetectorRef.detectChanges();
  }

  public highlightItemOff() {
    this.highlightedItemIndex = null;
    this.changeDetectorRef.detectChanges();
  }

  public onCloseClick() {
    if (this.elementType === 'SMILES') {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        const smilesString = this.moleculeSearchService.molToSmileWithRdkit(molFile);
        const smilesArray: string[] = smilesString.split('.');
        if (!isNullOrUndefined(smilesArray) && smilesArray.length) {
          this.checkMoleculeInEditor(smilesArray);
        }
      });
    } else {
      this.moleculeEditor.getMoleculeMolFile().then((molFile: string) => {
        this.moleculeSearchService
          .MolToSmartsWithRdkit(molFile)
          .subscribe((smartsArray: string[]) => {
            if (!isNullOrUndefined(smartsArray) && smartsArray.length) {
              this.checkMoleculeInEditor(smartsArray);
            }
          });
      });
    }
  }

  public checkMoleculeInEditor(smilesArray: any) {
    this.elementList.sort((a, b) => a.localeCompare(b));
    smilesArray.sort((a, b) => a.localeCompare(b));
    this.unsavedChangesInEditor = !smilesArray.every((element) =>
      this.elementList.includes(element),
    );
    if (this.unsavedChangesInEditor && smilesArray[0] !== '') {
      const dialogConfiguration: ConfirmDialogConfiguration = {
        title: `Discard drawn molecule`,
        message: `Currently drawn molecule was not added to the list. Do you want to discard it?`,
        trueActionName: 'DISCARD',
      };

      this.confirmDialogService
        .confirm(dialogConfiguration)
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe((confirm) => {
          if (confirm) {
            this.dialogRef.close(this.elementList);
          }
        });
    } else {
      this.dialogRef.close(this.elementList);
    }
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
    if (this.moleculeEditorSubscriptions && !this.moleculeEditorSubscriptions.closed) {
      this.moleculeEditorSubscriptions.unsubscribe();
    }
    if (this.molChangeSubscription && !this.molChangeSubscription.closed) {
      this.molChangeSubscription.unsubscribe();
    }
    if (!!this.editorInstance) {
      this.editorInstance.off('molchange');
    }
    clearTimeout(this.errorMessageTimeout);
  }

  public getSmilesOrSmartsFromEditor() {
    const smilesOrSmartsObservable: Observable<any> = this.getSmilesOrSmartsObservable();
    this.moleculeEditorSubscriptions.add(
      smilesOrSmartsObservable
        .pipe(
          finalize(() => {
            this.changeDetectorRef.detectChanges();
          }),
        )
        .subscribe(
          (element: string) => {
            this.unsavedChangesInEditor = !!element
              ? !this.elementList.includes(element)
              : !!element;
          },
          // MarvinJS in version 19.14.0 seems to have a bug which occurs when attempting to getSmiles when editor
          // is empty. We need to make a workaround for it - assume that whenever we get this error ('Parsing
          // of service response has been failed') it actually is an empty SMILES response. It's ugly, but I can't
          // find any other solution for now.
          (err) => {
            this.unsavedChangesInEditor = false;
          },
        ),
    );
  }

  public getSmilesOrSmartsObservable(): Observable<any> {
    if (this.isElementTypeSmiles(this.elementType)) {
      const smilesObservable = Observable.create((observer) => {
        observer.next(this.moleculeEditor.getMoleculeSmiles());
        observer.complete();
      });
      return smilesObservable;
    } else if (this.isElementTypeSmarts(this.elementType)) {
      const smartsObservable = Observable.create((observer) => {
        observer.next(this.moleculeEditor.getMoleculeSmarts());
        observer.complete();
      });
      return smartsObservable;
    }
  }

  public isElementTypeSmarts(elementType: string): boolean {
    return elementType === 'SMARTS';
  }

  public isElementTypeSmiles(elementType: string): boolean {
    return elementType === 'SMILES';
  }

  public displayErrorMessage(errorMessage: string) {
    if (this.errorMessageTimeout) {
      clearTimeout(this.errorMessageTimeout);
    }
    this.errorMessage = errorMessage;
    this.errorMessageTimeout = setTimeout(() => {
      this.errorMessage = '';
    }, 3500);
  }

  public fadeDuplicatesRemovedNotice() {
    setTimeout(() => {
      this.isDuplicatesRemovedNoticeDisplayed = false;
    }, 5000);
  }

  public removeDuplicates(stringList: string[]): string[] {
    return stringList.filter((element, idx) => stringList.indexOf(element) === idx);
  }

  public removeEmptyStrings(stringList: string[]): string[] {
    return stringList.filter((element) => !!element.length);
  }

  public isElementDuplicated(element: string, elementList: string[]): boolean {
    return elementList.some((elementListItem) => element === elementListItem);
  }

  public setValueFromPaste(value: string) {
    const valueToSet: string = (value + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
    let inputFormat = StructureInputType.SMILES;
    if (valueToSet.includes('V2000') || valueToSet.includes('v2000')) {
      inputFormat = StructureInputType.MOL;
    }
    this.selectedSmiles = valueToSet;
    this.moleculeEditor.setMolecule(valueToSet, inputFormat);
  }
}
