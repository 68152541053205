import { Component, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { AuthorizedImageService } from '../../services';
import { SafeUrl } from '@angular/platform-browser';
import { ParsedConnectionError } from '../../services';

@Component({
  selector: 'ch-authorized-image',
  styleUrls: ['./authorized-image.component.scss'],
  templateUrl: './authorized-image.component.html',
})
export class AuthorizedImageComponent implements OnChanges {
  @Input() public src: string;
  @Input() public width: number;
  @Input() public height: number;
  @Input() public maxWidth: number;
  @Input() public maxHeight: number;
  @Input() public minWidth: number = 100;
  @Input() public minHeight: number = 100;

  @Output() public loading: boolean = false;
  @Output() public error: boolean = false;
  @Output() public errorMessage: string = '';
  @Output() public imageObjectUrl: any;

  constructor(private authImageService: AuthorizedImageService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('src')) {
      this.loading = true;
      // TODO: Take care of possible pending previous change.
      this.authImageService.getImage(this.src).subscribe(
        (objectUrl: SafeUrl) => {
          this.imageObjectUrl = objectUrl;
        },
        (error: any) => {
          const connetionError = new ParsedConnectionError(error);
          this.errorMessage = connetionError.isRecognized()
            ? connetionError.promptMessage
            : 'Error!';
          this.error = true;
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
    }
  }
}
